import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faInbox,
  faQuestionCircle,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import Tippy from '@tippyjs/react';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { cn } from 'src/common/utils';
import Logo from 'src/assets/sitelife_icon_brand.svg';
import LogoText from 'src/assets/sitelife_icon_text_brand.svg';
import { Tooltip } from 'src/common/components/Tooltip';
import { Paper } from 'src/common/components/Paper';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import {
  acceptInvitation,
  declineInvitation,
} from 'src/project/api/projectsApi';
import { Button } from 'src/common/components/buttons/Button';
import { useOnClickOutside } from 'src/common/hooks/useClickOutside';
import {
  GetProjectResponse,
  LicenseType,
  ProjectInvitation,
} from 'src/project/types/projects';
import { PrimaryNav } from './PrimaryNav';
import { UserMenu } from './UserMenu';
import { TextLink } from 'src/common/components/TextLink';
import { isProjectOwnerOrAdmin } from 'src/user/utils';
import {
  useProjectInvitesOfCurrentUserQuery,
  useTechCrewsQuery,
} from 'src/project/queries';
import {
  hasViewAnalysisPermission,
  hasViewDocumentationPermission,
} from 'src/Conrep/App/ActionAllowed/utils';
import { useConsent } from '../CookieConsent';
import { Changelog } from './Changelog';
import { useUser } from 'src/auth/user-context';

const LogoIcon = () => (
  <img src={Logo} className="h-[60px] px-[11px]" alt="sitelife logo" />
);

const LogoFull = () => (
  <img src={LogoText} className="h-[60px] px-[7px]" alt="sitelife logo" />
);

export const BaseAppbar = ({
  logo,
  children,
  left,
}: {
  logo: React.ReactNode;
  left?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <header className="z-50 bg-blue-800 font-medium tracking-wider text-white">
      <div className="flex h-[60px] items-center">
        <Link
          to="/"
          className="shrink-0 hover:bg-white/10 focus:text-blue-200 focus:outline-none"
        >
          {logo}
        </Link>

        {left}

        <div className="ml-auto flex items-center">{children}</div>
      </div>
    </header>
  );
};

export const SitelifeAppbar = () => {
  const { value: consentValue } = useConsent();
  const { data: invitations } = useProjectInvitesOfCurrentUserQuery();

  const hasInvitations = invitations && invitations.length > 0;

  return (
    <BaseAppbar logo={<LogoFull />}>
      {hasInvitations && <ProjectInvitations invitations={invitations} />}
      <HelpCenter />
      {consentValue === 'all' ? <Changelog /> : null}
      <UserMenu />
    </BaseAppbar>
  );
};

export const ProjectAppbar = ({ project }: { project: GetProjectResponse }) => {
  const user = useUser();
  const { value: consentValue } = useConsent();
  const hasAdminPermissions = isProjectOwnerOrAdmin(user._id, project);
  const isDemoProject = project.license.type === LicenseType.DEMO;

  const { data: techCrews } = useTechCrewsQuery(project._id);
  const hasDocumentationViewingPermissions = !!techCrews?.some((tc) =>
    hasViewDocumentationPermission(user._id, tc),
  );

  const hasAnalysisViewingPermissions = !!techCrews?.some((tc) =>
    hasViewAnalysisPermission(user._id, tc),
  );

  const { data: invitations } = useProjectInvitesOfCurrentUserQuery();

  const hasInvitations = invitations && invitations.length > 0;

  return (
    <BaseAppbar
      logo={<LogoIcon />}
      left={
        <PrimaryNav
          isDemoProject={isDemoProject}
          hasDocumentationViewingPermissions={
            hasAdminPermissions || hasDocumentationViewingPermissions
          }
          hasAnalysisViewingPermissions={
            hasAdminPermissions || hasAnalysisViewingPermissions
          }
        />
      }
    >
      {hasInvitations && <ProjectInvitations invitations={invitations} />}
      <HelpCenter />
      {consentValue === 'all' ? <Changelog /> : null}
      <UserMenu />
    </BaseAppbar>
  );
};

const buttonClasses =
  'block h-[60px] p-4 hover:bg-white/10 focus:text-blue-200 focus:outline-none';

const HelpCenter = () => {
  const { t } = useTranslation('app');
  return (
    <Tooltip content={t('appbar.helpCenter')} position="bottom">
      <a
        href="https://help.sitelife.com"
        target="_blank"
        rel="noopener noreferrer"
        className={buttonClasses}
      >
        <FontAwesomeIcon
          icon={faQuestionCircle}
          size="lg"
          className="p-1.5"
          fixedWidth
        />
      </a>
    </Tooltip>
  );
};

const ProjectInvitations = ({
  invitations,
}: {
  invitations: ProjectInvitation[] | undefined;
}) => {
  const { t } = useTranslation(['common', 'app']);
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(false);
  const tippyEl = useRef<HTMLDivElement>(null);
  useOnClickOutside(tippyEl, () => setIsOpen(false));

  const handleAccept = async (id: string) => {
    try {
      await acceptInvitation(id);
      toast.success(t('common:status.success'));
      queryClient.invalidateQueries({
        queryKey: ['project-invitations'],
      });
      queryClient.invalidateQueries({
        queryKey: ['projects'],
      });
    } catch (e) {
      toast.error(t('common:status.failure'));
    }
  };

  const handleDecline = async (id: string) => {
    try {
      await declineInvitation(id);
      toast.success(t('common:status.success'));
      queryClient.invalidateQueries({
        queryKey: ['project-invitations'],
      });
    } catch (e) {
      toast.error(t('common:status.failure'));
    }
  };

  const hasInvitations = invitations && invitations.length > 0;
  const sortedInvitations = hasInvitations
    ? [...invitations]
        .sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        )
        .reverse()
    : [];

  const filteredInvitations =
    sortedInvitations.length > 3
      ? sortedInvitations.slice(0, 3)
      : sortedInvitations;

  return (
    <Tippy
      content={
        <Paper className="w-[320px] text-shuttleGray-800" ref={tippyEl}>
          <div className="flex items-center justify-between border-b border-gray-200 p-2">
            <div className="flex-1" />
            <h3 className="font-medium">{t('app:invitations.invitations')}</h3>
            <div className="flex-1 text-right">
              <ActionIcon onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon fixedWidth icon={faTimes} />
              </ActionIcon>
            </div>
          </div>
          <ul>
            {filteredInvitations.map((invite) => (
              <li key={invite._id} className="border-b-2 px-4 py-3">
                <div className="center flex items-center border-b border-gray-200 last-of-type:border-b-0">
                  <div>
                    <h5 className="break-all font-semibold">
                      {invite.projectName}
                    </h5>
                    <h5 className="break-all text-shuttleGray-600">
                      {invite.companyName}
                    </h5>
                  </div>
                  <div className="ml-auto flex pl-2">
                    <Button
                      className="h-10 w-10"
                      onClick={() => handleAccept(invite._id)}
                    >
                      <FontAwesomeIcon fixedWidth icon={faCheck} />
                    </Button>
                    <Button
                      variant="secondary"
                      className="ml-2 h-10 w-10"
                      onClick={() => handleDecline(invite._id)}
                    >
                      <FontAwesomeIcon fixedWidth icon={faTimes} />
                    </Button>
                  </div>
                </div>
              </li>
            ))}
            <li className="px-4 py-3">
              <div className="flex justify-center border-b border-gray-200 last-of-type:border-b-0">
                <TextLink to="/invitations">
                  {t('app:invitations.allInvitations')}
                </TextLink>
              </div>
            </li>
            {!hasInvitations && (
              <li className="px-4 py-3 text-center text-shuttleGray-600">
                {t('app:invitations.noPendingInvitations')}
              </li>
            )}
          </ul>
        </Paper>
      }
      interactive
      visible={isOpen}
    >
      <button
        className={cn(buttonClasses, 'relative')}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <FontAwesomeIcon
          icon={faInbox}
          size="lg"
          className="p-1.5"
          fixedWidth
        />
        {hasInvitations && (
          <div className="pointer-events-none absolute right-[18px] top-[18px] h-2 w-2 rounded-full bg-red-500" />
        )}
      </button>
    </Tippy>
  );
};
