import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { faInbox } from '@fortawesome/pro-regular-svg-icons';

import { ActionMessage } from 'src/common/components/ActionMessage';
import { Button } from 'src/common/components/buttons/Button';
import { useCompanyInvitesOfCurrentUserQuery } from 'src/project/queries';
import { acceptInvitation } from '../api';

export const CompanyInvitations = () => {
  const { t } = useTranslation('company');
  const queryClient = useQueryClient();
  const { data: invitations } = useCompanyInvitesOfCurrentUserQuery();

  const acceptMutation = useMutation({
    mutationFn: acceptInvitation,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['companies'],
      });
      queryClient.invalidateQueries({
        queryKey: ['user', 'invitations'],
      });
    },
  });

  if (!invitations) return null;

  const handleAccept = (invitationId: string) => {
    acceptMutation.mutate(invitationId);
  };

  return (
    <ul className="mb-6">
      {invitations.map((invitation) => (
        <li key={invitation._id} className="mb-3 last:mb-0">
          <ActionMessage icon={faInbox}>
            <div className="flex w-full items-center justify-between">
              <p>
                <Trans ns="company" i18nKey="inviteUsers.pendingInviteMessage">
                  <strong>{{ userName: invitation.createdByName }}</strong> has
                  invited you to join the organization{' '}
                  <strong>{{ companyName: invitation.companyName }}</strong>
                </Trans>
              </p>
              <div className="ml-3 flex items-center gap-3">
                <Button
                  variant="green"
                  onClick={() => handleAccept(invitation._id)}
                  disabled={acceptMutation.isPending}
                >
                  {t('invitation.view.accept')}
                </Button>
              </div>
            </div>
          </ActionMessage>
        </li>
      ))}
    </ul>
  );
};
