import {
  faArrowLeft,
  faChevronDown,
  faChevronUp,
  faKey,
  faPlus,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { ActionMessage } from 'src/common/components/ActionMessage';
import { AddMembersModal } from 'src/common/components/AddMembersModal';
import { Avatar } from 'src/common/components/Avatar';
import { Checkbox } from 'src/common/components/inputs/Checkbox';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Modal } from 'src/common/components/Modal';
import { SearchSort } from 'src/common/components/SearchSort';
import { SelectSitelife } from 'src/common/components/SelectSitelife';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { SidebarLayout } from 'src/common/components/SidebarLayout';
import { Tooltip } from 'src/common/components/Tooltip';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Button } from 'src/common/components/buttons/Button';
import {
  addUserToTechCrewAdmins,
  removeUserFromTechCrewAdmins,
  updateTechCrew,
} from '../api/techCrewsApi';
import { EditPermissionsModal } from '../components/TechCrew/EditPermissionsModal';
import { TechCrewSidebar } from '../components/TechCrewSidebar';
import {
  useEligibleTechCrewUsersQuery,
  useProjectUsersQuery,
} from '../queries';
import { ProjectUser } from '../types/projects';
import {
  MappedTechCrewPermission,
  TechCrew,
  TechCrewPermission,
} from '../types/techCrews';
import { useProject } from '../project-context';

type Props = {
  techCrew: TechCrew;
};

// Sort ProjectUser entities based on a specific attribute and sort order
function sortUsersByAttribute(
  usersList: ProjectUser[],
  attribute: string,
  sortUsersOrder: string,
  techCrew: TechCrew,
): ProjectUser[] {
  const sortedUsers = usersList.slice().sort((a, b) => {
    let aValue;
    let bValue;
    switch (attribute) {
      case 'name':
        aValue = a.name;
        bValue = b.name;
        break;
      case 'surname':
        aValue = a.surname;
        bValue = b.surname;
        break;
      case 'email':
        aValue = a.email;
        bValue = b.email;
        break;
      case 'admin':
        aValue = techCrew.admins.includes(a._id);
        bValue = techCrew.admins.includes(b._id);
        break;
      default:
        aValue = '';
        bValue = '';
        break;
    }

    // Perform sorting based on sortOrder
    if (sortUsersOrder === 'asc') {
      if (aValue < bValue || aValue === undefined) {
        return -1;
      } else if (aValue > bValue || bValue === undefined) {
        return 1;
      }
      return 0;
    } else {
      if (aValue > bValue || bValue === undefined) {
        return -1;
      } else if (aValue < bValue || aValue === undefined) {
        return 1;
      }
      return 0;
    }
  });

  return sortedUsers;
}

// Filter the sorted list based on an entered string
function filterSortedUsers(
  sortedUsers: ProjectUser[],
  searchString: string,
): ProjectUser[] {
  return sortedUsers.filter((user) => {
    const lowerSearchString = searchString.toLowerCase();
    const lowerName = user.name.toLowerCase();
    const lowerSurname = user.surname.toLowerCase();
    const lowerEmail = user.email.toLowerCase();

    return (
      lowerName.includes(lowerSearchString) ||
      lowerSurname.includes(lowerSearchString) ||
      lowerEmail.includes(lowerSearchString)
    );
  });
}

export const TechCrewMembersView = ({ techCrew }: Props) => {
  const { t } = useTranslation(['common', 'project']);
  const { projectId = '' } = useParams();
  const queryClient = useQueryClient();
  const [showProjectAdmins, setShowProjectAdmins] = useState(false);
  const [isAddMembersOpen, setIsAddMembersOpen] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState<
    TechCrewPermission[]
  >([]);
  const [searchUsersBy, setSearchUsersBy] = useState('');
  const [sortUsersOrder, setSortUsersOrder] = useState<'asc' | 'desc'>('asc');
  const [sortUsersBy, setSortUsersBy] = useState('email');

  const sortOptions: SelectSitelifeOptionType[] = [
    { label: 'Email', value: 'email' },
    { label: t('common:firstName'), value: 'name' },
    { label: t('common:surname'), value: 'surname' },
    { label: t('project:users.admin'), value: 'admin' },
  ];

  const currentProject = useProject();
  const { data: currentProjectUsers } = useProjectUsersQuery(projectId);
  const projectAdmins = currentProjectUsers.filter(
    (u) =>
      currentProject.owner === u._id || currentProject.admins?.includes(u._id),
  );
  let techCrewMembers = currentProjectUsers.filter(
    (u) =>
      !!techCrew.members.find((m) => m.userId === u._id) &&
      !projectAdmins.find((a) => a._id === u._id),
  );

  const hasSelectedPermission = (member: ProjectUser): boolean => {
    return selectedPermissions.every((p) => {
      return techCrew.members
        .find((m) => m.userId === member._id)
        ?.permissions.includes(p);
    });
  };

  if (selectedPermissions.length > 0) {
    techCrewMembers = techCrewMembers.filter((member) =>
      hasSelectedPermission(member),
    );
  }
  // get all permissions distinct from the selected tech crew members
  const availablePermissions = [
    ...new Set(techCrew.members.flatMap((m) => m.permissions)),
  ];
  const mapPermissions = (permissions: TechCrewPermission[]) =>
    permissions.map((p) => ({
      value: p,
      label: t(MappedTechCrewPermission[p]),
    }));
  const permissionOptions = mapPermissions(availablePermissions);

  const arrangedTechCrewMembersList = techCrewMembers
    ? filterSortedUsers(
        sortUsersByAttribute(
          techCrewMembers,
          sortUsersBy,
          sortUsersOrder,
          techCrew,
        ),
        searchUsersBy,
      )
    : [];

  const handleChangePermissions = (
    selectedOptions: SelectSitelifeOptionType[],
  ) => {
    setSelectedPermissions(
      selectedOptions.map((p) => p.value as TechCrewPermission),
    );
  };
  async function updateTechCrewMemberPermissions(
    memberId: string,
    newPermissions: TechCrewPermission[],
  ) {
    try {
      // copy members and get index of member to update
      const updatedMembers = [...techCrew.members];
      const index = updatedMembers.findIndex((m) => m.userId === memberId);
      // update permissions by index and save to backend
      updatedMembers[index].permissions = newPermissions;
      await updateTechCrew(projectId, techCrew._id, {
        members: updatedMembers,
      });
      // invalidate query and show success toast
      await queryClient.invalidateQueries({
        queryKey: ['projects', projectId],
      });
      toast.success(
        t(
          'project:techCrewEdit.membersView.editPermissions.updatePermissionsSuccess',
        ),
      );
    } catch (e) {
      toast.error(
        t(
          'project:techCrewEdit.membersView.editPermissions.updatePermissionsFailure',
        ),
      );
    }
  }

  async function addTechCrewMembers(newMemberIds: string[]) {
    const newMembers = newMemberIds.map((userId) => {
      return { userId, permissions: [] };
    });
    const updatedMembers = [...techCrew.members, ...newMembers];
    await updateTechCrew(projectId, techCrew._id, {
      members: updatedMembers,
    });
    await queryClient.invalidateQueries({
      queryKey: ['projects', projectId],
    });
  }

  async function removeTechCrewMember(userId: string) {
    try {
      const updatedAdmins = techCrew.admins.filter((a) => a !== userId);
      const updatedMembers = techCrew.members.filter(
        (m) => m.userId !== userId,
      );
      await updateTechCrew(projectId, techCrew._id, {
        ...techCrew,
        admins: updatedAdmins,
        members: updatedMembers,
      });
      await queryClient.invalidateQueries({
        queryKey: ['projects', projectId],
      });
      toast.success(t('project:techCrewEdit.membersView.removeMemberSuccess'));
    } catch (error) {
      toast.error(t('project:techCrewEdit.membersView.removeMemberFailure'));
    }
  }

  async function addTechCrewMemberToAdmins(adminId: string) {
    try {
      await addUserToTechCrewAdmins(projectId, techCrew._id, adminId);
      await queryClient.invalidateQueries({
        queryKey: ['projects', projectId],
      });
      toast.success(
        t('project:techCrewEdit.membersView.promoteToAdminSuccess'),
      );
    } catch {
      toast.error(t('project:techCrewEdit.membersView.promoteToAdminFailure'));
    }
  }

  async function removeTechCrewMemberFromAdmins(adminId: string) {
    try {
      await removeUserFromTechCrewAdmins(projectId, techCrew._id, adminId);
      await queryClient.invalidateQueries({
        queryKey: ['projects', projectId],
      });
      toast.success(t('project:techCrewEdit.membersView.demoteAdminSuccess'));
    } catch {
      toast.error(t('project:techCrewEdit.membersView.demoteAdminFailure'));
    }
  }

  /**
   * @deprecated this violates react state design principles
   * TODO: refactor this, so children dont write state into the parent: lift state up
   */
  function CallGetUsersHook(
    page: number,
    entriesPerPage: number,
    searchBy?: string,
    sortBy?: string,
    sortOrder?: 'asc' | 'desc',
  ) {
    const { isPending, data: paginatedUsers } = useEligibleTechCrewUsersQuery(
      projectId,
      techCrew._id,
      page,
      entriesPerPage,
      searchBy,
      sortBy,
      sortOrder,
    );

    return { isPending, paginatedUsers };
  }
  return (
    <SidebarLayout
      header={
        <Header
          techCrew={techCrew}
          onClickAddMembers={() => setIsAddMembersOpen(true)}
        />
      }
      headerLeft={
        <Link to="../../techCrews">
          <Button variant="tertiary">
            <FontAwesomeIcon icon={faArrowLeft} />
            <span>{t('project:techCrewEdit.allTrades')}</span>
          </Button>
        </Link>
      }
      sidebar={<TechCrewSidebar />}
    >
      <div className="xl:flex">
        <div className="flex-col">
          <span>{t('common:filterBy')}</span>
          <SelectSitelife
            className="mr-2 mt-1 w-[340px]"
            options={permissionOptions}
            value={mapPermissions(selectedPermissions)}
            onChange={(selection: SelectSitelifeOptionType[]) => {
              handleChangePermissions(selection);
            }}
            isMulti
            closeMenuOnSelect={false}
          />
        </div>
        <SearchSort
          searchBy={searchUsersBy}
          sortBy={
            sortOptions.find(
              (sortOption) => sortOption.value === sortUsersBy,
            ) || sortOptions[0]
          }
          sortOrder={sortUsersOrder}
          sortOptions={sortOptions}
          onSearchBy={(newSearchBy) => setSearchUsersBy(newSearchBy)}
          onSortBy={(newSortBy) => setSortUsersBy(newSortBy)}
          onSortOrder={(newSortOrder) => setSortUsersOrder(newSortOrder)}
          className="mr-4"
        />
      </div>
      <div className="mt-8 flex flex-col space-y-8">
        {techCrewMembers?.length > 0 && (
          <table className="relative w-full divide-y divide-gray-200 shadow">
            <thead>
              <tr>
                <th scope="col" className="table-th">
                  {t('common:person')}
                </th>
                <th scope="col" className="table-th hidden xl:table-cell">
                  {t('common:position')}
                </th>
                <th scope="col" className="table-th">
                  {t('common:admin')}
                </th>
                <th scope="col" className="table-th">
                  <span className="sr-only">Buttons</span>
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {arrangedTechCrewMembersList.map((member) => {
                return (
                  <MemberRow
                    key={member._id}
                    user={member}
                    permissions={
                      techCrew.members.find((m) => m.userId === member._id)
                        ?.permissions
                    }
                    removeTechCrewMember={removeTechCrewMember}
                    addTechCrewMemberToAdmins={addTechCrewMemberToAdmins}
                    removeTechCrewMemberFromAdmins={
                      removeTechCrewMemberFromAdmins
                    }
                    updateTechCrewMemberPermissions={
                      updateTechCrewMemberPermissions
                    }
                    isTechCrewAdmin={techCrew.admins.includes(member._id)}
                  />
                );
              })}
            </tbody>
          </table>
        )}

        <div>
          <ActionMessage variant="info">
            <div className="flex w-full items-center justify-between">
              <span>
                <Trans
                  ns="project"
                  i18nKey="techCrewEdit.membersView.adminInfo"
                />
              </span>
              <ActionIcon
                onClick={() => setShowProjectAdmins(!showProjectAdmins)}
              >
                <FontAwesomeIcon
                  icon={showProjectAdmins ? faChevronDown : faChevronUp}
                />
              </ActionIcon>
            </div>
          </ActionMessage>
          {showProjectAdmins && (
            <table className="relative w-full divide-y divide-gray-200 shadow">
              <thead>
                <tr>
                  <th scope="col" className="table-th">
                    {t('common:person')}
                  </th>
                  <th scope="col" className="table-th hidden xl:table-cell">
                    {t('common:position')}
                  </th>
                  <th scope="col" className="table-th">
                    {t('common:admin')}
                  </th>
                </tr>
              </thead>
              <tbody className="table-body">
                {projectAdmins.map((member) => {
                  return (
                    <MemberRow
                      user={member}
                      isProjectAdmin={true}
                      key={member._id}
                    />
                  );
                })}
              </tbody>
            </table>
          )}
        </div>

        <AddMembersModal
          CallGetUsersHook={CallGetUsersHook}
          callAddMembers={addTechCrewMembers}
          isOpen={isAddMembersOpen}
          onBack={() => setIsAddMembersOpen(false)}
          onClose={() => setIsAddMembersOpen(false)}
        />
      </div>
    </SidebarLayout>
  );
};

const Header = ({
  techCrew,
  onClickAddMembers,
}: {
  techCrew: TechCrew;
  onClickAddMembers: () => void;
}) => {
  const { t } = useTranslation('project');
  return (
    <div className="flex min-h-[40px] items-center">
      <div className="flex items-center">
        <div
          style={{ backgroundColor: techCrew.color }}
          className="h-4 w-4 rounded"
        />
        <h1 className="ml-2 flex text-2xl font-semibold text-shuttleGray-800">
          {techCrew.name}
        </h1>
      </div>
      <div className="ml-auto">
        <Button onClick={onClickAddMembers}>
          <FontAwesomeIcon icon={faPlus} />
          {t('techCrewEdit.membersView.addMembers')}
        </Button>
      </div>
    </div>
  );
};

const MemberRow = ({
  user,
  permissions,
  removeTechCrewMember,
  addTechCrewMemberToAdmins,
  removeTechCrewMemberFromAdmins,
  updateTechCrewMemberPermissions,
  isTechCrewAdmin = false,
  isProjectAdmin = false,
}: {
  user: ProjectUser;
  permissions?: TechCrewPermission[];
  removeTechCrewMember?: (id: string) => Promise<void>;
  addTechCrewMemberToAdmins?: (id: string) => Promise<void>;
  removeTechCrewMemberFromAdmins?: (id: string) => Promise<void>;
  updateTechCrewMemberPermissions?: (
    userId: string,
    newPermissions: TechCrewPermission[],
  ) => Promise<void>;
  isTechCrewAdmin?: boolean;
  isProjectAdmin?: boolean;
}) => {
  const { t } = useTranslation(['common', 'project']);

  const [isEditPermissionsOpen, setIsEditPermissionsOpen] = useState(false);
  const [
    isPromoteToAdminConfirmationOpen,
    setIsPromoteToAdminConfirmationOpen,
  ] = useState(false);
  const [isDemoteAdminConfirmationOpen, setIsDemoteAdminConfirmationOpen] =
    useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  return (
    <tr>
      <td className="table-td">
        <div className="flex items-center">
          <Avatar
            size="sm"
            alt={`${user.name} ${user.surname}`}
            // src={user.image?.key}
          />

          <div className="ml-4 overflow-hidden py-1">
            <div className="max-w-[300px] truncate text-base font-semibold">
              {[user.name, user.surname].join(' ')}
            </div>
            <div className="truncate text-base text-shuttleGray-600">
              {user.email}
            </div>
          </div>
        </div>
      </td>
      <td className="table-td hidden xl:table-cell">{user.position}</td>
      <td className="table-td ">
        <Checkbox
          onChange={() => {
            if (isTechCrewAdmin) {
              setIsDemoteAdminConfirmationOpen(true);
            } else {
              setIsPromoteToAdminConfirmationOpen(true);
            }
          }}
          checked={isTechCrewAdmin || isProjectAdmin}
          disabled={isProjectAdmin}
        />
      </td>
      {!isProjectAdmin && (
        <td>
          <Tooltip
            content={t(
              'project:techCrewEdit.membersView.editPermissions.title',
            )}
          >
            <ActionIcon onClick={() => setIsEditPermissionsOpen(true)}>
              <FontAwesomeIcon icon={faKey} />
            </ActionIcon>
          </Tooltip>
          <Tooltip content={t('project:techCrewEdit.membersView.delete')}>
            <ActionIcon onClick={() => setIsDeleteConfirmationOpen(true)}>
              <FontAwesomeIcon icon={faTrash} />
            </ActionIcon>
          </Tooltip>
        </td>
      )}
      {permissions && updateTechCrewMemberPermissions && (
        <Modal
          isOpen={isEditPermissionsOpen}
          onRequestClose={() => setIsEditPermissionsOpen(false)}
        >
          <EditPermissionsModal
            permissions={permissions}
            isOpen={isEditPermissionsOpen}
            isAdmin={isTechCrewAdmin || isProjectAdmin}
            onClose={() => setIsEditPermissionsOpen(false)}
            onSubmit={async (newPermissions: TechCrewPermission[]) => {
              await updateTechCrewMemberPermissions(user._id, newPermissions);
              setIsEditPermissionsOpen(false);
            }}
          />
        </Modal>
      )}
      {addTechCrewMemberToAdmins && (
        <Modal
          isOpen={isPromoteToAdminConfirmationOpen}
          onRequestClose={() => setIsPromoteToAdminConfirmationOpen(false)}
        >
          <ConfirmationDialog
            title={t('project:techCrewEdit.membersView.promoteToAdminTitle')}
            message={
              <Trans
                ns="project"
                i18nKey="techCrewEdit.membersView.promoteToAdminMessage"
                values={{ userName: `${user.name} ${user.surname}` }}
                components={[
                  <span
                    className="font-extrabold text-shuttleGray-800"
                    key="1"
                  />,
                ]}
              />
            }
            customConfirmText={t('common:button.assignPermissions')}
            onCancel={() => setIsPromoteToAdminConfirmationOpen(false)}
            onConfirm={async () => {
              await addTechCrewMemberToAdmins(user._id);
              setIsPromoteToAdminConfirmationOpen(false);
            }}
          />
        </Modal>
      )}
      {removeTechCrewMemberFromAdmins && (
        <Modal
          isOpen={isDemoteAdminConfirmationOpen}
          onRequestClose={() => setIsDemoteAdminConfirmationOpen(false)}
        >
          <ConfirmationDialog
            title={t('project:techCrewEdit.membersView.demoteAdminTitle')}
            message={
              <Trans
                ns="project"
                i18nKey="techCrewEdit.membersView.demoteAdminMessage"
                values={{ userName: `${user.name} ${user.surname}` }}
                components={[
                  <span
                    className="font-extrabold text-shuttleGray-800"
                    key="1"
                  />,
                ]}
              />
            }
            customConfirmText={t('common:button.removePermissions')}
            onCancel={() => setIsDemoteAdminConfirmationOpen(false)}
            onConfirm={async () => {
              await removeTechCrewMemberFromAdmins(user._id);
              setIsDemoteAdminConfirmationOpen(false);
            }}
          />
        </Modal>
      )}
      {removeTechCrewMember && (
        <Modal
          isOpen={isDeleteConfirmationOpen}
          onRequestClose={() => setIsDeleteConfirmationOpen(false)}
        >
          <ConfirmationDialog
            title={t(
              'project:techCrewEdit.membersView.removeConfirmationTitle',
            )}
            message={t(
              'project:techCrewEdit.membersView.removeConfirmationMessage',
            )}
            customConfirmText={t(
              'project:techCrewEdit.membersView.removeConfirmationButton',
            )}
            onCancel={() => setIsDeleteConfirmationOpen(false)}
            onConfirm={async () => {
              await removeTechCrewMember(user._id);
              setIsDeleteConfirmationOpen(false);
            }}
          />
        </Modal>
      )}
    </tr>
  );
};
