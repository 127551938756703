import {
  faEdit,
  faTimes,
  faCheck,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cloneDeep from 'lodash/cloneDeep';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from 'src/common/utils';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { Tooltip } from 'src/common/components/Tooltip/Tooltip';
import { Tag } from 'src/project/types/projects';

type Props = {
  tag: Tag;
  usedTagList: string[];
  disabled?: boolean;
  onTagSave: (tag: Tag) => void;
  onTagRemove: (tag: Tag) => void;
};

export const ModelSettingsTag = ({
  tag,
  usedTagList,
  disabled,
  onTagSave,
  onTagRemove,
}: Props) => {
  const { t } = useTranslation('project');
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [newTagName, setNewTagName] = useState<string>(tag.name);

  const handleRemoveTag = () => {
    setCanEdit(false);
    onTagRemove(tag);
  };

  const handleEditTag = () => {
    if (canEdit && tag.name !== newTagName) {
      if (newTagName.trim() === '') {
        handleRemoveTag();
        return;
      }
      const newTag = cloneDeep(tag);
      newTag.name = newTagName;
      onTagSave(newTag);
      setNewTagName(tag.name);
    }
    setCanEdit(!canEdit);
  };

  const handleCancelTagEdit = () => {
    setCanEdit(false);
    setNewTagName(tag.name);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleEditTag();
    }
  };

  return (
    <div className={cn('flex items-center justify-between px-1 py-2')}>
      <div className="mr-4 flex h-8 w-full items-center">
        <div className="flex w-full flex-col">
          {canEdit ? (
            <TextInput
              value={newTagName}
              className="grow"
              onChange={(e) => setNewTagName(e.target.value)}
              placeholder="Tag Name"
              onKeyDown={onKeyDown}
            />
          ) : (
            <button className="text-left" onClick={handleEditTag}>
              <span className="mr-2 truncate text-sm text-shuttleGray-800">
                {tag.name}
              </span>
            </button>
          )}
        </div>
      </div>
      <Tooltip
        content={
          canEdit ? t('settings.tags.saveEditTag') : t('settings.tags.editTag')
        }
      >
        <ActionIcon onClick={handleEditTag} disabled={disabled}>
          <FontAwesomeIcon icon={canEdit ? faCheck : faEdit} />
        </ActionIcon>
      </Tooltip>
      {canEdit && (
        <Tooltip content={t('settings.tags.cancelEditTag')}>
          <ActionIcon onClick={handleCancelTagEdit} disabled={disabled}>
            <FontAwesomeIcon icon={faTimes} />
          </ActionIcon>
        </Tooltip>
      )}
      <Tooltip
        content={
          usedTagList.includes(tag._id ?? '')
            ? t('settings.tags.cannotDeleteTag')
            : t('settings.tags.deleteTag')
        }
      >
        <div>
          <ActionIcon
            onClick={handleRemoveTag}
            disabled={disabled || usedTagList.includes(tag._id ?? '')}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ActionIcon>
        </div>
      </Tooltip>
    </div>
  );
};
