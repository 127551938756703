import { Embed, models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  selectForgeViewerDbIdToExternalIdMapping,
  selectForgeViewerExternalIdToDbIdMapping,
  selectForgeViewerReady,
  selectForgeViewerSelection,
} from 'src/Forge';
import {
  HashMap,
  useColorizeForge,
  useForgeViewerFilter,
} from 'src/Forge/hooks';
import { useAppSelector } from 'src/app/hooks';
import { InlineMessage } from 'src/common/components/InlineMessage';
import toast from 'src/common/toast';
import { LicenseType } from 'src/project/types/projects';
import { TechCrew } from 'src/project/types/techCrews';
import { getPowerBiReportData, getToken } from '../api';
import { RefreshHistory } from '../types';
import { useProject } from 'src/project/project-context';
import {
  extractExportedVisualData,
  getHashedElementIdOfExternalId,
  getPowerBiVisualData,
  getTechCrewIdFromPowerBiVisual,
} from '../utils';

export const PowerBiEmbedded = ({
  pageName,
  datasetId,
  techCrews,
  refreshHistory,
  onSelectTechCrew,
  onRendered,
}: {
  pageName: string;
  datasetId: string;
  techCrews: TechCrew[];
  refreshHistory?: RefreshHistory;
  onSelectTechCrew: (id?: string) => void;
  onRendered: () => void;
}) => {
  const [isReportRendered, setIsReportRendered] = useState<boolean>(false);
  const [reportContainer, setReportContainer] = useState<Embed>();

  const [embedToken, setEmbedToken] = useState<string>('');
  const [powerbiReportId, setPowerbiReportId] = useState<string>();
  const [powerbiReportPages, setPowerbiReportPages] =
    useState<Record<string, string>>();
  const [extIdsColorDict, setExtIdsColorDict] = useState<HashMap<string>>({});
  const [displayedElementsInViewer, setDisplayedElementsInViewer] = useState<
    string[]
  >([]);

  const [powerBiElementFilter, setPowerBiElementFilter] = useState<string[]>(
    [],
  );

  const forgeViewerReady = useAppSelector(selectForgeViewerReady);
  const viewerDbIdToExternalIdMapping = useAppSelector(
    selectForgeViewerDbIdToExternalIdMapping,
  );
  const forgeViewerExternalIdToDbIdMapping = useAppSelector(
    selectForgeViewerExternalIdToDbIdMapping,
  );
  const viewerSelection = useAppSelector(selectForgeViewerSelection);

  const currentProject = useProject();
  const isDemoProject = currentProject.license.type === LicenseType.DEMO;
  const { t, i18n } = useTranslation('analysis');
  const language = i18n.language.slice(0, 2);

  useColorizeForge(
    forgeViewerReady,
    extIdsColorDict,
    forgeViewerExternalIdToDbIdMapping,
  );

  useForgeViewerFilter(displayedElementsInViewer);

  // Authentication
  useEffect(() => {
    const getReportIds = async () => {
      try {
        const { analysisProId, pages } = await getPowerBiReportData(
          currentProject._id,
        );
        setPowerbiReportId(analysisProId);
        setPowerbiReportPages(pages);
      } catch (e) {
        toast.error(t('pro.error'));
      }
    };
    async function getEmbedToken() {
      if (!powerbiReportId) {
        return;
      }
      try {
        const embedTokenResponse = await getToken(currentProject._id);
        const embedTokenString = embedTokenResponse.token;
        setEmbedToken(embedTokenString);
      } catch (e) {
        toast.error(t('pro.error'));
      }
    }

    getReportIds();
    getEmbedToken();
  }, [t, currentProject._id, powerbiReportId]);

  // getting PowerBi data once Report is loaded & rendered
  useEffect(() => {
    async function handlePowerBiStates() {
      const forgeFilterVisual = await getPowerBiVisualData(
        'forgeFilter',
        reportContainer,
      );
      if (forgeFilterVisual) {
        const exportData = await forgeFilterVisual.exportData(
          models.ExportDataType.Summarized,
        );
        const exportVisualDataObject = extractExportedVisualData(exportData);

        setExtIdsColorDict(exportVisualDataObject.extIdColorDict);
        const extIds = exportVisualDataObject.extIds;
        setDisplayedElementsInViewer(extIds);
      }

      const techCrewVisual = await getPowerBiVisualData(
        'techCrew Slicer',
        reportContainer,
      );
      const selectedTechCrew = await getTechCrewIdFromPowerBiVisual(
        techCrewVisual,
        techCrews,
      );

      onSelectTechCrew(selectedTechCrew);
    }

    handlePowerBiStates();
  }, [reportContainer, isReportRendered, onSelectTechCrew, techCrews]);

  // handle filtering powerBi elements when selecting elements in forgeViewer
  useEffect(() => {
    async function handlePowerBiElementFilter(externalIds: string[]) {
      if (externalIds.length > 0) {
        const hashedExternalIds = await Promise.all(
          externalIds.map((extId) => {
            return getHashedElementIdOfExternalId(extId);
          }),
        );
        setPowerBiElementFilter(hashedExternalIds);
      } else {
        if (viewerDbIdToExternalIdMapping) {
          const allExternalIds = Object.values(viewerDbIdToExternalIdMapping);
          const allHashedExternalIds = await Promise.all(
            allExternalIds.map((extId) => {
              return getHashedElementIdOfExternalId(extId);
            }),
          );
          setPowerBiElementFilter(allHashedExternalIds);
        }
      }
    }

    if (forgeViewerReady && viewerSelection && viewerDbIdToExternalIdMapping) {
      const selectedViewerExternalIds: string[] = [];
      viewerSelection.forEach((dbId) => {
        selectedViewerExternalIds.push(viewerDbIdToExternalIdMapping[dbId]);
      });

      handlePowerBiElementFilter(selectedViewerExternalIds);
    }
  }, [viewerDbIdToExternalIdMapping, viewerSelection, forgeViewerReady]);

  enum HyperlinkClickBehavior {
    Navigate = 0,
    NavigateAndRaiseEvent = 1,
    RaiseEvent = 2,
  }

  const powerBiFilterConfig: any =
    powerBiElementFilter.length > 0
      ? [
          {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: {
              table: 'Fact_ElementAction',
              column: 'idElement',
            },
            operator: 'In',
            values: powerBiElementFilter,
            filterType: models.FilterType.Basic,
          },
          {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: {
              table: 'Fact_ConstructionProgress',
              column: 'idElement',
            },
            operator: 'In',
            values: powerBiElementFilter,
            filterType: models.FilterType.Basic,
          },
          {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: {
              table: 'Fact_ElementBuildingProcess',
              column: 'fkElement',
            },
            operator: 'In',
            values: powerBiElementFilter,
            filterType: models.FilterType.Basic,
          },
        ]
      : [];

  const embedConfig = {
    type: 'report', // Supported types: report, dashboard, tile, visual and qna
    id: powerbiReportId,
    embedUrl: `https://app.powerbi.com/reportEmbed`,
    accessToken: embedToken,
    tokenType: models.TokenType.Embed,
    pageName: powerbiReportPages
      ? powerbiReportPages[pageName]
      : 'ReportSection',
    filters: powerBiFilterConfig,
    settings: {
      localeSettings: {
        language: language,
      },
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
      hyperlinkClickBehavior: HyperlinkClickBehavior.RaiseEvent,
      background: models.BackgroundType.Transparent,
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToPage,
        pageSize: {
          type: models.PageSizeType.Custom,
          width: 960,
          height: 1080,
        },
      },
    },
    datasetBinding: {
      datasetId: datasetId,
    },
  };

  const idOfLastSuccessfulRefresh = () => {
    return refreshHistory?.refreshHistory.find((r) => r.status === 'Completed')
      ?.requestId;
  };

  const eventHandlers = new Map([
    [
      'rendered',
      () => {
        setIsReportRendered((rendered) => !rendered);
        onRendered();
      },
    ],
    ['dataHyperlinkClicked', () => undefined],
  ]);

  return (
    <div className="relative flex h-full flex-col" id="powerBI">
      {isDemoProject ? (
        <InlineMessage variant="warning" className="m-2">
          {t('pro.demoData')}
        </InlineMessage>
      ) : null}
      <div className="absolute left-0 right-0 top-0 z-10 h-10 bg-white" />
      <PowerBIEmbed
        key={idOfLastSuccessfulRefresh()}
        embedConfig={embedConfig}
        eventHandlers={eventHandlers}
        cssClassName="h-full"
        getEmbeddedComponent={setReportContainer}
      />
    </div>
  );
};
