import { HTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';

import { cn } from 'src/common/utils';

type Props = {
  variant?: 'success' | 'warning' | 'error' | 'primary' | 'secondary';
};

export const InlineMessage = ({
  variant = 'primary',
  children,
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement> & Props) => (
  <div
    className={cn(
      'inline-flex items-center gap-2 rounded-sm px-3 py-1.5',
      [
        {
          'bg-green-50 text-green-700': variant === 'success',
          'bg-orange-50 text-orange-700': variant === 'warning',
          'bg-red-50 text-red-700': variant === 'error',
          'bg-teal-50 text-teal-700': variant === 'primary',
          'bg-blue-50 text-blue-700': variant === 'secondary',
        },
      ],
      className,
    )}
    {...rest}
  >
    <FontAwesomeIcon icon={iconMap[variant]} fixedWidth />
    {children}
  </div>
);

const iconMap = {
  success: faCheckCircle,
  warning: faExclamationTriangle,
  error: faExclamationCircle,
  primary: faInfoCircle,
  secondary: faInfoCircle,
};
