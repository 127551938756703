import { useQuery } from '@tanstack/react-query';

import { getInvitationsForUser as getCompanyInvitationsForUser } from 'src/company/api';
import { getActionGroups } from './api/actionGroupsApi';
import { getActions } from './api/actionsApi';
import {
  getEligibleProjectUsers,
  getInvitationsForUser as getProjectInvitationsForUser,
  getProject,
  getServiceSpecifications,
  getUsers,
} from './api/projectsApi';
import { getEligibleTechCrewUsers, getTechCrews } from './api/techCrewsApi';
import { getCustomParameterPropertiesSuggestions } from './api/suggestionApi';

const MAX_NAME_SUGGESTIONS = 8;

export function useProjectQuery(projectId: string) {
  return useQuery({
    queryKey: ['projects', projectId],
    queryFn: () => getProject(projectId),
  });
}

export function useProjectInvitesOfCurrentUserQuery() {
  return useQuery({
    queryKey: ['project-invitations'],
    queryFn: getProjectInvitationsForUser,
  });
}

export function useCompanyInvitesOfCurrentUserQuery() {
  return useQuery({
    queryKey: ['user', 'invitations'],
    queryFn: getCompanyInvitationsForUser,
  });
}

export function useActionGroupsQuery(projectId: string, techCrewId: string) {
  return useQuery({
    queryKey: ['projects', projectId, 'techCrews', techCrewId, 'actionGroups'],
    queryFn: () => getActionGroups(projectId, techCrewId),
    initialData: [],
    staleTime: 0,
  });
}

export function useActionsQuery(projectId: string) {
  return useQuery({
    queryKey: ['projects', projectId, 'actions'],

    queryFn: () => getActions(projectId),
  });
}

export function useTechCrewsQuery(projectId: string) {
  return useQuery({
    queryKey: ['projects', projectId, 'techCrews'],

    queryFn: () => getTechCrews(projectId),
  });
}

export function useProjectUsersQuery(projectId: string) {
  return useQuery({
    queryKey: ['projects', projectId, 'users'],
    queryFn: () => getUsers(projectId),
    initialData: [],
    staleTime: 0,
  });
}

export function useServiceSpecificationsQuery(projectId: string) {
  return useQuery({
    queryKey: ['projects', projectId, 'serviceSpecifications'],

    queryFn: () => getServiceSpecifications(projectId),
  });
}

export function useEligibleTechCrewUsersQuery(
  projectId: string,
  techCrewId: string,
  page: number,
  entriesPerPage: number,
  searchBy?: string,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
) {
  return useQuery({
    queryKey: [
      'projects',
      projectId,
      'techCrews',
      techCrewId,
      'eligibleusers',
      page,
      entriesPerPage,
      searchBy,
      sortBy,
      sortOrder,
    ],

    queryFn: () =>
      getEligibleTechCrewUsers(
        projectId,
        techCrewId,
        page,
        entriesPerPage,
        searchBy,
        sortBy,
        sortOrder,
      ),
  });
}

export function useEligibleProjectUsersQuery(
  projectId: string,
  page: number,
  entriesPerPage: number,
  searchBy?: string,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
) {
  return useQuery({
    queryKey: [
      'projects',
      projectId,
      'eligibleusers',
      page,
      entriesPerPage,
      searchBy,
      sortBy,
      sortOrder,
    ],

    queryFn: () =>
      getEligibleProjectUsers(
        projectId,
        page,
        entriesPerPage,
        searchBy,
        sortBy,
        sortOrder,
      ),
  });
}

export function useCustomParameterPropertiesSuggestionsQuery(
  projectId?: string,
  parameterId?: string,
  selectedId?: number[] | [],
) {
  return useQuery({
    queryKey: [
      'projects',
      projectId,
      'suggestions',
      'customParameterProperties',
      parameterId,
      selectedId,
    ],
    queryFn: () =>
      getCustomParameterPropertiesSuggestions(
        projectId,
        parameterId,
        MAX_NAME_SUGGESTIONS,
      ),
    staleTime: 0,
  });
}
