import { SignUpRequestPayload } from 'shared';

import { User } from 'src/user/types';
import { api } from 'src/common/fetch';

export async function login(email: string, password: string) {
  return api.post<User>('/api/auth/login', { email, password });
}

export async function refreshLogin() {
  return api.get<User>('/api/auth/refresh');
}

export async function logout() {
  return api.post('/api/auth/logout');
}

export async function requestPasswordReset(email: string, lang = 'de') {
  return api.post(`/api/auth/request-password-reset?lang=${lang}`, { email });
}

// no idea why the server returns 202 here on email in use? this is a validation arror, so it should be 400
export async function requestSignUp(
  payload: SignUpRequestPayload,
  search?: string,
  lang = 'de',
) {
  return api.post(
    `/api/auth/request-signup${
      search ? `${search}&lang=${lang}` : `?lang=${lang}`
    }`,
    payload,
  );
}

export async function completeSignUp(token: string) {
  return api.post(
    '/api/auth/signup',
    {},
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  );
}

export async function resetPassword(password: string, token: string) {
  return api.post(
    '/api/auth/reset-password',
    { password },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  );
}
