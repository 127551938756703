import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import React, {
  ChangeEvent,
  ComponentPropsWithoutRef,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { cn } from 'src/common/utils';
import { InputWrapper } from '../Wrapper/InputWrapper';

export interface ColorInputProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'value' | 'defaultValue'> {
  id?: string;
  label?: ReactNode;
  description?: ReactNode;
  error?: string | boolean;
  required?: boolean;
  colors: string[];
  disabledColors?: string[];
  wrapperProps?: { [key: string]: any };
  value?: string;
  defaultValue?: string;
}

export const ColorInput = React.forwardRef<HTMLInputElement, ColorInputProps>(
  function ColorInput(props: ColorInputProps, ref) {
    const {
      className,
      id,
      label,
      error,
      required,
      disabled,
      description,
      colors,
      disabledColors,
      value,
      defaultValue,
      wrapperProps,
      style,
      onChange,
      ...rest
    } = props;
    const { t } = useTranslation('common');
    const [internalColor, setInternalColor] = useState<string | undefined>(
      value,
    );
    const [tippyOpen, setTippyOpen] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const openColorPickerRef = useRef<HTMLButtonElement>(null);

    const isColorValid = useCallback(
      (color: string): boolean => {
        return colors.includes(color) && !disabledColors?.includes(color);
      },
      [colors, disabledColors],
    );

    useEffect(() => {
      const initColor = value ? value : defaultValue || '';
      if (isColorValid(initColor)) {
        setInternalColor(initColor);
        handleColorClick(initColor);
      } else {
        const defaultColor =
          (disabledColors && colors.length > disabledColors.length
            ? colors.find((color) => disabledColors?.indexOf(color) === -1)
            : colors[0]) || '';
        setInternalColor(defaultColor);
        handleColorClick(defaultColor);
      }
    }, [value, defaultValue, colors, isColorValid, disabledColors]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInternalColor(event.target.value);
      onChange && onChange(event);
    };

    const handleFocus = () => {
      openColorPickerRef.current?.focus();
    };

    const handleColorClick = (color: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/unbound-method
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      ).set;
      nativeInputValueSetter?.call(inputRef.current, color);
      inputRef.current?.dispatchEvent(new Event('input', { bubbles: true }));

      setTippyOpen(false);
    };

    return (
      <InputWrapper
        id={id}
        required={required}
        aria-disabled={disabled}
        label={label}
        error={error}
        description={description}
        className={className}
        style={style}
        {...wrapperProps}
      >
        <div className="flex flex-col whitespace-nowrap">
          <Tippy
            appendTo={document.body}
            disabled={disabled}
            content={
              <div className="min-h-[234px] w-[248px] rounded bg-white p-4 shadow">
                <div className="text-center font-medium">
                  {t('colorInput.chooseColor')}
                </div>
                {!colors || colors.length === 0 ? (
                  <div className="mt-2 w-full text-center text-shuttleGray-600">
                    {t('colorInput.noChoices')}
                  </div>
                ) : (
                  <div className="mt-5 flex flex-wrap gap-1">
                    {colors.map((color) => {
                      const isDisabled =
                        disabledColors && disabledColors?.includes(color);
                      const isSelected = internalColor === color;
                      return (
                        <button
                          className={cn(
                            'h-5 w-8 rounded-sm',

                            {
                              'opacity-10': isDisabled && !isSelected,
                              'pointer-events-none': isDisabled,
                              'ring-2 ring-blue-600': isSelected,
                              'hover:ring-1 hover:ring-blue-600 hover:ring-offset-1':
                                !isSelected,
                              'focus:ring-1 focus:ring-blue-600 focus:ring-offset-1':
                                !isSelected,
                            },
                          )}
                          style={{ backgroundColor: color }}
                          onClick={() => handleColorClick(color)}
                          disabled={isDisabled}
                          key={color}
                          aria-label={color}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            }
            visible={tippyOpen}
            onClickOutside={() => setTippyOpen(false)}
            placement="bottom-end"
            interactive
            arrow={false}
            zIndex={1111}
          >
            <button
              className={cn(
                'h-10 w-[46px] cursor-pointer border p-1',
                'rounded-sm',
                'border-gray-300 hover:border-gray-500',
                'focus:border focus:border-blue-600 focus:outline-none',
              )}
              type="button"
              onClick={() => setTippyOpen(true)}
              aria-label="open-color-picker"
              ref={openColorPickerRef}
            >
              <div
                className="flex h-full w-full cursor-pointer items-center rounded-sm"
                style={{ backgroundColor: internalColor }}
              >
                {!internalColor && (
                  <div className="whitespace-pre-wrap text-center text-xs">
                    {t('colorInput.chooseColor')}
                  </div>
                )}
              </div>
            </button>
          </Tippy>
        </div>
        <input
          {...rest}
          id={id}
          className="hidden"
          type="text"
          disabled={disabled}
          required={required}
          defaultValue={internalColor}
          onChange={handleChange}
          onFocus={handleFocus}
          ref={(inputElement) => {
            (inputRef.current as any) = inputElement;
            if (ref) {
              if (typeof ref === 'function') {
                ref(inputElement);
              } else {
                ref.current = inputElement;
              }
            }
          }}
          {...rest}
        />
      </InputWrapper>
    );
  },
);
