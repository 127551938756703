import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

import { Avatar } from 'src/common/components/Avatar';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import toast from 'src/common/toast';
import { CompanyInvitation } from 'src/company/types';
import { withdrawInvitation } from 'src/company/api';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Modal } from 'src/common/components/Modal';

type Props = {
  companyId: string;
  pendingInvitation: CompanyInvitation;
};

export const CompanyInvitationItem = ({
  companyId,
  pendingInvitation,
}: Props) => {
  const { t } = useTranslation('company');
  const queryClient = useQueryClient();

  const [showUserDeleteModal, setShowUserDeleteModal] =
    useState<boolean>(false);

  async function handleWithdrawInvitation(invitationId: string) {
    try {
      await withdrawInvitation(companyId, invitationId);
      queryClient.invalidateQueries({
        queryKey: ['companies', companyId, 'invitations'],
      });
    } catch (exception) {
      toast.error(t('users.delete.error'));
    }
  }

  return (
    <>
      <tr key={pendingInvitation._id}>
        <td className="table-td table-td-truncate">
          <div className="flex items-center">
            <Avatar size="sm" alt={pendingInvitation.email} />

            <div className="ml-4 overflow-hidden py-1">
              <div className="text-base font-semibold">--</div>
              <div className="truncate text-base text-shuttleGray-600">
                {pendingInvitation.email}
              </div>
            </div>
          </div>
        </td>
        <td className="table-td">&nbsp;</td>
        <td className="table-td" />
        <td className="pr-3 text-right">
          <ActionIcon onClick={() => setShowUserDeleteModal(true)}>
            <FontAwesomeIcon icon={faTrash} />
          </ActionIcon>
        </td>
      </tr>
      <Modal className="mt-80" isOpen={showUserDeleteModal}>
        <ConfirmationDialog
          title={t('users.delete.title')}
          message={t('users.delete.message')}
          customConfirmText={t('users.delete.confirm')}
          onCancel={() => setShowUserDeleteModal(false)}
          onConfirm={() => {
            handleWithdrawInvitation(pendingInvitation._id);
            setShowUserDeleteModal(false);
          }}
        />
      </Modal>
    </>
  );
};
