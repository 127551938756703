import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';

export function init() {
  dayjs.extend(relativeTime);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(weekOfYear);
  dayjs.extend(isoWeek);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(isBetween);
  dayjs.extend(duration);
  dayjs.extend(isToday);
  dayjs.extend(localizedFormat);
  dayjs.extend(updateLocale);

  dayjs.tz.setDefault('Europe/Vienna');

  // set EN locale to start with Monday instead of Sunday
  dayjs.updateLocale('en-gb', {
    weekStart: 1,
  });
}

export const isoDateFormat = 'YYYY-MM-DD'; // ISO-8601 date format only
