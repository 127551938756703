import { useEffect, useState } from 'react';

import { logger } from 'src/logger';

export function useClipboard({
  onSuccess,
  onError,
}: {
  onSuccess: (text: string) => void;
  onError?: (e: unknown) => void;
}) {
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    navigator.permissions
      .query({ name: 'clipboard-read' as PermissionName })
      .then((status) => {
        setIsAllowed(status.state !== 'denied'); // status.state can be 'granted', 'prompt', 'denied'
      });
  }, []);

  async function read() {
    try {
      const text = await navigator.clipboard.readText();
      onSuccess(text);
    } catch (e) {
      onError?.(e);
      logger.capture(e);
    }
  }

  return { read, isAllowed };
}
