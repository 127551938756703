import { faEye, faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Modal } from 'src/common/components/Modal';
import { Spinner } from 'src/common/components/Spinner';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Badge } from 'src/common/components/Badge';
import { Avatar } from 'src/common/components/Avatar';
import { deleteUser, impersonateUser } from 'src/admin/apiUsers';
import { UserEditModal } from './UserEditModal';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { Tooltip } from 'src/common/components/Tooltip';

import type { CompanyPopulatedUser } from 'shared';

type Props = {
  user: CompanyPopulatedUser;
};

export const UserRow = ({ user }: Props) => {
  const { t } = useTranslation(['admin', 'common']);

  const navigate = useNavigate();

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleImpersonate = async () => {
    try {
      setIsLoading(true);
      const loggedInUser = await impersonateUser(user._id);
      queryClient.setQueryData(['user'], loggedInUser);
      queryClient.resetQueries();
      navigate('/');
    } catch {
      toast.error(t('users.impersonateFailure'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDeleteUser = async () => {
    try {
      await deleteUser(user._id);
      await queryClient.invalidateQueries({
        queryKey: ['admin-users'],
      });
      toast.success(t('users.delete.success'));
    } catch (exception) {
      toast.error(t('users.delete.failure'));
    }
    setIsDeleteConfirmationOpen(false);
  };

  return (
    <tr>
      <td className="table-td table-td-truncate min-w-[200px]">
        <div className="flex items-center">
          <Avatar size="sm" alt={`${user.name} ${user.surname}`} />
          <div className="ml-4 overflow-hidden py-1">
            <div className="truncate text-base font-semibold">
              {[user.name, user.surname].join(' ')}
            </div>
            <div className="truncate text-base text-shuttleGray-600">
              {user.email}
            </div>
          </div>
        </div>
      </td>
      <td className="table-td table-td-truncate min-w-[150px] lg:table-cell">
        <Tooltip content={user.position} position="left">
          <span className="truncate">{user.position}</span>
        </Tooltip>
      </td>
      <td className="table-td min-w-[50px] lg:table-cell">
        <UserTypeBadge level={user.level} />
      </td>
      <td className="table-td flex">
        <ActionIcon className="ml-auto" onClick={handleImpersonate}>
          <FontAwesomeIcon icon={faEye} />
        </ActionIcon>
        <>
          <ActionIcon onClick={() => setIsUserModalOpen(true)}>
            <FontAwesomeIcon icon={faEdit} />
          </ActionIcon>
          <ActionIcon onClick={() => setIsDeleteConfirmationOpen(true)}>
            <FontAwesomeIcon icon={faTrash} />
          </ActionIcon>
        </>
      </td>
      <UserEditModal
        user={user}
        isOpen={isUserModalOpen}
        onClose={() => setIsUserModalOpen(false)}
      />
      <Modal
        isOpen={isDeleteConfirmationOpen}
        onRequestClose={() => setIsDeleteConfirmationOpen(false)}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <ConfirmationDialog
            title={t('users.delete.title')}
            message={
              <div className="flex flex-col space-y-2">
                <span>{t('users.delete.message')}</span>
                <InlineMessage variant="warning" className="mb-4">
                  {t('admin:users.delete.info')}
                </InlineMessage>
              </div>
            }
            onCancel={() => setIsDeleteConfirmationOpen(false)}
            onConfirm={handleConfirmDeleteUser}
            customConfirmText={t('common:button.delete')}
          />
        )}
      </Modal>
    </tr>
  );
};

const UserTypeBadge = ({ level }: { level: string }) => (
  <Badge
    variant={level === 'USER' ? 'green' : level === 'ADMIN' ? 'blue' : 'orange'}
  >
    {level}
  </Badge>
);
