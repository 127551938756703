import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { SignUpRequestPayload } from 'shared';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { useState } from 'react';

import { Button } from 'src/common/components/buttons/Button';
import { Checkbox } from 'src/common/components/inputs/Checkbox';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { PasswordStrengthInput } from 'src/common/components/inputs/PasswordStrengthInput/PasswordStrengthInput';
import { PASSWORD_REGEX } from 'src/user/constants';
import { resolveLanguageForUrl } from 'src/auth/util';
import { AcceptedError } from 'src/errors';
import { requestSignUp } from 'src/auth/api';

type FormValues = {
  name: string;
  surname: string;
  email: string;
  company: string;
  password: string;
  acceptedToS: boolean;
};

type Props = {
  onSignUp: (email: string) => void;
};

export const SignUpForm = ({ onSignUp }: Props) => {
  const { t, i18n } = useTranslation(['auth', 'common']);
  const { search } = useLocation();
  const language = resolveLanguageForUrl(i18n.language);
  const email = new URLSearchParams(search).get('email');

  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(payload: SignUpRequestPayload) {
    try {
      setIsLoading(true);
      await requestSignUp(payload, search, language);
      amplitude.track('signup: success');
      toast.success(t('common:success.create'));
      onSignUp(payload.email);
    } catch (err) {
      if (err instanceof AcceptedError) {
        setIsLoading(false);
        amplitude.track('signup: email in use');
        toast.error(t('auth:signUp.emailAlreadyInUse'));
      } else {
        toast.error(t('common:errors.generic'));
      }
    } finally {
      setIsLoading(false);
    }
  }

  const {
    handleSubmit,
    register,
    watch,
    formState: { isValid },
  } = useForm<FormValues>({ defaultValues: { password: '' } });

  const passwordInvalid =
    watch('password') !== '' && !PASSWORD_REGEX.test(watch('password'));
  const disableSubmit = !isValid || passwordInvalid;

  return (
    <div className="h-full px-8">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
        <div className="flex gap-2">
          <TextInput
            id="name"
            label={t('signUp.form.firstName')}
            placeholder={t('signUp.form.firstName')}
            className="w-full"
            required
            {...register('name', { required: true })}
          />

          <TextInput
            id="surname"
            label={t('signUp.form.surname')}
            placeholder={t('signUp.form.surname')}
            className="w-full"
            {...register('surname')}
          />
        </div>

        {email ? (
          <TextInput
            id="email"
            label={t('email')}
            placeholder={t('email')}
            className="w-full"
            required
            disabled
            value={email}
            {...register('email', { required: true })}
          />
        ) : (
          <TextInput
            id="email"
            label={t('email')}
            placeholder={t('email')}
            className="w-full"
            required
            {...register('email', { required: true })}
          />
        )}

        <TextInput
          id="company"
          label={t('signUp.form.companyName')}
          placeholder={t('signUp.form.companyName')}
          className="w-full"
          {...register('company')}
        />

        <PasswordStrengthInput
          id="password"
          label={t('password')}
          placeholder={t('password')}
          className="w-full"
          required
          description={t('resetPassword.passwordInputDescription')}
          error={passwordInvalid && t('resetPassword.passwordError')}
          value={watch('password')}
          {...register('password', { required: true })}
        />

        <Checkbox
          className="text-sm"
          label={
            <Trans
              ns="auth"
              i18nKey="signUp.form.ToS"
              components={[
                <Link
                  key={0}
                  to="https://bim-management.com/sitelife/"
                  className="inline-flex items-center gap-1 font-medium text-blue-600 hover:text-blue-700 hover:underline focus:text-blue-800"
                />,
                <Link
                  key={1}
                  to="https://bim-management.com/datenschutzerklaerung/"
                  className="inline-flex items-center gap-1 font-medium text-blue-600 hover:text-blue-700 hover:underline focus:text-blue-800"
                />,
              ]}
            />
          }
          required
          {...register('acceptedToS', { required: true })}
        />

        <div className="mt-4 flex w-full flex-col gap-2 text-base">
          <Button type="submit" loading={isLoading} disabled={disableSubmit}>
            {`${t('signUp.createAccount')}${
              import.meta.env.VITE_ENVIRONMENT === 'staging'
                ? ` ${t('test')}`
                : ''
            }`}
          </Button>
          <div className="w-full">
            <Link to="/" className="w-full">
              <Button variant="tertiary" className="w-full">
                {t('signUp.toLogin')}
              </Button>
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};
