import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import FileUploadIllustration from 'src/assets/illu_start_fileupload.svg';
import { useUser } from 'src/auth/user-context';
import { Card } from 'src/common/components/Card';
import { Container } from 'src/common/components/Container';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { LicenseExpiredMessage } from 'src/project/components/LicenseExpiredMessage';
import {
  GetProjectResponse,
  LicenseEvaluationType,
  LicenseType,
} from 'src/project/types/projects';
import { validateLicense } from 'src/project/utils';
import { SheetSetList } from 'src/sheetset/components/SheetSetList';
import { isProjectOwnerOrAdmin } from 'src/user/utils';
import { FileRow } from '../components/FileRow';
import { FileUploadNewFileButton } from '../components/Upload/NewFile/Button';
import { useFilesQuery } from '../queries';
import { TeaserPage } from 'src/common/components/TeaserPage';

export const FilesView = ({ project }: { project: GetProjectResponse }) => {
  const { t } = useTranslation(['file', 'sheetset', 'common']);
  const { data: files } = useFilesQuery(project._id);
  const user = useUser();

  const isAdmin = isProjectOwnerOrAdmin(user._id, project);
  const isFreeProject = project.license.type === LicenseType.FREE;
  const isExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;
  const isDemo = project.license.type === LicenseType.DEMO;
  const sortedFiles = [...(files || [])].sort((fileA, fileB) =>
    dayjs(fileB.createdAt).diff(fileA.createdAt),
  );

  if (isFreeProject) {
    return (
      <Container className="min-h-full p-8" variant="box">
        <TeaserPage
          project={project}
          description={t('file:upgradePage.upgradeText')}
          videoUrl="https://www.youtube.com/embed/uDh-XgDUe3U?si=0sJ1s2zBHmN9PqPu" //TODO: To be replaced as soon as the correct video is chosen
          cardTitle={t('upgradePage.cardTitle')}
          cardText={t('upgradePage.cardText')}
        />
      </Container>
    );
  }

  return (
    <Container className="min-h-full py-8" variant="box">
      {isExpired && <LicenseExpiredMessage />}
      {files?.length ? (
        <>
          <SheetSetSection />
          <Header isAdmin={isAdmin} isDemo={isDemo} isExpired={isExpired} />
          <div className="inline-block min-w-full overflow-auto align-middle">
            <table className="relative min-w-full divide-y divide-gray-200 shadow">
              <thead>
                <tr>
                  <th scope="col" className="table-th w-6/12">
                    {t('file:table.name')}
                  </th>
                  <th scope="col" className="table-th w-2/12">
                    {t('file:table.date')}
                  </th>
                  <th scope="col" className="table-th w-2/12">
                    {t('file:table.status')}
                  </th>
                  <th scope="col" className="table-th w-1/12" />
                </tr>
              </thead>
              <tbody className="table-body">
                {sortedFiles.map((file) => (
                  <FileRow
                    key={file._id}
                    file={file}
                    disabled={!isAdmin || isExpired}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <NoFiles isDemo={isDemo} isAdmin={isAdmin} isExpired={isExpired} />
      )}
    </Container>
  );
};

const Header = ({
  isDemo,
  isAdmin,
  isExpired,
}: {
  isDemo: boolean;
  isAdmin: boolean;
  isExpired: boolean;
}) => {
  const { t } = useTranslation('file');
  return (
    <div className="mb-5 flex justify-between">
      <div>
        <h3 className="mb-1 text-2xl font-semibold">{t('title')}</h3>
        <span className="text-shuttleGray-600">{t('titleInfo')}</span>
      </div>
      <div className="flex justify-end space-x-4">
        {!isDemo && (
          <FileUploadNewFileButton disabled={!isAdmin || isExpired} />
        )}
      </div>
    </div>
  );
};

const SheetSetSection = () => {
  const [searchFilter, setSearchFilter] = useState<string>();
  const { t } = useTranslation(['file', 'sheetset']);

  return (
    <section className="mb-12">
      <div className="mb-5 flex justify-between">
        <div>
          <h3 className="mb-1 text-2xl font-semibold">{t('sheetset:title')}</h3>
          <span className="text-shuttleGray-600">
            {t('sheetset:titleInfo')}
          </span>
        </div>
        <div className="relative flex items-center">
          <TextInput
            className="ml-10 w-96"
            placeholder={`${t('file:search')}...`}
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
          />
        </div>
      </div>

      <SheetSetList searchFilter={searchFilter} />
    </section>
  );
};

const NoFiles = ({
  isAdmin,
  isDemo,
  isExpired,
}: {
  isAdmin: boolean;
  isDemo: boolean;
  isExpired: boolean;
}) => {
  const { t } = useTranslation(['file']);

  return (
    <Card>
      <div className="mx-auto my-6 max-w-[500px] text-center">
        <img className="mx-auto mb-8" src={FileUploadIllustration} alt="" />
        <h1 className="my-4 text-4xl font-semibold">{t('file:empty.title')}</h1>
        <div className="my-6 text-shuttleGray-600">
          <p>{t('file:empty.text')}</p>
          <div className="my-6 bg-gray-100 p-4 text-left text-shuttleGray-600">
            <div className="mb-2 inline-flex items-center gap-2">
              <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
              <span className="text-lg">{t('file:empty.infobox.title')}</span>
            </div>
            <p className="mb-5 mt-2">{t('file:empty.infobox.text')}</p>
          </div>
        </div>
        {!isDemo && (
          <FileUploadNewFileButton disabled={!isAdmin || isExpired} />
        )}
      </div>
    </Card>
  );
};
