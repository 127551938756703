import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  CSSProperties,
  forwardRef,
} from 'react';

import { cn } from 'src/common/utils';

interface InputProps extends ComponentPropsWithoutRef<'input'> {
  id?: string;
  className?: string;
  style?: CSSProperties;
  invalid?: boolean;
  error?: string | boolean;
  required?: boolean;
  disabled?: boolean;
}

export const Input = forwardRef(function Input(
  props: InputProps,
  ref: ComponentPropsWithRef<'input'>['ref'],
) {
  const {
    id,
    type,
    className,
    invalid,
    required,
    disabled,
    style,
    error,
    ...rest
  } = props;

  return (
    <input
      {...rest}
      id={id}
      ref={ref}
      required={required}
      aria-invalid={invalid}
      disabled={disabled}
      type={type}
      style={style}
      className={cn(
        { 'border-red-500': error },
        { 'border-gray-100 bg-gray-100': disabled },
        {
          'border-gray-300 hover:border-gray-500': !disabled && !error,
        },
        'placeholder:text-gray-800',
        'w-full flex-auto rounded-sm leading-[22px]',
        'focus:border focus:border-blue-600 focus:outline-none',
        className,
      )}
    />
  );
});
