import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';

import { GetAdminProjectResponse } from 'src/project/types/projects';
import { SelectNative } from 'src/common/components/inputs/SelectNative/SelectNative';
import { Button } from 'src/common/components/buttons/Button';
import { changeProjectOwner } from 'src/admin/apiProjects';
import { Modal } from 'src/common/components/Modal';
import { Spinner } from 'src/common/components/Spinner';
import newOwnerIllu from 'src/assets/illu_new-owner.svg';
import { useAdminProjectUsersQuery } from 'src/admin/queries';
import { isUserPartOfCompany } from 'src/admin/utils';

type Props = {
  project: GetAdminProjectResponse;
  isOpen: boolean;
  onClose: () => void;
};

export const ProjectNewOwnerModal = ({ project, isOpen, onClose }: Props) => {
  const { t } = useTranslation(['admin', 'common']);
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<{ newOwner: string }>({ defaultValues: { newOwner: '' } });

  const { data: projectMembers, isPending } = useAdminProjectUsersQuery(
    project._id,
  );

  const potentialNewOwners = projectMembers.filter(
    (u) =>
      u._id !== project.owner._id &&
      isUserPartOfCompany(project.company?._id, u.companies),
  );

  async function handleTransfer({ newOwner }: { newOwner: string }) {
    try {
      await changeProjectOwner(project._id, newOwner);
      await queryClient.invalidateQueries({
        queryKey: ['admin-projects'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['admin-users'],
      });
      toast.success(
        t('admin:projects.modal.information.transferOwner.success'),
      );
      onClose();
    } catch (e) {
      toast.error(t('admin:projects.modal.information.transferOwner.failure'));
    }
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="w-[680px]">
      <Modal.Header onClose={onClose}>
        {t('admin:projects.modal.information.transferOwner.title')}
      </Modal.Header>
      <Modal.Body>
        <form
          className="flex flex-col items-center gap-6"
          onSubmit={handleSubmit(handleTransfer)}
        >
          <div className="ml-4 flex gap-9">
            <img src={newOwnerIllu} alt="" />
            <div className="flex w-full flex-col gap-4">
              <p className="text-shuttleGray-600">
                <Trans
                  ns="admin"
                  i18nKey="projects.modal.information.transferOwner.text"
                />
              </p>
              {isPending ? (
                <Spinner />
              ) : (
                <SelectNative
                  label={t(
                    'admin:projects.modal.information.transferOwner.newOwner',
                  )}
                  required
                  {...register('newOwner')}
                >
                  <option value="" disabled hidden>
                    {t('admin:projects.modal.information.transferOwner.choose')}
                  </option>
                  {potentialNewOwners.map((member) => (
                    <option key={member._id} value={member._id}>
                      {member.name} {member.surname}
                    </option>
                  ))}
                </SelectNative>
              )}
            </div>
          </div>
          <div className="ml-auto flex gap-2">
            <Button variant="tertiary" onClick={onClose}>
              {t('common:button.abort')}
            </Button>
            <Button variant="primary" type="submit" disabled={!isDirty}>
              {t('common:button.save')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
