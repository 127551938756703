import { User } from 'src/user/types';
import {
  Company,
  CompanyCreateRequest,
  CompanyCreateResponse,
  CompanyInvitation,
} from './types';
import { DownloadRef, PatchRef } from 'src/common/types';
import { api } from 'src/common/fetch';

export async function createCompany(company: CompanyCreateRequest) {
  return api.post<CompanyCreateResponse>('/api/companies', company);
}

export async function getUserCompanies() {
  return api.get<Company[] | null>('/api/companies');
}

export async function getCompanyById(companyId: string) {
  return api.get<Company>(`/api/companies/${companyId}`);
}

export async function updateCompany(
  companyId: string,
  company: Partial<Company>,
) {
  return api.patch(`/api/companies/${companyId}`, company);
}

export async function deleteCompany(companyId: string) {
  return api.delete(`/api/companies/${companyId}`);
}

export async function getCompanyImage(companyId: string) {
  return api.get<DownloadRef>(`/api/companies/${companyId}/image`);
}

export async function editCompanyImage(companyId: string, extension: string) {
  return api.post<PatchRef>(`/api/companies/${companyId}/image`, { extension });
}

export async function deleteCompanyImage(companyId: string) {
  return api.delete(`/api/companies/${companyId}/image`);
}

export async function getCompanyUsers(id: string) {
  return api.get<User[]>(`/api/companies/${id}/users`);
}

export async function removeUser(companyId: string, userId: string) {
  return api.delete(`/api/companies/${companyId}/users/`, { userId });
}

export async function addUserToAdmins(companyId: string, userId: string) {
  return api.post(`/api/companies/${companyId}/admins`, { userId });
}

export async function removeUserFromAdmins(companyId: string, userId: string) {
  return api.delete(`/api/companies/${companyId}/admins`, { userId });
}

export async function leaveCompany(companyId: string) {
  return api.delete(`/api/companies/${companyId}/leave`);
}

export async function transferOwnership(companyId: string, ownerId: string) {
  return api.patch(`/api/companies/${companyId}/owner`, { ownerId });
}

export async function getPendingInvitations(companyId: string) {
  return api.get<CompanyInvitation[]>(
    `/api/companies/${companyId}/invitations`,
  );
}

export async function inviteUserToCompany(
  companyId: string,
  email: string,
  lang = 'de',
) {
  return api.post(`/api/companies/${companyId}/invitations?lang=${lang}`, {
    email,
  });
}

export async function withdrawInvitation(
  companyId: string,
  invitationId: string,
) {
  return api.delete(`/api/companies/${companyId}/invitations/${invitationId}`);
}

export async function getInvitationsForUser() {
  return api.get<CompanyInvitation[]>('/api/company-invitations');
}

export async function acceptInvitation(invitationId: string) {
  return api.post(`/api/company-invitations/${invitationId}/accept`);
}
