import {
  faCheckCircle,
  faCircleX,
  faRefresh,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { refreshDataset } from '../api';
import { Button } from 'src/common/components/buttons/Button';
import { GetProjectResponse } from 'src/project/types/projects';
import { RefreshHistory } from '../types';
import { sleep } from '../utils';

const getIsRefreshing = (history: RefreshHistory) => {
  if (
    history.refreshHistory[0].status === 'Completed' ||
    history.refreshHistory[0].status === 'Failed'
  ) {
    return false;
  }
  return true;
};

export const AnalysisUpdate = ({
  project,
  history,
}: {
  project: GetProjectResponse;
  history: RefreshHistory;
}) => {
  const { t } = useTranslation(['analysis', 'common']);
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const queryClient = useQueryClient();

  if (history.refreshHistory.length === 0) {
    return null;
  }

  if (
    history.refreshHistory.findIndex((h) => h.status === 'Completed') === -1
  ) {
    return null;
  }

  const isRefreshing = isRefreshDisabled || getIsRefreshing(history);

  const handleUpdateProject = async () => {
    setIsRefreshDisabled(true);
    await refreshDataset(project._id);
    await sleep(3000);
    await queryClient.invalidateQueries({
      queryKey: ['projects', project._id, 'powerbi-refreshes'],
    });
    setIsRefreshDisabled(false);
  };

  const getIconByStatus = (status: string) => {
    switch (status) {
      case 'Completed':
        return (
          <FontAwesomeIcon
            className="pr-3 text-green-600"
            fixedWidth
            icon={faCheckCircle}
          />
        );
      case 'Failed':
        return (
          <FontAwesomeIcon
            className="pr-3 text-red-600"
            fixedWidth
            icon={faCircleX}
          />
        );
      default:
        return (
          <FontAwesomeIcon
            className="pr-3 text-shuttleGray-600"
            fixedWidth
            icon={faRefresh}
            onClick={() =>
              queryClient.invalidateQueries({
                queryKey: ['projects', project._id, 'powerbi-refreshes'],
              })
            }
          />
        );
    }
  };

  return (
    <div className="flex items-center justify-between">
      <p className="pr-1 text-shuttleGray-600">
        {`${t('update.lastUpdate')} ${dayjs(
          history.refreshHistory[0]?.endTime ??
            history.refreshHistory[0]?.startTime,
        ).format('L LT')}`}
      </p>
      {getIconByStatus(history.refreshHistory[0].status)}
      <Button
        loading={isRefreshing}
        variant="primary"
        size="sm"
        onClick={handleUpdateProject}
      >
        <FontAwesomeIcon icon={faRefresh} />
        {t('update.update')}
      </Button>
    </div>
  );
};
