import { toast as reactHotToast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toaster as ReactHotToaster } from 'react-hot-toast';
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';

/**
 * tailwind styles are using the important variants here,
 * because goober injects styles at runtime, which means in a build (not in local dev)
 * our tailwind utils get overwritten by goober
 **/

const defaultStyles = '!px-3 !py-2.5 !rounded-sm !text-white !shadow';

export const Toaster = () => (
  <ReactHotToaster
    position="bottom-left"
    reverseOrder
    toastOptions={{
      icon: <FontAwesomeIcon icon={faInfoCircle} fixedWidth />,
      className: `${defaultStyles} !bg-teal-600`,
      success: {
        icon: <FontAwesomeIcon icon={faCheckCircle} fixedWidth />,
        className: `${defaultStyles} !bg-green-600`,
      },
      error: {
        icon: <FontAwesomeIcon icon={faExclamationCircle} fixedWidth />,
        className: `${defaultStyles} !bg-red-600`,
      },
    }}
  />
);

export default reactHotToast;
