import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAlphaDown,
  faSortAlphaUp,
} from '@fortawesome/pro-duotone-svg-icons';
import { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { cn } from 'src/common/utils';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { SelectSitelife } from 'src/common/components/SelectSitelife';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';

type Props = {
  searchBy: string;
  sortBy?: SelectSitelifeOptionType;
  sortOrder: 'asc' | 'desc';
  sortOptions?: SelectSitelifeOptionType[];
  onSearchBy: (searchBy: string) => void;
  onSortBy?: (sortBy: string) => void;
  onSortOrder: (sortOrder: 'asc' | 'desc') => void;
  searchOnChange?: boolean;
  className?: string;
};

export const SearchSort = ({
  searchBy,
  sortBy,
  sortOrder,
  sortOptions,
  onSearchBy,
  onSortBy,
  onSortOrder,
  searchOnChange,
  className,
}: Props) => {
  const { t } = useTranslation('common');
  const [searchByTerm, setSearchByTerm] = useState(searchBy);

  const debouncedSearchBy = useMemo(
    () =>
      debounce((value: string) => {
        onSearchBy(value);
      }, 1000),
    [onSearchBy],
  );

  const handleChangeSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
    const newSearchByTerm = event.target.value;
    setSearchByTerm(newSearchByTerm);
    if (
      newSearchByTerm !== searchBy &&
      (searchOnChange || newSearchByTerm === '')
    ) {
      debouncedSearchBy(newSearchByTerm);
    }
  };

  const handleClearSearchByTerm = () => {
    if (searchByTerm !== '') {
      setSearchByTerm('');
      onSearchBy('');
    }
  };

  const handleSearchKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchByTerm !== searchBy) {
      onSearchBy(searchByTerm);
      searchOnChange && debouncedSearchBy.cancel();
    }
  };

  const handleChangeSortOption = (newSortOption: SelectSitelifeOptionType) => {
    if (newSortOption.value && onSortBy) {
      onSortBy(newSortOption.value);
    }
  };

  const handleSwitchSortDirection = () => {
    if (sortOrder === 'asc') {
      onSortOrder('desc');
    } else {
      onSortOrder('asc');
    }
  };

  return (
    <div className={cn('flex items-end', className)}>
      <div className="relative flex items-center">
        <TextInput
          placeholder={t('searchPlaceholder')}
          className="mr-4 w-[400px]"
          value={searchByTerm}
          onChange={handleChangeSearchTerm}
          onKeyDown={handleSearchKeyDown}
        />
        <ActionIcon
          className="absolute right-0 top-0 mr-5 mt-1"
          onClick={handleClearSearchByTerm}
        >
          <FontAwesomeIcon icon={faTimes} />
        </ActionIcon>
      </div>
      {sortBy && sortOptions && onSortBy && (
        <div>
          <span>{t('sortBy')}</span>
          <SelectSitelife
            className="mr-2 mt-1 w-[240px]"
            value={sortBy}
            options={sortOptions}
            onChange={handleChangeSortOption}
            usePortaling
          />
        </div>
      )}
      <ActionIcon className="mb-1" onClick={handleSwitchSortDirection}>
        <FontAwesomeIcon
          size="lg"
          icon={sortOrder === 'asc' ? faSortAlphaDown : faSortAlphaUp}
        />
      </ActionIcon>
    </div>
  );
};
