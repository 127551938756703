import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Button } from 'src/common/components/buttons/Button';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { getCategories, getViewDict } from 'src/project/utils';
import toast from 'src/common/toast';
import { Action } from 'src/project/types/actions';
import { ActionGroup } from 'src/project/types/actionGroups';
import {
  createActionGroup,
  updateActionGroup,
} from 'src/project/api/actionGroupsApi';
import { TechCrew } from '../types/techCrews';
import { SelectSitelife } from 'src/common/components/SelectSitelife';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { arraysEqual } from 'src/common/utils';
import { useFilesQuery } from 'src/file/queries';
import { useSheetSetsQuery } from 'src/sheetset/queries';

interface FormValues {
  name: string;
  description: string;
}

interface Props {
  projectId: string;
  techCrew: TechCrew;
  actionGroup?: ActionGroup;
  onCancel?: () => void;
  inUse?: boolean;
  usedCategories: string[];
  actions: Action[];
  isFirst?: boolean;
}

export const ActionGroupForm = ({
  projectId,
  techCrew,
  actionGroup,
  onCancel,
  inUse = false,
  usedCategories,
  actions,
  isFirst = false,
}: Props) => {
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();
  const { data: sheetSets } = useSheetSetsQuery(projectId);
  const { data: files } = useFilesQuery(projectId);
  const viewDict = getViewDict(files);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<FormValues>({ mode: 'onChange' });

  const [selectedViews, setSelectedViews] = useState<
    SelectSitelifeOptionType[]
  >([]);
  const [selectedCategories, setSelectedCategories] = useState<
    SelectSitelifeOptionType[]
  >([]);
  const [selectedActions, setSelectedActions] = useState<
    SelectSitelifeOptionType[]
  >([]);

  const { isPending: categoriesLoading, data: categories } = useQuery({
    queryKey: [
      'projects',
      projectId,
      'techCrews',
      techCrew._id,
      'categories',
      selectedViews,
      viewDict,
    ],

    queryFn: () => {
      if (selectedViews && viewDict) {
        const viewIds = selectedViews.map((option) => option.value || '');
        return getCategories(techCrew, viewIds, viewDict);
      }
      return [];
    },

    initialData: [],
    staleTime: 0,
  });

  const setActionGroupValues = useCallback(() => {
    if (actionGroup) {
      reset({
        name: actionGroup.name,
        description: actionGroup.description,
      });
      setSelectedCategories(
        actionGroup.categories.map((category) => ({
          label: category,
          value: category,
          isFixed: inUse,
        })),
      );
      setSelectedActions(
        actions
          .filter((action) => actionGroup.actions.includes(action._id))
          .map((action) => ({
            label: action.name,
            value: action._id,
            isFixed: inUse,
          })),
      );
    } else {
      reset();
      setSelectedViews([]);
      setSelectedCategories([]);
      setSelectedActions([]);
    }
  }, [actionGroup, actions, inUse, reset]);

  useEffect(() => {
    setActionGroupValues();
  }, [setActionGroupValues]);

  const views: SelectSitelifeOptionType[] =
    sheetSets && viewDict
      ? [
          ...new Set(
            sheetSets
              .map((sheetSet) => sheetSet.views)
              .flat()
              .map((fileVersionView) => {
                const file = files?.find((f) => f._id === fileVersionView.file);
                const view =
                  viewDict[
                    `${fileVersionView.file}_${fileVersionView.viewableId}`
                  ];
                const label = file ? `${view.name} - ${file.name}` : view.name;
                return {
                  label,
                  value: view._id,
                };
              }),
          ),
        ]
      : [];

  const categoryOptions: SelectSitelifeOptionType[] = categories.map(
    (category) => ({
      value: category,
      label: category,
      isDisabled: usedCategories.includes(category),
    }),
  );

  const actionOptions: SelectSitelifeOptionType[] =
    actions.map((action) => ({
      value: action._id,
      label: action.name,
    })) || [];

  const handleSave = async ({ name, description }: FormValues) => {
    const editedActionGroup = {
      name,
      description,
      categories: selectedCategories.map((category) => category.value || ''),
      actions: selectedActions.map((action) => action.value || ''),
    };

    if (actionGroup) {
      try {
        await updateActionGroup(techCrew, actionGroup._id, editedActionGroup);
        queryClient.invalidateQueries({
          queryKey: [
            'projects',
            projectId,
            'techCrews',
            techCrew._id,
            'actionGroups',
          ],
        });
        toast.success(t('actionGroup.view.updateActionGroupSuccess'));
        onCancel && onCancel();
      } catch (error) {
        toast.error(t('actionGroup.view.updateActionGroupFailure'));
      }
    } else {
      try {
        await createActionGroup(techCrew, editedActionGroup);
        queryClient.invalidateQueries({
          queryKey: [
            'projects',
            projectId,
            'techCrews',
            techCrew._id,
            'actionGroups',
          ],
        });
        toast.success(t('actionGroup.view.addActionGroupSuccess'));
        onCancel && onCancel();
      } catch (err) {
        toast.error(t('actionGroup.view.addActionGroupFailure'));
      }
    }
  };

  const getCurrentCategoryString = () => {
    if (techCrew) {
      if (!techCrew.categoryParameter || techCrew.categoryParameter === '') {
        return t('actionGroup.form.revitCategory');
      }
      return techCrew.categoryParameter;
    }
    return '';
  };

  const areSelectsDirty = (): boolean => {
    return (
      !actionGroup ||
      !arraysEqual<string>(
        actionGroup.categories,
        selectedCategories.map((category) => category.value as string),
      ) ||
      !arraysEqual<string>(
        actionGroup.actions,
        selectedActions.map((action) => action.value as string),
      )
    );
  };

  const isSaveDisabled = !(isDirty || areSelectsDirty());

  return (
    <form
      className="flex w-full flex-col gap-8"
      onSubmit={handleSubmit(handleSave)}
    >
      <div className="flex flex-col gap-4 overflow-y-auto">
        <TextInput
          label={t('actionGroup.form.name')}
          required
          {...register('name')}
        />

        <TextInput
          label={t('actionGroup.form.description')}
          {...register('description')}
        />

        {!actionGroup?.isDefaultActionGroup && (
          <div>
            <span className="whitespace-nowrap">
              {t('actionGroup.form.views')}
            </span>
            <SelectSitelife
              options={views}
              onChange={(newViews: SelectSitelifeOptionType[]) =>
                setSelectedViews(newViews)
              }
              value={selectedViews}
              usePortaling
              isMulti
            />
          </div>
        )}
        {!actionGroup?.isDefaultActionGroup && (
          <div>
            <span className="whitespace-nowrap">
              {t('actionGroup.form.category')} [{getCurrentCategoryString()}]
            </span>
            <SelectSitelife
              isMulti
              options={categoryOptions}
              value={selectedCategories}
              isClearable={false}
              closeMenuOnSelect={false}
              onChange={(newCategories: SelectSitelifeOptionType[]) =>
                setSelectedCategories(newCategories)
              }
              isDisabled={
                !(selectedViews && selectedViews.length > 0) &&
                selectedCategories.length < 1
              }
              isLoading={categoriesLoading}
              usePortaling
              noDelete={inUse}
            />
            {isFirst && (
              <InlineMessage variant="error" className="mt-2">
                {t('actionGroup.form.attentionFirstActionGroup')}
              </InlineMessage>
            )}

            <InlineMessage variant="warning" className="mt-2">
              {t('actionGroup.form.attentionUsedActionGroup1')}
            </InlineMessage>
          </div>
        )}

        <div>
          <span className="whitespace-nowrap">
            {t('actionGroup.form.actions')}
          </span>
          <SelectSitelife
            isClearable={false}
            closeMenuOnSelect={false}
            isMulti
            options={actionOptions}
            value={selectedActions}
            onChange={(newActions: SelectSitelifeOptionType[]) =>
              setSelectedActions(newActions)
            }
            usePortaling
            noDelete={inUse}
          />
          <InlineMessage variant="warning" className="mt-2">
            {t('actionGroup.form.attentionUsedActionGroup2')}
          </InlineMessage>

          {actionGroup?.isDefaultActionGroup && (
            <InlineMessage variant="primary" className="mt-2">
              {t('actionGroup.view.default')}
            </InlineMessage>
          )}
        </div>
      </div>
      <div className="ml-auto flex gap-2">
        <Button
          variant="tertiary"
          onClick={() => {
            setActionGroupValues();
            onCancel && onCancel();
          }}
        >
          {t('common:button.abort')}
        </Button>
        <Button type="submit" disabled={isSaveDisabled}>
          {actionGroup ? t('common:button.save') : t('common:button.add')}
        </Button>
      </div>
    </form>
  );
};
