import * as amplitude from '@amplitude/analytics-browser';
import React from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';

export function init() {
  const apiKey = import.meta.env.VITE_AMPLITUDE_API_KEY;
  if (!apiKey) {
    return;
  }

  const optOut = getCookieConsentValue('sitelife-tracking-consent') !== 'all';

  amplitude.init(apiKey, undefined, {
    optOut,
    defaultTracking: {
      pageViews: {
        trackHistoryChanges: 'all',
      },
      sessions: true,
      formInteractions: true,
      fileDownloads: true,
    },
    logLevel: amplitude.Types.LogLevel.None,
  });
  identifySizes();
}

function getSizeString(x: number, y: number) {
  return `${x}x${y}px`;
}

function identifySizes() {
  const event = new amplitude.Identify();
  const { innerWidth, innerHeight } = window;
  const { height, width } = window.screen;
  event.setOnce('viewport', getSizeString(innerWidth, innerHeight));
  event.setOnce('screenResolution', getSizeString(width, height));
  amplitude.identify(event);
}

export function useAmplitudeUser(id?: string) {
  React.useEffect(() => {
    if (!id) {
      return;
    }
    amplitude.setUserId(id);
  }, [id]);
}
