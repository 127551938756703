import * as Shared from 'shared';

import { PaginatedResponse } from 'src/common/types';
import {
  CreateTechCrew,
  TechCrew,
  TechCrewUsage,
  UpdateTechCrew,
} from '../types/techCrews';
import { api } from 'src/common/fetch';

export async function getTechCrew(projectId: string, techCrewId: string) {
  return api.get<TechCrew>(
    `/api/projects/${projectId}/techcrews/${techCrewId}`,
  );
}

export async function getTechCrews(projectId: string) {
  return api.get<Array<TechCrewUsage>>(`/api/projects/${projectId}/techcrews`);
}

export async function createTechCrew(
  projectId: string,
  techCrew: CreateTechCrew,
) {
  return api.post<TechCrew>(`/api/projects/${projectId}/techcrews`, techCrew);
}

export async function updateTechCrew(
  projectId: string,
  techCrewId: string,
  techCrew: UpdateTechCrew,
) {
  return api.post<TechCrew>(
    `/api/projects/${projectId}/techcrews/${techCrewId}`,
    techCrew,
  );
}

export async function addUserToTechCrewAdmins(
  projectId: string,
  techCrewId: string,
  adminId: string,
) {
  return api.post(`/api/projects/${projectId}/techcrews/${techCrewId}/admins`, {
    adminId,
  });
}

export async function removeUserFromTechCrewAdmins(
  projectId: string,
  techCrewId: string,
  adminId: string,
) {
  return api.delete(
    `/api/projects/${projectId}/techcrews/${techCrewId}/admins`,
    { adminId },
  );
}

export async function deleteTechCrew(projectId: string, techCrewId: string) {
  return api.delete(`/api/projects/${projectId}/techcrews/${techCrewId}`);
}

export async function getEligibleTechCrewUsers(
  projectId: string,
  techCrewId: string,
  page: number,
  entriesPerPage: number,
  searchBy?: string,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
) {
  const searchParams = new URLSearchParams({
    page: page.toString(),
    entriesPerPage: entriesPerPage.toString(),
    ...(searchBy && { searchBy }),
    ...(sortBy && { sortBy }),
    ...(sortOrder && { sortOrder: sortOrder?.toString() }),
  });

  return api.get<PaginatedResponse<Shared.User>>(
    `/api/projects/${projectId}/techcrews/${techCrewId}/eligibleusers?${searchParams.toString()}`,
  );
}

export async function updateTechCrewServiceSpecificationPath(
  projectId: string,
  techCrewId: string,
  updatedData: Partial<TechCrew>,
  type: 'REGIE' | 'WORKDAY',
) {
  return api.post<TechCrew>(
    `/api/projects/${projectId}/techcrews/${techCrewId}/servicepaths/${type.toLowerCase()}`,
    updatedData,
  );
}

export async function updateTechCrewPlannedHours(
  projectId: string,
  techCrewId: string,
  techCrew: Shared.UpdateTechCrewPlannedHoursPayload,
) {
  return api.post(
    `/api/projects/${projectId}/techcrews/${techCrewId}/plannedhours`,
    techCrew,
  );
}

export async function deleteTechCrewPlannedHours(
  projectId: string,
  techCrewId: string,
) {
  return api.delete(
    `/api/projects/${projectId}/techcrews/${techCrewId}/plannedhours`,
  );
}
