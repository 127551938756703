import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faUndo } from '@fortawesome/pro-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';

import toast from 'src/common/toast';
import { Button } from 'src/common/components/buttons/Button';
import { DraggableParameterList } from 'src/Forge/components/DraggableParameterList';
import { useResizeForge } from 'src/Forge/hooks';
import { validateLicense } from 'src/project/utils';
import {
  GetProjectResponse,
  LicenseEvaluationType,
} from 'src/project/types/projects';
import { updateProject } from '../../api/projectsApi';
import { ParameterSelection } from './ParameterSelection';

type Props = {
  project: GetProjectResponse;
  parameters: string[];
};

export const DisplayedParameters = ({ project, parameters }: Props) => {
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();

  const [localParameters, setLocalParameters] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useResizeForge([localParameters]);

  useEffect(() => {
    if (project.shownProperties) {
      setLocalParameters(project.shownProperties);
    }
  }, [project.shownProperties]);

  const handleAddParameter = (parameter: string) => {
    const tempParameters = Array.from(localParameters);
    tempParameters.unshift(parameter);
    setLocalParameters(tempParameters);
  };

  const handleSave = async () => {
    if (project._id) {
      setIsLoading(true);
      try {
        await updateProject(project._id, {
          shownProperties: localParameters,
        });
        queryClient.invalidateQueries({
          queryKey: ['projects', project._id],
        });
        toast.success(
          t('settings.modelParameter.displayedParameters.saveSuccess'),
        );
      } catch (err) {
        toast.error(
          t('settings.modelParameter.displayedParameters.saveFailure'),
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const isDirty = !isEqual(localParameters, project.shownProperties);

  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;

  return (
    <div className="flex flex-col gap-4">
      <ParameterSelection
        parameters={parameters}
        localParameters={localParameters}
        onAddParameter={handleAddParameter}
      />
      <DraggableParameterList
        items={localParameters.map((localParameter) => ({
          title: localParameter,
        }))}
        canRemoveItems={!isLicenseExpired}
        setItems={(parameterItems) =>
          setLocalParameters(
            parameterItems.map((parameterItem) => parameterItem.title),
          )
        }
      />
      {isDirty && (
        <div className="ml-auto flex items-center gap-4">
          <Button
            variant="tertiary"
            onClick={() =>
              project.shownProperties &&
              setLocalParameters(project.shownProperties)
            }
          >
            <FontAwesomeIcon icon={faUndo} />
          </Button>
          <Button onClick={() => handleSave()} loading={isLoading}>
            {t('common:button.save')}
          </Button>
        </div>
      )}
    </div>
  );
};
