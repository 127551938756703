import { LicenseType } from 'shared';
import * as amplitude from '@amplitude/analytics-browser';

import { createProject } from 'src/project/api/projectsApi';

export async function createNewProject(
  projectName: string,
  licenseType: LicenseType,
  projectCompanyId: string,
) {
  const createdProject = await createProject({
    name: projectName,
    licenseType,
    company: projectCompanyId,
  });
  if (!createdProject) {
    throw new Error();
  }
  return createdProject;
}

export function trackProjectCreationAnalytics(
  projectId: string,
  projectName: string,
) {
  amplitude.track('project: create', {
    projectId,
    projectName,
  });
}
