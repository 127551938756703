import { ComponentPropsWithoutRef } from 'react';

import { Spinner } from './../Spinner';

export function Image({
  src,
  alt,
  ...props
}: {
  src?: string;
  alt?: string;
} & ComponentPropsWithoutRef<'img'>) {
  if (!src) {
    return <Spinner />;
  }

  return <img src={src} alt={alt || ''} {...props} />;
}
