import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Checkbox } from 'src/common/components/inputs/Checkbox';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { CustomServiceSpecification } from 'src/project/types/projects';
import { TechCrew } from 'src/project/types/techCrews';

type Props = {
  serviceSpecification: CustomServiceSpecification;
  checked: string[];
  onCheck: (
    c: string[],
    custom?: CustomServiceSpecification['parsedData']['custom'][0],
  ) => void;
  techCrewForSuggestions?: TechCrew;
  target?: 'WORKDAY' | 'REGIE';
};

export const ServiceSpecificationList = ({
  serviceSpecification,
  checked,
  onCheck,
  techCrewForSuggestions,
  target,
}: Props) => {
  const { t } = useTranslation(['common', 'project']);

  const [originalCustomSs, setOriginalCustomSs] = useState<
    CustomServiceSpecification['parsedData']['custom'] | null
  >(null);
  const [currentCustomSs, setCurrentCustomSs] = useState<
    CustomServiceSpecification['parsedData']['custom'] | null
  >(null);

  const filterRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!techCrewForSuggestions) {
      setOriginalCustomSs(serviceSpecification.parsedData.custom);
      setCurrentCustomSs(serviceSpecification.parsedData.custom);
      return;
    }

    let filteredSs: CustomServiceSpecification['parsedData']['custom'] = [];
    switch (target) {
      case 'WORKDAY':
        if (techCrewForSuggestions.serviceSpecificationPathsWorkday) {
          filteredSs = serviceSpecification.parsedData.custom.filter(
            (x) =>
              techCrewForSuggestions.serviceSpecificationPathsWorkday!.paths.indexOf(
                x._id,
              ) > -1,
          );
        }
        break;
      case 'REGIE':
        if (techCrewForSuggestions.serviceSpecificationPathsRegie) {
          filteredSs = serviceSpecification.parsedData.custom.filter(
            (x) =>
              techCrewForSuggestions.serviceSpecificationPathsRegie!.paths.indexOf(
                x._id,
              ) > -1,
          );
        }
        break;
    }
    setOriginalCustomSs(filteredSs);
    setCurrentCustomSs(filteredSs);
  }, [serviceSpecification, techCrewForSuggestions, target]);

  const handleCheck = (
    custom: CustomServiceSpecification['parsedData']['custom'][0],
  ) => {
    const inPaths = checked.find((x) => x === custom._id);
    const newPaths = cloneDeep(checked);
    if (inPaths) {
      const idx = newPaths.findIndex((x) => x === custom._id);
      newPaths.splice(idx, 1);
    } else if (custom._id) {
      newPaths.push(custom._id);
    }
    onCheck(newPaths, custom);
  };

  const getIsBasChecked = (
    custom: CustomServiceSpecification['parsedData']['custom'][0],
  ) => {
    const inPaths = checked.find((x) => x === custom._id);
    if (inPaths) {
      return true;
    }
    return false;
  };

  const getAreAllChecked = () => checked.length === currentCustomSs?.length;

  const handleCheckAll = () => {
    if (getAreAllChecked()) {
      onCheck([]);
    } else {
      onCheck(currentCustomSs?.map((bas) => bas._id) as string[]);
    }
  };

  const filterList = (searchString: string) => {
    if (originalCustomSs) {
      const filteredCustomSs = originalCustomSs.filter(
        (x) =>
          x.key.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
          x.description.toLowerCase().indexOf(searchString.toLowerCase()) > -1,
      );
      setCurrentCustomSs(filteredCustomSs);
    }
  };

  return (
    <div className="flex min-h-[480px] flex-col rounded border border-gray-400 bg-gray-100 p-3">
      <div className="mb-4 flex items-start justify-between">
        <div>
          <h3 className="text-xl font-semibold text-shuttleGray-800">
            {t('project:settings.serviceSpecifications.title')}
          </h3>

          <div className="text-base font-medium text-gray-800">
            {serviceSpecification?.name}
          </div>
        </div>

        <form
          className="flex w-96 max-w-[50%] items-center gap-1"
          onSubmit={(ev) => {
            ev.preventDefault();
            filterList((ev.target as HTMLFormElement).filterText.value);
          }}
        >
          <div className="relative flex w-full items-center">
            <TextInput
              type="text"
              placeholder={t('project:settings.serviceSpecifications.filterSS')}
              className="form-input w-full text-sm"
              name="filterText"
              ref={filterRef}
            />
            <ActionIcon
              className="absolute right-4 top-3"
              onClick={() => {
                if (filterRef.current) {
                  filterRef.current.value = '';
                }
              }}
            >
              <FontAwesomeIcon icon={faTimes} fixedWidth />
            </ActionIcon>
          </div>

          <ActionIcon>
            <FontAwesomeIcon icon={faSearch} fixedWidth />
          </ActionIcon>
        </form>
      </div>
      <div className="inline-block min-w-full align-middle ">
        <div className="overflow-hidden">
          <div className="flex min-h-0 flex-col overflow-auto">
            <table className="min-w-full divide-y divide-gray-100">
              <thead>
                <tr>
                  <th className="table-th">
                    {!techCrewForSuggestions ? (
                      <Checkbox
                        checked={getAreAllChecked()}
                        onChange={() => handleCheckAll()}
                        className="ml-4"
                      />
                    ) : null}
                  </th>
                  <th className="table-th">{t('common:labels.key')}</th>
                  <th className="table-th">{t('common:labels.description')}</th>
                </tr>
              </thead>
              <tbody className="table-body w-full">
                {currentCustomSs?.map((bas) => (
                  <tr key={bas.key}>
                    <td className="table-td ">
                      <Checkbox
                        checked={getIsBasChecked(bas)}
                        onChange={() => handleCheck(bas)}
                        className="ml-4"
                      />
                    </td>
                    <td className="table-td ">
                      <span className="text-sm font-medium leading-5 text-shuttleGray-800">
                        {bas.key}
                      </span>
                    </td>
                    <td className="table-td ">{bas.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
