import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/common/components/buttons/Button';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { CustomParameter } from 'src/project/types/projects';
import { CustomParameterTypeModal } from './CustomParameterTypeModal';

type Props = {
  parameters: CustomParameter[];
  localParameters: CustomParameter[];
  onAddParameter: (parameter: CustomParameter) => void;
  parametersIncludePath?: boolean;
  isDisabled?: boolean;
};

export const ParameterEntry = ({
  parameters,
  localParameters,
  onAddParameter,
  isDisabled,
}: Props) => {
  const { t } = useTranslation('project');

  const [selectedParameter, setSelectedParameter] =
    useState<CustomParameter | null>();

  const [nameInUse, setNameInUse] = useState(false);
  const [typeSelected, setTypeSelected] = useState(false);

  useEffect(() => {
    setSelectedParameter(null);
  }, [parameters]);

  return (
    <div className="flex gap-4">
      <div className="flex w-full flex-col gap-1.5">
        <TextInput
          placeholder={t('settings.modelParameter.parameter')}
          value={selectedParameter?.name || ''}
          error={nameInUse ? t('settings.actions.form.nameInUse') : false}
          onChange={(e) => {
            setNameInUse(false);
            const newSelectedParameterName: string = e.target.value;
            setSelectedParameter({
              name: newSelectedParameterName,
              type: selectedParameter?.type || 'string',
            });
          }}
          disabled={isDisabled}
        />
      </div>
      <Button
        className="mt-0"
        onClick={() => {
          if (
            localParameters.every(
              (element) => element.name !== selectedParameter?.name,
            )
          ) {
            setTypeSelected(true);
          } else {
            setNameInUse(true);
          }
        }}
        disabled={!selectedParameter || nameInUse || isDisabled}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      {typeSelected && (
        <CustomParameterTypeModal
          parameter={selectedParameter}
          onSave={(type) => {
            setTypeSelected(false);
            const updatedParameter: CustomParameter = {
              name: selectedParameter?.name || '',
              type: type as 'string' | 'number' | 'boolean',
            };
            onAddParameter(updatedParameter);
            setSelectedParameter(null);
          }}
          onClose={() => {
            setTypeSelected(false);
            setSelectedParameter(null);
          }}
        />
      )}
    </div>
  );
};
