import { GetProjectResponse } from 'src/project/types/projects';
import { RefreshHistory } from './types';
import { sleep } from './utils';
import { api } from 'src/common/fetch';

export async function getToken(projectId: string) {
  return api.get<{ token: string; expiration: Date }>(
    `/api/analysis/token/${projectId}`,
  );
}

export async function getPowerBiReportData(projectId: string) {
  return api.get<{
    masterGroupId: string;
    analysisProId: string;
    sitelifeBasisId: string;
    workspaceId: string;
    datasetName: string;
    analysisProDatasetId: string;
    pages: { [page: string]: string };
  }>(`/api/analysis/report/${projectId}`);
}

async function getAadToken(projectId: string) {
  const data = await api.get<{ token: string }>(
    `/api/analysis/aad/${projectId}`,
  );
  return data.token;
}

export async function refreshDataset(projectId: string) {
  return api.get(`/api/analysis/refresh/${projectId}`);
}

export async function getRefreshHistory(projectId: string) {
  return api.get<RefreshHistory>(`/api/analysis/refreshes/${projectId}`);
}

export async function resetDataset(projectId: string) {
  return api.delete(`/api/analysis/dataset/${projectId}`);
}

async function getDatasetIdFromImport(
  workspaceId: string,
  importId: string,
  token: string,
): Promise<string> {
  for (let tryCount = 0; tryCount < 10; tryCount++) {
    const importCheck = await fetch(
      `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/imports/${importId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const importCheckData = await importCheck.json();

    switch (importCheckData.importState) {
      case 'Succeeded':
        return importCheckData.datasets[0].id;
      case 'Failed':
        throw new Error();
      case 'Publishing':
      default:
        await sleep(3000);
    }
  }
  throw new Error();
}

export async function setupProject(project: GetProjectResponse) {
  const token = await getAadToken(project._id);
  const { masterGroupId, sitelifeBasisId, workspaceId, datasetName } =
    await getPowerBiReportData(project._id);

  // hier sitelifeBasisId überschreiben zum testen

  // export
  const exportRes = await fetch(
    `https://api.powerbi.com/v1.0/myorg/groups/${masterGroupId}/reports/${sitelifeBasisId}/Export`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );

  // import
  const blob = await exportRes.blob();
  const formData = new FormData();
  formData.append(datasetName, blob);

  const importRes = await fetch(
    `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/imports?datasetDisplayName=${datasetName}&skipReport=True`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    },
  );

  // make sure upload is completely finished before continuing
  const importData = await importRes.json();
  const datasetId = await getDatasetIdFromImport(
    workspaceId,
    importData.id,
    token,
  );

  const setupData = await api.get<{
    datasetId: string;
  }>(`/api/analysis/setup/${project._id}?datasetId=${datasetId}`);

  if (!setupData.datasetId) {
    throw new Error();
  }

  return setupData.datasetId;
}
