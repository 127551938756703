/* eslint-disable */ // until Karishma reworks the component
import React, { useEffect, useRef, useState } from 'react';
import Select, {
  components,
  IndicatorProps,
  MenuListComponentProps,
  OptionTypeBase,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Styles } from 'react-select/src/styles';
import {
  MultiValueGenericProps,
  MultiValueRemoveProps,
} from 'react-select/src/components/MultiValue';
import {
  faCheckDouble,
  faChevronDown,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';

import { removeDuplicatesFromArray } from 'src/common/utils';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { Tooltip } from '../Tooltip';
import { Chip } from '../Chip';

const defaultTextColor = '#292F36'; // shuttle-gray-800

// check if options are nested
function getIsOptionsNested(options: any): {
  isNested: boolean;
  count: number;
} {
  let nested = true;
  let count = 0;
  let copy: { label: string; value: string }[] = [];
  if (options) {
    options.forEach((o: any) => {
      if (o.options) {
        copy = [...copy, ...cloneDeep(o.options)];
      } else {
        nested = false;
      }
    });
    copy = removeDuplicatesFromArray(copy);
    count = copy.length;
  }

  return {
    isNested: nested,
    count,
  };
}

const MenuList = (props: MenuListComponentProps<OptionTypeBase, boolean>) => {
  const { t } = useTranslation('common');

  const { isNested, count: isNestedCount } = getIsOptionsNested(props.options);

  const getPlaceholderString = () => {
    let prefix;
    const suffix = t('selectSitelife.search');
    const opts = props.options || [];
    const vals = props.selectProps.value || [];

    if (props.selectProps.isMulti) {
      if (isNested) {
        prefix = isNestedCount - vals.length;
      } else {
        prefix = opts.length - vals.length;
      }
    } else if (isNested) {
      prefix = isNestedCount;
    } else {
      prefix = opts.length;
    }
    return (
      prefix +
      (prefix > 1
        ? ` ${t('selectSitelife.options')} `
        : ` ${t('selectSitelife.option')} `) +
      suffix
    );
  };

  const isSearchDisabled =
    (props.options &&
      props.selectProps?.value &&
      (isNested
        ? isNestedCount - props.selectProps.value.length < 1
        : props.options.length - props.selectProps.value.length < 1)) ||
    false;

  const setFocus = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.currentTarget.focus();
  };

  return (
    <div>
      <div className="b box-border flex items-center space-x-3 p-3">
        <TextInput
          className="box-border w-full"
          autoCorrect="off"
          autoComplete="off"
          spellCheck="false"
          type="text"
          value={props.selectProps.inputValue}
          onChange={(e) =>
            props.selectProps.onInputChange?.(e.currentTarget.value, {
              action: 'input-change',
            })
          }
          onMouseDown={setFocus}
          onTouchEnd={setFocus}
          onFocus={props.selectProps.onMenuInputFocus}
          aria-autocomplete="list"
          aria-label={props.selectProps['aria-label']}
          aria-labelledby={props.selectProps['aria-labelledby']}
          placeholder={getPlaceholderString()}
          disabled={isSearchDisabled}
        />

        {props.selectProps.isMulti && (
          <Tooltip
            content={
              <>
                <div className="font-medium">
                  {t('selectSitelife.addToSelection')}
                </div>
                <div className="mt-1 flex items-center">
                  {t('selectSitelife.greyObjects')}
                </div>
              </>
            }
          >
            <button
              className="cursor-pointer text-gray-600 hover:text-shuttleGray-800 disabled:pointer-events-none"
              onClick={() => props.selectProps.selectAll()}
              disabled={isSearchDisabled}
            >
              <FontAwesomeIcon icon={faCheckDouble} />
            </button>
          </Tooltip>
        )}
      </div>
      {/* TODO: use VirtualizedSelectMenu */}
      <components.MenuList {...props} />
    </div>
  );
};

const ValueContainer = ({
  children,
  ...props
}: MenuListComponentProps<OptionTypeBase, false>) => {
  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    setValue: props.setValue,
    selectProps: props.selectProps,
    theme: props.theme,
    innerProps: props.selectProps.innerProps,
  };

  return (
    <components.ValueContainer {...props}>
      {React.Children.map(
        children,
        (child) =>
          child ||
          (props.hasValue ? (
            <components.SingleValue
              {...commonProps}
              isDisabled={!!props.selectProps.isDisabled}
              data={props.getValue()}
            >
              {props.selectProps.getOptionLabel?.(props.getValue()[0])}
            </components.SingleValue>
          ) : (
            <components.Placeholder
              {...commonProps}
              key="placeholder"
              isFocused={props.selectProps.isFocused}
              isDisabled={!!props.selectProps.isDisabled}
            >
              {props.selectProps.placeholder}
            </components.Placeholder>
          )),
      )}
    </components.ValueContainer>
  );
};

const DropdownIndicator = (props: IndicatorProps<OptionTypeBase, boolean>) => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon
      icon={faChevronDown}
      className="text-shuttleGray-800"
      fixedWidth
    />
  </components.DropdownIndicator>
);

const MultiValueContainer = (props: MultiValueGenericProps<OptionTypeBase>) => (
  <Chip.Container {...props} className="m-1" />
);

const MultiValueLabel = (props: MultiValueGenericProps<OptionTypeBase>) => (
  <Chip.Label {...props} />
);

const MultiValueRemove = (props: MultiValueRemoveProps<OptionTypeBase>) =>
  props.data.isFixed ? null : (
    <Chip.Remove onClick={props.innerProps.onClick} />
  );

const ClearIndicator = (props: IndicatorProps<OptionTypeBase, boolean>) => {
  const {
    children = <FontAwesomeIcon icon={faTimes} />,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} className="p-2 text-shuttleGray-800">
      {children}
    </div>
  );
};

const customComponents = {
  MenuList,
  ValueContainer,
  IndicatorSeparator: null,
  ClearIndicator,
  DropdownIndicator,
  MultiValueContainer,
  MultiValueRemove,
  MultiValueLabel,
};

function isIncludingString(
  string: string,
  option: { label: string; value: string },
) {
  let result = false;
  if (
    !string ||
    option.label.toString().toLowerCase().includes(string.toLowerCase()) ||
    option.value.toString().toLowerCase().includes(string.toLowerCase())
  ) {
    result = true;
  }
  return result;
}

function valuesIncludeOption(
  values: { label: string; value: string }[],
  option: { label: string; value: string },
) {
  let found = false;
  values.forEach((v) => {
    if (v.value === option.value && v.label === option.label) {
      found = true;
    }
  });
  return found;
}

export const SelectSitelife: React.FC<any> = (props) => {
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation('common');

  const { isNested } = getIsOptionsNested(props.options);
  const inputFocus = () => setIsFocused(true);

  const onChangeCustom = (option: any, params: any) => {
    setIsFocused(false);
    if (props.onChange) {
      props.onChange(option, params);
    }
  };
  const onInputChangeCustom = (val: string) => setInputValue(val);

  const onClickAway = () => {
    setIsFocused(false);
    setInputValue('');
  };

  useEffect(() => {
    document.addEventListener('mousedown', onClickAway);
    return () => {
      document.removeEventListener('mousedown', onClickAway);
    };
  }, []);

  const selectAll = () => {
    let copy: { label: string; value: string; isDisabled?: boolean }[] = [];
    if (isNested) {
      if (props.options) {
        props.options.forEach((o: any) => {
          if (o.options) {
            copy = [...copy, ...cloneDeep(o.options)];
          }
        });
      } else {
        copy = [];
      }
    } else {
      copy = cloneDeep(props.options || []);
    }

    const copyValue = cloneDeep(props.value || []);

    const filteredOptions = copy.filter((o) => {
      if (o.isDisabled) {
        return false;
      }
      return (
        isIncludingString(inputValue, o) && !valuesIncludeOption(copyValue, o)
      );
    });
    const final = [...copyValue, ...filteredOptions];
    const uniqueArray = removeDuplicatesFromArray(final);
    if (props.onChange) {
      props.onChange(uniqueArray);
    }
  };

  const propsCustom = {
    isFocused: isFocused || undefined,
    menuIsOpen: isFocused || undefined,
  };

  // prepare all props and exclude unwanted
  const {
    captureMenuScroll, // exclude, we need this to be false
    placeholder, // exclude
    noOptionsMessage, // exclude
    onChange, // exclude
    onInputChange, // exclude
    isSearchable, // exclude
    classNamePrefix, // exclude
    menuPortalTarget, // disabled for now, not working
    className, // is used on outer container
    usePortaling,
    styles,
    flexStylesContainer,
    noDelete,
    isCreatable,

    ...cleanProps
  } = props;

  let customStyles: Styles<OptionTypeBase, boolean> = {
    control: (base, more) => ({
      ...base,
      '&:hover:not(:disabled)': {
        borderColor: '#B2B7C0', // gray-500
      },
      borderColor: more.isFocused ? '#2F50C3' : '#D4D6DC', // blue-600, neutral-gray-300
      backgroundColor: more.isDisabled ? '#F5F6F7' : 'white', // disabled = neutral-gray-100
      color: defaultTextColor,
      outline: 'none',
      borderRadius: '2px',
      boxShadow: 'none', // no box-shadow
      minHeight: '40px',
    }),
    placeholder: (base) => ({
      ...base,
      color: '#D4D6DC', // neutral-gray-300
    }),
    option: (base, more) => ({
      ...base,
      color: defaultTextColor,
      cursor: more.isDisabled ? 'not-allowed' : 'default',
      backgroundColor: more.isDisabled
        ? '#F5F6F7'
        : more.isFocused
        ? '#D8DEF6'
        : 'white', // disabled = neutral-gray-100
      '&:hover': {
        backgroundColor: more.isDisabled ? undefined : '#E8ECFA', // blue-50
      },
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0 6px',
    }),
  };

  if (usePortaling) {
    customStyles.menuPortal = (base) => {
      const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
      return { ...rest, zIndex: 99999 };
    };
  }

  if (flexStylesContainer) {
    customStyles.container = (base) => ({
      ...base,
      flex: 1,
    });
  }
  if (styles) {
    customStyles = {
      ...customStyles,
      ...styles,
    };
  }

  if (noDelete) {
    customStyles.multiValueRemove = (base) => ({ ...base, display: 'none' });
  }

  return (
    <div
      ref={containerRef}
      className={className}
      data-testid="sitelife-select-wrapper"
    >
      {isCreatable ? (
        <CreatableSelect
          classNamePrefix="select"
          styles={customStyles}
          menuPortalTarget={usePortaling ? document.body : undefined}
          {...cleanProps}
          // pass select all method so we can use it in menu list
          selectAll={selectAll}
          formatCreateLabel={(value) =>
            `${t('selectSitelife.create')} "${value}"`
          }
          components={{
            ...customComponents,
            ...props.components,
          }}
          // from example
          inputValue={inputValue}
          onMenuInputFocus={inputFocus}
          onChange={onChangeCustom}
          onInputChange={onInputChangeCustom}
          {...propsCustom}
          // bugfix
          captureMenuScroll={false}
          // translations
          placeholder={inputValue ? '' : t('selectSitelife.selectOrCreate')}
          noOptionsMessage={() => t('selectSitelife.noSelection')}
        />
      ) : (
        <Select
          classNamePrefix="select"
          styles={customStyles}
          menuPortalTarget={usePortaling ? document.body : undefined}
          {...cleanProps}
          // pass select all method so we can use it in menu list
          selectAll={selectAll}
          components={{
            ...customComponents,
            ...props.components,
          }}
          // from example
          inputValue={inputValue}
          isSearchable={false}
          onMenuInputFocus={inputFocus}
          onChange={onChangeCustom}
          onInputChange={onInputChangeCustom}
          {...propsCustom}
          // bugfix
          captureMenuScroll={false}
          placeholder={t('selectSitelife.select')}
          noOptionsMessage={() => t('selectSitelife.noSelection')}
        />
      )}
    </div>
  );
};
