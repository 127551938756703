import { faMegaphone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';

import { HeaderButton } from './HeaderButton';

window.beamer_config = {
  product_id: import.meta.env.VITE_BEAMER_ID,
  selector: '#beamer_el',
};

export const Beamer = forwardRef<HTMLButtonElement>(
  function Beamer(props, ref) {
    return (
      <>
        <Helmet>
          <script
            type="text/javascript"
            src="https://app.getbeamer.com/js/beamer-embed.js"
            defer
          />
        </Helmet>
        <HeaderButton id="beamer_el" ref={ref} {...props}>
          <FontAwesomeIcon
            icon={faMegaphone}
            size="lg"
            className="p-1.5"
            fixedWidth
          />
        </HeaderButton>
      </>
    );
  },
);
