import { t } from 'i18next';
import { ReactNode } from 'react';

import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';

type DeleteModalProps = {
  title: string;
  subtitle?: React.ReactNode;
  onClose: () => void;
  deleteButton: () => void;
  isOpen: boolean;
  children?: ReactNode;
};

export const DeleteModal = ({
  title,
  subtitle,
  onClose,
  deleteButton,
  isOpen,
  children,
}: DeleteModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Delete-File-Confirmation"
      className="flex w-[432px] flex-col"
    >
      <Modal.Header onClose={onClose}>{title}</Modal.Header>
      <h3 className='text-gray-600" mt-2 px-6 font-normal'>{subtitle}</h3>
      <Modal.Body>
        {children}
        <div className="flex justify-end space-x-2">
          <Button variant="tertiary" onClick={onClose}>
            {t('common:button.abort')}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              deleteButton();
            }}
          >
            {t('common:button.delete')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
