import { SheetSet } from './types';
import { api } from 'src/common/fetch';

export async function getSheetSets(projectId: string): Promise<SheetSet[]> {
  return api.get<SheetSet[]>(`/api/projects/${projectId}/sheetsets`);
}

export async function createSheetSet(
  projectId: string,
  sheetSetData: Pick<SheetSet, 'name' | 'views'>,
) {
  return api.post<SheetSet>(
    `/api/projects/${projectId}/sheetsets`,
    sheetSetData,
  );
}

export async function updateSheetSet(
  projectId: string,
  sheetSetId: string,
  sheetSetData: Pick<SheetSet, 'name' | 'views'>,
) {
  return api.patch<SheetSet>(
    `/api/projects/${projectId}/sheetsets/${sheetSetId}`,
    sheetSetData,
  );
}

export function deleteSheetSet(projectId: string, sheetSetId: string) {
  return api.delete(`/api/projects/${projectId}/sheetsets/${sheetSetId}`);
}
