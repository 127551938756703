import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Button } from 'src/common/components/buttons/Button';
import { SidebarLayout } from 'src/common/components/SidebarLayout';
import { TechCrewForm } from '../components/TechCrew/Form';
import { TechCrewSidebar } from '../components/TechCrewSidebar';
import { TechCrew } from '../types/techCrews';

interface Props {
  techCrew: TechCrew;
  isFreeProject: boolean;
  is3dProject: boolean;
  disabled?: boolean;
}

export const TechCrewSettingsView = ({
  techCrew,
  isFreeProject,
  is3dProject,
  disabled,
}: Props) => {
  const { t } = useTranslation('project');
  const { projectId = '' } = useParams();

  return (
    <SidebarLayout
      header={<Header techCrew={techCrew} />}
      headerLeft={
        <Link to="../../techCrews">
          <Button variant="tertiary">
            <FontAwesomeIcon icon={faArrowLeft} />
            <span>{t('techCrewEdit.allTrades')}</span>
          </Button>
        </Link>
      }
      sidebar={<TechCrewSidebar />}
    >
      <div className="bg-white p-6 shadow">
        <TechCrewForm
          techCrew={techCrew}
          projectId={projectId}
          isFreeProject={isFreeProject}
          is3dProject={is3dProject}
          disabled={disabled}
        />
      </div>
    </SidebarLayout>
  );
};

const Header = ({ techCrew }: { techCrew: TechCrew }) => {
  return (
    <div className="flex min-h-[40px] items-center">
      <div
        style={{ backgroundColor: techCrew.color }}
        className="h-4 w-4 rounded"
      />
      <h1 className="ml-2 flex text-2xl font-semibold text-shuttleGray-800">
        {techCrew.name}
      </h1>
    </div>
  );
};
