import {
  IconDefinition,
  faCheckSquare,
  faCog,
  faEye,
  faFileExport,
  faPlusSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UseFormRegisterReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'src/common/components/inputs/Checkbox';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';
import { useProject } from 'src/project/project-context';
import { LicenseType } from 'src/project/types/projects';
import { TechCrewPermission } from 'src/project/types/techCrews';

type Props = {
  permissions: TechCrewPermission[];
  isOpen: boolean;
  isAdmin: boolean;
  onClose: () => void;
  onSubmit: (newPermissions: TechCrewPermission[]) => Promise<void>;
};

type FormState = {
  viewDocumentation: boolean;
  editDocumentation: boolean;
  controlDocumentation: boolean;
  exportDataDocumentation: boolean;
  viewAnalysis: boolean;
  editAnalysis: boolean;
};

export const EditPermissionsModal = ({
  permissions,
  isOpen,
  isAdmin,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation(['common', 'project']);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm<FormState>({
    defaultValues: {
      viewDocumentation: permissions.includes(
        TechCrewPermission.VIEW_DOCUMENTATION,
      ),
      editDocumentation: permissions.includes(
        TechCrewPermission.EDIT_DOCUMENTATION,
      ),
      controlDocumentation: permissions.includes(
        TechCrewPermission.CONTROL_DOCUMENTATION,
      ),
      exportDataDocumentation: permissions.includes(
        TechCrewPermission.EXPORT_DATA_DOCUMENTATION,
      ),
      viewAnalysis: permissions.includes(TechCrewPermission.VIEW_ANALYSIS),
      editAnalysis: permissions.includes(TechCrewPermission.EDIT_ANALYSIS),
    },
  });

  // header checkbox: checked if all documentation permissions are checked
  const isDocumentationChecked =
    watch('viewDocumentation') &&
    watch('editDocumentation') &&
    watch('controlDocumentation') &&
    watch('exportDataDocumentation');
  // header checkbox: indeterminate if some documentation permissions are checked
  const isDocumentationIndeterminate = watch('viewDocumentation')
    ? !watch('editDocumentation') ||
      !watch('controlDocumentation') ||
      !watch('exportDataDocumentation')
    : watch('editDocumentation') ||
      watch('controlDocumentation') ||
      watch('exportDataDocumentation');
  watch('exportDataDocumentation');
  // view checkbox: enforced if edit is checked
  const needsViewDocumentation =
    watch('editDocumentation') ||
    watch('controlDocumentation') ||
    watch('exportDataDocumentation');
  watch('exportDataDocumentation');

  // header checkbox: checked if all analysis permissions are checked
  const isAnalysisChecked = watch('viewAnalysis') && watch('editAnalysis');
  // header checkbox: indeterminate if some analysis permissions are checked
  const isAnalysisIndeterminate = watch('viewAnalysis')
    ? !watch('editAnalysis')
    : watch('editAnalysis');
  // view checkbox: enforced if edit is checked
  const needsViewAnalysis = watch('editAnalysis');

  function onSave(state: FormState) {
    const newPermissions = [
      ...(needsViewDocumentation || state.viewDocumentation
        ? [TechCrewPermission.VIEW_DOCUMENTATION]
        : []),
      ...(state.editDocumentation
        ? [TechCrewPermission.EDIT_DOCUMENTATION]
        : []),
      ...(state.controlDocumentation
        ? [TechCrewPermission.CONTROL_DOCUMENTATION]
        : []),
      ...(state.exportDataDocumentation
        ? [TechCrewPermission.EXPORT_DATA_DOCUMENTATION]
        : []),
      ...(needsViewAnalysis || state.viewAnalysis
        ? [TechCrewPermission.VIEW_ANALYSIS]
        : []),
      ...(state.editAnalysis ? [TechCrewPermission.EDIT_ANALYSIS] : []),
    ];
    onSubmit(newPermissions);
  }

  const currentProject = useProject();

  const isFreeProject = currentProject.license.type === LicenseType.FREE;

  return (
    <Modal
      className="flex min-w-[700px] flex-col space-y-6"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <Modal.Header onClose={onClose}>
        {t('project:techCrewEdit.membersView.editPermissions.title')}
      </Modal.Header>
      <form onSubmit={handleSubmit(onSave)}>
        <div className="flex flex-col justify-between space-y-4 bg-gray-100 p-6">
          {isAdmin && (
            <InlineMessage variant="warning">
              {t('project:techCrewEdit.membersView.editPermissions.adminInfo')}
            </InlineMessage>
          )}
          <ul className="flex flex-col space-y-[1px]">
            <PermissionListHeader
              label={t(
                'project:techCrewEdit.membersView.editPermissions.documentation',
              )}
              checked={isDocumentationChecked}
              disabled={isAdmin}
              indeterminate={isDocumentationIndeterminate}
              onClick={(flag) => {
                setValue('viewDocumentation', flag, {
                  shouldDirty: true,
                });
                setValue('editDocumentation', flag, {
                  shouldDirty: true,
                });
                setValue('controlDocumentation', flag, {
                  shouldDirty: true,
                });
                setValue('exportDataDocumentation', flag, {
                  shouldDirty: true,
                });
              }}
            />
            {needsViewDocumentation ? (
              <PermissionsListItem
                disabled={true}
                checkedWhenDisabled={true}
                label={t(
                  'project:techCrewEdit.membersView.editPermissions.viewDocumentation',
                )}
                icon={faEye}
              />
            ) : (
              <PermissionsListItem
                disabled={isAdmin}
                checkedWhenDisabled={watch('viewDocumentation')}
                label={t(
                  'project:techCrewEdit.membersView.editPermissions.viewDocumentation',
                )}
                icon={faEye}
                register={register('viewDocumentation')}
              />
            )}
            <PermissionsListItem
              disabled={isAdmin}
              checkedWhenDisabled={watch('editDocumentation')}
              label={t(
                'project:techCrewEdit.membersView.editPermissions.editDocumentation',
              )}
              icon={faPlusSquare}
              register={register('editDocumentation')}
            />
            {!isFreeProject && (
              <PermissionsListItem
                disabled={isAdmin}
                checkedWhenDisabled={watch('controlDocumentation')}
                label={t(
                  'project:techCrewEdit.membersView.editPermissions.controlDocumentation',
                )}
                icon={faCheckSquare}
                register={register('controlDocumentation')}
              />
            )}
            <PermissionsListItem
              disabled={isAdmin}
              checkedWhenDisabled={watch('exportDataDocumentation')}
              label={t(
                'project:techCrewEdit.membersView.editPermissions.exportDataDocumentation',
              )}
              icon={faFileExport}
              register={register('exportDataDocumentation')}
            />
          </ul>
          {!isFreeProject && (
            <ul className="flex flex-col space-y-[1px]">
              <PermissionListHeader
                label={t(
                  'project:techCrewEdit.membersView.editPermissions.analysis',
                )}
                checked={isAnalysisChecked}
                disabled={isAdmin}
                indeterminate={isAnalysisIndeterminate}
                onClick={(flag) => {
                  setValue('viewAnalysis', flag, {
                    shouldDirty: true,
                  });
                  setValue('editAnalysis', flag, { shouldDirty: true });
                }}
              />
              {needsViewAnalysis ? (
                <PermissionsListItem
                  disabled={true}
                  checkedWhenDisabled={true}
                  label={t(
                    'project:techCrewEdit.membersView.editPermissions.viewAnalysis',
                  )}
                  icon={faEye}
                />
              ) : (
                <PermissionsListItem
                  disabled={isAdmin}
                  checkedWhenDisabled={watch('viewAnalysis')}
                  label={t(
                    'project:techCrewEdit.membersView.editPermissions.viewAnalysis',
                  )}
                  icon={faEye}
                  register={register('viewAnalysis')}
                />
              )}
              <PermissionsListItem
                disabled={isAdmin}
                checkedWhenDisabled={watch('editAnalysis')}
                label={t(
                  'project:techCrewEdit.membersView.editPermissions.editAnalysis',
                )}
                icon={faCog}
                register={register('editAnalysis')}
              />
            </ul>
          )}
        </div>
        <div className="flex h-full items-center justify-end p-6">
          <Button className="mr-4" variant="tertiary" onClick={onClose}>
            {t('common:button.abort')}
          </Button>
          <Button type="submit" disabled={!isDirty}>
            {t('common:button.save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export const PermissionListHeader = ({
  label,
  checked,
  indeterminate,
  onClick,
  disabled,
}: {
  label: string;
  checked: boolean;
  indeterminate?: boolean;
  onClick?: (flag: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <li className="flex justify-between bg-white p-4 shadow">
      <span className="font-bold">{label}</span>
      <Checkbox
        onChange={() => onClick && onClick(!checked)}
        checked={checked}
        indeterminate={indeterminate}
        disabled={disabled}
      />
    </li>
  );
};

const PermissionsListItem = ({
  label,
  icon,
  disabled = false,
  register,
  checkedWhenDisabled,
}: {
  label: string;
  icon: IconDefinition;
  disabled?: boolean;
  register?: UseFormRegisterReturn;
  checkedWhenDisabled?: boolean;
}) => {
  return (
    <li className="flex justify-between bg-white p-4 shadow">
      <div>
        <FontAwesomeIcon icon={icon} className="text-shuttleGray-500" />
        <span className="ml-3">{label}</span>
      </div>
      {disabled ? (
        <Checkbox checked={checkedWhenDisabled} disabled={true} />
      ) : (
        <Checkbox {...register} />
      )}
    </li>
  );
};
