import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from 'src/common/utils';

type Props = React.ComponentPropsWithoutRef<'a'> & {
  children: React.ReactNode;
  href: string;
  className?: string;
  disabled?: boolean;
};

export const ExternalTextLink = ({
  children,
  href,
  className,
  disabled,
  ...rest
}: Props) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    {...rest}
    className={cn(
      'inline-flex items-center gap-1 font-medium',
      className,
      disabled
        ? 'cursor-default text-gray-400'
        : 'text-blue-600 hover:text-blue-700 hover:underline focus:text-blue-800',
    )}
  >
    {children}
    <FontAwesomeIcon icon={faExternalLink} size="xs" />
  </a>
);
