import { useTranslation } from 'react-i18next';
import {
  useMatch,
  LinkProps,
  useResolvedPath,
  Link,
  useParams,
} from 'react-router-dom';

import { cn } from 'src/common/utils';
import { Badge } from 'src/common/components/Badge';

export const PrimaryNav = ({
  isDemoProject,
  hasDocumentationViewingPermissions,
  hasAnalysisViewingPermissions,
}: {
  isDemoProject: boolean;
  hasDocumentationViewingPermissions: boolean;
  hasAnalysisViewingPermissions: boolean;
}) => {
  const { projectId = '' } = useParams();
  const { t } = useTranslation(['app', 'common']);

  return (
    <div className="flex h-full">
      <PrimaryNavLink to={`/p/${projectId}/dashboard`}>
        <div className="flex items-center gap-2">
          {t('appbar.project')}
          {isDemoProject && <Badge variant="teal">{t('common:demo')}</Badge>}
        </div>
      </PrimaryNavLink>
      {hasDocumentationViewingPermissions && (
        <PrimaryNavLink to={`/p/${projectId}/conrep`}>
          {t('appbar.btb')}
        </PrimaryNavLink>
      )}
      {hasAnalysisViewingPermissions && (
        <PrimaryNavLink to={`/p/${projectId}/a`}>
          {t('appbar.analysis')}
        </PrimaryNavLink>
      )}
    </div>
  );
};

const PrimaryNavLink = ({ children, to, ...rest }: LinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: `${resolved.pathname}/*` });

  return (
    <Link
      to={to}
      {...rest}
      className={cn(
        'flex h-full items-center border-b-2 px-6 hover:bg-white/10 focus:text-blue-200 focus:outline-none',
        match ? 'border-white' : 'border-transparent',
      )}
    >
      {children}
    </Link>
  );
};
