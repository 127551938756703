import { useTranslation } from 'react-i18next';
import { Link, useMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

import { Button } from 'src/common/components/buttons/Button';
import { Container } from 'src/common/components/Container';
import { NotFound } from 'src/common/components/NotFound';

export const NotFoundInProjects = () => {
  const { t } = useTranslation('project');
  const match = useMatch({ path: '/p/:projectId/*' });

  return (
    <Container className="mt-8" variant="box">
      <NotFound
        action={
          match && (
            <Link to={match.pathnameBase}>
              <Button>
                <FontAwesomeIcon icon={faArrowLeft} />
                {t('notFound.backToOverview')}
              </Button>
            </Link>
          )
        }
      />
    </Container>
  );
};
