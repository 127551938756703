import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faBuilding, faMap } from '@fortawesome/pro-regular-svg-icons';
import { faCube } from '@fortawesome/pro-duotone-svg-icons';
import { useParams } from 'react-router-dom';

import { cn } from 'src/common/utils';
import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';
import { FileVersionView } from 'src/file/types';
import { isSameViewFromSameFile } from 'src/file/util';
import { isKeyType } from '../../utils';
import { GroupedViewerModelOptions, KeyTypes } from '../../types';
import { useFilesQuery } from 'src/file/queries';

type Props = {
  views: FileVersionView[];
  selectedViewId?: string;
  onViewChange: (v: FileVersionView) => void;
};

const getIconForKey = (key: string): FontAwesomeIconProps['icon'] => {
  switch (key) {
    case KeyTypes._3D:
      return faBuilding;
    case KeyTypes._2D:
      return faMap;
    default:
      return faCube;
  }
};

function getViewOptions(views: FileVersionView[]) {
  const grouped: GroupedViewerModelOptions = {
    [KeyTypes._3D]: [],
    [KeyTypes._2D]: [],
    other: [],
  };
  views.forEach((o) => {
    switch (o.role) {
      case KeyTypes._3D:
        grouped[KeyTypes._3D].push(o);
        break;
      case KeyTypes._2D:
        grouped[KeyTypes._2D].push(o);
        break;
      default:
        grouped[KeyTypes.OTHER].push(o);
        break;
    }
  });
  return grouped;
}

export const ForgeViewerViewSelector = ({
  views,
  selectedViewId,
  onViewChange,
}: Props) => {
  const { t } = useTranslation('forge');
  const { projectId = '' } = useParams();
  const { data: files } = useFilesQuery(projectId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedView = views.find((v) => v._id === selectedViewId);
  const groupedOptions = getViewOptions(views);

  const getTitleForKey = (key: KeyTypes): string => {
    switch (key) {
      case KeyTypes._3D:
        return t('header.viewSelector.3d');
      case KeyTypes._2D:
        return t('header.viewSelector.2d');
      default:
        return t('header.viewSelector.others');
    }
  };

  const handleViewChange = (view: FileVersionView) => {
    setIsModalOpen(false);
    onViewChange(view);
  };

  return (
    <>
      <div className="flex items-center space-x-2">
        <span className="text-sm font-bold">{selectedView?.name} </span>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => setIsModalOpen(true)}
        >
          {t('header.selectView')}
        </Button>
      </div>
      <Modal isOpen={isModalOpen}>
        <div className="flex w-[768px] flex-col overflow-hidden xl:w-[864px]">
          <Modal.Header onClose={() => setIsModalOpen(false)}>
            {t('header.viewSelector.selectView')}
          </Modal.Header>
          <div className="overflow-y-auto pb-4 pt-4">
            {groupedOptions &&
              Object.keys(groupedOptions).map((key) => (
                <div
                  key={key}
                  className={cn({
                    hidden: isKeyType(key) && groupedOptions[key].length === 0,
                    'mt-4 first:mt-0':
                      isKeyType(key) && groupedOptions[key].length !== 0,
                  })}
                >
                  <div className="flex items-center pb-2 pl-2">
                    <div className="flex w-12 items-center justify-center">
                      <FontAwesomeIcon icon={getIconForKey(key)} />
                    </div>
                    <span className="text-sm font-semibold text-gray-800">
                      {isKeyType(key) && getTitleForKey(key)}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    {isKeyType(key) &&
                      groupedOptions[key].map((view) => {
                        const fileName = files?.find(
                          (file) => file._id === view.file,
                        )?.name;
                        return (
                          <button
                            key={view.guid}
                            className={cn(
                              'cursor-pointer py-2 pl-14 pr-5 text-left hover:bg-gray-300',
                              {
                                'pointer-events-none font-bold text-blue-600':
                                  isSameViewFromSameFile(selectedView, view),
                              },
                            )}
                            onClick={() => handleViewChange(view)}
                          >
                            {fileName ? fileName + ' - ' : ''}
                            {view.name}
                          </button>
                        );
                      })}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </>
  );
};
