import { HTMLAttributes, ReactNode } from 'react';

import { cn } from 'src/common/utils';

type Props = {
  label: ReactNode;
  variant?: 'gray';
  size?: 'sm' | 'lg';
};

export const Fact = ({
  label,
  variant,
  size = 'sm',
  children,
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement> & Props) => (
  <div
    className={cn(
      `flex flex-col py-2`,
      [
        {
          'px-3': size === 'sm',
          'px-4': size === 'lg',
          'bg-gray-100': variant === 'gray',
        },
      ],
      className,
    )}
    {...rest}
  >
    <div className={cn('text-shuttleGray-600', [{ 'text-sm': size === 'sm' }])}>
      {label}
    </div>
    <div className={cn([{ 'text-4xl': size === 'lg' }])}>{children}</div>
  </div>
);
