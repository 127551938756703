import dayjs from 'dayjs';
import { Embed, models, Report, VisualDescriptor } from 'powerbi-client';

import { HashMap } from 'src/Forge/hooks';
import { Tag } from 'src/project/types/projects';
import {
  LvBasedHours,
  TagBasedHours,
  TechCrew,
} from 'src/project/types/techCrews';
import { OnlvSuggestion, Suggestion } from './types';

export function getEarliestDateOfArray(
  dates: (string | undefined)[],
): string | undefined {
  return dates.reduce((acc, curr) => {
    if (acc) {
      if (curr) {
        return dayjs(acc).isBefore(curr) ? acc : curr;
      }
      return acc;
    }
    return curr;
  }, undefined);
}

export function getLatestDateOfArray(
  dates: (string | undefined)[],
): string | undefined {
  return dates.reduce((acc, curr) => {
    if (acc) {
      if (curr) {
        return dayjs(acc).isAfter(curr) ? acc : curr;
      }
      return acc;
    }
    return curr;
  }, undefined);
}

export function isOnlvSuggestion(
  suggestions: Suggestion | Suggestion[],
): suggestions is OnlvSuggestion | OnlvSuggestion[] {
  if (!Array.isArray(suggestions)) {
    return (suggestions as OnlvSuggestion)?.value !== undefined;
  }
  return (suggestions[0] as OnlvSuggestion)?.value !== undefined;
}

export function techCrewHoursAvailableInServiceSpecification(
  lvBasedHours: LvBasedHours[],
  suggestions: OnlvSuggestion[],
): LvBasedHours[] {
  const lvSet = new Set(lvBasedHours.map((lv) => lv.lvPath));

  function traverse(s: OnlvSuggestion) {
    if (lvSet.has(s.value)) {
      lvSet.delete(s.value);
    }

    if (s.children) {
      s.children.forEach(traverse);
    }
  }

  suggestions.forEach(traverse);

  return lvBasedHours.filter((lv) => lvSet.has(lv.lvPath));
}

export function unavailableTagsInTagList(
  tags: Tag[] | undefined,
  tagBasedHours: { [tagId: string]: number },
): TagBasedHours[] {
  // check if there are entries in the TagListBasedHours Settings that do not appear in the TagList
  if (!tags) {
    return [];
  }

  // get all the tags that have ours on them but are not in the list anymore
  const unavailableTags = Object.keys(tagBasedHours).filter(
    (plannedTag) =>
      !tags.some((availableTag) => availableTag._id === plannedTag),
  );

  return unavailableTags.map((tagId) => {
    return { tag: tagId, plannedHours: tagBasedHours[tagId] };
  });
}

export function numberWithDots(x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function sleep(ms: number): Promise<unknown> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function removeHashedElementId(hashedElementId: string): string {
  const parts = hashedElementId.split('_');
  parts.pop();
  return parts.join('_');
}

export async function getPowerBiVisualData(
  title: string,
  reportContainer: Embed | undefined,
) {
  if (!reportContainer) {
    return;
  }
  const pages = await (reportContainer as Report).getPages();
  const activePage = pages.filter((page) => page.isActive)[0];
  const visuals = await activePage.getVisuals();
  const powerBiVisual = visuals.filter((visual) => visual.title === title)[0];
  return powerBiVisual;
}

export async function getTechCrewIdFromPowerBiVisual(
  powerBiVisual: VisualDescriptor | undefined,
  techCrews: TechCrew[],
) {
  if (!powerBiVisual) {
    return;
  }

  const slicerState = await powerBiVisual.getSlicerState();

  if (!slicerState.filters) {
    return;
  }

  const techCrewDict = slicerState.filters[0] as any as Record<string, string>;

  if (!techCrewDict.values) {
    return;
  }

  const techCrew = techCrews.find((tc) => tc.name === techCrewDict.values[0]);

  if (!techCrew) {
    return;
  }

  return techCrew._id;
}

export function extractExportedVisualData(
  exportData: models.IExportDataResult,
) {
  /* Transform raw exported data to external IDs string and check if color information is available,
      store that on specific containers and return them
      */

  // Remove Column Title
  const externalIdString = exportData.data.substring(
    exportData.data.indexOf('\n') + 1,
  );
  const externalIdArray: string[] = externalIdString.split('\r\n');
  externalIdArray.pop();
  const extIds: string[] = [];
  const extIdColorDict: HashMap<string> = {};

  externalIdArray.forEach(function (rowItem) {
    const splittedRowItem: string[] = rowItem.split(',');
    if (splittedRowItem.length > 1) {
      if (splittedRowItem[1].length > 1) {
        const color = splittedRowItem[1];
        extIdColorDict[removeHashedElementId(splittedRowItem[0])] = color;
      }
    }
    extIds.push(removeHashedElementId(splittedRowItem[0]));
  });

  return { extIds, extIdColorDict };
}

async function createHash(externalId: string) {
  const textAsBuffer = new TextEncoder().encode(externalId);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray
    .map((item) => item.toString(16).padStart(2, '0'))
    .join('');
  return hash;
}

export async function getHashedElementIdOfExternalId(externalId: string) {
  const paths = externalId.split('/');
  const elementId = paths[paths.length - 1];
  const hashedElementId = await createHash(elementId);
  return `${elementId}_${hashedElementId}`;
}
