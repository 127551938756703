import { User } from 'src/user/types';
import { DownloadRef, PaginatedResponse } from 'src/common/types';
import { api } from 'src/common/fetch';

import type {
  PatchCompanyMembersPayload,
  UpdateCompanyPayload,
  CreateCompanyPayload,
  CreateCompanyResponse,
  CompanyPopulatedUser,
  OwnerPopulatedCompany,
} from 'shared';

export async function getCompanyUsersAsAdmin(id: string) {
  return api.get<User[]>(`/api-admin/companies/${id}/users`);
}

export async function getCompanies(
  page: number,
  entriesPerPage: number,
  searchBy?: string,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
) {
  const params = {
    page: page.toString(),
    entriesPerPage: entriesPerPage.toString(),
    ...(searchBy && { searchBy }),
    ...(sortBy && { sortBy }),
    ...(sortOrder && { sortOrder: sortOrder?.toString() }),
  };
  const searchParams = new URLSearchParams(params);

  return api.get<PaginatedResponse<OwnerPopulatedCompany>>(
    `/api-admin/companies?${searchParams.toString()}`,
  );
}

export async function createCompany(company: CreateCompanyPayload) {
  return api.post<CreateCompanyResponse>('/api-admin/companies', company);
}

export async function updateCompany(
  company: UpdateCompanyPayload,
  companyId: string,
) {
  return api.patch<CompanyPopulatedUser>(
    `/api-admin/companies/${companyId}`,
    company,
  );
}

export async function updateCompanyMembers(
  members: PatchCompanyMembersPayload,
  companyId: string,
) {
  return api.patch<CompanyPopulatedUser>(
    `/api-admin/companies/${companyId}/users`,
    members,
  );
}

export async function deleteCompany(companyId: string) {
  return api.delete(`/api-admin/companies/${companyId}`);
}

export async function removeUserFromCompany(companyId: string, userId: string) {
  return api.delete(`/api-admin/companies/${companyId}/users/${userId}`);
}

export async function getCompanyImage(companyId: string) {
  return api.get<DownloadRef>(`/api-admin/companies/${companyId}/image`);
}
