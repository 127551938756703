import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchSort } from 'src/common/components/SearchSort';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { CompanyInvitationItem } from 'src/company/components/CompanyInvitationItem';
import { CompanyUserItem } from 'src/company/components/CompanyUserItem';
import { User } from 'src/user/types';
import { isCompanyOwnerOrAdmin } from 'src/user/utils';
import { useCompanyInvitationsQuery, useCompanyUsersQuery } from '../queries';
import { Company } from '../types';
import { getCompanyRole } from '../utils';

type Props = {
  company: Company;
  user: User;
};

// Sort ProjectUser entities based on a specific attribute and sort order
function sortUsersByAttribute(
  usersList: User[],
  attribute: string,
  sortUsersOrder: string,
  company: Company,
): User[] {
  const sortedUsers = usersList.slice().sort((a, b) => {
    let aValue;
    let bValue;
    switch (attribute) {
      case 'name':
        aValue = a.name;
        bValue = b.name;
        break;
      case 'surname':
        aValue = a.surname;
        bValue = b.surname;
        break;
      case 'email':
        aValue = a.email;
        bValue = b.email;
        break;
      case 'role':
        aValue = getCompanyRole(company, a) ? getCompanyRole(company, a) : '';
        bValue = getCompanyRole(company, b) ? getCompanyRole(company, b) : '';
        break;
      default:
        aValue = '';
        bValue = '';
        break;
    }

    // Perform sorting based on sortOrder
    if (sortUsersOrder === 'asc') {
      if (aValue < bValue) {
        return -1;
      } else if (aValue > bValue) {
        return 1;
      }
      return 0;
    } else {
      if (aValue > bValue) {
        return -1;
      } else if (aValue < bValue) {
        return 1;
      }
      return 0;
    }
  });

  return sortedUsers;
}

// Filter the sorted list based on an entered string
function filterSortedUsers(sortedUsers: User[], searchString: string): User[] {
  return sortedUsers.filter((user) => {
    const lowerSearchString = searchString.toLowerCase();
    const lowerName = user.name.toLowerCase();
    const lowerSurname = user.surname.toLowerCase();
    const lowerEmail = user.email.toLowerCase();

    return (
      lowerName.includes(lowerSearchString) ||
      lowerSurname.includes(lowerSearchString) ||
      lowerEmail.includes(lowerSearchString)
    );
  });
}

export const CompanyUsersView = ({ company, user: currentUser }: Props) => {
  const { t } = useTranslation(['company', 'common']);
  const { data: companyUsers } = useCompanyUsersQuery(company._id);
  const isAdminOrOwner = isCompanyOwnerOrAdmin(currentUser._id, company);

  const companyRole = getCompanyRole(company, currentUser);

  const [searchUsersBy, setSearchUsersBy] = useState('');
  const [sortUsersOrder, setSortUsersOrder] = useState<'asc' | 'desc'>('asc');
  const [sortUsersBy, setSortUsersBy] = useState('email');

  const sortOptions: SelectSitelifeOptionType[] = [
    { label: 'Email', value: 'email' },
    { label: t('common:firstName'), value: 'name' },
    { label: t('common:surname'), value: 'surname' },
    { label: t('company:users.role'), value: 'role' },
  ];

  const arrangedCompanyUsersList = companyUsers
    ? filterSortedUsers(
        sortUsersByAttribute(
          companyUsers,
          sortUsersBy,
          sortUsersOrder,
          company,
        ),
        searchUsersBy,
      )
    : [];

  return (
    <>
      <SearchSort
        searchBy={searchUsersBy}
        sortBy={
          sortOptions.find((sortOption) => sortOption.value === sortUsersBy) ||
          sortOptions[0]
        }
        sortOrder={sortUsersOrder}
        sortOptions={sortOptions}
        onSearchBy={(newSearchBy) => setSearchUsersBy(newSearchBy)}
        onSortBy={(newSortBy) => setSortUsersBy(newSortBy)}
        onSortOrder={(newSortOrder) => setSortUsersOrder(newSortOrder)}
        className="mr-4"
      />
      <table className="divide-neutralGray-200 relative mt-8 w-full divide-y shadow">
        <thead>
          <tr>
            <th scope="col" className="table-th">
              {t('company:users.person')}
            </th>
            <th scope="col" className="table-th hidden lg:block">
              {t('company:users.position')}
            </th>
            <th scope="col" className="table-th">
              {t('company:users.role')}
            </th>
            <th scope="col" className="table-th">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody className="table-body">
          {arrangedCompanyUsersList.map((member, index) => (
            <CompanyUserItem
              key={index}
              member={member}
              otherMembers={companyUsers.filter((u) => u !== member)}
              userRole={companyRole}
              memberRole={getCompanyRole(company, member)}
              currentUserId={currentUser._id}
              companyId={company._id}
            />
          ))}
          {isAdminOrOwner ? (
            <PendingInvitationsList companyId={company._id} />
          ) : null}
        </tbody>
      </table>
    </>
  );
};

const PendingInvitationsList = ({ companyId }: { companyId: string }) => {
  const { data: pendingInvitations } = useCompanyInvitationsQuery(companyId);

  return (
    <>
      {pendingInvitations.map((invitation) => (
        <CompanyInvitationItem
          companyId={companyId}
          key={`invitation-${invitation._id}`}
          pendingInvitation={invitation}
        />
      ))}
    </>
  );
};
