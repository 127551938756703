import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import toast from 'src/common/toast';
import { Button } from 'src/common/components/buttons/Button';
import { resetPassword } from '../../api';
import { AuthLayout } from '../../components/AuthLayout';
import { SetPasswordForm } from '../../components/SetPasswordForm';

export const ResetPasswordView = () => {
  const { t } = useTranslation('auth');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (password: string) => {
    if (!token) {
      return;
    }

    setIsLoading(true);
    try {
      await resetPassword(password, token);
      setSuccess(true);
    } catch {
      toast.error(t('resetPassword.error'));
    }
    setIsLoading(false);
  };

  return (
    <AuthLayout>
      <div className="px-8">
        {!success ? (
          <SetPasswordForm isLoading={isLoading} onSubmit={handleSubmit} />
        ) : (
          <>
            <h1 className="mb-2 mt-2 text-xl font-semibold">
              {t('resetPassword.textSuccessTitle')}
            </h1>
            <Button
              className="mt-6 w-full"
              variant="secondary"
              onClick={() => navigate('/')}
            >
              {`${t('forgotPasswordForm.toLogin')}`}
            </Button>
          </>
        )}
      </div>
    </AuthLayout>
  );
};
