import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import { Container } from 'src/common/components/Container';
import { Button } from 'src/common/components/buttons/Button';
import { Pagination } from 'src/common/components/Pagination';
import { SearchSort } from 'src/common/components/SearchSort';
import { useAdminProjectsQuery } from '../queries';
import { ProjectRow } from '../components/project/ProjectRow';
import { ProjectForm } from '../components/project/ProjectForm';
import { Card } from 'src/common/components/Card';
import { Modal } from 'src/common/components/Modal';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Spinner } from 'src/common/components/Spinner';

export const ProjectsView = () => {
  const { t } = useTranslation(['admin', 'common']);

  const [searchBy, setSearchBy] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] =
    useState(false);

  const { data: paginatedProjects, isPending } = useAdminProjectsQuery(
    page,
    entriesPerPage,
    searchBy,
    sortBy,
    sortOrder,
  );

  // reset page to 1 when search changes
  useEffect(() => {
    setPage(1);
  }, [searchBy]);

  const sortOptions: SelectSitelifeOptionType[] = [
    { label: t('common:labels.name'), value: 'name' },
    { label: t('common:description'), value: 'description' },
    { label: t('common:organizations'), value: 'company.name' },
    { label: t('common:owner'), value: 'owner.name' },
  ];

  const projects = paginatedProjects ? paginatedProjects.data : [];

  return (
    <Container className="h-full" variant="box">
      <h1 className="text-2xl font-semibold tracking-wide">
        {t('projects.title')}
      </h1>
      <div className="flex w-full items-end">
        <SearchSort
          searchBy={searchBy}
          sortBy={
            sortOptions.find((sortOption) => sortOption.value === sortBy) ||
            sortOptions[0]
          }
          sortOrder={sortOrder}
          sortOptions={sortOptions}
          onSearchBy={(newSearchBy) => setSearchBy(newSearchBy)}
          onSortBy={(newSortBy) => setSortBy(newSortBy)}
          onSortOrder={(newSortOrder) => setSortOrder(newSortOrder)}
          className="mr-4"
        />
        <Button
          className="ml-auto"
          onClick={() => setIsCreateProjectModalOpen(true)}
        >
          <FontAwesomeIcon icon={faPlus} />
          {t('projects.createProject')}
        </Button>
      </div>
      <Card className="mt-6 overflow-auto">
        {isPending ? (
          <Spinner containerClassName="w-full my-[160px] text-center" />
        ) : projects.length > 0 ? (
          <table className="relative w-full divide-y divide-gray-200 shadow">
            <thead>
              <tr>
                <th scope="col" className="table-th">
                  {t('common:labels.name')}
                </th>
                <th scope="col" className="table-th">
                  {t('common:description')}
                </th>
                <th scope="col" className="table-th">
                  {t('common:organization')}
                </th>
                <th scope="col" className="table-th">
                  {t('common:owner')}
                </th>
                <th scope="col" className="table-th xl:table-cell" />
              </tr>
            </thead>
            <tbody className="table-body">
              {projects.map((project) => (
                <ProjectRow project={project} key={project._id} />
              ))}
            </tbody>
          </table>
        ) : (
          <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
            <span className="font-semibold text-shuttleGray-800">
              {`${searchBy}`}
            </span>
            {t('common:notFound.message')}
          </p>
        )}
      </Card>
      {projects.length > 0 && !isPending && paginatedProjects && (
        <Pagination
          maxPage={Math.ceil(paginatedProjects.totalCount / entriesPerPage)}
          page={page}
          onChangePage={setPage}
          entriesPerPage={entriesPerPage}
          onChangeEntriesPerPage={setEntriesPerPage}
          className="mt-4"
        />
      )}
      <Modal
        className="max-w-[781px]"
        isOpen={isCreateProjectModalOpen}
        onRequestClose={() => setIsCreateProjectModalOpen(false)}
      >
        <Modal.Header onClose={() => setIsCreateProjectModalOpen(false)}>
          {t('projects.modal.createTitle')}
        </Modal.Header>
        <ProjectForm onClose={() => setIsCreateProjectModalOpen(false)} />
      </Modal>
    </Container>
  );
};
