import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faUndo } from '@fortawesome/pro-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';

import toast from 'src/common/toast';
import { Button } from 'src/common/components/buttons/Button';
import { DraggableParameterList } from 'src/Forge/components/DraggableParameterList';
import { useResizeForge } from 'src/Forge/hooks';
import { validateLicense } from 'src/project/utils';
import {
  CustomParameter,
  GetProjectResponse,
  LicenseEvaluationType,
} from 'src/project/types/projects';
import { updateProject } from '../../api/projectsApi';
import { ParameterEntry } from './ParameterEntry';
import { CustomParameterNameModal } from './CustomParameterNameModal';

type Props = {
  project: GetProjectResponse;
  parameters: CustomParameter[];
};

export const CustomParameters = ({ project, parameters }: Props) => {
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();

  const [localParameters, setLocalParameters] = useState<CustomParameter[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedParameter, setSelectedParameter] = useState<CustomParameter>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useResizeForge([localParameters]);

  useEffect(() => {
    if (project.customParameters) {
      setLocalParameters(project.customParameters);
    }
  }, [project.customParameters]);

  const handleAddParameter = (parameter: CustomParameter) => {
    const tempParameters = Array.from(localParameters);
    tempParameters.unshift(parameter);
    setLocalParameters(tempParameters);
  };

  const handleSave = async () => {
    if (project._id) {
      setIsLoading(true);
      try {
        await updateProject(project._id, {
          customParameters: localParameters,
        });
        queryClient.invalidateQueries({
          queryKey: ['projects', project._id],
        });
        toast.success(
          t('settings.modelParameter.customParameters.saveSuccess'),
        );
      } catch (err) {
        toast.error(t('settings.modelParameter.customParameters.saveFailure'));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const isDirty = !isEqual(localParameters, project.customParameters);

  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;

  return (
    <div className="flex flex-col gap-4">
      <ParameterEntry
        parameters={parameters}
        localParameters={localParameters}
        onAddParameter={handleAddParameter}
        isDisabled={isLicenseExpired}
      />
      <DraggableParameterList
        items={localParameters.map((localParameter) => ({
          title: localParameter.name,
          subtitle:
            localParameter.type === 'string'
              ? t('project:settings.modelParameter.customParameters.textType')
              : localParameter.type === 'number'
              ? t('project:settings.modelParameter.customParameters.numberType')
              : t(
                  'project:settings.modelParameter.customParameters.booleanType',
                ),
        }))}
        canRemoveItems={!isLicenseExpired}
        setItems={(parameterItems) =>
          setLocalParameters(
            parameterItems
              .map((parameterItem) => {
                const matchingCustomParameter = localParameters.find(
                  (customParameter: CustomParameter) =>
                    customParameter.name === parameterItem.title,
                );
                return matchingCustomParameter
                  ? { ...matchingCustomParameter }
                  : null;
              })
              .filter(
                (customParameter): customParameter is CustomParameter =>
                  customParameter !== null,
              ),
          )
        }
        onEdit={(item) => {
          const res = localParameters.find(
            (element) => element.name === item.title,
          );
          setSelectedParameter(res);
          setIsModalOpen(true);
        }}
      />
      {isDirty && (
        <div className="ml-auto flex items-center gap-4">
          <Button
            variant="tertiary"
            onClick={() =>
              project.customParameters &&
              setLocalParameters(project.customParameters)
            }
          >
            <FontAwesomeIcon icon={faUndo} />
          </Button>
          <Button onClick={() => handleSave()} loading={isLoading}>
            {t('common:button.save')}
          </Button>
        </div>
      )}
      <CustomParameterNameModal
        parameters={localParameters}
        selectedParameter={selectedParameter}
        isOpen={isModalOpen}
        onSave={() => setIsModalOpen(false)}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};
