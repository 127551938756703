import { useQuery } from '@tanstack/react-query';

import {
  getUserCompanies,
  getCompanyUsers,
  getPendingInvitations,
  getCompanyById,
} from './api';

export function useCompanyUsersQuery(companyId: string) {
  return useQuery({
    queryKey: ['companies', companyId, 'users'],
    queryFn: () => getCompanyUsers(companyId),
    initialData: [],
    staleTime: 0,
  });
}

export function useCompaniesQuery() {
  return useQuery({
    queryKey: ['companies'],
    queryFn: getUserCompanies,
  });
}

export function useCompanyQuery(companyId: string) {
  return useQuery({
    queryKey: ['companies', companyId],
    queryFn: () => getCompanyById(companyId),
  });
}

export function useCompanyInvitationsQuery(companyId: string) {
  return useQuery({
    queryKey: ['companies', companyId, 'invitations'],
    queryFn: () => getPendingInvitations(companyId),
    initialData: [],
    staleTime: 0,
  });
}
