import { HTMLAttributes, forwardRef } from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

import { cn } from 'src/common/utils';

type Props = {
  variant?: 'green' | 'orange' | 'red' | 'teal' | 'blue';
  size?: 'sm' | 'md' | 'lg';
  icon?: FontAwesomeIconProps['icon'];
  uppercase?: boolean;
};

export const Badge = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & Props
>(function Badge(
  {
    variant = 'blue',
    icon,
    size = 'sm',
    uppercase = true,
    children,
    className,
    ...rest
  },
  ref,
) {
  return (
    <div
      ref={ref}
      className={cn(
        `inline-flex items-center gap-1 truncate whitespace-nowrap rounded-full px-2 font-medium`,
        [
          {
            'bg-green-50 text-green-600': variant === 'green',
            'bg-orange-50 text-orange-600': variant === 'orange',
            'bg-red-50 text-red-600': variant === 'red',
            'bg-teal-50 text-teal-600': variant === 'teal',
            'bg-blue-50 text-blue-600': variant === 'blue',
          },
          {
            'h-4 text-xs': size === 'sm',
            'h-6 text-sm': size === 'md',
            'h-8 text-base': size === 'lg',
          },
          {
            uppercase: uppercase,
          },
        ],
        className,
      )}
      {...rest}
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      {children}
    </div>
  );
});
