import ReactModal from 'react-modal';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import { cn } from 'src/common/utils';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';

const Modal = ({
  isOpen,
  onRequestClose,
  className,
  overlayClassName,
  children,
  ...rest
}: ReactModal.Props) => {
  if (!isOpen) return null;

  return (
    <ReactModal
      isOpen
      onRequestClose={onRequestClose}
      onAfterOpen={() => {
        const elements = document.getElementsByClassName('ReactModal__Overlay');
        const overlay = elements[elements.length - 1];
        overlay.scrollTop = 0;
      }}
      className={cn(
        'anchor relative rounded bg-white outline-none',
        className?.toString(),
      )}
      overlayClassName={cn(
        'items-start bg-black/50 justify-center left-0 overflow-y-auto fixed top-0 w-full flex anchor h-full p-2',
        overlayClassName?.toString(),
      )}
      {...rest}
    >
      {children}
    </ReactModal>
  );
};

const Header = ({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose?: () => void;
}) => {
  return (
    <div className="flex items-center justify-between px-6 pt-6">
      <h1 className="text-xl font-semibold">{children}</h1>
      {onClose && (
        <ActionIcon onClick={onClose} className="ml-4">
          <FontAwesomeIcon icon={faTimes} />
        </ActionIcon>
      )}
    </div>
  );
};

const Body = ({ children }: { children: React.ReactNode }) => {
  return <div className="p-6">{children}</div>;
};

const Title = ({ children }: { children: React.ReactNode }) => {
  return <h1 className="text-xl font-semibold">{children}</h1>;
};

const Close = ({ onClose }: { onClose: () => void }) => {
  return (
    <ActionIcon onClick={onClose} className="ml-4">
      <FontAwesomeIcon icon={faTimes} />
    </ActionIcon>
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Title = Title;
Modal.Close = Close;

export { Modal };

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  function toggle() {
    setIsOpen(!isOpen);
  }

  return { isOpen, open, close, toggle };
}
