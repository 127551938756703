import { faBook } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import NoServiceSpecificationIllustration from 'src/assets/illu_no-ss.svg';
import { Button } from 'src/common/components/buttons/Button';
import { Card } from 'src/common/components/Card';
import { isProjectOwnerOrAdmin } from 'src/user/utils';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { useUser } from 'src/auth/user-context';
import { useProject } from '../project-context';

export const NoServiceSpecification = () => {
  const { t } = useTranslation('project');
  const navigate = useNavigate();
  const project = useProject();
  const projectId = project._id;
  const user = useUser();

  const canManageServiceSpecifications = isProjectOwnerOrAdmin(
    user._id,
    project,
  );

  return (
    <Card>
      <div className="mx-auto my-6 max-w-[500px] text-center">
        <img
          className="mx-auto mb-8"
          src={NoServiceSpecificationIllustration}
          alt=""
        />
        <h1 className="my-4 text-4xl font-semibold">
          {t('techCrewEdit.noServiceSpecs.title')}
        </h1>
        <div className="mb-6 text-shuttleGray-600">
          <p className="my-3">
            {t('techCrewEdit.noServiceSpecs.description1')}
          </p>
          <p className="my-3">
            {t('techCrewEdit.noServiceSpecs.description2')}
          </p>
        </div>
        {canManageServiceSpecifications ? (
          <Button
            onClick={() =>
              navigate(
                `/p/${projectId}/dashboard/settings/service-specifications`,
              )
            }
          >
            <FontAwesomeIcon icon={faBook} />
            {t('techCrewEdit.noServiceSpecs.action')}
          </Button>
        ) : (
          <InlineMessage variant="warning">
            {t('techCrewEdit.noServiceSpecs.cannotManageServiceSpecs')}
          </InlineMessage>
        )}
      </div>
    </Card>
  );
};
