import * as amplitude from '@amplitude/analytics-browser';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createProject, updateProjectImage } from 'src/admin/apiProjects';
import toast from 'src/common/toast';
import { ImageResizeResponse } from 'src/common/types';
import { Project } from 'src/project/types/projects';
import { ProjectAboutPage } from './ProjectAboutPage';
import { ProjectCompanyPage } from './ProjectCompanyPage';
import { ProjectInformationPage } from './ProjectInformationPage';
import { s3 } from 'src/common/fetch';
import { isString } from 'src/common/utils';

import type { OwnerPopulatedCompany } from 'shared';
type Props = {
  onClose: () => void;
};

enum ProjectModalPage {
  PAGE_COMPANY = 1,
  PAGE_ABOUT = 2,
  PAGE_INFORMATION = 3,
}

export const ProjectForm = ({ onClose }: Props) => {
  const { t } = useTranslation('common');

  const [currentPage, setCurrentPage] = useState(ProjectModalPage.PAGE_COMPANY);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [project, setProject] = useState<Partial<Project>>({});
  const [projectImage, setProjectImage] = useState<ImageResizeResponse>();
  const [company, setCompany] = useState<OwnerPopulatedCompany>();

  const queryClient = useQueryClient();

  async function callCreateProject() {
    try {
      setIsLoading(true);
      const createdProject = await createProject(project);
      if (!createdProject) {
        throw new Error(t('errors.create'));
      }
      if (projectImage && projectImage.blob) {
        try {
          const { url } = await updateProjectImage(
            createdProject._id,
            projectImage.extension,
          );
          if (!url) {
            throw new Error();
          }
          await s3.upload(url, projectImage.blob);
        } catch (err) {
          toast.error(t('errors.errorPicture'));
        }
      }
      queryClient.invalidateQueries({
        queryKey: ['admin-projects'],
      });
      amplitude.track('project: create', {
        projectId: createdProject._id,
        projectName: createdProject.name,
      });
      toast.success(t('success.create'));
    } catch (err) {
      toast.error(t('errors.create'));
    } finally {
      setIsLoading(false);
      onClose();
    }
  }

  const renderProjectModalPages = () => {
    switch (currentPage) {
      case ProjectModalPage.PAGE_COMPANY:
        return (
          <ProjectCompanyPage
            companyId={
              isString(project.company) ? project.company : project.company?._id
            }
            onClickCompany={(selectedCompany: OwnerPopulatedCompany) => {
              setProject({ company: selectedCompany._id });
              setCompany(selectedCompany);
              setCurrentPage(ProjectModalPage.PAGE_ABOUT);
            }}
          />
        );
      case ProjectModalPage.PAGE_ABOUT:
        return (
          company && (
            <ProjectAboutPage
              project={project}
              setProject={setProject}
              company={company}
              projectImage={projectImage}
              setProjectImage={setProjectImage}
              onClickBack={onClose}
              onClickNext={() =>
                setCurrentPage(ProjectModalPage.PAGE_INFORMATION)
              }
            />
          )
        );
      case ProjectModalPage.PAGE_INFORMATION:
        return (
          <ProjectInformationPage
            projectInformation={project.projectInformation || {}}
            setProjectInformation={(projectInformation) => {
              setProject({ ...project, projectInformation });
            }}
            onClickBack={() => setCurrentPage(ProjectModalPage.PAGE_ABOUT)}
            onClickNext={callCreateProject}
            isLoading={isLoading}
          />
        );
    }
  };

  return (
    <div className="h-[700px] min-w-[700px]">{renderProjectModalPages()}</div>
  );
};
