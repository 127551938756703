import { useQuery } from '@tanstack/react-query';

import { getSheetSets } from './api';

export function useSheetSetsQuery(projectId: string) {
  return useQuery({
    queryKey: ['sheetsets', projectId],
    queryFn: () => getSheetSets(projectId),
  });
}
