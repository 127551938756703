import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faUndo } from '@fortawesome/pro-regular-svg-icons';

import toast from 'src/common/toast';
import { Button } from 'src/common/components/buttons/Button';
import { DraggableParameterList } from 'src/Forge/components/DraggableParameterList';
import { useResizeForge } from 'src/Forge/hooks';
import { validateLicense } from 'src/project/utils';
import { updateProject } from '../../api/projectsApi';
import { ParameterSelection } from './ParameterSelection';
import {
  Filter,
  GetProjectResponse,
  LicenseEvaluationType,
} from '../../types/projects';
import { NameFilterModal } from './NameFilterModal';

type Props = {
  project: GetProjectResponse;
  parameters: string[];
};

export const Filters = ({ project, parameters }: Props) => {
  const { t } = useTranslation(['project', 'common']);

  const [localFilters, setLocalFilters] = useState<Filter[]>([]);
  const [remoteFilters, setRemoteFilters] = useState<Filter[]>([]);
  const [selectedParameter, setSelectedParameter] = useState<{
    name?: string;
    parameter: string;
  }>();
  const [isLoading, setIsLoading] = useState(false);

  useResizeForge([localFilters]);

  useEffect(() => {
    const filtersWithoutId = project.filters.map(({ name, parameter }) => ({
      name,
      parameter,
    }));
    setLocalFilters(filtersWithoutId);
    setRemoteFilters(filtersWithoutId);
  }, [project.filters]);

  const handleAddParameter = (parameter: string) => {
    setSelectedParameter({ parameter });
  };

  const handleSaveFilter = (newFilter: Filter) => {
    const tempFilters = Array.from(localFilters);
    const filterIndex = tempFilters.findIndex(
      (filter) => filter.parameter === newFilter.parameter,
    );
    if (filterIndex < 0) {
      tempFilters.unshift(newFilter);
    } else {
      tempFilters[filterIndex] = newFilter;
    }
    setLocalFilters(tempFilters);
    setSelectedParameter(undefined);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await updateProject(project._id, {
        filters: localFilters,
      });
      toast.success(t('settings.modelParameter.filters.saveSuccess'));
      setRemoteFilters(localFilters);
    } catch (err) {
      toast.error(t('settings.modelParameter.filters.saveSuccess'));
    } finally {
      setIsLoading(false);
    }
  };

  const isDirty = !isEqual(localFilters, remoteFilters);

  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;

  return (
    <div className="flex flex-col gap-4">
      <ParameterSelection
        parameters={parameters}
        localParameters={localFilters.map((filter) => filter.parameter)}
        onAddParameter={handleAddParameter}
      />
      <DraggableParameterList
        items={localFilters.map((filter) => ({
          title: filter.name,
          subtitle: filter.parameter,
        }))}
        canRemoveItems={!isLicenseExpired}
        setItems={(parameterItems) =>
          setLocalFilters(
            parameterItems.map((parameterItem) => ({
              name: parameterItem.title,
              parameter: parameterItem.subtitle || parameterItem.title,
            })),
          )
        }
        onEdit={(parameterItem) =>
          setSelectedParameter({
            name: parameterItem.title,
            parameter: parameterItem.subtitle || '',
          })
        }
      />
      {isDirty && (
        <div className="ml-auto flex items-center gap-4">
          <Button
            variant="tertiary"
            onClick={() => setLocalFilters(remoteFilters)}
          >
            <FontAwesomeIcon icon={faUndo} />
          </Button>
          <Button onClick={() => handleSave()} loading={isLoading}>
            {t('common:button.save')}
          </Button>
        </div>
      )}
      <NameFilterModal
        parameter={selectedParameter}
        onSave={handleSaveFilter}
        onClose={() => setSelectedParameter(undefined)}
      />
    </div>
  );
};
