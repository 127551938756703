import { ReactNode } from 'react';

import { cn } from 'src/common/utils';

type Props = {
  children: ReactNode;
  className?: string;
};

export const Card = ({ children, className }: Props) => (
  <div className={cn(className, 'overflow-hidden rounded-sm bg-white shadow')}>
    {children}
  </div>
);
