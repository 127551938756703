import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getActiveModelFileVersion } from 'src/file/util';
import { ForgeProgress, ModelFile } from 'src/file/types';
import { TranslateButton } from './TranslateButton';
import { useProject } from 'src/project/project-context';
import { refreshForgeStatus } from 'src/file/api';
import { Spinner } from 'src/common/components/Spinner';
import { logger } from 'src/logger';

export const TranslationStatus = ({ file }: { file: ModelFile }) => {
  const { t } = useTranslation(['file']);

  const activeVersion = getActiveModelFileVersion(file);
  if (!activeVersion) {
    logger.capture('No active version found for file');
    return null;
  }

  const status = activeVersion.forgeProgress;

  if (status === ForgeProgress.NONE) {
    return <TranslateButton file={file} />;
  }

  if (status === ForgeProgress.FORGE_PROGRESS) {
    return <TranslationProgress file={file} />;
  }
  if (status === ForgeProgress.DONE) {
    return (
      <div className="text-green-600">
        <FontAwesomeIcon icon={faCheckCircle} />
      </div>
    );
  }
  if (status === ForgeProgress.FORGE_FAILED) {
    return (
      <>
        <TranslateButton file={file} />
        <div className="mt-1 text-sm text-red-600">
          <FontAwesomeIcon icon={faTimesCircle} />{' '}
          <span>{t('file:item.status.error')}</span>
        </div>
      </>
    );
  }

  return null;
};

function TranslationProgress({ file }: { file: ModelFile }) {
  const { _id: projectId } = useProject();
  const version = getActiveModelFileVersion(file);
  const queryClient = useQueryClient();

  if (!file._id || !version) {
    throw new Error('File must have an id and an active version');
  }

  const query = useQuery({
    queryKey: [
      'projects',
      projectId,
      'files',
      file._id,
      'versions',
      version._id,
      'refresh-status',
    ],

    queryFn: () => {
      if (
        !file?._id ||
        version?.forgeProgress !== ForgeProgress.FORGE_PROGRESS
      ) {
        return Promise.reject();
      }
      return refreshForgeStatus(projectId, file._id, version?._id);
    },

    enabled: !!version,
    refetchInterval: 5000,
  });

  if (query.data?.status === ForgeProgress.DONE) {
    queryClient.invalidateQueries({
      queryKey: ['projects', projectId, 'files'],
    });
  }

  if (query.isPending || !query.data?.progress) {
    return <Spinner />;
  }

  return (
    <div className="flex items-center gap-2">
      <Spinner />
      {query.data && (
        <p className="text-sm text-shuttleGray-600">{`${query.data.progress}%`}</p>
      )}
    </div>
  );
}
