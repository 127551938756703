import { useTranslation } from 'react-i18next';

import { Card } from 'src/common/components/Card';
import { UserCompanyRow } from './UserCompanyRow';

import type { CompanyPopulatedUser } from 'shared';

type Props = {
  user: CompanyPopulatedUser;
};

export const UserCompany = ({ user }: Props) => {
  const { t } = useTranslation('admin');

  const companies = user.companies;
  const noCompanies = !companies || companies.length === 0;

  if (noCompanies) {
    return (
      <div className="-mx-6 -mb-6 flex flex-col gap-8 overflow-y-auto rounded-b bg-gray-100 p-6">
        <Card>
          <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
            {t('users.modal.userHasNoOrganization')}
          </p>
        </Card>
      </div>
    );
  }

  return (
    <div className="-mx-6 -mb-6 flex flex-col gap-4 overflow-y-auto rounded-b bg-gray-100 p-6">
      {companies.map((company) => (
        <UserCompanyRow key={company._id} company={company} user={user} />
      ))}
    </div>
  );
};
