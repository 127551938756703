import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/common/components/buttons/Button';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { TextLink } from 'src/common/components/TextLink';

type Props = {
  onSubmit: (email: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
};

export const ForgotPasswordForm = ({ onSubmit, isLoading }: Props) => {
  const { t } = useTranslation('auth');

  return (
    <div>
      <h1 className="mb-2 mt-2 text-xl font-semibold">{t('forgotPassword')}</h1>
      <p className="text-shuttleGray-600">{t('forgotPasswordForm.text')}</p>
      <form onSubmit={onSubmit} className="mt-2">
        <TextInput
          label={t('email')}
          placeholder={t('email')}
          name="email"
          type="email"
          className="mt-3 w-full"
          id="email"
          required
        />
        <div className="mt-8 flex w-full flex-col gap-2 text-base">
          <Button type="submit" loading={isLoading}>
            {`${t('forgotPasswordForm.linkText')}`}
          </Button>
        </div>
      </form>
      <div className="mt-2 py-2 text-center">
        <TextLink to="/login">{`${t('forgotPasswordForm.toLogin')}`}</TextLink>
      </div>
    </div>
  );
};
