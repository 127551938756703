import { Navigate, Route, Routes } from 'react-router-dom';

import { Container } from 'src/common/components/Container';
import { NotFoundInProjects } from 'src/project/components/NotFoundInProjects';
import { LicenseExpiredMessage } from '../components/LicenseExpiredMessage';
import { LicenseEvaluationType, LicenseType } from '../types/projects';
import { validateLicense } from '../utils';
import { ActionsView } from './ActionsView';
import { InformationView } from './InformationView';
import { ModelParameterView } from './ModelParameterView';
import { ServiceSpecificationsView } from './ServiceSpecificationsView';
import { TagView } from './TagView';
import { WeatherView } from './WeatherView';
import { useProject } from '../project-context';

export const ProjectSettingsView = () => {
  const project = useProject();
  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;

  const isFreeProject = project.license.type === LicenseType.FREE;

  return (
    <Container className="py-4" variant="box">
      {isLicenseExpired && <LicenseExpiredMessage />}
      <div className="pt-4">
        <Routes>
          <Route path="information" element={<InformationView />} />
          {!isFreeProject && (
            <>
              <Route path="model-parameter" element={<ModelParameterView />} />
              <Route path="weather" element={<WeatherView />} />
              <Route path="actions" element={<ActionsView />} />
              <Route
                path="service-specifications"
                element={
                  <ServiceSpecificationsView
                    isLicenseExpired={isLicenseExpired}
                  />
                }
              />
              <Route path="tags" element={<TagView />} />
            </>
          )}

          <Route index element={<Navigate to="information" replace />} />
          <Route path="*" element={<NotFoundInProjects />} />
        </Routes>
      </div>
    </Container>
  );
};
