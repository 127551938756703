import { faArrowRight, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MainAppbarLayout } from 'src/app/components/MainAppbarLayout';
import deleteIllu from 'src/assets/illu_delete.svg';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { Loading } from 'src/common/components/Loading';
import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';
import { PasswordInput } from 'src/common/components/inputs/PasswordInput/PasswordInput';
import { useCompaniesQuery } from 'src/company/queries';
import { getAllProjects } from 'src/project/api/projectsApi';
import {
  LicenseType,
  ProjectWithDownloadRef,
} from 'src/project/types/projects';
import { deleteUser, getUserProfile } from '../api';
import { ProfileInfoForm } from '../components/ProfileInfoForm';
import { UserProfileResponse } from '../types';
import { useUser } from 'src/auth/user-context';
import { ProfileImage } from '../components/ManageProfileImage';
import { NotFound } from 'src/common/components/NotFound';

export const UserProfileView = () => {
  const user = useUser();
  const profileQuery = useQuery({
    queryKey: ['user-profile', user._id],
    queryFn: () => getUserProfile(user._id),
  });
  const { data: projects } = useQuery({
    queryKey: ['projects'],
    queryFn: getAllProjects,
    initialData: [],
    staleTime: 0,
  });

  if (profileQuery.isPending) {
    return (
      <MainAppbarLayout>
        <Loading />
      </MainAppbarLayout>
    );
  }

  if (profileQuery.isError) {
    return (
      <MainAppbarLayout>
        <NotFound />
      </MainAppbarLayout>
    );
  }

  const userProfile = profileQuery.data;

  return (
    <MainAppbarLayout>
      <Header createdAt={userProfile.createdAt} />
      <div className="mb-4">
        <ProfileImage />
      </div>
      <div className="relative bg-white p-4 shadow">
        <ProfileInfoForm userProfile={userProfile} />
        <DeleteUserButton
          user={userProfile}
          className="absolute bottom-4 left-4"
          projects={projects}
        />
      </div>
    </MainAppbarLayout>
  );
};

const Header = ({ createdAt }: { createdAt: string }) => {
  const { t } = useTranslation('user');

  return (
    <>
      <h1 className="text-2xl font-semibold">{t('profile')}</h1>
      <p className="mb-2 text-shuttleGray-600">
        {t('joined')} {dayjs(createdAt).format('L')}
      </p>
    </>
  );
};

const DeleteUserButton = ({
  user,
  className,
  projects,
}: {
  user: UserProfileResponse;
  className: string;
  projects: ProjectWithDownloadRef[];
}) => {
  const { t } = useTranslation(['user', 'common']);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCurrentStatusModal, setShowCurrentStatusModal] = useState(false);
  const { data: companies } = useCompaniesQuery();

  const ownedCompanies = companies?.filter((c) => c.owner === user._id);
  const ownsCompany = ownedCompanies?.length;

  // Look into the project list if there are any projects owned by the user, excluding demo projects
  const userProjectsAsOwner = projects.filter(
    (project) =>
      project.owner === user._id && project.license.type !== LicenseType.DEMO,
  );

  const slicedUserProjectsAsOwnerList =
    userProjectsAsOwner && userProjectsAsOwner.length > 5
      ? userProjectsAsOwner?.slice(0, 5)
      : userProjectsAsOwner;

  return (
    <div className={className}>
      <Button
        onClick={() => {
          userProjectsAsOwner.length === 0 && !ownsCompany
            ? setShowDeleteModal(true)
            : setShowCurrentStatusModal(true);
        }}
        variant="red"
        disabled={false}
      >
        <FontAwesomeIcon icon={faTrash} />
        {t('user:delete.buttonText')}
      </Button>
      <DeleteUserModal
        isOpen={showDeleteModal}
        key={showDeleteModal.toString()}
        email={user.email}
        userId={user._id}
        onClose={() => setShowDeleteModal(false)}
      />
      <CurrentStatusModal
        title={t('user:noDelete.title')}
        subtitle={t('user:noDelete.text')}
        isOpen={showCurrentStatusModal}
        onClose={() => setShowCurrentStatusModal(false)}
      >
        {userProjectsAsOwner && userProjectsAsOwner.length > 0 && (
          <>
            <InlineMessage variant="warning" className="mb-4">
              {t('user:noDelete.projectOwnertext')}
            </InlineMessage>
            <ul className="space-y-4">
              {slicedUserProjectsAsOwnerList?.map((project: any) => {
                return (
                  <li key={project._id}>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="mr-2 text-teal-600"
                    />
                    {project.name}
                  </li>
                );
              })}
            </ul>
            {userProjectsAsOwner.length > 5 && (
              <div className="mt-6">
                <Trans
                  ns="user"
                  i18nKey="noDelete.extraProjects"
                  values={{ value: userProjectsAsOwner.length - 5 }}
                  components={[
                    <span
                      key={userProjectsAsOwner.length - 5}
                      className="font-extrabold text-shuttleGray-800"
                    />,
                  ]}
                />
              </div>
            )}
          </>
        )}
        {ownsCompany && (
          <>
            <InlineMessage variant="warning" className="mb-4 mt-8">
              {t('user:noDelete.companyOwnertext')}
            </InlineMessage>
            <ul className="space-y-4">
              {ownedCompanies.map((company) => (
                <li key={company._id}>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="mr-2 text-teal-600"
                  />
                  {company.name}
                </li>
              ))}
            </ul>
          </>
        )}
      </CurrentStatusModal>
    </div>
  );
};

type CurrentStatusModalProps = {
  title: string;
  subtitle?: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  children?: ReactNode;
};

const CurrentStatusModal = ({
  title,
  subtitle,
  onClose,
  isOpen,
  children,
}: CurrentStatusModalProps) => {
  const { t } = useTranslation(['user', 'common']);
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Delete-File-Confirmation"
      className="flex w-[432px] flex-col"
    >
      <Modal.Header onClose={onClose}>{title}</Modal.Header>
      <h3 className='text-gray-600" mt-2 px-6 font-normal'>{subtitle}</h3>
      <Modal.Body>
        {children}
        <div className="mt-8 flex justify-end space-x-2">
          <Button variant="secondary" onClick={onClose}>
            {t('common:button.abort')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const DeleteUserModal = ({
  isOpen,
  email,
  userId,
  onClose,
}: {
  isOpen: boolean;
  email: string;
  userId: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation(['user', 'common']);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors, isDirty },
    setError,
    handleSubmit,
  } = useForm<{ password: string }>({
    mode: 'onSubmit',
  });

  const handleDelete = async ({ password }: { password: string }) => {
    try {
      await deleteUser(userId, password);
      queryClient.invalidateQueries({
        queryKey: ['user'],
      });
      queryClient.invalidateQueries({
        queryKey: ['projects'],
      });
      queryClient.invalidateQueries({
        queryKey: ['company'],
      });
      navigate('/');
      toast.success(t('user:delete.success'));
    } catch (exception) {
      setError('password', { message: t('user:delete.error') });
      toast.error(t('user:delete.failure'));
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="w-[680px]">
      <Modal.Header onClose={onClose}>{t('user:delete.title')}</Modal.Header>
      <Modal.Body>
        <form
          className="mt-8 flex flex-col items-center gap-12"
          onSubmit={handleSubmit(handleDelete)}
        >
          <div className="flex gap-9">
            <div>
              <img src={deleteIllu} alt="" />
            </div>
            <div className="flex w-full flex-col gap-4">
              <p className="text-shuttleGray-600">
                <Trans
                  ns="user"
                  i18nKey="delete.text"
                  values={{ email }}
                  components={[
                    <span
                      className="font-extrabold text-shuttleGray-800"
                      key="1"
                    />,
                  ]}
                />
              </p>
              <PasswordInput
                label={t('user:delete.label')}
                required
                error={errors['password']?.message}
                {...register('password')}
              />
            </div>
          </div>
          <div className="ml-auto flex gap-2">
            <Button variant="tertiary" onClick={onClose}>
              {t('common:button.abort')}
            </Button>
            <Button
              variant="danger"
              type="submit"
              disabled={!isDirty || Object.keys(errors).length > 0}
            >
              {t('user:delete.submit')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
