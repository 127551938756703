import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { removeUserFromProject } from 'src/admin/apiProjects';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Modal } from 'src/common/components/Modal';
import { LicenseType } from 'src/project/types/projects';
import { UserProjectRowRoleBadge } from './UserProjectRoleBadge';

import type { OwnerCompanyPopulatedProject } from 'shared';

type Props = {
  project: OwnerCompanyPopulatedProject;
  userId: string;
};

export const UserProjectRow = ({ project, userId }: Props) => {
  const { t } = useTranslation(['admin', 'common']);

  const [isRemoveUserConfirmationOpen, setIsRemoveUserConfirmationOpen] =
    useState(false);

  const queryClient = useQueryClient();

  const handleRemoveUserConfirmation = async () => {
    try {
      await removeUserFromProject(project._id, userId);
      toast.success(t('users.modal.removeUserSuccess'));
      await queryClient.invalidateQueries({
        queryKey: ['admin-user-projects'],
      });
    } catch (exception) {
      toast.error(t('users.modal.removeUserFailure'));
    }
    setIsRemoveUserConfirmationOpen(false);
  };

  const isUserOwner = project.owner._id === userId;
  const showRemoveButton =
    !isUserOwner || project.license.type === LicenseType.DEMO;

  return (
    <>
      <tr>
        <td className="table-td table-td-truncate min-w-[200px]">
          {project.name}
        </td>
        <td className="table-td">
          <UserProjectRowRoleBadge project={project} userId={userId} />
        </td>
        <td className="table-td text-right">
          {showRemoveButton && (
            <ActionIcon onClick={() => setIsRemoveUserConfirmationOpen(true)}>
              <FontAwesomeIcon icon={faTrash} />
            </ActionIcon>
          )}
        </td>
      </tr>
      <Modal
        isOpen={isRemoveUserConfirmationOpen}
        onRequestClose={() => setIsRemoveUserConfirmationOpen(false)}
      >
        <ConfirmationDialog
          title={t('users.modal.removeUserTitle')}
          message={
            <Trans
              ns="admin"
              i18nKey="users.modal.removeUserMessage"
              components={[
                <span className="font-semibold text-shuttleGray-800" key="1" />,
              ]}
              values={{ projectName: project.name }}
            />
          }
          onCancel={() => setIsRemoveUserConfirmationOpen(false)}
          onConfirm={handleRemoveUserConfirmation}
          customConfirmText={t('common:button.remove')}
        />
      </Modal>
    </>
  );
};
