import { useQuery } from '@tanstack/react-query';

import {
  getCustomParameterProperties,
  getCustomParameterPropertiesByExternalId,
  getCustomParameters,
} from '../../api';

export function useProjectCustomParameters(projectId: string) {
  return useQuery({
    queryKey: ['projects', projectId, 'customParameters'],

    queryFn: () => getCustomParameters(projectId),
  });
}
export function useProjectCustomParameterPropertiesByExternalIdQuery(
  projectId: string,
  externalIds: string[],
) {
  return useQuery({
    queryKey: ['projects', projectId, 'customParameterProperties', externalIds],
    queryFn: async () => {
      try {
        const res = await Promise.all(
          externalIds.map(async (externalId) => {
            const result = await getCustomParameterPropertiesByExternalId(
              projectId,
              externalId,
            );
            return result;
          }),
        );
        return res;
      } catch (error) {
        throw new Error('Error fetching custom parameter properties:');
      }
    },
  });
}

export function useProjectCustomParameterProperties(projectId: string) {
  return useQuery({
    queryKey: ['projects', projectId, 'customParameterProperties'],

    queryFn: () => getCustomParameterProperties(projectId),
  });
}
