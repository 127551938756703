/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/unbound-method */
/**
 * This extension removes superfluous buttons from the
 * viewer toolbar for less distraction.
 */

// TODO: Type this file correctly and remove the eslint suppressors

import { ExtensionArray } from './ExtensionArray';

class ToggleSuperfluousOptionsExtension extends Autodesk.Viewing.Extension {
  loadedExtensions: ExtensionArray | null = null;

  toolsShouldBeVisible = false;

  load() {
    const onToolbarCreatedBound = this.onToolbarCreated.bind(this);
    this.loadedExtensions = new ExtensionArray();

    if (this.viewer.toolbar) {
      this._createUI();
    } else {
      this.viewer.addEventListener(
        Autodesk.Viewing.TOOLBAR_CREATED_EVENT,
        onToolbarCreatedBound,
      );
    }

    return true;
  }

  unload() {
    if (!this.viewer.toolbar) {
      return true;
    }
    this.viewer.toolbar.removeControl('CustomToggleOptionsToolbar');
    return true;
  }

  onToolbarCreated() {
    this._createUI();
    this.viewer.removeEventListener(
      Autodesk.Viewing.TOOLBAR_CREATED_EVENT,
      this.onToolbarCreated,
    );

    const hideAtStartupBound = this.hideAtStartup.bind(this);
    this.viewer.addEventListener(
      Autodesk.Viewing.EXTENSION_LOADED_EVENT,
      hideAtStartupBound,
    );
  }

  hideAtStartup(e: any) {
    this.loadedExtensions?.push(e.extensionId);
    this.s(false);
  }

  toggleTool(tool: any, visible: any) {
    if (tool) {
      tool.setVisible(visible);
    }
  }

  toggleButtonCss(button: any, visible: any) {
    if (button) {
      if (visible) {
        button.container.style.display = 'block';
      } else {
        button.container.style.display = 'none';
      }
    }
  }

  s(visible: any) {
    if (!this.viewer.toolbar) {
      return;
    }
    const navControl = this.viewer.toolbar.getControl(
      'navTools',
    ) as Autodesk.Viewing.UI.ControlGroup;
    if (navControl) {
      this.toggleTool(navControl.getControl('toolbar-zoomTool'), visible);
      this.toggleTool(navControl.getControl('toolbar-bimWalkTool'), visible);
      this.toggleButtonCss(
        navControl.getControl('toolbar-cameraSubmenuTool'),
        visible,
      );
      this.toggleButtonCss(navControl.getControl('toolbar-zoomTool'), visible);
    }

    const modelControl = this.viewer.toolbar.getControl(
      'modelTools',
    ) as Autodesk.Viewing.UI.ControlGroup;
    if (modelControl) {
      this.toggleTool(modelControl.getControl('toolbar-sectionTool'), visible);
      this.toggleButtonCss(
        modelControl.getControl('toolbar-explodeTool'),
        visible,
      );
    }

    const settingsControl = this.viewer.toolbar.getControl(
      'settingsTools',
    ) as Autodesk.Viewing.UI.ControlGroup;
    if (settingsControl) {
      this.toggleTool(
        settingsControl.getControl('toolbar-modelStructureTool'),
        visible,
      );
      this.toggleTool(
        settingsControl.getControl('toolbar-propertiesTool'),
        visible,
      );
      this.toggleTool(
        settingsControl.getControl('toolbar-settingsTool'),
        visible,
      );
      this.toggleTool(
        settingsControl.getControl('toolbar-fullscreenTool'),
        visible,
      );
    }
  }

  _createUI() {
    this.toolsShouldBeVisible = false;
    const toggleButton = new Autodesk.Viewing.UI.Button(
      'CustomToggleOptionsButton',
    );
    // @ts-ignore
    toggleButton.container.children[0].classList.add(
      'fal',
      'custom-viewer-icon-plus',
    );
    toggleButton.setToolTip('Zusätzliche Optionen ein-/ausblenden');
    toggleButton.addClass('viewerButton');

    const toggleExtensionToolbar =
      (this.viewer.toolbar.getControl(
        'CustomToggleOptionsToolbar',
      ) as Autodesk.Viewing.UI.ControlGroup) ||
      new Autodesk.Viewing.UI.ControlGroup('CustomToggleOptionsToolbar');

    toggleButton.onClick = () => {
      if (this.toolsShouldBeVisible) {
        // @ts-ignore
        toggleButton.container.children[0].classList.remove(
          'custom-viewer-icon-minus',
        );
        // @ts-ignore
        toggleButton.container.children[0].classList.add(
          'custom-viewer-icon-plus',
        );
      } else {
        // @ts-ignore
        toggleButton.container.children[0].classList.remove(
          'custom-viewer-icon-plus',
        );
        // @ts-ignore
        toggleButton.container.children[0].classList.add(
          'custom-viewer-icon-minus',
        );
      }
      this.s(!this.toolsShouldBeVisible);
      this.toolsShouldBeVisible = !this.toolsShouldBeVisible;
    };
    toggleExtensionToolbar.addControl(toggleButton);
    this.viewer.toolbar.addControl(toggleExtensionToolbar);
  }
}

export default ToggleSuperfluousOptionsExtension;
