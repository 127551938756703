import Custom from 'src/assets/weather/not-available.svg';
import ClearSky from 'src/assets/weather/clear-day.svg';
import MostlyClear from 'src/assets/weather/partly-cloudy-day.svg';
import Cloudy from 'src/assets/weather/overcast-day.svg';
import Overcast from 'src/assets/weather/overcast.svg';
import Fog from 'src/assets/weather/fog-day.svg';
import LightRain from 'src/assets/weather/partly-cloudy-day-drizzle.svg';
import Rain from 'src/assets/weather/rain.svg';
import HeavyRain from 'src/assets/weather/overcast-rain.svg';
import Thunderstorm from 'src/assets/weather/thunderstorms.svg';
import RainAndSnow from 'src/assets/weather/sleet.svg';
import LightSnow from 'src/assets/weather/partly-cloudy-day-snow.svg';
import Snow from 'src/assets/weather/snow.svg';
import HeavySnow from 'src/assets/weather/overcast-snow.svg';
import Hail from 'src/assets/weather/hail.svg';
import Wind from 'src/assets/weather/wind.svg';
import HeavyWind from 'src/assets/weather/wind-alert.svg';
import { WeatherEntry, WeatherEntryDescription } from './types';

export function compareWeatherEntries(a: WeatherEntry, b: WeatherEntry) {
  return a.time < b.time ? -1 : 1;
}

export const WEATHER_CONDITION_MAP = {
  CUSTOM: {
    icon: '🏷️',
    label: 'conrepApp:report.dayInfo.weather.simple.input.clearSky',
  },
  CLEAR_SKY: {
    icon: '☀️',
    label: 'conrepApp:report.dayInfo.weather.simple.input.clearSky',
  },
  MOSTLY_CLEAR: {
    icon: '⛅',
    label: 'conrepApp:report.dayInfo.weather.simple.input.mostlyClear',
  },
  CLOUDY: {
    icon: '🌥️',
    label: 'conrepApp:report.dayInfo.weather.simple.input.cloudy',
  },
  OVERCAST: {
    icon: '☁️',
    label: 'conrepApp:report.dayInfo.weather.simple.input.overcast',
  },
  FOG: {
    icon: '🌫️',
    label: 'conrepApp:report.dayInfo.weather.simple.input.fog',
  },
  LIGHT_RAIN: {
    icon: '🌧️',
    label: 'conrepApp:report.dayInfo.weather.simple.input.lightRain',
  },
  RAIN: {
    icon: '🌧️',
    label: 'conrepApp:report.dayInfo.weather.simple.input.rain',
  },
  HEAVY_RAIN: {
    icon: '🌧️',
    label: 'conrepApp:report.dayInfo.weather.simple.input.heavyRain',
  },
  THUNDERSTORM: {
    icon: '🌩️',
    label: 'conrepApp:report.dayInfo.weather.simple.input.thunderstorm',
  },
  RAIN_AND_SNOW: {
    icon: '🌨️',
    label: 'conrepApp:report.dayInfo.weather.simple.input.rainAndSnow',
  },
  LIGHT_SNOW: {
    icon: '❄️',
    label: 'conrepApp:report.dayInfo.weather.simple.input.lightSnow',
  },
  SNOW: {
    icon: '❄️',
    label: 'conrepApp:report.dayInfo.weather.simple.input.snow',
  },
  HEAVY_SNOW: {
    icon: '❄️',
    label: 'conrepApp:report.dayInfo.weather.simple.input.heavySnow',
  },
  HAIL: {
    icon: '🧊',
    label: 'conrepApp:report.dayInfo.weather.simple.input.hail',
  },
  WIND: {
    icon: '💨',
    label: 'conrepApp:report.dayInfo.weather.simple.input.wind',
  },
  HEAVY_WIND: {
    icon: '💨',
    label: 'conrepApp:report.dayInfo.weather.simple.input.heavyWind',
  },
} as const;

const WEATHER_ICON_MAP: Record<string, string> = Object.entries(
  WEATHER_CONDITION_MAP,
).reduce(
  (acc, [key, value]) => {
    acc[value.icon] = key;
    return acc;
  },
  {} as { [key: string]: string },
);

export const WEATHER_GROUPS = [
  {
    label: 'conrepApp:report.dayInfo.weather.simple.input.cloudGroup',
    conditions: ['CLEAR_SKY', 'MOSTLY_CLEAR', 'CLOUDY', 'OVERCAST', 'FOG'],
  },
  {
    label: 'conrepApp:report.dayInfo.weather.simple.input.rainGroup',
    conditions: ['LIGHT_RAIN', 'RAIN', 'HEAVY_RAIN', 'THUNDERSTORM'],
  },
  {
    label: 'conrepApp:report.dayInfo.weather.simple.input.snowGroup',
    conditions: ['RAIN_AND_SNOW', 'LIGHT_SNOW', 'SNOW', 'HEAVY_SNOW', 'HAIL'],
  },
  {
    label: 'conrepApp:report.dayInfo.weather.simple.input.windGroup',
    conditions: ['WIND', 'HEAVY_WIND'],
  },
] as const;

export const validateWeatherCondition = (
  c: string,
): c is keyof typeof WEATHER_CONDITION_MAP => {
  return Object.keys(WEATHER_CONDITION_MAP).includes(c);
};

export function getWeatherIconsForDescriptions(
  description: WeatherEntryDescription[],
) {
  return (
    description
      .map((d) => {
        if (!validateWeatherCondition(d.condition)) {
          return null;
        }
        return {
          source: getWeatherEntrySvgIconPath(d),
          label: WEATHER_CONDITION_MAP[d.condition].label,
        };
      })
      .filter(<T>(v: T): v is NonNullable<T> => v !== null) || []
  );
}

export function getWeatherEntryDescriptionIcons(
  descriptions: WeatherEntryDescription[],
) {
  return descriptions.map((desc) => WEATHER_ICON_MAP[desc.condition] || '');
}

function getWeatherEntrySvgIconPath(description: WeatherEntryDescription) {
  switch (description.condition) {
    case 'CUSTOM': {
      return Custom;
    }
    case 'CLEAR_SKY':
      return ClearSky;
    case 'MOSTLY_CLEAR':
      return MostlyClear;
    case 'CLOUDY':
      return Cloudy;
    case 'OVERCAST':
      return Overcast;
    case 'FOG':
      return Fog;
    // rain
    case 'LIGHT_RAIN':
      return LightRain;
    case 'RAIN':
      return Rain;
    case 'HEAVY_RAIN':
      return HeavyRain;
    case 'THUNDERSTORM':
      return Thunderstorm;
    // snow
    case 'RAIN_AND_SNOW':
      return RainAndSnow;
    case 'LIGHT_SNOW':
      return LightSnow;
    case 'SNOW':
      return Snow;
    case 'HEAVY_SNOW':
      return HeavySnow;
    case 'HAIL':
      return Hail;
    // wind
    case 'WIND':
      return Wind;
    case 'HEAVY_WIND':
      return HeavyWind;
  }
  return '';
}
