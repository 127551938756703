import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInbox,
  faSquareArrowUpRight,
} from '@fortawesome/pro-regular-svg-icons';
import { ReactNode } from 'react';

import { ActionMessage } from 'src/common/components/ActionMessage';
import { useProjectInvitesOfCurrentUserQuery } from 'src/project/queries';
import { getAllProjects } from '../api/projectsApi';
import { Button } from 'src/common/components/buttons/Button';

export const ProjectInvitations = () => {
  const { data: invitations } = useProjectInvitesOfCurrentUserQuery();
  const { data: projects } = useQuery({
    queryKey: ['projects'],
    queryFn: getAllProjects,
    initialData: [],
  });

  const gotInvites = !!invitations && invitations.length > 0;
  const isInProject = projects.length > 0;
  if (!gotInvites || isInProject) {
    return null;
  }

  if (invitations.length === 1) {
    const invitation = invitations[0];
    const { createdByName: userName, projectName } = invitation;
    return (
      <InvitationBanner>
        <Trans
          ns="project"
          i18nKey="invitations.pendingInviteSingle"
          values={{ userName, projectName }}
          components={[
            <span key="0" className="font-semibold" />,
            <span key="1" className="font-semibold" />,
          ]}
        />
      </InvitationBanner>
    );
  }

  const inviteCount = invitations.length;
  return (
    <InvitationBanner>
      <Trans
        ns="project"
        i18nKey="invitations.pendingInviteMulti"
        values={{ inviteCount }}
        components={[<span key="0" className="font-semibold" />]}
      />
    </InvitationBanner>
  );
};

const InvitationBanner = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('project');
  return (
    <ActionMessage icon={faInbox} className="mb-6">
      <div className="flex w-full items-center justify-between">
        <p>{children}</p>
        <div className="ml-3 flex items-center gap-3">
          <Link to="/invitations">
            <Button variant="green">
              <FontAwesomeIcon icon={faSquareArrowUpRight} />
              {t('invitations.linkInfo')}
            </Button>
          </Link>
        </div>
      </div>
    </ActionMessage>
  );
};
