import { api } from 'src/common/fetch';

import type { ForgeToken } from './types';

export async function getForgeViewerToken() {
  return api.get<ForgeToken>('/api/forge/token/viewer');
}

export async function getProperties(
  projectId: string,
  fileId: string,
  fileVersionId: string,
  fileVersionViewId: string,
  includePaths = false,
) {
  return api.get<string[]>(
    `/api/projects/${projectId}/files/${fileId}/versions/${fileVersionId}/views/${fileVersionViewId}/properties?includePaths=${includePaths.toString()}`,
  );
}

export async function getCategoryValues(
  projectId: string,
  fileId: string,
  fileVersionId: string,
  fileVersionViewId: string,
) {
  return api.get<string[]>(
    `/api/projects/${projectId}/files/${fileId}/versions/${fileVersionId}/views/${fileVersionViewId}/categories`,
  );
}

export async function getParamValues(
  projectId: string,
  fileId: string,
  fileVersionId: string,
  fileVersionViewId: string,
  param: string,
) {
  const query = `key=${encodeURI(param)}`;
  return api.get<string[]>(
    `/api/projects/${projectId}/files/${fileId}/versions/${fileVersionId}/views/${fileVersionViewId}/values?${query}`,
  );
}
