import { faHelmetSafety } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { cn } from 'src/common/utils';
import { Badge } from 'src/common/components/Badge';
import { Card } from 'src/common/components/Card';
import { Image } from 'src/common/components/image/Image';
import {
  LicenseType,
  ProjectWithDownloadRef,
} from 'src/project/types/projects';
import projectThumbnail from 'src/assets/project-thumb.svg';

export const ProjectListItem = ({
  project,
}: {
  project: ProjectWithDownloadRef;
}) => {
  const { t } = useTranslation('project');

  const meta = {
    street: project.projectInformation?.street,
    area: getLocation(project.projectInformation),
  };

  const isExpired = Boolean(
    project.license?.expirationDate &&
      new Date(project.license?.expirationDate) < new Date(),
  );

  const licenseType = project.license?.type;

  const daysLeft =
    dayjs(project.license?.expirationDate).diff(dayjs(), 'day') + 1;

  return (
    <Link to={`/p/${project._id}`}>
      <Card className="min-h-[220px] border border-transparent transition-colors hover:border-blue-600">
        <div className="relative">
          <div className="h-28 overflow-hidden bg-gray-200">
            <Image
              loading="lazy"
              src={project.downloadRef?.downloadUrl || projectThumbnail}
              className={cn('h-full w-full object-cover object-center', {
                grayScale: isExpired,
              })}
            />
          </div>
          <div className="absolute left-0 top-0 flex flex-col items-end gap-1 p-2">
            {isExpired ? (
              <Badge
                icon={faHelmetSafety}
                size="md"
                className="rounded-md bg-[#292F36CC] text-[#F65C52]"
                uppercase={false}
              >
                {t('listView.archived.label')}
              </Badge>
            ) : (
              licenseType === LicenseType.BASIC && (
                <Badge
                  icon={faHelmetSafety}
                  size="md"
                  className="rounded-md bg-[#292F36CC] text-[#F9BC47]"
                  uppercase={false}
                >
                  {daysLeft <= 15 &&
                    daysLeft > 0 &&
                    t('listView.daysLeft', { daysLeft })}
                </Badge>
              )
            )}
          </div>
        </div>
        <div className="p-4">
          <h3 className="mb-2 font-semibold">{project.name}</h3>
          <div className="text-shuttleGray-600">
            <div>{meta.street}</div>
            <div>{meta.area}</div>
          </div>
        </div>
      </Card>
    </Link>
  );
};

function getLocation(info: ProjectWithDownloadRef['projectInformation']) {
  if (!info) {
    return null;
  }
  const addressLine = [info.zipCode, info.city].filter(Boolean).join(' ');
  return [addressLine, info.country].filter(Boolean).join(', ');
}
