import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  faCheckCircle,
  faClock,
  faEuroSign,
  faExclamationCircle,
  faMapMarkerAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { faHardHat } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

import { logger } from 'src/logger';
import { s3 } from 'src/common/fetch';
import { ImageInput } from 'src/common/components/inputs/ImageInput';
import { getProjectImage, setProjectImage } from '../api/projectsApi';
import { LicenseEvaluationType, License, LicenseType } from '../types/projects';
import { validateLicense } from '../utils';
import { Image } from 'src/common/components/image/Image';
import projectThumbnail from 'src/assets/project-thumb.svg';
import { useProject } from '../project-context';
import { cn, isString, resizeImage } from 'src/common/utils';
import { Button } from 'src/common/components/buttons/Button';
import { useUser } from 'src/auth/user-context';
import { isProjectOwnerOrAdmin } from 'src/user/utils';

const IconListItem = ({
  icon,
  title,
  children,
}: {
  icon: ReactNode;
  title: ReactNode;
  children: ReactNode;
}) => {
  return (
    <div>
      <div className="flex">
        <div className="flex w-6 items-center">{icon}</div>
        <div className="font-semibold">{title}</div>
      </div>
      <div className="pl-6 text-shuttleGray-600">{children}</div>
    </div>
  );
};

const getTimeZone = async (
  latitude?: string,
  longitude?: string,
  timestamp?: number,
) => {
  try {
    const res = await fetch(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${
        import.meta.env.VITE_GOOGLE_MAPS_TIMEZONE_API_KEY
      }`,
    );
    return res.json();
  } catch (error) {
    throw new Error('Error during time zone fetching');
  }
};

const joinValues = (values: (string | undefined)[]) =>
  values.filter(Boolean).join(', ') || '-';

const Location = ({
  location,
}: {
  location: {
    street?: string;
    zipCode?: string;
    city?: string;
    country?: string;
  };
}) => {
  const { t } = useTranslation(['project']);

  const street = joinValues([location.street]);
  const address = joinValues([
    location.zipCode,
    location.city,
    location.country,
  ]);

  return (
    <IconListItem
      icon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
      title={t('project:overview.basicInfoCard.address')}
    >
      <div className="flex flex-col">
        <p>{street}</p>
        <p>{address}</p>
      </div>
    </IconListItem>
  );
};

const TimeZone = ({
  coordinates,
}: {
  coordinates: {
    latitude?: string;
    longitude?: string;
  };
}) => {
  const { t } = useTranslation(['project']);

  const { data, error } = useQuery({
    queryKey: ['timeZone'],

    queryFn: () =>
      getTimeZone(
        coordinates.latitude,
        coordinates.longitude,
        Math.floor(new Date().getTime() / 1000),
      ),

    enabled: !!coordinates.latitude && !!coordinates.longitude,
  });

  if (!coordinates.latitude || !coordinates.longitude) return null;

  return (
    <IconListItem
      icon={<FontAwesomeIcon icon={faClock} />}
      title={t('project:overview.basicInfoCard.timeZone')}
    >
      <div className="flex flex-col">
        {!error && data && (
          <p>
            <span className="mr-2">
              {dayjs().tz(data.timeZoneId).format('LT')}
            </span>
            (UTC
            {(data.rawOffset + data.dstOffset) / 3600 >= 0
              ? '+' + (data.rawOffset + data.dstOffset) / 3600
              : (data.rawOffset + data.dstOffset) / 3600}
            )
          </p>
        )}
      </div>
    </IconListItem>
  );
};

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

type ProjectLocation = {
  street?: string;
  city?: string;
  zipCode?: string;
  country?: string;
};

type ProjectCoordinates = {
  latitude?: string;
  longitude?: string;
};

export const BasicProjectInfo = () => {
  const project = useProject();
  const user = useUser();
  const hasAdminPrivileges = isProjectOwnerOrAdmin(user._id, project);

  const projectVolume = project.projectInformation?.projectVolume;

  const projectLocation = {
    street: project.projectInformation?.street,
    city: project.projectInformation?.city,
    zipCode: project.projectInformation?.zipCode,
    country: project.projectInformation?.country,
  };

  const projectCoordinates = {
    latitude: project.projectInformation?.latitude?.toString(),
    longitude: project.projectInformation?.longitude?.toString(),
  };

  const hasProjectVolume = !!projectVolume;
  const hasProjectLocation = !Object.values(projectLocation).some((v) => !v);
  const hasProjectCoordinates = !Object.values(projectCoordinates).some(
    (v) => !v,
  );

  const gotSomeBasicInfo =
    project.projectInformation &&
    (hasProjectVolume || hasProjectLocation || hasProjectCoordinates);

  return (
    <div className="flex h-full">
      <ProjectImageInput />

      {gotSomeBasicInfo ? (
        <FilledInfoCard
          projectName={project.name}
          companyName={
            isString(project.company) ? project.company : project.company.name
          }
          license={project.license}
          projectVolume={projectVolume}
          projectLocation={hasProjectLocation ? projectLocation : undefined}
          projectCoordinates={
            hasProjectCoordinates ? projectCoordinates : undefined
          }
        />
      ) : (
        <EmptyInfoCard
          projectId={project._id}
          projectName={project.name}
          license={project.license}
          hasAdminPrivileges={hasAdminPrivileges}
        />
      )}
    </div>
  );
};

const FilledInfoCard = ({
  projectName,
  companyName,
  license,
  projectVolume,
  projectLocation,
  projectCoordinates,
}: {
  projectName: string;
  companyName: string;
  license: License;
  projectVolume?: string;
  projectLocation?: ProjectLocation;
  projectCoordinates?: ProjectCoordinates;
}) => {
  const { t } = useTranslation('project');

  const licenseValidation = validateLicense(license);

  function licenseTypeToString() {
    if (license.expirationDate) {
      const date = dayjs(license.expirationDate).format('L');
      return `${license.type} ${t('overview.basicInfoCard.expirationDate', {
        date,
      })}`;
    }
    return license.type;
  }

  return (
    <div className="w-1/2 px-8 py-6">
      <h2 className="text-2xl font-semibold">{projectName}</h2>
      {companyName && (
        <h3 className="mb-6 text-lg font-semibold">{companyName}</h3>
      )}
      <div className="space-y-3">
        {!!projectVolume && (
          <IconListItem
            title={t('overview.basicInfoCard.volume')}
            icon={<FontAwesomeIcon icon={faEuroSign} />}
          >
            <div>{formatter.format(+projectVolume)}</div>
          </IconListItem>
        )}
        {!!projectLocation && <Location location={projectLocation} />}
        {!!projectCoordinates && <TimeZone coordinates={projectCoordinates} />}
        <IconListItem
          title={t('overview.basicInfoCard.license')}
          icon={
            <FontAwesomeIcon
              icon={
                licenseValidation === LicenseEvaluationType.VALID
                  ? faCheckCircle
                  : faExclamationCircle
              }
            />
          }
        >
          <div>{licenseTypeToString()}</div>
        </IconListItem>
      </div>
    </div>
  );
};

const EmptyInfoCard = ({
  projectId,
  projectName,
  license,
  hasAdminPrivileges,
}: {
  projectId: string;
  projectName: string;
  license: License;
  hasAdminPrivileges: boolean;
}) => {
  const { t } = useTranslation('project');

  const isFreeProject = license.type === LicenseType.FREE;

  return (
    <div className="flex w-1/2 flex-col px-8 py-6">
      <div className="flex h-1/2 flex-col justify-start">
        <h2 className="text-2xl font-semibold">{projectName}</h2>
        <div className="mr-auto mt-2 inline-flex items-center gap-2 rounded-md bg-shuttleGray-50 p-2">
          <FontAwesomeIcon
            className={cn({
              'text-shuttleGray-800': isFreeProject,
              'text-orange-400': !isFreeProject,
            })}
            icon={faHardHat}
          />
          <span className="font-semibold">
            {isFreeProject ? 'Free' : 'Basic'}
          </span>
        </div>
      </div>
      <div className="flex h-1/2 flex-grow flex-col justify-start text-center">
        <p className="mb-5 text-shuttleGray-600">
          {t('overview.basicInfoCard.empty.text')}
        </p>
        {hasAdminPrivileges && (
          <Link to={`/p/${projectId}/dashboard/settings/information`}>
            <Button variant="secondary" size="sm" className="mx-auto">
              {t('overview.basicInfoCard.empty.button')}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export function ProjectImageInput() {
  const project = useProject();
  const queryClient = useQueryClient();
  const uploadMutation = useMutation({
    mutationFn: async (file: File) => {
      const { blob, extension } = await resizeImage(file);
      const { url: uploadUrl } = await setProjectImage(project._id, extension);
      return s3.upload(uploadUrl, blob);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['projects', project._id],
      }),
    onError: logger.capture,
  });

  const hasImage = !!project.image;

  const query = useQuery({
    queryKey: ['projects', project._id, 'image'],

    queryFn: () => getProjectImage(project._id),
  });

  return (
    <div className="relative grid h-full w-1/2 place-items-center">
      <Image
        src={query.data?.downloadUrl || projectThumbnail}
        className="h-full object-cover"
      />
      {!hasImage && (
        <div className="absolute bottom-16">
          <ImageInput
            src={query?.data?.downloadUrl}
            onChange={uploadMutation.mutate}
            isLoading={hasImage && query.isPending}
            isUploading={uploadMutation.isPending}
            buttonVariant="secondary"
            buttonSize="sm"
          />
        </div>
      )}
    </div>
  );
}
