/* eslint-disable @typescript-eslint/ban-ts-comment */
function getHitDbId(x: number, y: number) {
  y = 1.0 - y;
  x = x * 2.0 - 1.0;
  y = y * 2.0 - 1.0;

  const vpVec = new THREE.Vector3(x, y, 1);

  const result = NOP_VIEWER?.impl.hitTestViewport(vpVec, false);
  return result ? result.dbId : null;
}

function normalize(screenPoint: { x: number; y: number }) {
  const viewport = NOP_VIEWER?.navigation.getScreenViewport();
  if (!viewport) {
    return screenPoint;
  }

  return {
    x: (screenPoint.x - viewport.left) / viewport.width,
    y: (screenPoint.y - viewport.top) / viewport.height,
  };
}

class SelectionTool extends Autodesk.Viewing.ToolInterface {
  constructor() {
    super();
    // @ts-ignore
    this.names = ['selection-tool'];
    // Hack: delete functions defined *on the instance* of the tool.
    // We want the tool controller to call our class methods instead.
    // @ts-ignore
    delete this.register;
    // @ts-ignore
    delete this.deregister;
    // @ts-ignore
    delete this.activate;
    // @ts-ignore
    delete this.deactivate;
    // @ts-ignore
    delete this.getPriority;
    // @ts-ignore
    delete this.handleSingleTap;
    // @ts-ignore
    delete this.handleSingleClick;
  }

  register() {}

  deregister() {}

  activate() {}

  deactivate() {}

  getPriority() {
    return 42; // Or feel free to use any number higher than 0 (which is the priority of all the default viewer tools)
  }

  update() {
    return false;
  }

  handleSingleTap(event: any) {
    // TODO: Where does the center come from?
    const screenPoint = {
      x: event.center.x,
      y: event.center.y,
    };
    const n = normalize(screenPoint);
    const dbId = getHitDbId(n.x, n.y);

    if (!dbId) {
      NOP_VIEWER?.select([]);
      return true;
    }

    const currentlySelected = NOP_VIEWER?.getSelection();
    if (!(currentlySelected && currentlySelected.length > 0)) {
      NOP_VIEWER?.select([dbId]);
      return true;
    }

    const index = currentlySelected.indexOf(dbId);
    if (index > -1) {
      // found
      const copy = [...currentlySelected];
      copy.splice(index, 1);
      NOP_VIEWER?.select(copy);
    } else {
      const merged = [...currentlySelected, dbId];
      NOP_VIEWER?.select(merged);
    }

    return true;
  }

  handleSingleClick() {
    // do nothing and let viewer & our old logic do the job
    return false;
  }

  _update() {
    // Here we will be updating the actual geometry
  }
}

export default SelectionTool;
