import {
  faGlobe,
  faMapMarkerAlt,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { Company, CompanyPopulatedUser } from 'shared';

import { removeUserFromCompany } from 'src/admin/apiCompanies';
import { Badge } from 'src/common/components/Badge';
import { Card } from 'src/common/components/Card';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';

type Props = {
  company: Company;
  user: CompanyPopulatedUser;
};

export const UserCompanyRow = ({ company, user }: Props) => {
  const { t } = useTranslation(['admin', 'common']);
  const queryClient = useQueryClient();

  const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] =
    useState(false);

  const userId = user._id;
  const isPersonalCompany = company._id === user.personalCompany._id;
  const showRemoveButton = company.owner !== userId;

  async function callRemoveUserFromCompany() {
    try {
      await removeUserFromCompany(company._id, userId);
      await queryClient.invalidateQueries({
        queryKey: ['admin-organizations', 'admin-projects', 'admin-users'],
      });
      toast.success(t('admin:users.modal.removeUserFromOrgSuccess'));
    } catch {
      toast.error(t('admin:users.modal.removeUserFromOrgFailure'));
    } finally {
      setIsRemoveConfirmationOpen(false);
    }
  }

  const hasLocation = company.zipCode || company.cityName;
  const hasWebsite = company.websiteLink;
  const hasInformation = hasLocation || hasWebsite;

  return (
    <>
      <Card className="space-y-6 p-6">
        <div>
          <h2 className="text-lg font-semibold">{company.name}</h2>
          {isPersonalCompany && <Badge>{t('common:personalWorkspace')}</Badge>}
          <div className="text-shuttleGray-600">{company.description}</div>
        </div>
        {hasInformation && (
          <div className="flex space-x-24">
            {hasLocation && (
              <div className="flex w-1/2 flex-col items-start truncate">
                <div className="flex">
                  <div className="flex w-6 items-center justify-start">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </div>
                  <div className="font-semibold">{t('common:address')}</div>
                </div>
                <div className="pl-6 text-shuttleGray-600">
                  {`${company.zipCode || ''}, ${company.cityName || ''}`}
                </div>
              </div>
            )}
            {hasWebsite && (
              <div className="flex w-1/2 flex-col items-start truncate">
                <div className="flex">
                  <div className="flex w-6 items-center justify-start">
                    <FontAwesomeIcon icon={faGlobe} />
                  </div>
                  <div className="font-semibold">{t('common:website')}</div>
                </div>
                <div className="truncate pl-6 text-shuttleGray-600">
                  {company.websiteLink}
                </div>
              </div>
            )}
          </div>
        )}
        {showRemoveButton && (
          <Button
            variant="red"
            onClick={() => setIsRemoveConfirmationOpen(true)}
          >
            <FontAwesomeIcon icon={faTrash} />
            {t('admin:users.modal.removeUserFromOrganization')}
          </Button>
        )}
      </Card>
      <Modal
        isOpen={isRemoveConfirmationOpen}
        onRequestClose={() => setIsRemoveConfirmationOpen(false)}
      >
        <ConfirmationDialog
          title={t('admin:users.modal.removeUserFromOrgTitle')}
          message={
            <Trans
              ns="admin"
              i18nKey="users.modal.removeUserFromOrgMessage"
              values={{ companyName: company.name }}
              components={[
                <span className="font-semibold text-shuttleGray-800" key="1" />,
              ]}
            />
          }
          onCancel={() => setIsRemoveConfirmationOpen(false)}
          onConfirm={callRemoveUserFromCompany}
          customConfirmText={t('common:button.delete')}
        />
      </Modal>
    </>
  );
};
