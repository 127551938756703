import { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { useTranslation } from 'react-i18next';

import { cn } from 'src/common/utils';
import { getWeatherIconsForDescriptions } from 'src/Conrep/App/Report/DayInfo/Weather/utils';
import { Fact } from 'src/common/components/Fact';
import { Tabs } from '../Tabs';
import { Spinner } from '../Spinner';
import {
  WeatherEntry,
  WeatherIcon,
} from 'src/Conrep/App/Report/DayInfo/Weather/types';

import 'chartjs-adapter-dayjs-3';

type Props = {
  weatherForecast: WeatherEntry[] | null;
  showForecast?: boolean;
  showIconBar?: boolean;
  className?: string;
};

type IconProps = {
  icons: WeatherIcon[];
  mainIcon?: boolean;
};

const Icons = ({ icons, mainIcon = true }: IconProps) => {
  const { t } = useTranslation(['common', 'conrep']);

  return (
    <div className="flex w-10 items-center justify-center">
      {icons.map((icon, index) => (
        <div
          className={cn('flex items-center justify-center', {
            'h-11 w-20': mainIcon,
            'h-7 w-10': !mainIcon,
          })}
          key={index}
        >
          <img
            alt={t(icon.label as any)}
            className="w-full"
            src={icon.source}
          />
        </div>
      ))}
    </div>
  );
};

export const WeatherWidget = ({
  weatherForecast,
  showForecast = false,
  showIconBar = false,
  className = '',
}: Props) => {
  const { t } = useTranslation(['common', 'conrep']);
  const tempChart = useRef<Chart<'line'> | null>(null);
  const timeSeries = useRef(null);

  const handleTabChange = (value: string) => {
    if (tempChart.current && weatherForecast) {
      switch (value) {
        case 'temperature':
          tempChart.current.data.datasets[0].data = weatherForecast.map((x) =>
            parseFloat(x.temperature),
          );
          break;
        case 'humidity':
          tempChart.current.data.datasets[0].data = weatherForecast.map((x) =>
            x.humidity ? x.humidity : 0,
          );
          break;
      }
      tempChart.current.update();
    }
  };

  useEffect(() => {
    if (tempChart.current) {
      tempChart.current.destroy();
    }
    let ctx = null;
    if (timeSeries.current) {
      ctx = (timeSeries.current as any).getContext('2d');
    }

    if (ctx && weatherForecast) {
      const labels = weatherForecast.map((x) => x.time);
      const data = weatherForecast.map((x) => parseFloat(x.temperature));

      tempChart.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: '',
              data: data,
              borderColor: 'rgba(101, 82, 221)',
              borderWidth: 1,
              borderJoinStyle: 'round',
              fill: false,
              tension: 0.4,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'timeseries',
              display: true,
              time: {
                unit: 'day',
                /*
                displayFormats: {
                  day: 'MM',
                },
                */
              },
              ticks: {
                source: 'labels',
              },
            },
          },
        },
      });
    }
  }, [weatherForecast]);

  if (
    !weatherForecast ||
    weatherForecast.length === 0 ||
    !weatherForecast[0].description
  ) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner className="text-5xl text-gray-500" />
      </div>
    );
  }

  // this is a hack to handle the unstable type of daily vs hourly precipitation
  const precipitation =
    typeof weatherForecast[0].precipitation === 'object'
      ? weatherForecast[0].precipitation['1h']
      : weatherForecast[0].precipitation;

  return (
    <div className={cn('flex w-full flex-col items-center', className)}>
      <div className="min-h-40 flex w-full">
        <div className="flex grow content-center gap-2">
          <div className="flex content-center">
            {weatherForecast[0].description && (
              <Icons
                icons={getWeatherIconsForDescriptions(
                  weatherForecast[0].description,
                )}
              />
            )}
          </div>
          <div className="flex content-center items-center text-[32px] font-semibold">
            {Math.round(parseFloat(weatherForecast[0].temperature))}°C
          </div>
        </div>
        <div className="flex grow items-center">
          <div className="flex grow flex-row items-center gap-4">
            <Fact
              label={t('common:weather.wind')}
              variant="gray"
              className="w-1/2 text-lg"
            >
              {Math.round(
                (weatherForecast[0].windSpeed
                  ? weatherForecast[0].windSpeed
                  : 0) * 10,
              ) / 10}{' '}
              m/s
            </Fact>
            <Fact
              label={t('common:weather.precipitation')}
              variant="gray"
              className="w-1/2 text-lg"
            >
              {precipitation} mm
            </Fact>
          </div>
        </div>
      </div>

      {showForecast && (
        <div className="mt-4 w-full">
          <Tabs defaultValue="temperature" onValueChange={handleTabChange}>
            <Tabs.List>
              <Tabs.Tab value="temperature">
                {t('common:weather.temperature')}
              </Tabs.Tab>
              <Tabs.Tab value="humidity">
                {t('common:weather.humidity')}
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>
          <div className="my-3 min-h-[128px] w-full overflow-hidden">
            <canvas ref={timeSeries} className="pl-2 pr-2" />
          </div>
          {showIconBar && (
            <div className="flex w-full content-evenly justify-between">
              {weatherForecast.map((fc) => {
                return (
                  <div key={fc.time}>
                    {fc.description && (
                      <Icons
                        icons={getWeatherIconsForDescriptions(fc.description)}
                        mainIcon={false}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
