import { ConrepAppFilterPreset, Vector3 } from 'src/Conrep/App/types';
import { FileVersionView, FileVersionViewReference } from 'src/file/types';

export enum KeyTypes {
  _3D = '3d',
  _2D = '2d',
  OTHER = 'other',
}

export type GroupedViewerModelOptions = {
  [k in KeyTypes]: FileVersionView[];
};

export type ForgeViewerNaming = {
  [dbId: number]: string;
};

export type ForgeViewerDbIdToExternalIdMapping = {
  [id: number]: string;
};

export type ForgeViewerExternalIdToDbIdMapping = {
  [externalId: string]: number;
};

export type ForgeViewerExternalIdToFileIdMapping = {
  [externalId: string]: string;
};

export enum ForgeViewerHeaderType {
  SHEETSET,
  FILE,
  VIEW,
}

export type ForgeToken = {
  forgeToken: {
    credentials: {
      access_token: string;
      expires_in: number;
    };
  };
};

export type ForgeView = {
  guid: string;
  name: string;
  viewableID: string;
  role?: string;
  size?: number;
};

export type RestoreViewData = {
  viewReference: FileVersionViewReference;
  eye: Vector3;
  up: Vector3;
  target: Vector3;
  filterPreset: ConrepAppFilterPreset;
  markupUrl?: string;
};

export type ForgeElementProperties = Record<string, Record<string, string>>;

export type ForgeElementPropertiesSet = Record<string, string[]>;
