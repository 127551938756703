import { QueryCache, QueryClient } from '@tanstack/react-query';

import { UnauthorizedError } from './errors';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (err, query) => {
      if (query.meta?.isAuthRefresh) {
        return;
      }
      if (err instanceof UnauthorizedError) {
        queryClient.resetQueries();
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    },
    mutations: {
      retry: 4,
      onError: (err) => {
        if (err instanceof UnauthorizedError) {
          queryClient.resetQueries();
        }
      },
    },
  },
});
