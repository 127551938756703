import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button } from 'src/common/components/buttons/Button';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import {
  fileVersionViewToReference,
  getActiveModelFileVersion,
  isModelFile,
} from 'src/file/util';
import { CheckboxTree, Node } from 'src/common/components/CheckboxTree';
import {
  FileVersionView,
  ForgeProgress,
  ModelFile,
  SitelifeFile,
} from 'src/file/types';
import { SheetSet } from '../types';
import { useFilesQuery } from 'src/file/queries';
import { getViewDict } from 'src/project/utils';

function isViewInSheetSet(view: FileVersionView, sheetSet: SheetSet) {
  if (!sheetSet) {
    return false;
  }
  return sheetSet.views.find(
    (v) =>
      v.viewableId === view.viewableId &&
      v.file === view.file &&
      v.fileVersion === view.fileVersion,
  );
}

function getNodes(
  files: SitelifeFile[],
  sheetSet?: SheetSet,
): [Node[], string[]] {
  const initiallyCheckedNodes: string[] = [];
  const fileNodes: Node[] = [];

  files
    ?.filter(isModelFile)
    .filter((f): f is ModelFile & { _id: string } => f._id !== undefined)
    .filter(
      (f) =>
        f.versions.find((fv) => fv._id === f.activeVersion)?.forgeProgress ===
        ForgeProgress.DONE,
    )
    .forEach((f) => {
      const activeVersion = getActiveModelFileVersion(f);
      activeVersion?.views.forEach((v) => {
        if (sheetSet && isViewInSheetSet(v, sheetSet)) {
          initiallyCheckedNodes.push(v._id);
        }
      });
      const views: Node[] =
        activeVersion?.views.map((v) => ({ label: v.name, value: v._id })) ||
        [];
      const file: Node = {
        label: f.name,
        value: f._id,
        children: views,
      };
      fileNodes.push(file);
    });

  return [fileNodes, initiallyCheckedNodes];
}

type Props = {
  sheetSet?: SheetSet;
  takenNames?: string[];
  onCancel: () => void;
  onSave: (sheetSet: Partial<SheetSet>) => void;
};

export const SheetSetForm = ({
  sheetSet,
  takenNames = [],
  onCancel,
  onSave,
}: Props) => {
  const { t } = useTranslation(['sheetset', 'common']);
  const { projectId = '' } = useParams();
  const { data: files } = useFilesQuery(projectId);
  const viewDict = getViewDict(files);
  const [checkedNodes, setCheckedNodes] = useState<string[]>(() => {
    const [, initiallyCheckedNodes] = getNodes(files || [], sheetSet);
    return initiallyCheckedNodes;
  });
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [newName, setNewName] = useState(sheetSet?.name);

  const [fileNodes] = getNodes(files || [], sheetSet);

  const isEditedSheetSetNameSame = sheetSet && sheetSet.name === newName;
  const isSheetSetNameTaken =
    !isEditedSheetSetNameSame && takenNames.some((n) => n === newName);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!viewDict) return;

    const views = Object.values(viewDict)
      .filter((v) => checkedNodes.includes(v._id))
      .map((v) => fileVersionViewToReference(v));
    const ssObject: Partial<SheetSet> = {
      name: newName,
      views,
    };
    onSave(ssObject);
  };

  const selectedViews = checkedNodes.length;

  const formTitle = sheetSet
    ? `${t('sheetset:form.edit')} ${sheetSet.name}`
    : `${t('sheetset:form.newSheetSet')}`;

  return (
    <form onSubmit={onSubmit} className="space-y-4 p-4">
      <h3 className="text-xl font-semibold">{formTitle}</h3>

      <TextInput
        label={t('sheetset:form.name')}
        className="w-full"
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        placeholder={t('sheetset:form.nameSheetSet')}
        required
        error={
          isSheetSetNameTaken && t('sheetset:form.nameTaken', { name: newName })
        }
      />

      {files && files.length > 0 ? (
        <div className="flex flex-col space-y-2">
          <span className="text-lg font-semibold">
            {t('sheetset:form.views')}
          </span>
          {selectedViews < 1 && (
            <span className="text-red-500">
              {t('sheetset:form.viewsRequired')}
            </span>
          )}
          <CheckboxTree
            nodes={fileNodes}
            checked={checkedNodes}
            expanded={expandedNodes}
            onCheck={setCheckedNodes}
            onExpand={setExpandedNodes}
            showExpandAll
            showNodeIcon={false}
          />
        </div>
      ) : null}

      <div className="flex h-full justify-end space-x-2">
        <Button
          variant="tertiary"
          onClick={() => {
            onCancel();
          }}
        >
          {t('common:button.abort')}
        </Button>
        <Button
          type="submit"
          disabled={!newName || isSheetSetNameTaken || selectedViews < 1}
        >
          {sheetSet ? t('common:button.save') : t('sheetset:form.add')}
        </Button>
      </div>
    </form>
  );
};
