import React from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from 'src/common/utils';
import { Spinner } from 'src/common/components/Spinner';

const buttonVariants = cva(
  'relative inline-flex cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-sm border leading-none tracking-wide focus:outline-none disabled:cursor-default',
  {
    variants: {
      variant: {
        primary:
          'border-transparent bg-blue-600 font-semibold text-white hover:bg-blue-700 focus:bg-blue-800 disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-100',
        secondary:
          'border-blue-600 bg-transparent font-medium text-blue-600 hover:border-blue-700 hover:bg-blue-50 hover:text-blue-700 focus:border-blue-700 focus:bg-blue-100 focus:text-blue-700 disabled:border-transparent disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-100',
        tertiary:
          'border-transparent font-medium text-shuttleGray-500 hover:bg-shuttleGray-50 focus:bg-shuttleGray-100 disabled:text-gray-400 disabled:hover:bg-transparent',
        green:
          'border-transparent font-medium text-green-700 hover:bg-green-100 focus:bg-green-200 disabled:text-green-400 disabled:hover:bg-transparent',
        orange:
          'border-transparent font-medium text-orange-700 hover:bg-orange-100 focus:bg-orange-200 disabled:text-orange-400 disabled:hover:bg-transparent',
        red: 'border-transparent font-medium text-red-700 hover:bg-red-100 focus:bg-red-200 disabled:text-red-400 disabled:hover:bg-transparent',
        danger:
          'border-transparent font-medium text-red-700 hover:bg-red-100 focus:bg-red-200 disabled:text-red-400 disabled:hover:bg-transparent',
      },
      size: {
        sm: 'h-8 px-3 text-sm',
        md: 'h-10 px-4 text-base',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  },
);

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    loading?: boolean;
  };

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant = 'primary',
      size = 'md',
      loading,
      className,
      children,
      disabled,
      onClick,
      ...rest
    },
    ref,
  ) => {
    return (
      <button
        type="button"
        ref={ref}
        disabled={disabled || loading}
        {...rest}
        onClick={onClick}
        className={cn(buttonVariants({ variant, size, className }))}
      >
        <div
          className={cn('flex content-center items-center gap-2', {
            invisible: loading,
          })}
        >
          {children}
        </div>
        {loading && <Spinner containerClassName="absolute" />}
      </button>
    );
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
