import { HTMLAttributes, ReactNode, useState } from 'react';

import { cn } from 'src/common/utils';

function getInitials(name: string): string {
  const words = name.trim().toUpperCase().split(' ');
  if (words.length < 2) {
    if (words[0].length > 2) {
      return words[0].slice(0, 2);
    } else {
      return words[0].charAt(0);
    }
  }
  return words.slice(0, 2).reduce((acc, value) => `${acc}${value[0]}`, '');
}

export type Props = {
  alt: string;
  src?: string;
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  children?: ReactNode;
};
export const Avatar = ({
  alt,
  src,
  className,
  size = 'md',
  children,
  ...rest
}: Props & HTMLAttributes<HTMLDivElement>) => {
  const [isImageError, setIsImageError] = useState(false);

  const twoLetterAlt = getInitials(alt);

  return (
    <div
      className={cn(
        className,
        'flex shrink-0 items-center justify-center overflow-hidden rounded-full bg-teal-600 font-medium uppercase leading-none text-white',
        {
          'h-5 w-5 text-xs': size === 'xs',
          'h-8 w-8 text-sm': size === 'sm',
          'h-12 w-12 text-lg': size === 'md',
          'h-16 w-16 text-2xl': size === 'lg',
        },
      )}
      {...rest}
    >
      {children ? (
        children
      ) : src && !isImageError ? (
        <img
          alt={alt}
          src={src}
          className="h-full w-full object-cover text-center text-transparent"
          onError={() => setIsImageError(true)}
        />
      ) : (
        twoLetterAlt
      )}
    </div>
  );
};
