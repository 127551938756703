import { useTranslation } from 'react-i18next';

import { Modal } from 'src/common/components/Modal';
import { Spinner } from 'src/common/components/Spinner';

type Props = {
  onClose: () => void;
  uploadProgress?: number;
};

export const UploadingModal = ({ onClose, uploadProgress }: Props) => {
  const { t } = useTranslation('file');

  const getProgressMessage = () => {
    if (uploadProgress === 100) {
      return t('upload.uploadingModal.completeMessage');
    }
    return t('upload.uploadingModal.progressMessage', { uploadProgress });
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <Modal.Title>{t('upload.uploadingModal.title')}</Modal.Title>
        <Modal.Close onClose={onClose} />
      </div>
      <h2 className=" text-shuttleGray-600">
        {t('upload.uploadingModal.subTitle')}
      </h2>
      {uploadProgress !== undefined ? (
        <div className="flex flex-col items-center">
          <Spinner
            containerClassName="w-full text-center mt-28"
            className="text-2xl"
          />
          <p className="mb-[168px] mt-2 text-shuttleGray-600">
            {getProgressMessage()}
          </p>
        </div>
      ) : (
        <Spinner
          containerClassName="w-full text-center mt-28 mb-[168px]"
          className="text-2xl"
        />
      )}
    </>
  );
};
