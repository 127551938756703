import { faPaste } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { UseFormRegisterReturn, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { updateProject, upgradeProjectLicense } from 'src/admin/apiProjects';
import { Card } from 'src/common/components/Card';
import { Button } from 'src/common/components/buttons/Button';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { TextAreaInput } from 'src/common/components/inputs/TextAreaInput/TextAreaInput';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import {
  GetAdminProjectResponse,
  LicenseType,
} from 'src/project/types/projects';
import { Modal, useModal } from 'src/common/components/Modal';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';

import type { IUpdateProjectPayload } from 'shared';

type Props = {
  project: GetAdminProjectResponse;
  onClickChangeCompany: () => void;
  onClose: () => void;
};

export const ProjectEditModalInformation = ({
  project,
  onClickChangeCompany,
  onClose,
}: Props) => {
  const { t } = useTranslation(['admin', 'common', 'project']);
  const queryClient = useQueryClient();

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty, isValid },
  } = useForm<IUpdateProjectPayload>({
    mode: 'onSubmit',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [clipboardPermitted, setClipboardPermitted] = useState<boolean>(false);

  useEffect(() => {
    if (project) {
      reset({
        name: project.name,
        description: project.description,
        projectInformation: {
          street: project.projectInformation?.street,
          zipCode: project.projectInformation?.zipCode,
          city: project.projectInformation?.city,
          country: project.projectInformation?.country,
          latitude: project.projectInformation?.latitude,
          longitude: project.projectInformation?.longitude,
        },
        license: {
          type: project.license.type,
          expirationDate: dayjs(project.license.expirationDate).format(
            'YYYY-MM-DD',
          ),
        },
      });
    } else {
      reset({});
    }
  }, [reset, project]);

  useEffect(() => {
    if (!navigator?.permissions?.query) {
      return;
    }

    navigator.permissions
      .query({ name: 'clipboard-read' as PermissionName })
      .then((permissionStatus) => {
        if (permissionStatus.state === 'denied') {
          setClipboardPermitted(false);
        } else {
          setClipboardPermitted(true);
        }
      })
      .catch(() => {
        setClipboardPermitted(false);
      });
  }, []);

  const pasteFromClipboard = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        try {
          const split = text.split(',');
          if (split.length < 2) {
            throw new Error('Wrong Format');
          }
          const lat = parseFloat(split[0]);
          const long = parseFloat(split[1]);
          if (!lat || !long) {
            throw new Error('Wrong Format');
          }

          setValue('projectInformation.latitude', lat, { shouldDirty: true });
          setValue('projectInformation.longitude', long, { shouldDirty: true });
          toast.success(
            t('project:settings.information.coordinates.pasteSuccess'),
          );
        } catch (err) {
          toast.error(
            t('project:settings.information.coordinates.pasteFailure'),
          );
        }
      })
      .catch(() => {
        toast.error(t('project:settings.information.coordinates.pasteFailure'));
      });
  };

  async function handleSave(projectData: IUpdateProjectPayload) {
    try {
      setIsLoading(true);
      await updateProject(project._id, projectData);
      await queryClient.invalidateQueries({ queryKey: ['admin-projects'] });
      toast.success(t('admin:projects.modal.information.updateSuccess'));
    } catch {
      toast.error(t('admin:projects.modal.information.updateFailure'));
    } finally {
      setIsLoading(false);
    }
  }

  async function onConfirmUpgradeToBasic() {
    try {
      setIsLoading(true);
      await upgradeProjectLicense(project._id);
      await queryClient.invalidateQueries({ queryKey: ['admin-projects'] });
      toast.success(t('admin:projects.modal.license.upgradeSuccess'));
    } catch {
      toast.error(t('admin:projects.modal.license.upgradeFailure'));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="flex h-[600px] w-[700px] flex-col justify-between">
      <div className="mt-6 h-full space-y-6 overflow-y-auto bg-gray-100 p-6">
        {project.license.type !== LicenseType.DEMO && (
          <div>
            <h3 className="text-lg font-semibold">
              {t('admin:projects.modal.information.organization')}
            </h3>
            <Card className="mt-4 flex justify-between p-6">
              <div>
                <h4 className="font-semibold">{project.company.name}</h4>
                <div className="text-sm text-shuttleGray-600">
                  {project.company.description}
                </div>
              </div>
              <div>
                <Button variant="secondary" onClick={onClickChangeCompany}>
                  {t('admin:projects.modal.information.newOrganization')}
                </Button>
              </div>
            </Card>
          </div>
        )}

        <form className="flex flex-col justify-between space-y-6">
          <div>
            <h3 className="text-lg font-semibold">
              {t('admin:projects.modal.information.basicInformation')}
            </h3>
            <Card className="mt-4 flex flex-col justify-between space-y-4 p-6">
              <TextInput
                {...register('name')}
                label={t('common:labels.name')}
                placeholder={t('admin:projects.modal.namePlaceholder')}
                type="text"
                required
              />
              <TextAreaInput
                {...register('description')}
                className="resize-none"
                rows={5}
                label={t('common:labels.description')}
                placeholder={t('admin:projects.modal.descriptionPlaceholder')}
              />
              {/* TODO: PROJECT IMAGE INPUT GOES HERE */}
              <div className="w-full text-lg text-red-600">
                NEW IMAGE COMPONENT COMING SOON
              </div>
            </Card>
          </div>
          <div>
            <h3 className="text-lg font-semibold">
              {t('admin:projects.modal.information.location')}
            </h3>
            <Card className="mt-4 flex flex-col justify-between p-6">
              <TextInput
                {...register('projectInformation.street')}
                label={t('project:settings.information.location.street')}
                placeholder={t(
                  'project:settings.information.location.streetPlaceholder',
                )}
                className="mb-4 w-full"
              />
              <div className="mb-4 flex gap-4">
                <TextInput
                  {...register('projectInformation.zipCode')}
                  label={t('project:settings.information.location.zipCode')}
                  placeholder={t(
                    'project:settings.information.location.zipCodePlaceholder',
                  )}
                  className="w-2/5"
                  type="number"
                  maxLength={10}
                />
                <TextInput
                  {...register('projectInformation.city')}
                  label={t('project:settings.information.location.city')}
                  placeholder={t(
                    'project:settings.information.location.cityPlaceholder',
                  )}
                  className="w-full"
                />
              </div>
              <TextInput
                {...register('projectInformation.country')}
                label={t('project:settings.information.location.country')}
                placeholder={t(
                  'project:settings.information.location.countryPlaceholder',
                )}
                defaultValue=""
                className="w-full"
              />
            </Card>
          </div>
          <div>
            <h3 className="text-lg font-semibold">
              {t('admin:projects.modal.information.coordinates')}
            </h3>
            <Card className="mt-4 flex flex-col justify-between space-y-4 p-6">
              {clipboardPermitted && (
                <div className="flex flex-col">
                  <InlineMessage className="mb-4">
                    {t('project:settings.information.coordinates.googleMaps')}
                  </InlineMessage>
                  <Button
                    variant="secondary"
                    className="mb-4"
                    onClick={pasteFromClipboard}
                  >
                    <FontAwesomeIcon icon={faPaste} />

                    {t('project:settings.information.coordinates.paste')}
                  </Button>
                </div>
              )}
              <TextInput
                {...register('projectInformation.latitude')}
                label={t('project:settings.information.coordinates.latitude')}
                placeholder={t(
                  'project:settings.information.coordinates.latitudePlaceholder',
                )}
                type="number"
                min="-90"
                max="90"
                step="0.00000000000000001"
                className="mb-4"
              />
              <TextInput
                {...register('projectInformation.longitude')}
                label={t('project:settings.information.coordinates.longitude')}
                placeholder={t(
                  'project:settings.information.coordinates.longitudePlaceholder',
                )}
                type="number"
                min="-180"
                max="180"
                step="0.00000000000000001"
                className="mb-4"
              />
            </Card>
          </div>
          {project.license.type !== LicenseType.DEMO && (
            <div>
              <h3 className="text-lg font-semibold">
                {t('projects.modal.license.title')}
              </h3>
              {project.license.type === LicenseType.FREE ? (
                <FreeLicenseCard
                  onConfirmUpgradeToBasic={onConfirmUpgradeToBasic}
                  isLoading={isLoading}
                />
              ) : (
                <BasicLicenseCard
                  register={register('license.expirationDate')}
                />
              )}
            </div>
          )}
        </form>
      </div>
      <div className="flex items-center justify-between p-6">
        <div className="flex-1" />
        <div className="flex-1 text-right">
          <Button variant="tertiary" onClick={onClose} disabled={isLoading}>
            {t('common:button.abort')}
          </Button>
          <Button
            className="ml-2"
            onClick={handleSubmit(handleSave)}
            disabled={!isDirty || !isValid}
            loading={isLoading}
          >
            {t('common:button.save')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const FreeLicenseCard = ({
  onConfirmUpgradeToBasic,
  isLoading,
}: {
  onConfirmUpgradeToBasic: () => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation('admin');

  const { isOpen, open, close } = useModal();

  return (
    <Card className="mt-4 flex flex-col justify-between space-y-4 p-6">
      <p>
        <Trans
          ns="admin"
          i18nKey="projects.modal.license.freeDescription"
          components={[
            <span key={0} className="font-semibold" />,
            <span key={0} className="text-red-600" />,
          ]}
        />
      </p>
      <Button onClick={open} loading={isLoading}>
        {t('projects.modal.license.upgradeTitle')}
      </Button>
      <Modal isOpen={isOpen} onRequestClose={close}>
        <ConfirmationDialog
          title={t('projects.modal.license.upgradeTitle')}
          message={
            <Trans
              ns="admin"
              i18nKey="projects.modal.license.upgradeMessage"
              components={[<span key={0} className="font-semibold" />]}
            />
          }
          onCancel={close}
          onConfirm={() => {
            onConfirmUpgradeToBasic();
            close();
          }}
          customConfirmText={t('projects.modal.license.upgradeConfirm')}
        />
      </Modal>
    </Card>
  );
};

const BasicLicenseCard = ({
  register,
}: {
  register: UseFormRegisterReturn;
}) => {
  const { t } = useTranslation('admin');
  return (
    <Card className="mt-4 flex flex-col justify-between space-y-4 p-6">
      <TextInput
        {...register}
        type="date"
        label={t('projects.modal.license.expirationDate')}
      />
    </Card>
  );
};
