import { faArrowLeft, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import NoLicenseSVG from 'src/assets/illu_invalid-license.svg?react';
import { Button } from 'src/common/components/buttons/Button';

export const NoLicense = () => {
  const { t } = useTranslation('project');
  const navigate = useNavigate();
  return (
    <div className="mb-auto mt-auto flex w-full flex-col items-center">
      <NoLicenseSVG className="h-auto w-36" />
      <div className="flex max-w-lg flex-col items-center">
        <span className="mt-6 text-center text-2xl font-semibold text-shuttleGray-800">
          {t('NoLicense.noLicenseHeader')}
        </span>
        <span className="my-4 text-center text-base font-normal text-shuttleGray-600">
          {t('NoLicense.noLicenseContent')}
        </span>
        <div className="flex space-x-2">
          <Button
            variant="secondary"
            onClick={() => {
              navigate('/');
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            {t('NoLicense.back')}
          </Button>
          <a href="mailto:support@sitelife.com">
            <Button>
              <FontAwesomeIcon icon={faEnvelope} />
              {t('NoLicense.contactUs')}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
