import { useQuery } from '@tanstack/react-query';

import { getProjectFiles } from './api';

export function useFilesQuery(projectId: string) {
  return useQuery({
    queryKey: ['projects', projectId, 'files'],

    queryFn: () => getProjectFiles(projectId),
  });
}
