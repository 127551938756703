import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectSitelife } from 'src/common/components/SelectSitelife';
import { Button } from 'src/common/components/buttons/Button';

export interface ParameterOption {
  value: string;
  label: string;
  isDisabled: boolean;
}

type Props = {
  parameters: string[];
  localParameters: string[];
  onAddParameter: (parameter: string) => void;
  parametersIncludePath?: boolean;
};

export const ParameterSelection = ({
  parameters,
  localParameters,
  onAddParameter,
  parametersIncludePath = false,
}: Props) => {
  const { t } = useTranslation('project');

  const [selectedParameter, setSelectedParameter] =
    useState<ParameterOption | null>();

  useEffect(() => {
    setSelectedParameter(null);
  }, [parameters]);

  const getNameOfParamPath = (path: string): string => {
    const pathSections = path.split('.');
    return pathSections[pathSections.length - 1];
  };

  const parameterOptions = parameters.map((parameter) => ({
    label: parametersIncludePath ? getNameOfParamPath(parameter) : parameter,
    value: parameter,
    isDisabled: localParameters.includes(parameter),
  }));

  const noParametersAvailable =
    !parameterOptions || parameterOptions.length <= 0;

  return (
    <div className="flex gap-4">
      <div className="flex w-full flex-col gap-1.5">
        {t('settings.modelParameter.parameter')}
        <SelectSitelife
          virtualized
          flexStylesContainer
          options={parameterOptions}
          value={selectedParameter}
          onChange={(newParameter: ParameterOption) =>
            setSelectedParameter(newParameter)
          }
          isDisabled={noParametersAvailable}
          usePortaling
        />
      </div>
      <Button
        className="mt-auto"
        onClick={() => {
          if (selectedParameter?.value) {
            onAddParameter(selectedParameter.value);
          }
          setSelectedParameter(null);
        }}
        disabled={noParametersAvailable || !selectedParameter}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </div>
  );
};
