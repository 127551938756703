import * as amplitude from '@amplitude/analytics-browser';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { MIN_UPLOAD_SIZE } from 'src/Forge/constants';
import { s3 } from 'src/common/fetch';
import toast from 'src/common/toast';
import {
  completeBatchSignedS3Upload,
  createModelFile,
  deleteFile,
} from 'src/file/api';
import { FailureModal } from 'src/file/components/Upload/NewFile/FailureModal';
import { SelectModal } from 'src/file/components/Upload/NewFile/SelectModal';
import { SuccessModal } from 'src/file/components/Upload/NewFile/SuccessModal';
import { UploadingModal } from 'src/file/components/Upload/NewFile/UploadingModal';
import { useFilesQuery } from 'src/file/queries';
import {
  FileDataWrapper,
  FileType,
  ForgeBatchSignedS3Response,
  ModelFile,
} from 'src/file/types';
import {
  createFileObjects,
  getFileType,
  isForgeBatchUpload,
} from 'src/file/util';

export enum UploadState {
  SELECT = 'SELECT',
  UPLOADING = 'UPLOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

type Props = {
  fileType: FileType.REVIT | FileType.IFC | FileType.PDF_PLAN;
  onClose: () => void;
};

async function uploadChunkToS3(
  uploadUrl: string,
  chunk: Blob,
  mimeType: string,
) {
  await s3.upload(uploadUrl, chunk, {
    'Content-Type': mimeType,
  });
}

async function uploadFileToS3(uploadUrl: string, file: File, mimeType: string) {
  await s3.upload(uploadUrl, file, {
    'Content-Type': mimeType,
  });
}

export const NewFileModal = ({ fileType, onClose }: Props) => {
  const { t } = useTranslation('file');
  const { projectId = '' } = useParams();
  const { data: files } = useFilesQuery(projectId);
  const queryClient = useQueryClient();
  const [uploadState, setUploadState] = useState(UploadState.SELECT);
  const [uploadProgress, setUploadProgress] = useState<number | undefined>(
    undefined,
  );
  const [createdModelFile, setCreatedModelFile] = useState<
    ModelFile | undefined
  >(undefined);

  const fileNames = files?.map((file) => file.name) || [];

  const wrapFile = (file: File) => {
    try {
      const { fileType: checkedFileType, mimeType } = getFileType(file);
      if (checkedFileType !== fileType) {
        throw new Error();
      }
      const fileWrapper: FileDataWrapper = {
        file,
        uploaded: false,
        failed: false,
        fileType,
        mimeType,
      };
      uploadFile(fileWrapper);
    } catch (e) {
      toast.error(`${t('upload.modal.unknownFileType')}`);
    }
  };

  async function uploadFileInChunks(
    uploadUrl: ForgeBatchSignedS3Response,
    fileWrapper: FileDataWrapper,
    created: ModelFile,
  ) {
    const totalChunks = uploadUrl.urls.length;
    let uploadedChunks = 0;
    const chunks: Blob[] = [];

    let start = 0;
    let end = MIN_UPLOAD_SIZE;

    while (start < fileWrapper.file.size) {
      const slicedFile = fileWrapper.file.slice(start, end);
      chunks.push(slicedFile);
      start = end;
      end = start + MIN_UPLOAD_SIZE;
    }

    // Upload each chunk
    for (const chunk of chunks) {
      await uploadChunkToS3(
        uploadUrl.urls[uploadedChunks],
        chunk,
        fileWrapper.mimeType || created.extension,
      );

      uploadedChunks++;
      const percentCompleted = Math.round((uploadedChunks * 100) / totalChunks);
      setUploadProgress(percentCompleted);
    }

    // Complete the batch upload
    await completeBatchSignedS3Upload(
      projectId,
      uploadUrl.uploadKey,
      created.versions[0].objectKey,
    );
  }

  const uploadFile = async (fileWrapper: FileDataWrapper) => {
    try {
      if (!fileWrapper) {
        throw new Error();
      }
      setUploadState(UploadState.UPLOADING);
      const fileObjects = await createFileObjects([fileWrapper], '/');
      const { created, uploadUrl } = await createModelFile(
        projectId,
        fileObjects[0],
      );
      setCreatedModelFile(created);

      if (isForgeBatchUpload(uploadUrl)) {
        await uploadFileInChunks(uploadUrl, fileWrapper, created);
      } else {
        await uploadFileToS3(
          uploadUrl,
          fileWrapper.file,
          fileWrapper.mimeType || created.extension,
        );
      }
      queryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'files'],
      });
      setUploadState(UploadState.SUCCESS);
      toast.success(t('upload.modal.uploadSuccess'));
      amplitude.track('plan: upload', {
        projectId: projectId,
        fileType: fileWrapper.fileType,
      });
    } catch (err) {
      setUploadState(UploadState.FAILURE);
      toast.error(t('upload.modal.uploadFailure'));
    }
  };

  const onCloseRemoveFile = async () => {
    if (createdModelFile?._id) {
      await deleteFile(projectId, createdModelFile._id);
      queryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'files'],
      });
      setUploadState(UploadState.SELECT);
    }
    onClose();
  };

  const getModal = () => {
    switch (uploadState) {
      case UploadState.SELECT:
        return (
          <SelectModal
            fileType={fileType}
            currentFileNames={fileNames}
            onFileSelected={wrapFile}
            onClose={onClose}
          />
        );
      case UploadState.UPLOADING:
        return (
          <UploadingModal
            onClose={onCloseRemoveFile}
            uploadProgress={uploadProgress}
          />
        );
      case UploadState.SUCCESS:
        return <SuccessModal onClose={onClose} />;
      case UploadState.FAILURE:
        return (
          <FailureModal
            onRetry={() => setUploadState(UploadState.SELECT)}
            onClose={onClose}
          />
        );
    }
  };

  return <div className="p-6">{getModal()}</div>;
};
