import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';

import { Input } from '../Input/Input';
import { InputWrapper } from '../Wrapper/InputWrapper';

export interface TextInputProps extends ComponentPropsWithoutRef<'input'> {
  id?: string;
  label?: ReactNode;
  description?: ReactNode;
  error?: string | boolean;
  required?: boolean;
  wrapperProps?: { [key: string]: any };
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(props: TextInputProps, ref) {
    const {
      className,
      id,
      label,
      error,
      required,
      disabled,
      type = 'text',
      style,
      placeholder,
      description,
      wrapperProps,
      maxLength = 255,
      ...rest
    } = props;

    return (
      <InputWrapper
        id={id}
        required={required}
        aria-disabled={disabled}
        label={label}
        error={error}
        description={description}
        className={className}
        style={style}
        {...wrapperProps}
      >
        <Input
          {...rest}
          required={required}
          disabled={disabled}
          invalid={!!error}
          ref={ref}
          id={id}
          type={type}
          placeholder={placeholder}
          error={error}
          maxLength={maxLength}
        />
      </InputWrapper>
    );
  },
);
