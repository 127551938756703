import { useTranslation } from 'react-i18next';

import { cn } from 'src/common/utils';
import { Avatar } from 'src/common/components/Avatar';
import { Badge } from 'src/common/components/Badge';
import { ProjectUser } from 'src/project/types/projects';
import { Tooltip } from 'src/common/components/Tooltip';

type Props = {
  users: ProjectUser[];
  className?: string;
};

export const UserList = ({ users, className }: Props) => {
  return (
    <div className={cn('space-y-2', className)}>
      {users.map((user) => (
        <div key={user._id} className="border-b border-gray-200 pb-4 pt-2">
          <UserItem user={user} isGuest={!user.isInProjectCompany} />
        </div>
      ))}
    </div>
  );
};

const UserItem = ({
  user,
  isGuest = false,
}: {
  user: ProjectUser;
  isGuest?: boolean;
}) => {
  const { t } = useTranslation('project');

  return (
    <div className="flex w-full items-center gap-2">
      <Avatar alt={`${user.name} ${user.surname}`} size="sm" />
      <div className="flex min-w-0 flex-1 flex-col">
        <div className="font-semibold">
          {user.name} {user.surname}
        </div>
        <div className="truncate text-shuttleGray-600">{user.email}</div>
      </div>
      {isGuest && (
        <div className="flex items-center">
          <Tooltip content={t('users.userIsGuest')}>
            <Badge variant="teal"> {t('users.guest')}</Badge>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
