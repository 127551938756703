import { isUserPartOfCompany } from 'src/admin/utils';

import type {
  CompanyPopulatedUser,
  OwnerCompanyPopulatedProject,
} from 'shared';

function companyGroupToArray(projects: OwnerCompanyPopulatedProject[]) {
  const groupedProjects: OwnerCompanyPopulatedProject[][] = [];
  const reducedProjectGroup = projects.reduce(
    (acc: Record<string, OwnerCompanyPopulatedProject[]>, project) => {
      if (typeof project.company === 'string') return acc;
      const { name } = project.company;
      return {
        ...acc,
        [name]: acc.hasOwnProperty(name) ? [...acc[name], project] : [project],
      };
    },
    {},
  );
  Object.keys(reducedProjectGroup).forEach((key) => {
    groupedProjects.push(reducedProjectGroup[key]);
  });
  return groupedProjects;
}

export function groupProjectsByCompany(
  user: CompanyPopulatedUser,
  allProjects: OwnerCompanyPopulatedProject[],
) {
  const personalCompanyId = user.personalCompany._id;

  const personalProjects = allProjects.filter(
    (project) => project.company._id === personalCompanyId,
  );

  const groupedProjects: OwnerCompanyPopulatedProject[][] = [];

  const companyProjects = allProjects.filter(
    (project) =>
      project.company._id !== personalCompanyId &&
      isUserPartOfCompany(project.company._id, user.companies),
  );
  groupedProjects.push(...companyGroupToArray(companyProjects));

  const guestProjects = allProjects.filter(
    (project) =>
      project.company._id !== personalCompanyId &&
      !isUserPartOfCompany(project.company._id, user.companies),
  );
  groupedProjects.push(...companyGroupToArray(guestProjects));

  return { personalProjects, groupedProjects };
}
