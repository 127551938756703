import { DataExportQueryParameter } from 'shared';
import dayjs from 'dayjs';

import { downloadFile } from 'src/common/utils';
import { EXPORT_FORMATS } from './constants';
import { ExportFormat } from './types';
import { getCSVV2, getExcelV2, getPDFV2 } from './api';
import i18n from 'src/i18n';
import { isoDateFormat } from 'src/dayjs';

export function formatDateForDataExportQuery(date: string): string {
  return dayjs(date).format(isoDateFormat);
}

export function getLanguageCode(): string {
  const { language } = i18n;
  const languageCode = language.includes('-')
    ? language.split('-')[0]
    : language;
  return languageCode;
}

export function isValidExportFormat(
  format?: string | null,
): format is ExportFormat {
  return !!format && EXPORT_FORMATS.includes(format as ExportFormat);
}

export async function downloadExportFile(
  format: ExportFormat,
  projectId: string,
  techCrewId: string,
  queryParameter: DataExportQueryParameter,
) {
  let absoluteUrl = '';
  let name = '';
  switch (format) {
    case 'CSV': {
      const exportResult = await getCSVV2(
        projectId,
        techCrewId,
        queryParameter,
      );
      if (!exportResult.data) {
        throw new Error();
      }
      const { data, fileName } = exportResult;
      absoluteUrl = URL.createObjectURL(data);
      name = fileName || 'export.csv';
      break;
    }
    case 'Excel': {
      const exportResult = await getExcelV2(
        projectId,
        techCrewId,
        queryParameter,
      );
      if (!exportResult.data) {
        throw new Error();
      }
      const { data, fileName } = exportResult;
      absoluteUrl = URL.createObjectURL(data);
      name = fileName || 'export.xlsx';
      break;
    }
    case 'PDF': {
      const { downloadUrl, fileName } = await getPDFV2(
        projectId,
        techCrewId,
        queryParameter,
      );
      absoluteUrl = downloadUrl;
      name = fileName;
      break;
    }
    default:
      throw new Error('Invalid export format.');
  }
  downloadFile(absoluteUrl, name);
}
