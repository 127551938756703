import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

import { TabNavBar } from 'src/common/components/TabNavBar';
import { isProjectOwnerOrAdmin } from 'src/user/utils';
import { FilesView } from 'src/file/views/FilesView';
import { ModelPreviewView } from 'src/file/views/ModelPreviewView';
import { NotFoundInProjects } from 'src/project/components/NotFoundInProjects';
import { ProjectUsersView as UsersView } from './UsersView';
import { ProjectSettingsView as SettingsView } from './SettingsView';
import { ProjectOverviewView } from './ProjectOverviewView';
import { TechCrewListView } from './TechCrewListView';
import { TechCrewView } from './TechCrewView';
import { is3dProject } from '../utils';
import { useTechCrewsQuery } from '../queries';
import { useProject } from '../project-context';
import { useUser } from 'src/auth/user-context';

export const DashboardView = () => {
  const project = useProject();
  const user = useUser();
  const { t } = useTranslation(['common', 'project']);
  const { data: techCrews } = useTechCrewsQuery(project._id);

  // project owner and admins can see all tech crews
  // tech crew admins can only see tech crews they are admins of
  const isAdmin = isProjectOwnerOrAdmin(user._id, project);
  const avaliableTechCrews = isAdmin
    ? techCrews
    : techCrews?.filter((tc) => tc.admins.includes(user._id));
  const isAdminOfAnyTechCrew =
    isAdmin || (avaliableTechCrews && avaliableTechCrews.length > 0);
  const is3d = is3dProject(project);

  return (
    <div className="flex h-full w-full flex-col">
      <TabNavBar.Container>
        <TabNavBar>
          <TabNavBar.Link label={t('project:view.overview')} to="overview" />
          <TabNavBar.Link label={t('project:view.files')} to="files" />
          <TabNavBar.Link
            label={t('project:view.users')}
            to="users"
            disabled={!isAdmin}
          />
          <TabNavBar.Link
            label={t('project:view.techCrews')}
            to="techCrews"
            disabled={!isAdminOfAnyTechCrew}
          />
          <TabNavBar.Link
            label={t('project:view.settings')}
            to="settings"
            disabled={!isAdmin}
          />
        </TabNavBar>
      </TabNavBar.Container>
      <main className="h-full overflow-auto">
        <Routes>
          <Route path="overview" element={<ProjectOverviewView />} />
          <Route path="files" element={<FilesView project={project} />} />
          {isAdminOfAnyTechCrew && (
            <>
              <Route
                path="techcrews"
                element={
                  <TechCrewListView
                    project={project}
                    currentUserId={user._id}
                    isProjectOwnerOrAdmin={isAdmin}
                    is3dProject={is3d}
                  />
                }
              />
              <Route
                path="techcrews/:techCrewId/*"
                element={
                  <TechCrewView
                    project={project}
                    is3dProject={is3d}
                    availableTechCrewsIds={avaliableTechCrews?.map(
                      (tc) => tc._id,
                    )}
                  />
                }
              />
            </>
          )}
          {isAdmin && (
            <>
              <Route
                path="users"
                element={
                  <UsersView
                    project={project}
                    currentUserId={user._id}
                    isProjectOwnerOrAdmin={isAdmin}
                  />
                }
              />
              <Route path="settings/*" element={<SettingsView />} />
            </>
          )}
          <Route path="v" element={<ModelPreviewView />} />
          <Route index element={<Navigate to="overview" replace />} />
          <Route path="*" element={<NotFoundInProjects />} />
        </Routes>
      </main>
    </div>
  );
};
