import { CustomParameter, Filter } from 'src/project/types/projects';
import {
  ForgeElementProperties,
  ForgeElementPropertiesSet,
  ForgeViewerExternalIdToDbIdMapping,
  KeyTypes,
} from './types';
import { ConrepAppElementProperties, CustomFilter } from 'src/Conrep/App/types';

export const stringArrayToInt = (arr: string[]): number[] =>
  arr.map((a) => parseInt(a, 10));

const regex = new RegExp(
  /^((\w|\s|-|ß|ä|ö|ü|Ä|Ö|Ü)*(({{){1}(\w|\s|-|ß|ä|ö|ü|Ä|Ö|Ü)+(}}){1})*(\w|\s|-|ß|ä|ö|ü|Ä|Ö|Ü)*)*$/,
);

const splitRegex = new RegExp(/(({{){1}(\w|\s|-|ß|ä|ö|ü|Ä|Ö|Ü)+(}}){1})/g);

export function getElementIdOfExternalId(externalId: string): {
  elementId: string;
  linkedFileId?: string;
} {
  const paths = externalId.split('/');
  if (paths.length === 2) {
    return {
      elementId: paths[1],
      linkedFileId: paths[0],
    };
  }

  return { elementId: paths[paths.length - 1] };
}

// Helper function to gather all dbids
// NOTE: we used to get this recursively via the instance tree, but didn't get all dbIds in some IFC files. This approach seems to work for now.
export function getAllLeafComponents(viewer: any, callback: any) {
  let cbCount = 0; // count pending callbacks
  const components: any = []; // store the results
  let tree: any; // the instance tree

  function getLeafComponentsRec(parent: any) {
    cbCount++;
    if (tree.getChildCount(parent) != 0) {
      tree.enumNodeChildren(
        parent,
        function (children: any) {
          getLeafComponentsRec(children);
        },
        false,
      );
    } else {
      components.push(parent);
    }
    if (--cbCount == 0) callback(components);
  }
  viewer.getObjectTree(function (objectTree: any) {
    tree = objectTree;
    const allLeafComponents = getLeafComponentsRec(tree.getRootId());
    callback(allLeafComponents);
  });
}

const elementNameRegex = new RegExp(/^(\w|\s|-|ß|ä|ö|ü|Ä|Ö|Ü)+\[([0-9]+)\]/g);
function elementIsWorkable(
  element: number,
  tree: Autodesk.Viewing.InstanceTree,
) {
  const nodeName = tree.getNodeName(element);
  const matchesRegex = !!nodeName.match(elementNameRegex);
  return matchesRegex;
}

// This is the old way we used to get dbIds, which we still require for the inverted filter
export function getObjectTreeLeafComponentIds(
  viewer: Autodesk.Viewing.GuiViewer3D,
  callback: (ids: number[]) => void,
) {
  let cbCount = 0; // count pending callbacks
  const components: number[] = []; // store the results
  let tree: Autodesk.Viewing.InstanceTree; // the instance tree

  function getLeafComponentsRec(parent: number) {
    cbCount++;
    if (tree.getChildCount(parent) !== 0) {
      if (elementIsWorkable(parent, tree)) {
        components.push(parent);
      }

      tree.enumNodeChildren(
        parent,
        function (children) {
          getLeafComponentsRec(children);
        },
        false,
      );
    } else {
      components.push(parent);
    }
    if (--cbCount === 0) {
      callback(components);
    }
  }

  viewer.getObjectTree(function (objectTree) {
    tree = objectTree;
    const rootId = tree.getRootId();
    getLeafComponentsRec(rootId);
  });
}

export function getElementNameProperties(namingParameter: string) {
  const paramNames = namingParameter.match(splitRegex);
  if (
    namingParameter &&
    regex.test(namingParameter) &&
    paramNames &&
    paramNames.length > 0
  ) {
    const final: string[] = [];
    paramNames.forEach((paramNameWithParentheses) => {
      const paramName = paramNameWithParentheses.slice(2, -2);
      final.push(paramName);
    });
    return final;
  }

  return [];
}

export function getElementName(
  element: Autodesk.Viewing.PropertyResult,
  namingParameter: string,
) {
  if (
    !namingParameter ||
    namingParameter === '' ||
    !regex.test(namingParameter)
  ) {
    return element.name;
  }
  let newName = namingParameter;
  const paramNames = namingParameter.match(splitRegex);
  if (paramNames) {
    paramNames.forEach((paramNameWithParentheses) => {
      const paramName = paramNameWithParentheses.slice(2, -2);
      const foundProperty = element.properties.find(
        (prop) => prop.displayName === paramName,
      );
      if (foundProperty) {
        newName = newName.replace(
          new RegExp(paramNameWithParentheses, 'g'),
          foundProperty.displayValue.toString(),
        );
      } else if (paramName === 'NAME' && element.name) {
        newName = newName.replace(
          new RegExp(paramNameWithParentheses, 'g'),
          element.name,
        );
      } else if (paramName === 'dbId') {
        newName = newName.replace(
          new RegExp(paramNameWithParentheses, 'g'),
          element.dbId.toString(),
        );
      }
    });
  }
  if (newName === namingParameter && element.name) {
    return element.name;
  }
  return newName;
}

function userFunction(pdb: any, args: any) {
  // initialize the object we are going to return
  const propertyValues: ForgeElementProperties = {};
  // Query Property DB for Each DBID
  args.dbIds.forEach((dbId: any) => {
    propertyValues[dbId] = {};
    const properties = pdb.getObjectProperties(dbId, args.propertyNames, true);
    if (properties) {
      properties.properties.forEach((property: any) => {
        propertyValues[dbId][property.displayName] = property.displayValue;
      });
    }
  });
  return propertyValues;
}

export async function getProperties(
  dbIds: number[],
  propertyNames: string[] | undefined,
  includeRevitCategory = false,
  viewer: Autodesk.Viewing.GuiViewer3D,
): Promise<ForgeElementProperties> {
  return await viewer.model.getPropertyDb().executeUserFunction(userFunction, {
    dbIds,
    propertyNames,
    includeRevitCategory,
  });
}

export function elementPropertiesToSet(
  elementProperties: ForgeElementProperties,
  filters: string[],
): ForgeElementPropertiesSet {
  const propertySets: Record<string, Set<string>> = {};
  filters.forEach((parameterName) => {
    propertySets[parameterName] = new Set();
  });
  Object.keys(elementProperties).forEach((dbId) => {
    filters.forEach((parameterName) => {
      const value = elementProperties[dbId][parameterName];
      if (value !== null && value !== undefined) {
        propertySets[parameterName].add(elementProperties[dbId][parameterName]);
      }
    });
  });
  // serialize the sets back to arrays
  const propertyArrays: ForgeElementPropertiesSet = {};
  Object.keys(propertySets).forEach((key) => {
    propertyArrays[key] = Array.from(propertySets[key]);
  });
  return propertyArrays;
}

export function externalIdsToDbIds(
  externalIds: { elementId: string }[] | number[],
  mapping: ForgeViewerExternalIdToDbIdMapping | null,
) {
  const dbIds: number[] = [];
  if (mapping) {
    externalIds.forEach((extId) => {
      if (typeof extId === 'number') {
        dbIds.push(extId);
      } else {
        const mapped = mapping[extId.elementId];
        if (mapped) {
          dbIds.push(mapped);
        }
      }
    });
  }
  return dbIds;
}

export function applyCustomFilters(
  conrepAllElementProperties: ConrepAppElementProperties,
  ids: number[],
  customFilter: CustomFilter,
  settingsFilter: Filter[] | CustomParameter[],
) {
  // let filtered
  if (
    Object.keys(customFilter).length === 0 &&
    customFilter.constructor === Object
  ) {
    // no need to apply custom filters, there are none
    return ids;
  }

  const newDbIds: number[] = [];

  ids.forEach((dbId) => {
    // evaluate custom filters for each dbId
    const properties = conrepAllElementProperties[dbId];
    let isValidDbId = true; // check for every active filter if it passes
    Object.keys(customFilter).forEach((filterParameter) => {
      if (
        customFilter[filterParameter] &&
        customFilter[filterParameter].length > 0
      ) {
        // if this filter is set, continue

        if (
          properties &&
          Object.prototype.hasOwnProperty.call(properties, filterParameter)
        ) {
          // if the dbId Element hast the property

          if (
            !customFilter[filterParameter].includes(properties[filterParameter])
          ) {
            // filtred property is not int this element, so its not valid
            isValidDbId = false;
          }
        } else {
          // Integrate a type guard
          if (settingsFilter.length > 0 && 'parameter' in settingsFilter[0]) {
            // Treat settingsFilter as Filter[] based on the first item check
            if (
              (settingsFilter as Filter[]).some(
                (f) => f.parameter === filterParameter,
              )
            ) {
              isValidDbId = false;
            }
          } else if (settingsFilter.length > 0 && 'name' in settingsFilter[0]) {
            // Treat settingsFilter as CustomParameter[] based on the first item check
            if (
              (settingsFilter as CustomParameter[]).some(
                (f) => f.name === filterParameter,
              )
            ) {
              isValidDbId = false;
            }
          }
        }
      }
    });
    if (isValidDbId) {
      newDbIds.push(dbId);
    }
  });

  return newDbIds;
}
export function getFilteredElementProperties(
  conrepAllElementProperties: ConrepAppElementProperties | null,
  ids: number[] | null,
) {
  const filtered: ConrepAppElementProperties = {};
  if (ids && conrepAllElementProperties) {
    ids.forEach((id) => {
      const value = conrepAllElementProperties[id];
      if (value) {
        filtered[id] = value;
      } else {
        filtered[id] = {};
      }
    });
  }
  return filtered;
}

export function isKeyType(enumKey: string | KeyTypes): enumKey is KeyTypes {
  return (
    Object.keys(KeyTypes).includes(enumKey) ||
    Object.values(KeyTypes).includes(enumKey as KeyTypes)
  );
}

export function normalizeUrn(urn: string) {
  return !urn.startsWith('urn:') ? `urn:${urn}` : urn;
}
