import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { Container } from 'src/common/components/Container';
import { NotFoundInProjects } from 'src/project/components/NotFoundInProjects';
import { TechCrewActionGroupsView } from './TechCrewActionGroupsView';
import { TechCrewServiceSpecificationPositionsView } from './TechCrewServiceSpecificationPositionsView';
import { TechCrewSettingsView } from './TechCrewSettingsView';
import { getTechCrew } from '../api/techCrewsApi';
import { validateLicense } from '../utils';
import {
  GetProjectResponse,
  LicenseEvaluationType,
  LicenseType,
} from '../types/projects';
import { LicenseExpiredMessage } from '../components/LicenseExpiredMessage';
import { TechCrewMembersView } from './TechCrewMembersView';

export const TechCrewView = ({
  project,
  is3dProject,
  availableTechCrewsIds,
}: {
  project: GetProjectResponse;
  is3dProject: boolean;
  availableTechCrewsIds?: string[];
}) => {
  const { projectId = '', techCrewId = '' } = useParams();

  const { data: techCrew } = useQuery({
    queryKey: ['projects', projectId, 'techCrews', techCrewId],
    queryFn: () => getTechCrew(projectId, techCrewId),
  });

  if (!techCrew || !availableTechCrewsIds?.includes(techCrew._id)) {
    return null;
  }

  const isFreeProject = project.license.type === LicenseType.FREE;
  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;

  return (
    <Container className="h-full py-4" variant="box">
      {isLicenseExpired && <LicenseExpiredMessage />}
      <div className="py-4">
        <Routes>
          <Route
            path="settings"
            element={
              <TechCrewSettingsView
                techCrew={techCrew}
                isFreeProject={isFreeProject}
                is3dProject={is3dProject}
                disabled={isLicenseExpired}
              />
            }
          />
          {!isFreeProject && (
            <>
              <Route
                path="service-specifications"
                element={
                  <TechCrewServiceSpecificationPositionsView
                    techCrew={techCrew}
                  />
                }
              />

              <Route
                path="action-groups"
                element={
                  <TechCrewActionGroupsView
                    techCrew={techCrew}
                    isLicenseExpired={isLicenseExpired}
                  />
                }
              />
            </>
          )}
          <Route
            path="members"
            element={<TechCrewMembersView techCrew={techCrew} />}
          />
          <Route index element={<Navigate to="settings" replace />} />
          <Route path="*" element={<NotFoundInProjects />} />
        </Routes>
      </div>
    </Container>
  );
};
