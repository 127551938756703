import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { Filter } from '../../types/projects';

interface Props {
  parameter: { name?: string; parameter: string } | undefined;
  onSave: (newFilter: Filter) => void;
  onClose: () => void;
}

export const NameFilterModal = ({ parameter, onSave, onClose }: Props) => {
  const { t } = useTranslation(['project', 'common']);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<{ name: string }>();

  useEffect(() => {
    if (parameter) {
      if (parameter.name) {
        reset({ name: parameter.name });
      } else {
        setValue('name', parameter.parameter, { shouldDirty: true });
      }
    }
  }, [reset, parameter, setValue]);

  if (!parameter) {
    return null;
  }

  return (
    <Modal isOpen onRequestClose={onClose} className="w-[680px]">
      <Modal.Header onClose={onClose}>
        {t(
          `settings.modelParameter.filters.${
            parameter.name ? 'edit' : 'add'
          }Filter`,
        )}
      </Modal.Header>
      <Modal.Body>
        <form
          className="flex flex-col gap-6"
          onSubmit={handleSubmit(({ name }) =>
            onSave({ name, parameter: parameter.parameter }),
          )}
        >
          <TextInput
            label={t('settings.modelParameter.filters.name')}
            required
            {...register('name')}
          />
          <div className="ml-auto flex gap-2">
            <Button variant="tertiary" onClick={onClose}>
              {t('common:button.abort')}
            </Button>
            <Button variant="primary" type="submit" disabled={!isDirty}>
              {t('common:button.save')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
