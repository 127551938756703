import { faTimes, faUndo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Button } from 'src/common/components/buttons/Button';
import { Tooltip } from 'src/common/components/Tooltip';
import toast from 'src/common/toast';
import { updateTechCrewServiceSpecificationPath } from 'src/project/api/techCrewsApi';
import {
  NewServiceSpecification,
  ServiceSpecificationType,
} from 'src/project/types/projects';
import { TechCrew } from 'src/project/types/techCrews';
import { ServiceSpecificationList } from './ServiceSpecificationList';
import { ServiceSpecificationTreeGaeb } from './ServiceSpecificationTreeGaeb';
import { ServiceSpecificationTreeOnlv } from './ServiceSpecificationTreeOnlv';

type Props = {
  serviceSpecification: NewServiceSpecification;
  techCrew: TechCrew;
  onSave: () => void;
};

export const SelectWorkdayServiceSpecification = ({
  serviceSpecification,
  techCrew,
  onSave,
}: Props) => {
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();

  const { projectId = '' } = useParams();

  const [checked, setChecked] = useState(
    techCrew?.serviceSpecificationPathsWorkday?.paths || [],
  );
  const [expanded, setExpanded] = useState<string[]>();
  const [isSaving, setIsSaving] = useState(false);

  const handleSavePaths = async () => {
    setIsSaving(true);
    const data = {
      serviceSpecificationPathsWorkday: {
        serviceSpecification: serviceSpecification._id,
        paths: checked,
      },
    };
    await updateTechCrewServiceSpecificationPath(
      projectId,
      techCrew._id,
      data,
      'WORKDAY',
    );
    await queryClient.invalidateQueries({
      queryKey: ['projects', projectId, 'techCrews'],
    });
    setIsSaving(false);
    toast.success(t('techCrewEdit.selectWorkdayBoq.reloadPositions'));
    onSave();
  };

  return (
    <div>
      <div className="mb-4 flex items-center justify-end">
        <div className="flex items-baseline">
          <Tooltip
            content={t('techCrewEdit.selectWorkdayBoq.selectSavedPositions')}
          >
            <div>
              <ActionIcon
                className="mr-1"
                onClick={() =>
                  setChecked(
                    techCrew.serviceSpecificationPathsWorkday?.paths || [],
                  )
                }
              >
                <FontAwesomeIcon icon={faUndo} />
              </ActionIcon>
            </div>
          </Tooltip>
          <span className="mr-1 text-sm text-gray-600">
            {t('techCrewEdit.selectWorkdayBoq.savedPositions')}
            {': '}
          </span>
          <span className="mr-3 text-sm font-medium text-shuttleGray-700">
            {techCrew?.serviceSpecificationPathsWorkday?.paths?.length || 0}
          </span>{' '}
          <Tooltip
            content={t('techCrewEdit.selectWorkdayBoq.removeCurrentSelection')}
          >
            <div>
              <ActionIcon className="mr-1" onClick={() => setChecked([])}>
                <FontAwesomeIcon icon={faTimes} />
              </ActionIcon>
            </div>
          </Tooltip>
          <span className="mr-1 text-sm text-gray-600">
            {t('techCrewEdit.selectWorkdayBoq.selectedPositions')}
            {': '}
          </span>
          <span className="w-12 text-sm font-medium text-shuttleGray-700">
            {checked?.length || 0}
          </span>
        </div>
        <Button
          onClick={() => handleSavePaths()}
          className="w-32"
          loading={isSaving}
        >
          {t('common:button.save')}
        </Button>
      </div>

      {serviceSpecification.specificationType ===
      ServiceSpecificationType.ONLV ? (
        <ServiceSpecificationTreeOnlv
          serviceSpecification={serviceSpecification}
          isWorkdaySettingsTree
          ssLevel="LG"
          checked={checked}
          expanded={expanded || []}
          onCheck={setChecked}
          onExpand={setExpanded}
          treeProps={{ noCascade: true }}
        />
      ) : serviceSpecification.specificationType ===
        ServiceSpecificationType.GAEB ? (
        <ServiceSpecificationTreeGaeb
          serviceSpecification={serviceSpecification}
          checked={checked}
          expanded={expanded || []}
          onCheck={setChecked}
          onExpand={setExpanded}
        />
      ) : (
        <ServiceSpecificationList
          serviceSpecification={serviceSpecification}
          checked={checked}
          onCheck={setChecked}
        />
      )}
    </div>
  );
};
