export const resolveLanguageForUrl = (langCode: string) => {
  if (langCode === 'de' || langCode.startsWith('de')) {
    return 'de';
  }
  if (langCode === 'en' || langCode.startsWith('en')) {
    return 'en';
  }

  return 'en';
};
