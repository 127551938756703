import { Link, LinkProps } from 'react-router-dom';

import { cn } from 'src/common/utils';

type Props = LinkProps & {
  children: React.ReactNode;
  disabled?: boolean;
};

export const TextLink = ({ children, disabled, ...rest }: Props) => (
  <Link
    {...rest}
    className={cn(
      'inline-flex items-center gap-2 font-medium',
      disabled
        ? 'cursor-default text-gray-400'
        : 'text-blue-600 hover:text-blue-700 hover:underline focus:text-blue-800',
    )}
  >
    {children}
  </Link>
);
