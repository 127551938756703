import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Modal } from 'src/common/components/Modal';
import { Tabs } from 'src/common/components/Tabs';
import { Avatar } from 'src/common/components/Avatar';
import { UserProjects } from './projects/UserProjects';
import { UserForm } from './UserForm';
import { UserCompany } from './company/UserCompany';

import type { CompanyPopulatedUser } from 'shared';

type Props = {
  user: CompanyPopulatedUser;
  isOpen: boolean;
  onClose: () => void;
};

export const UserEditModal = ({ user, isOpen, onClose }: Props) => {
  const { t } = useTranslation(['admin', 'common']);
  return (
    <Modal className="min-w-[680px]" isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header onClose={onClose}>
        {t('admin:users.modal.editTitle')}
      </Modal.Header>
      <Modal.Body>
        <div className="mb-6 flex items-center">
          <Avatar size="lg" alt={`${user.name} ${user.surname}`} />
          <div className="ml-4 overflow-hidden py-1">
            <div className="truncate text-2xl font-semibold">
              {[user.name, user.surname].join(' ')}
            </div>
            <div className="truncate text-lg text-shuttleGray-600">
              {user.email}
            </div>
          </div>
          <div className="mb-2 ml-auto mt-auto truncate text-shuttleGray-600">
            {`${t('admin:users.modal.lastUpdated')}: ${dayjs(
              user.updatedAt,
            ).format('L LT')}`}
          </div>
        </div>
        <Tabs defaultValue="personal">
          <Tabs.List>
            <Tabs.Tab value="personal">
              {t('admin:users.modal.personalInformation')}
            </Tabs.Tab>
            <Tabs.Tab value="organization">{t('common:organization')}</Tabs.Tab>
            <Tabs.Tab value="projects">{t('admin:projects.title')}</Tabs.Tab>
          </Tabs.List>
          <Tabs.Content value="personal">
            <div className="py-4">
              <UserForm user={user} onClose={onClose} />
            </div>
          </Tabs.Content>
          <Tabs.Content value="organization">
            <UserCompany user={user} />
          </Tabs.Content>
          <Tabs.Content value="projects">
            <UserProjects user={user} />
          </Tabs.Content>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};
