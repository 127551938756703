import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from 'src/common/components/Card';
import FourOFourIcon from 'src/assets/illu_404.svg';

type Props = {
  action?: ReactNode;
};

export const NotFound = ({ action }: Props) => {
  const { t } = useTranslation('common');
  return (
    <Card className="flex flex-col items-center justify-center gap-4 py-40">
      <img src={FourOFourIcon} alt="404 error icon" />
      <h1 className="mt-4 text-4xl font-semibold">{t('notFound.title')}</h1>
      <p className="mb-2 max-w-[600px] text-center text-shuttleGray-600">
        {t('notFound.text')}
      </p>
      {action}
    </Card>
  );
};
