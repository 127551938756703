export enum ForgeProgress {
  NONE = 'NONE',
  FORGE_PROGRESS = 'FORGE_PROGRESS',
  FORGE_FAILED = 'FORGE_FAILED',
  DONE = 'DONE',
}

export enum FileType {
  IMAGE = 'IMAGE',
  PDF = 'PDF',
  PDF_PLAN = 'PDF_PLAN',
  REVIT = 'REVIT',
  IFC = 'IFC',
  COMBINED = 'COMBINED',
}

export type FileOrFolder =
  | { type: 'file'; file: SitelifeFile }
  | { type: 'folder'; folder: { name: string } };

export type ForgeBatchSignedS3Response = {
  uploadKey: string;
  uploadExpiration: Date;
  urlExpiration: Date;
  urls: string[];
};

export type CreateModelFileResponse = {
  created: ModelFile;
  uploadUrl: string | ForgeBatchSignedS3Response;
};

export type FileDataWrapper = {
  file: File;
  uploaded: boolean;
  failed: boolean;
  fileType: FileType;
  mimeType: string;
  isCompressed?: boolean;
  rootFilename?: string;
};

export type FileVersionViewDictionary = {
  [fileIdUnderscoreViewId: string]: FileVersionView;
};

export type FileVersionViewReference = {
  _id?: string;
  file: string;
  fileVersion: string;
  guid: string;
  viewableId: string;
  isMasterView?: boolean;
};

export type FileVersionView = {
  _id: string;
  project: string;
  file: string;
  fileVersion: string;
  name: string;
  role: string;
  guid: string;
  viewableId: string;
  isMasterView?: boolean;
  viewerUrnEncoded: string;
};

export interface RevitFileVersion {
  _id: string;
  objectKey: string;
  viewerUrn: string;
  viewerUrnEncoded: string;
  views: FileVersionView[];
  forgeProgress: ForgeProgress;
  isCompressed: boolean;
  rootFilename: string;
  createdBy: string;
  createdAt: Date | string;
}

interface RevitFile extends BaseFile {
  type: FileType.REVIT;
  versions: RevitFileVersion[];
  activeVersion: string;
}

export interface IfcFileVersion {
  _id: string;
  objectKey: string;
  viewerUrn: string;
  viewerUrnEncoded: string;
  views: FileVersionView[];
  forgeProgress: ForgeProgress;
  createdBy: string;
  createdAt: Date | string;
}

interface IfcFile extends BaseFile {
  type: FileType.IFC;
  versions: IfcFileVersion[];
  activeVersion: string;
}

export interface ImageFile extends BaseFile {
  key: string;
  type: FileType.IMAGE;
}

interface PdfFile extends BaseFile {
  key: string;
  type: FileType.PDF;
}

export interface PdfPlanFileVersion {
  _id: string;
  objectKey: string;
  viewerUrn: string;
  viewerUrnEncoded: string;
  views: FileVersionView[];
  forgeProgress: ForgeProgress;
  createdBy: string;
  createdAt: Date | string;
}

interface PdfPlanFile extends BaseFile {
  type: FileType.PDF_PLAN;
  versions: PdfPlanFileVersion[];
  activeVersion: string;
}

export type SitelifeFile =
  | RevitFile
  | IfcFile
  | PdfFile
  | PdfPlanFile
  | ImageFile;

export interface BaseFile {
  _id?: string;
  path?: string;
  name: string;
  extension: string;
  type: FileType;
  chunks?: number;
  project?: string;
  createdBy?: string;
  createdAt?: Date | string;
  updatedBy?: string;
  updatedAt?: Date | string;
}

export type ModelFile = RevitFile | IfcFile | PdfPlanFile;

export type ForgeProgressStatus = {
  version: string;
  status: ForgeProgress;
  progress: string;
};
