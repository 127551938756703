import { useState } from 'react';

import { SignUpSuccessMessage } from '../../components/SignUpSuccessMessage';
import { SignUpForm } from '../../components/SignUpForm';
import { AuthLayout } from '../../components/AuthLayout';

export const SignUpView = () => {
  const [targetEmail, setTargetEmail] = useState<string | null>();

  return (
    <AuthLayout>
      {!targetEmail ? (
        <SignUpForm onSignUp={setTargetEmail} />
      ) : (
        <SignUpSuccessMessage email={targetEmail} />
      )}
    </AuthLayout>
  );
};
