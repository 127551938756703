import {
  ActionGroup,
  CreateActionGroup,
  UpdateActionGroup,
} from '../types/actionGroups';
import { TechCrew } from '../types/techCrews';
import { api } from 'src/common/fetch';

export async function getActionGroups(projectId: string, techCrewId: string) {
  return api.get<ActionGroup[]>(
    `/api/projects/${projectId}/techcrews/${techCrewId}/actiongroups`,
  );
}

export async function createActionGroup(
  techCrew: TechCrew,
  newActionGroup: CreateActionGroup,
) {
  return api.post<ActionGroup>(
    `/api/projects/${techCrew.project}/techcrews/${techCrew._id}/actiongroups`,
    newActionGroup,
  );
}

export async function updateActionGroup(
  techCrew: TechCrew,
  actionGroupId: string,
  updatedActionGroup: UpdateActionGroup,
) {
  return api.post<ActionGroup>(
    `/api/projects/${techCrew.project}/techcrews/${techCrew._id}/actiongroups/${actionGroupId}`,
    updatedActionGroup,
  );
}

export async function deleteActionGroup(
  projectId: string,
  techCrewId: string,
  actionGroupId: string,
) {
  return api.delete(
    `/api/projects/${projectId}/techcrews/${techCrewId}/actiongroups/${actionGroupId}`,
  );
}
