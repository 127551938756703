import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, useTranslation } from 'react-i18next';
import { faArrowLeft, faTimes } from '@fortawesome/pro-regular-svg-icons';

import { Button } from 'src/common/components/buttons/Button';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { ForgeViewerHeader } from 'src/Forge';
import { Container } from 'src/common/components/Container';
import WarningIllustration from 'src/assets/illu_warning.svg';
import { useSheetSetsQuery } from 'src/sheetset/queries';
import { useFilesQuery } from '../queries';
import { ForgeViewerHeaderType } from 'src/Forge/types';
import { getViewsFromFile, getViewsFromSheetSet, isModelFile } from '../util';
import { FileVersionView } from '../types';
import { Viewer } from 'src/forge-v2/forge';

export const ModelPreviewView = () => {
  const [searchParams] = useSearchParams();
  const sheetSetId = searchParams.get('sheetSet');
  const fileId = searchParams.get('file');

  if (sheetSetId && fileId) {
    return <ViewerEmpty />;
  }

  return (
    <Container className="h-full">
      <div className="flex h-full flex-col pb-5">
        {sheetSetId ? (
          <SheetSetPreview sheetSetId={sheetSetId} />
        ) : fileId ? (
          <FilePreview fileId={fileId} />
        ) : null}
      </div>
    </Container>
  );
};

function FilePreview({ fileId }: { fileId: string }) {
  const { projectId = '' } = useParams();
  const { data: files, isPending } = useFilesQuery(projectId);
  const [searchParams, setSearchParams] = useSearchParams();
  const viewId = searchParams.get('view');

  if (isPending) {
    return null;
  }

  const modelFiles = files?.filter(isModelFile) || [];
  const file = modelFiles.find((f) => f._id === fileId);
  if (!file) {
    return <ViewerEmpty />;
  }

  const views = getViewsFromFile(file);
  const view = views.find((v) => v._id === viewId) || views[0];

  if (!view) {
    return <ViewerEmpty />;
  }

  function handleViewChange(v: FileVersionView) {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      view: v._id,
    });
  }

  return (
    <>
      <ForgeViewerHeader
        title={file.name}
        type={ForgeViewerHeaderType.FILE}
        views={views}
        selectedViewId={view._id}
        onViewChange={handleViewChange}
      >
        <Link to={`/p/${projectId}/dashboard/files`}>
          <ActionIcon>
            <FontAwesomeIcon icon={faTimes} />
          </ActionIcon>
        </Link>
      </ForgeViewerHeader>
      <div className="flex h-full shadow">
        <Viewer urn={view.viewerUrnEncoded} viewableId={view.viewableId} />
      </div>
    </>
  );
}

function SheetSetPreview({ sheetSetId }: { sheetSetId: string }) {
  const { projectId = '' } = useParams();
  const { data: files, isPending: isLoadingFiles } = useFilesQuery(projectId);
  const { data: sheetSets, isLoading: isLoadingSheetSets } =
    useSheetSetsQuery(projectId);
  const [searchParams, setSearchParams] = useSearchParams();
  const viewId = searchParams.get('view');

  if (isLoadingFiles || isLoadingSheetSets) {
    return null;
  }

  const sheetSet = sheetSets?.find((s) => s._id === sheetSetId);
  if (!sheetSet) {
    return <ViewerEmpty />;
  }

  const modelFiles = files?.filter(isModelFile) || [];
  const views = getViewsFromSheetSet(sheetSet, modelFiles);
  const view = views.find((v) => v._id === viewId) || views[0];
  if (!view) {
    return <ViewerEmpty />;
  }

  function handleViewChange(v: FileVersionView) {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      view: v._id,
    });
  }

  return (
    <>
      <ForgeViewerHeader
        title={sheetSet.name}
        type={ForgeViewerHeaderType.SHEETSET}
        views={views}
        selectedViewId={view._id}
        onViewChange={handleViewChange}
      >
        <Link to={`/p/${projectId}/dashboard/files`}>
          <ActionIcon>
            <FontAwesomeIcon icon={faTimes} />
          </ActionIcon>
        </Link>
      </ForgeViewerHeader>
      <div className="flex h-full shadow">
        <Viewer urn={view.viewerUrnEncoded} viewableId={view.viewableId} />
      </div>
    </>
  );
}

function ViewerEmpty() {
  const { projectId = '' } = useParams();
  const { t } = useTranslation('file');
  const navigate = useNavigate();
  return (
    <div className="flex h-full flex-col items-center justify-center gap-4">
      <img alt="warning" src={WarningIllustration} />
      <h1 className="mt-4 text-4xl font-semibold">
        {t('viewer.emptyState.title')}
      </h1>
      <div className="max-w-[640px] text-center text-shuttleGray-600">
        <Trans ns="file" i18nKey="viewer.emptyState.warning" />
      </div>
      <Button
        className="mt-2"
        onClick={() => navigate(`/p/${projectId}/dashboard/files`)}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        {t('viewer.emptyState.modelsAndPlans')}
      </Button>
    </div>
  );
}
