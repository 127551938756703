import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  faClock,
  faChartBar,
  faCogs,
  faCheckCircle,
  faCircleX,
  faRefresh,
} from '@fortawesome/pro-regular-svg-icons';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { SidebarLayout } from 'src/common/components/SidebarLayout';
import { SidebarMenu } from 'src/common/components/SidebarMenu';
import { GetProjectResponse } from 'src/project/types/projects';
import { Card } from 'src/common/components/Card';
import { resetDataset } from '../api';
import { Button } from 'src/common/components/buttons/Button';
import { RefreshHistory } from '../types';

export const AnalysisPowerBiManagementView = ({
  project,
  history,
}: {
  project: GetProjectResponse;
  history: RefreshHistory | undefined;
}) => {
  const { t } = useTranslation('analysis');
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);

  const handleResetDataset = async () => {
    if (project._id) {
      setIsLoading(true);
      try {
        await resetDataset(project._id);
        await queryClient.invalidateQueries({
          queryKey: ['projects', project._id, 'powerbi-refreshes'],
        });
        toast.success(t('settings.messages.resetDatasetSuccess'));
      } catch (err) {
        toast.error(t('settings.messages.resetDatasetError'));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const showStatus = (status: string) => {
    switch (status) {
      case 'Completed':
        return (
          <FontAwesomeIcon
            className="pr-3 text-green-600"
            fixedWidth
            icon={faCheckCircle}
          />
        );
      case 'Failed':
        return (
          <FontAwesomeIcon
            className="pr-3 text-red-600"
            fixedWidth
            icon={faCircleX}
          />
        );
      default:
        return (
          <FontAwesomeIcon
            className="pr-3 text-shuttleGray-600"
            fixedWidth
            icon={faRefresh}
          />
        );
    }
  };

  return (
    <SidebarLayout
      header={
        <div>
          <h3 className="mb-1 text-2xl font-semibold">
            {t('settings.datasetSettings')}
          </h3>
          <p className="text-shuttleGray-600">
            {t('settings.datasetSettingsDescription')}
          </p>
        </div>
      }
      sidebar={
        <SidebarMenu>
          <SidebarMenu.Item
            to="../plannedHours"
            icon={<FontAwesomeIcon icon={faClock} fixedWidth />}
          >
            {t('settings.plannedHours')}
          </SidebarMenu.Item>
          <SidebarMenu.Item
            to="../analysisParameters"
            icon={<FontAwesomeIcon icon={faChartBar} fixedWidth />}
          >
            {t('settings.analysisParameters')}
          </SidebarMenu.Item>
          <SidebarMenu.Item
            to="../powerBiManagement"
            icon={<FontAwesomeIcon icon={faCogs} fixedWidth />}
          >
            {t('settings.datasetSettings')}
          </SidebarMenu.Item>
        </SidebarMenu>
      }
    >
      <Card className="p-6">
        <h4 className="mb-4 text-lg font-semibold">
          {t('settings.datasetReset')}
        </h4>
        <p className="my-4 text-shuttleGray-600">
          {t('settings.datasetResetDescription')}
        </p>
        <Button
          onClick={() => handleResetDataset()}
          loading={isLoading}
          disabled={history?.refreshHistory?.length === 0}
        >
          {t('settings.reset')}
        </Button>

        <hr className="my-6" />

        <h4 className="mb-4 text-lg font-semibold">
          {t('settings.datasetUpdates')}
        </h4>
        <p className="my-4 text-shuttleGray-600">
          {t('settings.datasetUpdatesDescription')}
        </p>
        <table className="w-full table-fixed divide-y divide-gray-100 border-b border-gray-100 shadow">
          <thead className="table-head">
            <tr>
              <th className="table-th">{t('settings.table.startTime')}</th>
              <th className="table-th">{t('settings.table.endTime')}</th>
              <th className="table-th">{t('settings.table.status')}</th>
              <th className="table-th" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {history?.refreshHistory.map((update) => (
              <tr
                key={update.requestId}
                className="h-20 border-gray-100 bg-white text-shuttleGray-700"
              >
                <td className="table-td table-td-text">
                  {dayjs(update.startTime).format('L LT')}
                </td>
                <td className="table-td table-td-text">
                  {dayjs(update.endTime).format('L LT')}
                </td>
                <td className="table-td table-td-text">
                  {update.status === 'Unknown'
                    ? t('settings.table.update')
                    : update.status}
                </td>
                <td className="table-td table-td-text">
                  {showStatus(update.status)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </SidebarLayout>
  );
};
