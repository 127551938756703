import { User } from 'src/user/types';
import { Company } from './types';

/**
 * roles a user can have within a company
 */
export enum CompanyRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export function getCompanyRole(company: Company, member: User): CompanyRole {
  if (company.owner === member._id) {
    return CompanyRole.OWNER;
  } else if (company.admins?.includes(member._id)) {
    return CompanyRole.ADMIN;
  }
  return CompanyRole.USER;
}
