import {
  ChangeEvent,
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { cn } from 'src/common/utils';
import { InputWrapper } from '../Wrapper/InputWrapper';
import { Input } from '../Input/Input';

export interface CurrencyInputProps extends ComponentPropsWithoutRef<'input'> {
  id?: string;
  label?: ReactNode;
  description?: ReactNode;
  error?: string | boolean;
  required?: boolean;
  currencySymbol?: string;
  locale?: string;
  wrapperProps?: { [key: string]: any };
}

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  function CurrencyInput(props: CurrencyInputProps, ref) {
    const {
      className,
      id,
      label,
      error,
      required,
      disabled,
      style,
      placeholder = '0,00',
      description,
      currencySymbol = '€',
      locale = 'de-DE',
      onChange,
      value,
      wrapperProps,
      ...rest
    } = props;

    const [editing, setEditing] = useState<boolean>(false);
    const [formattedValue, setFormattedValue] = useState<string>('');
    let element: HTMLInputElement | null = null;

    const formatter = useMemo(
      () =>
        new Intl.NumberFormat(locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      [locale],
    );

    function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
      const num = formatter.format(+event.target.value);
      setFormattedValue(num);
      onChange && onChange(event);
    }

    useEffect(() => {
      if (editing) {
        element?.focus();
      }
    }, [editing, element]);

    return (
      <InputWrapper
        id={id}
        required={required}
        aria-disabled={disabled}
        label={label}
        error={error}
        description={description}
        className={className}
        style={style}
        {...wrapperProps}
      >
        <div className="relative flex-auto">
          <div className="absolute left-2 h-full select-none leading-[40px] text-gray-800">
            {currencySymbol}
          </div>
          <Input
            {...rest}
            value={value}
            required={required}
            disabled={disabled}
            invalid={!!error}
            ref={(inputElement) => {
              element = inputElement;
              if (ref) {
                if (typeof ref === 'function') {
                  ref(inputElement);
                } else {
                  ref.current = inputElement;
                }
                // set initial value of display input
                if (element?.value) {
                  const num = formatter.format(+element.value);
                  setFormattedValue(num);
                }
              }
            }}
            id={id}
            type="number"
            step="0.01"
            placeholder={placeholder}
            error={error}
            className={cn('pl-5', { hidden: !editing })}
            onBlur={() => setEditing(false)}
            onChange={handleInputChange}
          />
          <Input
            {...rest}
            data-testid="display"
            required={required}
            disabled={disabled}
            invalid={!!error}
            type="text"
            placeholder={placeholder}
            error={error}
            className={cn('pl-5', { hidden: editing })}
            defaultValue={formattedValue}
            onFocus={() => setEditing(true)}
          />
        </div>
      </InputWrapper>
    );
  },
);
