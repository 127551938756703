import { GetFreeFieldUsageResponse } from 'shared';

import { ConrepSettings } from 'src/Conrep/settings/types';
import { api } from 'src/common/fetch';

export async function getFreeFieldUsage(projectId: string) {
  return api.get<GetFreeFieldUsageResponse>(
    `/api-conrep/projects/${projectId}/settings/freefieldusage`,
  );
}

export async function updateConrepSettings(
  projectId: string,
  updatedData: Partial<ConrepSettings> & { password?: string },
): Promise<void> {
  return api.post<void>(
    `/api-conrep/projects/${projectId}/settings`,
    updatedData,
  );
}

export async function updateWeatherProvider(
  projectId: string,
  weatherProvider: string,
  quickEntries: string[],
) {
  return api.post<void>(`/api/projects/${projectId}/weather-provider`, {
    provider: weatherProvider,
    quickEntries,
  });
}

export async function getTcUsage(projectId: string) {
  return api.get<{ [id: string]: boolean }>(
    `/api-conrep/projects/${projectId}/statistics/tcUsage`,
  );
}
