import { TechCrew } from 'src/project/types/techCrews';
import { ConrepAppUserStatus, ConrepAppWorkday } from '../types';
import { TechCrewPermission } from 'src/project/types/techCrews';

export function isActionAllowed(
  conrepCurrentUserStatus: ConrepAppUserStatus | null,
  conrepWorkdayData: ConrepAppWorkday | null,
) {
  return !conrepCurrentUserStatus && !conrepWorkdayData?.isLocked;
}

function hasTechCrewPermission(
  userId: string,
  techCrew: TechCrew,
  permission: TechCrewPermission,
) {
  const isAdmin = techCrew.admins.includes(userId);
  const memberEntry = techCrew.members.find((m) => m.userId === userId);
  const hasPermission = !!memberEntry?.permissions.includes(permission);
  return isAdmin || hasPermission;
}

export function hasViewDocumentationPermission(
  userId: string,
  techCrew: TechCrew,
) {
  return hasTechCrewPermission(
    userId,
    techCrew,
    TechCrewPermission.VIEW_DOCUMENTATION,
  );
}

export function hasEditDocumentationPermission(
  userId: string,
  techCrew: TechCrew,
) {
  return hasTechCrewPermission(
    userId,
    techCrew,
    TechCrewPermission.EDIT_DOCUMENTATION,
  );
}

export function hasControlDocumentationPermission(
  userId: string,
  techCrew: TechCrew,
) {
  return hasTechCrewPermission(
    userId,
    techCrew,
    TechCrewPermission.CONTROL_DOCUMENTATION,
  );
}

export function hasExportDocumentationDataPermission(
  userId: string,
  techCrew: TechCrew,
) {
  return hasTechCrewPermission(
    userId,
    techCrew,
    TechCrewPermission.EXPORT_DATA_DOCUMENTATION,
  );
}

export function hasViewAnalysisPermission(userId: string, techCrew: TechCrew) {
  return hasTechCrewPermission(
    userId,
    techCrew,
    TechCrewPermission.VIEW_ANALYSIS,
  );
}

export function hasEditAnalysisPermission(userId: string, techCrew: TechCrew) {
  return hasTechCrewPermission(
    userId,
    techCrew,
    TechCrewPermission.EDIT_ANALYSIS,
  );
}
