import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { cn } from 'src/common/utils';

type Props = {
  containerClassName?: string;
  labelClassName?: string;
  onClick?: () => void;
  onClose?: () => void;
  selectable?: boolean;
} & React.ComponentPropsWithoutRef<'div'>;

export const Chip = ({
  containerClassName,
  labelClassName,
  onClick,
  onClose,
  children,
}: Props) => {
  return (
    <Container className={containerClassName}>
      <Label className={labelClassName} onClick={onClick}>
        {children}
      </Label>
      {onClose && <Remove onClick={onClose} />}
    </Container>
  );
};

const Container = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={cn(
      'inline-flex h-[28px] items-center overflow-hidden rounded-sm bg-blue-50',
      className,
    )}
  >
    {children}
  </div>
);

const Label = ({
  children,
  className,
  onClick,
}: {
  children: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  if (onClick) {
    return (
      <button
        type="button"
        onClick={onClick}
        className={cn(
          'h-full truncate whitespace-nowrap px-2 hover:bg-blue-500 hover:text-white',
          className,
        )}
      >
        {children}
      </button>
    );
  }
  return (
    <div className={cn('truncate whitespace-nowrap px-2', className)}>
      {children}
    </div>
  );
};
const Remove = ({
  onClick,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => (
  <button
    className="h-full px-2 hover:bg-blue-200 focus:bg-blue-600 focus:text-white focus:outline-none focus:hover:bg-blue-600"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faTimes} />
  </button>
);

Chip.Remove = Remove;
Chip.Label = Label;
Chip.Container = Container;
