import { UserMenu } from 'src/app/components/appbar/UserMenu';
import { BaseAppbar } from 'src/app/components/appbar';
import LogoText from 'src/assets/sitelife_icon_text_brand.svg';

const LogoFull = () => (
  <img src={LogoText} className="h-[60px] px-[7px]" alt="sitelife logo" />
);

export const AdminAppbar = () => {
  return (
    <BaseAppbar
      logo={<LogoFull />}
      left={
        <span className="-ml-2.5 mt-1.5 text-2xl font-normal text-blue-300">
          admin
        </span>
      }
    >
      <UserMenu />
    </BaseAppbar>
  );
};
