import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { faEdit, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { useMutation } from '@tanstack/react-query';

import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Button } from 'src/common/components/buttons/Button';
import { Card } from 'src/common/components/Card';
import { Modal } from 'src/common/components/Modal';
import { SidebarLayout } from 'src/common/components/SidebarLayout';
import { Tooltip } from 'src/common/components/Tooltip';
import toast from 'src/common/toast';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { createAction, deleteAction, updateAction } from '../api/actionsApi';
import { SettingsSidebar } from '../components/SettingsSidebar';
import { LicenseEvaluationType } from '../types/projects';
import { useActionsQuery } from '../queries';
import { Action } from '../types/actions';
import { validateLicense } from '../utils';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Spinner } from 'src/common/components/Spinner';
import { useProject } from '../project-context';
import { Badge } from 'src/common/components/Badge';

export const ActionsView = () => {
  const project = useProject();
  const { t } = useTranslation(['project', 'common']);
  const { data: actions, isPending } = useActionsQuery(project._id);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;

  return (
    <>
      <SidebarLayout
        header={
          <div className="flex items-end justify-between gap-3">
            <div>
              <h1 className="mb-1 text-2xl font-semibold">
                {t('settings.actions.title')}
              </h1>
              <p className="text-shuttleGray-600">
                {t('settings.actions.description')}
              </p>
            </div>
            <Button
              type="button"
              disabled={isLicenseExpired}
              onClick={() => setIsCreateModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
              {t('project:settings.actions.createButton')}
            </Button>
          </div>
        }
        sidebar={<SettingsSidebar />}
      >
        <Card className="overflow-auto">
          {actions?.length ? (
            isPending ? (
              <Spinner containerClassName="w-full my-[160px] text-center" />
            ) : (
              <table className="w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="table-th">{t('common:labels.name')}</th>
                    <th className="table-th" />
                  </tr>
                </thead>
                <tbody className="table-body">
                  {actions.map((action) => (
                    <ActionRow
                      projectId={project._id}
                      key={action._id}
                      action={action}
                      actions={actions}
                      disabled={isLicenseExpired}
                    />
                  ))}
                </tbody>
              </table>
            )
          ) : (
            <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
              {t('settings.actions.noActions')}
            </p>
          )}
        </Card>
      </SidebarLayout>
      {isCreateModalOpen ? (
        <ActionFormModal
          isOpen={isCreateModalOpen}
          actions={actions}
          onClose={() => setIsCreateModalOpen(false)}
          projectId={project._id}
        />
      ) : null}
    </>
  );
};

const ActionRow = ({
  projectId,
  action,
  actions,
  disabled,
}: {
  projectId: string;
  action: Action;
  actions: Action[];
  disabled?: boolean;
}) => {
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleConfirmDelete = async (id: string) => {
    try {
      await deleteAction(projectId, id);
      queryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'actions'],
      });
      toast.success(t('common:success.delete'));
    } catch (err) {
      toast.error(t('common:errors.delete'));
    }
  };

  const editTooltip = action.isUsed
    ? t('settings.actions.actionIsUsed')
    : t('common:button.edit');
  const deleteTooltip = action.isUsed
    ? t('settings.actions.actionIsUsed')
    : action.isDefaultAction
    ? t('settings.actions.actionIsDefault')
    : t('common:button.delete');

  return (
    <>
      <tr key={action._id}>
        <td className="table-td">
          <div className="flex items-center">
            <div
              className="mr-2 h-4 w-4 rounded"
              style={{
                backgroundColor: action.color,
              }}
            />
            <span className="max-w-[200px] truncate font-semibold">
              {action.name}
            </span>
            {action.isDefaultAction && (
              <Badge className="ml-2">{t('settings.actions.default')}</Badge>
            )}
          </div>
        </td>
        <td className="table-td">
          <div className="flex justify-end">
            <Tooltip content={editTooltip}>
              <span>
                <ActionIcon
                  onClick={() => setIsEditModalOpen(true)}
                  disabled={action.isUsed || disabled}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </ActionIcon>
              </span>
            </Tooltip>
            <Tooltip content={deleteTooltip}>
              <span>
                <ActionIcon
                  onClick={() => setIsConfirmDeleteOpen(true)}
                  disabled={action.isUsed || disabled || action.isDefaultAction}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ActionIcon>
              </span>
            </Tooltip>
          </div>
        </td>
      </tr>
      <Modal
        isOpen={isConfirmDeleteOpen}
        onRequestClose={() => setIsConfirmDeleteOpen(false)}
      >
        <ConfirmationDialog
          title={t('project:settings.actions.confirmDelete.title')}
          message={t('project:settings.actions.confirmDelete.text')}
          onCancel={() => setIsConfirmDeleteOpen(false)}
          onConfirm={() => {
            setIsConfirmDeleteOpen(false);
            handleConfirmDelete(action._id);
          }}
        />
      </Modal>
      <ActionFormModal
        isOpen={isEditModalOpen}
        action={action}
        actions={actions}
        onClose={() => setIsEditModalOpen(false)}
        projectId={projectId}
      />
    </>
  );
};

const getRandomColor = () => {
  return '#000000'.replace(/0/g, () => (~~(Math.random() * 16)).toString(16));
};

type ActionFormState = {
  name: string;
  color: string;
};

const ActionFormModal = ({
  isOpen,
  action,
  actions,
  projectId,
  onClose,
}: {
  isOpen: boolean;
  action?: Action;
  actions?: Action[];
  projectId: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation(['common', 'project']);
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (formData: ActionFormState) => {
      return createAction(projectId, formData);
    },
  });
  const updateMutation = useMutation({
    mutationFn: ({
      formData,
      actionId,
    }: {
      formData: ActionFormState;
      actionId: string;
    }) => {
      return updateAction(projectId, actionId, formData);
    },
  });

  const isLoading = createMutation.isPending || updateMutation.isPending;

  const { register, handleSubmit, formState, setError } =
    useForm<ActionFormState>({
      defaultValues: {
        name: action?.name || '',
        color: action?.color || getRandomColor(),
      },
    });

  const onSubmitSuccess = async (formData: ActionFormState) => {
    // check name is unique
    const isNameInUse = actions?.find((a) => a.name === formData.name);

    if (isNameInUse && action?.name !== formData.name) {
      setError('name', { type: 'custom' });
      return;
    }

    if (action) {
      try {
        await updateMutation.mutateAsync({ formData, actionId: action._id });
        toast.success(t('common:success.save'));
      } catch (error) {
        toast.error(t('common:errors.save'));
      }
    } else {
      try {
        await createMutation.mutateAsync(formData);
        toast.success(t('common:success.create'));
      } catch (error) {
        toast.error(t('common:errors.create'));
      }
    }

    queryClient.invalidateQueries({
      queryKey: ['projects', projectId, 'actions'],
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="w-[680px]">
      <Modal.Header onClose={onClose}>
        {action
          ? t('project:settings.actions.form.editTitle')
          : t('project:settings.actions.form.createTitle')}
        {action?.isDefaultAction && (
          <Badge className="ml-2" size="md">
            {t('project:settings.actions.default')}
          </Badge>
        )}
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmitSuccess)}>
          <div className="flex gap-2">
            <TextInput
              {...register('name')}
              className="w-full"
              autoFocus
              label={t('project:settings.actions.form.nameLabel')}
              placeholder={t('project:settings.actions.form.namePlaceholder')}
              error={
                formState.errors.name
                  ? t('project:settings.actions.form.nameInUse')
                  : false
              }
            />
            <div>
              <label className="block">
                {t('project:settings.actions.form.colorLabel')}
              </label>
              <input
                type="color"
                {...register('color')}
                className="h-10 w-10 rounded-sm border border-gray-300 bg-white p-1"
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end">
            <Button
              variant="tertiary"
              className="mr-2"
              onClick={onClose}
              disabled={isLoading}
            >
              {t('common:button.abort')}
            </Button>
            <Button
              type="submit"
              disabled={!formState.isDirty}
              loading={isLoading}
            >
              {t('common:button.save')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
