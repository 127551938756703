import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaste } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { Button } from 'src/common/components/buttons/Button';
import { ProjectInformation } from 'src/project/types/projects';

type Props = {
  projectInformation: ProjectInformation;
  setProjectInformation: (pi: ProjectInformation) => void;
  onClickBack: () => void;
  onClickNext: () => void;
  isLoading: boolean;
};

export const ProjectInformationPage = ({
  projectInformation,
  setProjectInformation,
  onClickBack,
  onClickNext,
  isLoading,
}: Props) => {
  const { t } = useTranslation(['project', 'common']);

  const [clipboardPermitted, setClipboardPermitted] = useState<boolean>(false);

  const { register, reset, handleSubmit, setValue } =
    useForm<ProjectInformation>({
      mode: 'onChange',
    });

  useEffect(() => {
    if (projectInformation) {
      reset({ ...projectInformation });
    } else {
      reset({});
    }
  }, [reset, projectInformation]);

  useEffect(() => {
    if (!navigator?.permissions?.query) {
      return;
    }

    navigator.permissions
      .query({ name: 'clipboard-read' as PermissionName })
      .then((permissionStatus) => {
        if (permissionStatus.state === 'denied') {
          setClipboardPermitted(false);
        } else {
          setClipboardPermitted(true);
        }
      })
      .catch(() => {
        setClipboardPermitted(false);
      });
  }, []);

  const pasteFromClipboard = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        try {
          const split = text.split(',');
          if (split.length < 2) {
            throw new Error('Wrong Format');
          }
          const lat = parseFloat(split[0]);
          const long = parseFloat(split[1]);
          if (!lat || !long) {
            throw new Error('Wrong Format');
          }

          setValue('latitude', lat, { shouldDirty: true });
          setValue('longitude', long, { shouldDirty: true });
          toast.success(
            t('project:settings.information.coordinates.pasteSuccess'),
          );
        } catch (err) {
          toast.error(
            t('project:settings.information.coordinates.pasteFailure'),
          );
        }
      })
      .catch(() => {
        toast.error(t('project:settings.information.coordinates.pasteFailure'));
      });
  };

  function handleSave(projectInformationValues: ProjectInformation) {
    setProjectInformation(projectInformationValues);
  }

  return (
    <div className="flex h-full flex-col justify-end">
      <form
        className="flex h-full flex-col justify-between px-6 pb-6"
        onChange={handleSubmit(handleSave)}
      >
        <h4 className="mb-4 text-lg font-semibold">
          {t('project:settings.information.location.title')}
        </h4>
        <TextInput
          {...register('street')}
          label={t('project:settings.information.location.street')}
          placeholder={t(
            'project:settings.information.location.streetPlaceholder',
          )}
          className="mb-4 w-full"
        />

        <div className="mb-4 flex gap-4">
          <TextInput
            {...register('zipCode')}
            label={t('project:settings.information.location.zipCode')}
            placeholder={t(
              'project:settings.information.location.zipCodePlaceholder',
            )}
            className="w-40"
            type="number"
            maxLength={10}
          />
          <TextInput
            {...register('city')}
            label={t('project:settings.information.location.city')}
            placeholder={t(
              'project:settings.information.location.cityPlaceholder',
            )}
            className="w-full"
          />
        </div>

        <TextInput
          {...register('country')}
          label={t('project:settings.information.location.country')}
          placeholder={t(
            'project:settings.information.location.countryPlaceholder',
          )}
          defaultValue=""
          className="mb-4 w-full"
        />

        <h4 className="mb-4 text-lg font-semibold">
          {t('project:settings.information.coordinates.title')}
        </h4>

        {clipboardPermitted && (
          <div className="w-[calc(70%)]">
            <InlineMessage className="mb-4 w-full">
              {t('project:settings.information.coordinates.googleMaps')}
            </InlineMessage>
            <Button
              variant="secondary"
              className="mb-4"
              onClick={pasteFromClipboard}
            >
              <FontAwesomeIcon icon={faPaste} />

              {t('project:settings.information.coordinates.paste')}
            </Button>
          </div>
        )}
        <TextInput
          {...register('latitude')}
          label={t('project:settings.information.coordinates.latitude')}
          placeholder={t(
            'project:settings.information.coordinates.latitudePlaceholder',
          )}
          type="number"
          min="-90"
          max="90"
          step="0.00000000000000001"
          className="mb-4 w-full"
        />
        <TextInput
          {...register('longitude')}
          label={t('project:settings.information.coordinates.longitude')}
          placeholder={t(
            'project:settings.information.coordinates.longitudePlaceholder',
          )}
          type="number"
          min="-180"
          max="180"
          step="0.00000000000000001"
          className="mb-4 w-full"
        />
      </form>
      <div className="flex items-center justify-between p-6">
        <div className="flex-1" />
        <span className="flex-1 text-center text-sm text-shuttleGray-600">
          {t('common:pagination.page')} 2 {t('common:of')} 2
        </span>
        <div className="flex-1 text-right">
          <Button variant="tertiary" onClick={onClickBack} disabled={isLoading}>
            {t('common:button.back')}
          </Button>
          <Button className="ml-2" onClick={onClickNext} loading={isLoading}>
            {t('common:button.create')}
          </Button>
        </div>
      </div>
    </div>
  );
};
