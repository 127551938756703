import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { updateCompanyMembers } from 'src/admin/apiCompanies';
import { useAdminUsersQuery } from 'src/admin/queries';
import { Pagination } from 'src/common/components/Pagination';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Button } from 'src/common/components/buttons/Button';
import { SelectNative } from 'src/common/components/inputs/SelectNative/SelectNative';
import { CompanyRole } from 'src/company/utils';
import { SearchSort } from 'src/common/components/SearchSort';
import { SimpleUserRow } from '../user/SimpleUserRow';
import { CompanyNewOwnerModal } from './CompanyNewOwnerModal';
import { CompanyRemoveUserModal } from './CompanyRemoveUserModal';
import { Card } from 'src/common/components/Card';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Spinner } from 'src/common/components/Spinner';

import type {
  CompanyPopulatedUser,
  OwnerPopulatedCompany,
  PatchCompanyMembersPayload,
} from 'shared';

type Props = {
  company: OwnerPopulatedCompany;
  onClickAddMembers: () => void;
};

export const CompanyMembersPage = ({ company, onClickAddMembers }: Props) => {
  const { t } = useTranslation('common');

  const [searchBy, setSearchBy] = useState('');
  const [sortBy, setSortBy] = useState('surname');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const filter = {
    filterBy: 'inCompany',
    filter: company._id,
  };

  const { data: paginatedUsers, isPending } = useAdminUsersQuery(
    page,
    entriesPerPage,
    searchBy,
    sortBy,
    sortOrder,
    filter,
  );

  const sortOptions: SelectSitelifeOptionType[] = [
    { label: t('surname'), value: 'surname' },
    { label: t('name'), value: 'name' },
    { label: t('email'), value: 'email' },
    { label: t('organizations'), value: 'company.name' },
  ];

  const users = paginatedUsers ? paginatedUsers.data : [];

  return (
    <div className="flex h-[600px] flex-col justify-between">
      <div className="flex flex-row items-baseline space-x-4 px-6">
        <SearchSort
          searchBy={searchBy}
          sortBy={
            sortOptions.find((sortOption) => sortOption.value === sortBy) ||
            sortOptions[0]
          }
          sortOrder={sortOrder}
          sortOptions={sortOptions}
          onSearchBy={(newSearchBy) => setSearchBy(newSearchBy)}
          onSortBy={(newSortBy) => setSortBy(newSortBy)}
          onSortOrder={(newSortOrder) => setSortOrder(newSortOrder)}
        />
        <Button size="sm" onClick={onClickAddMembers}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
      <div className="mt-6 h-full overflow-y-auto bg-gray-100 p-6">
        <Card>
          {isPending ? (
            <Spinner containerClassName="w-full h-full my-[160px] text-center" />
          ) : users.length > 0 ? (
            <table className="relative w-full divide-y divide-gray-200 shadow">
              <thead>
                <tr>
                  <th scope="col" className="table-th">
                    {t('person')}
                  </th>
                  <th scope="col" className="table-th">
                    {t('role')}
                  </th>
                  <th scope="col" className="table-th xl:table-cell" />
                </tr>
              </thead>
              <tbody className="table-body">
                {users.map((user) => (
                  <UserRow user={user} company={company} key={user._id} />
                ))}
              </tbody>
            </table>
          ) : (
            <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
              {searchBy ? (
                <>
                  <span className="font-semibold text-shuttleGray-800">
                    {`${searchBy}`}
                  </span>{' '}
                  {t('notFound.message')}
                </>
              ) : (
                t('noUsers')
              )}
            </p>
          )}
        </Card>
      </div>
      {users.length > 0 && !isPending && paginatedUsers && (
        <Pagination
          maxPage={Math.ceil(paginatedUsers.totalCount / entriesPerPage)}
          page={page}
          onChangePage={setPage}
          entriesPerPage={entriesPerPage}
          onChangeEntriesPerPage={setEntriesPerPage}
          className="p-6"
        />
      )}
    </div>
  );
};

export const UserRow = ({
  user,
  company,
}: {
  user: CompanyPopulatedUser;
  company: OwnerPopulatedCompany;
}) => {
  const { t } = useTranslation(['admin', 'common']);
  const queryClient = useQueryClient();

  const [showRemoverUserModal, setShowRemoveUserModal] = useState(false);
  const [userIdToRemove, setUserIdToRemove] = useState<string>();

  const memberRole =
    company.owner._id === user._id
      ? CompanyRole.OWNER
      : company.admins.includes(user._id)
      ? CompanyRole.ADMIN
      : CompanyRole.USER;
  const isOwner = memberRole === CompanyRole.OWNER;

  async function onChangeUserRole(newRole: CompanyRole) {
    try {
      const payload: PatchCompanyMembersPayload = {};
      switch (newRole) {
        case CompanyRole.ADMIN:
          payload.admins = [
            ...company.admins.map((id: string) => id.toString()),
            user._id,
          ];
          break;
        case CompanyRole.USER:
          payload.admins = company.admins
            .map((id: string) => id.toString())
            .filter((id: string) => id !== user._id);
          break;
      }
      await updateCompanyMembers(payload, company._id);
      await queryClient.invalidateQueries({
        queryKey: ['admin-companies'],
      });
      toast.success(t('admin:changeRoleSuccess'));
    } catch {
      toast.error(t('admin:changeRoleFailure'));
    }
  }

  return (
    <>
      <SimpleUserRow
        user={user}
        middleContent={
          <>
            {!isOwner && (
              <SelectNative
                value={memberRole}
                onChange={(e) =>
                  onChangeUserRole(e.target.value as CompanyRole)
                }
              >
                <option value={CompanyRole.ADMIN}>{t('common:admin')}</option>
                <option value={CompanyRole.USER}>{t('common:user')}</option>
              </SelectNative>
            )}
          </>
        }
        key={user._id}
      >
        {isOwner ? (
          <Button
            size="sm"
            variant="secondary"
            onClick={() => setUserIdToRemove(user._id)}
          >
            {t('admin:selectNewOwner')}
          </Button>
        ) : isOwner ? null : (
          <ActionIcon
            onClick={() => {
              setShowRemoveUserModal(true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ActionIcon>
        )}
      </SimpleUserRow>
      <CompanyRemoveUserModal
        company={company}
        userId={user._id}
        isOpen={showRemoverUserModal}
        onClose={() => {
          setShowRemoveUserModal(false);
        }}
      />
      <CompanyNewOwnerModal
        company={company}
        isOpen={!!userIdToRemove}
        onClose={() => {
          setUserIdToRemove(undefined);
        }}
      />
    </>
  );
};
