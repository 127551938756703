import { faCheck, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { cn } from 'src/common/utils';
import { Button } from 'src/common/components/buttons/Button';
import { Pagination } from 'src/common/components/Pagination';
import { Card } from 'src/common/components/Card';
import { Modal } from 'src/common/components/Modal';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Spinner } from 'src/common/components/Spinner';
import { Avatar } from 'src/common/components/Avatar';
import { SearchSort } from 'src/common/components/SearchSort';
import { PaginatedResponse } from 'src/common/types';
import { SelectSitelife } from '../SelectSitelife';
import { TechCrewPermission, TechCrewUsage } from 'src/project/types/techCrews';
import { TechCrewPermissionsEditor } from 'src/project/views/UsersView';

import type { CompanyPopulatedUser, User } from 'shared';

const MAX_PAGES = 2;

type Props = {
  /**
   * @deprecated this violates react state design principles
   * TODO: refactor this, so children dont write state into the parent: lift state up
   */
  CallGetUsersHook: (
    page: number,
    entriesPerPage: number,
    searchBy?: string,
    sortBy?: string,
    sortOrder?: 'asc' | 'desc',
  ) => {
    isPending: boolean;
    paginatedUsers:
      | PaginatedResponse<User>
      | PaginatedResponse<CompanyPopulatedUser>
      | undefined;
  };
  callAddMembers: (
    ids: string[],
    assignedTechCrewId?: string,
    techCrewPermissions?: TechCrewPermission[],
  ) => Promise<void>;
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
  techCrews?: TechCrewUsage[];
};

export const AddMembersModal = ({
  techCrews,
  CallGetUsersHook,
  callAddMembers,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation('common');

  const [newMemberIds, setNewMemberIds] = useState<string[]>([]);

  const [searchBy, setSearchBy] = useState('');
  const [sortBy, setSortBy] = useState('surname');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [addMembersModalPage, setAddMembersModalPage] = useState(1);
  const [selectedTechCrew, setSelectedTechCrew] =
    useState<SelectSitelifeOptionType>();
  const [techCrewPermissions, setTechCrewPermissions] =
    useState<Record<TechCrewPermission, boolean>>();

  const { paginatedUsers, isPending } = CallGetUsersHook(
    page,
    entriesPerPage,
    searchBy,
    sortBy,
    sortOrder,
  );

  const sortOptions: SelectSitelifeOptionType[] = [
    { label: t('surname'), value: 'surname' },
    { label: t('name'), value: 'name' },
    { label: t('email'), value: 'email' },
    { label: t('organizations'), value: 'company.name' },
  ];

  const techCrewOptions = techCrews?.map((techCrew) => {
    return { label: techCrew.name, value: techCrew._id };
  });

  const selectedPermissions =
    techCrewPermissions &&
    (Object.keys(techCrewPermissions).filter(
      (key) =>
        techCrewPermissions &&
        techCrewPermissions[key as TechCrewPermission] === true,
    ) as TechCrewPermission[]);

  const users = paginatedUsers ? paginatedUsers.data : [];

  const goBack = () => {
    setAddMembersModalPage(addMembersModalPage - 1);
  };

  const closeModal = () => {
    setNewMemberIds([]);
    setAddMembersModalPage(1);
    setSelectedTechCrew(undefined);
    setTechCrewPermissions(undefined);
    onClose();
  };

  async function handleAddNewMembers() {
    try {
      await callAddMembers(
        newMemberIds,
        selectedTechCrew?.value,
        selectedPermissions,
      );
      toast.success(t('members.addMembersSuccess'));
      closeModal();
    } catch (e) {
      toast.error(t('members.addMembersFailure'));
    }
  }

  const renderAddMembersModalPages = () => {
    switch (addMembersModalPage) {
      case 2:
        return renderProjectModalPageTwo();
      case 1:
      default:
        return renderProjectModalPageOne();
    }
  };

  const renderProjectModalPageOne = () => (
    <div className="flex h-[600px] flex-col justify-between">
      <div className="flex flex-row items-baseline space-x-4 px-6">
        <SearchSort
          searchBy={searchBy}
          sortBy={
            sortOptions.find((sortOption) => sortOption.value === sortBy) ||
            sortOptions[0]
          }
          sortOrder={sortOrder}
          sortOptions={sortOptions}
          onSearchBy={(newSearchBy) => setSearchBy(newSearchBy)}
          onSortBy={(newSortBy) => setSortBy(newSortBy)}
          onSortOrder={(newSortOrder) => setSortOrder(newSortOrder)}
        />
      </div>
      <div className="mt-6 h-full overflow-y-auto bg-gray-100 p-6">
        <Card>
          {isPending ? (
            <Spinner containerClassName="w-full h-full my-[160px] text-center" />
          ) : users.length > 0 ? (
            <table className="relative w-full divide-y divide-gray-200 shadow">
              <thead>
                <tr>
                  <th scope="col" className="table-th">
                    {t('person')}
                  </th>
                  <th scope="col" className="table-th">
                    {t('position')}
                  </th>
                  <th scope="col" className="table-th xl:table-cell" />
                </tr>
              </thead>
              <tbody className="table-body">
                {users.map((user) => (
                  <UserRow
                    key={user._id}
                    user={user}
                    newMemberIds={newMemberIds}
                    setNewMemberIds={setNewMemberIds}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
              {searchBy ? (
                <>
                  <span className="font-semibold text-shuttleGray-800">
                    {`"${searchBy}"`}
                  </span>{' '}
                  {t('notFound.message')}
                </>
              ) : (
                t('noUsersFound')
              )}
            </p>
          )}
        </Card>
      </div>
      {users.length > 0 && !isPending && paginatedUsers && (
        <Pagination
          maxPage={Math.ceil(paginatedUsers.totalCount / entriesPerPage)}
          page={page}
          onChangePage={setPage}
          entriesPerPage={entriesPerPage}
          onChangeEntriesPerPage={setEntriesPerPage}
          className="p-6"
        />
      )}
    </div>
  );

  const renderProjectModalPageTwo = () => (
    <div className="flex flex-col justify-between space-y-4 p-6">
      <div>
        <span>{t('permissionEditor.techCrew')}</span>
        <SelectSitelife
          options={techCrewOptions}
          value={selectedTechCrew}
          onChange={(option: SelectSitelifeOptionType) => {
            setSelectedTechCrew(option);
            if (!option) {
              // when selection is cleared
              setTechCrewPermissions(undefined);
            }
          }}
          isClearable
          className="w-full"
        />
      </div>
      {selectedTechCrew && (
        <TechCrewPermissionsEditor
          onPermissionsChange={setTechCrewPermissions}
        />
      )}
    </div>
  );

  return (
    <Modal
      className="min-w-[700px]"
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <Modal.Header onClose={closeModal}>
        {t('members.addMembersTitle')}
      </Modal.Header>
      {renderAddMembersModalPages()}
      <div className="flex items-center justify-between px-6 pb-6">
        <div className="flex-1" />
        {techCrews && (
          <span className="flex-1 text-center text-sm text-shuttleGray-600">
            {t('page')} {addMembersModalPage < MAX_PAGES ? 1 : 2} {t('of')}{' '}
            {MAX_PAGES}
          </span>
        )}
        <div className="flex-1 text-right">
          {techCrews && addMembersModalPage === 1 ? (
            <div>
              <Button
                variant="tertiary"
                onClick={closeModal}
                disabled={isPending}
              >
                {t('button.abort')}
              </Button>

              <Button
                type="submit"
                className="ml-2"
                loading={isPending}
                onClick={() =>
                  addMembersModalPage === 1 &&
                  setAddMembersModalPage(addMembersModalPage + 1)
                }
                disabled={newMemberIds.length === 0}
              >
                {t('button.continue')}
              </Button>
            </div>
          ) : (
            <div>
              <Button variant="tertiary" onClick={goBack} disabled={isPending}>
                {t('button.back')}
              </Button>

              <Button
                type="submit"
                className="ml-2"
                loading={isPending}
                onClick={handleAddNewMembers}
              >
                {t('button.add')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const UserRow = ({
  user,
  newMemberIds,
  setNewMemberIds,
}: {
  user: CompanyPopulatedUser | User;
  newMemberIds: string[];
  setNewMemberIds: (id: string[]) => void;
}) => {
  const { t } = useTranslation('common');
  const wasAdded = newMemberIds.includes(user._id);

  return (
    <tr className={cn({ 'bg-blue-50': wasAdded })}>
      <td className="table-td table-td-truncate min-w-[200px]">
        <div className="flex items-center">
          <Avatar size="sm" alt={`${user.name} ${user.surname}`} />
          <div className="ml-4 overflow-hidden py-1">
            <div className="truncate text-base font-semibold">
              {[user.name, user.surname].join(' ')}
            </div>
            <div className="truncate text-base text-shuttleGray-600">
              {user.email}
            </div>
          </div>
        </div>
      </td>
      <td className="table-td table-td-truncate lg:table-cell">
        {user.position}
      </td>
      <td className="table-td w-[140px] text-right">
        {wasAdded ? (
          <Button
            size="sm"
            onClick={() => {
              const tmpMembers = newMemberIds.filter((id) => id !== user._id);
              setNewMemberIds(tmpMembers);
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
            {t('button.added')}
          </Button>
        ) : (
          <Button
            size="sm"
            variant="secondary"
            onClick={() => setNewMemberIds([...newMemberIds, user._id])}
          >
            <FontAwesomeIcon icon={faPlus} />
            {t('button.add')}
          </Button>
        )}
      </td>
    </tr>
  );
};
