import { ReactNode, forwardRef } from 'react';

import { cn } from 'src/common/utils';

export const HeaderButton = forwardRef<
  HTMLButtonElement,
  {
    children: ReactNode;
    className?: string;
  } & React.HTMLAttributes<HTMLButtonElement>
>(function HeaderButton({ children, className, ...rest }, ref) {
  return (
    <button
      ref={ref}
      {...rest}
      className={cn(
        'h-[60px] p-4 focus-within:border-b-2 focus-within:text-blue-200 hover:bg-white/10',
        className,
      )}
    >
      {children}
    </button>
  );
});
