import type {
  DownloadRef,
  PaginatedResponse,
  PatchRef,
} from 'src/common/types';

import { GetAdminProjectResponse } from 'src/project/types/projects';
import { Project } from 'src/project/types/projects';
import { api } from 'src/common/fetch';

import type {
  CompanyPopulatedUser,
  IUpdateProjectPayload,
  IUpdateProjectResponse,
} from 'shared';

export async function getProjectUsersAsAdmin(id: string) {
  return api.get<CompanyPopulatedUser[]>(`/api-admin/projects/${id}/users`);
}

export async function getProjects(
  page: number,
  entriesPerPage: number,
  searchBy?: string,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
) {
  const params = {
    page: page.toString(),
    entriesPerPage: entriesPerPage.toString(),
    ...(searchBy && { searchBy }),
    ...(sortBy && { sortBy }),
    ...(sortOrder && { sortOrder: sortOrder?.toString() }),
  };
  const searchParams = new URLSearchParams(params);

  return api.get<PaginatedResponse<GetAdminProjectResponse>>(
    `/api-admin/projects?${searchParams.toString()}`,
  );
}

export async function createProject(project: Partial<Project>) {
  return api.post<Project>('/api-admin/projects', project);
}

export async function updateProject(
  projectId: string,
  project: IUpdateProjectPayload,
) {
  return api.patch<IUpdateProjectResponse>(
    `/api-admin/projects/${projectId}`,
    project,
  );
}

export async function upgradeProjectLicense(projectId: string) {
  return api.patch<IUpdateProjectResponse>(
    `/api-admin/projects/${projectId}/upgrade-license`,
  );
}

export async function changeProjectCompany(
  projectId: string,
  companyId: string,
  ownerId: string,
) {
  return api.patch<IUpdateProjectResponse>(
    `/api-admin/projects/${projectId}/company`,
    { companyId, ownerId },
  );
}

export async function getProjectImage(projectId: string) {
  return api.get<DownloadRef>(`/api-admin/projects/${projectId}/image`);
}

export async function updateProjectImage(projectId: string, extension: string) {
  return api.post<PatchRef>(`/api-admin/projects/${projectId}/image`, {
    extension,
  });
}

export async function deleteProject(projectId: string) {
  return api.delete(`/api-admin/projects/${projectId}`);
}

export async function changeProjectOwner(projectId: string, ownerId: string) {
  return api.patch<IUpdateProjectResponse>(
    `/api-admin/projects/${projectId}/owner`,
    { ownerId },
  );
}

export async function addUsersToProject(projectId: string, userIds: string[]) {
  return api.post<IUpdateProjectResponse>(
    `/api-admin/projects/${projectId}/users`,
    userIds,
  );
}

export async function removeUserFromProject(projectId: string, userId: string) {
  return api.delete(`/api-admin/projects/${projectId}/users/${userId}`);
}

export async function addUserToProjectAdmins(
  projectId: string,
  userId: string,
) {
  return api.post(`/api-admin/projects/${projectId}/admins`, { userId });
}

export async function removeUserFromProjectAdmins(
  projectId: string,
  userId: string,
) {
  return api.delete(`/api-admin/projects/${projectId}/admins/${userId}`);
}
