import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';

import { s3 } from 'src/common/fetch';
import { resizeImage } from 'src/common/utils';
import { logger } from 'src/logger';
import {
  deleteProjectImage,
  setProjectImage,
  getProjectImage,
} from 'src/project/api/projectsApi';
import { useProject } from 'src/project/project-context';
import { ImageInput } from 'src/common/components/inputs/ImageInput';

export function ProjectImage() {
  const project = useProject();
  const queryClient = useQueryClient();
  const deleteMutation = useMutation({
    mutationFn: deleteProjectImage,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['projects', project._id],
      });
      queryClient.removeQueries({
        queryKey: ['projects', project._id, 'image'],
      });
    },
    onError: logger.capture,
  });
  const uploadMutation = useMutation({
    mutationFn: async (file: File) => {
      const { blob, extension } = await resizeImage(file);
      const { url: uploadUrl } = await setProjectImage(project._id, extension);
      return s3.upload(uploadUrl, blob);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['projects', project._id],
      }),
    onError: logger.capture,
  });

  const hasImage = !!project.image;

  const query = useQuery({
    queryKey: ['projects', project._id, 'image'],
    queryFn: () => getProjectImage(project._id),
    enabled: hasImage && !deleteMutation.isPending,
  });

  return (
    <div className="mb-8">
      <ImageInput
        src={query.data?.downloadUrl}
        onChange={uploadMutation.mutate}
        onDelete={() => deleteMutation.mutate(project._id)}
        isLoading={hasImage && query.isPending}
        isDeleting={deleteMutation.isPending}
        isUploading={uploadMutation.isPending}
      />
    </div>
  );
}
