import { store } from 'src/app/store';
import { setForgeViewerSelection } from 'src/Forge/slice';

/**
 * ViewerExtension for listening to Selection Events.
 * Writes the current selection to the redux store.
 */
class SelectionExtension extends Autodesk.Viewing.Extension {
  onSelectionBinded: any;

  load() {
    this.onSelectionBinded = this.onSelectionEvent.bind(this);
    this.viewer.addEventListener(
      Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
      this.onSelectionBinded,
    );
    return true;
  }

  unload() {
    this.viewer.removeEventListener(
      Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
      this.onSelectionBinded,
    );
    this.onSelectionBinded = null;
    return true;
  }

  onSelectionEvent() {
    const currSelection = this.viewer.getSelection();
    store.dispatch(setForgeViewerSelection(currSelection));
  }
}

export default SelectionExtension;
