import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import {
  addUserToProjectAdmins,
  removeUserFromProjectAdmins,
} from 'src/admin/apiProjects';
import { useAdminUsersQuery } from 'src/admin/queries';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Button } from 'src/common/components/buttons/Button';
import { SelectNative } from 'src/common/components/inputs/SelectNative/SelectNative';
import { Pagination } from 'src/common/components/Pagination';
import { GetAdminProjectResponse } from 'src/project/types/projects';
import { SearchSort } from 'src/common/components/SearchSort';
import { SimpleUserRow } from '../user/SimpleUserRow';
import { ProjectNewOwnerModal } from './ProjectNewOwnerModal';
import { ProjectRemoveUserModal } from './ProjectRemoveUserModal';
import { Card } from 'src/common/components/Card';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Spinner } from 'src/common/components/Spinner';

import type { CompanyPopulatedUser } from 'shared';

type Props = {
  project: GetAdminProjectResponse;
  onClickAddMembers: () => void;
};

export const ProjectEditModalUsers = ({
  project,
  onClickAddMembers,
}: Props) => {
  const { t } = useTranslation('common');

  const [searchBy, setSearchBy] = useState('');
  const [sortBy, setSortBy] = useState('surname');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const filter = {
    filterBy: 'inProject',
    filter: project._id,
  };

  const { data: paginatedUsers, isLoading: isLoadingUsers } =
    useAdminUsersQuery(
      page,
      entriesPerPage,
      searchBy,
      sortBy,
      sortOrder,
      filter,
    );

  const sortOptions: SelectSitelifeOptionType[] = [
    { label: t('surname'), value: 'surname' },
    { label: t('name'), value: 'name' },
    { label: t('email'), value: 'email' },
    { label: t('organizations'), value: 'company.name' },
  ];

  const users = paginatedUsers ? paginatedUsers.data : [];

  return (
    <div className="flex h-[600px] flex-col justify-between">
      <div className="flex flex-row items-baseline space-x-4 px-6">
        <SearchSort
          searchBy={searchBy}
          sortBy={
            sortOptions.find((sortOption) => sortOption.value === sortBy) ||
            sortOptions[0]
          }
          sortOrder={sortOrder}
          sortOptions={sortOptions}
          onSearchBy={(newSearchBy) => setSearchBy(newSearchBy)}
          onSortBy={(newSortBy) => setSortBy(newSortBy)}
          onSortOrder={(newSortOrder) => setSortOrder(newSortOrder)}
        />
        <Button size="sm" onClick={onClickAddMembers}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
      <div className="mt-6 h-full overflow-y-auto bg-gray-100 p-6">
        <Card>
          {isLoadingUsers ? (
            <Spinner containerClassName="w-full h-full my-[160px] text-center" />
          ) : users.length > 0 ? (
            <table className="relative w-full divide-y divide-gray-200 shadow">
              <thead>
                <tr>
                  <th scope="col" className="table-th">
                    {t('person')}
                  </th>
                  <th scope="col" className="table-th">
                    {t('role')}
                  </th>
                  <th scope="col" className="table-th xl:table-cell" />
                </tr>
              </thead>
              <tbody className="table-body">
                {users.map((user) => (
                  <UserRow user={user} project={project} key={user._id} />
                ))}
              </tbody>
            </table>
          ) : (
            <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
              {searchBy ? (
                <>
                  <span className="font-semibold text-shuttleGray-800">
                    {`${searchBy}`}
                  </span>{' '}
                  {t('notFound.message')}
                </>
              ) : (
                t('noUsers')
              )}
            </p>
          )}
        </Card>
      </div>
      {users.length > 0 && !isLoadingUsers && paginatedUsers && (
        <Pagination
          maxPage={Math.ceil(paginatedUsers.totalCount / entriesPerPage)}
          page={page}
          onChangePage={setPage}
          entriesPerPage={entriesPerPage}
          onChangeEntriesPerPage={setEntriesPerPage}
          className="p-6"
        />
      )}
    </div>
  );
};

type ProjectRole = 'ADMIN' | 'USER';

export const UserRow = ({
  user,
  project,
}: {
  user: CompanyPopulatedUser;
  project: GetAdminProjectResponse;
}) => {
  const { t } = useTranslation(['admin', 'common']);
  const queryClient = useQueryClient();

  const [showRemoverUserModal, setShowRemoveUserModal] = useState(false);
  const [userIdToRemove, setUserIdToRemove] = useState<string>();

  const userRole: ProjectRole = project.admins?.includes(user._id)
    ? 'ADMIN'
    : 'USER';
  const isOwner = user._id === project.owner._id;
  const gotOtherUsers = project.explicitAccess.length > 1;

  async function onChangeUserRole(newRole: ProjectRole) {
    try {
      switch (newRole) {
        case 'ADMIN':
          await addUserToProjectAdmins(project._id, user._id);
          break;
        case 'USER':
          await removeUserFromProjectAdmins(project._id, user._id);
          break;
      }
      // await updateProjectMembers(payload, project._id);
      await queryClient.invalidateQueries({
        queryKey: ['admin-projects'],
      });
      toast.success(t('admin:changeRoleSuccess'));
    } catch {
      toast.error(t('admin:changeRoleFailure'));
    }
  }

  return (
    <>
      <SimpleUserRow
        user={user}
        middleContent={
          <>
            {!isOwner && (
              <SelectNative
                value={userRole}
                onChange={(e) =>
                  onChangeUserRole(e.target.value as ProjectRole)
                }
              >
                <option value="ADMIN">{t('common:admin')}</option>
                <option value="USER">{t('common:user')}</option>
              </SelectNative>
            )}
          </>
        }
        key={user._id}
      >
        {gotOtherUsers ? (
          isOwner ? (
            <Button
              size="sm"
              variant="secondary"
              onClick={() => setUserIdToRemove(user._id)}
            >
              {t('admin:selectNewOwner')}
            </Button>
          ) : (
            <ActionIcon
              onClick={() => {
                setShowRemoveUserModal(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </ActionIcon>
          )
        ) : null}
      </SimpleUserRow>
      <ProjectRemoveUserModal
        project={project}
        userId={user._id}
        isOpen={showRemoverUserModal}
        onClose={() => {
          setShowRemoveUserModal(false);
        }}
      />
      <ProjectNewOwnerModal
        project={project}
        isOpen={!!userIdToRemove}
        onClose={() => {
          setUserIdToRemove(undefined);
        }}
      />
    </>
  );
};
