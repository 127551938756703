import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  className?: string;
  containerClassName?: string;
};

export const Spinner = ({ className, containerClassName }: Props) => (
  <div className={containerClassName}>
    <FontAwesomeIcon icon={faSpinnerThird} pulse className={className} />
  </div>
);
