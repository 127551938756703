import { forwardRef, HTMLAttributes } from 'react';

import { cn } from 'src/common/utils';

export const Paper = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  function Paper({ children, className, ...rest }, ref) {
    return (
      <div
        {...rest}
        ref={ref}
        className={cn(
          'rounded border border-gray-300 bg-white shadow',
          className,
        )}
      >
        {children}
      </div>
    );
  },
);
