import { Company } from 'src/company/types';
import { GetProjectResponse } from 'src/project/types/projects';
import { TechCrew } from 'src/project/types/techCrews';
import { ProjectUser } from 'src/project/types/projects';

export function isCompanyOwnerOrAdmin(
  userId: string,
  company: Company,
): boolean {
  return company.owner === userId || company.admins?.includes(userId);
}

export function isProjectOwnerOrAdmin(
  userId: string,
  project: GetProjectResponse,
) {
  return project.owner === userId || project.admins?.includes(userId) || false;
}

export function isConrepTechCrewUser(
  userId: string,
  techCrews: TechCrew[],
): boolean {
  return techCrews.some((tc) =>
    tc.members.map((m) => m.userId).includes(userId),
  );
}

export function resolveUserIdToName(
  id?: string,
  users?: ProjectUser[],
): string | null {
  if (id && users) {
    const found = users.find((u) => u._id === id);
    if (found) {
      if (found.surname) {
        return `${found.name} ${found.surname}`;
      }
      return found.name;
    }
  }
  return null;
}
