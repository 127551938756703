import { User } from '@sentry/react';

import type { DownloadRef } from 'src/common/types';
import type { Company } from 'src/company/types';
import type { ConrepSettings } from 'src/Conrep/settings/types';
import type { FileVersionViewReference, ImageFile } from 'src/file/types';

export enum UserRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export type ProjectInformation = {
  country?: string;
  zipCode?: string;
  city?: string;
  street?: string;
  latitude?: number;
  longitude?: number;
  projectVolume?: string;
};

export type Tag = {
  _id?: string;
  name: string;
};

export type PowerBiParameter = {
  _id?: string;
  parameterPath: string;
  parameterUnit: string;
};

export type Filter = {
  name: string;
  parameter: string;
};

export type CustomParameter = {
  name: string;
  type: string | number | boolean;
  _id?: string;
};

type ExternalProject = {
  _id: string;
  externalId: string;
  externalName: string;
  linkedBy: string;
  linkedAt: string;
  externalApplication: string;
};

export enum WeatherProviders {
  NONE = 'NONE',
  OPEN_WEATHER = 'OPEN_WEATHER',
  GERMAN_WEATHER = 'GERMAN_WEATHER',
}

export type Project = {
  _id: string;
  owner: string;
  name: string;
  description?: string;
  company: string | Company;
  admins?: string[];
  conrepSettings?: ConrepSettings;
  defaultView?: FileVersionViewReference;
  shownProperties: string[];
  customParameters: CustomParameter[];
  tags?: Tag[];
  filters: Filter[];
  image?: ImageFile;
  imageKey?: string;
  explicitAccess: string[];
  externalProjects: ExternalProject[];
  license: License;
  licenseType?: LicenseType;
  projectInformation?: ProjectInformation;
  weatherSettings?: {
    weatherProvider?: WeatherProviders;
    weatherQuickEntries?: string[];
  };
  originDemoProject?: string;
  powerBiParameters?: PowerBiParameter[];
  usedTags?: string[];
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
};

export type UpdateProjectRequest = {
  name?: string;
  description?: string;
  defaultView?: FileVersionViewReference;
  shownProperties?: string[];
  customParameters?: CustomParameter[];
  filters?: Filter[];
  admins?: string[];
  tags?: Tag[];
  projectInformation?: ProjectInformation;
  powerBiParameters?: PowerBiParameter[];
};

export type GetProjectResponse = Omit<Project, 'companyId'> & {
  company: Company | string;
  defaultSheetSet?: string; // no idea why this is on it, its not part of the api model
};

export type GetAdminProjectResponse = Omit<Project, 'company' | 'owner'> & {
  company: Company;
  owner: User;
};

export type ProjectWithDownloadRef = Omit<Project, 'companyId'> & {
  company: Company;
  downloadRef?: DownloadRef;
};

export type OpenWorkDaysResponse = {
  techCrewId: string;
  techCrewName: string;
  techCrewColor: string;
  workDays: number;
  lockedWorkDays: number;
};

export enum LicenseEvaluationType {
  VALID = 'VALID',
  EXPIRED = 'EXPIRED',
  INVALID = 'INVALID',
}

export enum LicenseType {
  FREE = 'FREE',
  BASIC = 'BASIC',
  DEMO = 'DEMO',
}

export type License = {
  _id?: string;
  type: LicenseType;
  expirationDate: string;
};

export enum ProjectFilter {
  FREE = 'FREE',
  BASIC = 'BASIC',
  ARCHIVED = 'ARCHIVED',
  DEMO = 'DEMO',
}

export type ProjectUser = {
  _id: string;
  name: string;
  surname: string;
  position?: string;
  updatedAt?: Date;
  role: UserRole;
  email: string;
  isInProjectCompany: boolean;
};

enum InvitationStatus {
  'PENDING' = 'PENDING',
  'ACCEPTED' = 'ACCEPTED',
  'DECLINED' = 'DECLINED',
  'WITHDRAWN' = 'WITHDRAWN',
}

export type ProjectInvitation = {
  _id: string;
  company: string; // id
  companyName: string;
  createdAt: string;
  createdBy: string; // id
  createdByName: string;
  email: string;
  project: string; // id
  projectName: string;
  status: InvitationStatus;
};

export enum ServiceSpecificationType {
  ONLV = 'ONLV',
  GAEB = 'GAEB',
  CUSTOM = 'CUSTOM',
}

export type NewServiceSpecification =
  | ONLVServiceSpecification
  | GAEBServiceSpecification
  | CustomServiceSpecification;

type ONLVServiceSpecification = {
  specificationType: ServiceSpecificationType.ONLV;
  _id: string;
  name: string;
  description: string;
  originalFilename: string;
  fileExtension: string;
  parsedData: {
    onlv: any;
  };
  createdBy: string;
  createdAt: string;
};

type GAEBServiceSpecification = {
  specificationType: ServiceSpecificationType.GAEB;
  _id: string;
  name: string;
  description: string;
  originalFilename: string;
  fileExtension: string;
  parsedData: {
    gaeb: any;
  };
  createdBy: string;
  createdAt: string;
};

export type CustomServiceSpecification = {
  specificationType: ServiceSpecificationType.CUSTOM;
  _id: string;
  name: string;
  description: string;
  parsedData: {
    custom: {
      _id: string;
      key: string;
      description: string;
    }[];
  };
  createdBy: string;
  createdAt: string;
};

export type CreateCustomServiceSpecificationRequest = {
  name: string;
  description?: string;
  specificationType: ServiceSpecificationType.CUSTOM;
  parsedData: {
    custom: {
      key: string;
      description: string;
    }[];
  };
};

export type CreateServiceSpecificationResponse = {
  created: ONLVServiceSpecification | CustomServiceSpecification;
  uploadUrl?: string;
};

export type CreateServiceSpecificationRequest = {
  name: string;
  description?: string;
  originalFilename: string;
  specificationType:
    | ServiceSpecificationType.ONLV
    | ServiceSpecificationType.GAEB;
  fileExtension: string;
};

export type UpdateServiceSpecificationRequest = {
  name?: string;
  description?: string;
  parsedData?: {
    custom: {
      key: string;
      description: string;
    }[];
  };
};
