import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { Badge } from 'src/common/components/Badge';
import { AddDemoProjectButton } from './AddDemoProjectButton';
import { LicenseType } from 'src/project/types/projects';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { deleteProject } from 'src/admin/apiProjects';
import { Modal } from 'src/common/components/Modal';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { UserProjectRowRoleBadge } from './UserProjectRoleBadge';
import { Card } from 'src/common/components/Card';

import type { CompanyPopulatedUser } from 'shared';
import type { OwnerCompanyPopulatedProject } from 'shared';

type Props = {
  personalProjects: OwnerCompanyPopulatedProject[];
  user: CompanyPopulatedUser;
};

export const UserPersonalProjectsGroup = ({
  personalProjects,
  user,
}: Props) => {
  const { t } = useTranslation(['admin', 'common']);
  const queryClient = useQueryClient();

  const [showTable, setShowTable] = useState(true);
  const [demoProjectToDelete, setDemoProjectToDelete] =
    useState<OwnerCompanyPopulatedProject>();

  const existingDemoProjects = personalProjects.filter(
    (p) => p.license.type === LicenseType.DEMO,
  );
  const companyName = user.personalCompany.name;

  const onConfirmDeleteDemoProject = async () => {
    try {
      if (
        !demoProjectToDelete ||
        demoProjectToDelete.license.type !== LicenseType.DEMO
      ) {
        throw new Error();
      }
      await deleteProject(demoProjectToDelete._id);
      toast.success(t('admin:users.demo.deleteDemoProjectSuccess'));
      await queryClient.invalidateQueries({
        queryKey: ['admin-user-projects'],
      });
    } catch {
      toast.error(t('admin:users.demo.deleteDemoProjectFailure'));
    } finally {
      setDemoProjectToDelete(undefined);
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <h3 className="text-lg font-semibold ">{companyName}</h3>
          <Badge className="ml-2">{t('common:personalWorkspace')}</Badge>
        </div>
        <div className="flex items-center space-x-2">
          <AddDemoProjectButton
            user={user}
            existingDemoProjects={existingDemoProjects}
            companyName={user.personalCompany.name}
          />
          <ActionIcon onClick={() => setShowTable(!showTable)}>
            <FontAwesomeIcon icon={showTable ? faChevronDown : faChevronUp} />
          </ActionIcon>
        </div>
      </div>
      {showTable &&
        (personalProjects.length > 0 ? (
          <table className="relative w-full divide-y divide-gray-200 shadow">
            <thead>
              <tr>
                <th scope="col" className="table-th">
                  {t('common:name')}
                </th>
                <th scope="col" className="table-th normal-case">
                  {t('admin:users.modal.roleOfUser')}
                </th>
                <th scope="col" className="table-th xl:table-cell" />
              </tr>
            </thead>
            <tbody className="table-body">
              {personalProjects.map((project) => (
                <tr key={project._id}>
                  <td className="table-td table-td-truncate min-w-[200px]">
                    {project.name}
                  </td>
                  <td className="table-td">
                    <UserProjectRowRoleBadge
                      project={project}
                      userId={user._id}
                    />
                  </td>
                  <td className="table-td text-right">
                    {project.license.type === LicenseType.DEMO && (
                      <ActionIcon
                        onClick={() => setDemoProjectToDelete(project)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </ActionIcon>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Card className="w-full py-[40px] text-center text-shuttleGray-600">
            {t('admin:users.modal.emptyPersonalProjects')}
          </Card>
        ))}
      <DeleteConfirmationModal
        isOpen={!!demoProjectToDelete}
        onClose={() => setDemoProjectToDelete(undefined)}
        onConfirm={onConfirmDeleteDemoProject}
        projectName={demoProjectToDelete?.name || ''}
        companyName={companyName}
      />
    </div>
  );
};

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  projectName,
  companyName,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  projectName: string;
  companyName: string;
}) => {
  const { t } = useTranslation(['admin', 'common']);
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ConfirmationDialog
        title={t('admin:users.demo.deleteDemoProjectTitle')}
        message={
          <Trans
            ns="admin"
            i18nKey="users.demo.deleteDemoProjectMessage"
            components={[
              <span className="font-semibold text-shuttleGray-800" key="0" />,
              <span className="font-semibold text-shuttleGray-800" key="1" />,
            ]}
            values={{ projectName, companyName }}
          />
        }
        onCancel={onClose}
        onConfirm={onConfirm}
        customConfirmText={t('common:button.delete')}
      />
    </Modal>
  );
};
