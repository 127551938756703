import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import { AnalysisView } from 'src/analysis/views/AnalysisView';
import { ProjectAppbar } from 'src/app/components/appbar';
import { isProjectOwnerOrAdmin } from 'src/user/utils';
import { DashboardView } from './DashboardView';
import { NoLicense } from '../components/NoLicence';
import { LicenseEvaluationType } from '../types/projects';
import { validateLicense } from '../utils';
import { NotFoundInProjects } from '../components/NotFoundInProjects';
import {
  hasEditAnalysisPermission,
  hasViewAnalysisPermission,
  hasViewDocumentationPermission,
} from 'src/Conrep/App/ActionAllowed/utils';
import { useProjectQuery, useTechCrewsQuery } from '../queries';
import { Loading } from 'src/common/components/Loading';
import { ViewerProvider } from 'src/forge-v2/forge';
import { ViewerKitchenSink } from 'src/forge-v2/views/ViewerKitchensink';
import { ProjectProvider, useProject } from '../project-context';
import { Spinner } from 'src/common/components/Spinner';
import { useUser } from 'src/auth/user-context';
import { NotFoundInApp } from 'src/app/components/NotFoundInApp';

const ConrepView = lazy(() => import('../../Conrep/views/ConrepView'));

export const ProjectView = () => {
  const { projectId = '' } = useParams();
  const { data: project, isPending, isError } = useProjectQuery(projectId);

  if (isError) {
    // TODO: we need to determine here depending on the type of error, but for now we simply show a NotFound error
    return <NotFoundInApp />;
  }
  if (isPending) {
    return <Spinner />;
  }

  return (
    <ProjectProvider project={project}>
      <ViewerProvider>
        <Project />
      </ViewerProvider>
    </ProjectProvider>
  );
};

const Project = () => {
  const user = useUser();
  const project = useProject();

  const { data: techCrews } = useTechCrewsQuery(project._id);
  const isAdmin = isProjectOwnerOrAdmin(user._id, project);

  const hasDocumentationViewingPermissions = !!techCrews?.some((tc) =>
    hasViewDocumentationPermission(user._id, tc),
  );

  const hasAnalysisEditPermissions = !!techCrews?.some((tc) =>
    hasEditAnalysisPermission(user._id, tc),
  );

  const hasAnalysisViewPermissions = !!techCrews?.some((tc) =>
    hasViewAnalysisPermission(user._id, tc),
  );

  const licenseValidation = validateLicense(project.license);
  if (licenseValidation === LicenseEvaluationType.INVALID) {
    return <NoLicense />;
  }

  const isDev = import.meta.env.MODE === 'development';

  return (
    <>
      <Helmet>
        <title>{project.name} - Sitelife</title>
      </Helmet>
      <div className="flex h-full flex-col">
        <ProjectAppbar project={project} />
        <div className="flex h-full min-h-0 grow flex-row">
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="dashboard/*" element={<DashboardView />} />
              {(isAdmin || hasDocumentationViewingPermissions) && (
                <Route path="conrep/*" element={<ConrepView />} />
              )}
              {(isAdmin || hasAnalysisViewPermissions) && (
                <Route
                  path="a/*"
                  element={
                    <AnalysisView
                      user={user}
                      hasAnalysisEditPermissions={hasAnalysisEditPermissions}
                      hasAdminPermissions={isAdmin}
                    />
                  }
                />
              )}
              {isDev && (
                <Route
                  path="/viewer-kitchensink"
                  element={
                    <ViewerProvider>
                      <ViewerKitchenSink />
                    </ViewerProvider>
                  }
                />
              )}
              <Route index element={<Navigate to="dashboard" replace />} />
              <Route path="*" element={<NotFoundInProjects />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </>
  );
};
