import { ReactNode } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';

export const Tooltip = ({
  content,
  children,
  position,
  offset,
}: {
  children: ReactNode;
  content: ReactNode;
  position?: React.ComponentPropsWithoutRef<
    typeof RadixTooltip.Content
  >['side'];
  offset?: number;
}) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className="animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 overflow-hidden rounded-sm bg-blue-800 p-2 px-3 py-1.5 text-sm text-white shadow-md"
            sideOffset={offset ? offset : 5}
            side={position}
          >
            {content}
            <RadixTooltip.Arrow className="fill-blue-800" />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
