import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { CompanyEditModal } from './CompanyEditModal';
import { deleteCompany } from 'src/admin/apiCompanies';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Modal } from 'src/common/components/Modal';
import { Spinner } from 'src/common/components/Spinner';
import { Badge } from 'src/common/components/Badge';

import type { OwnerPopulatedCompany } from 'shared';

type Props = {
  company: OwnerPopulatedCompany;
};

export const CompanyRow = ({ company }: Props) => {
  const { t } = useTranslation(['admin', 'common']);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleConfirmDeleteCompany = async () => {
    try {
      setIsLoading(true);
      await deleteCompany(company._id);
      await queryClient.invalidateQueries({
        queryKey: ['admin-companies'],
      });
      toast.success(t('admin:organizations.delete.success'));
    } catch (exception) {
      toast.error(t('admin:organizations.delete.failure'));
    } finally {
      setIsLoading(false);
    }
    setIsDeleteConfirmationOpen(false);
  };

  const isPersonalCompany = company.owner.personalCompany === company._id;

  return (
    <tr>
      <td className="table-td table-td-truncate min-w-[200px]">
        <div className="flex flex-col">
          <span className="truncate font-semibold">{company.name}</span>
          {isPersonalCompany && (
            <span>
              <Badge className="">{t('common:personalWorkspace')}</Badge>
            </span>
          )}
        </div>
      </td>
      <td className="table-td table-td-truncate min-w-[200px]">
        {company.description}
      </td>
      <td className="table-td table-td-truncate lg:table-cell">
        {company.owner.name} {company.owner.surname}
      </td>
      <td className="table-td table-td-truncate lg:table-cell">
        {company.zipCode}
      </td>
      <td className="table-td table-td-truncate lg:table-cell">
        {company.cityName}
      </td>
      <td className="table-td flex">
        <ActionIcon
          className="ml-auto"
          onClick={() => setIsEditModalOpen(true)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </ActionIcon>
        {!isPersonalCompany && (
          <ActionIcon onClick={() => setIsDeleteConfirmationOpen(true)}>
            <FontAwesomeIcon icon={faTrash} />
          </ActionIcon>
        )}
      </td>
      <CompanyEditModal
        company={company}
        isPersonalCompany={isPersonalCompany}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
      />
      <Modal
        isOpen={isDeleteConfirmationOpen}
        onRequestClose={() => setIsDeleteConfirmationOpen(false)}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <ConfirmationDialog
            title={t('admin:organizations.delete.title')}
            message={
              <div className="flex flex-col space-y-2">
                <span>{t('admin:organizations.delete.message')}</span>
                <InlineMessage variant="warning" className="mb-4">
                  {t('admin:organizations.delete.info')}
                </InlineMessage>
              </div>
            }
            onCancel={() => setIsDeleteConfirmationOpen(false)}
            onConfirm={handleConfirmDeleteCompany}
            customConfirmText={t('common:button.delete')}
          />
        )}
      </Modal>
    </tr>
  );
};
