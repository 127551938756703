import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dataset, Workspace } from 'shared';

import { deleteDatasetById } from 'src/admin/apiAnalysis';
import { Badge } from 'src/common/components/Badge/Badge';
import { Modal } from 'src/common/components/Modal/Modal';
import { Spinner } from 'src/common/components/Spinner/Spinner';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Button } from 'src/common/components/buttons/Button';
import toast from 'src/common/toast';

type Props = {
  workspace: Workspace;
  dataset: Dataset;
};

export const DatasetRow = ({ workspace, dataset }: Props) => {
  const { t } = useTranslation(['admin', 'common']);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const queryClient = useQueryClient();

  const handleDataseteDelete = async () => {
    try {
      setIsDeleting(true);
      await deleteDatasetById(workspace.id, dataset.id);
      await queryClient.invalidateQueries({
        queryKey: ['admin-analysis'],
      });
      setIsDeleting(false);
      setIsDeleteConfirmationOpen(false);
      toast.success(t('admin:analysis.deleteDataset.success'));
    } catch {
      toast.error(t('admin:analysis.deleteDataset.failure'));
    }
  };
  return (
    <div>
      <tr key={dataset.id}>
        <td>
          <div className="flex pl-16">
            <div className="group flex flex-row items-center space-x-2 p-1 pr-2 hover:bg-shuttleGray-50">
              {dataset.badge && <Badge className="ml-1">{dataset.badge}</Badge>}
              <span className="text-td-text">{dataset.name}</span>
            </div>
          </div>
        </td>
        <td className="mt-2 flex">
          <ActionIcon
            className="ml-auto"
            onClick={() => setIsDeleteConfirmationOpen(true)}
            disabled={isDeleting}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ActionIcon>
        </td>
      </tr>
      <Modal
        isOpen={isDeleteConfirmationOpen}
        onRequestClose={() => setIsDeleteConfirmationOpen(false)}
        className="w-[432px]"
      >
        {isDeleting ? (
          <Spinner containerClassName="mx-[160px] my-[160px] text-center" />
        ) : (
          <>
            <Modal.Header onClose={() => setIsDeleteConfirmationOpen(false)}>
              {t('admin:analysis.deleteDataset.title')}
            </Modal.Header>
            <Modal.Body>
              <p className="mb-3 text-shuttleGray-600">
                {t('admin:analysis.deleteDataset.message')}
              </p>
              <div className="flex justify-end space-x-2">
                <Button
                  variant="tertiary"
                  onClick={() => setIsDeleteConfirmationOpen(false)}
                >
                  {t('common:button.abort')}
                </Button>
                <Button variant="danger" onClick={handleDataseteDelete}>
                  {t('common:button.delete')}
                </Button>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </div>
  );
};
