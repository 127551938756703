import {
  faBook,
  faBookmark,
  faCloudsSun,
  faCube,
  faHardHat,
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { SidebarMenu } from 'src/common/components/SidebarMenu';
import { LicenseType } from '../types/projects';
import { useProject } from '../project-context';

export const SettingsSidebar = () => {
  const { t } = useTranslation('project');

  const currentProject = useProject();
  const isFreeProject = currentProject.license.type === LicenseType.FREE;

  return (
    <SidebarMenu>
      <SidebarMenu.Item
        to="../information"
        icon={<FontAwesomeIcon icon={faInfoCircle} fixedWidth />}
      >
        {t('settings.information.title')}
      </SidebarMenu.Item>
      {!isFreeProject && (
        <>
          <SidebarMenu.Item
            to="../model-parameter"
            icon={<FontAwesomeIcon icon={faCube} fixedWidth />}
          >
            {t('settings.modelParameter.title')}
          </SidebarMenu.Item>
          <SidebarMenu.Item
            to="../actions"
            icon={<FontAwesomeIcon icon={faHardHat} fixedWidth />}
          >
            {t('settings.actions.title')}
          </SidebarMenu.Item>{' '}
          <SidebarMenu.Item
            to="../tags"
            icon={<FontAwesomeIcon icon={faBookmark} fixedWidth />}
          >
            {t('settings.tags.title')}
          </SidebarMenu.Item>
          <SidebarMenu.Item
            to="../service-specifications"
            icon={<FontAwesomeIcon icon={faBook} fixedWidth />}
          >
            {t('settings.serviceSpecifications.title')}
          </SidebarMenu.Item>
          <SidebarMenu.Item
            to="../weather"
            icon={<FontAwesomeIcon icon={faCloudsSun} fixedWidth />}
          >
            {t('settings.weather.title')}
          </SidebarMenu.Item>
        </>
      )}
    </SidebarMenu>
  );
};
