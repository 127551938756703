import {
  ReactNode,
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  DependencyList,
  useRef,
} from 'react';
import React from 'react';

import { getForgeViewerToken } from '../Forge/api';
import { ExtendedGuiViewer3D } from '../Forge/Viewer/Extensions/ExtendedGuiViewer3D';
import ToggleSuperfluousOptionsExtension from '../Forge/Viewer/Extensions/ToggleSuperFluousOptionsExtension';

const INITIALIZER_OPTIONS: Autodesk.Viewing.InitializerOptions = {
  env: 'AutodeskProduction2',
  api: 'streamingV2_EU',
  getAccessToken(onTokenReady) {
    getForgeViewerToken().then((token) => {
      onTokenReady &&
        onTokenReady(
          token.forgeToken.credentials.access_token,
          token.forgeToken.credentials.expires_in,
        );
    });
  },
};

function initializeViewer() {
  return new Promise<void>((resolve, reject) => {
    try {
      Autodesk.Viewing.Initializer(INITIALIZER_OPTIONS, () => {
        resolve();
      });
    } catch (e) {
      reject();
    }
  });
}

function getViewer(el: HTMLElement) {
  return new Promise<Autodesk.Viewing.GuiViewer3D>((resolve, reject) => {
    const viewer = new Autodesk.Viewing.GuiViewer3D(el, {
      extensions: [
        'Autodesk.Viewing.MarkupsCore',
        'ToggleSuperfluousOptionsExtension',
      ],
      disabledExtensions: {},
      theme: 'light-theme',
    });

    configureMeasureExtension(viewer);

    const startedCode = viewer.start();
    if (startedCode > 0) {
      return reject();
    }

    return resolve(viewer);
  });
}

function fetchDocument(viewer: Autodesk.Viewing.GuiViewer3D, urn: string) {
  return new Promise<Autodesk.Viewing.Document>((resolve, reject) => {
    const prefixedUrn = urn.startsWith('urn:') ? urn : `urn:${urn}`;
    const loadedUrn = viewer.model?.getDocumentNode().getRootNode().urn();
    const prefixedLoadedUrn = loadedUrn && `urn:${loadedUrn}`;
    if (prefixedUrn === prefixedLoadedUrn) {
      const document = viewer.model?.getDocumentNode().getDocument();
      if (!document) return reject();
      return resolve(document);
    }
    Autodesk.Viewing.Document.load(prefixedUrn, resolve, reject);
  });
}

async function loadModel(
  viewer: Autodesk.Viewing.GuiViewer3D,
  document: Autodesk.Viewing.Document,
  viewableID?: string,
) {
  const isDocumentAlreadyLoaded =
    viewableID !== undefined &&
    viewableID === viewer.model?.getDocumentNode().data.viewableID;
  if (isDocumentAlreadyLoaded) {
    return viewer.model;
  }

  let nodeToLoad = document.getRoot().getDefaultGeometry();
  if (viewableID) {
    // @ts-expect-error // TODO: check if we extend search somehow? or why this works
    const viewNode = document.getRoot().search({ viewableID });
    if (viewNode) {
      nodeToLoad = viewNode[0];
    }
  }

  const model = await viewer.loadDocumentNode(document, nodeToLoad, {
    keepCurrentModels: false,
    skipHiddenFragments: false,
  });
  return model;
}

function getHasAllowedName(nodeName: string) {
  const isAllowedName = Boolean(
    nodeName.match(new RegExp(/^(\w|\s|-|ß|ä|ö|ü|Ä|Ö|Ü)+\[([0-9]+)\]/g)),
  );
  /**
   * ATTENTION!
   * This is a very hacky hotfix for the project "Bodner Firmenzentrale"
   * Instead of a regex we just use "startsWith" to be as explicit as possible.
   * More details: https://github.com/orgs/sitelife/projects/2?pane=issue&itemId=29947085
   */
  if (!isAllowedName) {
    return nodeName.startsWith('054_136_001_FTTR_C2530_XC1_Balkon');
  }
  return isAllowedName;
}

export function getLeafNodes(viewer: Autodesk.Viewing.GuiViewer3D) {
  return new Promise<number[]>((resolve) => {
    let callbackCount = 0;
    const elements: number[] = [];
    let tree: Autodesk.Viewing.InstanceTree;

    function getLeaf(dbId: number) {
      callbackCount++;
      if (tree.getChildCount(dbId) !== 0) {
        if (getHasAllowedName(tree.getNodeName(dbId))) {
          elements.push(dbId);
        }

        tree.enumNodeChildren(dbId, getLeaf, false);
      } else {
        elements.push(dbId);
      }
      if (--callbackCount === 0) {
        resolve(elements);
      }
    }

    viewer.getObjectTree((objectTree) => {
      tree = objectTree;
      const rootId = tree.getRootId();
      getLeaf(rootId);
    });
  });
}

function registerCustomExtensions() {
  Autodesk.Viewing.theExtensionManager.registerExtension(
    'ToggleSuperfluousOptionsExtension',
    ToggleSuperfluousOptionsExtension,
  );
}

async function loadBoxSelectionExtension(viewer: Autodesk.Viewing.GuiViewer3D) {
  const boxSelectionExtension = await viewer.loadExtension(
    'Autodesk.BoxSelection',
    {
      useGeometricIntersection: true,
    },
  );
  (boxSelectionExtension as any).addToolbarButton(true);
}

async function disableCubeUiHomeButton(viewer: Autodesk.Viewing.GuiViewer3D) {
  const viewCubeUiExtension = await viewer.getExtensionAsync(
    'Autodesk.ViewCubeUi',
  );
  if (viewCubeUiExtension) {
    (viewCubeUiExtension as any).displayHomeButton(false);
  }
}

function configureMeasureExtension(viewer: ExtendedGuiViewer3D) {
  viewer.addEventListener(
    Autodesk.Viewing.EXTENSION_LOADED_EVENT,
    ({ extensionId }) => {
      if (extensionId !== 'Autodesk.Measure') {
        return;
      }

      viewer.getExtension('Autodesk.Measure', (extension) => {
        if (
          !(extension instanceof Autodesk.Extensions.Measure.MeasureExtension)
        ) {
          return;
        }

        extension.setUnits('m');
        extension.setPrecision(2);
      });
    },
  );
}

/**
 * @param urn (optional) passing a `urn` will load the document as soon as `viewer` is ready
 * @param viewableID (optional) specify which `viewableID` is automatically loaded
 * i dont know if its a good idea to allow autoload in this hook.
 * what happens if two components use the hook and both pass a value for urn?
 */
export function useViewerDocument() {
  const { viewer, model, setModel } = useContext(viewerContext);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  const currentViewableID = model?.getDocumentNode()?.data.viewableID;
  const currentUrn = model?.getDocumentNode()?.getRootNode().urn();
  const modelType: '3d' | '2d' | undefined = model?.is3d()
    ? '3d'
    : model?.is2d()
    ? '2d'
    : undefined;

  const load = useCallback(
    async function load(urnToLoad: string, viewableIDToLoad?: string) {
      if (!viewer) return;
      const _document = await fetchDocument(viewer, urnToLoad);
      const _model = await loadModel(viewer, _document, viewableIDToLoad);
      setModel(_model);

      if (_model.is3d()) {
        disableCubeUiHomeButton(viewer);
        loadBoxSelectionExtension(viewer);
      }
    },
    [setModel, viewer],
  );

  useViewerEvent(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
    setIsLoading(false);
  });

  useViewerEvent(Autodesk.Viewing.MODEL_UNLOADED_EVENT, () => {
    setIsLoading(true);
  });

  useViewerEvent(
    Autodesk.Viewing.PROGRESS_UPDATE_EVENT,
    (e: {
      type: 'progress';
      target: Autodesk.Viewing.GuiViewer3D;
      model: Autodesk.Viewing.Model;
      percent: number;
      state: number;
    }) => {
      setProgress(e.percent);
    },
  );

  return {
    urn: currentUrn,
    viewableID: currentViewableID,
    load,
    modelType,
    isLoading,
    /** percent is only updated when loading 3D models */
    progress,
  };
}

export function useViewerSetting<T>(
  settingName: keyof Autodesk.Viewing.Private.ViewerPreferences,
  defaultValue: T,
) {
  const { viewer } = useViewerInstance();
  const [value, setValue] = useState<T>(() => {
    if (!viewer) return false;
    const v = viewer.prefs.get(settingName);
    return v ? v : defaultValue ? defaultValue : undefined;
  });

  useViewerEvent(
    Autodesk.Viewing.PREF_CHANGED_EVENT,
    (event: { name: string; value: T }) => {
      if (event.name !== settingName) return;
      setValue(event.value);
    },
  );

  function setSetting(v: T) {
    if (!viewer) return;
    viewer.prefs.set(settingName, v);
  }

  return [value, setSetting] as const;
}

function useViewerInstance() {
  const { viewer, setViewer } = useContext(viewerContext);

  const setup = useCallback(
    async function setup(el: HTMLElement) {
      if (viewer) {
        return;
      }
      await initializeViewer();
      registerCustomExtensions();
      const _viewer = await getViewer(el);
      setViewer(_viewer);
    },
    [setViewer, viewer],
  );

  const teardown = useCallback(
    function teardown() {
      if (!viewer) return;
      viewer.finish();
      setViewer(undefined);
    },
    [viewer, setViewer],
  );

  return { viewer, setup, teardown };
}

export function useViewerSelection() {
  const { viewer } = useContext(viewerContext);
  const [selection, setSelection] = useState<number[]>(() =>
    viewer ? viewer.getSelection() : [],
  );

  useViewerEvent(
    Autodesk.Viewing.SELECTION_CHANGED_EVENT,
    (e: { dbIdArray: number[] }) => {
      if (!e.dbIdArray) return;
      setSelection(e.dbIdArray);
    },
  );

  function select(dbIds: number[]) {
    viewer?.select(dbIds);
  }

  return [selection, select] as const;
}

export function useViewerElements() {
  const { viewer } = useContext(viewerContext);
  const [elements, setElements] = useState<Autodesk.Viewing.PropertyResult[]>(
    [],
  );

  const loadProperties = useCallback(
    async function loadProperties() {
      if (!viewer?.model) return;
      const leafElements = await getLeafNodes(viewer);
      viewer.model.getBulkProperties(leafElements, {}, (result) => {
        setElements(result);
      });
    },
    [viewer],
  );

  useEffect(() => {
    loadProperties();
  }, [loadProperties]);

  useViewerEvent(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, loadProperties, [
    viewer,
  ]);

  function getPropertiesForExternalIds(
    externalIds: string[],
    attributeNamesFilter?: string[],
  ) {
    const result = elements.filter(
      (p) => p.externalId && externalIds.includes(p.externalId),
    );

    if (attributeNamesFilter) {
      return result.map((element) => {
        return {
          ...element,
          properties: element.properties.filter((p) =>
            attributeNamesFilter.includes(p.attributeName),
          ),
        };
      });
    }
    return result;
  }

  function getPropertiesForDbIds(
    dbIds: number[],
    attributeNamesFilter?: string[],
  ) {
    const result = elements.filter(
      (p) => p.externalId && dbIds.includes(p.dbId),
    );

    if (attributeNamesFilter) {
      return result.map((element) => {
        return {
          ...element,
          properties: element.properties.filter((p) =>
            attributeNamesFilter.includes(p.attributeName),
          ),
        };
      });
    }
    return result;
  }

  function getDbid(externalId: string) {
    return elements.find((e) => e.externalId === externalId)?.dbId;
  }

  function getExternalId(dbId: number) {
    return elements.find((e) => e.dbId === dbId)?.externalId;
  }

  /**
   * @param color in hex eg `#FF0000`
   */
  function setColor(dbIds: number[], color: string) {
    const threeColor = new THREE.Color(color);
    const vector4 = new THREE.Vector4(
      threeColor.r,
      threeColor.g,
      threeColor.b,
      1,
    );
    dbIds.forEach((id) => {
      viewer?.setThemingColor(id, vector4.normalize());
    });
  }

  function clearColors() {
    if (!viewer?.model) return;
    viewer.clearThemingColors(viewer.model);
  }

  function focus(dbIds: number[]) {
    viewer?.fitToView(dbIds);
  }

  function hide(dbIds: number[]) {
    viewer?.hide(dbIds);
  }

  function isolate(dbIds: number[]) {
    viewer?.isolate(dbIds);
  }

  return {
    elements,
    getPropertiesForDbIds,
    getPropertiesForExternalIds,
    getDbid,
    getExternalId,
    setColor,
    clearColors,
    focus,
    hide,
    isolate,
  };
}

function getViewerScreenShotAsync(viewer: Autodesk.Viewing.GuiViewer3D) {
  return new Promise<string>((resolve, reject) => {
    if (!viewer) reject('viewer not ready');
    viewer.getScreenShot(
      viewer.container.clientWidth,
      viewer.container.clientHeight,
      resolve,
    );
  });
}

export function useViewerActions() {
  const { viewer } = useContext(viewerContext);

  function resizeViewer() {
    viewer?.resize();
  }

  function getCamera() {
    if (!viewer) {
      return;
    }

    const target = viewer.navigation.getTarget();
    const position = viewer.navigation.getPosition();
    const cameraUp = viewer.navigation.getCameraUpVector();
    const eye = viewer.navigation.getEyeVector();

    return {
      cameraUp,
      target,
      position,
      eye,
    };
  }

  function setCamera({
    position,
    target,
    up,
  }: {
    position: THREE.Vector3;
    target: THREE.Vector3;
    up: THREE.Vector3;
  }) {
    if (!viewer) {
      return;
    }
    viewer.navigation.setView(position, target);
    viewer.navigation.setCameraUpVector(up);
  }

  async function getScreenshot() {
    if (!viewer) return;
    return getViewerScreenShotAsync(viewer);
  }

  return {
    resizeViewer,
    getCamera,
    setCamera,
    getScreenshot,
  };
}

function createEditMode(
  ext: Autodesk.Viewing.Extensions.Markups.Core,
  name: Autodesk.Viewing.Extensions.Markups.Core.EditMode['type'],
) {
  switch (name) {
    case 'circle':
      return new Autodesk.Viewing.Extensions.Markups.Core.EditModeCircle(ext);
    case 'cloud':
      return new Autodesk.Viewing.Extensions.Markups.Core.EditModeCloud(ext);
    case 'freehand':
      return new Autodesk.Viewing.Extensions.Markups.Core.EditModeFreehand(ext);
    case 'highlight':
      return new Autodesk.Viewing.Extensions.Markups.Core.EditModeHighlight(
        ext,
      );
    case 'polycloud':
      return new Autodesk.Viewing.Extensions.Markups.Core.EditModePolycloud(
        ext,
      );
    case 'polyline':
      return new Autodesk.Viewing.Extensions.Markups.Core.EditModePolyline(ext);
    case 'rectangle':
      return new Autodesk.Viewing.Extensions.Markups.Core.EditModeRectangle(
        ext,
      );
    case 'label':
      return new Autodesk.Viewing.Extensions.Markups.Core.EditModeText(ext);
    case 'arrow':
    default:
      return new Autodesk.Viewing.Extensions.Markups.Core.EditModeArrow(ext);
  }
}

export function useViewerMarkup() {
  const { viewer } = useViewerInstance();
  const [extension, setExtension] = useState<
    Autodesk.Viewing.Extensions.Markups.Core | undefined
  >(() =>
    viewer
      ? (viewer.getExtension(
          'Autodesk.Viewing.MarkupsCore',
        ) as Autodesk.Viewing.Extensions.Markups.Core)
      : undefined,
  );
  const [editMode, _setEditMode] = useState<string | undefined>(
    extension?.editMode?.type,
  );

  useViewerEvent(
    Autodesk.Viewing.EXTENSION_LOADED_EVENT,
    async (event: {
      extensionId: string;
      target: Autodesk.Viewing.Viewer3D;
    }) => {
      if (event.extensionId !== 'Autodesk.Viewing.MarkupsCore') return;
      const ext = (await event.target.getExtensionAsync(
        'Autodesk.Viewing.MarkupsCore',
      )) as Autodesk.Viewing.Extensions.Markups.Core | undefined;
      if (!ext) return;
      setExtension(ext);
    },
  );

  useEffect(() => {
    if (!extension) return;

    function handleEventModeChanged(e: any) {
      if (!e.target) return;
      _setEditMode(e.target.type);
    }

    function handleEventModeLeave() {
      _setEditMode(undefined);
    }

    extension.addEventListener(
      'EVENT_EDITMODE_CHANGED',
      handleEventModeChanged,
    );
    extension.addEventListener('EVENT_EDITMODE_LEAVE', handleEventModeLeave);

    return () => {
      if (!extension) return;
      extension.removeEventListener(
        'EVENT_EDITMODE_CHANGED',
        handleEventModeChanged,
      );
      extension.removeEventListener(
        'EVENT_EDITMODE_LEAVE',
        handleEventModeLeave,
      );
    };
  }, [extension]);

  function setStyle({
    strokeWidth = 5,
    strokeColor = '#434190',
    fontSize = 5,
    strokeOpacity = 1,
  }: {
    strokeWidth?: number;
    strokeColor?: string;
    fontSize?: number;
    strokeOpacity?: number;
  } = {}) {
    if (!extension?.editMode) return;
    const styleObject =
      Autodesk.Viewing.Extensions.Markups.Core.Utils.createStyle(
        ['stroke-width', 'stroke-color', 'stroke-opacity', 'font-size'],
        extension,
      );

    let sizeMultiplier = 1;
    const cameraDistance = viewer?.navigation.getPosition().length();
    if (cameraDistance) {
      sizeMultiplier = cameraDistance / 1400;
    }
    styleObject['stroke-width'] = strokeWidth * sizeMultiplier;
    styleObject['font-size'] = fontSize * sizeMultiplier;
    styleObject['stroke-color'] = strokeColor;
    styleObject['stroke-opacity'] = strokeOpacity;

    extension.setStyle(styleObject);
  }

  function leaveEditMode() {
    extension?.hide();
  }

  function enterEditMode() {
    extension?.enterEditMode();
  }

  function changeEditMode(
    mode: Autodesk.Viewing.Extensions.Markups.Core.EditMode['type'],
  ) {
    if (!extension) return;
    if (!editMode) {
      enterEditMode();
    }
    const modeInstance = createEditMode(extension, mode);
    if (!modeInstance) return;
    extension.changeEditMode(modeInstance);
  }

  async function getScreenshot() {
    if (!viewer || !extension) return;
    const blobUrl = await getViewerScreenShotAsync(viewer);
    if (!blobUrl) return;

    const screenshotEl = await new Promise<HTMLImageElement>(
      (resolve, reject) => {
        const element: HTMLImageElement = new Image();
        element.onload = () => resolve(element);
        element.onerror = reject;
        element.src = blobUrl;
      },
    );

    const canvasEl = document.createElement('canvas');
    const { width, height } = viewer.getDimensions();
    canvasEl.width = width;
    canvasEl.height = height;
    const ctx = canvasEl.getContext('2d');
    if (!ctx) return;
    ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
    ctx.drawImage(screenshotEl, 0, 0, canvasEl.width, canvasEl.height);

    if (extension.markups.length > 0) {
      await new Promise<void>((resolve, reject) => {
        try {
          extension.renderToCanvas(ctx, resolve);
        } catch (e) {
          reject(e);
        }
      });
    }

    const canvasBlob = await new Promise<Blob>((resolve, reject) => {
      canvasEl.toBlob((blob) => {
        if (!blob) return reject();
        resolve(blob);
      }, 'image/png');
    });

    return URL.createObjectURL(canvasBlob);
  }

  return {
    changeEditMode,
    editMode,
    leaveEditMode,
    enterEditMode,
    setStyle,
    getScreenshot,
  };
}

/**
 * use with caution!
 *
 * Autodesk.Viewer events are not really typed, so you gotta do some investigation to find what you get in the event payload
 */
export function useViewerEvent(
  event: string,
  callback: (e: any) => any,
  callbackDependencies: DependencyList = [],
) {
  const { viewer } = useViewerInstance();

  // rule disabled because we manually controll the dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useCallback(callback, [callbackDependencies]);

  useEffect(() => {
    if (!viewer) return;
    viewer.addEventListener(event, callback);
    return () => {
      if (!viewer) return;
      viewer.removeEventListener(event, callback);
    };
  }, [viewer, callback, event]);
}

type ViewerContext = {
  viewer: Autodesk.Viewing.GuiViewer3D | undefined;
  setViewer: (viewer?: Autodesk.Viewing.GuiViewer3D) => void;
  model: Autodesk.Viewing.Model | undefined;
  setModel: (viewer: Autodesk.Viewing.Model) => void;
};

const viewerContext = createContext<ViewerContext>({
  viewer: undefined,
  setViewer: () => undefined,
  model: undefined,
  setModel: () => undefined,
});

export const ViewerProvider = ({ children }: { children: ReactNode }) => {
  const [viewer, setViewer] = useState<Autodesk.Viewing.GuiViewer3D>();
  const [model, setModel] = useState<Autodesk.Viewing.Model>();

  return (
    <viewerContext.Provider
      value={{
        viewer,
        setViewer,
        model,
        setModel,
      }}
    >
      {children}
    </viewerContext.Provider>
  );
};

export const Viewer = React.memo(function Viewer({
  urn,
  viewableId,
}: {
  urn: string;
  viewableId?: string;
}) {
  const { load } = useViewerDocument();

  useEffect(() => {
    (async () => {
      await load(urn, viewableId);
    })();
  }, [load, urn, viewableId]);

  return <ViewerCanvas />;
});

const ViewerCanvas = React.memo(function ViewerCanvas() {
  const el = useRef<HTMLDivElement>(null);
  const { setup, teardown } = useViewerInstance();

  useEffect(() => {
    (async () => {
      if (!el.current) return;
      await setup(el.current);
    })();

    return () => {
      teardown();
    };
  }, [setup, teardown]);

  return <div ref={el} className="relative isolate h-full w-full" />;
});
