import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';

import { ForgeViewerBase, ForgeViewerHeader } from 'src/Forge';
import { TechCrew } from 'src/project/types/techCrews';
import { Button } from 'src/common/components/buttons/Button';
import {
  GetProjectResponse,
  LicenseEvaluationType,
} from 'src/project/types/projects';
import { getOpenWorkDays } from 'src/project/api/projectsApi';
import { LicenseExpiredMessage } from 'src/project/components/LicenseExpiredMessage';
import { validateLicense } from 'src/project/utils';
import { setupProject } from '../api';
import { RefreshHistory } from '../types';
import { Card } from 'src/common/components/Card';
import { Spinner } from 'src/common/components/Spinner';
import { useProject } from 'src/project/project-context';
import { useSheetSetsQuery } from 'src/sheetset/queries';
import { useFilesQuery } from 'src/file/queries';
import { getViewsFromSheetSet, isModelFile } from 'src/file/util';
import ThreeDisGreatIllustration from 'src/assets/illu_3d-is-great.svg';
import { SheetSet } from 'src/sheetset/types';
import { PowerBiEmbedded } from '../components/PowerBiEmbedded';

export const AnalysisProView = ({
  pageName,
  techCrews,
  history,
  hasAdminPermissions,
}: {
  pageName: string;
  techCrews: TechCrew[];
  history: RefreshHistory | undefined;
  hasAdminPermissions: boolean;
}) => {
  const { t } = useTranslation('analysis');
  const project = useProject();
  const [selectedTechCrewId, setSelectedTechCrewId] = useState<string>();
  const [selectedViewId, setSelectedViewId] = useState<string>();

  const { data: sheetSets } = useSheetSetsQuery(project._id);
  const { data: files } = useFilesQuery(project._id);
  const modelFiles = files?.filter(isModelFile) || [];
  const techCrewSheetSets: SheetSet[] | undefined = sheetSets?.filter((ss) =>
    techCrews.some((tc) => tc.sheetSets?.includes(ss._id)),
  );

  const views = techCrewSheetSets
    ?.map((sheetSet) => getViewsFromSheetSet(sheetSet, modelFiles))
    .flat();

  const techCrew = techCrews.find((tc) => tc._id === selectedTechCrewId);
  const projectHasModelViews = views && views.length > 0;

  const defaultTechCrewView = views?.find(
    (v) => v.viewableId === techCrew?.defaultView?.viewableId,
  );

  const selectedView = views?.find((v) => v._id === selectedViewId);

  const [isPowerBILoading, setisPowerBILoading] = useState(true);
  const workdayCount = useOpenWorkDaysCount(project._id);

  useEffect(() => {
    setSelectedTechCrewId(undefined);
    setSelectedViewId(undefined);
  }, [pageName]);

  useEffect(() => {
    if (defaultTechCrewView) {
      setSelectedViewId(defaultTechCrewView._id);
    }
  }, [defaultTechCrewView, selectedTechCrewId]);

  if (!history) {
    return (
      <Spinner
        className="text-2xl"
        containerClassName="w-full my-[160px] text-center"
      />
    );
  }

  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;

  const isSetupCompleted = () =>
    history.refreshHistory.length > 0 &&
    history.refreshHistory.findIndex((x) => x.status === 'Completed') > -1;

  return (
    <div className="flex h-full w-full flex-col gap-6 overflow-hidden px-[60px] py-4">
      {isLicenseExpired && <LicenseExpiredMessage />}
      <Card
        className={
          projectHasModelViews
            ? 'h-full lg:min-w-[610px]'
            : 'mx-auto h-full w-full lg:w-1/2 lg:min-w-[610px]'
        }
      >
        {isSetupCompleted() ? (
          <div className="flex h-full gap-6">
            <div className="relative flex-1 lg:min-w-[610px]">
              <PowerBiEmbedded
                datasetId={history.powerBiDatasetId}
                pageName={pageName}
                techCrews={techCrews}
                refreshHistory={history}
                onSelectTechCrew={setSelectedTechCrewId}
                onRendered={() => setisPowerBILoading(false)}
              />
              {isPowerBILoading && (
                <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-white">
                  <Spinner className="text-8xl" />
                </div>
              )}
            </div>
            {projectHasModelViews ? (
              <div className="hidden w-0 flex-1  lg:block">
                <div className="flex h-full flex-col">
                  <ForgeViewerHeader
                    views={views}
                    selectedViewId={selectedView?._id}
                    onViewChange={(v) => setSelectedViewId(v._id)}
                  />
                  <div className="h-full flex-1">
                    {selectedView ? (
                      <ForgeViewerBase view={selectedView} />
                    ) : (
                      <div className="h-full w-full">
                        <div className="flex h-full w-full items-center justify-center">
                          <div className="mx-auto my-6 max-w-[500px] text-center">
                            <img
                              className="mx-auto mb-8"
                              src={ThreeDisGreatIllustration}
                              alt=""
                            />
                            <div className="mb-6 text-shuttleGray-600">
                              {t('pro.chooseView')}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <AnalysisSetup
            hasAdminPermissions={hasAdminPermissions}
            project={project}
            workdayCount={workdayCount}
            history={history}
          />
        )}
      </Card>
    </div>
  );
};

function useOpenWorkDaysCount(projectId: string) {
  const { data: openWorkDays } = useQuery({
    queryKey: ['projects', projectId, 'open-workdays'],
    queryFn: () => getOpenWorkDays(projectId),
    initialData: [],
    staleTime: 0,
  });

  return openWorkDays.reduce((acc, tc) => acc + tc.workDays, 0);
}

const AnalysisSetup = ({
  workdayCount,
  project,
  history,
  hasAdminPermissions,
}: {
  workdayCount: number;
  project: GetProjectResponse;
  history: RefreshHistory | undefined;
  hasAdminPermissions: boolean;
}) => {
  const { t } = useTranslation('analysis');
  const [isLoading, setIsLoading] = useState(false);

  const handleSetupProject = async () => {
    setIsLoading(true);
    try {
      await setupProject(project);
    } catch (e) {
      toast.error(t('pro.setup.error'));
      setIsLoading(false);
    }
  };

  // there are setups but none has been completed yet
  const initialSetupInProgress = () =>
    history &&
    history?.refreshHistory.length > 0 &&
    history.refreshHistory.findIndex((x) => x.status === 'Completed') === -1;

  return (
    <div className="flex h-full items-center">
      <div className="mx-auto my-6 max-w-[500px] text-center">
        <img className="mx-auto mb-8" src={ThreeDisGreatIllustration} alt="" />
        <h1 className="my-4 text-4xl font-semibold">
          {!hasAdminPermissions
            ? t('pro.setup.titlePermissionRequired')
            : initialSetupInProgress()
            ? t('pro.setup.titleInProgress')
            : t('pro.setup.title')}
        </h1>
        <div className="mb-6 text-shuttleGray-600">
          {!hasAdminPermissions
            ? t('pro.setup.permissionRequired')
            : workdayCount < 10
            ? t('pro.setup.notEnoughDays')
            : initialSetupInProgress()
            ? t('pro.setup.textInProgress')
            : t('pro.setup.text')}
        </div>
        <Button
          onClick={handleSetupProject}
          loading={isLoading || initialSetupInProgress()}
          disabled={workdayCount < 10 || !hasAdminPermissions}
        >
          {t('pro.setup.setupProject')}
        </Button>
      </div>
    </div>
  );
};
