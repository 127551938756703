import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/common/components/buttons/Button';
import { PasswordInput } from 'src/common/components/inputs/PasswordInput/PasswordInput';
import { PasswordStrengthInput } from 'src/common/components/inputs/PasswordStrengthInput/PasswordStrengthInput';
import { PASSWORD_REGEX } from 'src/user/constants';

type Props = {
  isLoading: boolean;
  onSubmit: (password: string) => void;
};

export const SetPasswordForm = ({ isLoading, onSubmit }: Props) => {
  const { t } = useTranslation('auth');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const isSame = password === passwordConfirm;
  const passwordIsValid = PASSWORD_REGEX.test(password);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(password);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="my-2 text-xl font-semibold">
        {t('resetPassword.textTitle')}
      </h1>
      <PasswordStrengthInput
        label={t('resetPassword.newPassword')}
        placeholder={t('resetPassword.newPassword')}
        value={password}
        name="password"
        className="w-full"
        onChange={(e) => setPassword(e.target.value)}
        id="password"
        required
        description={t('resetPassword.passwordInputDescription')}
        error={
          password !== '' && !passwordIsValid
            ? t('resetPassword.passwordError')
            : false
        }
      />

      <PasswordInput
        label={t('resetPassword.passwordConfirm')}
        placeholder={t('resetPassword.passwordConfirm')}
        value={passwordConfirm}
        name="passwordConfirm"
        className="mt-3 w-full"
        onChange={(e) => setPasswordConfirm(e.target.value)}
        id="passwordConfirm"
        required
        error={
          passwordConfirm !== '' && !isSame
            ? t('resetPassword.passwordConfirmError')
            : false
        }
      />

      <div className="mt-8 flex w-full flex-col gap-2 text-base">
        <Button type="submit" loading={isLoading}>
          {t('resetPassword.savePassword')}
        </Button>
      </div>
    </form>
  );
};
