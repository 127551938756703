import { Action, CreateActionRequest } from '../types/actions';
import { api } from 'src/common/fetch';

export async function getActions(projectId: string) {
  return api.get<Action[]>(`/api/projects/${projectId}/actions`);
}

export async function createAction(
  projectId: string,
  action: CreateActionRequest,
) {
  return api.post<Action>(`/api/projects/${projectId}/actions`, action);
}

export async function updateAction(
  projectId: string,
  actionId: string,
  updatedData: Partial<CreateActionRequest>,
) {
  return api.post<Action>(
    `/api/projects/${projectId}/actions/${actionId}`,
    updatedData,
  );
}

export async function deleteAction(projectId: string, actionId: string) {
  return api.delete(`/api/projects/${projectId}/actions/${actionId}`);
}
