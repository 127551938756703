import {
  faBook,
  faCog,
  faHardHat,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { SidebarMenu } from 'src/common/components/SidebarMenu';
import { LicenseType } from '../types/projects';
import { useProject } from '../project-context';

export const TechCrewSidebar = () => {
  const { t } = useTranslation('project');

  const currentProject = useProject();
  const isFreeProject = currentProject.license.type === LicenseType.FREE;

  return (
    <SidebarMenu>
      <SidebarMenu.Item
        to="../settings"
        icon={<FontAwesomeIcon icon={faCog} fixedWidth />}
      >
        {t('techCrewEdit.settings')}
      </SidebarMenu.Item>
      {!isFreeProject && (
        <>
          <SidebarMenu.Item
            to="../service-specifications"
            icon={<FontAwesomeIcon icon={faBook} fixedWidth />}
          >
            {t('techCrewEdit.boqPositions')}
          </SidebarMenu.Item>

          <SidebarMenu.Item
            to="../action-groups"
            icon={<FontAwesomeIcon icon={faHardHat} fixedWidth />}
          >
            {t('techCrewEdit.actionGroups')}
          </SidebarMenu.Item>
        </>
      )}
      <SidebarMenu.Item
        to="../members"
        icon={<FontAwesomeIcon icon={faUser} fixedWidth />}
      >
        {t('techCrewEdit.members')}
      </SidebarMenu.Item>
    </SidebarMenu>
  );
};
