import {
  AcceptedError,
  BadRequestError,
  ForbiddenError,
  NotFoundError,
  ParseError,
  ServerError,
  UnauthorizedError,
} from 'src/errors';

const BASE_URL = import.meta.env.VITE_API_URL;
const DEFAULT_HEADERS: HeadersInit = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

function handleStatusCode(response: Response) {
  if (response.status === 202) {
    throw new AcceptedError();
  }
  if (response.status === 400) throw new BadRequestError(response.statusText);
  if (response.status === 403) throw new ForbiddenError(response.statusText);
  if (response.status === 404) throw new NotFoundError();
  if (response.status >= 500 && response.status <= 599) {
    throw new ServerError(response.statusText);
  }
}

async function fetchApi(path: string, options: RequestInit) {
  const fetchOptions: RequestInit = {
    credentials: 'include',
    ...options,
  };
  let response = await fetch(BASE_URL + path, fetchOptions);

  if (response.status === 401) {
    const refreshResponse = await fetch(BASE_URL + '/api/auth/refresh', {
      method: 'GET',
      credentials: 'include',
      headers: DEFAULT_HEADERS,
    });

    if (!refreshResponse.ok) {
      throw new UnauthorizedError();
    }
    response = await fetch(BASE_URL + path, fetchOptions);
  }
  handleStatusCode(response);
  return response;
}

async function parseJson<T>(response: Response) {
  const contentType = response.headers.get('Content-Type');
  let data;
  try {
    if (contentType?.includes('application/json')) {
      data = await response.json();
    }
  } catch {
    throw new ParseError();
  }
  return data as T;
}

export const api = {
  get: async <T>(path: string, headers: HeadersInit = DEFAULT_HEADERS) => {
    const response = await fetchApi(path, {
      method: 'GET',
      headers,
    });
    return parseJson<T>(response);
  },
  generateFile: async (
    path: string,
    body: any = null,
    headers: HeadersInit = DEFAULT_HEADERS,
  ) => {
    const response = await fetchApi(path, {
      method: 'POST',
      body: body ? JSON.stringify(body) : undefined,
      headers,
    });
    const filename = response.headers.get('x-sitelife-filename');
    try {
      const data = await response.blob();
      return { filename, data };
    } catch {
      throw new ParseError();
    }
  },
  getFile: async (path: string, headers: HeadersInit = DEFAULT_HEADERS) => {
    const response = await fetchApi(path, {
      method: 'GET',
      headers,
    });
    const fileName = response.headers.get('x-sitelife-filename');
    try {
      const data = await response.blob();
      return { fileName, data };
    } catch {
      throw new ParseError();
    }
  },
  post: async <T = void>(
    path: string,
    data: any = null,
    headers: HeadersInit = DEFAULT_HEADERS,
  ) => {
    const response = await fetchApi(path, {
      method: 'POST',
      body: data ? JSON.stringify(data) : undefined,
      headers,
    });
    return parseJson<T>(response);
  },
  patch: async <T = void>(
    path: string,
    data: any = null,
    headers: HeadersInit = DEFAULT_HEADERS,
  ) => {
    const response = await fetchApi(path, {
      method: 'PATCH',
      body: data ? JSON.stringify(data) : undefined,
      headers,
    });
    return parseJson<T>(response);
  },
  put: async <T = void>(
    path: string,
    data: any = null,
    headers: HeadersInit = DEFAULT_HEADERS,
  ) => {
    const response = await fetchApi(path, {
      method: 'PUT',
      body: data ? JSON.stringify(data) : undefined,
      headers,
    });
    return parseJson<T>(response);
  },
  delete: async <T = void>(
    path: string,
    data?: any,
    headers: HeadersInit = DEFAULT_HEADERS,
  ) => {
    const response = await fetchApi(path, {
      method: 'DELETE',
      body: data ? JSON.stringify(data) : undefined,
      headers,
    });
    return parseJson<T>(response);
  },
};

export const s3 = {
  get: async (url: string, headers?: HeadersInit) => {
    const options: RequestInit = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, options);
    handleStatusCode(response);
    try {
      return response.blob();
    } catch {
      throw new ParseError();
    }
  },
  upload: async (
    url: string,
    file: Blob,
    headers: HeadersInit = { 'Content-Type': file.type },
  ) => {
    const options: RequestInit = {
      method: 'PUT',
      headers,
      body: file,
    };
    const response = await fetch(url, options);
    handleStatusCode(response);
  },
  put: async (
    url: string,
    file: Blob,
    headers: HeadersInit = { 'Content-Type': file.type },
  ) => {
    const options: RequestInit = {
      method: 'PUT',
      headers,
      body: file,
    };
    const response = await fetch(url, options);
    handleStatusCode(response);
  },
};
