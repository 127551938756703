import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'src/common/components/buttons/Button';

type Props = {
  emailAddress: string;
};

export const ForgotPasswordSuccessMessage = ({ emailAddress }: Props) => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  return (
    <div>
      <h1 className="my-2 text-xl font-semibold">{t('thanks')}</h1>
      <p className="mb-2 text-shuttleGray-600">
        {t('forgotPasswordSuccess.info')}
      </p>
      <p className="mb-2 text-shuttleGray-600">
        <Trans
          ns="auth"
          i18nKey="forgotPasswordSuccess.text"
          values={{ emailAddress }}
          components={[
            <span className="font-semibold text-shuttleGray-800" key="1">
              {emailAddress}
            </span>,
          ]}
        />
      </p>
      <p className="text-shuttleGray-600">{t('forgotPasswordSuccess.spam')}</p>
      <Button
        className="mt-6 w-full"
        variant="secondary"
        onClick={() => navigate('/')}
      >
        {t('forgotPasswordForm.toLogin')}
      </Button>
    </div>
  );
};
