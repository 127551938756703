import React, { InputHTMLAttributes, ReactNode } from 'react';

import { cn } from 'src/common/utils';

type Props = { label?: ReactNode };

export const Radio = React.forwardRef<
  HTMLInputElement,
  Props & InputHTMLAttributes<HTMLInputElement>
>(function Radio({ label, id, disabled, checked, ...rest }, ref) {
  return (
    <label
      htmlFor={id}
      className={cn('inline-flex items-center gap-2', [
        {
          'text-gray-400': disabled,
        },
      ])}
    >
      <input
        id={id}
        type="radio"
        checked={checked}
        disabled={disabled}
        ref={ref}
        {...rest}
        className={cn(
          'border-gray-500 text-blue-600 focus:border-blue-800 focus:text-blue-800 focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:border-0',
          [
            {
              'disabled:text-blue-100': checked,
              'disabled:bg-gray-100': !checked,
            },
          ],
        )}
      />
      {label}
    </label>
  );
});
