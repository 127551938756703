import { useTranslation } from 'react-i18next';

import { Button } from 'src/common/components/buttons/Button';

export const SomethingWentWrong = () => {
  const { t } = useTranslation('common');
  return (
    <div role="alert" className="p-6">
      <header>{t('somethingWentWrong.title')}</header>
      <p>{t('somethingWentWrong.message')}</p>
      <Button type="button" onClick={() => window.location.reload()}>
        {t('somethingWentWrong.reloadButton')}
      </Button>
    </div>
  );
};
