import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CompanyImage } from '../components/CompanyImage';
import { Company } from '../types';
import { Button } from 'src/common/components/buttons/Button';
import deleteIllu from 'src/assets/illu_delete.svg';
import { useModal, Modal } from 'src/common/components/Modal';
import { ExternalTextLink } from 'src/common/components/TextLink';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { logger } from 'src/logger';
import { updateCompany, deleteCompany } from '../api';
import { CompanyFormValues, CompanyForm } from '../components/CompanyForm';

export const CompanySettingsView = ({ company }: { company: Company }) => {
  return (
    <>
      <CompanyImage company={company} />
      <div className="relative bg-white p-4 shadow">
        <EditCompanyForm company={company} />
      </div>
    </>
  );
};

const EditCompanyForm = ({ company }: { company: Company }) => {
  const { t } = useTranslation(['company', 'common']);
  const queryClient = useQueryClient();
  const deleteModal = useModal();
  const form = useForm({
    defaultValues: {
      name: company.name,
      description: company.description || '',
      zipCode: company.zipCode || '',
      cityName: company.cityName || '',
      websiteLink: company.websiteLink || '',
    },
    mode: 'onBlur',
  });
  const mutation = useMutation({
    mutationFn: ({
      companyId,
      formValues,
    }: {
      companyId: string;
      formValues: CompanyFormValues;
    }) => updateCompany(companyId, formValues),
  });

  const isDisabled =
    Object.keys(form.formState.errors).length > 0 || !form.formState.isDirty;

  async function handleSubmit(formValues: CompanyFormValues) {
    try {
      await mutation.mutateAsync({ companyId: company._id, formValues });
      form.reset(formValues);
      queryClient.invalidateQueries({
        queryKey: ['companies'],
      });
      toast.success(t('common:success.save'));
    } catch (e) {
      logger.capture(e);
      toast.error(t('common:errors.save'));
    }
  }

  return (
    <>
      <CompanyForm form={form} onSubmit={handleSubmit}>
        <Button onClick={deleteModal.open} variant="red" type="button">
          <FontAwesomeIcon icon={faTrash} />
          {t('delete.title')}
        </Button>
        <Button
          type="submit"
          disabled={isDisabled}
          loading={mutation.isPending}
          className="ml-auto"
        >
          {t('common:button.save')}
        </Button>
      </CompanyForm>
      <DeleteCompanyModal
        isOpen={deleteModal.isOpen}
        company={company}
        key={deleteModal.isOpen.toString()}
        onClose={deleteModal.close}
      />
    </>
  );
};

const DeleteCompanyModal = ({
  isOpen,
  company,
  onClose,
}: {
  isOpen: boolean;
  company: Company;
  onClose: () => void;
}) => {
  const { t } = useTranslation(['company', 'common']);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<{ companyName: string }>({
    mode: 'onChange',
  });
  const mutation = useMutation({
    mutationFn: deleteCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['companies'],
      });
      navigate('/');
    },
    onError: (error) => {
      logger.capture(error);
      toast.error(t('company:delete.failure'));
    },
  });

  function handleDelete() {
    mutation.mutate(company._id);
    toast.success(t('company:delete.success'));
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="w-[680px]">
      <Modal.Header onClose={onClose}>
        {t('company:delete.title')}?
      </Modal.Header>
      <Modal.Body>
        <form
          className="mt-8 flex flex-col items-center gap-12"
          onSubmit={handleSubmit(handleDelete)}
        >
          <div className="flex gap-9">
            <img src={deleteIllu} alt="" className="object-scale-down" />
            <div className="flex w-full flex-col gap-4">
              <p className="text-shuttleGray-600">
                <Trans
                  ns="company"
                  i18nKey="delete.text"
                  values={{ companyName: company.name }}
                  components={[
                    <ExternalTextLink
                      href="mailto:sitelife-help@sitelife.com"
                      key="1"
                    >
                      &nbsp;
                    </ExternalTextLink>,
                    <span
                      className="font-extrabold text-shuttleGray-800"
                      key="2"
                    />,
                  ]}
                />
              </p>
              <TextInput
                label={t('company:delete.input.label')}
                required
                error={errors['companyName']?.message}
                {...register('companyName', {
                  validate: (match) => {
                    return (
                      match === company.name ||
                      t('company:delete.input.error').toString()
                    );
                  },
                })}
              />
            </div>
          </div>
          <div className="ml-auto flex gap-2">
            <Button variant="tertiary" onClick={onClose}>
              {t('common:button.abort')}
            </Button>
            <Button
              variant="danger"
              type="submit"
              disabled={!isDirty || Object.keys(errors).length > 0}
            >
              {t('company:delete.submit')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
