import dayjs from 'dayjs';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { ImageResizeResponse } from './types';

export function compareStringAsc(a: string, b: string) {
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1;
  }
  if (a.toLowerCase() > b.toLowerCase()) {
    return 1;
  }
  return 0;
}

export function compareStringDesc(a: string, b: string) {
  if (a.toLowerCase() < b.toLowerCase()) {
    return 1;
  }
  if (a.toLowerCase() > b.toLowerCase()) {
    return -1;
  }
  return 0;
}

export function compareDateAsc(a: any, b: any, property?: string) {
  const aMoment = property ? dayjs(a[property]) : dayjs(a);
  const bMoment = property ? dayjs(b[property]) : dayjs(b);

  return aMoment.diff(bMoment);
}

export function compareDateDesc(a: string, b: string) {
  const aMoment = dayjs(a);
  const bMoment = dayjs(b);
  return bMoment.diff(aMoment);
}

export function removeDuplicatesFromArray<T>(array: T[]) {
  return array.filter((thing, index) => {
    const _thing = JSON.stringify(thing);
    return index === array.findIndex((obj) => JSON.stringify(obj) === _thing);
  });
}

export function downloadFile(absoluteUrl: string, name: string): void {
  const link = document.createElement('a');
  link.href = absoluteUrl;
  link.download = name;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  // delete link;
}

export function arraysEqual<T>(_arr1: T[], _arr2: T[]): boolean {
  if (
    !Array.isArray(_arr1) ||
    !Array.isArray(_arr2) ||
    _arr1.length !== _arr2.length
  )
    return false;

  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}

export function isArrayOfType<T>(
  array: T[] | string[] | undefined,
): array is T[] {
  if (!Array.isArray(array)) {
    return false;
  }
  if (array.length === 0) {
    return true;
  }
  return !array.some((v: any) => typeof v === 'string');
}

export function isString<T>(value: T | string): value is string {
  return typeof value === 'string';
}

export function isStringArray<T>(array: T[] | string[]): array is string[] {
  if (!Array.isArray(array)) {
    return false;
  }
  if (array.length === 0) {
    return true;
  }
  let flag = true;
  array.forEach((v: any) => {
    if (!isString(v)) {
      flag = false;
    }
  });
  return flag;
}

export function assertExists<T>(value: T): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw new Error('Expected `value` to not be null or undefined');
  }
}

export async function resizeImage(
  imageFile: File,
  maxSize = 1080,
  overrideType = false,
): Promise<ImageResizeResponse> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (fileProgressEvent) {
      const dataUrl = fileProgressEvent.target?.result as string;
      if (!dataUrl) {
        reject();
      }

      const imgToResize = new Image();

      imgToResize.onload = function () {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (!context) {
          reject();
        } else {
          const originalWidth = imgToResize.width;
          const originalHeight = imgToResize.height;

          let resizingFactor = 1;
          const widthResizeFactor = maxSize / originalWidth;
          const heightResizeFactor = maxSize / originalHeight;
          if (widthResizeFactor < 1 || heightResizeFactor < 1) {
            resizingFactor =
              widthResizeFactor < heightResizeFactor
                ? widthResizeFactor
                : heightResizeFactor;
          }

          const calculatedWidth = originalWidth * resizingFactor;
          const calculatedHeight = originalHeight * resizingFactor;

          canvas.width = calculatedWidth;
          canvas.height = calculatedHeight;

          // white background for transparent png's
          context.fillStyle = '#ffffff';
          context.fillRect(0, 0, canvas.width, canvas.height);

          context.drawImage(
            imgToResize,
            0,
            0,
            originalWidth * resizingFactor,
            originalHeight * resizingFactor,
          );

          const mimeType = overrideType ? imageFile.type : 'image/jpeg';
          const extension = overrideType
            ? `.${imageFile.name.split('.').pop() || ''}`
            : '.jpg';

          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject();
              } else {
                resolve({ blob, extension, mimeType });
              }
            },
            mimeType,
            // optional compression ration for file types that support lossy compression (e.g. jpg or webp)
            // 0.1,
          );
        }
      };

      // entry point for image.onload
      imgToResize.src = dataUrl;
    };

    // entry point for reader.onload
    reader.readAsDataURL(imageFile);
  });
}

export function isClipboardEvent(event: Event): event is ClipboardEvent {
  return 'clipboardData' in event;
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
