import {
  faBookAlt,
  faBookmark,
  faCheck,
  faEdit,
  faPlus,
  faTimes,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from 'src/common/utils';
import { Badge } from 'src/common/components/Badge';
import { Modal } from 'src/common/components/Modal';
import { Tooltip } from 'src/common/components/Tooltip';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Button } from 'src/common/components/buttons/Button';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import toast from 'src/common/toast';
import { getServiceSpecificationSuggestions } from 'src/project/api/projectsApi';
import {
  deleteTechCrewPlannedHours,
  updateTechCrewPlannedHours,
} from 'src/project/api/techCrewsApi';
import {
  LvBasedHours,
  TagBasedHours,
  TechCrew,
} from 'src/project/types/techCrews';
import { Tag } from 'src/project/types/projects';
import { refreshDataset } from '../api';
import { GaebSuggestion, Suggestion } from '../types';
import {
  isOnlvSuggestion,
  numberWithDots,
  techCrewHoursAvailableInServiceSpecification,
  unavailableTagsInTagList,
} from '../utils';
import { useProject } from 'src/project/project-context';
import { isoDateFormat } from 'src/dayjs';

export const SettingsListItem = ({
  techCrew,
  disabled,
}: {
  techCrew: TechCrew;
  disabled: boolean;
}) => {
  const { t } = useTranslation(['analysis', 'common']);
  const currentProject = useProject();
  const queryClient = useQueryClient();

  const [editHoursActive, setEditHoursActive] = useState(false);
  const [showEditTagModal, setShowEditTagModal] = useState(false);
  const [showEditLvModal, setShowEditLvModal] = useState(false);

  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [unassignablePositions, setUnassignablePositions] = useState<
    LvBasedHours[]
  >([]);
  const [unavailableTags, setUnavailableTags] = useState<TagBasedHours[]>([]);

  const [hours, setHours] = useState(0);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const [tagBasedHours, setTagBasedHours] = useState<{
    [tagId: string]: number;
  }>({});

  const [lvBasedHours, setLvBasedHours] = useState(techCrew.lvBasedHours || []);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const getSuggestions = async () => {
      let fetchedSuggestions: Suggestion[] = [];
      if (
        techCrew.serviceSpecificationPathsWorkday &&
        techCrew.serviceSpecificationPathsWorkday.serviceSpecification
      ) {
        try {
          fetchedSuggestions = await getServiceSpecificationSuggestions(
            techCrew.project,
            techCrew._id,
            techCrew.serviceSpecificationPathsWorkday?.serviceSpecification ||
              '',
            'WORKDAY',
          );
        } catch (e) {
          Sentry.captureException(e);
        }
      }
      setSuggestions(fetchedSuggestions);

      if (isOnlvSuggestion(fetchedSuggestions)) {
        // check if there are unassignable suggestions
        const unavailableLvBasedHours =
          techCrewHoursAvailableInServiceSpecification(
            lvBasedHours,
            fetchedSuggestions,
          );

        if (unavailableLvBasedHours.length > 0) {
          setUnassignablePositions(unavailableLvBasedHours);
        }
      }
    };
    getSuggestions();
  }, [lvBasedHours, techCrew]);

  useEffect(() => {
    const techCrewHours: {
      [tagId: string]: number;
    } = {};
    techCrew.tagBasedHours?.forEach((h) => {
      techCrewHours[h.tag] = h.plannedHours;
    });
    setTagBasedHours(techCrewHours);

    const unavailable = unavailableTagsInTagList(
      currentProject.tags,
      techCrewHours,
    );
    setUnavailableTags(unavailable);
  }, [currentProject.tags, techCrew.tagBasedHours]);

  const closeTagModal = () => {
    setShowEditTagModal(false);
  };

  const closeLvModal = () => {
    setLvBasedHours(techCrew.lvBasedHours || []);
    setShowEditLvModal(false);
  };

  const isCancelSaveDisabled =
    isSaving || !from || !to || dayjs(from).isAfter(dayjs(to));

  const saveTechCrewEntry = async () => {
    if (
      (from && to) ||
      Object.keys(tagBasedHours).length > 0 ||
      lvBasedHours.length > 0
    ) {
      setIsSaving(true);

      const tagHours = Object.keys(tagBasedHours).map((tag) => {
        return {
          tag,
          plannedHours: tagBasedHours[tag],
        };
      });

      const update = {
        plannedHours: hours > 0 ? hours : techCrew.plannedHours,
        startDate: from ? from : techCrew.startDate,
        endDate: to ? to : techCrew.endDate,
        tagBasedHours: tagHours.length > 0 ? tagHours : techCrew.tagBasedHours,
        lvBasedHours:
          lvBasedHours.length > 0 ? lvBasedHours : techCrew.lvBasedHours,
      };

      try {
        await updateTechCrewPlannedHours(
          currentProject._id,
          techCrew._id,
          update,
        );
        queryClient.invalidateQueries({
          queryKey: ['projects', currentProject._id, 'techCrews'],
        });
        await refreshDataset(currentProject._id);
        await queryClient.invalidateQueries({
          queryKey: ['projects', currentProject._id, 'powerbi-refreshes'],
        });
        if (isOnlvSuggestion(suggestions)) {
          const unavailableLvBasedHours =
            techCrewHoursAvailableInServiceSpecification(
              lvBasedHours,
              suggestions,
            );
          setUnassignablePositions(unavailableLvBasedHours);
        }
        toast.success(t('settings.messages.saveSuccess'));
        setEditHoursActive(false);
      } catch (e) {
        toast.error(t('settings.messages.saveError'));
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleDeleteTechCrewEntry = async () => {
    try {
      await deleteTechCrewPlannedHours(currentProject._id, techCrew._id);
      queryClient.invalidateQueries({
        queryKey: ['projects', currentProject._id, 'techCrews'],
      });
      toast.success(t('settings.messages.deleteSuccess'));
    } catch (e) {
      toast.error(t('settings.messages.deleteError'));
    }
  };

  const handleEditEntry = () => {
    setHours(techCrew.plannedHours || 0);
    setFrom(dayjs(techCrew.startDate).format(isoDateFormat));
    setTo(dayjs(techCrew.endDate).format(isoDateFormat));
    setEditHoursActive(true);
  };

  const cancelEditEntry = () => {
    setHours(techCrew.plannedHours || 0);
    setFrom('');
    setTo('');
    setEditHoursActive(false);
  };

  const removeUnassignableHours = (lvPath: string) => {
    let unassignableHours = cloneDeep(lvBasedHours);
    unassignableHours = unassignableHours.filter((h) => h.lvPath !== lvPath);
    setLvBasedHours(unassignableHours);
  };

  const removeUnassignableTag = (tagId: string) => {
    const copy = cloneDeep(tagBasedHours);
    delete copy[tagId];
    setTagBasedHours(copy);

    const unavailable = cloneDeep(unavailableTags);
    const tagIdx = unavailable.findIndex((ta) => ta.tag === tagId);

    unavailable.splice(tagIdx, 1);
    setUnavailableTags(unavailable);
  };

  const setLvPathHours = (plannedHours: number, lvPath: string) => {
    const listIdx = lvBasedHours.findIndex(
      (lvHours) => lvHours.lvPath === lvPath,
    );
    const copy = cloneDeep(lvBasedHours);
    if (listIdx === -1) {
      copy.push({
        lvPath,
        plannedHours,
      });
    } else {
      copy[listIdx] = {
        lvPath,
        plannedHours,
      };
    }

    setLvBasedHours(copy);
  };

  const getLvPathHours = (lvPath: string): number => {
    const lvHours = lvBasedHours.find(
      (plannedLvHours) => plannedLvHours.lvPath === lvPath,
    );
    if (lvHours) {
      return lvHours.plannedHours;
    }
    return 0;
  };

  const getPlannedLvHoursDifference = (): number => {
    const plannedHours = lvBasedHours.filter(
      (lvHours) =>
        !unassignablePositions.some(
          (unassignableHours) => unassignableHours.lvPath === lvHours.lvPath,
        ),
    );
    return plannedHours.reduce(
      (previous, current) => previous + current.plannedHours,
      0,
    );
  };

  const getUnavailablePlannedHoursDifference = (): number => {
    const unplannedHours = unassignablePositions.filter((unplanned) =>
      lvBasedHours.some(
        (unassignableHours) => unassignableHours.lvPath === unplanned.lvPath,
      ),
    );
    return unplannedHours.reduce(
      (previous, current) => previous + current.plannedHours,
      0,
    );
  };

  const getUnavailableTagHoursDifference = (): number => {
    return unavailableTags.reduce(
      (previous, current) => previous + current.plannedHours,
      0,
    );
  };

  const getTagHoursDifference = (tags: Tag[] | undefined): number => {
    if (tags && techCrew.tagBasedHours) {
      const availableHours = techCrew.tagBasedHours.filter((plannedTag) =>
        tags.some((availableTag) => availableTag._id === plannedTag.tag),
      );

      return availableHours.reduce(
        (previous, current) => previous + current.plannedHours,
        0,
      );
    }
    return 0;
  };

  const renderUnassignableHours = (unassignableHours: LvBasedHours) => {
    const deleteFlag = !lvBasedHours.some(
      (h) => h.lvPath === unassignableHours.lvPath,
    );

    return (
      <tr className="table-row w-0">
        <td className="table-td">
          <Badge>?</Badge>
        </td>
        <td
          className={cn('table-td table-td-text ', {
            'line-through': deleteFlag,
          })}
        >
          {unassignableHours.lvPath}
        </td>
        <td
          className={cn('table-td table-td-text px-0 text-right', {
            'line-through': deleteFlag,
          })}
        >
          {unassignableHours.plannedHours} {t('settings.hours')}
        </td>
        <td className="table-td w-0 py-0 text-right">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => removeUnassignableHours(unassignableHours.lvPath)}
            disabled={deleteFlag}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </td>
      </tr>
    );
  };

  const renderSuggestion = (suggestion: any) => {
    if (suggestion.children) {
      return (
        <>
          {suggestion.children.map((sugg: any) => (
            <React.Fragment key={sugg.value}>
              {renderSuggestion(sugg)}
            </React.Fragment>
          ))}
        </>
      );
    }

    return (
      <tr>
        <td className="table-td">
          <Badge>{suggestion.nodeType ? suggestion.nodeType : 'BAS'}</Badge>
        </td>
        <td className="table-td table-td-text max-w-[400px] px-0">
          <div className="overflow-x-auto">
            {`${String(suggestion.label)} - ${String(suggestion.description)}`}
          </div>
        </td>
        <td className="table-td table-td-text py-0 text-right">
          <TextInput
            type="number"
            min={0}
            onChange={(e) =>
              setLvPathHours(parseInt(e.target.value), suggestion.value)
            }
            value={getLvPathHours(suggestion.value)}
            className="mr-2 w-[85px]"
          />
          {t('settings.hours')}
        </td>
      </tr>
    );
  };

  const renderGaebSuggestion = (suggestion: GaebSuggestion) => {
    if (suggestion.children) {
      return (
        <>
          {suggestion.children.map((child: GaebSuggestion) => (
            <React.Fragment key={child.rNoPart}>
              {renderGaebSuggestion(child)}
            </React.Fragment>
          ))}
        </>
      );
    }

    return (
      <tr>
        <td className="table-td table-td-text max-w-[400px] px-0">
          <div className="mx-4 overflow-x-auto">
            {`${String(suggestion.rNoPart)} - ${suggestion.label}`}
          </div>
        </td>
        <td className="table-td table-td-text py-0 text-right">
          <TextInput
            type="number"
            min={0}
            onChange={(e) =>
              setLvPathHours(parseInt(e.target.value), suggestion.rNoPart)
            }
            value={getLvPathHours(suggestion.rNoPart)}
            className="mr-2 w-[85px]"
          />
          {t('settings.hours')}
        </td>
      </tr>
    );
  };

  return (
    <>
      <tr
        key={techCrew._id}
        className="h-20 border-gray-100 bg-white text-shuttleGray-700"
      >
        <td className="table-td table-td-text">
          <div className="flex items-center">
            <div
              className="mr-3 h-4 w-4 min-w-[16px] rounded"
              style={{
                backgroundColor: techCrew.color,
              }}
            />
            <span className="truncate font-semibold">{techCrew.name}</span>
          </div>
        </td>
        <td className="table-td table-td-text">
          <div className="flex w-40 items-center">
            {editHoursActive ? (
              <div>
                <TextInput
                  type="number"
                  min={0}
                  onChange={(e) => setHours(parseInt(e.target.value))}
                  value={hours}
                  className="box-sizing-border-box -ml-4 h-10 w-28 px-4"
                />
                {t('settings.hours')}
              </div>
            ) : techCrew.plannedHours !== undefined &&
              techCrew.plannedHours > 0 ? (
              techCrew ? (
                `${numberWithDots(techCrew.plannedHours)} ${t(
                  'settings.hours',
                )}`
              ) : null
            ) : (
              <Button
                variant="secondary"
                size="sm"
                onClick={() => setEditHoursActive(true)}
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faPlus} />
                {t('settings.addHours')}
              </Button>
            )}
          </div>
        </td>
        <td className="table-td table-td-text">
          <div className="flex w-full items-center gap-8">
            {editHoursActive ? (
              <>
                <TextInput
                  type="date"
                  onChange={(event) => {
                    setFrom(event.target.value);
                  }}
                  max={to}
                  value={from}
                  className="w-full"
                />
                <span>-</span>
              </>
            ) : (
              <span>
                {techCrew.startDate
                  ? dayjs(techCrew.startDate).format('L')
                  : null}
              </span>
            )}
          </div>
        </td>
        <td className="table-td table-td-text">
          <div className="flex w-3/4 items-center">
            {editHoursActive ? (
              <TextInput
                type="date"
                onChange={(event) => setTo(event.target.value)}
                value={to}
                min={from}
                className="w-full"
              />
            ) : techCrew.endDate ? (
              dayjs(techCrew.endDate).format('L')
            ) : null}
          </div>
        </td>
        <td className="table-td table-td-text">
          <div className="flex justify-end space-x-2">
            {editHoursActive ? (
              <>
                <Button
                  size="sm"
                  variant="secondary"
                  className="h-8 w-8"
                  onClick={cancelEditEntry}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
                <Button
                  size="sm"
                  className="h-8 w-8"
                  loading={isSaving}
                  onClick={saveTechCrewEntry}
                  disabled={isCancelSaveDisabled}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </>
            ) : (
              <div>
                {techCrew.startDate ? (
                  <Tooltip content={t('settings.hoursOnLv')}>
                    <ActionIcon onClick={() => setShowEditLvModal(true)}>
                      <FontAwesomeIcon icon={faBookAlt} />
                    </ActionIcon>
                  </Tooltip>
                ) : null}

                {techCrew.startDate ? (
                  <Tooltip content={t('settings.hoursOnTags')}>
                    <ActionIcon onClick={() => setShowEditTagModal(true)}>
                      <FontAwesomeIcon icon={faBookmark} />
                    </ActionIcon>
                  </Tooltip>
                ) : null}

                <Tooltip content={t('settings.editHours')}>
                  <ActionIcon disabled={disabled} onClick={handleEditEntry}>
                    <FontAwesomeIcon icon={faEdit} />
                  </ActionIcon>
                </Tooltip>

                <Tooltip content={t('settings.deleteHours')}>
                  <ActionIcon
                    disabled={disabled}
                    onClick={handleDeleteTechCrewEntry}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </ActionIcon>
                </Tooltip>
              </div>
            )}
          </div>
        </td>
      </tr>
      {showEditTagModal && (
        <Modal
          isOpen={showEditTagModal}
          onRequestClose={closeTagModal}
          contentLabel="Tag Details Modal"
          className="w-full max-w-[800px]"
        >
          <div className="flex h-full flex-col p-4">
            <Modal.Header onClose={closeTagModal}>
              {t('settings.hoursOnTags')}
            </Modal.Header>
            <Modal.Body>
              <div className="-ml-4 -mr-6 mt-2 overflow-y-scroll">
                <form
                  id="set-tag-hours"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    setIsSaving(true);
                    await saveTechCrewEntry();
                    setIsSaving(false);
                    setShowEditTagModal(false);
                  }}
                >
                  <div>
                    {unavailableTags.length > 0 ? (
                      <table className="w-full divide-y divide-gray-100">
                        <thead className="table-head">
                          <tr>
                            <th className="table-th">
                              {t('settings.unavailableLabels')}
                            </th>
                            <th />
                            <th className="table-th text-right">
                              {getUnavailableTagHoursDifference()}
                              {' / '}
                              {techCrew.plannedHours} {t('settings.hours')}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-red-100  text-red-700">
                          {unavailableTags.map((tag) => {
                            const deleteFlag = false;
                            return (
                              <tr key={tag.tag}>
                                <td
                                  className={cn(
                                    'table-td table-td-text table-td-truncate max-w-[350px] ',
                                    {
                                      'line-through': deleteFlag,
                                    },
                                  )}
                                >
                                  {t('settings.unknownLabel')}
                                </td>
                                <td
                                  className={cn(
                                    'table-td table-td-text text-right ',
                                    {
                                      'line-through': deleteFlag,
                                    },
                                  )}
                                >
                                  {tag.plannedHours} {t('settings.hours')}
                                </td>
                                <td className="table-td table-td-text text-right">
                                  <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={() =>
                                      removeUnassignableTag(tag.tag)
                                    }
                                    disabled={deleteFlag}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : null}

                    <table className="w-full divide-y divide-gray-100">
                      <thead className="table-head">
                        <tr>
                          <th className="table-th">{t('settings.tags')}</th>
                          <th className="table-th text-right">
                            {getTagHoursDifference(currentProject.tags)}
                            {' / '}
                            {techCrew.plannedHours} {t('settings.hours')}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-shuttleGray-700">
                        {currentProject.tags
                          ? currentProject.tags.map((tag) => {
                              return (
                                <tr key={tag._id}>
                                  <td className="table-td table-td-text table-td-truncate max-w-[350px]">
                                    {tag.name}
                                  </td>
                                  <td className="table-td table-td-text text-right">
                                    <TextInput
                                      type="number"
                                      min={0}
                                      onChange={(e) => {
                                        if (tag._id) {
                                          const copy = cloneDeep(tagBasedHours);
                                          copy[tag._id] = parseInt(
                                            e.target.value,
                                          );
                                          setTagBasedHours(copy);
                                        }
                                      }}
                                      value={
                                        tag._id && tagBasedHours[tag._id]
                                          ? tagBasedHours[tag._id]
                                          : 0
                                      }
                                      className="mr-2 w-[85px]"
                                    />
                                    {t('settings.hours')}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>

                  {currentProject.tags?.length === 0 ? (
                    <div className="flex h-full">
                      <div className="m-auto text-shuttleGray-600">
                        {t('settings.noSuggestions')}
                      </div>
                    </div>
                  ) : null}
                </form>
              </div>
              {currentProject.tags?.length !== 0 ? (
                <div className="mt-2 flex h-full items-center justify-end">
                  <Button type="submit" loading={isSaving} form="set-tag-hours">
                    {t('common:button.save')}
                  </Button>
                </div>
              ) : null}
            </Modal.Body>
          </div>
        </Modal>
      )}
      {showEditLvModal && (
        <Modal
          isOpen={showEditLvModal}
          onRequestClose={closeLvModal}
          contentLabel="Lv Details Modal"
          className="w-full max-w-[800px]"
        >
          <Modal.Header onClose={closeLvModal}>
            {t('settings.hoursOnLv')}
          </Modal.Header>
          <Modal.Body>
            <div className="-ml-4 -mr-6 max-h-[400px] overflow-y-scroll">
              <form
                id="set-lv-hours"
                onSubmit={async (e) => {
                  e.preventDefault();
                  setIsSaving(true);
                  await saveTechCrewEntry();
                  setIsSaving(false);
                  setShowEditLvModal(false);
                }}
                autoComplete="on"
              >
                {unassignablePositions.length > 0 ? (
                  <table className="w-full">
                    <thead className="table-head">
                      <tr className="table-row">
                        <th className="table-th">
                          <span>Unassignable Hours</span>
                        </th>
                        <th />
                        <th />
                        <th className="table-th text-right">
                          {getUnavailablePlannedHoursDifference()}
                          {' / '}
                          {techCrew.plannedHours ?? 0} {t('settings.hours')}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full divide-y divide-red-100 text-red-700">
                      {unassignablePositions.map((unassignableHours) => {
                        return renderUnassignableHours(unassignableHours);
                      })}
                    </tbody>
                  </table>
                ) : null}
                <table className="w-full">
                  <thead className="table-head">
                    <tr className="table-row">
                      <th className="table-th">
                        <span>{t('settings.positions')}</span>
                      </th>
                      <th />
                      <th className="table-th text-right">
                        {getPlannedLvHoursDifference()}
                        {' / '}
                        {techCrew.plannedHours ?? 0} {t('settings.hours')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="w-full divide-y divide-gray-100 text-shuttleGray-700">
                    {suggestions.map((suggestion) => {
                      const suggestionType = isOnlvSuggestion(suggestion);
                      const key = suggestionType
                        ? suggestion.value
                        : suggestion.rNoPart;

                      const content = suggestionType
                        ? renderSuggestion(suggestion)
                        : renderGaebSuggestion(suggestion);

                      return (
                        <React.Fragment key={key}>{content}</React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </form>
            </div>
            {suggestions.length !== 0 ? (
              <div className="flex justify-end pt-6">
                <Button type="submit" loading={isSaving} form="set-lv-hours">
                  {t('common:button.save')}
                </Button>
              </div>
            ) : null}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
