import * as amplitude from '@amplitude/analytics-browser';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { Modal } from 'src/common/components/Modal';
import toast from 'src/common/toast';
import { matchesSearchFilter } from 'src/file/util';
import { LicenseEvaluationType } from 'src/project/types/projects';
import { getViewDict, validateLicense } from 'src/project/utils';
import { isProjectOwnerOrAdmin } from 'src/user/utils';
import { createSheetSet } from '../../api';
import { SheetSet } from '../../types';
import { CreateSheetSetButton } from '../CreateSheetSetButton';
import { SheetSetForm } from '../SheetSetForm';
import { SheetSetItem } from './Item';
import { Tooltip } from 'src/common/components/Tooltip';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { useProject } from 'src/project/project-context';
import { useUser } from 'src/auth/user-context';
import { useSheetSetsQuery } from 'src/sheetset/queries';
import { useFilesQuery } from 'src/file/queries';

export const SheetSetList = ({ searchFilter }: { searchFilter?: string }) => {
  const { t } = useTranslation(['sheetset', 'common']);
  const project = useProject();
  const user = useUser();
  const queryClient = useQueryClient();
  const { data: sheetSets } = useSheetSetsQuery(project._id);
  const { data: files } = useFilesQuery(project._id);
  const [showNewSheetSetModal, setShowNewSheetSetModal] = useState(false);
  const viewDict = getViewDict(files);

  const handleSave = async (sheetSetData: Partial<SheetSet>) => {
    try {
      await createSheetSet(project._id, {
        name: sheetSetData.name || '',
        views: sheetSetData.views || [],
      });
      queryClient.invalidateQueries({
        queryKey: ['sheetsets', project._id],
      });
      toast.success(t('sheetset:addSheetSetSuccess'));
      amplitude.track('create sheetset', { projectId: project._id });
      setShowNewSheetSetModal(false);
    } catch (e) {
      toast.error(t('sheetset:addSheetSetFailure'));
    }
  };

  const isViewDictEmpty = isEmptyObject(viewDict);
  const isOwnerOrAdmin = isProjectOwnerOrAdmin(user._id, project);
  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;
  const filteredSheetSets =
    sheetSets?.filter((ss) => matchesSearchFilter(ss.name, searchFilter)) || [];
  const sheetSetNames = sheetSets?.map((ss) => ss.name) || [];

  return (
    <>
      {isViewDictEmpty && (
        <InlineMessage variant="warning" className="mb-4">
          {t('sheetset:noViewsAvailable')}
        </InlineMessage>
      )}
      <div
        className="grid gap-4"
        style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr)' }}
      >
        {filteredSheetSets.map((ss) => (
          <SheetSetItem
            takenNames={sheetSetNames}
            sheetSet={ss}
            disabled={!isOwnerOrAdmin || isLicenseExpired}
            key={ss._id}
          />
        ))}
        {isOwnerOrAdmin && (
          <Tooltip
            content={isViewDictEmpty ? t('sheetset:noViewsAvailable') : null}
          >
            <div className="w-full">
              <CreateSheetSetButton
                onClick={() => setShowNewSheetSetModal(true)}
                disabled={isLicenseExpired || isViewDictEmpty}
              />
            </div>
          </Tooltip>
        )}
        <Modal isOpen={showNewSheetSetModal} className="w-[768px]">
          <SheetSetForm
            takenNames={sheetSetNames}
            onCancel={() => setShowNewSheetSetModal(false)}
            onSave={handleSave}
          />
        </Modal>
      </div>
    </>
  );
};

function isEmptyObject(obj: any) {
  if (!obj) {
    return true;
  }
  return Object.keys(obj).length === 0;
}
