import { faFileUpload, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'src/common/components/buttons/Button';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { LicenseType, Project } from 'src/project/types/projects';
import { useAdminCompanyUsersQuery } from 'src/admin/queries';
import { TextAreaInput } from 'src/common/components/inputs/TextAreaInput/TextAreaInput';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { SelectSitelife } from 'src/common/components/SelectSitelife';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { ImageResizeResponse } from 'src/common/types';
import { resizeImage } from 'src/common/utils';
import { Radio } from 'src/common/components/inputs/Radio';

import type { OwnerPopulatedCompany } from 'shared';

type Props = {
  project: Partial<Project>;
  setProject: (p: Partial<Project>) => void;
  company: OwnerPopulatedCompany;
  projectImage?: ImageResizeResponse;
  setProjectImage: (i?: ImageResizeResponse) => void;
  onClickBack: () => void;
  onClickNext: () => void;
};

export const ProjectAboutPage = ({
  project,
  setProject,
  company,
  projectImage,
  setProjectImage,
  onClickBack,
  onClickNext,
}: Props) => {
  const { t } = useTranslation(['admin', 'common']);

  const { data: companyMembers, isPending } = useAdminCompanyUsersQuery(
    company._id,
  );
  const [ownerOptions, setOwnerOptions] =
    useState<SelectSitelifeOptionType[]>();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const { register, reset, handleSubmit, getValues, watch } = useForm<
    Partial<Project>
  >({
    mode: 'onChange',
    defaultValues: { licenseType: LicenseType.BASIC },
  });

  useEffect(() => {
    if (project) {
      reset({ ...project });
    } else {
      reset({});
    }
    if (companyMembers) {
      setOwnerOptions(
        companyMembers.map((cm) => {
          return { label: `${cm.name} ${cm.surname}`, value: cm._id };
        }),
      );
    }
  }, [reset, project, companyMembers]);

  function handleSave(projectValues: Partial<Project>) {
    setProject(projectValues);
  }

  async function onProjectImageChange(e: ChangeEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const resizedImage = await resizeImage(target.files[0]);
      setProjectImage(resizedImage);
    } else {
      setProjectImage(undefined);
    }
  }

  return (
    <div className="flex h-full flex-col justify-end">
      <form
        className="flex h-full flex-col justify-between px-6 pb-6"
        onChange={handleSubmit(handleSave)}
      >
        <TextInput
          {...register('name')}
          label={t('common:labels.name')}
          placeholder={t('admin:projects.modal.namePlaceholder')}
          type="text"
          required
        />
        <div className="flex flex-col gap-2">
          <span>
            {t('common:owner')}
            <span className="text-red-500">* </span>
            <Trans
              ns="admin"
              i18nKey="projects.modal.fromOrganization"
              values={{ companyName: company.name }}
              components={[
                <span
                  className="font-extrabold text-shuttleGray-800"
                  key="1"
                />,
              ]}
            />
          </span>
          <SelectSitelife
            options={ownerOptions}
            value={ownerOptions?.find((o) => o.value === watch('owner'))}
            onChange={({ value: owner }: SelectSitelifeOptionType) => {
              const projectValues = getValues();
              setProject({ ...projectValues, owner });
            }}
            isLoading={isPending}
            closeMenuOnSelect
            usePortaling
          />
        </div>
        <TextAreaInput
          {...register('description')}
          className="resize-none"
          rows={5}
          label={t('common:labels.description')}
          placeholder={t('admin:projects.modal.descriptionPlaceholder')}
        />
        <div className="flex flex-col gap-2">
          <h4 className="font-semibold">
            {t('admin:projects.modal.license.title')}
          </h4>
          <Radio
            value={LicenseType.BASIC}
            label={t('admin:projects.modal.license.basic')}
            {...register('licenseType')}
          />
          <Radio
            value={LicenseType.FREE}
            label={t('admin:projects.modal.license.free')}
            {...register('licenseType')}
          />
        </div>
        <div className="flex flex-col">
          {t('admin:projects.modal.projectImage')}
          <span className="text-sm text-shuttleGray-400">
            {t('admin:projects.modal.imageInfo')}
          </span>
        </div>
        <div className="flex h-[100px] flex-col items-center justify-center rounded border border-gray-300">
          {projectImage?.blob ? (
            <div className="min-h-0 grow overflow-hidden p-2">
              <img
                alt="preview"
                className="h-full object-scale-down"
                src={URL.createObjectURL(projectImage.blob)}
              />
              <ActionIcon onClick={() => setProjectImage(undefined)}>
                <FontAwesomeIcon
                  className="absolute bottom-32 right-10"
                  icon={faTrash}
                />
              </ActionIcon>
            </div>
          ) : (
            <Button
              variant="secondary"
              onClick={() => fileInputRef.current?.click()}
            >
              <FontAwesomeIcon
                icon={faFileUpload}
                className="mr-2"
                fixedWidth
              />
              {t('admin:projects.modal.uploadImage')}
            </Button>
          )}
        </div>
        <input
          type="file"
          className="hidden"
          accept="image/png, image/jpeg"
          onChange={onProjectImageChange}
          ref={fileInputRef}
        />
      </form>
      <div className="flex items-center justify-between p-6">
        <div className="flex-1" />
        <span className="flex-1 text-center text-sm text-shuttleGray-600">
          {t('common:pagination.page')} 1 {t('common:of')} 2
        </span>
        <div className="flex-1 text-right">
          <Button variant="tertiary" onClick={onClickBack}>
            {t('common:button.abort')}
          </Button>
          <Button
            className="ml-2"
            onClick={onClickNext}
            disabled={!project.name || !project.owner}
          >
            {t('common:button.continue')}
          </Button>
        </div>
      </div>
    </div>
  );
};
