import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  CSSProperties,
  forwardRef,
} from 'react';

import { cn } from 'src/common/utils';

interface SelectInputProps extends ComponentPropsWithoutRef<'select'> {
  id?: string;
  className?: string;
  style?: CSSProperties;
  invalid?: boolean;
  error?: string | boolean;
  required?: boolean;
  disabled?: boolean;
}

export const Select = forwardRef(function Select(
  props: SelectInputProps,
  ref: ComponentPropsWithRef<'select'>['ref'],
) {
  const {
    id,
    className,
    invalid,
    required,
    disabled,
    style,
    error,
    children,
    ...rest
  } = props;

  return (
    <select
      {...rest}
      id={id}
      ref={ref}
      required={required}
      aria-invalid={invalid}
      disabled={disabled}
      style={style}
      className={cn(
        { 'border-red-500': error },
        {
          'border-gray-100 bg-gray-100 hover:border-gray-100': disabled,
        },
        {
          'border-gray-300 hover:border-gray-500': !disabled && !error,
        },
        'w-full flex-auto rounded-sm leading-[22px]',
        'focus:border focus:border-blue-600 focus:outline-none',
        className,
      )}
    >
      {children}
    </select>
  );
});
