import { NavLink } from 'react-router-dom';

import { cn } from 'src/common/utils';

export const SidebarMenu = ({ children }: { children: React.ReactNode }) => (
  <div className="flex flex-col gap-2">{children}</div>
);

type ItemProps = {
  children: React.ReactNode;
  icon: React.ReactNode;
  to: string;
};

const Item = ({ children, icon, to }: ItemProps) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cn(
          'flex min-h-[40px] items-center gap-2 rounded-sm px-4 py-3 font-medium',
          isActive
            ? 'bg-white text-blue-800 shadow'
            : 'text-shuttleGray-500 hover:text-blue-800',
        )
      }
    >
      <div className="shrink-0">{icon}</div>
      <div className="min-w-0 truncate">{children}</div>
    </NavLink>
  );
};

SidebarMenu.Item = Item;
