import { DependencyList, useEffect, useMemo } from 'react';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { isSameViewFromSameFile } from 'src/file/util';
import { parseFilterPresetToStoreFormat } from 'src/Conrep/App/Model/Filter/utils';
import toast from 'src/common/toast';
import {
  selectForgeViewerExternalIdToDbIdMapping,
  selectForgeViewerMarkupActive,
  selectForgeViewerReady,
  selectForgeViewerRestoreViewData,
  selectForgeViewerViewReference,
  setForgeViewerDbIdToExternalIdMapping,
  setForgeViewerExternalIdToDbIdMapping,
  setForgeViewerExternalIdToFileIdMapping,
  setForgeViewerFiltering,
  setForgeViewerIsolated,
  setForgeViewerIsolatedUnsync,
  setForgeViewerLeafNodes,
  setForgeViewerMarkupActive,
  setForgeViewerNaming,
  setForgeViewerReady,
  setForgeViewerRestoreViewData,
  setForgeViewerSelection,
  setForgeViewerViewReference,
} from './slice';
import { getSetOfViewsFromSheetSets } from 'src/sheetset/utils';
import {
  setViewerModelOptions,
  setIdFilter,
  setBlockFiltering,
  setAllElementProperties,
  setPropertySetsAll,
  setFilteredElementProperties,
  setPropertySetsFiltered,
  resetFilters,
  setFilterPreset,
  selectConrepAppTechCrew,
} from 'src/Conrep/App/slice';
import { ConrepAppFilterPreset } from 'src/Conrep/App/types';
import { s3 } from 'src/common/fetch';
import { useProject } from 'src/project/project-context';
import { useFilesQuery } from 'src/file/queries';
import { useSheetSetsQuery } from 'src/sheetset/queries';
import { getViewDict } from 'src/project/utils';
import { getElementIdOfExternalId } from './utils';
import { ForgeViewerExternalIdToDbIdMapping } from './types';

export type HashMap<ValueType> = {
  [key: string]: ValueType;
};

export const useForgeViewerCleanUp = (
  viewerRef:
    | Autodesk.Viewing.Viewer3D
    | Autodesk.Viewing.GuiViewer3D
    | undefined
    | null,
) => {
  useEffect(
    () => () => {
      if (viewerRef) {
        viewerRef.finish();
        Autodesk.Viewing.shutdown();
      }
    },
    [viewerRef],
  );
};

// https://stackoverflow.com/questions/56262515/how-to-handle-dependencies-array-for-custom-hooks-in-react
export const useForgeViewerReset = <T = any>(callback?: T) => {
  const appDispatch = useAppDispatch();
  useEffect(() => {
    batch(() => {
      appDispatch(setForgeViewerReady(false));
      appDispatch(setForgeViewerFiltering(false));
      appDispatch(setForgeViewerViewReference(null));

      appDispatch(setForgeViewerExternalIdToDbIdMapping(null));
      appDispatch(setForgeViewerExternalIdToFileIdMapping(null));
      appDispatch(setForgeViewerDbIdToExternalIdMapping(null));
      appDispatch(setForgeViewerNaming(null));
      appDispatch(setForgeViewerLeafNodes(null));
      appDispatch(setForgeViewerIsolated(null));
      appDispatch(setForgeViewerIsolatedUnsync(null));
      appDispatch(setViewerModelOptions(null));

      appDispatch(setIdFilter(null));
      appDispatch(setBlockFiltering(false));
      appDispatch(setAllElementProperties(null));
      appDispatch(setPropertySetsAll(null));
      appDispatch(setFilteredElementProperties(null));
      appDispatch(setPropertySetsFiltered(null));
      appDispatch(setForgeViewerSelection(null));
      appDispatch(resetFilters());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDispatch, callback && callback]);
};

export const useForgeRestoreViewAndMarkup = () => {
  const { projectId = '' } = useParams();
  const appDispatch = useAppDispatch();
  const { data: files } = useFilesQuery(projectId);
  const viewDict = getViewDict(files);
  const viewReference = useAppSelector(selectForgeViewerViewReference);
  const forgeViewerReady = useAppSelector(selectForgeViewerReady);
  const restoreViewData = useAppSelector(selectForgeViewerRestoreViewData);
  const currentProject = useProject();
  const extToDb = useAppSelector(selectForgeViewerExternalIdToDbIdMapping);
  const markupActive = useAppSelector(selectForgeViewerMarkupActive);

  useEffect(() => {
    const loadMarkup = async (markupUrl: string | undefined) => {
      const markupExtension: any = NOP_VIEWER?.getExtension(
        'Autodesk.Viewing.MarkupsCore',
      );

      if (!markupUrl) {
        appDispatch(setForgeViewerMarkupActive(false));
        if (markupActive) {
          markupExtension.leaveEditMode();
          markupExtension.hide();
        }
        return;
      }
      const svg = await s3.get(markupUrl);
      if (svg) {
        const svgString = await svg.text();

        markupExtension.show();
        markupExtension.leaveEditMode();
        markupExtension.loadMarkups(svgString, 'Layer1');
        appDispatch(setForgeViewerMarkupActive(true));
      } else {
        toast.error('Markup konnte nicht geladen werden');
        appDispatch(setForgeViewerMarkupActive(false));
        markupExtension.leaveEditMode();
        markupExtension.hide();
      }
    };

    const applyFilterPreset = (preset: ConrepAppFilterPreset) => {
      if (!preset) {
        return;
      }

      if (preset && extToDb) {
        const reduxFormat = parseFilterPresetToStoreFormat(preset, extToDb);
        const allFiltersFound = Object.keys(reduxFormat.customFilter).every(
          (param) => {
            const index = currentProject.filters?.findIndex(
              (f) => f.parameter === param,
            );

            return index !== undefined && index > -1;
          },
        );
        if (!allFiltersFound) {
          toast.error('Es konnten nicht alle Filter wiederhergestellt werden');
        }

        appDispatch(setFilterPreset(reduxFormat));
      }
    };

    const setImageMarkupRestoreData = async (restoreData: any): Promise<void> =>
      new Promise((resolve, reject) => {
        if (!NOP_VIEWER) {
          return;
        }
        if (forgeViewerReady) {
          // deactivate markup
          const markupExtension: any = NOP_VIEWER.getExtension(
            'Autodesk.Viewing.MarkupsCore',
          );
          markupExtension.unloadMarkups('Layer1');

          const setCameraViewMarkUpURLFilterPreset = () => {
            if (NOP_VIEWER) {
              if (restoreData.eye && restoreData.target && restoreData.up) {
                NOP_VIEWER.navigation.setView(
                  restoreData.eye,
                  restoreData.target,
                );
                NOP_VIEWER.navigation.setCameraUpVector(restoreData.up);
              }

              if (restoreData.filterPreset) {
                applyFilterPreset(restoreData.filterPreset);
              }
              loadMarkup(restoreData.markupUrl);
            }
          };

          const listener = (e: any) => {
            if (forgeViewerReady && e.value.finalFrame && NOP_VIEWER) {
              NOP_VIEWER.removeEventListener(
                Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
                listener,
              );

              setCameraViewMarkUpURLFilterPreset();
              resolve();
            }
          };

          NOP_VIEWER.addEventListener(
            Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
            listener,
          );

          setCameraViewMarkUpURLFilterPreset();
        } else {
          reject();
        }
      });

    if (restoreViewData && viewReference && viewDict) {
      if (
        !isSameViewFromSameFile(restoreViewData.viewReference, viewReference)
      ) {
        const view =
          viewDict[
            restoreViewData.viewReference.file +
              '_' +
              restoreViewData.viewReference.viewableId
          ];
        if (!view) {
          appDispatch(setForgeViewerRestoreViewData(null));
          return;
        } else {
          appDispatch(setForgeViewerReady(false));
          appDispatch(setForgeViewerViewReference(view));
        }
      } else {
        setImageMarkupRestoreData(restoreViewData);
      }
    }
  }, [
    restoreViewData,
    viewReference,
    viewDict,
    appDispatch,
    forgeViewerReady,
    extToDb,
    currentProject.filters,
    markupActive,
  ]);
};

export const useForgeSelection = (isSelectionDisabled: boolean) => {
  const forgeViewerReady = useAppSelector(selectForgeViewerReady);
  useEffect(() => {
    if (!forgeViewerReady || !NOP_VIEWER) {
      return;
    }
    NOP_VIEWER.disableSelection(isSelectionDisabled);
  }, [forgeViewerReady, isSelectionDisabled]);
};

export const useRedrawForge = (watch: any, timeout = 300) => {
  useEffect(() => {
    if (typeof NOP_VIEWER !== 'undefined' && NOP_VIEWER !== null) {
      if (NOP_VIEWER.isLoadDone()) {
        setTimeout(() => {
          NOP_VIEWER?.resize();
        }, timeout);
      }
    }
  }, [watch, timeout]);
};

export const useColorizeForge = (
  isViewerReady: boolean,
  externalIdColorMap: HashMap<string>,
  forgeViewerExternalIdToDbIdMapping: ForgeViewerExternalIdToDbIdMapping | null,
) => {
  useEffect(() => {
    if (!isViewerReady || !NOP_VIEWER || !forgeViewerExternalIdToDbIdMapping) {
      return;
    }

    NOP_VIEWER.clearThemingColors(NOP_VIEWER.model);

    if (!isViewerReady || !NOP_VIEWER) {
      return;
    }

    for (const externaId of Object.keys(externalIdColorMap)) {
      const dbId = forgeViewerExternalIdToDbIdMapping[externaId];
      const color = externalIdColorMap[externaId];
      const threeColor = new THREE.Color(color);
      const vector4 = new THREE.Vector4(
        threeColor.r,
        threeColor.g,
        threeColor.b,
        1,
      );
      NOP_VIEWER.setThemingColor(dbId, vector4.normalize());
    }
  }, [isViewerReady, externalIdColorMap, forgeViewerExternalIdToDbIdMapping]);
};

export const useForgeViewerFilter = (externalIds: string[]): void => {
  const elementIds = externalIds.map((id) => getElementIdOfExternalId(id));
  const appDispatch = useAppDispatch();
  const forgeViewerReady = useAppSelector(selectForgeViewerReady);
  const forgeViewerExternalIdToDbIdMapping = useAppSelector(
    selectForgeViewerExternalIdToDbIdMapping,
  );

  useEffect(() => {
    if (!forgeViewerReady || !NOP_VIEWER?.model) {
      return;
    }

    if (!forgeViewerExternalIdToDbIdMapping) {
      NOP_VIEWER.model.getExternalIdMapping(
        (mapping) => {
          const dbIds = elementIds.map((ref) => mapping[ref.elementId]);
          appDispatch(setForgeViewerIsolatedUnsync(dbIds));
        },
        () => undefined,
      );
    } else {
      const dbIds = elementIds.map(
        (ref) => forgeViewerExternalIdToDbIdMapping[ref.elementId],
      );
      appDispatch(setForgeViewerIsolatedUnsync(dbIds));
    }
  }, [
    forgeViewerReady,
    elementIds,
    appDispatch,
    forgeViewerExternalIdToDbIdMapping,
  ]);
};

export const useResizeForge = (deps: DependencyList): void => {
  const forgeViewerReady = useAppSelector(selectForgeViewerReady);
  useEffect(() => {
    if (forgeViewerReady && NOP_VIEWER) {
      NOP_VIEWER.resize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgeViewerReady, ...deps]);
};

export function useForgeLoadView() {
  const dispatch = useAppDispatch();
  const project = useProject();
  const { data: sheetSets } = useSheetSetsQuery(project._id);
  const { data: files } = useFilesQuery(project._id);
  const viewDict = useMemo(() => getViewDict(files), [files]);
  const conrepTechCrew = useAppSelector(selectConrepAppTechCrew);

  useEffect(() => {
    if (!viewDict || !sheetSets) {
      return;
    }

    const defaultView = conrepTechCrew?.defaultView;
    if (defaultView) {
      const view = viewDict[`${defaultView.file}_${defaultView.viewableId}`];
      if (view) {
        dispatch(setForgeViewerViewReference(view));
        return;
      }
    }

    const techCrewSheetSetIds = conrepTechCrew?.sheetSets;
    if (techCrewSheetSetIds) {
      const filteredheetSets = sheetSets.filter((sheetSet) =>
        techCrewSheetSetIds.includes(sheetSet._id),
      );
      const sheetSetViews = getSetOfViewsFromSheetSets(
        filteredheetSets,
        viewDict,
      );
      const view = sheetSetViews[0];
      if (view) {
        dispatch(setForgeViewerViewReference(view));
        return;
      }
    }
    const views = Object.entries(viewDict).map((v) => v[1]);
    let defaultProjectView = null;
    if (project.defaultView) {
      defaultProjectView = views.find((v) =>
        isSameViewFromSameFile(v, project.defaultView),
      );
    }
    if (defaultProjectView) {
      dispatch(setForgeViewerViewReference(defaultProjectView));
      return;
    }

    dispatch(setForgeViewerViewReference(views[0]));
  }, [viewDict, conrepTechCrew, sheetSets, project.defaultView, dispatch]);
}
