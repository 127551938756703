import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAdminCompaniesQuery } from 'src/admin/queries';
import { Button } from 'src/common/components/buttons/Button';
import { Pagination } from 'src/common/components/Pagination';
import { SearchSort } from 'src/common/components/SearchSort';
import { Card } from 'src/common/components/Card';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Spinner } from 'src/common/components/Spinner';

import type { OwnerPopulatedCompany } from 'shared';

type Props = {
  companyId?: string;
  onClickCompany: (c: OwnerPopulatedCompany) => void;
};

export const ProjectCompanyPage = ({ companyId, onClickCompany }: Props) => {
  const { t } = useTranslation(['admin', 'common']);

  const [page, setPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const { data: paginatedCompanies, isPending } = useAdminCompaniesQuery(
    page,
    entriesPerPage,
    searchBy,
    sortBy,
    sortOrder,
  );

  const sortOptions: SelectSitelifeOptionType[] = [
    { label: t('common:labels.name'), value: 'name' },
    { label: t('common:description'), value: 'description' },
  ];

  const companies = paginatedCompanies ? paginatedCompanies.data : [];

  return (
    <div className="flex h-full flex-col justify-between pt-6">
      <div className="space-y-4 px-6">
        <h3 className="text-xl font-semibold">
          {t('admin:projects.modal.selectOrganization')}
        </h3>
        <div className="flex items-center">
          <SearchSort
            searchBy={searchBy}
            sortBy={
              sortOptions.find((sortOption) => sortOption.value === sortBy) ||
              sortOptions[0]
            }
            sortOrder={sortOrder}
            sortOptions={sortOptions}
            onSearchBy={(newSearchBy) => setSearchBy(newSearchBy)}
            onSortBy={(newSortBy) => setSortBy(newSortBy)}
            onSortOrder={(newSortOrder) => setSortOrder(newSortOrder)}
          />
        </div>
      </div>
      <div className="mt-6 h-full overflow-y-auto bg-gray-100 p-6">
        <Card>
          {isPending ? (
            <Spinner containerClassName="w-full h-full my-[160px] text-center" />
          ) : companies.length > 0 ? (
            <table className="w-full divide-y divide-gray-200 shadow">
              <thead>
                <tr>
                  <th scope="col" className="table-th">
                    {t('common:labels.name')}
                  </th>
                  <th scope="col" className="table-th">
                    {t('common:description')}
                  </th>
                  <th scope="col" className="table-th" />
                </tr>
              </thead>
              <tbody className="table-body">
                {companies.map((company) => (
                  <tr key={company._id}>
                    <td className="table-td table-td-truncate min-w-[100px]">
                      <div className="flex items-center">
                        <div className="ml-4 overflow-hidden py-1">
                          <div className="truncate text-base font-semibold">
                            {company.name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="table-td table-td-truncate lg:table-cell">
                      {company.description}
                    </td>
                    <td className="table-td max-w-[60px] text-right lg:table-cell">
                      {companyId === company._id ? (
                        <Button size="sm">
                          <FontAwesomeIcon icon={faCheck} />
                          {t('common:button.selected')}
                        </Button>
                      ) : (
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={() => onClickCompany(company)}
                        >
                          {t('common:button.select')}
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="mx-auto my-[210px] max-w-[500px] text-center text-shuttleGray-600">
              <span className="font-semibold text-shuttleGray-800">
                {`${searchBy}`}
              </span>
              {t('common:notFound.message')}
            </p>
          )}
        </Card>
      </div>
      {companies.length > 0 && !isPending && paginatedCompanies && (
        <Pagination
          maxPage={Math.ceil(paginatedCompanies.totalCount / entriesPerPage)}
          page={page}
          onChangePage={setPage}
          entriesPerPage={entriesPerPage}
          onChangeEntriesPerPage={setEntriesPerPage}
          className="p-6"
        />
      )}
    </div>
  );
};
