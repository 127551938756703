import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';

import { cn } from 'src/common/utils';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { PasswordStrengthInput } from 'src/common/components/inputs/PasswordStrengthInput/PasswordStrengthInput';
import { Button } from 'src/common/components/buttons/Button';
import { Checkbox } from 'src/common/components/inputs/Checkbox';
import { ExternalTextLink } from 'src/common/components/TextLink';
import { EMAIL_REGEX, PASSWORD_REGEX } from 'src/user/constants';
import { createUser, updateUser } from '../../apiUsers';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Modal } from 'src/common/components/Modal';
import { SelectNative } from 'src/common/components/inputs/SelectNative/SelectNative';

import type { CompanyPopulatedUser, UserUpdatePayload } from 'shared';

type FormValues = {
  email: string;
  password: string;
  name: string;
  surname: string;
  position: string;
  level: string;
  accepted: boolean;
};

type Props = {
  user?: CompanyPopulatedUser;
  onClose: () => void;
};

export const UserForm = ({ user, onClose }: Props) => {
  const { t } = useTranslation(['admin', 'common']);

  const [isPasswordConfirmationOpen, setIsPasswordConfirmationOpen] =
    useState(false);

  const {
    register,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      email: user?.email,
      password: '',
      name: user?.name,
      surname: user?.surname,
      position: user?.position,
      level: user?.level,
    },
  });

  const queryClient = useQueryClient();

  const sendUpdateUser = async (userToUpdate: UserUpdatePayload) => {
    try {
      if (!user) {
        throw new Error('User is not defined');
      }
      await updateUser(userToUpdate, user._id);
      toast.success(t('users.modal.updateSuccess'));
      onClose();
      await queryClient.invalidateQueries({ queryKey: ['admin-users'] });
    } catch (exception) {
      toast.error(t('users.modal.updateFailure'));
    }
  };

  const handleSave = async ({
    email,
    password,
    name,
    surname,
    position,
    accepted,
    level,
  }: FormValues) => {
    if (user) {
      if (password && password.length > 0) {
        setIsPasswordConfirmationOpen(true);
      } else {
        await sendUpdateUser({ email, name, surname, position, level });
      }
    } else {
      if (accepted) {
        try {
          await createUser({ email, password, name, surname, position, level });
          toast.success(t('users.modal.createSuccess'));
          onClose();
          await queryClient.invalidateQueries({ queryKey: ['admin-users'] });
        } catch (exception) {
          toast.error(t('users.modal.createFailure'));
        }
      }
    }
  };

  const handleGeneratePassword = () => {
    setValue('password', generatePassword(), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleChangePassword = async () => {
    await sendUpdateUser({
      email: getValues('email'),
      password: getValues('password'),
      name: getValues('name'),
      surname: getValues('surname'),
      position: getValues('position'),
      level: getValues('level'),
    });
    setIsPasswordConfirmationOpen(false);
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(handleSave)}>
      <TextInput
        {...register('email', {
          required: true,
          pattern: EMAIL_REGEX,
        })}
        label={t('common:email')}
        placeholder={t('placeholder', {
          title: t('common:email'),
        })}
        type="email"
        autoFocus
        required
        error={
          errors.email &&
          t('error', {
            title: t('common:email'),
          })
        }
      />
      <div className="flex items-center gap-4">
        <PasswordStrengthInput
          className="mr-10 w-full"
          {...register('password', {
            required: !user,
            pattern: PASSWORD_REGEX,
          })}
          label={user ? t('common:newPassword') : t('common:password')}
          description={t('users.modal.passwordDescription')}
          placeholder={t('placeholder', {
            title: t('common:password'),
          })}
          value={watch('password')}
          required={!user}
          error={
            errors.password &&
            t('error', {
              title: t('common:password'),
            })
          }
        />
        <Button
          variant="secondary"
          className={cn('mb-1 mt-auto', {
            'mb-[26px]': errors.password,
          })}
          onClick={handleGeneratePassword}
          disabled={!!watch('password')}
        >
          {t('common:generate')}
        </Button>
      </div>
      <hr className="mb-2 mt-4" />
      <SelectNative
        {...register('level')}
        label={t('common:level')}
        placeholder={t('placeholder', {
          title: t('common:level'),
        })}
        required={!user}
      >
        <option value="ADMIN">{t('common:admin')}</option>
        <option value="USER">{t('common:user')}</option>
      </SelectNative>
      <TextInput
        {...register('name')}
        label={t('common:name')}
        placeholder={t('placeholder', {
          title: t('common:name'),
        })}
        type="text"
        required={!user}
      />
      <TextInput
        {...register('surname')}
        label={t('common:surname')}
        placeholder={t('placeholder', {
          title: t('common:surname'),
        })}
        type="text"
      />
      <TextInput
        {...register('position')}
        label={t('common:position')}
        placeholder={t('placeholder', {
          title: t('common:position'),
        })}
        type="text"
      />
      {!user && (
        <>
          <hr className="mb-2 mt-4" />
          <Checkbox
            {...register('accepted')}
            label={
              <Trans
                ns="admin"
                i18nKey="users.modal.accepted"
                components={[
                  <ExternalTextLink
                    href="https://bim-management.com/sitelife/"
                    key="1"
                  >
                    &nbsp;
                  </ExternalTextLink>,
                  <ExternalTextLink
                    href="https://bim-management.com/datenschutzerklaerung/"
                    key="2"
                  >
                    &nbsp;
                  </ExternalTextLink>,
                ]}
              />
            }
            required
          />
        </>
      )}

      <div className="ml-auto mt-2 flex gap-2">
        <Button variant="tertiary" onClick={onClose}>
          {t('common:button.abort')}
        </Button>
        <Button type="submit" disabled={!isDirty || !isValid}>
          {user ? t('common:button.save') : t('common:button.create')}
        </Button>
      </div>
      <Modal
        isOpen={isPasswordConfirmationOpen}
        onRequestClose={() => setIsPasswordConfirmationOpen(false)}
      >
        <ConfirmationDialog
          title={t('users.modal.changePasswordTitle')}
          message={t('users.modal.changePasswordMessage')}
          onCancel={() => setIsPasswordConfirmationOpen(false)}
          onConfirm={handleChangePassword}
          customCancelText={t('common:button.notChange')}
          customConfirmText={t('common:button.change')}
        />
      </Modal>
    </form>
  );
};

const upperCases = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export function generatePassword(): string {
  const upperCaseIndex = Math.floor(Math.random() * upperCases.length);
  const partA = Math.random().toString(36).slice(-8);
  const partB = Math.random().toString(36).slice(-8);
  return partA + upperCases[upperCaseIndex] + partB;
}
