import { createContext, useContext } from 'react';

import { GetProjectResponse } from './types/projects';

const projectContext = createContext<GetProjectResponse | null>(null);

export const useProject = () => {
  const project = useContext(projectContext);
  if (!project) {
    throw new Error('useProject must be used within a ProjectProvider');
  }
  return project;
};

export const ProjectProvider = ({
  children,
  project,
}: {
  children: React.ReactNode;
  project: GetProjectResponse;
}) => {
  return (
    <projectContext.Provider value={project}>
      {children}
    </projectContext.Provider>
  );
};
