import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';

import { Button } from '../buttons/Button';
import { UpgradeModal } from '../UpgradeModal';
import { Card } from '../Card';
import { upgradeProjectLicense } from 'src/project/api/projectsApi';
import LicenseBasicIllu from 'src/assets/projectLicense/illu_basic_license.svg';
import { GetProjectResponse } from 'src/project/types/projects';
import { Spinner } from '../Spinner';
import { FeatureList } from '../FeatureList';

export const TeaserPage = ({
  project,
  description,
  videoUrl,
  cardTitle,
  cardText,
  cardShadow = true,
}: {
  project: GetProjectResponse;
  description: string;
  videoUrl?: string;
  cardTitle?: string;
  cardText?: string;
  cardShadow?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpgradeConfirmationOpen, setIsUpgradeConfirmationOpen] =
    useState(false);
  const [isUpgrading, setIsUpgrading] = useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const onClickUpgrade = () => setIsUpgradeConfirmationOpen(true);

  return isLoading || isUpgrading ? (
    <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-white">
      <Spinner className="text-8xl" />
    </div>
  ) : (
    <>
      {cardShadow ? (
        <Card className="mx-auto w-full min-w-[768px]">
          <TeaserContent
            description={description}
            videoUrl={videoUrl}
            cardTitle={cardTitle}
            cardText={cardText}
            onClickUpgrade={onClickUpgrade}
          />
        </Card>
      ) : (
        <div className="mx-auto w-full min-w-[768px]">
          <TeaserContent
            description={description}
            videoUrl={videoUrl}
            cardTitle={cardTitle}
            cardText={cardText}
            onClickUpgrade={onClickUpgrade}
          />
        </div>
      )}
      <UpgradeModal
        isOpen={isUpgradeConfirmationOpen}
        onClose={() => setIsUpgradeConfirmationOpen(false)}
        upgradeButton={async () => {
          setIsUpgradeConfirmationOpen(false);
          setIsUpgrading(true);
          await upgradeProjectLicense(project._id);
          setIsUpgrading(false);
          await queryClient.invalidateQueries();
        }}
      />
    </>
  );
};

const TeaserContent = ({
  description,
  videoUrl,
  cardTitle,
  cardText,
  onClickUpgrade,
}: {
  description: string;
  videoUrl?: string;
  cardTitle?: string;
  cardText?: string;
  onClickUpgrade: () => void;
}) => {
  const { t } = useTranslation('project');

  const features = t('license.basicFunctionalities', {
    returnObjects: true,
  }).map((v) => ({
    label: v,
    enabled: true,
  }));

  const showInformationCard = videoUrl && cardTitle && cardText;

  return (
    <div className="mx-auto my-6 max-w-[680px] text-center">
      <img
        className="mx-auto mb-8"
        src={LicenseBasicIllu}
        alt="Basic illustation"
      />
      <h1 className="my-4 text-4xl font-semibold">
        {t('license.teaserPageTitle')}
      </h1>
      <div className="mb-6 text-shuttleGray-600">{description}</div>
      <Button onClick={onClickUpgrade}>{t('license.teaserPageButton')}</Button>
      {showInformationCard && (
        <InformationCard
          url={videoUrl}
          cardTitle={cardTitle}
          cardText={cardText}
        />
      )}
      <h1 className="mt-8 text-left text-lg font-semibold">
        {t('license.teaserPageAdvantages')}
      </h1>
      <FeatureList features={features} />
    </div>
  );
};

const InformationCard = ({
  url,
  cardTitle,
  cardText,
}: {
  url: string;
  cardTitle: string;
  cardText: string;
}) => {
  return (
    <div className="mt-4 flex space-x-4 bg-shuttleGray-50 p-4 text-shuttleGray-600">
      <iframe
        title="Sitelife"
        src={url}
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="h-24 w-40"
      />
      <div className="space-y-2 text-left">
        <div className="inline-flex">
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="mr-2 mt-1"
            fixedWidth
          />
          <p className="text-left">{cardTitle}</p>
        </div>
        <p className="text-start"> {cardText}</p>
      </div>
    </div>
  );
};
