import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  faPen,
  faImage,
  faImageSlash,
} from '@fortawesome/pro-regular-svg-icons';

import { cn } from 'src/common/utils';
import { Button } from 'src/common/components/buttons/Button';
import { Spinner } from 'src/common/components/Spinner';

export function ImageInput({
  src,
  onDelete,
  onChange,
  isLoading,
  isUploading,
  isDeleting,
  variant = 'square',
  buttonVariant = 'primary',
  buttonSize = 'md',
}: {
  src?: string;
  onDelete?: () => void;
  onChange: (file: File) => void;
  isLoading?: boolean;
  isUploading?: boolean;
  isDeleting?: boolean;
  variant?: 'rounded' | 'square';
  buttonVariant?: 'primary' | 'secondary';
  buttonSize?: 'sm' | 'md';
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('common');

  function handleFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.currentTarget.files?.[0];
    if (!file) {
      return;
    }
    onChange(file);
    e.currentTarget.value = '';
  }

  return (
    <div className="flex gap-2">
      {isLoading ? (
        <div
          className={cn('grid h-[200px] place-items-center bg-gray-200', {
            'rounded-full': variant === 'rounded',
            'aspect-4/3 rounded': variant === 'square',
          })}
        >
          <Spinner />
        </div>
      ) : src ? (
        <img
          src={src}
          alt=""
          className={cn('h-[200px] object-cover', {
            'rounded-full': variant === 'rounded',
            'aspect-4/3 rounded': variant === 'square',
          })}
        />
      ) : null}
      <div className="flex flex-col space-y-2">
        {src ? (
          <Button
            onClick={() => inputRef.current?.click()}
            loading={isUploading}
            variant="tertiary"
            className="h-10 w-10"
            size={buttonSize}
          >
            <FontAwesomeIcon icon={faPen} fixedWidth />
          </Button>
        ) : (
          <Button
            onClick={() => inputRef.current?.click()}
            loading={isUploading}
            variant={buttonVariant}
            size={buttonSize}
          >
            <FontAwesomeIcon icon={faImage} fixedWidth />
            {t('image.editor.selectImage')}
          </Button>
        )}
        <input
          type="file"
          name="image"
          ref={inputRef}
          accept="image/*"
          onChange={handleFileSelected}
          className="hidden"
        />
        {src && onDelete && (
          <button
            className="h-10 w-10 cursor-pointer rounded-sm text-red-300 transition-colors hover:bg-red-50 disabled:cursor-default disabled:hover:bg-transparent"
            onClick={onDelete}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <Spinner />
            ) : (
              <FontAwesomeIcon icon={faImageSlash} fixedWidth />
            )}
          </button>
        )}
      </div>
    </div>
  );
}
