import { faEdit, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';

import { cn } from 'src/common/utils';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';

type Props = {
  title: string;
  subtitle?: string;
  index: number;
  isLastItem: boolean;
  canRemoveItem: boolean;
  onEdit?: (item: { title: string; subtitle?: string }) => void;
  onRemove?: (item: string) => void;
};

export const DraggableParameterItem = ({
  title,
  subtitle,
  index,
  isLastItem,
  canRemoveItem,
  onEdit,
  onRemove,
}: Props) => {
  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  ) => ({
    userSelect: 'none',
    borderRadius: '0.25rem',
    // change background colour if dragging
    background: isDragging ? '#F0F0F0' : 'white',
    boxShadow: isDragging
      ? '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'
      : '',
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <Draggable key={`${title}_displayed`} draggableId={title} index={index}>
      {(providedInner, snapshotInner) => (
        <div
          ref={providedInner.innerRef}
          {...providedInner.draggableProps}
          style={getItemStyle(
            snapshotInner.isDragging,
            providedInner.draggableProps.style,
          )}
        >
          <div
            className={cn('flex items-center py-2 pl-1 pr-2', {
              'border-b': !isLastItem,
            })}
          >
            <i
              className="mx-4 text-gray-600 hover:text-shuttleGray-800"
              {...providedInner.dragHandleProps}
            >
              <FontAwesomeIcon icon={faGripVertical} />
            </i>
            <div className="mr-auto flex flex-col overflow-auto">
              <div className="truncate text-sm text-shuttleGray-800">
                {title}
              </div>
              {subtitle && (
                <div className="truncate text-xs text-shuttleGray-600">
                  {subtitle}
                </div>
              )}
            </div>
            {onEdit && (
              <ActionIcon
                className="shrink-0"
                onClick={() => onEdit({ title, subtitle })}
              >
                <FontAwesomeIcon icon={faEdit} />
              </ActionIcon>
            )}
            {onRemove && (
              <ActionIcon
                className="shrink-0"
                disabled={!canRemoveItem}
                onClick={() => onRemove(title)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </ActionIcon>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};
