import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';

import { removeUserFromProject } from 'src/admin/apiProjects';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Modal } from 'src/common/components/Modal';
import { GetAdminProjectResponse } from 'src/project/types/projects';

type Props = {
  project: GetAdminProjectResponse;
  userId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ProjectRemoveUserModal = ({
  project,
  userId,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation(['admin', 'common']);
  const queryClient = useQueryClient();

  async function handleRemoveUserFromCompany() {
    try {
      await removeUserFromProject(project._id, userId);
      await queryClient.invalidateQueries({ queryKey: ['admin-users'] });
      toast.success(t('admin:removeUserSuccess'));
      onClose();
    } catch (e) {
      toast.error(t('admin:removeUserFailure'));
    }
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ConfirmationDialog
        title={t('admin:projects.modal.information.removeUserTitle')}
        message={
          <Trans
            ns="admin"
            i18nKey="projects.modal.information.removeUserMessage"
            values={{ projectName: project.name }}
            components={[
              <span className="font-extrabold text-shuttleGray-800" key="1" />,
            ]}
          />
        }
        onCancel={onClose}
        onConfirm={handleRemoveUserFromCompany}
        customConfirmText={t('common:button.delete')}
      />
    </Modal>
  );
};
