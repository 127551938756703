import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { ActionMessage } from 'src/common/components/ActionMessage';

export const LicenseExpiredMessage = () => {
  const { t } = useTranslation('project');

  return (
    <ActionMessage variant="warning">
      <span className="mr-3 font-semibold">
        {t('overview.licenseExpiredBanner.licenseExpired')}
      </span>
      <span>{t('overview.licenseExpiredBanner.noChangesPossible')}</span>
      <a
        href="mailto:support@sitelife.com"
        className="ml-auto mr-2 flex cursor-pointer items-center gap-2 hover:underline"
      >
        <FontAwesomeIcon icon={faEnvelope} />
        <span>{t('overview.licenseExpiredBanner.contact')}</span>
      </a>
    </ActionMessage>
  );
};
