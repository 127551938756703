import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';
import { CustomParameter } from '../../types/projects';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { useProject } from 'src/project/project-context';
import { updateProject } from 'src/project/api/projectsApi';
import toast from 'src/common/toast';

interface Props {
  parameters: CustomParameter[];
  selectedParameter: CustomParameter | null | undefined;

  onSave: () => void;
  onClose: () => void;
  isOpen: boolean;
}

export const CustomParameterNameModal = ({
  parameters,
  selectedParameter,
  onSave,
  onClose,
  isOpen,
}: Props) => {
  const { t } = useTranslation(['project', 'common']);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm<{
    name: string | number | boolean;
  }>();

  const [nameInUse, setNameInUse] = useState(false);
  const currentProject = useProject();

  const queryClient = useQueryClient();

  const emptyParameterName = watch('name') === '';

  const handleEdit = async () => {
    if (currentProject._id) {
      try {
        const newSelectedParameter: CustomParameter = {
          ...selectedParameter,
          name: watch('name')?.toString() || selectedParameter?.name || '',
          type: selectedParameter?.type || 'string',
        };

        const tempParameters = parameters.map((param) =>
          param.name === selectedParameter?.name ? newSelectedParameter : param,
        );

        await updateProject(currentProject._id, {
          customParameters: tempParameters,
        });

        queryClient.invalidateQueries({
          queryKey: ['projects', currentProject._id],
        });
        setNameInUse(false);
        onSave();
        toast.success(
          t('settings.modelParameter.customParameters.saveSuccess'),
        );
      } catch (err) {
        toast.error(t('settings.modelParameter.customParameters.saveFailure'));
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="w-[680px]">
      <Modal.Header onClose={onClose}>
        {t(
          'project:settings.modelParameter.customParameters.updateCustomParametersName',
        )}
      </Modal.Header>
      <Modal.Body>
        <form
          className="flex flex-col gap-6"
          onSubmit={handleSubmit(handleEdit)}
        >
          <TextInput
            {...register('name')}
            placeholder={t('settings.modelParameter.parameter')}
            value={watch('name')?.toString() || ''}
            error={nameInUse ? t('settings.actions.form.nameInUse') : false}
            onChange={(e) => {
              if (
                parameters.find((element) => element.name === e.target.value)
              ) {
                setNameInUse(true);
              } else {
                setNameInUse(false);
              }
              setValue('name', e.target.value, { shouldDirty: true });
            }}
          />
          <div className="ml-auto flex gap-2">
            <Button variant="tertiary" onClick={onClose}>
              {t('common:button.abort')}
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={nameInUse || !isDirty || emptyParameterName}
            >
              {t('common:button.save')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
