import { faPaste, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import deleteIllu from 'src/assets/illu_delete.svg';
import { useUser } from 'src/auth/user-context';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';
import { CurrencyInput } from 'src/common/components/inputs/CurrencyInput/CurrencyInput';
import { TextAreaInput } from 'src/common/components/inputs/TextAreaInput/TextAreaInput';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { useClipboard } from 'src/common/hooks/useClipboard';
import toast from 'src/common/toast';
import { logger } from 'src/logger';
import { deleteProject, updateProject } from '../api/projectsApi';
import { useProject } from '../project-context';
import { GetProjectResponse, LicenseEvaluationType } from '../types/projects';
import { validateLicense } from '../utils';

type InformationFormState = {
  name: string;
  description: string;
  projectVolume?: string;
  street?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
};

export const InformationForm = () => {
  const { t } = useTranslation(['project', 'common']);
  const project = useProject();
  const defaultValues = {
    name: project.name,
    description: project.description,
    ...project.projectInformation,
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<InformationFormState>({
    defaultValues,
  });
  const clipboard = useClipboard({
    onSuccess: (text) => {
      try {
        const split = text.split(',');
        if (split.length < 2) {
          throw new Error('Wrong Format');
        }
        const lat = parseFloat(split[0]);
        const long = parseFloat(split[1]);
        if (!lat || !long) {
          throw new Error('Wrong Format');
        }
        setValue('latitude', lat, { shouldDirty: true });
        setValue('longitude', long, { shouldDirty: true });
        toast.success(t('settings.information.coordinates.pasteSuccess'));
      } catch (err) {
        toast.error(t('settings.information.coordinates.pasteFailure'));
      }
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const handleSave = async (data: InformationFormState) => {
    setIsLoading(true);
    try {
      const { name, description, ...conrepProjectInformation } = data;
      await updateProject(project._id, {
        name,
        description,
        projectInformation: conrepProjectInformation,
      });
      await queryClient.invalidateQueries({
        queryKey: ['projects', project._id],
      });
      reset(data);
      toast.success(t('settings.information.success'));
    } catch (err) {
      logger.capture(err);
      toast.error(t('settings.information.failure'));
    } finally {
      setIsLoading(false);
    }
  };

  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;

  return (
    <form onSubmit={handleSubmit(handleSave)} className="relative space-y-4">
      <section className="relative bg-white p-4 shadow">
        <h2 className="mb-4 text-lg font-semibold">
          {t('settings.information.general.title')}
        </h2>
        <TextInput
          label={t('settings.information.general.name')}
          placeholder={t('settings.information.general.namePlaceholder')}
          required
          className="mb-4 w-full"
          disabled={isLicenseExpired}
          {...register('name')}
        />
        <TextAreaInput
          label={t('settings.information.general.description')}
          placeholder={t('settings.information.general.descriptionPlaceholder')}
          rows={4}
          className="w-full"
          disabled={isLicenseExpired}
          {...register('description')}
        />
        <CurrencyInput
          label={t('settings.information.general.volume')}
          className="mb-4 w-full"
          disabled={isLicenseExpired}
          {...register('projectVolume')}
        />
      </section>

      <section className="relative bg-white p-4 shadow">
        <h2 className="mb-4 text-lg font-semibold">
          {t('settings.information.location.title')}
        </h2>
        <TextInput
          label={t('settings.information.location.street')}
          placeholder={t('settings.information.location.streetPlaceholder')}
          className="mb-4 w-full"
          disabled={isLicenseExpired}
          {...register('street')}
        />

        <div className="mb-4 flex gap-4">
          <TextInput
            label={t('settings.information.location.zipCode')}
            placeholder={t('settings.information.location.zipCodePlaceholder')}
            className="w-40"
            type="number"
            disabled={isLicenseExpired}
            {...register('zipCode')}
            maxLength={10}
          />
          <TextInput
            label={t('settings.information.location.city')}
            placeholder={t('settings.information.location.cityPlaceholder')}
            className="w-full"
            disabled={isLicenseExpired}
            {...register('city')}
          />
        </div>

        <TextInput
          label={t('settings.information.location.country')}
          placeholder={t('settings.information.location.countryPlaceholder')}
          defaultValue=""
          className="mb-4 w-full"
          disabled={isLicenseExpired}
          {...register('country')}
        />
        {clipboard.isAllowed ? (
          <>
            <Button
              variant="secondary"
              className="mb-4"
              onClick={clipboard.read}
              disabled={isLicenseExpired}
            >
              <FontAwesomeIcon icon={faPaste} />
              {t('settings.information.coordinates.paste')}
            </Button>
            <InlineMessage className="mb-4 w-full">
              {t('settings.information.coordinates.googleMaps')}
            </InlineMessage>
          </>
        ) : (
          <InlineMessage className="mb-4 w-full" variant="warning">
            {t('settings.information.coordinates.clipboardPasteBlocked')}
          </InlineMessage>
        )}
        <TextInput
          label={t('settings.information.coordinates.latitude')}
          placeholder={t(
            'settings.information.coordinates.latitudePlaceholder',
          )}
          type="number"
          min="-90"
          max="90"
          step="0.00000000000000001"
          className="input-number-hide-arrows mb-4 w-full"
          disabled={isLicenseExpired}
          {...register('latitude')}
        />
        <TextInput
          label={t('settings.information.coordinates.longitude')}
          placeholder={t(
            'settings.information.coordinates.longitudePlaceholder',
          )}
          type="number"
          min="-180"
          max="180"
          step="0.00000000000000001"
          className="input-number-hide-arrows mb-4 w-full"
          disabled={isLicenseExpired}
          {...register('longitude')}
        />
      </section>

      <footer className="mt-2 flex gap-2">
        <DeleteProjectButton />
        <div className="ml-auto flex gap-2">
          <Button
            className="ml-auto"
            type="submit"
            disabled={!isDirty || isLoading}
            loading={isLoading}
          >
            {t('common:button.save')}
          </Button>
        </div>
      </footer>
    </form>
  );
};

const DeleteProjectButton = () => {
  const user = useUser();
  const project = useProject();
  const { t } = useTranslation('project');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => setShowDeleteModal(true)}
        variant="red"
        disabled={project.owner !== user._id}
      >
        <FontAwesomeIcon icon={faTrash} />
        {t('settings.delete.buttonText')}
      </Button>
      <DeleteProjectModal
        isOpen={showDeleteModal}
        project={project}
        key={showDeleteModal.toString()}
        onClose={() => setShowDeleteModal(false)}
      />
    </>
  );
};

const DeleteProjectModal = ({
  isOpen,
  project,
  onClose,
}: {
  isOpen: boolean;
  project: GetProjectResponse;
  onClose: () => void;
}) => {
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<{ projectName: string }>({
    mode: 'onChange',
  });

  const handleDelete = async () => {
    try {
      await deleteProject(project._id);
      queryClient.invalidateQueries({
        queryKey: ['project'],
      });
      navigate('/');
      toast.success(t('project:settings.delete.success'));
    } catch (exception) {
      toast.error(t('project:settings.delete.failure'));
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="w-[680px]">
      <Modal.Header onClose={onClose}>
        {t('project:settings.delete.title')}
      </Modal.Header>
      <Modal.Body>
        {' '}
        <form
          className="mt-8 flex flex-col items-center gap-12"
          onSubmit={handleSubmit(handleDelete)}
        >
          <div className="flex gap-9">
            <div>
              <img src={deleteIllu} alt="" />
            </div>
            <div className="flex w-full flex-col gap-4">
              <p className="text-shuttleGray-600">
                <Trans
                  ns="project"
                  i18nKey="settings.delete.text"
                  values={{ projectName: project.name }}
                  components={[
                    <span
                      className="font-extrabold text-shuttleGray-800"
                      key="1"
                    />,
                  ]}
                />
              </p>
              <TextInput
                label={t('project:settings.delete.label')}
                required
                error={errors['projectName']?.message}
                {...register('projectName', {
                  validate: (match) => {
                    return (
                      match === project.name ||
                      t('project:settings.delete.error').toString()
                    );
                  },
                })}
              />
            </div>
          </div>
          <div className="ml-auto flex gap-2">
            <Button variant="tertiary" onClick={onClose}>
              {t('common:button.abort')}
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!isDirty || Object.keys(errors).length > 0}
            >
              {t('project:settings.delete.submit')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
