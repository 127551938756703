import { useAdminUserProjectsQuery } from 'src/admin/queries';
import { UserProjectsGroup } from './UserProjectsGroup';
import { groupProjectsByCompany } from './utils';
import { Spinner } from 'src/common/components/Spinner';
import { UserPersonalProjectsGroup } from './UserPersonalProjectsGroup';

import type { CompanyPopulatedUser } from 'shared';

type Props = {
  user: CompanyPopulatedUser;
};

export const UserProjects = ({ user }: Props) => {
  const { data: userProjects, isLoading: isLoadingProjects } =
    useAdminUserProjectsQuery(user._id);

  const { personalProjects, groupedProjects } = groupProjectsByCompany(
    user,
    userProjects,
  );

  const isLoading = isLoadingProjects;

  return (
    <div className="-mx-6 -mb-6 flex flex-col gap-8 overflow-y-auto rounded-b bg-gray-100 p-6">
      {isLoading ? (
        <Spinner containerClassName="w-full my-[160px] text-center" />
      ) : (
        <>
          <UserPersonalProjectsGroup
            key={user.personalCompany._id}
            personalProjects={personalProjects}
            user={user}
          />
          {groupedProjects.length > 0 &&
            groupedProjects.map((group) => (
              <UserProjectsGroup
                key={group[0]._id}
                projectGroup={group}
                user={user}
              />
            ))}
        </>
      )}
    </div>
  );
};
