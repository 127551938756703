import React, { Suspense } from 'react';
import Div100vh from 'react-div-100vh';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { AdminView } from 'src/admin/views/AdminView';
import { ForgotPasswordView } from 'src/auth/views/ForgotPasswordView';
import { LoginView } from 'src/auth/views/LoginView';
import { ResetPasswordView } from 'src/auth/views/ResetPasswordView';
import { SignUpCompleteView } from 'src/auth/views/SignUpCompleteView';
import { SignUpView } from 'src/auth/views/SignUpView';
import { Loading } from 'src/common/components/Loading';
import { Toaster } from 'src/common/toast';
import { CompanyView } from 'src/company/views/CompanyView';
import { ProjectListView } from 'src/project/views/ProjectListView';
import { ProjectView } from 'src/project/views/ProjectView';
import { User } from 'src/user/types';
import { InvitationsView } from 'src/user/views/InvitationView';
import { UserProfileView } from 'src/user/views/UserProfileView';
import { ConsentProvider, GDPRCookieConsent } from './components/CookieConsent';
import { NotFoundInApp } from './components/NotFoundInApp';
import { UserProvider } from 'src/auth/user-context';
import { refreshLogin } from 'src/auth/api';
import { useSentryUser } from 'src/error-tracking';
import { useAmplitudeUser } from 'src/analytics';

export const App = () => {
  const { data: user, isFetched } = useAuthQuery();

  useSentryUser(user?._id, user?.email);
  useAmplitudeUser(user?._id);

  if (!isFetched) {
    return null;
  }

  const userLevel = user?.level;

  return (
    <HelmetProvider>
      <ConsentProvider>
        <Helmet>
          <title>Sitelife</title>
        </Helmet>
        <Div100vh className="isolate">
          <BrowserRouter>
            <Routes>
              <Route element={<ProtectedRoute user={user} />}>
                <Route path="/" element={<ProjectListView />} />
                <Route path="company/:companyId/*" element={<CompanyView />} />
                <Route path="profile" element={<UserProfileView />} />
                <Route path="invitations" element={<InvitationsView />} />
                <Route path="p/:projectId/*" element={<ProjectView />} />
                <Route path="*" element={<NotFoundInApp />} />
              </Route>

              <Route element={<UnauthenticatedRoute user={user} />}>
                <Route path="/login" element={<LoginView />} />
                <Route
                  path="forgot-password"
                  element={<ForgotPasswordView />}
                />
                <Route path="reset-password" element={<ResetPasswordView />} />
                <Route path="sign-up" element={<SignUpView />} />
                <Route path="signup-url" element={<SignUpCompleteView />} />
              </Route>

              {userLevel === 'ADMIN' && (
                <Route
                  path="admin/*"
                  element={
                    <ProtectedRoute user={user}>
                      <Suspense fallback={<Loading />}>
                        <AdminView />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
              )}
            </Routes>
            <GDPRCookieConsent />
          </BrowserRouter>
        </Div100vh>
        <Toaster />
      </ConsentProvider>
    </HelmetProvider>
  );
};

function useAuthQuery() {
  return useQuery({
    queryKey: ['user'],
    queryFn: refreshLogin,
    meta: {
      isAuthRefresh: true,
    },
  });
}

const UnauthenticatedRoute = ({
  user,
  children,
}: {
  user?: User;
  children?: React.ReactNode;
}) => {
  const { search } = useLocation();
  if (!user) {
    return children ? <>{children}</> : <Outlet />;
  }
  return <Navigate to={`/${search}`} />;
};

const ProtectedRoute = ({
  user,
  children,
}: {
  user?: User;
  children?: React.ReactNode;
}) => {
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const targetUrl = pathname + search;

  const redirect = searchParams.get('redirect');
  if (redirect) {
    return <Navigate to={redirect} />;
  }

  if (user) {
    return (
      <UserProvider user={user}>
        {children ? children : <Outlet />}
      </UserProvider>
    );
  }

  const loginUrl =
    pathname !== '/'
      ? `/login?redirect=${encodeURIComponent(targetUrl)}`
      : `/login${search}`;

  return <Navigate to={loginUrl} />;
};
