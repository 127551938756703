import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { GetAdminProjectResponse } from 'src/project/types/projects';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { deleteProject } from 'src/admin/apiProjects';
import { ProjectEditModal } from './ProjectEditModal';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Modal } from 'src/common/components/Modal';
import { Spinner } from 'src/common/components/Spinner';

type Props = {
  project: GetAdminProjectResponse;
};

export const ProjectRow = ({ project }: Props) => {
  const { t } = useTranslation(['admin', 'common']);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleConfirmDeleteProject = async () => {
    try {
      setIsLoading(true);
      await deleteProject(project._id);
      await queryClient.invalidateQueries({
        queryKey: ['admin-projects'],
      });
      toast.success(t('admin:projects.delete.success'));
    } catch (exception) {
      toast.error(t('admin:projects.delete.failure'));
    } finally {
      setIsLoading(false);
    }
    setIsDeleteConfirmationOpen(false);
  };

  return (
    <tr>
      <td className="table-td table-td-truncate min-w-[200px]">
        <div className="flex items-center">
          <div className="ml-4 overflow-hidden py-1">
            <div className="truncate text-base font-semibold">
              {project.name}
            </div>
          </div>
        </div>
      </td>
      <td className="table-td table-td-truncate lg:table-cell">
        {project.description}
      </td>
      <td className="table-td table-td-truncate lg:table-cell">
        {project.company && project.company.name}
      </td>
      <td className="table-td table-td-truncate lg:table-cell">
        {project.owner && project.owner.name}
      </td>
      <td className="table-td flex">
        <ActionIcon
          className="ml-auto"
          onClick={() => setIsEditModalOpen(true)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </ActionIcon>
        <ActionIcon onClick={() => setIsDeleteConfirmationOpen(true)}>
          <FontAwesomeIcon icon={faTrash} />
        </ActionIcon>
      </td>
      <ProjectEditModal
        project={project}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
      />
      <Modal
        isOpen={isDeleteConfirmationOpen}
        onRequestClose={() => setIsDeleteConfirmationOpen(false)}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <ConfirmationDialog
            title={t('admin:projects.delete.title')}
            message={t('admin:projects.delete.message')}
            onCancel={() => setIsDeleteConfirmationOpen(false)}
            onConfirm={handleConfirmDeleteProject}
            customConfirmText={t('common:button.delete')}
          />
        )}
      </Modal>
    </tr>
  );
};
