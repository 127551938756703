import * as Shared from 'shared';

import { DownloadRef, PaginatedResponse, PatchRef } from 'src/common/types';
import { User } from 'src/user/types';
import {
  CreateCustomServiceSpecificationRequest,
  CreateServiceSpecificationRequest,
  CreateServiceSpecificationResponse,
  GetProjectResponse,
  NewServiceSpecification,
  OpenWorkDaysResponse,
  Project,
  ProjectInvitation,
  ProjectUser,
  ProjectWithDownloadRef,
  UpdateProjectRequest,
  UpdateServiceSpecificationRequest,
} from '../types/projects';
import { api, s3 } from 'src/common/fetch';
import { TechCrewPermission } from '../types/techCrews';
import { getLanguageCode } from 'src/Conrep/Export/v2/utils';

export async function createProject(projectData: Partial<Project>) {
  const lang = getLanguageCode();
  return api.post<ProjectWithDownloadRef>(
    `/api/projects?lang=${lang}`,
    projectData,
  );
}

export async function getProject(id: string) {
  return api.get<GetProjectResponse>(
    `/api/projects/${id}?populateCompany=true`,
  );
}

export async function getAllProjects() {
  return api.get<ProjectWithDownloadRef[]>(
    `/api/projects?populateCompany=true`,
  );
}

export async function updateProject(
  id: string,
  projectData: UpdateProjectRequest,
) {
  return api.post<GetProjectResponse>(`/api/projects/${id}`, projectData);
}

export async function deleteProject(id: string) {
  return api.delete(`/api/projects/${id}`);
}

export async function getUsers(id: string) {
  return api.get<ProjectUser[]>(`/api/projects/${id}/users`);
}

export async function deleteUser(projectId: string, userId: string) {
  return api.delete(`/api/projects/${projectId}/users`, { userId });
}

export async function getEligibleProjectUsers(
  projectId: string,
  page: number,
  entriesPerPage: number,
  searchBy?: string,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
) {
  const params = {
    page: page.toString(),
    entriesPerPage: entriesPerPage.toString(),
    ...(searchBy && { searchBy }),
    ...(sortBy && { sortBy }),
    ...(sortOrder && { sortOrder: sortOrder?.toString() }),
  };
  const searchParams = new URLSearchParams(params);

  return api.get<PaginatedResponse<Shared.User>>(
    `/api/projects/${projectId}/eligibleusers?${searchParams.toString()}`,
  );
}

export async function getPendingInvitations(id: string) {
  return api.get<ProjectInvitation[]>(`/api/projects/${id}/invitations`);
}

export async function inviteGuest(
  projectId: string,
  email: string,
  assignedTechCrewId?: string,
  techCrewPermissions?: TechCrewPermission[],
  lang = 'de',
) {
  return api.post<User[]>(
    `/api/projects/${projectId}/invitations?lang=${lang}`,
    { email, assignedTechCrewId, techCrewPermissions },
  );
}

export async function deleteInvitation(
  projectId: string,
  invitationId: string,
) {
  return api.delete<User[]>(
    `/api/projects/${projectId}/invitations/${invitationId}`,
  );
}

export async function getInvitationsForUser() {
  return api.get<ProjectInvitation[]>(`/api/project-invitations`);
}

export async function acceptInvitation(invitationId: string) {
  return api.post(`/api/project-invitations/${invitationId}/accept`);
}

export async function declineInvitation(invitationId: string) {
  return api.post(`/api/project-invitations/${invitationId}/decline`);
}

export async function getProjectImage(projectId: string) {
  return api.get<DownloadRef>(`/api/projects/${projectId}/image`);
}

export async function setProjectImage(id: string, extension: string) {
  return api.post<PatchRef>(`/api/projects/${id}/image`, { extension });
}

export async function deleteProjectImage(projectId: string) {
  return api.delete(`/api/projects/${projectId}/image`);
}

export async function getOpenWorkDays(projectId: string) {
  return api.get<OpenWorkDaysResponse[]>(
    `/api-conrep/projects/${projectId}/statistics`,
  );
}

export async function getServiceSpecifications(projectId: string) {
  return api.get<NewServiceSpecification[]>(
    `/api/projects/${projectId}/servicespecifications`,
  );
}

export async function createServiceSpecification(
  projectId: string,
  data:
    | CreateCustomServiceSpecificationRequest
    | CreateServiceSpecificationRequest,
) {
  return api.post<CreateServiceSpecificationResponse>(
    `/api/projects/${projectId}/servicespecifications`,
    data,
  );
}

// TODO: why does this return a project?
export async function parseServiceSpecification(
  projectId: string,
  serviceSpecificationId: string,
) {
  return api.post<ProjectWithDownloadRef>(
    `/api/projects/${projectId}/servicespecifications/${serviceSpecificationId}/parse`,
  );
}

export async function updateServiceSpecification(
  projectId: string,
  serviceSpecificationId: string,
  data: UpdateServiceSpecificationRequest,
) {
  return api.post<ProjectWithDownloadRef>(
    `/api/projects/${projectId}/servicespecifications/${serviceSpecificationId}`,
    data,
  );
}

export async function deleteServiceSpecification(
  projectId: string,
  serviceSpecificationId: string,
) {
  return api.delete(
    `/api/projects/${projectId}/servicespecifications/${serviceSpecificationId}`,
  );
}

export async function uploadServiceSpecificationToS3(url: string, file: File) {
  return s3.upload(url, file);
}

export async function getServiceSpecificationSuggestions(
  projectId: string,
  techCrewId: string,
  serviceSpecificationId: string,
  target: 'REGIE' | 'WORKDAY',
) {
  return api.get<any[]>(
    `/api/projects/${projectId}/serviceSpecifications/${serviceSpecificationId}/suggestions?target=${target}&techcrew=${techCrewId}`,
  );
}

export async function getServiceSpecificationTree(
  projectId: string,
  serviceSpecificationId: string,
  showCbLevel: string,
) {
  return api.get<any[]>(
    `/api/projects/${projectId}/servicespecifications/${serviceSpecificationId}/tree?showcheckboxlevel=${showCbLevel}`,
  );
}

export async function getGaebTree(
  projectId: string,
  serviceSpecificationId: string,
) {
  return getServiceSpecificationTree(projectId, serviceSpecificationId, '');
}

export async function transferProjectOwnership(
  projectId: string,
  ownerId: string,
) {
  return api.patch<Project>(`/api/projects/${projectId}/transfer-ownership`, {
    ownerId,
  });
}

export async function addUsersToProject(
  projectId: string,
  userIds: string[],
  assignedTechCrewId?: string,
  techCrewPermissions?: TechCrewPermission[],
) {
  return api.post<Shared.IUpdateProjectResponse>(
    `/api/projects/${projectId}/users`,
    {
      userIds,
      assignedTechCrewId,
      techCrewPermissions,
    },
  );
}

export async function upgradeProjectLicense(projectId: string) {
  return api.patch<Shared.IUpdateProjectResponse>(
    `/api/projects/${projectId}/upgrade-license`,
  );
}
