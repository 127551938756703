import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';

import { MainAppbarLayout } from 'src/app/components/MainAppbarLayout';
import {
  acceptInvitation,
  declineInvitation,
} from 'src/project/api/projectsApi';
import { Container } from 'src/common/components/Container';
import { Button } from 'src/common/components/buttons/Button';
import { useProjectInvitesOfCurrentUserQuery } from 'src/project/queries';

export const InvitationsView = () => {
  const { t } = useTranslation('user');
  const queryClient = useQueryClient();

  const { data: invitations } = useProjectInvitesOfCurrentUserQuery();

  const hasInvitations = Array.isArray(invitations) && invitations.length > 0;

  const handleAccept = async (id: string) => {
    try {
      await acceptInvitation(id);
      toast.success(t('invitationView.success'));
      queryClient.invalidateQueries({
        queryKey: ['project-invitations'],
      });
      queryClient.invalidateQueries({
        queryKey: ['projects'],
      });
    } catch (e) {
      toast.error(t('invitationView.failure'));
    }
  };

  const handleDecline = async (id: string) => {
    try {
      await declineInvitation(id);
      toast.success(t('invitationView.success'));
      queryClient.invalidateQueries({
        queryKey: ['project-invitations'],
      });
    } catch (e) {
      toast.error(t('invitationView.failure'));
    }
  };

  return (
    <MainAppbarLayout>
      <Container className="h-full" variant="box">
        <div className="mb-5 flex justify-between">
          <div>
            <h3 className="mb-1 text-2xl font-semibold">
              {t('invitationView.title')}
            </h3>
            <span className="text-shuttleGray-600">
              {t('invitationView.titleInfo')}
            </span>
          </div>
        </div>

        {hasInvitations && (
          <div className="min-w-full overflow-auto align-middle">
            <table className="relative min-w-full divide-y divide-gray-200 shadow">
              <thead>
                <tr>
                  <th scope="col" className="table-th">
                    {t('invitationView.projectName')}
                  </th>
                  <th scope="col" className="table-th">
                    {t('invitationView.organization')}
                  </th>
                  <th scope="col" className="table-th">
                    {t('invitationView.invitedBy')}
                  </th>
                  <th scope="col" className="table-th" />
                </tr>
              </thead>
              <tbody className="table-body">
                {invitations.map((invitation) => (
                  <InvitationItem
                    projectName={invitation.projectName}
                    organizationName={invitation.companyName}
                    invitedBy={invitation.createdByName}
                    onClickAccept={() => handleAccept(invitation._id)}
                    onClickDecline={() => handleDecline(invitation._id)}
                    key={invitation._id}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Container>
    </MainAppbarLayout>
  );
};

export const InvitationItem = ({
  projectName,
  organizationName,
  invitedBy,
  onClickAccept,
  onClickDecline,
}: {
  projectName: string;
  organizationName: string;
  invitedBy: string;
  onClickAccept: () => void;
  onClickDecline: () => void;
}) => {
  return (
    <tr>
      <td className="table-td">{projectName}</td>
      <td className="table-td">{organizationName}</td>
      <td className="table-td">{invitedBy}</td>
      <td className="table-td">
        <div className="ml-auto flex justify-end pl-2">
          <Button className="h-10 w-10" onClick={onClickAccept}>
            <FontAwesomeIcon fixedWidth icon={faCheck} />
          </Button>
          <Button
            variant="secondary"
            className="ml-2 h-10 w-10"
            onClick={onClickDecline}
          >
            <FontAwesomeIcon fixedWidth icon={faTimes} />
          </Button>
        </div>
      </td>
    </tr>
  );
};
