import { faCog, faSignOut, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import { useAuth, useUser } from 'src/auth/user-context';
import { Avatar } from 'src/common/components/Avatar';
import { Menu } from 'src/common/components/Menu';
import { getUserImage } from 'src/user/api';
import { User } from 'src/user/types';

export const UserMenu = () => {
  const user = useUser();
  const query = useQuery({
    queryKey: ['user-profile', user._id, 'image'],

    queryFn: () => getUserImage(user._id),
  });

  return (
    <Tippy
      content={<UserMenuContent user={user} />}
      arrow={false}
      trigger="click"
      placement="bottom-end"
      interactive
    >
      <div className="cursor-pointer p-3.5 focus-within:border-b-2 focus-within:border-white hover:bg-white/10">
        <Avatar
          alt={`${user.name} ${user.surname}`}
          src={query.data?.downloadUrl}
          size="sm"
        />
      </div>
    </Tippy>
  );
};

const UserMenuContent = ({ user }: { user: User }) => {
  const { logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('app');

  return (
    <Menu className="text-shuttleGray-800">
      <Menu.Title>{`${user.name} ${user.surname}`}</Menu.Title>
      <Menu.Divider />
      <Menu.List>
        {user.level === 'ADMIN' && (
          <>
            {location.pathname.startsWith('/admin') ? (
              <Link to="/">
                <Menu.Item>
                  <FontAwesomeIcon icon={faCog} fixedWidth />
                  {t('appbar.userArea')}
                </Menu.Item>
              </Link>
            ) : (
              <Link to="/admin">
                <Menu.Item>
                  <FontAwesomeIcon icon={faCog} fixedWidth />
                  {t('appbar.adminArea')}
                </Menu.Item>
              </Link>
            )}
          </>
        )}
        <Menu.Item onClick={() => navigate(`/profile`)}>
          <FontAwesomeIcon icon={faUser} fixedWidth />
          {t('appbar.myProfile')}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            navigate('/');
            logout();
          }}
        >
          <FontAwesomeIcon icon={faSignOut} fixedWidth />
          {t('appbar.logout')}
        </Menu.Item>
      </Menu.List>
    </Menu>
  );
};
