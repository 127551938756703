import React from 'react';
import { Outlet } from 'react-router-dom';

import { Container } from 'src/common/components/Container';
import { SitelifeAppbar } from './appbar';

export const MainAppbarLayout = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <div className="flex h-full flex-col">
      <SitelifeAppbar />
      <div className="flex h-full grow flex-row overflow-y-auto">
        <Container variant="box" className="py-8">
          {children ? children : <Outlet />}
        </Container>
      </div>
    </div>
  );
};
