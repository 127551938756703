// TODO: remove below line ASAP and fix the broken effects
/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCheckSquare,
  faSearch,
  faSquare,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cloneDeep from 'lodash/cloneDeep';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { cn } from 'src/common/utils';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { CheckboxTree } from 'src/common/components/CheckboxTree';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { SitelifeHighlighter } from 'src/common/components/SitelifeHighlighter';
import { Spinner } from 'src/common/components/Spinner';
import {
  getServiceSpecificationSuggestions,
  getServiceSpecificationTree,
} from 'src/project/api/projectsApi';
import { NewServiceSpecification } from 'src/project/types/projects';
import { TechCrew } from 'src/project/types/techCrews';
import { splicePosition } from 'src/project/utils';

type Props = {
  serviceSpecification: NewServiceSpecification;
  ssLevel?: string;
  checked: string[];
  onCheck: (c: string[], node?: any) => void;
  expanded: string[];
  onExpand: (e: string[]) => void;
  techCrewForSuggestions?: TechCrew;
  treeProps?: any;
  isWorkdaySettingsTree?: boolean;
};

export const ServiceSpecificationTreeOnlv = ({
  serviceSpecification,
  ssLevel = 'LV',
  checked,
  onCheck,
  expanded,
  onExpand,
  techCrewForSuggestions,
  treeProps,
  isWorkdaySettingsTree = false,
}: Props) => {
  const { t } = useTranslation('project');
  const { projectId = '' } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isFiltering, setIsFiltering] = useState(true);

  const [treeNodes, setTreeNodes] = useState<TreeNode[]>();
  const [filterTreeNodesText, setFilterTreeNodesText] = useState<string>();
  const filterRef = useRef<HTMLInputElement | null>(null);

  // can use ref here cause we dont need to rerender as it doesnt change
  const originalTree = useRef<TreeNode[]>();
  const originalTreeFiltered = useRef<TreeNode[]>();

  const onSelectChildChildChildNodes = (obj: TreeNode): void => {
    let copy = checked ? [...checked] : [];
    const childrenArray = obj.children
      .map((c) => {
        if (c.children) {
          return c.children.map((cc) => {
            if (cc.children) {
              return cc.children.map((ccc) => ccc.value);
            }
            return [];
          });
        }
        return [];
      })
      .flat(2);

    const allCheckedChildren = childrenArray.every((c) => {
      return checkPathsContainNodePath(checked, c);
    });

    if (allCheckedChildren) {
      copy = copy.filter((copyNode) => {
        return checkPathsContainNodePath(childrenArray, copyNode);
      });
    } else {
      obj.children.forEach((childNode) => {
        childNode.children?.forEach((childChildNode) => {
          childChildNode.children?.forEach((childChildChildNode) => {
            if (
              (checked &&
                !checkPathsContainNodePath(
                  checked,
                  childChildChildNode.value,
                )) ||
              !checked
            ) {
              copy.push(childChildChildNode.value);
            }
          });
        });
      });
    }

    onCheck(copy);
  };

  const onSelectChildChildNodes = (obj: TreeNode): void => {
    let copy = checked ? [...checked] : [];

    const childrenArray = obj.children
      .map((c) => {
        if (c.children) {
          return c.children.map((cc) => cc.value);
        }

        return [];
      })
      .flat();

    const allCheckedChildren = childrenArray.every((c) => {
      return checkPathsContainNodePath(checked, c);
    });

    if (allCheckedChildren) {
      copy = copy.filter((copyNode) => {
        return checkPathsContainNodePath(childrenArray, copyNode);
      });
    } else {
      obj.children.forEach((childNode) => {
        childNode.children?.forEach((childChildNode) => {
          if (
            (checked &&
              !checkPathsContainNodePath(checked, childChildNode.value)) ||
            !checked
          ) {
            copy.push(childChildNode.value);
          }
        });
      });
    }

    onCheck(copy);
  };

  const onSelectChildNodes = (obj: TreeNode) => {
    let copy = checked ? [...checked] : [];

    const childrenArray = obj.children.map((c) => c.value);
    const allChecked = childrenArray.every((c) => {
      if (copy.includes(c)) {
        return true;
      }
      return false;
    });

    if (allChecked) {
      copy = copy.filter((copyNode) => {
        return checkPathsContainNodePath(childrenArray, copyNode);
      });
    } else {
      obj.children.forEach((childNode) => {
        if (
          (checked && !checkPathsContainNodePath(checked, childNode.value)) ||
          !checked
        ) {
          copy.push(childNode.value);
        }
      });
    }
    onCheck(copy);
  };

  const paintTree = (
    tree: TreeNode[] | undefined = undefined,
    expandedNodes: string[] | undefined = undefined,
  ) => {
    const copy = cloneDeep(
      tree || originalTreeFiltered.current || originalTree.current,
    );
    // cancel if no tree yet
    if (!copy) {
      return;
    }

    traverseTree(
      copy,
      filterTreeNodesText,
      isWorkdaySettingsTree ? onSelectChildNodes : undefined,
      isWorkdaySettingsTree ? onSelectChildChildNodes : undefined,
      isWorkdaySettingsTree ? onSelectChildChildChildNodes : undefined,
      !!isWorkdaySettingsTree,
      checked,
    );

    setTreeNodes(copy);

    if (expandedNodes) {
      onExpand(expandedNodes);
    }
    if (!originalTree.current) {
      // if its the first time
      originalTree.current = copy;
    }
  };

  // initial loading effect
  useEffect(() => {
    const loadTree = async () => {
      setIsLoading(true);
      if (techCrewForSuggestions) {
        const suggestions = await getServiceSpecificationSuggestions(
          techCrewForSuggestions.project,
          techCrewForSuggestions._id,
          serviceSpecification._id,
          'WORKDAY',
        );
        paintTree(suggestions, expanded || []);
      } else {
        const tree = await getServiceSpecificationTree(
          projectId,
          serviceSpecification._id,
          ssLevel,
        );
        paintTree(tree, []);
      }
      setIsLoading(false);
    };
    loadTree();
  }, [serviceSpecification._id, ssLevel]);

  useEffect(() => {
    if (isWorkdaySettingsTree && checked && treeNodes) {
      paintTree();
    }
  }, [checked, isWorkdaySettingsTree]);

  const filterTreeNodes = (
    { tmpFiltered, tmpExpanded, parentMatched }: Partial<TreeNode>,
    node: TreeNode,
  ): Partial<TreeNode> => {
    const lvPosition = splicePosition(node.value);

    const matchesPosition =
      lvPosition &&
      filterTreeNodesText &&
      lvPosition
        .toLocaleLowerCase()
        .indexOf(filterTreeNodesText.toLocaleLowerCase()) > -1;
    const matchesLabel =
      node.description &&
      filterTreeNodesText &&
      node.description
        .toLocaleLowerCase()
        .indexOf(filterTreeNodesText.toLocaleLowerCase()) > -1;
    const matched = matchesLabel || matchesPosition;
    const children = (node.children || []).reduce(filterTreeNodes, {
      tmpFiltered: [],
      tmpExpanded: [],
      parentMatched: matched,
    } as Partial<TreeNode>);
    if (matched || parentMatched || children.tmpFiltered?.length) {
      const obj = { ...node };
      if (children?.tmpFiltered && children?.tmpFiltered?.length > 0) {
        obj.children = children.tmpFiltered;
      }
      tmpFiltered?.push(obj);
    }
    if (matched || (children.tmpExpanded && children.tmpExpanded.length)) {
      tmpExpanded?.push(node.value, ...(children.tmpExpanded as string[]));
    }

    return { tmpFiltered, tmpExpanded, parentMatched };
  };

  useEffect(() => {
    async function asyncFiltering() {
      // sleep 150ms so loading indicator is visible -> feels more natural
      // otherwise it feels stuck until new result re-renders
      await new Promise((r) => setTimeout(r, 150));
      if (filterTreeNodesText && originalTree.current) {
        const filterResult = originalTree.current.reduce(filterTreeNodes, {
          tmpFiltered: [],
          tmpExpanded: [],
          parentMatched: false,
        });
        originalTreeFiltered.current = filterResult.tmpFiltered;
        paintTree(
          filterResult.tmpFiltered as TreeNode[],
          filterResult.tmpExpanded as string[],
        );
      }
      setIsFiltering(false);
    }
    if (isFiltering) {
      asyncFiltering();
    }
  }, [isFiltering]);

  useEffect(() => {
    if (filterTreeNodesText) {
      setIsFiltering(true);
    } else {
      originalTreeFiltered.current = undefined;
      paintTree();
    }
  }, [filterTreeNodesText]);

  return (
    <div className="flex min-h-[480px] flex-col rounded border border-gray-300 bg-gray-100 p-3">
      <div className="mb-4 flex items-start justify-between">
        <div>
          <h3 className="text-xl font-semibold text-shuttleGray-800">
            {t('settings.serviceSpecifications.title')}
          </h3>

          <div className="text-base font-medium text-gray-800">
            {serviceSpecification.name}
          </div>
        </div>

        <form
          className="flex w-96 max-w-[50%] items-center"
          onSubmit={(e) => {
            e.preventDefault();
            setFilterTreeNodesText(
              (e.target as HTMLFormElement).filterText.value,
            );
          }}
        >
          <div className="relative flex w-full items-center">
            <TextInput
              placeholder={t('settings.serviceSpecifications.filterSS')}
              defaultValue={filterTreeNodesText || ''}
              className="w-full"
              name="filterText"
              ref={filterRef}
            />
          </div>
          <div className="ml-2 flex w-8 shrink-0 justify-center">
            {isFiltering ? (
              <Spinner />
            ) : (
              <ActionIcon type="submit">
                <FontAwesomeIcon icon={faSearch} />
              </ActionIcon>
            )}
          </div>
          <ActionIcon
            className="ml-2 shrink-0"
            onClick={() => {
              if (filterRef.current) {
                filterRef.current.value = '';
              }
              setFilterTreeNodesText('');
              paintTree();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </ActionIcon>
        </form>
      </div>
      {isLoading ? (
        <div className="flex grow items-center justify-center">
          <Spinner className="text-6xl" containerClassName="" />
        </div>
      ) : (
        <div>
          {!treeNodes ||
          treeNodes?.length === 0 ||
          (treeNodes?.length === 0 &&
            originalTree?.current &&
            originalTree?.current?.length > 0) ? (
            <div className="text-base font-medium text-shuttleGray-700">
              {t('settings.serviceSpecifications.noGroupsOrPositions')}
            </div>
          ) : (
            <CheckboxTree
              nodes={treeNodes || []}
              checked={((): string[] => {
                return treeNodes
                  ? checked.map((path) => {
                      return (
                        treeNodes[0].value +
                        '.' +
                        path.substring(path.indexOf('.') + 1)
                      );
                    })
                  : [];
              })()}
              expanded={expanded || []}
              onCheck={(c: string[], node: any) => onCheck(c, node)}
              onExpand={(e) => {
                onExpand(e);
              }}
              onClick={isWorkdaySettingsTree ? () => null : null}
              {...treeProps}
            />
          )}
        </div>
      )}
    </div>
  );
};

export type TreeNode = {
  children: TreeNode[];
  value: string;
  label: ReactElement;
  nodeType: 'ULG' | 'LG' | 'OG';
  description: string;
  showCheckbox: boolean;

  tmpFiltered: TreeNode[];
  tmpExpanded: string[];
  parentMatched: boolean;
};

function getIcon(allChecked: boolean, someChecked: boolean): ReactElement {
  if (allChecked && !someChecked) {
    return (
      <FontAwesomeIcon
        className="rct-icon rct-icon-check"
        icon={faCheckSquare}
        fixedWidth
      />
    );
  }
  if (!allChecked && someChecked) {
    return (
      <FontAwesomeIcon
        className="rct-icon rct-icon-half-check opacity-50"
        icon={faCheckSquare}
        fixedWidth
      />
    );
  }
  return (
    <FontAwesomeIcon
      className="rct-icon rct-icon-uncheck"
      icon={faSquare}
      fixedWidth
    />
  );
}

function checkPathsContainNodePath(
  checkPaths: string[],
  nodePath: string,
): boolean {
  if (checkPaths) {
    const trimmedCheckPaths = checkPaths.map((path) => {
      return path.substring(path.indexOf('.') + 1);
    });
    if (
      trimmedCheckPaths.includes(nodePath.substring(nodePath.indexOf('.') + 1))
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function traverseTree(
  children: TreeNode[],
  highlightedSearch: string | undefined,
  onChild: ((n: TreeNode) => void) | undefined,
  onChildChild: ((n: TreeNode) => void) | undefined,
  onChildChildChild: ((n: TreeNode) => void) | undefined,
  isWorkdaySettingsTree: boolean,
  checked: string[],
): void {
  const colorMapping = {
    FPOS: 'bg-blue-400',
    POS: 'bg-blue-500',
    ULG: 'bg-blue-600',
    LG: 'bg-blue-700',
    OG: 'bg-blue-800',
    HG: 'bg-blue-900',
    LV: 'bg-black',
  };

  children.forEach((obj) => {
    // for children
    let allChecked;
    let someChecked;

    if (
      obj.children &&
      (obj.nodeType === 'ULG' || obj.nodeType === 'LG' || obj.nodeType === 'OG')
    ) {
      const childrenArray = obj.children.map((c) => c.value);

      allChecked = childrenArray.every((c) => {
        return checkPathsContainNodePath(checked, c);
      });

      someChecked =
        !allChecked &&
        childrenArray.some((c) => {
          return checkPathsContainNodePath(checked, c);
        });
    }

    // for childrenChildren
    let allCheckedChildrenChildren;
    let someCheckedChildrenChildren;

    // for children
    let allCheckedChildren;
    let someCheckedChildren;

    if (obj.children && obj.nodeType === 'LG') {
      const childrenArray = obj.children
        .map((c) => {
          if (c.children) {
            return c.children.map((cc) => cc.value);
          }
          return [];
        })
        .flat();

      allCheckedChildren = childrenArray.every((c) => {
        return checkPathsContainNodePath(checked, c);
      });

      someCheckedChildren =
        !allCheckedChildren &&
        childrenArray.some((c) => {
          return checkPathsContainNodePath(checked, c);
        });
    }

    if (obj.children && obj.nodeType === 'OG') {
      // for positions
      const childrenChildrenArray = obj.children
        .map((c) => {
          if (c.children) {
            return c.children.map((cc) => {
              if (cc.children) {
                return cc.children.map((ccc) => ccc.value);
              }
              return [];
            });
          }
          return [];
        })
        .flat(2);

      allCheckedChildrenChildren = childrenChildrenArray.every((c) => {
        return checkPathsContainNodePath(checked, c);
      });

      someCheckedChildrenChildren =
        !allCheckedChildrenChildren &&
        childrenChildrenArray.some((c) => {
          return checkPathsContainNodePath(checked, c);
        });

      // for ULGs
      const childrenArray = obj.children
        .map((c) => {
          if (c.children) {
            return c.children.map((cc) => cc.value);
          }
          return [];
        })
        .flat();

      allCheckedChildren = childrenArray.every((c) => {
        return checkPathsContainNodePath(checked, c);
      });

      someCheckedChildren =
        !allCheckedChildren &&
        childrenArray.some((c) => {
          return checkPathsContainNodePath(checked, c);
        });
    }

    obj.label = (
      <span className="relative" key={obj.description}>
        <span
          className={cn('mr-2 rounded-xl px-2 text-sm font-medium text-white', {
            [colorMapping[obj.nodeType]]: obj.showCheckbox,
            'bg-gray-600': !obj.showCheckbox,
          })}
        >
          {obj.nodeType}
        </span>
        <span className="mr-3 font-medium text-shuttleGray-800">
          {highlightedSearch ? (
            <SitelifeHighlighter
              className=""
              highlightedSearch={highlightedSearch}
              textToHighlight={splicePosition(obj.value)}
            />
          ) : (
            splicePosition(obj.value)
          )}
        </span>

        <span className="ml-2 text-gray-800">
          {highlightedSearch ? (
            <SitelifeHighlighter
              className=""
              highlightedSearch={highlightedSearch}
              textToHighlight={obj.description || ''}
            />
          ) : (
            obj.description || ''
          )}
        </span>

        {((obj.nodeType === 'ULG' && onChild) ||
          (obj.nodeType === 'LG' && onChild) ||
          (obj.nodeType === 'LG' && onChildChild) ||
          (obj.nodeType === 'OG' && onChild) ||
          (obj.nodeType === 'OG' && onChildChild) ||
          (obj.nodeType === 'OG' && onChildChildChild)) && (
          <span className="my-auto ml-2 border-r border-gray-500" />
        )}
        {obj.nodeType === 'ULG' && onChild && (
          <span
            className="ml-2 cursor-pointer select-none rounded border border-gray-400 px-1 text-sm leading-tight text-gray-800 hover:bg-gray-100"
            onClick={() => onChild(obj)}
            onKeyDown={() => onChild(obj)}
            role="button"
            tabIndex={0}
          >
            {getIcon(!!allChecked, !!someChecked)} POS
          </span>
        )}
        {obj.nodeType === 'LG' && onChild && (
          <span
            className="ml-2 cursor-pointer select-none rounded border border-gray-400 px-1 text-sm leading-tight text-gray-800 hover:bg-gray-100"
            onClick={() => onChild(obj)}
            onKeyDown={() => onChild(obj)}
            role="button"
            tabIndex={0}
          >
            {getIcon(!!allChecked, !!someChecked)}
            ULG
          </span>
        )}
        {obj.nodeType === 'LG' && onChildChild && (
          <span
            className="ml-2 cursor-pointer select-none rounded border border-gray-400 px-1 text-sm leading-tight text-gray-800 hover:bg-gray-100"
            onClick={() => onChildChild(obj)}
            onKeyDown={() => onChildChild(obj)}
            role="button"
            tabIndex={0}
          >
            {getIcon(!!allCheckedChildren, !!someCheckedChildren)}
            POS
          </span>
        )}
        {obj.nodeType === 'OG' && onChild && (
          <span
            className="ml-2 cursor-pointer select-none rounded border border-gray-400 px-1 text-sm leading-tight text-gray-800 hover:bg-gray-100"
            onClick={() => onChild(obj)}
            onKeyDown={() => onChild(obj)}
            role="button"
            tabIndex={0}
          >
            {getIcon(!!allChecked, !!someChecked)} LG
          </span>
        )}
        {obj.nodeType === 'OG' && onChildChild && (
          <span
            className="ml-2 cursor-pointer select-none rounded border border-gray-400 px-1 text-sm leading-tight text-gray-800 hover:bg-gray-100"
            onClick={() => onChildChild(obj)}
            onKeyDown={() => onChildChild(obj)}
            role="button"
            tabIndex={0}
          >
            {getIcon(!!allCheckedChildren, !!someCheckedChildren)}
            ULG
          </span>
        )}
        {obj.nodeType === 'OG' && onChildChildChild && (
          <span
            className="ml-2 cursor-pointer select-none rounded border border-gray-400 px-1 text-sm leading-tight text-gray-800 hover:bg-gray-100"
            onClick={() => onChildChildChild(obj)}
            onKeyDown={() => onChildChildChild(obj)}
            role="button"
            tabIndex={0}
          >
            {getIcon(
              !!allCheckedChildrenChildren,
              !!someCheckedChildrenChildren,
            )}
            POS
          </span>
        )}
      </span>
    );
    if (Object.prototype.hasOwnProperty.call(obj, 'children')) {
      traverseTree(
        obj.children,
        highlightedSearch,
        onChild,
        onChildChild,
        onChildChildChild,
        isWorkdaySettingsTree,
        checked,
      );
    }
  });
}
