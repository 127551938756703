import { useTranslation } from 'react-i18next';

import { SidebarLayout } from 'src/common/components/SidebarLayout';
import { InformationForm } from '../components/InformationForm';
import { SettingsSidebar } from '../components/SettingsSidebar';
import { ProjectImage } from '../components/ProjectImage';

export const InformationView = () => {
  return (
    <SidebarLayout header={<Header />} sidebar={<SettingsSidebar />}>
      <ProjectImage />
      <InformationForm />
    </SidebarLayout>
  );
};

function Header() {
  const { t } = useTranslation('project');

  return (
    <div>
      <h3 className="mb-1 text-2xl font-semibold">
        {t('settings.information.title')}
      </h3>
      <p className="text-shuttleGray-600">
        {t('settings.information.description')}
      </p>
    </div>
  );
}
