import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { faEdit, faEye, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { Card } from 'src/common/components/Card';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Tooltip } from 'src/common/components/Tooltip';
import { SheetSet } from 'src/sheetset/types';
import { deleteSheetSet, updateSheetSet } from 'src/sheetset/api';
import { Modal } from 'src/common/components/Modal';
import { SheetSetForm } from '../SheetSetForm';
import { DeleteModal } from 'src/common/components/DeleteModal';

export const SheetSetItem = ({
  sheetSet,
  disabled,
  takenNames,
}: {
  sheetSet: SheetSet;
  disabled: boolean;
  takenNames: string[];
}) => {
  const { t } = useTranslation('sheetset');
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const queryClient = useQueryClient();

  async function handleSave(sheetSetData: Partial<SheetSet>) {
    try {
      await updateSheetSet(sheetSet.project, sheetSet._id, {
        name: sheetSetData.name || '',
        views: sheetSetData.views || [],
      });
      amplitude.track('sheetset: edit', { projectId: sheetSet.project });
      queryClient.invalidateQueries({
        queryKey: ['sheetsets', sheetSet.project],
      });
      toast.success(t('updateSheetSetSuccess'));
      setIsEditModalOpen(false);
    } catch (e) {
      toast.error(t('updateSheetSetFailure'));
    }
  }

  async function handleConfirmDelete() {
    try {
      await deleteSheetSet(sheetSet.project, sheetSet._id);
      queryClient.invalidateQueries({
        queryKey: ['sheetsets', sheetSet.project],
      });
      amplitude.track('sheetset: delete', {
        projectId: sheetSet.project,
      });
      setIsDeleteModalOpen(false);
    } catch (err) {
      toast.error(t('deleteSheetSetFailure'));
    }
  }

  const viewCount = `${sheetSet.views.length} ${
    sheetSet.views.length === 1 ? t('view') : t('views')
  }`;

  return (
    <Card
      key={sheetSet._id}
      className="group flex h-24 items-center border border-transparent hover:border-blue-600 focus:border-blue-700"
    >
      <div className="h-full w-full p-5">
        <h3 className="h-1/2 truncate font-semibold">{sheetSet.name}</h3>
        <div className="flex h-1/2 items-center">
          <div className="text-sm font-normal text-shuttleGray-600">
            {viewCount}
          </div>
          <div className="ml-auto flex justify-end opacity-0 group-hover:opacity-100">
            <Tooltip content={t('onClickView')}>
              <ActionIcon
                onClick={() => {
                  navigate(
                    `/p/${sheetSet.project}/dashboard/v?sheetSet=${sheetSet._id}`,
                  );
                }}
                disabled={sheetSet.views.length < 1}
              >
                <FontAwesomeIcon icon={faEye} />
              </ActionIcon>
            </Tooltip>
            <Tooltip content={t('onClickEdit')}>
              <ActionIcon
                onClick={() => setIsEditModalOpen(true)}
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faEdit} />
              </ActionIcon>
            </Tooltip>
            <Tooltip content={t('onClickDelete')}>
              <ActionIcon
                onClick={() => setIsDeleteModalOpen(true)}
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ActionIcon>
            </Tooltip>
          </div>
        </div>
      </div>
      <DeleteModal
        title={t('deleteSheetSet')}
        subtitle={t('deleteSheetSetConfirmation')}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        deleteButton={handleConfirmDelete}
      />
      <Modal isOpen={isEditModalOpen} className="w-[768px]">
        <SheetSetForm
          sheetSet={sheetSet}
          takenNames={takenNames}
          onCancel={() => setIsEditModalOpen(false)}
          onSave={handleSave}
        />
      </Modal>
    </Card>
  );
};
