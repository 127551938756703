import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

import { UserProjectRow } from 'src/admin/components/user/projects/UserProjectRow';
import { Badge } from 'src/common/components/Badge';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { isUserPartOfCompany } from 'src/admin/utils';

import type { CompanyPopulatedUser } from 'shared';
import type { OwnerCompanyPopulatedProject } from 'shared';

type Props = {
  projectGroup: OwnerCompanyPopulatedProject[];
  user: CompanyPopulatedUser;
  addProjectButton?: React.ReactNode;
};

export const UserProjectsGroup = ({ projectGroup, user }: Props) => {
  const { t } = useTranslation(['admin', 'common']);

  const [showTable, setShowTable] = useState(true);

  const projectCompanyId = projectGroup[0].company?._id;
  const isUserMemberOfCompany = isUserPartOfCompany(
    projectCompanyId,
    user.companies,
  );
  const companyName = projectGroup[0].company.name;

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <h3 className="text-lg font-semibold ">{companyName}</h3>
          {isUserMemberOfCompany && (
            <Badge className="ml-2">
              {t('users.modal.associatedOrganization')}
            </Badge>
          )}
        </div>
        <ActionIcon onClick={() => setShowTable(!showTable)}>
          <FontAwesomeIcon icon={showTable ? faChevronDown : faChevronUp} />
        </ActionIcon>
      </div>
      {showTable && (
        <table className="relative w-full divide-y divide-gray-200 shadow">
          <thead>
            <tr>
              <th scope="col" className="table-th">
                {t('common:name')}
              </th>
              <th scope="col" className="table-th normal-case">
                {t('users.modal.roleOfUser')}
              </th>
              <th scope="col" className="table-th xl:table-cell" />
            </tr>
          </thead>
          <tbody className="table-body">
            {projectGroup.map((project) => (
              <UserProjectRow
                key={project._id}
                project={project}
                userId={user._id}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
