import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Company } from 'src/company/types';
import {
  LicenseEvaluationType,
  LicenseType,
  ProjectFilter,
  ProjectWithDownloadRef,
} from 'src/project/types/projects';
import { validateLicense } from 'src/project/utils';
import { User } from 'src/user/types';

export function filterAndSortProjects(
  projects: ProjectWithDownloadRef[],
  search: string,
  sortDirection: 'asc' | 'desc',
  sortProperty: 'name' | 'createdAt',
) {
  return projects
    .filter((project) =>
      project.name.toLowerCase().includes(search.toLowerCase()),
    )
    .sort((a, b) => {
      const normalizedA = a[sortProperty]?.toLowerCase();
      const normalizedB = b[sortProperty]?.toLowerCase();
      if (normalizedA === undefined || normalizedB === undefined) {
        return 0;
      }
      if (normalizedA < normalizedB) return sortDirection === 'asc' ? -1 : 1;
      if (normalizedA > normalizedB) return sortDirection === 'asc' ? 1 : -1;

      return 0;
    });
}

export function getProjectFilterOptions(
  projects: ProjectWithDownloadRef[],
  archivedLabel: string,
) {
  const filterOptions: SelectSitelifeOptionType[] = [];

  const gotFreeProject = projects.find(
    (p) => p.license.type === LicenseType.FREE,
  );
  if (gotFreeProject) {
    filterOptions.push({ label: 'Free', value: ProjectFilter.FREE });
  }
  const gotBasicProject = projects.find(
    (p) => p.license.type === LicenseType.BASIC,
  );
  if (gotBasicProject) {
    filterOptions.push({ label: 'Basic', value: ProjectFilter.BASIC });
  }
  const gotExpiredProject = projects.find(
    (p) => validateLicense(p.license) === LicenseEvaluationType.EXPIRED,
  );
  if (gotExpiredProject) {
    filterOptions.push({
      label: archivedLabel,
      value: ProjectFilter.ARCHIVED,
    });
  }
  const gotDemoProject = projects.find(
    (p) => p.license.type === LicenseType.DEMO,
  );
  if (gotDemoProject) {
    filterOptions.push({ label: 'Demo', value: ProjectFilter.DEMO });
  }

  return filterOptions;
}

export function filterProjectsByExpiration(projects: ProjectWithDownloadRef[]) {
  return projects.filter((project) => {
    const isExpired = Boolean(
      project.license?.expirationDate &&
        new Date(project.license?.expirationDate) < new Date(),
    );

    return !isExpired;
  });
}

export function filterProjectsByExpirationAndLicense(
  projects: ProjectWithDownloadRef[],
  filterType: ProjectFilter[] | null,
) {
  if (filterType?.length === 0) {
    return projects;
  }

  // Handles the ARCHIVED filter
  const archivedProjects = filterType?.includes(
    ProjectFilter.ARCHIVED as unknown as ProjectFilter,
  )
    ? projects.filter((project) => {
        const isExpired = Boolean(
          project.license?.expirationDate &&
            new Date(project.license?.expirationDate) < new Date(),
        );
        return isExpired;
      })
    : [];

  const filteredProjects = projects.filter((project) => {
    // Handles the BASIC and FREE filters
    const hasMatchingLicenseType = filterType
      ? filterType.includes(project.license?.type as unknown as ProjectFilter)
      : true;

    const isNotExpired =
      Boolean(
        project.license?.expirationDate &&
          new Date(project.license?.expirationDate) >= new Date(),
      ) || !project.license?.expirationDate;

    return hasMatchingLicenseType && isNotExpired;
  });

  return [...filteredProjects, ...archivedProjects];
}

export function groupProjectsByCompany(
  allProjects: ProjectWithDownloadRef[],
  user: User,
  userCompanies: Company[] | undefined | null,
) {
  const map: {
    name: string;
    companyId?: string;
    isWorkspace: boolean;
    isAdmin: boolean;
    projects: ProjectWithDownloadRef[];
  }[] = [];

  const { _id: userId, personalCompany } = user;

  if (userCompanies && userId) {
    userCompanies.forEach((company) => {
      map.push({
        name: company.name,
        companyId: company._id,
        isWorkspace: company._id === personalCompany,
        isAdmin: company.owner === userId || company.admins.includes(userId),
        projects: allProjects.filter(
          (p) =>
            p.company &&
            (typeof p.company === 'string'
              ? p.company === company._id
              : p.company._id === company._id),
        ),
      });
    });
  }

  const guestProjects = allProjects.filter((project) => {
    const projectCompanyId =
      typeof project.company === 'string'
        ? project.company
        : project.company?._id;

    return !userCompanies?.some((company) => company._id === projectCompanyId);
  });

  const groupedGuestProjects = guestProjects.reduce(
    (acc: Record<string, ProjectWithDownloadRef[]>, project) => {
      if (!project.company) return acc;
      if (typeof project.company === 'string') return acc;

      const { name } = project.company;

      return {
        ...acc,
        [name]: acc.hasOwnProperty(name) ? [...acc[name], project] : [project],
      };
    },
    {},
  );

  Object.keys(groupedGuestProjects).forEach((key) => {
    map.push({
      name: key,
      isWorkspace: false,
      isAdmin: false,
      projects: groupedGuestProjects[key],
    });
  });

  map.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
  const personalCompanyIndex = map.findIndex(
    (c) => c.companyId === personalCompany,
  );
  map.push(map[personalCompanyIndex]);
  map.splice(personalCompanyIndex, 1);

  return map;
}
