import { FC, ReactNode } from 'react';

import { cn } from 'src/common/utils';

type Props = {
  headerLeft?: ReactNode;
  header?: ReactNode;
  sidebar: ReactNode;
  columnSize?: string;
  className?: string;
};

export const SidebarLayout: FC<Props> = ({
  headerLeft,
  header,
  sidebar,
  children,
  columnSize = '240px',
  className,
}) => {
  return (
    <div
      className={cn('grid w-full gap-6', className)}
      style={{
        gridTemplateColumns: `${columnSize} 1fr`,
      }}
    >
      <div>{headerLeft && headerLeft}</div>
      <div>{header && header}</div>
      <div>{sidebar}</div>
      <div>{children}</div>
    </div>
  );
};
