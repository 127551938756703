import { useTranslation } from 'react-i18next';

import revitLogo from 'src/assets/revit-logo.png';
import ifcLogo from 'src/assets/ifc-logo.png';
import pdfLogo from 'src/assets/pdf-logo.svg';
import { Tooltip } from 'src/common/components/Tooltip';
import { FileType } from '../types';

type Props = {
  fileType: FileType;
};

export const TypeBadge = ({ fileType }: Props) => {
  const { t } = useTranslation(['project']);

  const renderBadge = () => {
    if (fileType === FileType.REVIT) {
      return <img src={revitLogo} className="mr-1 h-4 w-4" alt="Revit Logo" />;
    }
    if (fileType === FileType.IFC) {
      return <img src={ifcLogo} className="mr-1 h-4 w-4" alt="IFCLogo" />;
    }
    if (fileType === FileType.PDF || fileType === FileType.PDF_PLAN) {
      return <img src={pdfLogo} className="mr-1 h-4 w-4" alt="PDF Logo" />;
    }
    return null;
  };

  return (
    <Tooltip content={`${fileType}-${t('project:typeBadge.project')}`}>
      <div>{renderBadge()}</div>
    </Tooltip>
  );
};
