import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, useTranslation } from 'react-i18next';
import {
  faClock,
  faChartBar,
  faTrashAlt,
  faCogs,
} from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { faUndo } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { SidebarLayout } from 'src/common/components/SidebarLayout';
import { SidebarMenu } from 'src/common/components/SidebarMenu';
import { ConrepAppModelBased } from 'src/Conrep/App/ModelBased';
import { Card } from 'src/common/components/Card';
import { Chip } from 'src/common/components/Chip';
import { ModelSelection } from 'src/project/components/ModelParameter/ModelSelection';
import { getProperties } from 'src/Forge';
import { PowerBiParameter } from 'src/project/types/projects';
import { ParameterSelection } from 'src/project/components/ModelParameter/ParameterSelection';
import { updateProject } from 'src/project/api/projectsApi';
import { Button } from 'src/common/components/buttons/Button';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { SelectNative } from 'src/common/components/inputs/SelectNative/SelectNative';
import { is3dProject } from 'src/project/utils';
import ThreeDisGreatIllustration from 'src/assets/illu_3d-is-great.svg';
import { refreshDataset } from '../api';
import { useProject } from 'src/project/project-context';
import { useFilesQuery } from 'src/file/queries';

export const AnalysisParametersView = () => {
  const { t } = useTranslation('analysis');
  const project = useProject();

  const queryClient = useQueryClient();
  const { data: files } = useFilesQuery(project._id);

  const [parameters, setParameters] = useState<string[]>([]);
  const [localParameters, setLocalParameters] = useState<PowerBiParameter[]>(
    [],
  );
  const [parametersLoading, setParametersLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (project.powerBiParameters) {
      setLocalParameters(project.powerBiParameters);
    }
  }, [project.powerBiParameters]);

  const handleLoadParameters = async (fileId: string, viewId: string) => {
    if (files) {
      const modelFile = files.find((file) => file._id === fileId);
      if (modelFile && 'activeVersion' in modelFile) {
        try {
          setParametersLoading(true);
          const properties = await getProperties(
            project._id,
            fileId,
            modelFile.activeVersion,
            viewId,
            true,
          );
          setParameters(properties);

          toast.success(
            t('settings.messages.loadParametersSuccess', {
              count: properties.length,
            }),
          );

          if (properties.length <= 0) {
            toast.error(t('settings.messages.loadParametersNoParameters'));
          }
        } catch (err) {
          toast.error(t('settings.messages.loadParametersFailure'));
        } finally {
          setParametersLoading(false);
        }
      }
    }
  };

  const setParameterUnit = (unit: string, parameter: PowerBiParameter) => {
    const tempParameters = Array.from(localParameters);
    const idx = tempParameters.findIndex(
      (x) => x.parameterPath === parameter.parameterPath,
    );
    if (idx > -1) {
      tempParameters[idx] = {
        _id: parameter._id,
        parameterPath: parameter.parameterPath,
        parameterUnit: unit,
      };
    }
    setLocalParameters(tempParameters);
  };

  const handleAddParameter = (parameter: string) => {
    if (localParameters.length > 4) {
      return;
    }
    const tempParameters = Array.from(localParameters);
    tempParameters.unshift({ parameterPath: parameter, parameterUnit: 'm³' });
    setLocalParameters(tempParameters);
    setIsDirty(true);
  };

  const handleRemoveParameter = (parameter: PowerBiParameter) => {
    const tempParameters = Array.from(localParameters);
    const index = tempParameters.findIndex(
      (param) => param.parameterPath === parameter.parameterPath,
    );

    if (index > -1) {
      tempParameters.splice(index, 1);
      setLocalParameters(tempParameters);
      setIsDirty(true);
    }
  };

  const getNameOfParamPath = (path: string): string => {
    if (path) {
      const pathSections = path.split('.');
      return pathSections[pathSections.length - 1];
    }
    return '';
  };

  const handleSave = async () => {
    if (project._id) {
      setIsLoading(true);
      try {
        await updateProject(project._id, {
          powerBiParameters: localParameters,
        });
        await refreshDataset(project._id);
        await queryClient.invalidateQueries({
          queryKey: ['projects', project._id, 'powerbi-refreshes'],
        });
        setIsDirty(false);
        toast.success(t('settings.messages.saveSuccess'));
      } catch (err) {
        toast.error(t('settings.messages.saveError'));
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <SidebarLayout
      header={
        <div>
          <h3 className="mb-1 text-2xl font-semibold">
            {t('settings.analysisParameters')}
          </h3>
          <p className="text-shuttleGray-600">
            {t('settings.analysisParametersDescription')}
          </p>
        </div>
      }
      sidebar={
        <SidebarMenu>
          <SidebarMenu.Item
            to="../plannedHours"
            icon={<FontAwesomeIcon icon={faClock} fixedWidth />}
          >
            {t('settings.plannedHours')}
          </SidebarMenu.Item>
          <SidebarMenu.Item
            to="../analysisParameters"
            icon={<FontAwesomeIcon icon={faChartBar} fixedWidth />}
          >
            {t('settings.analysisParameters')}
          </SidebarMenu.Item>

          <SidebarMenu.Item
            to="../powerBiManagement"
            icon={<FontAwesomeIcon icon={faCogs} fixedWidth />}
          >
            {t('settings.datasetSettings')}
          </SidebarMenu.Item>
        </SidebarMenu>
      }
    >
      <Card className="p-6">
        <ConrepAppModelBased>
          <h4 className="mb-4 text-lg font-semibold">{t('settings.model')}</h4>
          {parameters && parameters.length > 0 ? (
            <Chip className="mr-auto" onClose={() => setParameters([])}>
              <Trans
                ns="project"
                i18nKey="settings.modelParameter.loadedParameters"
                values={{ count: parameters.length }}
                components={[<span className="font-semibold" key="1" />]}
              />
            </Chip>
          ) : (
            <ModelSelection
              project={project}
              loading={parametersLoading}
              onLoadParameters={handleLoadParameters}
            />
          )}

          <hr className="my-6" />

          <h4 className="mb-2 text-lg font-semibold">
            {t('settings.usedParameters')}
          </h4>
          <p className="mb-4 text-shuttleGray-600">
            <Trans
              ns="project"
              i18nKey="settings.modelParameter.displayedParameters.subtitle"
              components={[
                <span className="font-semibold text-shuttleGray-800" key="1" />,
              ]}
            />
          </p>
          <div className="flex flex-col gap-4">
            <ParameterSelection
              parameters={parameters}
              localParameters={localParameters.map((p) => p.parameterPath)}
              onAddParameter={handleAddParameter}
              parametersIncludePath={true}
            />

            {localParameters.length >= 5 && (
              <InlineMessage variant="warning" className="m-2">
                {t('settings.analysisParametersWarning')}
              </InlineMessage>
            )}

            {localParameters.map((parameter) => (
              <div
                className="flex items-center py-2 pl-1 pr-2"
                key={parameter.parameterPath}
              >
                <div className="mr-auto flex flex-col overflow-auto">
                  <div className="truncate text-shuttleGray-800">
                    {getNameOfParamPath(parameter.parameterPath)}
                  </div>
                </div>

                <SelectNative
                  className="ml-1 mr-6"
                  style={{ width: '80px' }}
                  value={parameter.parameterUnit}
                  onChange={(e) => setParameterUnit(e.target.value, parameter)}
                  disabled={!parameter}
                >
                  <option value="m³">m³</option>
                  <option value="m">m</option>
                  <option value="m²">m²</option>
                  <option value="cm³">cm³</option>
                  <option value="cm²">cm²</option>
                  <option value="cm">cm</option>
                  <option value="kg">kg</option>
                  <option value="km">km</option>
                  <option value="Stk">Stk</option>
                  <option value="VE">VE</option>
                  <option value="l">l</option>
                  <option value="g">g</option>
                  <option value="t">t</option>
                  <option value="PA">PA</option>
                  <option value="h">h</option>
                  <option value="d">d</option>
                  <option value="Mo">Mo</option>
                </SelectNative>

                <ActionIcon
                  className="shrink-0"
                  onClick={() => handleRemoveParameter(parameter)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </ActionIcon>
              </div>
            ))}

            {isDirty && (
              <div className="ml-auto flex items-center gap-4">
                <Button
                  variant="tertiary"
                  onClick={() => {
                    project.powerBiParameters &&
                      setLocalParameters(project.powerBiParameters);
                    setIsDirty(false);
                  }}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
                <Button onClick={() => handleSave()} loading={isLoading}>
                  {t('settings.save')}
                </Button>
              </div>
            )}
          </div>
        </ConrepAppModelBased>
        {!is3dProject(project) ? (
          <div>
            <div className="mx-auto my-6 max-w-[500px] text-center">
              <img
                className="mx-auto mb-8"
                src={ThreeDisGreatIllustration}
                alt=""
              />
              <h1 className="my-4 text-4xl font-semibold">
                {t('elements.noModels.title')}
              </h1>
              <div className="mb-6 text-shuttleGray-600">
                {t('elements.noModels.text')}
              </div>
              <Link to={`/p/${project._id}/dashboard/files`}>
                <Button>{t('elements.noModels.action')}</Button>
              </Link>
            </div>
          </div>
        ) : null}
      </Card>
    </SidebarLayout>
  );
};
