import {
  IconDefinition,
  faCheckSquare,
  faEye,
  faFileExport,
  faPaperPlane,
  faPlus,
  faPlusSquare,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AddMembersModal } from 'src/common/components/AddMembersModal';
import { Avatar } from 'src/common/components/Avatar';
import { Badge } from 'src/common/components/Badge';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Container } from 'src/common/components/Container';
import { DeleteModal } from 'src/common/components/DeleteModal';
import { Modal } from 'src/common/components/Modal';
import { SearchSort } from 'src/common/components/SearchSort';
import { SelectSitelife } from 'src/common/components/SelectSitelife';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Spinner } from 'src/common/components/Spinner';
import { Tooltip } from 'src/common/components/Tooltip';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Button } from 'src/common/components/buttons/Button';
import { SelectNative } from 'src/common/components/inputs/SelectNative/SelectNative';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import toast from 'src/common/toast';
import { isString } from 'src/common/utils';
import i18n from 'src/i18n';
import { getOtherUserProfile } from 'src/user/api';
import { EMAIL_REGEX } from 'src/user/constants';
import {
  addUsersToProject,
  deleteInvitation,
  deleteUser,
  getPendingInvitations,
  inviteGuest,
  updateProject,
} from '../api/projectsApi';
import { LicenseExpiredMessage } from '../components/LicenseExpiredMessage';
import { PermissionListHeader } from '../components/TechCrew/EditPermissionsModal';
import { TransferOwnerModal } from '../components/TransferOwnerModal';
import {
  useEligibleProjectUsersQuery,
  useProjectUsersQuery,
  useTechCrewsQuery,
} from '../queries';
import {
  GetProjectResponse,
  LicenseEvaluationType,
  LicenseType,
  ProjectInvitation,
  ProjectUser,
  UserRole,
} from '../types/projects';
import { TechCrew, TechCrewPermission } from '../types/techCrews';
import { validateLicense } from '../utils';
import { Checkbox } from 'src/common/components/inputs/Checkbox';

// Sort ProjectUser entities based on a specific attribute and sort order
function sortUsersByAttribute(
  usersList: ProjectUser[],
  attribute: string,
  sortUsersOrder: string,
): ProjectUser[] {
  const sortedUsers = usersList.slice().sort((a, b) => {
    let aValue;
    let bValue;
    switch (attribute) {
      case 'name':
        aValue = a.name;
        bValue = b.name;
        break;
      case 'surname':
        aValue = a.surname;
        bValue = b.surname;
        break;
      case 'email':
        aValue = a.email;
        bValue = b.email;
        break;
      case 'role':
        aValue = a.role;
        bValue = b.role;
        break;
      default:
        aValue = '';
        bValue = '';
        break;
    }

    // Perform sorting based on sortOrder
    if (sortUsersOrder === 'asc') {
      if (aValue < bValue) {
        return -1;
      } else if (aValue > bValue) {
        return 1;
      }
      return 0;
    } else {
      if (aValue > bValue) {
        return -1;
      } else if (aValue < bValue) {
        return 1;
      }
      return 0;
    }
  });

  return sortedUsers;
}

// Filter the sorted list based on an entered string
function filterSortedUsers(
  sortedUsers: ProjectUser[],
  searchString: string,
): ProjectUser[] {
  return sortedUsers.filter((user) => {
    const lowerSearchString = searchString.toLowerCase();
    const lowerName = user.name.toLowerCase();
    const lowerSurname = user.surname.toLowerCase();
    const lowerEmail = user.email.toLowerCase();

    return (
      lowerName.includes(lowerSearchString) ||
      lowerSurname.includes(lowerSearchString) ||
      lowerEmail.includes(lowerSearchString)
    );
  });
}

export const ProjectUsersView = ({
  project,
  currentUserId,
  isProjectOwnerOrAdmin,
}: {
  project: GetProjectResponse;
  currentUserId: string;
  isProjectOwnerOrAdmin: boolean;
}) => {
  const projectId = project._id;
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isCompanyInviteModalOpen, setIsCompanyInviteModalOpen] =
    useState(false);
  const [searchUsersBy, setSearchUsersBy] = useState('');
  const [sortUsersBy, setSortUsersBy] = useState('email');
  const [sortUsersOrder, setSortUsersOrder] = useState<'asc' | 'desc'>('asc');
  const { data: users, isLoading: usersIsLoading } =
    useProjectUsersQuery(projectId);
  const { data: currentUser } = useQuery({
    queryKey: ['users', currentUserId],

    queryFn: () => getOtherUserProfile(currentUserId),
  });
  const { data: allTechCrews } = useTechCrewsQuery(projectId);
  const techCrews = isProjectOwnerOrAdmin
    ? allTechCrews
    : allTechCrews?.filter((tc) => tc.admins.includes(currentUserId));

  const sortOptions: SelectSitelifeOptionType[] = [
    { label: 'Email', value: 'email' },
    { label: t('common:firstName'), value: 'name' },
    { label: t('common:surname'), value: 'surname' },
    { label: t('users.role'), value: 'role' },
  ];

  const arrangedUserList = users
    ? filterSortedUsers(
        sortUsersByAttribute(users, sortUsersBy, sortUsersOrder),
        searchUsersBy,
      )
    : [];

  const { data: invitations } = useQuery({
    queryKey: ['projects', projectId, 'invitations'],
    queryFn: () => getPendingInvitations(projectId),
    initialData: [],
    staleTime: 0,
  });

  const isDemoProject = project.license.type === LicenseType.DEMO;

  const isPersonalCompany =
    currentUser?.personalCompany ===
    (isString(project.company) ? project.company : project.company?._id);

  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;

  /**
   * @deprecated this violates react state design principles
   * TODO: refactor this, so children dont write state into the parent: lift state up
   */
  function CallGetUsersHook(
    page: number,
    entriesPerPage: number,
    searchBy?: string,
    sortBy?: string,
    sortOrder?: 'asc' | 'desc',
  ) {
    const { isPending, data: paginatedUsers } = useEligibleProjectUsersQuery(
      projectId,
      page,
      entriesPerPage,
      searchBy,
      sortBy,
      sortOrder,
    );

    return { isPending, paginatedUsers };
  }

  return (
    <Container className="h-full py-4" variant="box">
      {isLicenseExpired && <LicenseExpiredMessage />}
      <div className="py-4">
        <div className="flex flex-wrap justify-between">
          <div className="flex flex-col gap-1">
            <h1 className="text-2xl font-semibold tracking-wide">
              {t('project:users.title')}
            </h1>
            <p className="font-normal text-shuttleGray-600">
              {t('project:users.accessInfo')}
            </p>
          </div>
          <div className="self-end">
            {isDemoProject ? (
              <Tooltip content={t('project:users.noInviteForDemo')}>
                <span>
                  <Button variant="secondary" disabled>
                    <FontAwesomeIcon icon={faPlus} />
                    {t('project:users.inviteGuest')}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <div className="mt-2 flex gap-4">
                <Button
                  variant="secondary"
                  onClick={() => setIsInviteModalOpen(true)}
                  disabled={isLicenseExpired}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                  <span>{t('project:users.inviteGuest')} </span>
                </Button>
                {!isPersonalCompany && (
                  <Button
                    variant="primary"
                    onClick={() => setIsCompanyInviteModalOpen(true)}
                    disabled={isLicenseExpired}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <span>{t('project:users.inviteGuestFromCompany')} </span>
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        <SearchSort
          searchBy={searchUsersBy}
          sortBy={
            sortOptions.find(
              (sortOption) => sortOption.value === sortUsersBy,
            ) || sortOptions[0]
          }
          sortOrder={sortUsersOrder}
          sortOptions={sortOptions}
          onSearchBy={(newSearchBy) => setSearchUsersBy(newSearchBy)}
          onSortBy={(newSortBy) => setSortUsersBy(newSortBy)}
          onSortOrder={(newSortOrder) => setSortUsersOrder(newSortOrder)}
          className="mr-4"
        />
        <div className="mt-6 w-full">
          {usersIsLoading ? (
            <Spinner containerClassName="w-full h-full my-[160px] text-center" />
          ) : (
            <table className="relative w-full divide-y divide-gray-200 shadow">
              <thead>
                <tr>
                  <th scope="col" className="table-th">
                    {t('common:labels.name')}
                  </th>
                  <th scope="col" className="table-th hidden xl:table-cell">
                    {t('users.position')}
                  </th>
                  <th scope="col" className="table-th">
                    {t('users.role')}
                  </th>
                  <th scope="col" className="table-th">
                    <span className="sr-only">Guest</span>
                  </th>
                </tr>
              </thead>

              <tbody className="table-body">
                <>
                  {arrangedUserList.map((user) => (
                    <UserRow
                      key={user._id}
                      isCurrentUser={currentUserId === user._id}
                      user={user}
                      users={users}
                      currentProject={project}
                      isDemoProject={isDemoProject}
                      disabled={isLicenseExpired}
                    />
                  ))}
                  {invitations.map((invite) => (
                    <InvitationRow
                      key={invite._id}
                      invite={invite}
                      disabled={isLicenseExpired}
                    />
                  ))}
                </>
              </tbody>
            </table>
          )}
        </div>
        <InviteMembersModal
          users={users}
          invitations={invitations}
          techCrews={techCrews}
          isOpen={isInviteModalOpen}
          onClose={() => setIsInviteModalOpen(false)}
        />
        <AddMembersModal
          CallGetUsersHook={CallGetUsersHook}
          callAddMembers={async (
            newMemberIds: string[],
            assignedTechCrewId?: string,
            techCrewPermissions?: TechCrewPermission[],
          ) => {
            await addUsersToProject(
              project._id,
              newMemberIds,
              assignedTechCrewId,
              techCrewPermissions,
            );
            queryClient.invalidateQueries({
              queryKey: ['projects', projectId],
            });
          }}
          isOpen={isCompanyInviteModalOpen}
          onBack={() => setIsCompanyInviteModalOpen(false)}
          onClose={() => setIsCompanyInviteModalOpen(false)}
          techCrews={techCrews}
        />
      </div>
    </Container>
  );
};

const InvitationRow = ({
  invite,
  disabled,
}: {
  invite: ProjectInvitation;
  disabled: boolean;
}) => {
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();

  const handleDelete = async () => {
    try {
      await deleteInvitation(invite.project, invite._id);
      toast.success(t('common:success.delete'));
      queryClient.invalidateQueries({
        queryKey: ['projects', invite.project, 'invitations'],
      });
    } catch (e) {
      toast.error(t('common:errors.delete'));
    }
  };

  return (
    <tr key={invite._id}>
      <td className="table-td">
        <div className="flex items-center">
          <div className="h-8 w-8 rounded-full border border-dashed border-gray-300" />
          <div className="ml-4 overflow-hidden py-1">
            <div className="truncate text-base font-semibold">--</div>
            <div className="flex items-center truncate text-base text-shuttleGray-600">
              {invite.email}
              <Badge className="ml-2" variant="teal">
                {t('users.pending')}
              </Badge>
            </div>
          </div>
        </div>
      </td>
      <td className="table-td hidden xl:table-cell" />
      <td className="table-td">{t('users.normalUser')}</td>
      <td className="table-td" />
      <td className="table-td">
        <div className="flex items-center justify-end">
          <Tooltip content={t('users.userIsGuest')}>
            <Badge variant="teal"> {t('users.guest')}</Badge>
          </Tooltip>
          <Tooltip content={t('users.removeUser')}>
            <ActionIcon
              onClick={handleDelete}
              className="ml-6"
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faTrash} />
            </ActionIcon>
          </Tooltip>
        </div>
      </td>
    </tr>
  );
};

const userRoleLabelMap = {
  [UserRole.OWNER]: 'users.owner',
  [UserRole.ADMIN]: 'users.admin',
  [UserRole.USER]: 'users.normalUser',
} as const;

const UserRow = ({
  user,
  users,
  currentProject,
  isCurrentUser,
  isDemoProject,
  disabled,
}: {
  user: ProjectUser;
  users: ProjectUser[];
  currentProject: GetProjectResponse;
  isCurrentUser: boolean;
  isDemoProject: boolean;
  disabled: boolean;
}) => {
  const { projectId = '' } = useParams();
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [newRole, setNewRole] = useState<UserRole | null>(null);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isConfirmRoleChangeOpen, setIsConfirmRoleChangeOpen] = useState(false);

  const isOwner = user._id === currentProject.owner;
  const isCurrentUserOwner = isCurrentUser && isOwner;
  const hasOtherMembers = users.length > 1;
  const canTransferOwnership = isCurrentUserOwner && hasOtherMembers;
  const isGuest = !user.isInProjectCompany && !isDemoProject;

  const handleChangeRoleConfirmed = async () => {
    const adminIds = users
      .filter((u) => u.role === UserRole.ADMIN)
      .map((u) => u._id);
    const newAdminIds =
      newRole === UserRole.ADMIN
        ? [...adminIds, user._id]
        : adminIds.filter((a) => a !== user._id);
    try {
      await updateProject(projectId, { admins: newAdminIds });
      await queryClient.invalidateQueries({
        queryKey: ['projects', projectId],
      });
      // if admin made themself a user, then update store and redirect
      if (isCurrentUser && newRole === UserRole.USER) {
        navigate('..');
      }
      toast.success(t('common:success.save'));
    } catch (error) {
      toast.error(t('common:errors.save'));
    }
    setNewRole(null);
  };

  const handleDeleteConfirmed = async () => {
    try {
      await deleteUser(projectId, user._id);
      await queryClient.invalidateQueries({
        queryKey: ['projects', projectId],
      });
      toast.success(t('common:success.delete'));
    } catch (error) {
      toast.error(t('common:errors.delete'));
    }
  };

  const renderUserRole = () => {
    if (user.role === UserRole.OWNER || isDemoProject) {
      return t(userRoleLabelMap[user.role]);
    }

    return (
      <SelectNative
        value={user.role}
        onChange={(e) => {
          const parsedRole =
            e.target.value === UserRole.ADMIN.toString()
              ? UserRole.ADMIN
              : UserRole.USER;
          setNewRole(parsedRole);
          setIsConfirmRoleChangeOpen(true);
        }}
        disabled={disabled}
      >
        <option value={UserRole.ADMIN}>
          {t(userRoleLabelMap[UserRole.ADMIN])}
        </option>
        <option value={UserRole.USER}>
          {t(userRoleLabelMap[UserRole.USER])}
        </option>
      </SelectNative>
    );
  };

  return (
    <tr>
      <td className="table-td">
        <div className="flex items-center">
          <Avatar
            size="sm"
            alt={`${user.name} ${user.surname}`}
            // src={user.image?.key}
          />

          <div className="ml-4 overflow-hidden py-1">
            <div className="max-w-[300px] truncate text-base font-semibold">
              {[user.name, user.surname].join(' ')}
            </div>
            <div className="truncate text-base text-shuttleGray-600">
              {user.email}
            </div>
          </div>
        </div>
      </td>
      <td className="table-td hidden xl:table-cell">{user.position}</td>
      <td className="table-td ">{renderUserRole()}</td>
      <td className="table-td ">
        {canTransferOwnership ? (
          <TransferOwnershipButton
            projectId={currentProject._id}
            userId={user._id}
          />
        ) : (
          <div className="flex items-center justify-end">
            {isGuest && (
              <Tooltip content={t('users.userIsGuest')}>
                <Badge variant="teal"> {t('users.guest')}</Badge>
              </Tooltip>
            )}

            {/* !TODO: The following part is generating the react render issue */}
            {/* Warning: Can't perform a React state update on an unmounted component.
             * This is a no-op, but it indicates a memory leak in your application.
             * To fix, cancel all subscriptions and asynchronous
             *  tasks in a useEffect cleanup function. */}
            {!isDemoProject && !isOwner && (
              <Tooltip content={t('users.removeUser')}>
                <ActionIcon
                  onClick={() => setIsConfirmDeleteOpen(true)}
                  className="ml-6"
                  disabled={disabled}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ActionIcon>
              </Tooltip>
            )}
          </div>
        )}
      </td>
      <DeleteModal
        isOpen={isConfirmDeleteOpen}
        title={t('users.removeUser')}
        subtitle={t('users.removeUserText')}
        onClose={() => setIsConfirmDeleteOpen(false)}
        deleteButton={() => {
          handleDeleteConfirmed();
          setIsConfirmDeleteOpen(false);
        }}
      />
      <Modal isOpen={isConfirmRoleChangeOpen}>
        <ConfirmationDialog
          onCancel={() => {
            setNewRole(null);
            setIsConfirmRoleChangeOpen(false);
          }}
          title={t('users.changeRole')}
          message={
            user.role === UserRole.ADMIN
              ? t('users.changeRoleTextAdmin')
              : t('users.changeRoleTextUser')
          }
          onConfirm={() => {
            handleChangeRoleConfirmed();
            setIsConfirmRoleChangeOpen(false);
          }}
        />
      </Modal>
    </tr>
  );
};

const InviteMembersModal = ({
  users,
  invitations,
  techCrews,
  isOpen,
  onClose,
}: {
  users: ProjectUser[];
  invitations: ProjectInvitation[];
  techCrews: TechCrew[] | undefined;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    email: string;
  }>();
  const { projectId = '' } = useParams();
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();

  const [selectedTechCrew, setSelectedTechCrew] =
    useState<SelectSitelifeOptionType>();
  const [techCrewPermissions, setTechCrewPermissions] =
    useState<Record<TechCrewPermission, boolean>>();

  const techCrewOptions = techCrews?.map((techCrew) => {
    return { label: techCrew.name, value: techCrew._id };
  });

  const selectedPermissions =
    techCrewPermissions &&
    (Object.keys(techCrewPermissions).filter(
      (key) =>
        techCrewPermissions &&
        techCrewPermissions[key as TechCrewPermission] === true,
    ) as TechCrewPermission[]);

  const mutation = useMutation({
    mutationFn: ({ email }: { email: string }) =>
      inviteGuest(
        projectId,
        email,
        selectedTechCrew?.value,
        selectedPermissions,
        i18n.language,
      ),
    onSuccess() {
      toast.success(t('users.inviteSuccessful'));
      queryClient.invalidateQueries({
        queryKey: ['projects', projectId],
      });
      reset({ email: '' });
      setSelectedTechCrew(undefined);
      setTechCrewPermissions(undefined);
    },
    onError() {
      toast.error(t('users.inviteError'));
    },
  });

  const existingEmails = [
    ...users.map((u) => u.email.toLowerCase()),
    ...invitations.map((i) => i.email.toLowerCase()),
  ];

  function sendInvite(formData: { email: string }) {
    const normalizedEmail = formData.email.toLowerCase();
    if (existingEmails.includes(normalizedEmail)) {
      toast.error(t('users.userAlreadyHasAccess'));
      return;
    }
    if (selectedTechCrew && !techCrewPermissions) {
      toast.error(t('users.permissionsNotSpecified'));
      return;
    }
    mutation.mutate({
      email: normalizedEmail,
    });
    onClose();
  }

  const closeModal = () => {
    setSelectedTechCrew(undefined);
    setTechCrewPermissions(undefined);
    reset({ email: '' });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} className="w-[700px]">
      <Modal.Header onClose={closeModal}>{t('users.inviteGuest')}</Modal.Header>
      <Modal.Body>
        <form
          onSubmit={handleSubmit(sendInvite)}
          className="-mt-2 flex flex-col gap-6"
        >
          <p className="text-shuttleGray-600">
            {t('users.projectUsersDescription')}
          </p>
          <TextInput
            {...register('email', {
              required: true,
              pattern: EMAIL_REGEX,
            })}
            label={t('common:labels.email')}
            type="email"
            className="w-full"
            error={errors.email && 'ungültige email'}
            autoComplete="off"
            required
          />
          <div>
            <span>{t('users.techCrew')}</span>
            <SelectSitelife
              options={techCrewOptions}
              value={selectedTechCrew}
              onChange={(option: SelectSitelifeOptionType) => {
                setSelectedTechCrew(option);
                if (!option) {
                  // when selection is cleared
                  setTechCrewPermissions(undefined);
                }
              }}
              isClearable
              className="w-full"
            />
          </div>

          {selectedTechCrew && (
            <TechCrewPermissionsEditor
              onPermissionsChange={setTechCrewPermissions}
            />
          )}

          <div className="ml-auto flex gap-2">
            <Button variant="tertiary" onClick={onClose}>
              {t('common:button.close')}
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={mutation.isPending || !!errors.email}
            >
              {t('users.inviteGuest')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const TechCrewPermissionsEditor = ({
  onPermissionsChange,
}: {
  onPermissionsChange: (
    permissions: Record<TechCrewPermission, boolean>,
  ) => void;
}) => {
  const { t } = useTranslation(['project', 'common']);

  const [viewDocumentation, setViewDocumentation] = useState(true);
  const [editDocumentation, setEditDocumentation] = useState(true);
  const [controlDocumentation, setControlDocumentation] = useState(false);
  const [exportDataDocumentation, setExportDataDocumentation] = useState(false);
  const [isDocumentationChecked, setIsDocumentationChecked] = useState(false);
  const [isViewDocumentationRequired, setIsViewDocumentationRequired] =
    useState(false);
  const [isDocumentationIndeterminate, setIsDocumentationIndeterminate] =
    useState(false);

  useEffect(() => {
    const allDocumentationChecked =
      viewDocumentation &&
      editDocumentation &&
      controlDocumentation &&
      exportDataDocumentation;

    const documentationIndeterminate = viewDocumentation
      ? !editDocumentation || !controlDocumentation || !exportDataDocumentation
      : editDocumentation || controlDocumentation || exportDataDocumentation;

    const needsViewDocumentation =
      editDocumentation || controlDocumentation || exportDataDocumentation;

    allDocumentationChecked
      ? setIsDocumentationChecked(true)
      : setIsDocumentationChecked(false);

    documentationIndeterminate
      ? setIsDocumentationIndeterminate(true)
      : setIsDocumentationIndeterminate(false);

    needsViewDocumentation
      ? setIsViewDocumentationRequired(true)
      : setIsViewDocumentationRequired(false);

    onPermissionsChange({
      [TechCrewPermission.VIEW_DOCUMENTATION]: viewDocumentation,
      [TechCrewPermission.EDIT_DOCUMENTATION]: editDocumentation,
      [TechCrewPermission.CONTROL_DOCUMENTATION]: controlDocumentation,
      [TechCrewPermission.EXPORT_DATA_DOCUMENTATION]: exportDataDocumentation,
      [TechCrewPermission.VIEW_ANALYSIS]: false,
      [TechCrewPermission.EDIT_ANALYSIS]: false,
    });
  }, [
    viewDocumentation,
    editDocumentation,
    controlDocumentation,
    exportDataDocumentation,
    onPermissionsChange,
  ]);

  return (
    <div className="flex flex-col justify-between space-y-4 bg-gray-100 p-6">
      <ul className="flex flex-col space-y-[1px]">
        <PermissionListHeader
          label={t(
            'project:techCrewEdit.membersView.editPermissions.documentation',
          )}
          checked={isDocumentationChecked}
          indeterminate={isDocumentationIndeterminate}
          onClick={(flag) => {
            setViewDocumentation(flag);
            setEditDocumentation(flag);
            setControlDocumentation(flag);
            setExportDataDocumentation(flag);
          }}
        />
        {isViewDocumentationRequired ? (
          <PermissionsListItem
            checked={true}
            disabled={true}
            label={t(
              'project:techCrewEdit.membersView.editPermissions.viewDocumentation',
            )}
            icon={faEye}
          />
        ) : (
          <PermissionsListItem
            checked={viewDocumentation}
            label={t(
              'project:techCrewEdit.membersView.editPermissions.viewDocumentation',
            )}
            icon={faEye}
            handleChange={() => setViewDocumentation(!viewDocumentation)}
          />
        )}
        <PermissionsListItem
          checked={editDocumentation}
          label={t(
            'project:techCrewEdit.membersView.editPermissions.editDocumentation',
          )}
          icon={faPlusSquare}
          handleChange={() => setEditDocumentation(!editDocumentation)}
        />
        <PermissionsListItem
          checked={controlDocumentation}
          label={t(
            'project:techCrewEdit.membersView.editPermissions.controlDocumentation',
          )}
          icon={faCheckSquare}
          handleChange={() => setControlDocumentation(!controlDocumentation)}
        />
        <PermissionsListItem
          checked={exportDataDocumentation}
          label={t(
            'project:techCrewEdit.membersView.editPermissions.exportDataDocumentation',
          )}
          icon={faFileExport}
          handleChange={() =>
            setExportDataDocumentation(!exportDataDocumentation)
          }
        />
      </ul>
    </div>
  );
};

const PermissionsListItem = ({
  label,
  icon,
  disabled = false,
  checked,
  handleChange,
}: {
  label: string;
  icon: IconDefinition;
  disabled?: boolean;
  checked?: boolean;
  handleChange?: () => void;
}) => {
  return (
    <li className="flex justify-between bg-white p-4 shadow">
      <div>
        <FontAwesomeIcon icon={icon} className="text-shuttleGray-500" />
        <span className="ml-3">{label}</span>
      </div>
      <Checkbox checked={checked} disabled={disabled} onChange={handleChange} />
    </li>
  );
};

const TransferOwnershipButton = ({
  projectId,
  userId,
}: {
  projectId: string;
  userId: string;
}) => {
  const { t } = useTranslation('project');
  const [showTransferOwnerModal, setShowTransferOwnerModal] = useState(false);

  return (
    <div className="flex items-center justify-end">
      <Button
        size="sm"
        variant="tertiary"
        onClick={() => setShowTransferOwnerModal(true)}
      >
        {t('transferOwner.title')}
      </Button>
      {showTransferOwnerModal ? (
        <TransferOwnerModal
          isOpen={showTransferOwnerModal}
          userId={userId}
          projectId={projectId}
          onClose={() => setShowTransferOwnerModal(false)}
        />
      ) : null}
    </div>
  );
};
