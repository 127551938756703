import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Input } from '../Input/Input';
import { InputWrapper } from '../Wrapper/InputWrapper';

export interface PasswordStrengthInputProps
  extends ComponentPropsWithoutRef<'input'> {
  id?: string;
  label?: ReactNode;
  description?: ReactNode;
  error?: string | boolean;
  required?: boolean;
  wrapperProps?: { [key: string]: any };
}

export const PasswordStrengthInput = forwardRef<
  HTMLInputElement,
  PasswordStrengthInputProps
>(function PasswordStrengthInput(props: PasswordStrengthInputProps, ref) {
  const {
    className,
    id,
    label,
    error,
    required,
    disabled,
    style,
    placeholder,
    description,
    wrapperProps,
    ...rest
  } = props;

  const [reveal, toggle] = useState(false);

  const pool = {
    digits: 10,
    lowercase: 26,
    uppercase: 26,
    symbols: 32,
  } as const;

  const lowercaseRegex = /[a-z]/;
  const uppercaseRegex = /[A-Z]/;
  const digitsRegex = /[0-9]/;
  const symbolsRegex = /[~`!@#$%^&*()_¿\-+={[}\]|:;"'<,>.?]/;

  const getPasswordStrength = (currentPassword: string) => {
    const length = currentPassword.length;
    let characterSetSize = 0;

    if (digitsRegex.test(currentPassword)) {
      characterSetSize += pool.digits;
    }

    if (lowercaseRegex.test(currentPassword)) {
      characterSetSize += pool.lowercase;
    }

    if (uppercaseRegex.test(currentPassword)) {
      characterSetSize += pool.uppercase;
    }

    if (symbolsRegex.test(currentPassword)) {
      characterSetSize += pool.symbols;
    }
    const strengthScore = length * Math.log2(characterSetSize);

    return Math.round(strengthScore);
  };

  const passwordStrength = getPasswordStrength(String(rest.value));

  return (
    <div className="relative">
      <PasswordStrengthLabel passwordStrength={passwordStrength} />
      <div>
        <InputWrapper
          id={id}
          required={required}
          aria-disabled={disabled}
          label={label}
          error={error}
          description={description}
          className={className}
          style={style}
          {...wrapperProps}
        >
          <div className="mb-3 w-full">
            <PasswordStrengthMeter passwordStrength={passwordStrength} />
          </div>
          <div className="relative flex-auto">
            <Input
              {...rest}
              required={required}
              disabled={disabled}
              invalid={!!error}
              ref={ref}
              id={id}
              type={reveal ? 'text' : 'password'}
              placeholder={placeholder}
              error={error}
              className="pr-10"
            />
            <ActionIcon
              aria-hidden
              onClick={() => toggle(!reveal)}
              type="button"
              className="absolute inset-y-1 right-1 text-shuttleGray-500"
            >
              {reveal ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </ActionIcon>
          </div>
        </InputWrapper>
      </div>
    </div>
  );
});

const PasswordStrengthLabel = ({
  passwordStrength,
}: {
  passwordStrength: number;
}) => {
  const { t } = useTranslation('auth');
  return (
    <div className="absolute right-0">
      {passwordStrength >= 0 && passwordStrength <= 24 ? (
        <span className="text-red-500">{t('passwordStrength.veryWeak')}</span>
      ) : undefined}

      {passwordStrength >= 25 && passwordStrength <= 49 ? (
        <span className="text-red-500">{t('passwordStrength.weak')}</span>
      ) : undefined}

      {passwordStrength >= 50 && passwordStrength <= 74 ? (
        <span className="text-orange-500">{t('passwordStrength.medium')}</span>
      ) : undefined}

      {passwordStrength >= 75 && passwordStrength <= 99 ? (
        <span className="text-green-500">{t('passwordStrength.strong')}</span>
      ) : undefined}

      {passwordStrength >= 100 ? (
        <span className="text-green-500">
          {t('passwordStrength.veryStrong')}
        </span>
      ) : undefined}
    </div>
  );
};

const PasswordStrengthMeter = ({
  passwordStrength,
}: {
  passwordStrength: number;
}) => {
  const barSize = 5;
  const bars = Array.from(Array(barSize)).map((_, index) => {
    let barColor = '';
    if (passwordStrength >= (index + 1) * 20) {
      if (passwordStrength >= 0 && passwordStrength <= 24) {
        barColor = 'bg-red-500';
      } else if (passwordStrength >= 25 && passwordStrength <= 49) {
        barColor = 'bg-red-500';
      } else if (passwordStrength >= 50 && passwordStrength <= 74) {
        barColor = 'bg-orange-500';
      } else if (passwordStrength >= 75) {
        barColor = 'bg-green-500';
      }
    } else {
      barColor = 'bg-gray-300';
    }
    return <div key={index} className={`mr-1 h-2 w-1/5 rounded ${barColor}`} />;
  });

  return <div className="flex">{bars}</div>;
};
