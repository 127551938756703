import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { cn } from 'src/common/utils';

type Props = { onClick: () => void; disabled: boolean };

export const CreateSheetSetButton = ({ onClick, disabled }: Props) => {
  const { t } = useTranslation('sheetset');

  const classes = cn(
    'border-dashed border rounded-sm flex items-center justify-center w-full h-24',
    {
      'border-gray-500 text-gray-500': disabled,
      'border-blue-600 text-blue-600 hover:border-blue-700 hover:bg-blue-50 transition-colors focus:bg-blue-100 focus:outline-none h-24 ':
        !disabled,
    },
  );

  return (
    <button className={classes} onClick={onClick} disabled={disabled}>
      <div className="flex items-center gap-2 font-medium">
        <FontAwesomeIcon icon={faPlus} /> {t('newSheetSet')}
      </div>
    </button>
  );
};
