import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from 'src/auth/user-context';
import { Spinner } from 'src/common/components/Spinner';
import { useCompaniesQuery } from 'src/company/queries';
import { getAllProjects } from 'src/project/api/projectsApi';
import { ProjectListActionBar } from './ActionBar';
import { ProjectListCompanySection } from './CompanySection';
import {
  filterAndSortProjects,
  filterProjectsByExpirationAndLicense,
  getProjectFilterOptions,
  groupProjectsByCompany,
} from './utils';
import { CreateFirstProjectModal } from '../CreateProject/CreateFirstProjectModal';
import { CreateCompanyButton } from 'src/company/components/CreateCompanyButton';
import { ProjectFilter } from 'src/project/types/projects';

const sessionKey = 'projectListSearchFilterAndSort';

export const ProjectList = () => {
  const { t } = useTranslation('project');
  const user = useUser();

  const { data: projects, isPending: isLoadingProjects } = useQuery({
    queryKey: ['projects'],
    queryFn: getAllProjects,
    initialData: [],
    staleTime: 0,
  });
  const { data: companies, isPending: isLoadingCompany } = useCompaniesQuery();

  const {
    search,
    setSearch,
    selectedFilters,
    setSelectedFilters,
    sortProperty,
    setSortProperty,
    sortDirection,
    setSortDirection,
  } = useSearchFilterAndSort();

  if (isLoadingProjects || isLoadingCompany) {
    return (
      <Spinner
        className="text-2xl"
        containerClassName="w-full my-[160px] text-center"
      />
    );
  }

  const filterOptions = getProjectFilterOptions(
    projects,
    t('listView.archived.label'),
  );

  const filteredProjectsByExpirationAndLicense =
    filterProjectsByExpirationAndLicense(projects, selectedFilters);

  const filteredAndSortedProjects = filterAndSortProjects(
    filteredProjectsByExpirationAndLicense,
    search,
    sortDirection,
    sortProperty,
  );

  const projectsByCompany = groupProjectsByCompany(
    filteredAndSortedProjects,
    user,
    companies,
  );

  const handleSelectedFiltersChange = (newFilterValues: ProjectFilter[]) => {
    const filterSet = [...new Set(newFilterValues)];
    setSelectedFilters(filterSet);
    sessionStorage.setItem(
      sessionKey,
      JSON.stringify({
        selectedFilters: filterSet,
        property: 'createdAt',
        direction: sortDirection,
      }),
    );
  };

  const handleSortPropertyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'createdAt') {
      setSortProperty('createdAt');
      sessionStorage.setItem(
        sessionKey,
        JSON.stringify({
          selectedFilters,
          property: 'createdAt',
          direction: sortDirection,
        }),
      );
    } else {
      setSortProperty('name');
      sessionStorage.setItem(
        sessionKey,
        JSON.stringify({
          selectedFilters,
          property: 'name',
          direction: sortDirection,
        }),
      );
    }
  };

  const handleToggleSortingDirection = () => {
    return setSortDirection((prev) => {
      const newDirection = prev === 'asc' ? 'desc' : 'asc';
      sessionStorage.setItem(
        sessionKey,
        JSON.stringify({
          selectedFilters,
          property: sortProperty,
          direction: newDirection,
        }),
      );
      return newDirection;
    });
  };

  return (
    <>
      <div className="flex flex-col gap-8">
        <div className="flex items-end justify-between">
          <div className=" inline-flex items-end ">
            <ProjectListActionBar
              search={search}
              onSearchChange={(e) => setSearch(e.currentTarget.value)}
              filterOptions={filterOptions}
              selectedFilters={selectedFilters}
              onSelectFilterChange={handleSelectedFiltersChange}
              sortProperty={sortProperty}
              onSortPropertyChange={handleSortPropertyChange}
              sortDirection={sortDirection}
              onToggleSortDirection={handleToggleSortingDirection}
            />
          </div>
          <CreateCompanyButton />
        </div>
        <div className="space-y-12">
          {projectsByCompany.map((companyGroup) => (
            <ProjectListCompanySection
              key={companyGroup.name}
              companyGroup={companyGroup}
              disableCollapse={!!search}
            />
          ))}
        </div>
      </div>
      {user.showNewProjectModal && (
        <CreateFirstProjectModal
          firstName={user.name}
          personalCompanyId={user.personalCompany}
        />
      )}
    </>
  );
};

function useSearchFilterAndSort() {
  const projectListSort = sessionStorage.getItem(sessionKey);
  const value = projectListSort
    ? JSON.parse(projectListSort)
    : { selectedFilters: [], property: 'name', direction: 'asc' };

  const [search, setSearch] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<ProjectFilter[]>(
    value.selectedFilters,
  );
  const [sortProperty, setSortProperty] = useState<'name' | 'createdAt'>(
    value.property,
  );
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(
    value.direction,
  );

  return {
    search,
    setSearch,
    selectedFilters,
    setSelectedFilters,
    sortProperty,
    setSortProperty,
    sortDirection,
    setSortDirection,
  };
}
