export class ExtensionArray {
  static instance: ExtensionArray;
  extensions: string[] = [];

  constructor() {
    if (ExtensionArray.instance) {
      return ExtensionArray.instance;
    }

    ExtensionArray.instance = this;

    this.extensions = [];

    return this;
  }

  getExtensions() {
    return this.extensions;
  }

  push(x: string) {
    this.extensions.push(x);
    return this.extensions;
  }
}
