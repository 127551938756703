import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

import { Button } from 'src/common/components/buttons/Button';
import toast from 'src/common/toast';
import { completeSignUp } from '../../api';
import { AuthLayout } from '../../components/AuthLayout';

export const SignUpCompleteView = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  if (!token) {
    return <NoTokenView />;
  }
  return <CheckTokenView token={token} />;
};

const NoTokenView = () => {
  const { t } = useTranslation(['auth', 'common']);
  return (
    <AuthLayout>
      <div className="px-8">
        <h1 className="mb-2 mt-2 text-xl font-semibold">
          {t('common:errors.generic')}
        </h1>

        <p className="text-shuttleGray-600">
          {t('auth:signUp.complete.error')}
        </p>

        <BackToLoginButton />
      </div>
    </AuthLayout>
  );
};

const CheckTokenView = ({ token }: { token: string }) => {
  const { t } = useTranslation(['auth', 'common']);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const createUserInBackend = async () => {
      try {
        setIsLoading(true);
        await completeSignUp(token);
        amplitude.track('signup: complete');
        toast.success(t('common:success.create'));
      } catch (e) {
        // user may have clicked the link twice, that's ok
      } finally {
        setIsLoading(false);
      }
    };
    createUserInBackend();
  }, [t, token]);

  return (
    <AuthLayout>
      <div className="px-8">
        <h1 className="mb-2 mt-2 text-xl font-semibold">{t('auth:thanks')}</h1>
        <p className="text-shuttleGray-600">
          {isLoading
            ? t('auth:signUp.complete.creatingAccount')
            : t('auth:signUp.complete.accountCreated')}
        </p>
        <BackToLoginButton loading={isLoading} />
      </div>
    </AuthLayout>
  );
};

const BackToLoginButton = ({ loading }: { loading?: boolean }) => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  return (
    <Button
      className="mt-6 w-full"
      onClick={() => navigate('/')}
      loading={loading}
    >{`${t('signUp.toLogin')}`}</Button>
  );
};
