import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useState, useEffect } from 'react';

import { CompanyCreateForm } from 'src/admin/components/company/CompanyCreateForm';
import { SearchSort } from 'src/common/components/SearchSort';
import { Button } from 'src/common/components/buttons/Button';
import { Pagination } from 'src/common/components/Pagination';
import { Container } from 'src/common/components/Container';
import { useAdminCompaniesQuery } from '../queries';
import { CompanyRow } from '../components/company/CompanyRow';
import { Card } from 'src/common/components/Card';
import { Modal } from 'src/common/components/Modal';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Spinner } from 'src/common/components/Spinner';

export const CompaniesView = () => {
  const { t } = useTranslation(['admin', 'common']);

  const [searchBy, setSearchBy] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [isCreateCompanyModalOpen, setIsCreateCompanyModalOpen] =
    useState(false);

  const { data: paginatedCompanies, isPending } = useAdminCompaniesQuery(
    page,
    entriesPerPage,
    searchBy,
    sortBy,
    sortOrder,
  );

  // reset page to 1 when search changes
  useEffect(() => {
    setPage(1);
  }, [searchBy]);

  const sortOptions: SelectSitelifeOptionType[] = [
    { label: t('common:name'), value: 'name' },
    { label: t('common:description'), value: 'description' },
    { label: t('common:owner'), value: 'owner.name' },
    { label: t('common:city'), value: 'cityName' },
  ];

  const companies = paginatedCompanies ? paginatedCompanies.data : [];

  return (
    <Container className="h-full" variant="box">
      <h1 className="text-2xl font-semibold tracking-wide">
        {t('common:organizations')}
      </h1>
      <div className="flex w-full items-end">
        <SearchSort
          searchBy={searchBy}
          sortBy={
            sortOptions.find((sortOption) => sortOption.value === sortBy) ||
            sortOptions[0]
          }
          sortOrder={sortOrder}
          sortOptions={sortOptions}
          onSearchBy={(newSearchBy) => setSearchBy(newSearchBy)}
          onSortBy={(newSortBy) => setSortBy(newSortBy)}
          onSortOrder={(newSortOrder) => setSortOrder(newSortOrder)}
          className="mr-4"
        />
        <Button
          className="ml-auto"
          onClick={() => setIsCreateCompanyModalOpen(true)}
        >
          <FontAwesomeIcon icon={faPlus} />
          {t('organizations.createOrganization')}
        </Button>
      </div>
      <Card className="mt-6 overflow-auto">
        {isPending ? (
          <Spinner containerClassName="w-full my-[160px] text-center" />
        ) : companies.length > 0 ? (
          <table className="relative w-full divide-y divide-gray-200 shadow">
            <thead>
              <tr>
                <th scope="col" className="table-th">
                  {t('common:name')}
                </th>
                <th scope="col" className="table-th">
                  {t('common:description')}
                </th>
                <th scope="col" className="table-th">
                  {t('common:owner')}
                </th>
                <th scope="col" className="table-th">
                  {t('common:zipCode')}
                </th>
                <th scope="col" className="table-th">
                  {t('common:city')}
                </th>
                <th scope="col" className="table-th xl:table-cell" />
              </tr>
            </thead>
            <tbody className="table-body">
              {companies.map((company) => (
                <CompanyRow key={company._id} company={company} />
              ))}
            </tbody>
          </table>
        ) : (
          <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
            <span className="font-semibold text-shuttleGray-800">
              {`${searchBy}`}
            </span>{' '}
            {t('common:notFound.message')}
          </p>
        )}
      </Card>
      {companies.length > 0 && !isPending && paginatedCompanies && (
        <Pagination
          maxPage={Math.ceil(paginatedCompanies.totalCount / entriesPerPage)}
          page={page}
          onChangePage={setPage}
          entriesPerPage={entriesPerPage}
          onChangeEntriesPerPage={setEntriesPerPage}
          className="mt-4"
        />
      )}
      <Modal
        className="max-w-[781px]"
        isOpen={isCreateCompanyModalOpen}
        onRequestClose={() => setIsCreateCompanyModalOpen(false)}
      >
        <Modal.Header onClose={() => setIsCreateCompanyModalOpen(false)}>
          {t('admin:organizations.createModalTitle')}
        </Modal.Header>
        <CompanyCreateForm onClose={() => setIsCreateCompanyModalOpen(false)} />
      </Modal>
    </Container>
  );
};
