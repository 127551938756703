import { useTranslation } from 'react-i18next';
import { OwnerCompanyPopulatedProject } from 'shared';

import { Badge } from 'src/common/components/Badge';

export const UserProjectRowRoleBadge = ({
  project,
  userId,
}: {
  project: OwnerCompanyPopulatedProject;
  userId: string;
}) => {
  const { t } = useTranslation('common');
  if (project.owner._id === userId) {
    return <Badge variant="orange">{t('owner')}</Badge>;
  }
  if (project.admins.includes(userId)) {
    return <Badge variant="blue">{t('admin')}</Badge>;
  }
  return <Badge variant="teal">{t('user')}</Badge>;
};
