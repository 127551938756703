import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Modal, useModal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';
import { createCompany } from 'src/company/api';
import {
  CompanyForm,
  CompanyFormValues,
} from 'src/company/components/CompanyForm';
import { logger } from 'src/logger';

export const CreateCompanyButton = () => {
  const { t } = useTranslation('project');
  const { isOpen, open, close } = useModal();

  return (
    <>
      <Button variant="secondary" onClick={open}>
        <FontAwesomeIcon icon={faPlus} />
        {t('listView.createCompany')}
      </Button>

      <CreateCompanyModal isOpen={isOpen} onClose={close} />
    </>
  );
};

function CreateCompanyModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<CompanyFormValues>({ mode: 'onBlur' });
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();

  async function handleSubmit(formValues: CompanyFormValues) {
    try {
      setIsLoading(true);
      await createCompany(formValues);
      await queryClient.invalidateQueries({
        queryKey: ['companies'],
      });
      toast.success(t('common:success.create'));
      setIsLoading(false);
      onClose();
    } catch (e) {
      logger.capture(e);
      toast.error(t('common:errors.create'));
    }
  }

  const isDisabled =
    Object.keys(form.formState.errors).length > 0 || !form.formState.isDirty;

  return (
    <Modal isOpen={isOpen} className="p-4">
      <div className="w-[480px]">
        <Modal.Header onClose={onClose}>{t('createModal.title')}</Modal.Header>
        <Modal.Body>
          <CompanyForm form={form} onSubmit={handleSubmit}>
            <Button variant="tertiary" type="reset" onClick={onClose}>
              {t('common:button.abort')}
            </Button>
            <Button
              type="submit"
              disabled={isDisabled}
              loading={isLoading}
              className="ml-auto"
            >
              {t('common:button.save')}
            </Button>
          </CompanyForm>
        </Modal.Body>
      </div>
    </Modal>
  );
}
