import { cn } from 'src/common/utils';

type Props = {
  children?: React.ReactNode;
  className?: string;
  variant?: 'box' | 'fluid' | 'box-sm';
};
export const Container = ({
  children,
  className,
  variant = 'fluid',
}: Props) => {
  return (
    <div className="h-full w-full px-3">
      <div
        className={cn(
          'mx-auto',
          {
            'max-w-[1320px]': variant === 'box',
          },
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};
