export class UnauthorizedError extends Error {
  constructor() {
    super('Request failed with code 401, logging out');
  }
}

export class ServerError extends Error {
  constructor(message?: string) {
    super(`Request failed with code 500. ${message}`);
  }
}

export class NotFoundError extends Error {
  constructor() {
    super(
      'Request failed with code 404. The resource you requested does not exist.',
    );
  }
}

export class BadRequestError extends Error {
  constructor(message?: string) {
    super(`Request failed with code 400. ${message}`);
  }
}

export class ForbiddenError extends Error {
  constructor(message?: string) {
    super(`Request failed with code 403. ${message}`);
  }
}

export class ParseError extends Error {
  constructor() {
    super('Could not parse response');
  }
}

/**
 * @deprecated
 * this only exists to handle "email in use error" on signup. server should respond with 400 instead
 */
export class AcceptedError extends Error {
  constructor() {
    super(
      'Accepted with code 202. The resource you requested is not yet available.',
    );
  }
}
