import { faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/common/components/buttons/Button';
import { Menu } from 'src/common/components/Menu';
import { Modal } from 'src/common/components/Modal';
import { FileType } from 'src/file/types';
import { TypeBadge } from '../../TypeBadge';
import { NewFileModal } from './Modal';

export const FileUploadNewFileButton = ({
  disabled,
}: {
  disabled: boolean;
}) => {
  const { t } = useTranslation(['file']);

  const [showMenu, setShowMenu] = useState(false);
  const [fileType, setModelFileType] = useState<
    FileType.REVIT | FileType.IFC | FileType.PDF_PLAN
  >();

  const renderUploadModal = () =>
    fileType && (
      <Modal isOpen={!!fileType} className="w-[672px]">
        <NewFileModal
          onClose={() => setModelFileType(undefined)}
          fileType={fileType}
        />
      </Modal>
    );

  const renderDropDown = () => (
    <Menu>
      <Menu.List>
        <Menu.Item onClick={() => setModelFileType(FileType.REVIT)}>
          <TypeBadge fileType={FileType.REVIT} />
          REVIT
        </Menu.Item>
        <Menu.Item onClick={() => setModelFileType(FileType.IFC)}>
          <TypeBadge fileType={FileType.IFC} />
          IFC
        </Menu.Item>
        <Menu.Item onClick={() => setModelFileType(FileType.PDF_PLAN)}>
          <TypeBadge fileType={FileType.PDF_PLAN} />
          PDF
        </Menu.Item>
      </Menu.List>
    </Menu>
  );

  return (
    <div>
      <Tippy
        content={renderDropDown()}
        placement="bottom-end"
        arrow={false}
        zIndex={1111}
        visible={showMenu}
        onClickOutside={() => setShowMenu(false)}
        interactive
      >
        <Button onClick={() => setShowMenu(!showMenu)} disabled={disabled}>
          <FontAwesomeIcon icon={faUpload} />
          <span>{t('file:upload.buttonText')} </span>
        </Button>
      </Tippy>
      {renderUploadModal()}
    </div>
  );
};
