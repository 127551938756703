import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faUndo } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';

import TradeColors from 'src/common/components/inputs/ColorPicker/TradeColors.json';
import { Button } from 'src/common/components/buttons/Button';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { ColorInput } from 'src/common/components/inputs/ColorPicker';
import toast from 'src/common/toast';
import { createTechCrew, updateTechCrew } from 'src/project/api/techCrewsApi';
import { getProperties, getViewDict } from 'src/project/utils';
import { TechCrew } from 'src/project/types/techCrews';
import { FileVersionViewDictionary } from 'src/file/types';
import { useActionGroupsQuery, useTechCrewsQuery } from 'src/project/queries';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { SelectSitelife } from 'src/common/components/SelectSitelife';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { SheetSet } from 'src/sheetset/types';
import { Tooltip } from 'src/common/components/Tooltip';
import { Toggle } from 'src/common/components/inputs/Toggle';
import { useSheetSetsQuery } from 'src/sheetset/queries';
import { useFilesQuery } from 'src/file/queries';
import { Spinner } from 'src/common/components/Spinner';

type FormValues = {
  name: string;
  color: string;
  description: string;
  controlled: boolean;
  sheetSets: string[];
  defaultViewId: string;
  categoryParameter: string;
  nameTemplate: string;
};

type Props = {
  techCrew?: TechCrew;
  projectId: string;
  isFreeProject: boolean;
  is3dProject: boolean;
  disabled?: boolean;
  onCancel?: () => void;
};

function useCategoriesQuery(
  appSheetSets: SheetSet[],
  viewDict: FileVersionViewDictionary,
  projectId: string,
  defaultViewId: string,
) {
  return useQuery({
    queryKey: ['projects', projectId, 'categories', defaultViewId],
    queryFn: () =>
      getProperties(appSheetSets, viewDict, defaultViewId, projectId),
  });
}

export const TechCrewForm = ({
  techCrew,
  projectId,
  isFreeProject,
  is3dProject,
  disabled,
  onCancel,
}: Props) => {
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();
  const { data: appSheetSets } = useSheetSetsQuery(projectId);
  const { data: techCrews } = useTechCrewsQuery(projectId);
  const { data: files } = useFilesQuery(projectId);
  const { data: actionGroups } = useActionGroupsQuery(
    projectId,
    techCrew?._id || '',
  );
  const viewDict = getViewDict(files);
  const [isSaving, setIsSaving] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isDirty, isValid, errors },
  } = useForm<FormValues>({
    defaultValues: {
      name: techCrew?.name,
      color: techCrew?.color,
      description: techCrew?.description,
      controlled: !!techCrew?.controlled,
      nameTemplate: techCrew?.nameTemplate,
      sheetSets: techCrew?.sheetSets || [],
      defaultViewId: techCrew?.defaultView?._id || '',
      categoryParameter: techCrew?.categoryParameter || '',
    },
  });

  const availableSheetSets = watch('sheetSets');

  const sheetSetOptions = appSheetSets?.map((sheetSet) => ({
    value: sheetSet._id,
    label: sheetSet.name,
  }));

  const viewOptions: SelectSitelifeOptionType[] = [];
  availableSheetSets.forEach((ss) => {
    const fullSheetSet = appSheetSets?.find((tmp) => tmp._id === ss);
    if (fullSheetSet && viewDict) {
      fullSheetSet.views.forEach((v) => {
        const view = viewDict[`${v.file}_${v.viewableId}`];
        if (view) {
          // only add view to options if none with same guid is in options
          if (!viewOptions.find((viewToAdd) => viewToAdd.guid === view.guid)) {
            viewOptions.push({ label: view.name, value: v._id });
          }
        }
      });
    }
  });

  const colors = TradeColors.map((pair) => pair.color);
  let disabledColors =
    techCrews
      ?.filter(
        (techCrewInUse) => !techCrew || techCrewInUse._id !== techCrew._id,
      )
      .map((techCrewMap) => techCrewMap.color) || [];
  if (colors.length <= disabledColors.length) {
    disabledColors = [];
  }

  const handleSave = async ({
    name,
    color,
    description,
    controlled,
    sheetSets,
    defaultViewId,
    categoryParameter,
    nameTemplate,
  }: FormValues) => {
    const defaultView = appSheetSets
      ?.map((sheetSet) => sheetSet.views)
      .flat()
      .find((fileVersionView) => fileVersionView._id === defaultViewId);

    const editedTechCrew = {
      name,
      color,
      description,
      controlled,
      sheetSets,
      ...(!!defaultView && { defaultView }),
      ...(!!categoryParameter && { categoryParameter }),
      nameTemplate,
    };

    setIsSaving(true);
    if (techCrew) {
      try {
        await updateTechCrew(projectId, techCrew._id, editedTechCrew);
        await queryClient.invalidateQueries({
          queryKey: ['projects', projectId, 'techCrews'],
        });
        toast.success(t('techCrew.updateTechCrewSuccess'));
        const gotSheetSets = sheetSets.length > 0;
        reset({
          ...editedTechCrew,
          defaultViewId: gotSheetSets
            ? defaultViewId || techCrew.defaultView?._id || ''
            : '',
          categoryParameter: gotSheetSets
            ? categoryParameter || techCrew.categoryParameter || ''
            : '',
        });
      } catch (error) {
        toast.error(t('techCrew.updateTechCrewFailure'));
      }
    } else {
      try {
        await createTechCrew(projectId, editedTechCrew);
        await queryClient.invalidateQueries({
          queryKey: ['projects'],
        });
        toast.success(t('techCrew.addTechCrewSuccess'));
        onCancel && onCancel();
      } catch (err) {
        toast.error(t('techCrew.addTechCrewFailure'));
      }
    }
    setIsSaving(false);
  };

  const showCategoriesSelect =
    is3dProject &&
    appSheetSets &&
    techCrews &&
    viewDict &&
    !!watch('defaultViewId');

  return (
    <form
      className="flex w-full flex-col gap-8"
      onSubmit={handleSubmit(handleSave)}
    >
      <div className="flex flex-col gap-4 overflow-y-auto">
        <div className="flex gap-4">
          <TextInput
            className="w-full"
            label={t('techCrew.form.name')}
            placeholder={t('techCrew.form.nameTechCrew')}
            required
            disabled={disabled}
            {...register('name')}
          />
          <ColorInput
            label={t('techCrew.form.color')}
            colors={colors}
            disabledColors={disabledColors}
            value={watch('color')}
            onChange={(event) =>
              setValue('color', event.target.value, { shouldDirty: true })
            }
            required
            disabled={disabled}
          />
        </div>

        <TextInput
          label={t('techCrew.form.description')}
          placeholder={t('techCrew.form.descriptionTechCrew')}
          disabled={disabled}
          {...register('description')}
        />

        {!isFreeProject && (
          <>
            <div className="flex items-center space-x-2">
              <Toggle
                label={t('techCrew.controlled.label')}
                {...register('controlled')}
                checked={watch('controlled')}
              />
              <Tooltip content={t('techCrew.controlled.info')}>
                <FontAwesomeIcon
                  className="text-shuttleGray-400"
                  icon={faInfoCircle}
                />
              </Tooltip>
            </div>
            <div>
              <span className="whitespace-nowrap">
                {t('techCrew.form.sheetSets')}
              </span>
              <Controller
                control={control}
                name="sheetSets"
                render={({ field }) => {
                  return (
                    <SelectSitelife
                      options={sheetSetOptions}
                      value={sheetSetOptions?.filter((o) =>
                        field.value.includes(o.value),
                      )}
                      onChange={(options: SelectSitelifeOptionType[]) => {
                        if (!techCrew) {
                          setValue('defaultViewId', '');
                          setValue('categoryParameter', '');
                        } else {
                          setValue('defaultViewId', '');
                        }
                        const sheetSetIds = options
                          .filter((o) => !!o.value)
                          .map((o) => o.value!);
                        setValue('sheetSets', sheetSetIds, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                      usePortaling
                      isLoading={!sheetSetOptions}
                      isDisabled={disabled}
                      isMulti
                    />
                  );
                }}
              />
            </div>
            {viewOptions.length > 0 ? (
              <div>
                {t('techCrew.form.startView')}
                <SelectSitelife
                  options={viewOptions}
                  value={
                    watch('defaultViewId')
                      ? viewOptions.find(
                          (o) => o.value === watch('defaultViewId'),
                        )
                      : ''
                  }
                  onChange={(option: SelectSitelifeOptionType) => {
                    if (!techCrew) {
                      setValue('categoryParameter', '');
                    }
                    setValue('defaultViewId', option.value || '', {
                      shouldDirty: true,
                    });
                  }}
                  usePortaling
                  isLoading={!viewOptions}
                  isDisabled={disabled}
                />
              </div>
            ) : (
              availableSheetSets.length > 0 && (
                <InlineMessage className="mb-2 mr-auto">
                  {t('techCrew.form.noViews')}
                </InlineMessage>
              )
            )}
            {showCategoriesSelect && (
              <CategoriesSelect
                value={watch('categoryParameter')}
                onChange={(value) =>
                  setValue('categoryParameter', value, { shouldDirty: true })
                }
                sheetSets={appSheetSets}
                defaultViewId={watch('defaultViewId')}
                projectId={projectId}
                viewDict={viewDict}
                disabled={disabled || actionGroups?.length > 1}
              />
            )}
            <TextInput
              label={`${t('techCrew.form.editElementName')} (${t(
                'techCrew.form.onlyIn3D',
              )})`}
              description={t('techCrew.form.editElementNameText')}
              placeholder="z.b. Typ {{Custom_Parameter}}"
              disabled={!is3dProject || disabled}
              error={errors.nameTemplate?.message}
              {...register('nameTemplate', {
                pattern: {
                  value:
                    /^((\w|\s|-|ß|ä|ö|ü|Ä|Ö|Ü)*(({{){1}(\w|\s|-|ß|ä|ö|ü|Ä|Ö|Ü)+(}}){1})*(\w|\s|-|ß|ä|ö|ü|Ä|Ö|Ü)*)*$/,
                  message: t('techCrew.form.invalid'),
                },
              })}
            />{' '}
          </>
        )}
      </div>
      <div className="ml-auto flex gap-2">
        {onCancel && (
          <Button
            variant="tertiary"
            onClick={() => {
              onCancel && onCancel();
            }}
          >
            {t('common:button.abort')}
          </Button>
        )}
        {techCrew && (
          <Button
            variant="secondary"
            onClick={() => reset()}
            disabled={!isDirty || isSaving}
          >
            <FontAwesomeIcon icon={faUndo} />
          </Button>
        )}
        <Button
          type="submit"
          disabled={!isDirty || !isValid}
          loading={isSaving}
        >
          {techCrew ? t('common:button.save') : t('common:button.add')}
        </Button>
      </div>
    </form>
  );
};

function CategoriesSelect({
  value,
  onChange,
  sheetSets,
  viewDict,
  projectId,
  defaultViewId,
  disabled = false,
}: {
  value: string;
  onChange: (value: string) => void;
  sheetSets: SheetSet[];
  viewDict: FileVersionViewDictionary;
  projectId: string;
  defaultViewId: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation('project');
  const { data: categories, isLoading: isLoadingCategories } =
    useCategoriesQuery(sheetSets, viewDict, projectId, defaultViewId);

  if (isLoadingCategories) {
    return <Spinner />;
  }

  if (!defaultViewId) {
    return (
      <InlineMessage className="mb-2 mr-auto">
        {t('techCrew.form.noViewSelected')}
      </InlineMessage>
    );
  }

  if (!categories || categories.length === 0) {
    return (
      <InlineMessage className="mb-2 mr-auto">
        {t('techCrew.form.noCategories')}
      </InlineMessage>
    );
  }

  const option = categories.find((o) => o.value === value);

  return (
    <div className="flex flex-col">
      <span className="whitespace-nowrap">
        {t('techCrew.form.categoryParameter')}
      </span>

      <span className="text-xs text-gray-600">
        {t('techCrew.form.descriptionCategoryParameter')}
      </span>

      <SelectSitelife
        options={categories}
        value={option}
        onChange={(o: SelectSitelifeOptionType) => {
          if (!o.value) return;
          onChange(o.value);
        }}
        isDisabled={disabled} // TODO: disabled prop?
        isLoading={isLoadingCategories}
        virtualized
        usePortaling
      />
      <InlineMessage variant="warning" className="mt-2">
        {t('techCrew.form.attentionCategoryParameter')}
      </InlineMessage>
    </div>
  );
}
