import i18n from 'i18next';
import dayjs from 'dayjs';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import adminDe from 'src/admin/_locales/de.json';
import adminEn from 'src/admin/_locales/en.json';
import appDe from 'src/app/_locales/de.json';
import appEn from 'src/app/_locales/en.json';
import commonDe from 'src/common/_locales/de.json';
import commonEn from 'src/common/_locales/en.json';
import authDe from 'src/auth/_locales/de.json';
import authEn from 'src/auth/_locales/en.json';
import companyDe from 'src/company/_locales/de.json';
import companyEn from 'src/company/_locales/en.json';
import projectDe from 'src/project/_locales/de.json';
import projectEn from 'src/project/_locales/en.json';
import conrepDe from 'src/Conrep/_locales/de.json';
import conrepEn from 'src/Conrep/_locales/en.json';
import conrepAppDe from 'src/Conrep/App/_locales/de.json';
import conrepAppEn from 'src/Conrep/App/_locales/en.json';
import analysisDe from 'src/analysis/_locales/de.json';
import analysisEn from 'src/analysis/_locales/en.json';
import forgeDe from 'src/Forge/_locales/de.json';
import forgeEn from 'src/Forge/_locales/en.json';
import userDe from 'src/user/_locales/de.json';
import userEn from 'src/user/_locales/en.json';
import fileDe from 'src/file/_locales/de.json';
import fileEn from 'src/file/_locales/en.json';
import sheetsetDe from 'src/sheetset/_locales/de.json';
import sheetsetEn from 'src/sheetset/_locales/en.json';

export const defaultNS = 'common';
export const resources = {
  de: {
    admin: adminDe,
    app: appDe,
    common: commonDe,
    auth: authDe,
    company: companyDe,
    project: projectDe,
    conrep: conrepDe,
    conrepApp: conrepAppDe,
    analysis: analysisDe,
    forge: forgeDe,
    user: userDe,
    file: fileDe,
    sheetset: sheetsetDe,
  },
  en: {
    admin: adminEn,
    app: appEn,
    common: commonEn,
    auth: authEn,
    company: companyEn,
    project: projectEn,
    conrep: conrepEn,
    conrepApp: conrepAppEn,
    analysis: analysisEn,
    forge: forgeEn,
    user: userEn,
    file: fileEn,
    sheetset: sheetsetEn,
  },
} as const;

export function init() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
      {
        fallbackLng: ['de', 'en'],
        supportedLngs: ['de', 'en'],
        detection: {
          order: ['cookie', 'localStorage', 'navigator'],
          caches: ['cookie', 'localStorage'],
        },
        defaultNS,
        resources,
        debug: false,
      },
      () => setDayjsLocale(i18n.language),
    );

  i18n.on('languageChanged', setDayjsLocale);
}

function setDayjsLocale(language: string) {
  const lang = language === 'en' ? 'en-gb' : language;
  dayjs.locale(lang);
}

export default i18n;
