import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

import { Modal } from 'src/common/components/Modal';
import { NoServiceSpecification } from 'src/project/components/NoServiceSpecification';
import { SidebarLayout } from 'src/common/components/SidebarLayout';
import { Button } from 'src/common/components/buttons/Button';
import { SelectSitelife } from 'src/common/components/SelectSitelife';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Fact } from 'src/common/components/Fact';
import {
  LicenseEvaluationType,
  NewServiceSpecification,
} from 'src/project/types/projects';
import { SelectRegieServiceSpecification } from '../components/TechCrew/SelectRegieServiceSpecification';
import { SelectWorkdayServiceSpecification } from '../components/TechCrew/SelectWorkdayServiceSpecification';
import { TechCrewSidebar } from '../components/TechCrewSidebar';
import { TechCrew } from '../types/techCrews';
import { useServiceSpecificationsQuery } from '../queries';
import { validateLicense } from '../utils';
import { Card } from 'src/common/components/Card';
import { Spinner } from 'src/common/components/Spinner';
import { useProject } from '../project-context';

export const TechCrewServiceSpecificationPositionsView = ({
  techCrew,
}: {
  techCrew: TechCrew;
}) => {
  const { t } = useTranslation('project');
  const { projectId = '' } = useParams();
  const { data: serviceSpecifications, isLoading } =
    useServiceSpecificationsQuery(projectId);

  const [
    currentServiceSpecificationRegie,
    setCurrentServiceSpecificationRegie,
  ] = useState<NewServiceSpecification>();

  const [
    currentServiceSpecificationWorkday,
    setCurrentServiceSpecificationWorkday,
  ] = useState<NewServiceSpecification>();

  const findBoQ = useCallback(
    (boqId: string | undefined) => {
      if (!boqId || !serviceSpecifications) return;
      return serviceSpecifications.find((spec) => spec._id === boqId);
    },
    [serviceSpecifications],
  );

  useEffect(() => {
    if (
      techCrew.serviceSpecificationPathsRegie?.serviceSpecification &&
      serviceSpecifications &&
      !currentServiceSpecificationRegie
    ) {
      const foundRegieSpec = findBoQ(
        techCrew.serviceSpecificationPathsRegie?.serviceSpecification,
      );
      setCurrentServiceSpecificationRegie(foundRegieSpec);
    }
    if (
      techCrew.serviceSpecificationPathsWorkday?.serviceSpecification &&
      serviceSpecifications &&
      !currentServiceSpecificationWorkday
    ) {
      const foundWorkdaySpec = findBoQ(
        techCrew.serviceSpecificationPathsWorkday?.serviceSpecification,
      );
      setCurrentServiceSpecificationWorkday(foundWorkdaySpec);
    }
  }, [
    serviceSpecifications,
    techCrew,
    currentServiceSpecificationRegie,
    currentServiceSpecificationWorkday,
    findBoQ,
  ]);

  const [showRegieModal, setShowRegieModal] = useState(false);
  const [showWorkdayModal, setShowWorkdayModal] = useState(false);

  return (
    <>
      <SidebarLayout
        header={
          <div className="flex min-h-[40px] items-center">
            <div
              style={{ backgroundColor: techCrew.color }}
              className="h-4 w-4 rounded"
            />
            <h1 className="ml-2 flex text-2xl font-semibold text-shuttleGray-800">
              {techCrew?.name}
            </h1>
          </div>
        }
        headerLeft={
          <Link to="../../techCrews">
            <Button variant="tertiary">
              <FontAwesomeIcon icon={faArrowLeft} />
              <span>{t('techCrewEdit.allTrades')}</span>
            </Button>
          </Link>
        }
        sidebar={<TechCrewSidebar />}
      >
        {serviceSpecifications && serviceSpecifications.length > 0 ? (
          isLoading ? (
            <Spinner containerClassName="w-full h-full my-[160px] text-center" />
          ) : (
            <Card className="p-6">
              <div className="flex flex-col space-y-2">
                <h3 className="text-lg font-semibold">
                  {t('techCrewEdit.regieBoq')}
                </h3>
                <div className="text-shuttleGray-600">
                  <Trans
                    ns="project"
                    i18nKey="techCrewEdit.regieBoqDescription"
                  >
                    Select items from a bill of quantities to make them
                    available in the
                    <strong> Additional Service Report </strong>section of the
                    site diary.
                  </Trans>
                </div>
                <SelectServiceSpecification
                  serviceSpecifications={serviceSpecifications}
                  currentServiceSpecification={currentServiceSpecificationRegie}
                  usageCount={
                    techCrew.serviceSpecificationPathsRegie?.paths.length || 0
                  }
                  usageText={t('techCrewEdit.boqUsageRegie')}
                  onChangeSpecification={(id) =>
                    setCurrentServiceSpecificationRegie(findBoQ(id))
                  }
                  onClickManage={() => setShowRegieModal(true)}
                />
              </div>
              <hr className="my-6 text-gray-300" />
              <div className="flex flex-col space-y-2">
                <h3 className="text-lg font-semibold">
                  {t('techCrewEdit.workdayBoq')}
                </h3>
                <div className="mt-2 text-shuttleGray-600">
                  <Trans
                    ns="project"
                    i18nKey="techCrewEdit.workdayBoqDescription"
                  >
                    Select items from a bill of quantities to make them
                    available in the
                    <strong> Additional Service Report </strong>section of the
                    site diary.
                  </Trans>
                </div>
                <div className="mt-3">
                  <SelectServiceSpecification
                    serviceSpecifications={serviceSpecifications}
                    currentServiceSpecification={
                      currentServiceSpecificationWorkday
                    }
                    usageCount={
                      techCrew.serviceSpecificationPathsWorkday?.paths.length ||
                      0
                    }
                    usageText={t('techCrewEdit.boqUsageWorkday')}
                    onChangeSpecification={(id) =>
                      setCurrentServiceSpecificationWorkday(findBoQ(id))
                    }
                    onClickManage={() => setShowWorkdayModal(true)}
                  />
                </div>
              </div>
            </Card>
          )
        ) : (
          <NoServiceSpecification />
        )}
      </SidebarLayout>
      {currentServiceSpecificationRegie && (
        <Modal isOpen={showRegieModal} className="w-[90%]">
          <Modal.Header onClose={() => setShowRegieModal(false)}>
            {t('techCrewEdit.boq')}
          </Modal.Header>
          <Modal.Body>
            <SelectRegieServiceSpecification
              serviceSpecification={currentServiceSpecificationRegie}
              techCrew={techCrew}
              onSave={() => setShowRegieModal(false)}
            />
          </Modal.Body>
        </Modal>
      )}
      {currentServiceSpecificationWorkday && (
        <Modal isOpen={showWorkdayModal} className="w-[90%]">
          <Modal.Header onClose={() => setShowWorkdayModal(false)}>
            {t('techCrewEdit.boq')}
          </Modal.Header>
          <Modal.Body>
            <SelectWorkdayServiceSpecification
              serviceSpecification={currentServiceSpecificationWorkday}
              techCrew={techCrew}
              onSave={() => setShowWorkdayModal(false)}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export const SelectServiceSpecification = ({
  currentServiceSpecification,
  usageCount,
  usageText,
  serviceSpecifications,
  onChangeSpecification,
  onClickManage,
}: {
  currentServiceSpecification?: NewServiceSpecification;
  usageCount: number;
  usageText: string;
  serviceSpecifications: NewServiceSpecification[];
  onChangeSpecification: (l?: string) => void;
  onClickManage: () => void;
}) => {
  const { t } = useTranslation('project');
  const project = useProject();
  const [option, setOption] = useState<SelectSitelifeOptionType>();

  useEffect(() => {
    if (currentServiceSpecification) {
      setOption({
        value: currentServiceSpecification._id,
        label: currentServiceSpecification.name,
      });
    }
  }, [currentServiceSpecification]);

  const options = serviceSpecifications.map((ss) => ({
    value: ss._id,
    label: ss.name,
  }));

  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;

  return (
    <div>
      <h4 className="mb-1">{t('techCrewEdit.selectBoq.selectBoq')}</h4>
      <div className="flex space-x-4">
        <div className="flex w-80 flex-col space-y-4">
          <SelectSitelife
            options={options}
            value={option}
            usePortaling
            onChange={(newOption: SelectSitelifeOptionType) => {
              setOption(newOption);
              onChangeSpecification(newOption.value);
            }}
          />
          <Fact label={usageText} variant="gray" size="lg">
            {usageCount}
          </Fact>
        </div>
        <Button
          variant="secondary"
          disabled={!option || isLicenseExpired}
          onClick={onClickManage}
        >
          {t('techCrewEdit.selectBoq.managePositions')}
        </Button>
      </div>
    </div>
  );
};
