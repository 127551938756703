import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { store } from 'src/app/store';
import { assertExists } from 'src/common/utils';
import { getForgeViewerToken } from 'src/Forge/api';
import SelectionExtension from 'src/Forge/Viewer/Extensions/SelectionExtension';
import ToggleSuperfluousOptionsExtension from 'src/Forge/Viewer/Extensions/ToggleSuperFluousOptionsExtension';
import { ExtendedGuiViewer3D } from 'src/Forge/Viewer/Extensions/ExtendedGuiViewer3D';
import SelectionTool from 'src/Forge/Viewer/Extensions/SelectionTool';
import { normalizeUrn, stringArrayToInt } from 'src/Forge/utils';
import { FileVersionView } from 'src/file/types';
import { useForgeViewerCleanUp } from '../hooks';
import { ForgeView } from '../types';

type Props = {
  view: FileVersionView;
  isolatedDbIds?: number[] | null;
  extensions?: { [key: string]: object };
  disabledExtensions?: { [key: string]: object };
  theme?: string;
  className?: string;
  onModelLoaded?: (
    viewer: Autodesk.Viewing.GuiViewer3D,
    event: Autodesk.Viewing.ViewerEvent,
  ) => void;
  onViewerInitialized?: (viewer: Autodesk.Viewing.GuiViewer3D) => void;
  onViewerReady?: (viewer: Autodesk.Viewing.GuiViewer3D) => void;
  onViewerLoading?: () => void;
  onViewerSettingsChange?: (event: { name: string; value: string }) => void;
  onUpdateProgress?: (percentage: number) => void;
  onUpdateIsolation?: (isolated: number[] | null) => void;
  onUpdateIsolationUnsync?: (isolated: number[] | null) => void;
  onUpdateFilter?: () => void;
  onUpdateModel?: (
    viewer: Autodesk.Viewing.GuiViewer3D,
    node: Autodesk.Viewing.ViewerItem,
  ) => void;
  onUpdateViewOptions?: (viewOptions: ForgeView[]) => void;
};

/**
 *
 * @param docUrn
 * @param extensions
 * @param disabledExtensions
 * @param theme
 * @param className
 * @param onModelLoaded please use memoized function
 * @param onViewerInitialized please use memoized function
 * @param onViewerLoading please use memoized function
 * @param onViewerReady please use memoized function
 * @param onViewerSettingsChange please use memoized function
 * @param onUpdateProgress please use memoized function
 * @param onUpdateIsolationUnsync please use memoized function
 * @param onUpdateFilter please use memoized function
 * @param onUpdateModel please use memoized function
 * @param onUpdateViewOptions please use memoized function
 * @constructor
 */
export const ForgeViewerCore = ({
  view,
  isolatedDbIds = null,
  extensions = {},
  disabledExtensions = {},
  theme = 'light-theme',
  className,
  onModelLoaded,
  onViewerInitialized,
  onViewerReady,
  onViewerLoading,
  onViewerSettingsChange,
  onUpdateProgress,
  onUpdateIsolation,
  onUpdateIsolationUnsync,
  onUpdateFilter,
  onUpdateModel,
  onUpdateViewOptions,
}: Props) => {
  const { t } = useTranslation(['forge']);

  const viewerRef = useRef<Autodesk.Viewing.GuiViewer3D | null>(null);
  const [viewerDomRef, setViewerDomRef] = useState<HTMLDivElement | null>(null);

  useForgeViewerCleanUp(viewerRef.current);

  function handleModelLoaded(event: Autodesk.Viewing.ViewerEvent) {
    const viewer = viewerRef.current;
    assertExists(viewer);

    viewer.removeEventListener(
      Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
      handleModelLoaded,
    );

    if (onModelLoaded) {
      onModelLoaded(viewer, event);
    }
  }

  function registerExtensions() {
    Autodesk.Viewing.theExtensionManager.registerExtension(
      'SelectionExtension',
      SelectionExtension,
    );

    Autodesk.Viewing.theExtensionManager.registerExtension(
      'ToggleSuperfluousOptionsExtension',
      ToggleSuperfluousOptionsExtension,
    );
  }

  function registerCustomizedMenu(viewer: ExtendedGuiViewer3D) {
    const customIsolate = () => {
      if (viewer) {
        const selection = viewer.getSelection();
        viewer.isolate(selection);
        const isolated = viewer.getIsolatedNodes();
        onUpdateIsolationUnsync && onUpdateIsolationUnsync(isolated);
      }
    };

    const customHide = () => {
      if (viewer) {
        const selection = viewer.getSelection();
        const isolated = viewer.getIsolatedNodes();

        let filtered =
          store.getState().conrepApp.filteredElementProperties.value;
        if (!filtered) {
          // fallback if its initial
          filtered = store.getState().conrepApp.allElementProperties.value;
        }

        const filteredInt = filtered
          ? stringArrayToInt(Object.keys(filtered))
          : [];

        const toSelect = filteredInt.filter(
          (dbId) => !selection.includes(dbId) && isolated.includes(dbId),
        );

        viewer.isolate(toSelect);
        onUpdateIsolationUnsync && onUpdateIsolationUnsync(toSelect);
      }
    };

    const customReset = () => {
      viewer?.isolate(store.getState().forgeViewer.leafNodes || undefined);

      (viewer.utilities as any).goHome();
      viewer.clearThemingColors(viewer.model);
      onUpdateFilter && onUpdateFilter();
      onUpdateIsolationUnsync &&
        onUpdateIsolationUnsync(store.getState().forgeViewer.leafNodes || null);
    };

    if (viewer) {
      viewer.registerContextMenuCallback(
        'MyChangingColorMenuItems',
        (menu: any, status: any) => {
          if (status.hasSelected) {
            for (let i = menu.length - 1; i >= 0; i--) {
              if (menu[i].title === 'Isolate') {
                menu[i].target = customIsolate;
              }
              if (menu[i].title === 'Hide Selected') {
                menu[i].target = customHide;
              }
              if (menu[i].title === 'Show All Objects') {
                menu[i].title = t('forge:resetIsolation');
                menu[i].target = customReset;
              }
            }
          } else {
            for (let i = menu.length - 1; i >= 0; i--) {
              if (menu[i].title === 'Show All Objects') {
                menu[i].title = t('forge:resetIsolation');
                menu[i].target = customReset;
              }
            }
          }
        },
      );
    }
  }

  const addEventListeners = useCallback(() => {
    if (!viewerRef.current) {
      return;
    }

    viewerRef.current.getHotkeyManager().popHotkeys('Autodesk.Escape');

    if (onViewerSettingsChange) {
      viewerRef.current.addEventListener(
        Autodesk.Viewing.PREF_CHANGED_EVENT,
        onViewerSettingsChange,
      );
    }

    if (onUpdateProgress) {
      viewerRef.current.addEventListener(
        Autodesk.Viewing.PROGRESS_UPDATE_EVENT,
        (psm) => {
          if (psm.state === 1 && psm.type === 'progress') {
            onUpdateProgress(psm.percent);
          }
        },
      );
    }

    viewerRef.current.addEventListener(
      Autodesk.Viewing.EXTENSION_PRE_ACTIVATED_EVENT,
      (extension) => {
        if (extension?.extensionId === 'Autodesk.Measure') {
          viewerRef.current?.getExtension(
            'Autodesk.Measure',
            (measureExtension: any) => {
              // see https://forge.autodesk.com/blog/setting-measure-command-units-api-forge-viewer
              measureExtension.setUnits('m');
              measureExtension.setPrecision(2);
            },
          );
        }
      },
    );
  }, [onUpdateProgress, onViewerSettingsChange]);

  function initializeViewer() {
    onViewerLoading && onViewerLoading();
    const options: Autodesk.Viewing.InitializerOptions = {
      env: 'AutodeskProduction2',
      api: 'streamingV2_EU', // we need to consider a logic if we're ever outside EMEA region
      getAccessToken(onTokenReady) {
        getForgeViewerToken()
          .then((viewerToken) => {
            onTokenReady &&
              onTokenReady(
                viewerToken.forgeToken.credentials.access_token,
                viewerToken.forgeToken.credentials.expires_in,
              );
          })
          // If Autodesk is down, kill the viewer
          .catch(() => {
            if (viewerRef.current) {
              viewerRef.current.finish();
            }
          });
      },
    };

    Autodesk.Viewing.Initializer(options, function () {
      assertExists(viewerDomRef);

      registerExtensions();

      const extensionsWithConfig: string[] = [];
      const extensionsWithoutConfig: string[] = [];

      for (const key in extensions) {
        const config = extensions[key];
        if (Object.keys(config).length === 0) {
          extensionsWithoutConfig.push(key);
        } else {
          extensionsWithConfig.push(key);
        }
      }

      const viewer = new ExtendedGuiViewer3D(viewerDomRef, {
        extensions: extensionsWithoutConfig,
        disabledExtensions: disabledExtensions || {},
        theme,
      });

      extensionsWithConfig.forEach((ext) => {
        viewer.loadExtension(ext, extensions[ext]);
      });

      viewerRef.current = viewer;
      registerCustomizedMenu(viewer);

      const startedCode = viewer.start();
      if (startedCode > 0) {
        // eslint-disable-next-line no-console
        console.error('Failed to create a Viewer: WebGL not supported.');
        return;
      }

      const selectionTool = new SelectionTool();
      viewer.toolController.registerTool(selectionTool);
      viewer.toolController.activateTool('selection-tool');

      viewer.addEventListener(
        Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
        handleModelLoaded,
        {
          once: true,
        },
      );

      addEventListeners();

      loadModel(viewer, normalizeUrn(view.viewerUrnEncoded));

      if (onViewerInitialized) {
        onViewerInitialized(viewer);
      }
    });
  }

  const onLoadFinished = useCallback(
    async (viewer: ExtendedGuiViewer3D, model: Autodesk.Viewing.Model) => {
      if (!model) {
        return;
      }

      const nodeChild = model
        .getDocumentNode()
        .data.children?.find((c: any) => c.role === 'graphics');

      if (nodeChild) {
        onUpdateModel && onUpdateModel(viewer, nodeChild);
      }

      viewer.unloadExtension('Autodesk.BoxSelection');

      if (model.is3d()) {
        (viewer.getExtension('Autodesk.ViewCubeUi') as any)?.displayHomeButton(
          false,
        );

        const ext = await viewer.loadExtension('Autodesk.BoxSelection', {
          useGeometricIntersection: true,
        });
        (ext as any).addToolbarButton(true);
      }
      onViewerReady && onViewerReady(viewer);
    },
    [onUpdateModel, onViewerReady],
  );

  const loadModel = (
    viewer: Autodesk.Viewing.GuiViewer3D,
    documentId: string,
  ) => {
    function onDocumentLoadSuccess(viewerDocument: Autodesk.Viewing.Document) {
      const bubbleNode = viewerDocument.getRoot();
      let defaultModel;

      if (view.viewableId) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const results = bubbleNode.search({ viewableID: view.viewableId });
        if (results && results.length) {
          defaultModel = results[0];
        }
      }

      if (onUpdateViewOptions) {
        const geometries = bubbleNode.search({ type: 'geometry' });

        // For "PDF"-Projects
        const viewOptions: ForgeView[] = geometries.map((v) => {
          return {
            guid: v.data.guid,
            name: v.data.name,
            viewableID: v.data.viewableID,
            role: v.data.role,
            size: v.data.size,
          };
        });
        onUpdateViewOptions(viewOptions);
      }

      if (!defaultModel) {
        defaultModel = bubbleNode.getDefaultGeometry();
      }
      const skipHiddenFragments = false;
      viewer
        .loadDocumentNode(viewerDocument, defaultModel, {
          keepCurrentModels: false,
          skipHiddenFragments,
        })
        .then((model) => onLoadFinished(viewer, model))
        .catch(onDocumentLoadFailure);
    }

    function onDocumentLoadFailure() {
      // eslint-disable-next-line no-console
      console.error('Failed fetching Forge manifest');
    }

    if (documentId) {
      onViewerLoading && onViewerLoading();
      Autodesk.Viewing.Document.load(
        documentId,
        onDocumentLoadSuccess,
        onDocumentLoadFailure,
      );
    } else {
      onViewerReady && onViewerReady(viewer);
    }
  };

  useEffect(() => {
    if (!viewerRef.current && viewerDomRef) {
      initializeViewer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerDomRef]);

  useLayoutEffect(() => {
    if (viewerRef.current) {
      loadModel(viewerRef.current, normalizeUrn(view.viewerUrnEncoded));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  useLayoutEffect(() => {
    if (viewerRef.current && isolatedDbIds) {
      viewerRef.current.isolate(isolatedDbIds);
      onUpdateIsolation && onUpdateIsolation(isolatedDbIds);
    }
  }, [isolatedDbIds, onUpdateIsolation]);

  function setViewerRef(ref: HTMLDivElement | null) {
    setViewerDomRef(ref);
  }

  return <div id="forgeViewer" ref={setViewerRef} className={className} />;
};
