import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  faClock,
  faChartBar,
  faCogs,
} from '@fortawesome/pro-regular-svg-icons';

import { SidebarLayout } from 'src/common/components/SidebarLayout';
import { SidebarMenu } from 'src/common/components/SidebarMenu';
import { NoTechCrew } from 'src/project/components/NoTechCrew';
import { TechCrew } from 'src/project/types/techCrews';
import { SettingsListItem } from '../components/SettingsListItem';
import {
  getEarliestDateOfArray,
  getLatestDateOfArray,
  numberWithDots,
} from '../utils';
import { Spinner } from 'src/common/components/Spinner';

export const PlannedHoursView = ({
  techCrews,
  licenseExpired,
  hasAdminPermissions,
  isLoading,
}: {
  techCrews: TechCrew[];
  licenseExpired: boolean;
  hasAdminPermissions: boolean;
  isLoading: boolean;
}) => {
  const { t } = useTranslation('analysis');

  const totalHours = techCrews.reduce(
    (acc, curr) => acc + (curr.plannedHours || 0),
    0,
  );
  const earliestDate =
    techCrews &&
    getEarliestDateOfArray(techCrews.map((techCrew) => techCrew.startDate));
  const latestDate =
    techCrews &&
    getLatestDateOfArray(techCrews.map((techCrew) => techCrew.endDate));

  return (
    <SidebarLayout
      header={
        <div>
          <h3 className="mb-1 text-2xl font-semibold">
            {t('settings.plannedHours')}
          </h3>
          <p className="text-shuttleGray-600">
            {t('settings.plannedHoursDescription')}
          </p>
        </div>
      }
      sidebar={
        <SidebarMenu>
          <SidebarMenu.Item
            to="../plannedHours"
            icon={<FontAwesomeIcon icon={faClock} fixedWidth />}
          >
            {t('settings.plannedHours')}
          </SidebarMenu.Item>
          {hasAdminPermissions && (
            <SidebarMenu.Item
              to="../analysisParameters"
              icon={<FontAwesomeIcon icon={faChartBar} fixedWidth />}
            >
              {t('settings.analysisParameters')}
            </SidebarMenu.Item>
          )}
          {hasAdminPermissions && (
            <SidebarMenu.Item
              to="../powerBiManagement"
              icon={<FontAwesomeIcon icon={faCogs} fixedWidth />}
            >
              {t('settings.datasetSettings')}
            </SidebarMenu.Item>
          )}
        </SidebarMenu>
      }
    >
      {isLoading ? (
        <Spinner containerClassName="w-full h-full my-[160px] text-center" />
      ) : techCrews && techCrews.length > 0 ? (
        <table className="w-full table-fixed divide-y divide-gray-100 border-b border-gray-100 shadow">
          <thead className="table-head">
            <tr>
              <th className="table-th">{t('settings.table.techCrew')}</th>
              <th className="table-th">{t('settings.table.plannedHours')}</th>
              <th className="table-th">{t('settings.table.plannedStart')}</th>
              <th className="table-th">{t('settings.table.plannedEnd')}</th>
              <th className="table-th" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {techCrews.map((techCrew) => (
              <SettingsListItem
                key={techCrew._id}
                techCrew={techCrew}
                disabled={licenseExpired}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th className="table-th pl-14 text-base">
                {t('settings.table.total')}
              </th>
              <th className="table-th text-base">
                {numberWithDots(totalHours ?? 0)} {t('settings.hours')}
              </th>
              <th className="table-th text-base">
                {earliestDate ? dayjs(earliestDate).format('L') : null}
              </th>
              <th className="table-th text-base">
                {latestDate ? dayjs(latestDate).format('L') : null}
              </th>
              <th className="table-th text-base" />
            </tr>
          </tfoot>
        </table>
      ) : (
        <NoTechCrew />
      )}
    </SidebarLayout>
  );
};
