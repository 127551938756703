import CookieConsent, {
  getCookieConsentValue,
  resetCookieConsentValue,
} from 'react-cookie-consent';
import { Trans, useTranslation } from 'react-i18next';
import * as amplitude from '@amplitude/analytics-browser';
import { ReactNode, createContext, useContext, useState } from 'react';

import { cn } from 'src/common/utils';
import { buttonVariants } from 'src/common/components/buttons/Button';

const COOKIE_NAME = 'sitelife-tracking-consent';

export const GDPRCookieConsent = () => {
  const { t } = useTranslation('app');
  const { giveConsent } = useConsent();

  return (
    <CookieConsent
      location="bottom"
      buttonText={t('cookieConsent.confirm')}
      enableDeclineButton
      flipButtons
      setDeclineCookie
      cookieValue="all"
      declineCookieValue="none"
      declineButtonText={t('cookieConsent.decline')}
      cookieName={COOKIE_NAME}
      disableButtonStyles
      style={{
        backgroundColor: '#192B69',
        zIndex: 99999,
        color: 'white',
      }}
      buttonClasses={cn(
        'mr-4',
        buttonVariants({ variant: 'primary', size: 'sm' }),
      )}
      declineButtonClasses={cn(
        'mr-4',
        buttonVariants({ variant: 'tertiary', size: 'sm' }),
      )}
      expires={150}
      onAccept={giveConsent}
      overlay
    >
      <Trans i18nKey="cookieConsent.description" ns="app">
        This website uses scripts and cookies to ensure the technical
        functionality as well as for sales and marketing purposes. You can find
        more detailled information in our{' '}
        <a
          href="https://bim-management.com/datenschutzerklaerung/"
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold text-blue-50 hover:underline"
        >
          privacy policy
        </a>{' '}
        and{' '}
        <a
          href="https://bim-management.com/impressum/"
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold text-blue-50 hover:underline"
        >
          imprint
        </a>
      </Trans>
    </CookieConsent>
  );
};

const consentContext = createContext({
  value: 'none',
  giveConsent: () => {
    // do nothing by default
  },
  revokeConsent: () => {
    // do nothing by default
  },
});

export type ConsentValue = 'all' | 'none';

export const ConsentProvider = ({ children }: { children: ReactNode }) => {
  const [value, setValue] = useState<ConsentValue>(() => {
    const cookieValue = getCookieConsentValue(COOKIE_NAME);
    if (cookieValue !== 'all') return 'none';
    return 'all';
  });

  const giveConsent = () => {
    if (import.meta.env.VITE_ENVIRONMENT !== 'local') {
      amplitude.setOptOut(false);
    }
    setValue('all');
  };
  const revokeConsent = () => {
    resetCookieConsentValue(COOKIE_NAME);
    setValue('none');
  };

  return (
    <consentContext.Provider value={{ value, giveConsent, revokeConsent }}>
      {children}
    </consentContext.Provider>
  );
};

export const useConsent = () => useContext(consentContext);
