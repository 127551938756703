import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAdminUsersQuery } from 'src/admin/queries';
import { Button } from 'src/common/components/buttons/Button';
import { Pagination } from 'src/common/components/Pagination';
import { SearchSort } from 'src/common/components/SearchSort';
import { SimpleUserRow } from '../user/SimpleUserRow';
import { Card } from 'src/common/components/Card';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Spinner } from 'src/common/components/Spinner';

import type { CompanyPopulatedUser } from 'shared';

type Props = {
  ownerId?: string;
  onClickOwner: (owner: CompanyPopulatedUser) => void;
};

export const CompanyUserPage = ({ ownerId, onClickOwner }: Props) => {
  const { t } = useTranslation(['admin', 'common']);

  const [searchBy, setSearchBy] = useState('');
  const [sortBy, setSortBy] = useState('surname');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const filter = { filterBy: 'doesNotOwnCompany', filter: 'null' };

  const { data: paginatedUsers, isPending } = useAdminUsersQuery(
    page,
    entriesPerPage,
    searchBy,
    sortBy,
    sortOrder,
    filter,
  );

  const sortOptions: SelectSitelifeOptionType[] = [
    { label: t('common:surname'), value: 'surname' },
    { label: t('common:name'), value: 'name' },
    { label: t('common:email'), value: 'email' },
    { label: t('common:organizations'), value: 'company.name' },
  ];

  const users = paginatedUsers ? paginatedUsers.data : [];

  return (
    <div className="flex h-[700px] flex-col justify-between">
      <div className="px-6">
        <h3 className="text-xl font-semibold">
          {t('admin:organizations.selectOwner')}
        </h3>
        <div className="flex items-center" />
        <SearchSort
          searchBy={searchBy}
          sortBy={
            sortOptions.find((sortOption) => sortOption.value === sortBy) ||
            sortOptions[0]
          }
          sortOrder={sortOrder}
          sortOptions={sortOptions}
          onSearchBy={(newSearchBy) => setSearchBy(newSearchBy)}
          onSortBy={(newSortBy) => setSortBy(newSortBy)}
          onSortOrder={(newSortOrder) => setSortOrder(newSortOrder)}
        />
      </div>
      <div className="mt-6 h-full overflow-y-auto bg-gray-100 p-6">
        <Card>
          {isPending ? (
            <Spinner containerClassName="w-full h-full my-[160px] text-center" />
          ) : users.length > 0 ? (
            <table className="relative w-full divide-y divide-gray-200 shadow">
              <thead>
                <tr>
                  <th scope="col" className="table-th">
                    {t('common:person')}
                  </th>
                  <th scope="col" className="table-th">
                    {t('common:position')}
                  </th>
                  <th scope="col" className="table-th xl:table-cell" />
                </tr>
              </thead>
              <tbody className="table-body">
                {users.map((user) => (
                  <UserRow
                    key={user._id}
                    user={user}
                    ownerId={ownerId}
                    onClickOwner={onClickOwner}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
              {searchBy ? (
                <>
                  <span className="font-semibold text-shuttleGray-800">
                    {`${searchBy}`}
                  </span>{' '}
                  {t('common:notFound.message')}
                </>
              ) : (
                t('common:noUsers')
              )}
            </p>
          )}
        </Card>
      </div>
      {users.length > 0 && !isPending && paginatedUsers && (
        <Pagination
          maxPage={Math.ceil(paginatedUsers.totalCount / entriesPerPage)}
          page={page}
          onChangePage={setPage}
          entriesPerPage={entriesPerPage}
          onChangeEntriesPerPage={setEntriesPerPage}
          className="p-6"
        />
      )}
    </div>
  );
};

const UserRow = ({
  user,
  ownerId,
  onClickOwner,
}: {
  user: CompanyPopulatedUser;
  ownerId?: string;
  onClickOwner: (u: CompanyPopulatedUser) => void;
}) => {
  const { t } = useTranslation('admin');
  const isOwner = ownerId === user._id;
  return (
    <SimpleUserRow
      user={user}
      middleContent={user.position}
      isSelected={isOwner}
      key={user._id}
    >
      {isOwner ? (
        <Button size="sm" disabled>
          <FontAwesomeIcon icon={faCheck} />
          {t('organizations.selected')}
        </Button>
      ) : isOwner ? null : (
        <Button
          size="sm"
          variant="secondary"
          onClick={() => onClickOwner(user)}
        >
          {t('organizations.select')}
        </Button>
      )}
    </SimpleUserRow>
  );
};
