import { FileVersionView, FileVersionViewDictionary } from 'src/file/types';
import { SheetSet } from './types';

export function getSetOfViewsFromSheetSets(
  sheetSets: SheetSet[],
  viewDict: FileVersionViewDictionary,
) {
  const availableViewSet: FileVersionView[] = [];
  sheetSets.forEach((ss) => {
    ss.views.forEach((v) => {
      if (
        availableViewSet.findIndex((viewAdded) => {
          return (
            viewAdded.viewableId === v.viewableId && viewAdded.file === v.file
          );
        }) < 0
      ) {
        // NOTE: we check for viewableId and fileId here, so that a view that is included in multiple sheetsets only is listed once
        // not found, so lets add it
        const fullView = viewDict[`${v.file}_${v.viewableId}`];
        if (fullView) {
          availableViewSet.push(fullView);
        }
      }
    });
  });
  return availableViewSet;
}
