import { SitelifeFile } from './types';
import { CreateModelFileResponse, ForgeProgressStatus } from './types';
import { api } from 'src/common/fetch';

export async function getProjectFiles(projectId: string) {
  return api.get<SitelifeFile[]>(`/api/projects/${projectId}/files`);
}

export async function createModelFile(projectId: string, fileData: any) {
  return api.post<CreateModelFileResponse>(
    `/api/projects/${projectId}/files`,
    fileData,
  );
}

export async function deleteFile(projectId: string, fileId: string) {
  return api.delete(`/api/projects/${projectId}/files/${fileId}`);
}

export async function refreshForgeStatus(
  projectId: string,
  fileId: string,
  versionId: string,
) {
  return api.post<ForgeProgressStatus>(
    `/api/forge/derivative/projects/${projectId}/files/${fileId}/versions/${versionId}/refresh-status`,
  );
}

export async function triggerForgeTranslation(
  projectId: string,
  fileId: string,
  versionId: string,
) {
  return api.post(
    `/api/forge/derivative/projects/${projectId}/files/${fileId}/versions/${versionId}`,
  );
}

export async function completeBatchSignedS3Upload(
  projectId: string,
  uploadKey: string,
  objectKey: string,
) {
  return api.post(`/api/projects/${projectId}/files/batchcompleteupload`, {
    uploadKey,
    objectKey,
  });
}
