// TODO: remove below line ASAP and fix the broken effects
/* eslint-disable react-hooks/exhaustive-deps */
import cloneDeep from 'lodash/cloneDeep';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxProps } from 'react-checkbox-tree';
import { useParams } from 'react-router-dom';

import { Spinner } from 'src/common/components/Spinner';
import { CheckboxTree } from 'src/common/components/CheckboxTree';
import {
  getGaebTree,
  getServiceSpecificationSuggestions,
} from 'src/project/api/projectsApi';
import { TechCrew } from 'src/project/types/techCrews';

export type RawGaebTreeNode = {
  rNoPart: string;
  label: string;
  children?: RawGaebTreeNode[];
};

export type GaebTreeNode = {
  children?: GaebTreeNode[];
  value: string;
  label: ReactElement;
  description: string;
  showCheckbox: boolean;

  tmpFiltered: GaebTreeNode[];
  tmpExpanded: string[];
  parentMatched: boolean;
};

export function rawGaebToTreeNodeArray(
  rawTree: RawGaebTreeNode[],
): GaebTreeNode[] {
  const gaebTree: GaebTreeNode[] = [];
  rawTree.forEach((node) => {
    const newNode: GaebTreeNode = {
      value: node.rNoPart,
      label: (
        <span key={node.label}>
          <span className="mr-3 font-medium text-shuttleGray-800">
            {node.rNoPart}
          </span>
          <span className="ml-2 text-gray-800">{node.label}</span>
        </span>
      ),
      description: `${node.rNoPart} ${node.label}`,
      showCheckbox: true,
      tmpFiltered: [],
      tmpExpanded: [],
      parentMatched: false,
    };
    if (node.children) {
      newNode.children = rawGaebToTreeNodeArray(node.children);
    }
    gaebTree.push(newNode);
  });
  return gaebTree;
}

type Props = {
  serviceSpecification: any;
  checked: string[];
  onCheck: (c: string[], node?: Node) => void;
  expanded: string[];
  onExpand: (e: string[]) => void;
  techCrewForSuggestions?: TechCrew;
} & Omit<CheckboxProps, 'nodes' | 'onClick'>;

export const ServiceSpecificationTreeGaeb = ({
  serviceSpecification,
  checked,
  onCheck,
  expanded,
  onExpand,
  techCrewForSuggestions,
  ...treeProps
}: Props) => {
  const { t } = useTranslation('project');
  const { projectId = '' } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [treeNodes, setTreeNodes] = useState<GaebTreeNode[]>();

  // can use ref here cause we dont need to rerender as it doesnt change
  const originalTree = useRef<GaebTreeNode[]>();
  const originalTreeFiltered = useRef<GaebTreeNode[]>();

  const paintTree = (tree: GaebTreeNode[] | undefined = undefined) => {
    const copy = cloneDeep(
      tree || originalTreeFiltered.current || originalTree.current,
    );
    // cancel if no tree yet
    if (!copy) {
      return;
    }
    setTreeNodes(copy);
    if (!originalTree.current) {
      // if its the first time
      originalTree.current = copy;
    }
  };

  // initial loading effect
  useEffect(() => {
    if (serviceSpecification) {
      const loadTree = async () => {
        setIsLoading(true);
        const tree = techCrewForSuggestions
          ? await getServiceSpecificationSuggestions(
              techCrewForSuggestions.project,
              techCrewForSuggestions._id,
              serviceSpecification._id,
              'WORKDAY',
            )
          : await getGaebTree(projectId, serviceSpecification._id);
        paintTree(rawGaebToTreeNodeArray(tree));
        setIsLoading(false);
      };
      loadTree();
    }
  }, [serviceSpecification._id]);

  if (!serviceSpecification._id) {
    return (
      <div className="flex min-h-[480px] flex-col rounded border border-gray-300 bg-gray-100 p-3">
        <div className="text-base font-medium text-shuttleGray-700">
          {t('settings.serviceSpecifications.noServiceSpecifications')}
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-[480px] flex-col rounded border border-gray-300 bg-gray-100 p-3">
      <div className="mb-4 flex items-start justify-between">
        <div>
          <h3 className="text-xl font-semibold text-shuttleGray-800">
            {t('settings.serviceSpecifications.title')}
          </h3>

          <div className="text-base font-medium text-gray-800">
            {serviceSpecification.name}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="flex grow items-center justify-center">
          <Spinner className="text-6xl" containerClassName="" />
        </div>
      ) : (
        <div>
          {!treeNodes ||
          treeNodes?.length === 0 ||
          (treeNodes?.length === 0 &&
            originalTree?.current &&
            originalTree?.current?.length > 0) ? (
            <div className="text-base font-medium text-shuttleGray-700">
              {t('settings.serviceSpecifications.noGroupsOrPositions')}
            </div>
          ) : (
            <CheckboxTree
              nodes={treeNodes || []}
              checked={checked}
              expanded={expanded || []}
              onCheck={onCheck}
              onExpand={onExpand}
              {...treeProps}
            />
          )}
        </div>
      )}
    </div>
  );
};
