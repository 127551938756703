type Props = {
  lat?: number;
  long?: number;
  street?: string;
  city?: string;
  country?: string;
};

export const GoogleMaps = (props: Props) => {
  const { lat, long, street, city, country } = props;

  const coordinatesExist = lat && long;
  const addressExists = street && city && country;

  if (!coordinatesExist && !addressExists) {
    return <span>No Location available</span>;
  }

  let query = '';

  // TODO: which one takes precedence?
  // for now, coordinates are stronger
  if (addressExists) {
    query = `${street},${city},${country}`;
  }
  if (coordinatesExist) {
    query = `${lat},${long}`;
  }

  const fullUrl = `https://www.google.com/maps/embed/v1/place?key=${
    import.meta.env.VITE_GOOGLE_MAPS_PLACE_API_KEY
  }${encodeURIComponent(query)}`;

  return (
    <iframe
      className="h-full w-full"
      title="mapsLocation"
      loading="lazy"
      allowFullScreen
      id="GoogleMapsEmbed"
      src={fullUrl}
    />
  );
};
