import {
  faCheckSquare,
  faChevronDown,
  faChevronRight,
  faMinusSquare,
  faPlusSquare,
  faSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactCheckboxTree, { CheckboxProps } from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

export type { Node } from 'react-checkbox-tree';

export const CheckboxTree = (props: CheckboxProps) => {
  return (
    <ReactCheckboxTree
      showExpandAll
      showNodeIcon={false}
      icons={{
        check: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-check"
            icon={faCheckSquare}
            fixedWidth
          />
        ),
        uncheck: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-uncheck"
            icon={faSquare}
            fixedWidth
          />
        ),
        halfCheck: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-half-check opacity-50"
            icon={faCheckSquare}
            fixedWidth
          />
        ),
        expandClose: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-expand-close"
            icon={faChevronRight}
            fixedWidth
          />
        ),
        expandOpen: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-expand-open"
            icon={faChevronDown}
            fixedWidth
          />
        ),
        expandAll: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-expand-all"
            icon={faPlusSquare}
            fixedWidth
          />
        ),
        collapseAll: (
          <FontAwesomeIcon
            className="rct-icon rct-icon-collapse-all"
            icon={faMinusSquare}
            fixedWidth
          />
        ),
      }}
      {...props}
    />
  );
};
