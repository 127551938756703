import { api } from 'src/common/fetch';

type CustomParameterSuggestions = {
  names: string[];
};
export async function getCustomParameterPropertiesSuggestions(
  projectId?: string,
  parameterId?: string,
  maxNameSuggestions = 4,
) {
  if (!projectId || !parameterId) {
    throw new Error('projectId and parameterId are required');
  }
  const nameQueryParam = `maxNameSuggestions=${maxNameSuggestions}`;
  return api.get<CustomParameterSuggestions>(
    `/api-conrep/projects/${projectId}/suggestions/customParameterProperties/${parameterId}?${nameQueryParam}`,
  );
}
