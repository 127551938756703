import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';

import { createCompany } from '../../apiCompanies';
import { CompanyUserPage } from './CompanyUserPage';
import { CompanyInfoFormValues, CompanyInfoPage } from './CompanyInfoPage';

import type { CompanyPopulatedUser } from 'shared';

type Props = {
  onClose: () => void;
};

enum CompanyModalPage {
  PAGE_OWNER = 1,
  PAGE_INFO = 2,
}

export const CompanyCreateForm = ({ onClose }: Props) => {
  const { t } = useTranslation(['admin', 'common']);

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(CompanyModalPage.PAGE_OWNER);

  const [owner, setOwner] = useState<CompanyPopulatedUser>();

  const queryClient = useQueryClient();

  async function handleSave(companyData: CompanyInfoFormValues) {
    try {
      if (!owner) {
        throw new Error('Owner is not defined');
      }
      setIsLoading(true);
      await createCompany({
        ...companyData,
        owner: owner._id,
      });
      toast.success(t('organizations.createSuccess'));
      await queryClient.invalidateQueries({
        queryKey: ['admin-companies'],
      });
      onClose();
    } catch (exception) {
      toast.error(t('organizations.createFailure'));
    } finally {
      setIsLoading(false);
    }
  }

  const renderCompanyModalPage = () => {
    switch (currentPage) {
      case CompanyModalPage.PAGE_OWNER:
        return (
          <CompanyUserPage
            ownerId={owner?._id}
            onClickOwner={(selectedOwner: CompanyPopulatedUser) => {
              setOwner(selectedOwner);
              setCurrentPage(CompanyModalPage.PAGE_INFO);
            }}
          />
        );
      case CompanyModalPage.PAGE_INFO:
        return (
          <CompanyInfoPage
            owner={owner}
            onClose={onClose}
            onSave={handleSave}
            isLoading={isLoading}
          />
        );
    }
  };

  return <div className="min-w-[700px] pt-6">{renderCompanyModalPage()}</div>;
};
