import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

import { hasEditAnalysisPermission } from 'src/Conrep/App/ActionAllowed/utils';
import { TabNavBar } from 'src/common/components/TabNavBar';
import { useTechCrewsQuery } from 'src/project/queries';
import { LicenseEvaluationType, LicenseType } from 'src/project/types/projects';
import { User } from 'src/user/types';
import { is3dProject, validateLicense } from 'src/project/utils';
import { AnalysisSettingsView } from './SettingsView';
import { AnalysisProView } from './ProView';
import { AnalysisUpdate } from '../components/AnalysisUpdateButton';
import { getRefreshHistory } from '../api';
import { useProject } from 'src/project/project-context';
import { TeaserPage } from 'src/common/components/TeaserPage';
import { Container } from 'src/common/components/Container';

export const AnalysisView = ({
  user,
  hasAnalysisEditPermissions,
  hasAdminPermissions,
}: {
  user: User;
  hasAnalysisEditPermissions: boolean;
  hasAdminPermissions: boolean;
}) => {
  const { t } = useTranslation(['analysis', 'common']);
  const currentProject = useProject();

  const isFreeProject = currentProject.license.type === LicenseType.FREE;
  const isLicenseExpired =
    validateLicense(currentProject.license) === LicenseEvaluationType.EXPIRED;

  const { data: allTechCrews, isPending } = useTechCrewsQuery(
    currentProject._id,
  );

  const techCrews = hasAdminPermissions
    ? allTechCrews
    : allTechCrews?.filter((tc) => hasEditAnalysisPermission(user._id, tc));

  const { data: refreshHistory } = useQuery({
    queryKey: ['projects', currentProject._id, 'powerbi-refreshes'],
    queryFn: () => getRefreshHistory(currentProject._id),
    refetchInterval: 3000,
    refetchIntervalInBackground: true,
  });

  if (!techCrews) {
    return null;
  }

  if (isFreeProject) {
    return (
      <Container className="min-h-full py-8" variant="box">
        <TeaserPage
          project={currentProject}
          videoUrl="https://www.youtube.com/embed/-VSBm7E3J5I?si=ELfsUqiQcC6r3N8N"
          description={t('analysis:upgradePage.upgradeText')}
          cardTitle={t('analysis:upgradePage.cardTitle')}
          cardText={t('analysis:upgradePage.cardText')}
        />
      </Container>
    );
  }

  return (
    <div className="flex h-full w-full flex-col">
      <TabNavBar.Container>
        <TabNavBar>
          <div className="flex flex-grow">
            <TabNavBar.Link
              to="targetActual"
              label={t('tab.targetActualComparison')}
            />
            <TabNavBar.Link
              to="productiveHours"
              label={t('tab.productiveHours')}
            />
            {is3dProject(currentProject) ? (
              <TabNavBar.Link to="progress" label={t('tab.progress')} />
            ) : null}
            {(hasAnalysisEditPermissions || hasAdminPermissions) && (
              <TabNavBar.Link to="settings" label={t('tab.settings')} />
            )}
          </div>
          {!!refreshHistory && (
            <AnalysisUpdate project={currentProject} history={refreshHistory} />
          )}
        </TabNavBar>
      </TabNavBar.Container>
      <main className="h-full overflow-auto">
        <Routes>
          <Route
            path="progress"
            element={
              <AnalysisProView
                pageName="progress"
                techCrews={techCrews}
                history={refreshHistory}
                hasAdminPermissions={hasAdminPermissions}
              />
            }
          />
          <Route
            path="productiveHours"
            element={
              <AnalysisProView
                pageName="productiveHours"
                techCrews={techCrews}
                history={refreshHistory}
                hasAdminPermissions={hasAdminPermissions}
              />
            }
          />
          <Route
            path="targetActual"
            element={
              <AnalysisProView
                pageName="targetActual"
                techCrews={techCrews}
                history={refreshHistory}
                hasAdminPermissions={hasAdminPermissions}
              />
            }
          />
          {(hasAnalysisEditPermissions || hasAdminPermissions) && (
            <Route
              path="settings/*"
              element={
                <AnalysisSettingsView
                  project={currentProject}
                  techCrews={techCrews}
                  history={refreshHistory}
                  licenseExpired={isLicenseExpired}
                  user={user}
                  hasAdminPermissions={hasAdminPermissions}
                  isLoading={isPending}
                />
              }
            />
          )}
          <Route index element={<Navigate to="targetActual" replace />} />
        </Routes>
      </main>
    </div>
  );
};
