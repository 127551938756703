import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ReactNode } from 'react';

import { cn } from 'src/common/utils';
import { WeatherWidget } from 'src/common/components/WeatherWidget';
import { getFiveDayWeatherForecast } from 'src/Conrep/App/Report/DayInfo/Input/api';
import MapEmpty from 'src/assets/illu_map-empty.svg';
import UserEmpty from 'src/assets/illu_user-empty.svg';
import WeatherEmpty from 'src/assets/dailyReport/illu_weather-empty.svg';
import DiaryEmpty from 'src/assets/illu_diary-empty.svg';
import { Button } from 'src/common/components/buttons/Button';
import { Container } from 'src/common/components/Container';
import { isProjectOwnerOrAdmin } from 'src/user/utils';
import { BasicProjectInfo } from '../components/BasicProjectInfo';
import { getOpenWorkDays } from '../api/projectsApi';
import { OpenWorkDays } from '../components/OpenWorkDays';
import { UserList } from '../components/UserList';
import {
  GetProjectResponse,
  LicenseEvaluationType,
  LicenseType,
  WeatherProviders,
} from '../types/projects';
import { validateLicense } from '../utils';
import { GoogleMaps } from '../components/GoogleMaps';
import { useProjectUsersQuery } from '../queries';
import { LicenseExpiredMessage } from '../components/LicenseExpiredMessage';
import { Card } from 'src/common/components/Card';
import { useUser } from 'src/auth/user-context';
import { useProject } from '../project-context';

export const ProjectOverviewView = () => {
  const project = useProject();
  const { t } = useTranslation('project');
  const user = useUser();

  const isLocationAvailable = Boolean(
    (project.projectInformation?.latitude &&
      project.projectInformation?.longitude) ||
      (project.projectInformation?.country &&
        project.projectInformation?.city &&
        project.projectInformation?.street),
  );

  const isFreeProjet = project?.license?.type === LicenseType.FREE;
  const licenseStatus = validateLicense(project?.license);
  const isLicenseExpired = licenseStatus === LicenseEvaluationType.EXPIRED;
  const hasAdminPrivileges = isProjectOwnerOrAdmin(user._id, project);

  return (
    <Container className="h-full" variant="box">
      <div className="pb-8 pt-4">
        {isLicenseExpired && <LicenseExpiredMessage />}
        <div
          className={cn('grid gap-4', {
            'mt-5': isLicenseExpired,
            'mt-4': !isLicenseExpired,
          })}
          style={{
            gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
          }}
        >
          <Card className="h-[363px] md:col-span-2">
            <BasicProjectInfo />
          </Card>

          {isLocationAvailable ? (
            <Card className="h-[363px] overflow-hidden">
              <GoogleMaps
                lat={project.projectInformation?.latitude}
                long={project.projectInformation?.longitude}
                street={project.projectInformation?.street}
                city={project.projectInformation?.city}
                country={project.projectInformation?.country}
              />
            </Card>
          ) : (
            <EmptyCard
              img={MapEmpty}
              actionLink={
                hasAdminPrivileges
                  ? `/p/${project._id}/dashboard/settings`
                  : undefined
              }
              actionText={t('overview.mapsCard.noDataLink')}
            >
              {t(
                `overview.mapsCard.noData${!hasAdminPrivileges ? 'User' : ''}`,
              )}
            </EmptyCard>
          )}
          {hasAdminPrivileges ? <UsersListCard project={project} /> : null}

          <WorkdaysCard projectId={project._id} isAdmin={hasAdminPrivileges} />

          {!isFreeProjet && (
            <WeatherCard
              project={project}
              hasAdminPrivileges={hasAdminPrivileges}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

const WeatherCard = ({
  project,
  hasAdminPrivileges,
}: {
  project: GetProjectResponse;
  hasAdminPrivileges: boolean;
}) => {
  const { t } = useTranslation('project');

  const { _id: projectId } = project;
  const { data: weatherData } = useQuery({
    queryKey: ['projects', projectId, 'weather'],
    queryFn: () => getFiveDayWeatherForecast(projectId),
  });

  const noCoordinates =
    !project.projectInformation?.latitude ||
    !project.projectInformation?.longitude;

  if (noCoordinates) {
    return (
      <EmptyCard
        img={WeatherEmpty}
        actionLink={
          hasAdminPrivileges
            ? `/p/${project._id}/dashboard/settings`
            : undefined
        }
        actionText={t('overview.weatherCard.noCoordinatesLink')}
      >
        {t(
          `overview.weatherCard.noCoordinates${
            !hasAdminPrivileges ? 'User' : ''
          }`,
        )}
      </EmptyCard>
    );
  }

  const noWeatherProvider =
    !project.weatherSettings ||
    project.weatherSettings.weatherProvider === WeatherProviders.NONE;

  if (noWeatherProvider) {
    return (
      <EmptyCard
        img={WeatherEmpty}
        actionLink={
          hasAdminPrivileges
            ? `/p/${project._id}/dashboard/settings/weather`
            : undefined
        }
        actionText={t('overview.weatherCard.noProviderLink')}
      >
        {t(
          `overview.weatherCard.noProvider${!hasAdminPrivileges ? 'User' : ''}`,
        )}
      </EmptyCard>
    );
  }

  return (
    <Card className="h-[363px] pl-6 pr-4 pt-4">
      {weatherData && weatherData?.length > 0 ? (
        <WeatherWidget weatherForecast={weatherData} showForecast={true} />
      ) : (
        <div className="flex h-full flex-col justify-center text-ellipsis text-center">
          {t('overview.weatherCard.noWeather')}
        </div>
      )}
    </Card>
  );
};

const WorkdaysCard = ({
  isAdmin,
  projectId,
}: {
  isAdmin: boolean;
  projectId: string;
}) => {
  const { t } = useTranslation('project');
  const { data: workDaysData } = useQuery({
    queryKey: ['projects', projectId, 'open-workdays'],
    queryFn: () => getOpenWorkDays(projectId),
    initialData: [],
    staleTime: 0,
  });

  const workdayCount = workDaysData.reduce((sum, w) => sum + w.workDays, 0);

  if (workdayCount > 0) {
    return (
      <Card className="h-[363px] p-6">
        <OpenWorkDays workDaysData={workDaysData} />
      </Card>
    );
  }

  return (
    <EmptyCard
      img={DiaryEmpty}
      actionLink={isAdmin ? `/p/${projectId}/conrep/app` : undefined}
      actionText={t('overview.workdayCard.noDataLink')}
    >
      {t(`overview.workdayCard.noData${!isAdmin ? 'User' : ''}`)}
    </EmptyCard>
  );
};

const UsersListCard = ({ project }: { project: GetProjectResponse }) => {
  const { t } = useTranslation('project');
  const { data: projectUsers } = useProjectUsersQuery(project._id);

  if (projectUsers.length > 1) {
    return (
      <Card className="flex h-[363px] flex-col pt-8">
        <h2 className="mb-2 flex px-8 text-2xl font-semibold">
          {t('overview.userCard.title')}
        </h2>
        <div className="flex grow overflow-y-scroll px-8">
          <UserList className="w-full" users={projectUsers} />
        </div>
      </Card>
    );
  }

  return (
    <EmptyCard
      img={UserEmpty}
      actionLink={`/p/${project._id}/dashboard/users`}
      actionText={t('overview.userCard.noDataLink')}
    >
      {t(`overview.userCard.noData`)}
    </EmptyCard>
  );
};

type EmptyCardProps = {
  children: ReactNode;
  img: string;
  actionLink?: string;
  actionText?: string;
};

const EmptyCard = ({
  children,
  img,
  actionLink,
  actionText,
}: EmptyCardProps) => (
  <Card className="flex min-h-[350px] flex-col justify-center p-6 text-center">
    <div className="flex h-1/2 flex-col justify-end">
      <img className="mx-auto mb-4" src={img} alt="" />
    </div>
    <div className="flex h-1/2 flex-grow flex-col justify-start">
      <p className="mb-5 text-shuttleGray-600">{children}</p>
      {actionLink && actionText && (
        <Link to={actionLink}>
          <Button variant="secondary" size="sm">
            {actionText}
          </Button>
        </Link>
      )}
    </div>
  </Card>
);
