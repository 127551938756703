import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import StartTradesIllustration from 'src/assets/illu_start_trades.svg';
import { Button } from 'src/common/components/buttons/Button';
import { Tooltip } from 'src/common/components/Tooltip';
import { isProjectOwnerOrAdmin } from 'src/user/utils';
import { Card } from 'src/common/components/Card';
import { useUser } from 'src/auth/user-context';
import { useProject } from '../project-context';

export const NoTechCrew = ({ className }: { className?: string }) => {
  const { t } = useTranslation('project');
  const user = useUser();
  const project = useProject();

  const isProjectAdmin = isProjectOwnerOrAdmin(user._id, project);

  return (
    <Card className={className}>
      <div className="mx-auto my-6 max-w-[500px] text-center">
        <img className="mx-auto mb-8" src={StartTradesIllustration} alt="" />
        <h1 className="my-4 text-4xl font-semibold">
          {t('noTechCrewMessage.title')}
        </h1>
        <div className="mb-6 text-shuttleGray-600">
          {t('noTechCrewMessage.text')}
        </div>
        {isProjectAdmin ? (
          <Link to={`/p/${project._id}/dashboard/techcrews`}>
            <Button>{t('noTechCrewMessage.buttonText')}</Button>
          </Link>
        ) : (
          <Tooltip content={t('noTechCrewMessage.tooltipText')}>
            <div>
              <Link to={`/p/${project._id}/dashboard/techcrews`}>
                <Button disabled>{t('noTechCrewMessage.buttonText')}</Button>
              </Link>
            </div>
          </Tooltip>
        )}
      </div>
    </Card>
  );
};
