import { ComponentPropsWithRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { Modal } from 'src/common/components/Modal';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import toast from 'src/common/toast';
import { useCompanyQuery } from 'src/company/queries';
import { LicenseType } from 'src/project/types/projects';
import { ProjectLicensePicker } from './ProjectLicensePicker';
import { CreateProjectLoadingState } from './types';
import { createNewProject, trackProjectCreationAnalytics } from './utils';

type Props = {
  companyId?: string;
  onClose: () => void;
};

export const ProjectCreateModal = ({
  companyId,
  onClose,
  isOpen,
  ...rest
}: Props & ComponentPropsWithRef<typeof Modal>) => {
  const { t } = useTranslation(['common', 'project']);
  const queryClient = useQueryClient();

  const [name, setName] = useState('');
  const [loading, setLoading] = useState<CreateProjectLoadingState>('idle');

  const navigate = useNavigate();

  const { data: userCompany } = useCompanyQuery(companyId ?? '');
  if (!userCompany) {
    return null;
  }

  const handleProjectCreate = async (
    licenseType: Exclude<LicenseType, LicenseType.DEMO>,
  ) => {
    const loadingState =
      licenseType === LicenseType.BASIC
        ? 'creatingBasicProject'
        : 'creatingFreeProject';
    setLoading(loadingState);
    try {
      // call backend to create project and track with amplitude
      const { _id: projectId, name: projectName } = await createNewProject(
        name,
        licenseType,
        userCompany._id,
      );
      trackProjectCreationAnalytics(projectId, projectName);
      // handle post creation tasks
      toast.success(t('project:createForm.view.successMessage'));
      navigate(`/p/${projectId}/dashboard/overview`);
      queryClient.invalidateQueries({
        queryKey: ['projects'],
      });
    } catch (err) {
      toast.error(t('project:createForm.view.failMessage'));
    } finally {
      setLoading('idle');
      onClose();
    }
  };

  const isNameEmpty = name === '';
  const disableLicenseButtons = isNameEmpty || loading !== 'idle';
  const isLoadingFreeProject = loading === 'creatingFreeProject';
  const isLoadingBasicProject = loading === 'creatingBasicProject';

  return (
    <Modal {...rest} isOpen={isOpen} className="w-[672px]">
      <Modal.Header onClose={onClose}>
        {t('project:listView.createProject')}
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-4">
          <TextInput
            label={t('project:createForm.view.projectName')}
            value={name}
            required={true}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <ProjectLicensePicker
            handleProjectCreate={handleProjectCreate}
            disableLicenseButtons={disableLicenseButtons}
            isLoadingFreeProject={isLoadingFreeProject}
            isLoadingBasicProject={isLoadingBasicProject}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
