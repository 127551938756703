import { User, UserProfileResponse } from './types';
import { DownloadRef, PatchRef } from 'src/common/types';
import { api } from 'src/common/fetch';

export async function getUserProfile(userId: string) {
  return api.get<UserProfileResponse>(`/api/u/${userId}`);
}

export async function getOtherUserProfile(userId: string) {
  return api.get<UserProfileResponse>(`/api/users/${userId}`);
}

export async function deleteUser(id: string, password: string) {
  return api.delete(`/api/users/${id}`, { password });
}

export async function getUserImage(userId: string) {
  return api.get<DownloadRef>(`/api/users/${userId}/image`);
}

export async function editUserImage(userId: string, extension: string) {
  return api.post<PatchRef>(`/api/users/${userId}/image`, { extension });
}

export async function deleteUserImage(userId: string) {
  return api.delete(`/api/users/${userId}/image`);
}

export async function updateUser(userId: string, userData: Partial<User>) {
  return api.post(`/api/users/${userId}`, userData);
}
