import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import React from 'react';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

import './index.css'; // do not move this import after the App import

import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { App } from 'src/app/App';
import { store } from './app/store';
import * as analytics from './analytics';
import * as errorTracking from './error-tracking';
import * as dayjs from './dayjs';
import * as i18n from './i18n';
import { SomethingWentWrong } from 'src/common/components/SomethingWentWrong';
import 'react-calendar/dist/Calendar.css';
import { queryClient } from './react-query';

Modal.setAppElement('#root');

errorTracking.init();
analytics.init();
dayjs.init();
i18n.init();

const sitelifeRoot = (
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<SomethingWentWrong />}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

const root = document.getElementById('root');
if (root === null) {
  throw new Error('Root element is null');
}

/**
 * HACK!
 * due to an issue with bryntum/gantt in combination with react@18,
 * we need to use react17 in local development, until bryntum gantt is upgraded
 */
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(sitelifeRoot, document.getElementById('root'));
} else {
  createRoot(root).render(sitelifeRoot);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
