import { useEffect, useMemo, useRef } from 'react';
import { Chart } from 'chart.js';
import { useTranslation } from 'react-i18next';

import { OpenWorkDaysResponse } from '../types/projects';

type WorkDaysData = {
  open: Array<number>;
  locked: Array<number>;
  days: Array<number>;
  totalOpen: number;
  totalLocked: number;
  totalDays: number;
  labels: Array<string>;
  colors: Array<string>;
};

type Props = {
  workDaysData: OpenWorkDaysResponse[];
};

export const OpenWorkDays = ({ workDaysData: workDaysDataResponse }: Props) => {
  const { t } = useTranslation('project');
  const chart = useRef<Chart<'doughnut'> | null>(null);

  const workDaysData: WorkDaysData = useMemo(() => {
    let workDays = workDaysDataResponse.sort(
      (workDay, otherWorkDay) =>
        otherWorkDay.workDays -
        otherWorkDay.lockedWorkDays -
        (workDay.workDays - workDay.lockedWorkDays),
    );
    const majorSplitPoint = 3;

    if (workDays.length > majorSplitPoint + 1) {
      const majorWorkDays = workDays.slice(0, majorSplitPoint);
      const minorWorkDays = workDays.slice(majorSplitPoint);

      workDays = [
        ...majorWorkDays,
        {
          techCrewId: 'others',
          techCrewName: t('overview.openWorkDaysCard.furtherTrades'),
          techCrewColor: '#B0CECE',
          workDays: minorWorkDays
            .map((workDay) => workDay.workDays)
            .reduce((partialSum, number) => partialSum + number, 0),
          lockedWorkDays: minorWorkDays
            .map((workDay) => workDay.lockedWorkDays)
            .reduce((partialSum, number) => partialSum + number, 0),
        },
      ];
    }

    const open = workDays.map(
      (workDay) => workDay.workDays - workDay.lockedWorkDays,
    );

    const locked = workDays.map((data) => data.lockedWorkDays);

    const days = workDays.map((workDay) => workDay.workDays);

    const totalOpen = open.reduce(
      (partialSum, number) => partialSum + number,
      0,
    );
    const totalLocked = locked.reduce(
      (partialSum, number) => partialSum + number,
      0,
    );
    const totalDays = days.reduce(
      (partialSum, number) => partialSum + number,
      0,
    );

    const labels = workDays.map((workDay) => workDay.techCrewName);

    const colors = workDays.map((workDay) => workDay.techCrewColor);

    return {
      open,
      locked,
      days,
      totalOpen,
      totalLocked,
      totalDays,
      labels,
      colors,
    };
  }, [t, workDaysDataResponse]);

  useEffect(() => {
    if (chart.current) {
      chart.current.destroy();
    }
    const ctx = document.getElementById('pieChart') as HTMLCanvasElement;

    const data = {
      datasets: [
        {
          data:
            workDaysData.totalDays > 0
              ? [...workDaysData.open, workDaysData.totalLocked]
              : [1],
          backgroundColor: [...workDaysData.colors, '#FCA864'], // hardcoded orange-400 for now, should be imported from tailwind.config.js in the future
        },
      ],
      labels:
        workDaysData.totalDays > 0
          ? [...workDaysData.labels, t('overview.openWorkDaysCard.locked')]
          : [t('overview.openWorkDaysCard.noData')],
    };

    chart.current = new Chart(ctx, {
      type: 'doughnut',
      data,
      options: {
        datasets: { doughnut: { borderWidth: 0 } },
        cutout: '60%',
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right',
            labels: {
              boxWidth: 16,
              boxHeight: 16,
              color: 'black',
              font: {
                size: 12,
              },
              padding: 8,
            },
            onClick: () => undefined,
          },
        },
      },
    });
  }, [workDaysData, t]);

  return (
    <div className="flex h-full w-full flex-col gap-6">
      <div className="text-2xl font-semibold">
        {t('overview.openWorkDaysCard.title')}
      </div>
      <div className="mb-[11px] overflow-hidden">
        <canvas id="pieChart" />
      </div>
      <div className="flex w-auto gap-4">
        <div className="flex w-2/4 flex-col bg-gray-100 px-4 py-2">
          <div className="text-shuttleGray-600">
            {t('overview.openWorkDaysCard.open')}
          </div>
          <div className="text-2xl text-shuttleGray-800">
            {workDaysData.totalOpen} {t('overview.openWorkDaysCard.days')}
          </div>
        </div>
        <div className="flex w-2/4 flex-col bg-gray-100 px-4 py-2">
          <div className="text-shuttleGray-600">
            {t('overview.openWorkDaysCard.locked')}
          </div>
          <div className="text-2xl text-shuttleGray-800">
            {workDaysData.totalLocked} {t('overview.openWorkDaysCard.days')}
          </div>
        </div>
      </div>
    </div>
  );
};
