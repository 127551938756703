import {
  faEdit,
  faPlus,
  faTrash,
  faUpload,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import IllustrationUrl from 'src/assets/illu_no-ss.svg';
import { resolveLanguageForUrl } from 'src/auth/util';
import { Card } from 'src/common/components/Card';
import { Chip } from 'src/common/components/Chip';
import { DeleteModal } from 'src/common/components/DeleteModal';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { Modal } from 'src/common/components/Modal';
import { SidebarLayout } from 'src/common/components/SidebarLayout';
import { Spinner } from 'src/common/components/Spinner';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Button } from 'src/common/components/buttons/Button';
import { TextAreaInput } from 'src/common/components/inputs/TextAreaInput/TextAreaInput';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import i18n from 'src/i18n';
import {
  createServiceSpecification,
  deleteServiceSpecification,
  parseServiceSpecification,
  updateServiceSpecification,
  uploadServiceSpecificationToS3,
} from '../api/projectsApi';
import { SettingsSidebar } from '../components/SettingsSidebar';
import { LV_FILE_EXTENSIONS } from '../constants';
import { useServiceSpecificationsQuery } from '../queries';
import {
  CreateCustomServiceSpecificationRequest,
  CreateServiceSpecificationRequest,
  CustomServiceSpecification,
  NewServiceSpecification,
  ServiceSpecificationType,
  UpdateServiceSpecificationRequest,
} from '../types/projects';

export const ServiceSpecificationsView = ({
  isLicenseExpired,
}: {
  isLicenseExpired: boolean;
}) => {
  const { t } = useTranslation(['project', 'common']);
  const { projectId = '' } = useParams();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { data: serviceSpecifications } =
    useServiceSpecificationsQuery(projectId);

  return (
    <SidebarLayout
      header={
        <div className="flex">
          <div className="mr-2">
            <h3 className="mb-1 text-2xl font-semibold">
              {t('settings.serviceSpecifications.title')}
            </h3>
            <p className="text-shuttleGray-600">
              {t('settings.serviceSpecifications.description')}
            </p>
          </div>
          <div className="ml-auto flex items-end gap-2">
            <Button
              variant="secondary"
              disabled={isLicenseExpired}
              onClick={() => setIsCreateModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
              {t('settings.serviceSpecifications.createButton')}
            </Button>
            <Button
              disabled={isLicenseExpired}
              onClick={() => setIsUploadModalOpen(true)}
            >
              <FontAwesomeIcon icon={faUpload} />
              {t('settings.serviceSpecifications.uploadButton')}
            </Button>
          </div>
        </div>
      }
      sidebar={<SettingsSidebar />}
    >
      <Card>
        {!serviceSpecifications || serviceSpecifications.length > 0 ? (
          !serviceSpecifications ? (
            <Spinner containerClassName="w-full my-[160px] text-center" />
          ) : (
            <table className="w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="table-th">{t('common:labels.name')}</th>
                  <th className="table-th">{t('common:labels.file')}</th>
                  <th className="table-th">{t('common:labels.date')}</th>
                  <th className="table-th" />
                </tr>
              </thead>
              <tbody className="table-body">
                {serviceSpecifications.map((ss) => (
                  <ServiceSpecificationRow
                    key={ss._id}
                    serviceSpecification={ss}
                    disabled={isLicenseExpired}
                  />
                ))}
              </tbody>
            </table>
          )
        ) : (
          <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
            {t('settings.serviceSpecifications.noServiceSpecifications')}
          </p>
        )}
      </Card>

      <CreateModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />
      <UploadModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
      />
    </SidebarLayout>
  );
};

const isCustomServiceSpecification = (
  serviceSpecification: NewServiceSpecification,
): serviceSpecification is CustomServiceSpecification => {
  return (
    serviceSpecification.specificationType === ServiceSpecificationType.CUSTOM
  );
};

const ServiceSpecificationRow = ({
  serviceSpecification,
  disabled,
}: {
  serviceSpecification: NewServiceSpecification;
  disabled: boolean;
}) => {
  const { t } = useTranslation(['project', 'common']);
  const { projectId = '' } = useParams();
  const queryClient = useQueryClient();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const filename = !isCustomServiceSpecification(serviceSpecification)
    ? serviceSpecification.originalFilename
    : null;

  const handleDelete = async (id: string) => {
    try {
      await deleteServiceSpecification(projectId, id);
      queryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'serviceSpecifications'],
      });
      toast.success(t('common:success.delete'));
    } catch (e) {
      toast.error(t('common:errors.delete'));
    }
    setIsDeleteModalOpen(false);
  };

  return (
    <tr key={serviceSpecification._id}>
      <td className="table-td max-w-[200px] truncate font-semibold">
        {serviceSpecification.name}
      </td>
      <td className="table-td max-w-[200px] truncate">{filename}</td>
      <td className="table-td">
        {dayjs(serviceSpecification.createdAt).format('L')}
      </td>
      <td className="table-td">
        <div className="flex justify-end">
          {isCustomServiceSpecification(serviceSpecification) && (
            <ActionIcon
              type="button"
              disabled={disabled}
              onClick={() => setIsEditModalOpen(true)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </ActionIcon>
          )}
          <ActionIcon
            type="button"
            disabled={disabled}
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ActionIcon>
        </div>
      </td>
      {isCustomServiceSpecification(serviceSpecification) && (
        <EditModal
          serviceSpecification={serviceSpecification}
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
      <DeleteModal
        title={t('settings.serviceSpecifications.deleteSSTitle')}
        subtitle={t('settings.serviceSpecifications.deleteSSMessage')}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        deleteButton={async () => {
          await handleDelete(serviceSpecification._id);
          setIsDeleteModalOpen(false);
        }}
      />
    </tr>
  );
};

type CustomServiceSpecificationEntries = Omit<
  CustomServiceSpecification['parsedData']['custom'][0],
  '_id'
>[];

type FormState = {
  name: string;
  description: string;
};

const CustomServiceSpecificationForm = ({
  serviceSpecification,
  onSubmit,
  onClose,
}: {
  serviceSpecification?: CustomServiceSpecification;
  onSubmit: (
    formData: FormState,
    ssMap: CustomServiceSpecificationEntries,
  ) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation(['project', 'common']);
  const { register, handleSubmit } = useForm<FormState>({
    defaultValues: {
      name: serviceSpecification?.name || '',
      description: serviceSpecification?.description || '',
    },
  });

  const [ssMap, setSsMap] = useState<CustomServiceSpecificationEntries>(
    serviceSpecification ? serviceSpecification.parsedData.custom : [],
  );
  const [newKey, setNewKey] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const keyInputEl = useRef<HTMLInputElement>(null);

  const addRow = (e: FormEvent) => {
    e.preventDefault();

    setSsMap((prev) => [
      ...prev,
      {
        key: newKey,
        description: newDescription,
      },
    ]);
    setNewKey('');
    setNewDescription('');

    keyInputEl.current?.focus();
  };

  const deleteRow = (key: string) => {
    setSsMap((prev) => prev.filter((ss) => ss.key !== key));
  };

  const isNewRowValid =
    newKey && newDescription && !ssMap.map((ss) => ss.key).includes(newKey);

  return (
    <>
      <form
        id="ssForm"
        onSubmit={handleSubmit((e) => onSubmit(e, ssMap))}
        className="mb-4"
      >
        <TextInput
          required
          autoFocus
          label={t('common:labels.name')}
          className="mb-4 w-full"
          {...register('name')}
        />
        <TextInput
          label={t('common:labels.description')}
          className="w-full"
          {...register('description')}
        />
      </form>
      <form onSubmit={addRow}>
        <table className="divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="table-th">{t('common:labels.key')}</th>
              <th className="table-th">{t('common:labels.description')}</th>
              <th className="table-th" />
            </tr>
          </thead>
          <tbody className="table-body">
            {ssMap.map((entry) => (
              <tr key={entry.key}>
                <td className="table-td max-w-[200px] truncate">{entry.key}</td>
                <td className="table-td max-w-[200px] truncate">
                  {entry.description}
                </td>
                <td className="table-td text-right">
                  <ActionIcon
                    type="button"
                    onClick={() => deleteRow(entry.key)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </ActionIcon>
                </td>
              </tr>
            ))}
            <tr>
              <td className="table-td">
                <TextInput
                  required
                  className="w-full"
                  ref={keyInputEl}
                  value={newKey}
                  onChange={(e) => setNewKey(e.currentTarget.value)}
                  error={ssMap.map((ss) => ss.key).includes(newKey)}
                />
              </td>
              <td className="table-td">
                <TextInput
                  required
                  className="w-full"
                  value={newDescription}
                  onChange={(e) => setNewDescription(e.currentTarget.value)}
                />
              </td>
              <td className="table-td text-right">
                <Button
                  type="submit"
                  className="-mt-1"
                  disabled={!isNewRowValid}
                  size="sm"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <div className="mt-4 flex justify-end gap-2">
        <Button variant="tertiary" onClick={onClose}>
          {t('common:button.abort')}
        </Button>
        <Button form="ssForm" type="submit">
          {t('common:button.save')}
        </Button>
      </div>
    </>
  );
};

const EditModal = ({
  serviceSpecification,
  isOpen,
  onClose,
}: {
  serviceSpecification: CustomServiceSpecification;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation(['common', 'project']);
  const { projectId = '' } = useParams();
  const queryClient = useQueryClient();

  const handleSubmit = async (
    formData: FormState,
    ssMap: CustomServiceSpecificationEntries,
  ) => {
    const payload: UpdateServiceSpecificationRequest = {
      ...formData,
      parsedData: {
        custom: ssMap,
      },
    };

    try {
      await updateServiceSpecification(
        projectId,
        serviceSpecification._id,
        payload,
      );
      queryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'serviceSpecifications'],
      });
      toast.success(t('success.save'));
      onClose();
    } catch (error) {
      toast.error(t('errors.save'));
    }
  };

  return (
    <Modal isOpen={isOpen} className="w-[600px]">
      <Modal.Header onClose={onClose}>
        {t('project:settings.serviceSpecifications.editServiceSpecification')}
      </Modal.Header>
      <Modal.Body>
        <CustomServiceSpecificationForm
          onClose={onClose}
          onSubmit={handleSubmit}
          serviceSpecification={serviceSpecification}
        />
      </Modal.Body>
    </Modal>
  );
};

const CreateModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation(['common', 'project']);
  const { projectId = '' } = useParams();
  const queryClient = useQueryClient();

  const handleSubmit = async (
    formData: FormState,
    ssMap: CustomServiceSpecificationEntries,
  ) => {
    const payload: CreateCustomServiceSpecificationRequest = {
      ...formData,
      specificationType: ServiceSpecificationType.CUSTOM,
      parsedData: {
        custom: ssMap,
      },
    };

    try {
      await createServiceSpecification(projectId, payload);
      queryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'serviceSpecifications'],
      });
      toast.success(t('success.create'));
      onClose();
    } catch (error) {
      toast.error(t('errors.create'));
    }
  };

  return (
    <Modal isOpen={isOpen} className="w-[600px]">
      <Modal.Header onClose={onClose}>
        {t('project:settings.serviceSpecifications.createServiceSpecification')}
      </Modal.Header>
      <Modal.Body>
        <CustomServiceSpecificationForm
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};

async function uploadOnlv(variables: {
  projectId: string;
  payload: CreateServiceSpecificationRequest;
  file: File;
}) {
  const { projectId, payload, file } = variables;
  const res = await createServiceSpecification(projectId, payload);
  if (!res.uploadUrl) {
    throw new Error('missing upload url');
  }
  await uploadServiceSpecificationToS3(res.uploadUrl, file);
  await parseServiceSpecification(projectId, res.created._id);
  return;
}

const UploadModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation(['project', 'common', 'file']);
  const queryClient = useQueryClient();
  const { projectId = '' } = useParams();
  const fileEl = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const { register, handleSubmit, reset } = useForm<FormState>();
  const [invalidFileType, setInvalidFileType] = useState<boolean>();
  const [invalidFileName, setInvalidFileName] = useState<boolean>();
  const [duplicateFileName, setDuplicateFileName] = useState<boolean>();

  const { data: serviceSpecifications } =
    useServiceSpecificationsQuery(projectId);

  const mutation = useMutation({
    mutationFn: uploadOnlv,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'serviceSpecifications'],
      });
      toast.success(t('common:success.create'));
      removeSelectedFile();
      reset();
      onClose();
    },
    onError: () => {
      toast.error(t('common:errors.create'));
      removeSelectedFile();
      reset();
      onClose();
    },
  });

  const openFileDialog = () => {
    if (!fileEl.current) return;
    fileEl.current.click();
  };

  const removeSelectedFile = () => {
    if (!fileEl.current) return;
    fileEl.current.value = '';
    setFile(null);
  };

  const preventEnterKeySubmission = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInvalidFileType(false);
    setInvalidFileName(false);
    const selectedFile = e.currentTarget.files?.[0];
    if (!selectedFile) return;

    // check for invalid extension type
    const fileExtension = selectedFile.name.substring(
      selectedFile.name.lastIndexOf('.'),
    );

    if (!LV_FILE_EXTENSIONS.includes(fileExtension.toLocaleLowerCase())) {
      setInvalidFileType(true);
      return;
    }

    setFile(selectedFile);
  };

  const handleFormValid = (formData: FormState) => {
    if (!file) return;

    const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
    let isDuplicateFileName = false;

    if (!LV_FILE_EXTENSIONS.includes(fileExtension.toLocaleLowerCase())) {
      return;
    }

    const serviceSpecType =
      fileExtension.toLocaleLowerCase() === '.onlv'
        ? ServiceSpecificationType.ONLV
        : ServiceSpecificationType.GAEB;

    // check for duplicate in Names
    serviceSpecifications?.forEach((serviceSpecification) => {
      if (serviceSpecification.name === formData.name) {
        isDuplicateFileName = true;
        return;
      }
    });

    if (!isDuplicateFileName) {
      const payload: CreateServiceSpecificationRequest = {
        name: formData.name,
        description: formData.description,
        originalFilename: file.name,
        fileExtension,
        specificationType: serviceSpecType,
      };

      mutation.mutate({ projectId, payload, file });
    } else {
      setDuplicateFileName(true);
      return;
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="w-[480px]">
      <Modal.Header
        onClose={() => {
          onClose();
          setInvalidFileType(false);
          setDuplicateFileName(false);
          setInvalidFileName(false);
        }}
      >
        {t('project:settings.serviceSpecifications.uploadButton')}
      </Modal.Header>
      <Modal.Body>
        {' '}
        <input
          ref={fileEl}
          type="file"
          accept={LV_FILE_EXTENSIONS}
          onChange={handleFileChange}
          className="sr-only"
        />
        {!file ? (
          <>
            <p>
              {t('project:settings.serviceSpecifications.uploadSSDescription')}
            </p>
            {(invalidFileName || invalidFileType) && (
              <InlineMessage variant="error" className="mt-6">
                {invalidFileName && t('file:upload.modal.invalidFileName')}
                {invalidFileType &&
                  t('file:upload.modal.uploadCorrectFileType', {
                    fileExtension:
                      resolveLanguageForUrl(i18n.language) === 'en'
                        ? '.onlv or .x8*'
                        : '.onlv oder .x8*',
                  })}
              </InlineMessage>
            )}
            <div className="text-center">
              <img
                src={IllustrationUrl}
                alt=""
                className="mx-auto mt-16 h-[120px]"
              />
              <Button className="mb-12 mt-8" onClick={openFileDialog}>
                {t('common:button.selectFile')}
              </Button>
            </div>
          </>
        ) : (
          <form onSubmit={handleSubmit(handleFormValid)}>
            <Chip onClose={removeSelectedFile}>{file.name}</Chip>
            <TextInput
              required
              label={t('common:labels.name')}
              className="mb-4 mt-6 w-full"
              onKeyDown={preventEnterKeySubmission}
              error={
                duplicateFileName &&
                t('file:upload.uploadBillOfQuantitiesModal.nameTaken')
              }
              {...register('name')}
            />
            <TextAreaInput
              label={t('common:labels.description')}
              className="w-full"
              {...register('description')}
            />
            <div className="flex justify-end">
              <Button
                className="mt-6"
                type="submit"
                disabled={mutation.isPending}
                loading={mutation.isPending}
              >
                <FontAwesomeIcon icon={faUpload} />
                {t('common:button.uploadFile')}
              </Button>
            </div>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};
