import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import chroma from 'chroma-js';

import {
  getElementIdOfExternalId,
  getObjectTreeLeafComponentIds,
} from 'src/Forge/utils';
import { FileVersionViewReference } from 'src/file/types';
import { Action } from 'src/project/types/actions';
import {
  ActionFilter,
  ConrepAppFilterPreset,
  ConrepAppFilterPresetDateRestriction,
  ConrepAppFilterPresetDateType,
  ConrepAppFilterPresetValue,
  ConrepAppFilterPresetValueTypes,
  ConrepAppPropertySets,
  ConrepAppReduxFilter,
  ConrepAppWorkday,
  ConrepAppWorkdayElements,
  ConrepAppWorkdayNumbers,
  CustomFilter,
  ElementAction,
} from '../../types';
import { isStringArray } from 'src/common/utils';
import {
  ForgeViewerDbIdToExternalIdMapping,
  ForgeViewerExternalIdToDbIdMapping,
} from 'src/Forge/types';

const EMPTY_STRING_LABEL = '∅';

export function getCustomFilterValues(param: string, filterTmp: CustomFilter) {
  if (!filterTmp || !filterTmp[param]) {
    return [];
  }
  return filterTmp[param].map((cf) => ({
    value: cf,
    label: cf === '' ? EMPTY_STRING_LABEL : cf,
    param,
  }));
}

function compareAlphabeticallyAsc(a: any, b: any) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

// NOTE: at the moment this is done for every element to check the status
// but we probably only need to store it once the workday is decided (?)
function getElementStatusUntilDate(
  elementActions: ElementAction[],
  date: string,
  conrepWorkdayNumbers: ConrepAppWorkdayNumbers | null,
  allActions: Action[],
) {
  // filter for only actions till the current workday

  const elementActionsFiltered = elementActions.filter(
    (x: any) => x.workday && x.workday.date && x.workday.date <= date,
  );

  // sort elementActions by date
  elementActionsFiltered.sort((a: any, b: any) => {
    if (a.workday.date < b.workday.date) return -1;
    return 1;
  });

  const actionsMap: Record<string, any> = {};
  Object.keys(elementActionsFiltered).forEach((elementActionId) => {
    const elementAction = elementActionsFiltered[Number(elementActionId)];
    const workdayId: string = (elementAction.workday as any)._id;
    if (workdayId && conrepWorkdayNumbers) {
      const actionWorkdayInfo = conrepWorkdayNumbers.find(
        (x) => x._id === workdayId,
      );

      if (
        actionWorkdayInfo &&
        dayjs(actionWorkdayInfo.date).isSameOrBefore(dayjs(date), 'day')
      ) {
        const action: any = allActions.find(
          (x: any) => x._id === elementAction.action,
        );

        actionsMap[action._id] = {};
        actionsMap[action._id].name = action.name;
        actionsMap[action._id].value = elementAction.value;
        actionsMap[action._id].color = { color: action.color };
        actionsMap[action._id].workday = {
          isLocked: actionWorkdayInfo.isLocked,
          number: actionWorkdayInfo.number,
          date: actionWorkdayInfo.date,
        };
        actionsMap[action._id].createdAt = action.updatedAt
          ? action.updatedAt
          : action.createdAt;
      }
    }
  });

  return actionsMap;
}

export async function getExternalIdsForDate(
  conrepWorkdayData: ConrepAppWorkday,
  conrepWorkdayElements: ConrepAppWorkdayElements | null,
  conrepWorkdayNumbers: ConrepAppWorkdayNumbers | null,
  conrepDbIdToExternalIdMapping: ForgeViewerDbIdToExternalIdMapping | null,
  onlySpecificDay = false,
  actionArray: Action[] | string[] | null = null,
  inverseFilter = false,
  actions: Action[],
) {
  if (!conrepWorkdayData) {
    return null;
  }
  let filteredExtIds: any[] = [];

  if (onlySpecificDay) {
    // all elements on a specific day
    if (actionArray) {
      const filteredElementActions = (
        conrepWorkdayData.elementActions as ElementAction[]
      ).filter((x) => (actionArray as string[]).indexOf(x.action) > -1);

      filteredExtIds = filteredElementActions.map((ea: any) =>
        getElementIdOfExternalId(ea.externalId),
      );
    } else {
      filteredExtIds = conrepWorkdayData.elementActions.map((ea: any) =>
        getElementIdOfExternalId(ea.externalId),
      );
    }
  } else if (conrepWorkdayElements) {
    // all elements until that day
    Object.keys(conrepWorkdayElements).forEach((key) => {
      let filtered = conrepWorkdayElements[key];
      if (actionArray) {
        filtered = conrepWorkdayElements[key].filter(
          (x: any) => actionArray.indexOf(x.action) > -1,
        );
      }

      const actionStatus = getElementStatusUntilDate(
        filtered,
        conrepWorkdayData.date,
        conrepWorkdayNumbers,
        actions,
      );

      if (Object.keys(actionStatus).length > 0) {
        filteredExtIds.push(getElementIdOfExternalId(key));
      }
    });
  }

  filteredExtIds = filteredExtIds.filter(
    (value: any, index: any, self: any) => self.indexOf(value) === index,
  );

  if (!inverseFilter) {
    return filteredExtIds;
  }

  function handleCallback() {
    return new Promise<number[]>((resolve) => {
      if (!NOP_VIEWER) {
        resolve([]);
        return;
      }
      getObjectTreeLeafComponentIds(NOP_VIEWER, (dbIds) => {
        resolve(dbIds);
      });
    });
  }

  const allElements: any = await handleCallback();

  const allExtIds = allElements.map((e: any) => {
    return getElementIdOfExternalId(conrepDbIdToExternalIdMapping![e]);
  });

  const allFilteredIds: string[] = filteredExtIds.map((e: any) => e.elementId);

  return allExtIds.filter(
    (element: any) => !allFilteredIds.includes(element.elementId),
  );
}

export function getSelectOptions(
  parameter: string,
  conrepPropertySetsFiltered: ConrepAppPropertySets,
  conrepPropertySetsAll: ConrepAppPropertySets,
) {
  let copy = [];

  if (conrepPropertySetsFiltered) {
    copy = cloneDeep(conrepPropertySetsFiltered[parameter]);
  } else if (conrepPropertySetsAll) {
    // do it with the filtered ones
    copy = cloneDeep(conrepPropertySetsAll[parameter]);
  } else {
    return [];
  }

  if (!copy) {
    return [];
  }

  copy.sort(compareAlphabeticallyAsc);

  const options = copy.map((p) => {
    if (p === '') {
      return { label: EMPTY_STRING_LABEL, value: p };
    }
    return { label: p, value: p };
  });

  return options || [];
}

export const colourStyles = {
  option: (styles1: any, { data, isDisabled, isFocused, isSelected }: any) => {
    const color = chroma(data.color);
    return {
      ...styles1,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles1[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles1: any, { data }: any) => {
    const color = chroma(data.color);
    return {
      ...styles1,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles1: any, { data }: any) => ({
    ...styles1,
    color: data.color,
  }),
  multiValueRemove: (styles1: any, { data }: any) => ({
    ...styles1,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

export const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

export function parseFilterPresetToStoreFormat(
  fp: ConrepAppFilterPreset,
  externalIdMapping: ForgeViewerExternalIdToDbIdMapping,
) {
  let isolatedDbIds: number[] = [];
  if (fp.isolatedIds && fp.isolatedIds.length >= 0) {
    isolatedDbIds = fp.isolatedIds.map(
      (extId) => externalIdMapping[extId] ?? extId,
    );
  }

  const reduxFormat: ConrepAppReduxFilter = {
    actionFilter: {
      mode: fp.dateRestriction,
      actions: [],
      inverted: false,
    },
    idFilter: isolatedDbIds,
    customFilter: {},
  };

  fp.filterValues.forEach((f) => {
    if (f.type === ConrepAppFilterPresetValueTypes.FILTER_VALUE_ACTION) {
      reduxFormat.actionFilter.actions = f.values;
      reduxFormat.actionFilter.inverted = f.inverted || false;
    } else if (f.filterId && isStringArray(f.values)) {
      reduxFormat.customFilter[f.filterId] = f.values;
    }
  });
  return reduxFormat;
}

export const getFilterData = (
  idFilter: number[],
  actionFilter: ActionFilter,
  customFilter: CustomFilter,
  screenshotDescription: string,
  viewReference: FileVersionViewReference,
  conrepDbIdToExternalIdMapping: ForgeViewerDbIdToExternalIdMapping,
): Partial<ConrepAppFilterPreset> | null => {
  // check if filters active
  if (
    idFilter &&
    idFilter.length === 0 &&
    actionFilter.mode === ConrepAppFilterPresetDateRestriction.NONE &&
    actionFilter.actions &&
    actionFilter.actions.length === 0 &&
    // conrepActiveFilters.customFilter &&
    Object.keys(customFilter).length === 0
  ) {
    return null;
  }

  let extIds: string[] = [];
  if (idFilter) {
    extIds = idFilter.map((dbId: any) => conrepDbIdToExternalIdMapping[dbId]);
  }
  const filterData: Partial<ConrepAppFilterPreset> = {
    name: screenshotDescription,
    view: viewReference,
    isolatedIds: extIds,
    dateRestriction: actionFilter.mode,
    dateType: ConrepAppFilterPresetDateType.DYNAMIC,
    hidden: true,
  };
  const customFilters: ConrepAppFilterPresetValue[] = [];
  if (actionFilter.actions && actionFilter.actions.length > 0) {
    customFilters.push({
      type: ConrepAppFilterPresetValueTypes.FILTER_VALUE_ACTION,
      values: actionFilter.actions || [],
      inverted: actionFilter.inverted,
    });
  }

  // gather custom filters
  Object.keys(customFilter).forEach((filterParameter) => {
    if (
      customFilter[filterParameter] &&
      customFilter[filterParameter].length > 0
    ) {
      customFilters.push({
        type: ConrepAppFilterPresetValueTypes.FILTER_VALUE_ELEMENT,
        filterId: filterParameter,
        values: customFilter[filterParameter] || [],
      });
    }
  });

  filterData.filterValues = customFilters;
  return filterData;
};
