import { WeatherEntry } from '../Weather/types';
import { api } from 'src/common/fetch';

export async function getWeatherData(
  id: string,
  dates: Date[],
  forecast = false,
) {
  return api.post<WeatherEntry[] | null>(`/api/projects/${id}/weather`, {
    dates,
    forecast,
  });
}

export async function getFiveDayWeatherForecast(id: string) {
  const dates = [];
  for (let i = 0; i <= 5; i++) {
    const date = new Date(new Date().setDate(new Date().getDate() + i));
    date.setHours(12, 0, 0);
    dates.push(date);
  }

  return api.post<WeatherEntry[] | null>(`/api/projects/${id}/weather`, {
    dates,
    forecast: true,
  });
}
