import React, { InputHTMLAttributes, ReactNode } from 'react';

import { cn } from 'src/common/utils';

export type Props = {
  label?: ReactNode;
  indeterminate?: boolean;
  error?: string;
};

export const Checkbox = React.forwardRef<
  HTMLInputElement,
  Props & InputHTMLAttributes<HTMLInputElement>
>(function CheckBox(
  {
    label,
    indeterminate,
    error,
    id,
    disabled,
    checked,
    required,
    className,
    ...rest
  },
  ref,
) {
  return (
    <div className={cn('inline-flex flex-col gap-2', className)}>
      <label
        htmlFor={id}
        className={cn('inline-flex w-full items-center gap-2', [
          {
            'text-gray-400': disabled,
          },
        ])}
      >
        <input
          id={id}
          className={cn(
            'rounded border-gray-500 focus:border-blue-800 focus:text-blue-800 focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:border-0',
            [
              {
                'bg-blue-100 text-blue-100':
                  disabled && (checked || indeterminate),
                'disabled:bg-gray-100': !checked && !indeterminate,
              },
            ],
          )}
          type="checkbox"
          disabled={disabled}
          required={required}
          checked={checked}
          ref={(inputElement) => {
            if (inputElement) {
              inputElement.indeterminate =
                !checked && indeterminate !== undefined ? indeterminate : false;
            }

            if (ref) {
              if (typeof ref === 'function') {
                ref(inputElement);
              } else {
                ref.current = inputElement;
              }
            }
          }}
          {...rest}
        />
        <div>
          {label}
          {required && (
            <span className={disabled ? 'text-gray-400' : 'text-red-600'}>
              *
            </span>
          )}
        </div>
      </label>
      {error && (
        <div
          className={cn('text-sm', [
            { 'text-red-600': !disabled, 'text-gray-400': disabled },
          ])}
        >
          {error}
        </div>
      )}
    </div>
  );
});
