import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import {
  faArrowLeft,
  faCog,
  faPlus,
  faSignOut,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';

import { Button } from 'src/common/components/buttons/Button';
import { Badge } from 'src/common/components/Badge';
import { SidebarLayout } from 'src/common/components/SidebarLayout';
import toast from 'src/common/toast';
import { SidebarMenu } from 'src/common/components/SidebarMenu';
import { isCompanyOwnerOrAdmin } from 'src/user/utils';
import leaveIllu from 'src/assets/illu_leave-organisation.svg';
import { Modal, useModal } from 'src/common/components/Modal';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { MainAppbarLayout } from 'src/app/components/MainAppbarLayout';
import { Loading } from 'src/common/components/Loading';
import { NotFoundInProjects } from 'src/project/components/NotFoundInProjects';
import { CompanySettingsView } from './CompanySettingsView';
import { CompanyUsersView } from './CompanyUsersView';
import { inviteUserToCompany, leaveCompany } from '../api';
import { Company } from '../types';
import { TransferOwnerModal } from '../components/TransferOwnerModal';
import {
  useCompanyInvitationsQuery,
  useCompanyUsersQuery,
  useCompanyQuery,
} from '../queries';
import i18n from 'src/i18n';
import { EMAIL_REGEX } from 'src/user/constants';
import { useUser } from 'src/auth/user-context';
import { NotFound } from 'src/common/components/NotFound';
import { User } from 'src/user/types';

export const CompanyView = () => {
  const { t } = useTranslation('company');
  const { companyId = '' } = useParams();
  const query = useCompanyQuery(companyId);
  const user = useUser();

  if (query.isError) {
    return (
      <MainAppbarLayout>
        <NotFound />
      </MainAppbarLayout>
    );
  }

  if (query.isPending) {
    return (
      <MainAppbarLayout>
        <Loading />
      </MainAppbarLayout>
    );
  }

  const company = query.data;
  const isPersonalCompany = user.personalCompany === company._id;
  const isAdminOrOwner = company
    ? isCompanyOwnerOrAdmin(user._id, company)
    : false;

  return (
    <MainAppbarLayout>
      <SidebarLayout
        headerLeft={<BackButton />}
        header={
          <Header
            company={company}
            user={user}
            isPersonalCompany={isPersonalCompany}
          />
        }
        sidebar={
          <SidebarMenu>
            {!isPersonalCompany && (
              <SidebarMenu.Item
                to="members"
                icon={<FontAwesomeIcon icon={faUser} fixedWidth />}
              >
                {t('sidebar.members')}
              </SidebarMenu.Item>
            )}
            {isAdminOrOwner && (
              <SidebarMenu.Item
                to="settings"
                icon={<FontAwesomeIcon icon={faCog} fixedWidth />}
              >
                {t('sidebar.settings')}
              </SidebarMenu.Item>
            )}
          </SidebarMenu>
        }
      >
        <Routes>
          {isPersonalCompany ? null : (
            <Route
              path="members"
              element={<CompanyUsersView company={company} user={user} />}
            />
          )}
          {isAdminOrOwner && (
            <Route
              path="settings"
              element={<CompanySettingsView company={company} />}
            />
          )}
          <Route index element={<Navigate to="members" replace />} />
          <Route path="*" element={<NotFoundInProjects />} />
        </Routes>
      </SidebarLayout>
    </MainAppbarLayout>
  );
};

const BackButton = () => {
  const { t } = useTranslation('company');
  return (
    <NavLink to="/">
      <Button variant="tertiary">
        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
        {t('back')}
      </Button>
    </NavLink>
  );
};

const Header = ({
  company,
  user,
  isPersonalCompany,
}: {
  company: Company;
  user: User;
  isPersonalCompany: boolean;
}) => {
  const { t } = useTranslation('company');

  const inviteModal = useModal();
  const leaveModal = useModal();
  const transferModal = useModal();
  const canAdmin = isCompanyOwnerOrAdmin(user._id, company);

  const handleLeave = () => {
    if (company.owner === user._id) {
      transferModal.open();
    } else {
      leaveModal.open();
    }
  };

  return (
    <>
      <div className="flex flex-wrap items-center gap-2">
        <h1 className="text-2xl font-semibold tracking-wide">{company.name}</h1>
        <Badge>
          {isPersonalCompany ? t('myWorkspace') : t('myOrganization')}
        </Badge>
        <div className="ml-auto flex gap-2">
          {!isPersonalCompany && (
            <Button variant="tertiary" onClick={handleLeave}>
              <FontAwesomeIcon icon={faSignOut} />
              {t('leave.title')}
            </Button>
          )}
          {canAdmin && !isPersonalCompany && (
            <Button onClick={inviteModal.open}>
              <FontAwesomeIcon icon={faPlus} />
              {t('inviteUsers.title')}
            </Button>
          )}
        </div>
      </div>
      {canAdmin && inviteModal.isOpen && (
        <InviteMembersModal
          companyId={company._id}
          isOpen={inviteModal.isOpen}
          onClose={inviteModal.close}
        />
      )}
      {leaveModal.isOpen && (
        <LeaveCompanyModal
          isOpen={leaveModal.isOpen}
          companyId={company._id}
          onClose={leaveModal.close}
        />
      )}
      {transferModal.isOpen && (
        <TransferOwnerModal
          isOpen={transferModal.isOpen}
          userId={user._id}
          companyId={company._id}
          onTransferred={leaveModal.open}
          onClose={transferModal.close}
        />
      )}
    </>
  );
};

export const LeaveCompanyModal = ({
  isOpen,
  companyId,
  onClose,
}: {
  isOpen: boolean;
  companyId: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation(['company', 'common']);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleLeave = async () => {
    try {
      await leaveCompany(companyId);
      toast.success(t('company:leave.success'));
      queryClient.invalidateQueries({
        queryKey: ['companies'],
      });
      navigate('/');
    } catch (exception) {
      toast.error(t('company:leave.failure'));
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="w-[680px]">
      <Modal.Header onClose={onClose}>{t('company:leave.title')}?</Modal.Header>
      <Modal.Body>
        <div className="mt-8 flex flex-col items-center gap-12">
          <div className="flex gap-4">
            <img src={leaveIllu} alt="" />
            <div className="flex w-full flex-col gap-4">
              <h6 className="text-shuttleGray-600">
                {t('company:leave.subtitle')}
              </h6>
              <InlineMessage>{t('company:leave.info')}</InlineMessage>
            </div>
          </div>
          <div className="ml-auto flex gap-2">
            <Button variant="tertiary" onClick={onClose}>
              {t('common:button.abort')}
            </Button>
            <Button variant="primary" type="submit" onClick={handleLeave}>
              {t('company:leave.title')}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const InviteMembersModal = ({
  isOpen,
  companyId,
  onClose,
}: {
  isOpen: boolean;
  companyId: string;
  onClose: () => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ email: string }>();
  const { t } = useTranslation(['company', 'common']);
  const queryClient = useQueryClient();
  const { data: companyUsers } = useCompanyUsersQuery(companyId);
  const { data: invitations } = useCompanyInvitationsQuery(companyId);
  const mutation = useMutation({
    mutationFn: (email: string) =>
      inviteUserToCompany(companyId, email, i18n.language),
    onSuccess() {
      toast.success(t('company:inviteUsers.sendInvitationSuccess'));
      queryClient.invalidateQueries({
        queryKey: ['companies', companyId, 'invitations'],
      });
      reset({ email: '' });
    },
    onError() {
      toast.error(t('company:inviteUsers.sendInvitationFail'));
    },
  });

  const existingEmails = [
    ...companyUsers.map((u) => u.email),
    ...invitations.map((i) => i.email),
  ];

  function sendInvite(formData: { email: string }) {
    if (existingEmails.includes(formData.email)) {
      toast.error(t('company:inviteUsers.alreadyInCompany'));
      return;
    }
    mutation.mutate(formData.email);
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="w-[480px]">
      <Modal.Header onClose={onClose}>
        {t('company:inviteUsers.title')}
      </Modal.Header>
      <Modal.Body>
        {' '}
        <form
          onSubmit={handleSubmit(sendInvite)}
          className="-mt-2 flex flex-col gap-6"
        >
          <p className="text-shuttleGray-600">
            {t('company:inviteUsers.subtitle')}
          </p>
          <TextInput
            {...register('email', {
              required: true,
              pattern: EMAIL_REGEX,
            })}
            label={t('company:inviteUsers.email')}
            type="email"
            className="w-full"
            autoFocus
            error={errors.email && t('company:inviteUsers.invalidEmail')}
          />
          <div className="ml-auto flex gap-2">
            <Button variant="tertiary" onClick={onClose}>
              {t('common:button.close')}
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={mutation.isPending || !!errors.email}
            >
              {t('company:inviteUsers.sendInvitation')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
