import { FileVersionViewReference } from 'src/file/types';

type ServiceSpecificationPath = {
  serviceSpecification: string;
  paths: string[];
};

export enum TechCrewPermission {
  // DOCUMENTATION
  VIEW_DOCUMENTATION = 'VIEW_DOCUMENTATION',
  EDIT_DOCUMENTATION = 'EDIT_DOCUMENTATION',
  CONTROL_DOCUMENTATION = 'CONTROL_DOCUMENTATION',
  EXPORT_DATA_DOCUMENTATION = 'EXPORT_DATA_DOCUMENTATION',
  // ANALYSIS
  VIEW_ANALYSIS = 'VIEW_ANALYSIS',
  EDIT_ANALYSIS = 'EDIT_ANALYSIS',
}

type SnakeCaseToCamelCase<S extends string> =
  S extends `${infer A}_${infer B}${infer Rest}`
    ? `${Lowercase<A>}${Capitalize<SnakeCaseToCamelCase<`${B}${Rest}`>>}`
    : Lowercase<S>;

type NormalizedTechCrewPermission = {
  [K in TechCrewPermission]: `project:techCrew.permission.${SnakeCaseToCamelCase<K>}`;
};

export const MappedTechCrewPermission: NormalizedTechCrewPermission = {
  VIEW_DOCUMENTATION: 'project:techCrew.permission.viewDocumentation',
  EDIT_DOCUMENTATION: 'project:techCrew.permission.editDocumentation',
  CONTROL_DOCUMENTATION: 'project:techCrew.permission.controlDocumentation',
  EXPORT_DATA_DOCUMENTATION:
    'project:techCrew.permission.exportDataDocumentation',
  VIEW_ANALYSIS: 'project:techCrew.permission.viewAnalysis',
  EDIT_ANALYSIS: 'project:techCrew.permission.editAnalysis',
} as const;

export type TechCrewMember = {
  userId: string;
  permissions: TechCrewPermission[];
};

export type TechCrew = {
  _id: string;
  name: string;
  description?: string;
  controlled: boolean;
  admins: string[];
  members: TechCrewMember[];
  defaultView?: FileVersionViewReference;
  sheetSets: string[];
  nameTemplate?: string;
  categoryParameter?: string;
  project: string;
  liableUser?: string;
  liableCompany?: string;
  serviceSpecificationPathsRegie?: ServiceSpecificationPath;
  serviceSpecificationPathsWorkday?: ServiceSpecificationPath;
  plannedHours?: number;
  tagBasedHours?: TagBasedHours[];
  lvBasedHours?: LvBasedHours[];
  customProgressValues?: number[];
  startDate?: string;
  endDate?: string;
  createdBy: string;
  createdAt: string;
  updatedBy?: string;
  updatedAt?: string;
  color: string;
};

export type TechCrewUsage = TechCrew & { isUsed: boolean };

export type LvBasedHours = {
  lvPath: string;
  plannedHours: number;
};

export type TagBasedHours = {
  tag: string;
  plannedHours: number;
};

export type CreateTechCrew = {
  name: string;
  description?: string;
  members?: TechCrewMember[];
  color: string;
  sheetSets: string[];
  defaultView?: FileVersionViewReference;
  nameTemplate?: string;
  plannedHours?: number;
  startDate?: string;
  endDate?: string;
  categoryParameter?: string;
};

export type UpdateTechCrew = {
  name?: string;
  color?: string;
  description?: string;
  admins?: string[];
  members?: TechCrewMember[];
  sheetSets?: string[];
  defaultView?: FileVersionViewReference;
  categoryParameter?: string;
  nameTemplate?: string;
  startDate?: string;
  endDate?: string;
  plannedHours?: number;
  tagBasedHours?: TagBasedHours[];
  lvBasedHours?: LvBasedHours[];
};
