import { CustomParameterProperty } from 'shared';

import { CustomParameter } from 'src/project/types/projects';
import {
  ConrepAppWorkday,
  ConrepAppWorkdayNumbers,
  ConrepAppWorkdayElements,
  ConrepAppWorkdayData,
  ReviewStatus,
  ConrepAppWorkdayReview,
  ConrepAppWorkdayMessage,
} from './types';
import { api } from 'src/common/fetch';

export async function getWorkdayNumbers(
  projectId: string,
  techCrewId: string,
  short?: boolean,
) {
  let queryParams = '';
  if (short === true) {
    queryParams = '?numbers=true&info=true';
  }
  return api.get<ConrepAppWorkdayNumbers>(
    `/api-conrep/projects/${projectId}/techcrews/${techCrewId}/workdays${queryParams}`,
  );
}

export async function getWorkday(
  projectId: string,
  techCrewId: string,
  wdId: string,
  onlyData?: boolean,
) {
  let queryString =
    '?elementActions=true&screenshots=true&nonModeledElements=true&regies=true';
  if (onlyData) {
    queryString = '';
  }
  return api.get<ConrepAppWorkday>(
    `/api-conrep/projects/${projectId}/techcrews/${techCrewId}/workdays/${wdId}${queryString}`,
  );
}

export async function getElementsByTechcrew(
  projectId: string,
  techCrewId: string,
) {
  return api.get<ConrepAppWorkdayElements>(
    `/api-conrep/projects/${projectId}/techcrews/${techCrewId}/workdays/null/actions`,
  );
}

export async function updateWorkdayData(
  projectId: string,
  tcId: string,
  wdId: string,
  workdayData: { data: Partial<ConrepAppWorkdayData> },
) {
  return api.post(
    `/api-conrep/projects/${projectId}/techcrews/${tcId}/workdays/${wdId}`,
    workdayData,
  );
}

export async function deleteWorkdays(
  projectId: string,
  techCrewId: string,
  wdIds: string[],
) {
  return api.delete(
    `/api-conrep/projects/${projectId}/techcrews/${techCrewId}/workdays`,
    wdIds,
  );
}

export async function lockWorkdays(
  projectId: string,
  techCrewId: string,
  wdIds: string[],
) {
  return api.post<{ success: string[]; failure: string[] }>(
    `/api-conrep/projects/${projectId}/techcrews/${techCrewId}/workdays/lock`,
    wdIds,
  );
}

export async function createWorkday(
  projectId: string,
  techCrewId: string,
  initialData: Partial<ConrepAppWorkday>,
) {
  return api.post<ConrepAppWorkday>(
    `/api-conrep/projects/${projectId}/techcrews/${techCrewId}/workdays`,
    initialData,
  );
}

export async function addWorkdayReview(
  projectId: string,
  techCrewId: string,
  workdayId: string,
  status: ReviewStatus,
) {
  return api.post<ConrepAppWorkdayReview>(
    `/api-conrep/projects/${projectId}/techcrews/${techCrewId}/workdays/${workdayId}/reviews`,
    { status },
  );
}

export async function addWorkdayMessage(
  projectId: string,
  techCrewId: string,
  workdayId: string,
  text: string,
) {
  return api.post<ConrepAppWorkdayMessage>(
    `/api-conrep/projects/${projectId}/techcrews/${techCrewId}/workdays/${workdayId}/messages`,
    { text },
  );
}

export async function getCustomParameters(projectId: string) {
  return api.get<CustomParameter[]>(
    `/api-conrep/projects/${projectId}/customParameters`,
  );
}

export async function getCustomParameterPropertiesByExternalId(
  projectId: string,
  externalId: string,
) {
  return api.get<CustomParameterProperty[]>(
    `/api-conrep/projects/${projectId}/customParameters/${externalId}/properties`,
  );
}

export async function getCustomParameterProperties(projectId: string) {
  return api.get<CustomParameterProperty[]>(
    `/api-conrep/projects/${projectId}/customParameters/properties`,
  );
}

export async function setCustomParameterProperty(
  projectId: string,
  customParameter: {
    id: string;
    property: { value: string | number | boolean };
    externalId: string;
  },
) {
  return api.post<{
    id: string;
    property: { value: string | number | boolean };
    externalId: string;
  }>(
    `/api-conrep/projects/${projectId}/customParameters/properties`,
    customParameter,
  );
}
export async function removeCustomParameterProperty(
  projectId: string,
  customParameterId: string,
  value: string | number | boolean,
) {
  let encodedValue;
  // Handle URL values
  if (typeof value === 'string') {
    try {
      // Check if value is a valid URL
      new URL(value);
      encodedValue = encodeURIComponent(value);
    } catch {
      encodedValue = value;
    }
  } else {
    encodedValue = value.toString();
  }
  return api.delete(
    `/api-conrep/projects/${projectId}/customParameters/${customParameterId}/properties/${encodedValue}`,
  );
}
