import { ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextAreaInput } from 'src/common/components/inputs/TextAreaInput/TextAreaInput';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';

export type CompanyFormValues = {
  name: string;
  description: string;
  zipCode: string;
  cityName: string;
  websiteLink: string;
};

export const CompanyForm = ({
  form,
  onSubmit,
  children,
}: {
  form: UseFormReturn<CompanyFormValues>;
  onSubmit: (state: CompanyFormValues) => void;
  children: ReactNode;
}) => {
  const { t } = useTranslation(['company', 'common']);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        label={t('company:form.name.label')}
        required
        error={errors.name?.message}
        {...register('name', {
          required: t('company:form.name.error'),
        })}
      />
      <TextAreaInput
        label={t('company:form.description')}
        rows={3}
        {...register('description')}
      />
      <div className="flex gap-2">
        <TextInput
          label={t('company:form.zipCode')}
          error={errors.zipCode?.message}
          {...register('zipCode')}
        />
        <TextInput
          label={t('company:form.cityName')}
          className="w-full"
          error={errors.cityName?.message}
          {...register('cityName')}
        />
      </div>
      <TextInput
        label={t('company:form.websiteLink')}
        {...register('websiteLink')}
      />
      <div className="mt-2 flex gap-2">{children}</div>
    </form>
  );
};
