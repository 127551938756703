import { useCallback } from 'react';
import { batch } from 'react-redux';

import { ExtendedGuiViewer3D } from 'src/Forge/Viewer/Extensions/ExtendedGuiViewer3D';
import {
  elementPropertiesToSet,
  getAllLeafComponents,
  getElementIdOfExternalId,
  getElementName,
  getElementNameProperties,
  getProperties,
} from 'src/Forge/utils';
import { useForgeRestoreViewAndMarkup } from 'src/Forge/hooks';
import { FileVersionView } from 'src/file/types';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { ForgeViewerCore } from 'src/Forge/Viewer/Core';
import {
  ForgeElementProperties,
  ForgeElementPropertiesSet,
  ForgeView,
  ForgeViewerDbIdToExternalIdMapping,
  ForgeViewerExternalIdToDbIdMapping,
  ForgeViewerExternalIdToFileIdMapping,
  ForgeViewerNaming,
} from '../types';
import {
  selectForgeViewerIsolatedUnsync,
  setForgeViewerDbIdToExternalIdMapping,
  setForgeViewerExternalIdToDbIdMapping,
  setForgeViewerExternalIdToFileIdMapping,
  setForgeViewerIsolated,
  setForgeViewerIsolatedUnsync,
  setForgeViewerLeafNodes,
  setForgeViewerNaming,
  setForgeViewerReady,
  setForgeViewerViewReference,
} from '../slice';
import {
  selectConrepAppTechCrew,
  setBlockFiltering,
  setAllElementProperties,
  setPropertySetsAll,
  setFilteredElementProperties,
  setPropertySetsFiltered,
  resetFilters,
  setViewerModelOptions,
} from 'src/Conrep/App/slice';
import { useProject } from 'src/project/project-context';
import { useFilesQuery } from 'src/file/queries';
import { getViewDict } from 'src/project/utils';

export const ForgeViewerBase = ({ view }: { view: FileVersionView }) => {
  const appDispatch = useAppDispatch();
  const currentProject = useProject();
  const { data: files } = useFilesQuery(currentProject._id);
  const viewDict = getViewDict(files);

  const conrepTechCrew = useAppSelector(selectConrepAppTechCrew);
  const unsyncIsolatedDbIds = useAppSelector(selectForgeViewerIsolatedUnsync);

  useForgeRestoreViewAndMarkup();

  const onLoadFinished = useCallback(
    (viewer: ExtendedGuiViewer3D) => {
      const { model } = viewer;

      if (!model) {
        return;
      }

      getAllLeafComponents(viewer, async (dbIds: number[]) => {
        // filter values as list

        const filterParameters = currentProject.filters?.map(
          (f) => f.parameter,
        );

        const externalToId: ForgeViewerExternalIdToDbIdMapping = {};
        const externalToFileId: ForgeViewerExternalIdToFileIdMapping = {};
        const idToExternal: ForgeViewerDbIdToExternalIdMapping = {};

        dbIds.forEach((dbId) => {
          viewer.getProperties(dbId, (data) => {
            if (data.externalId) {
              const { elementId, linkedFileId } = getElementIdOfExternalId(
                data.externalId,
              );
              externalToId[elementId] = dbId;
              idToExternal[dbId] = elementId;
              if (linkedFileId) {
                externalToFileId[elementId] = linkedFileId;
              }
            }
          });
        });

        let properties = getElementNameProperties(
          conrepTechCrew && conrepTechCrew.nameTemplate
            ? conrepTechCrew.nameTemplate
            : '',
        );

        if (properties && properties.length > 0) {
          properties = ['name', ...properties];
        } else {
          properties = ['name'];
        }

        const namingMapping: ForgeViewerNaming = {};

        let res: ForgeElementProperties;
        let sets: ForgeElementPropertiesSet;
        if (dbIds && dbIds.length > 0 && viewer) {
          res = await getProperties(dbIds, filterParameters, true, viewer);
          sets = filterParameters
            ? elementPropertiesToSet(res, filterParameters)
            : {};

          model?.getBulkProperties2(
            dbIds,
            { propFilter: properties },
            (elements) => {
              elements.forEach((e) => {
                const name = getElementName(
                  e,
                  conrepTechCrew && conrepTechCrew.nameTemplate
                    ? conrepTechCrew.nameTemplate
                    : '',
                );
                namingMapping[e.dbId] = name || 'ERROR';
              });

              batch(() => {
                view &&
                  viewDict &&
                  appDispatch(
                    setForgeViewerViewReference(
                      viewDict[`${view.file}_${view.viewableId}`],
                    ),
                  );
                appDispatch(setForgeViewerLeafNodes(dbIds));
                appDispatch(setForgeViewerNaming(namingMapping));
                appDispatch(
                  setForgeViewerDbIdToExternalIdMapping(idToExternal),
                );
                appDispatch(
                  setForgeViewerExternalIdToDbIdMapping(externalToId),
                );
                appDispatch(
                  setForgeViewerExternalIdToFileIdMapping(externalToFileId),
                );
                appDispatch(setBlockFiltering(false));

                // others
                appDispatch(setAllElementProperties(res));
                appDispatch(setPropertySetsAll(sets));

                appDispatch(setFilteredElementProperties({}));
                appDispatch(setPropertySetsFiltered({}));
                appDispatch(resetFilters());
              });
            },
          );
        }
      });
    },
    [currentProject.filters, conrepTechCrew, view, appDispatch, viewDict],
  );

  const handleViewerLoading = useCallback(() => {
    appDispatch(setForgeViewerReady(false));
  }, [appDispatch]);

  const handleViewerReady = useCallback(() => {
    appDispatch(setForgeViewerReady(true));
  }, [appDispatch]);

  const handleUpdateIsolationUnsync = useCallback(
    (ids) => {
      appDispatch(setForgeViewerIsolatedUnsync(ids));
    },
    [appDispatch],
  );

  const handleUpdateIsolation = useCallback(
    (ids) => {
      appDispatch(setForgeViewerIsolated([...ids]));
      appDispatch(setForgeViewerIsolatedUnsync(null));
    },
    [appDispatch],
  );

  const handleUpdateModel = useCallback(
    (viewer) => {
      onLoadFinished(viewer);
    },
    [onLoadFinished],
  );

  const handleUpdateViewOptions = useCallback(
    (viewOptions: ForgeView[]) => {
      appDispatch(setViewerModelOptions(viewOptions));
    },
    [appDispatch],
  );

  if (!view) {
    return null;
  }

  return (
    <div className="h-full w-full">
      <ForgeViewerCore
        view={view}
        isolatedDbIds={unsyncIsolatedDbIds}
        extensions={{
          'Autodesk.Viewing.MarkupsCore': {},
          SelectionExtension: {},
          ToggleSuperfluousOptionsExtension: {},
        }}
        onViewerReady={handleViewerReady}
        onViewerLoading={handleViewerLoading}
        onUpdateIsolation={handleUpdateIsolation}
        onUpdateIsolationUnsync={handleUpdateIsolationUnsync}
        onUpdateModel={handleUpdateModel}
        onUpdateViewOptions={handleUpdateViewOptions}
        className="relative h-full w-full"
      />
    </div>
  );
};
