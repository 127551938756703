import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import toast from 'src/common/toast';
import { requestPasswordReset } from 'src/auth/api';
import { AuthLayout } from 'src/auth/components/AuthLayout';
import { ForgotPasswordForm } from '../../components/ForgotPasswordForm';
import { ForgotPasswordSuccessMessage } from '../../components/ForgotPasswordSuccessMessage';
import i18n from 'src/i18n';

export const ForgotPasswordView = () => {
  const { t } = useTranslation('common');

  const [targetEmailAddress, setEmailAddress] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const mail = String(formData.get('email'));
    try {
      await requestPasswordReset(mail, i18n.language);
      setEmailAddress(mail);
    } catch (err) {
      toast.error(t('errors.generic'));
    }
    setIsLoading(false);
  };

  return (
    <AuthLayout>
      <div className="px-8">
        {!targetEmailAddress ? (
          <ForgotPasswordForm onSubmit={handleSubmit} isLoading={isLoading} />
        ) : (
          <ForgotPasswordSuccessMessage emailAddress={targetEmailAddress} />
        )}
      </div>
    </AuthLayout>
  );
};
