import { FileVersionViewReference, ImageFile } from 'src/file/types';
import { Action } from 'src/project/types/actions';
import { TechCrew } from 'src/project/types/techCrews';
import { ExtendedAttendance } from './Report/DayInfo/Attendance/types';
import { SimpleEquipment } from './Report/DayInfo/Equipment/types';
import { SimpleMaterial } from './Report/DayInfo/Material/types';
import { FreeFieldEntry } from './Report/DayInfo/FreeFields/types';
import { SimpleWeather } from './Report/DayInfo/Weather/types';
import { NonModeledElement } from './Report/NonModeledElements/types';

// TODO: 'ARCHIVED' is a userStatus? is 'LOCKED' then as well? -> reasons view only
export enum ConrepAppUserStatus {
  'ARCHIVED' = 'ARCHIVED',
  'VIEWER' = 'VIEWER',
  'LOCKED' = 'LOCKED',
}

/** Tabs */
export enum ConrepAppWorkdayTab {
  DAYINFO = 'dayinfo',
  REGIE = 'regie',
  ELEMENTS = 'elements',
  IMAGES = 'images',
  COMMENT = 'comment',
  NMBS = 'nonModeledElements',
}

/** FilterPreset */

export enum ConrepAppFilterPresetDateRestriction {
  'NONE' = 'NONE',
  'UNTIL' = 'UNTIL',
  'ONLY' = 'ONLY',
}

export enum ConrepAppFilterPresetDateType {
  'DYNAMIC' = 'DYNAMIC',
  'FIXED' = 'FIXED',
}

export enum ConrepAppFilterPresetValueTypes {
  'FILTER_VALUE_ACTION' = 'FILTER_VALUE_ACTION',
  'FILTER_VALUE_ELEMENT' = 'FILTER_VALUE_ELEMENT',
}

export type ConrepAppFilterPresetValue = {
  type: ConrepAppFilterPresetValueTypes;
  values: Action[] | string[];
  filterId?: string;
  inverted?: boolean;
};

export type ConrepAppReduxFilter = {
  actionFilter: ActionFilter;
  customFilter: CustomFilter;
  idFilter: number[];
};

export type ConrepAppFilterPreset = {
  _id?: string;
  name: string;
  project: string;
  view?: FileVersionViewReference;
  isolatedIds: string[];
  dateRestriction: ConrepAppFilterPresetDateRestriction;
  dateType: ConrepAppFilterPresetDateType;
  date?: string;
  filterValues: ConrepAppFilterPresetValue[];
  hidden?: boolean;
  createdBy: string;
  createdAt: string;
  updatedBy?: string;
  updatedAt?: Date;
};

export type ConrepAppFilterPresets = ConrepAppFilterPreset[];

/** WorkdayNumbers */

export enum NotLockableCause {
  UNACCEPTED_APPROVALS = 'UNACCEPTED_APPROVALS',
  UNLOCKED_EARLIER_WORKDAY = 'UNLOCKED_EARLIER_WORKDAY',
  ATTENDANCE_LV_HOURS_DIFF = 'ATTENDANCE_LV_HOURS_DIFF',
}

export type ConrepAppWorkdayNumbersEntry = {
  _id: string;
  approved: boolean;
  createdAt: string;
  createdBy: string;
  date: string;
  elementCount: number;
  infoCount: number;
  isLocked: boolean;
  lockableStatus: {
    workdayId: string;
    isLockable: boolean;
    notLockableCauses: NotLockableCause;
  };
  nonModeledElementCount: number;
  number: number;
  regieCount: number;
  screenshotCount: number;
  messages: number;
  updatedAt?: string;
  updatedBy?: string;
};

export type ConrepAppWorkdayNumbers = ConrepAppWorkdayNumbersEntry[];

export type WorkdayNumbersWithData = Array<
  ConrepAppWorkdayNumbersEntry & {
    position: number;
    isLastLockedWorkday?: boolean;
  }
>;

/** Workday */

export type ConrepAppWorkdayData = {
  _id?: string;
  attendance: ExtendedAttendance;
  weather: SimpleWeather;
  equipment: SimpleEquipment;
  material: SimpleMaterial;
  freeFields: FreeFieldEntry[];
  comment?: string;
};

export type ConrepAppRegieEffort = {
  _id?: string;
  count: number;
  position: string;
  positionShortText: string;
  description?: string;
  unit: string;
  value: number;
  isLV: boolean;
};

export type ConrepAppRegie = {
  _id: string;
  title: string;
  description?: string;
  workday: string;
  project: string;
  techCrew: string;
  efforts: ConrepAppRegieEffort[];
  createdBy: string;
  createdAt: string;
  updatedBy?: string;
  updatedAt?: string;
};

export type Vector3 = {
  x: number;
  y: number;
  z: number;
};

export type CameraDefinition = {
  position: Vector3;
  target: Vector3;
  up: Vector3;
};

export type Screenshot = {
  _id: string;
  file: ImageFile;
  markupKey?: string;
  description?: string;
  extension?: string;
  markupActive?: boolean;
  fileVersionView?: string;
  techCrew?: string;
  workday?: string;
  filterPreset: ConrepAppFilterPreset | string | null;
  project: string;
  createdBy: string;
  updatedBy?: string;
  cameraDefinition?: CameraDefinition;
  markupUrl?: string;
  downloadUrl?: string;
  createdAt: string;
  updatedAt?: string;
  view?: FileVersionViewReference;
};

export type ElementAction = {
  _id: string;
  action: string;
  actionGroup: string;
  externalId: string;
  linkedFileId?: string;
  value: number;
  project: string;
  view?: FileVersionViewReference;
  workday: Pick<ConrepAppWorkday, '_id' | 'date'> | string;
  techCrew: string;
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string;
};

export enum CreateOrUpdateElementActionStatus {
  FULFILLED = 'fulfilled',
}

export type CreateOrUpdateElementActionMapping = {
  status: CreateOrUpdateElementActionStatus;
  value: ElementAction;
}[];

export type DeleteElementActionsMapping = {
  [id: string]: { deleted: boolean; reason?: string };
};

export enum ReviewStatus {
  OPEN = 'OPEN',
  APPROVED = 'APPROVED',
}

export type ConrepAppWorkdayReview = {
  status: ReviewStatus;
  reviewerId?: string;
  workdayId?: string;
  createdAt: string;
};

export type ConrepAppWorkdayMessage = {
  text: string;
  authorId: string;
  workdayId?: string;
  createdAt: string;
};

export type ConrepAppWorkday = {
  data: ConrepAppWorkdayData;
  screenshots: Screenshot[];
  elementActions: ElementAction[] | string[];
  nonModeledElements: NonModeledElement[];
  regies: ConrepAppRegie[];
  isLocked: boolean;
  controlled: boolean;
  _id: string;
  project: string;
  techCrew: string | TechCrew;
  date: string;
  reviews: ConrepAppWorkdayReview[];
  messages: ConrepAppWorkdayMessage[];
  createdBy: string;
  createdAt: string;
  updatedAt?: string;
  updatedBy?: string;
};

/** Workday Elements */

export type ConrepAppWorkdayElements = {
  [actionId: string]: ElementAction[];
};

/** Action Dic */

export type ConrepAppActionDic = {
  [key: string]: Action;
};

export type ConrepAppElementProperties = {
  [id: number]: {
    [propertyName: string]: string;
  };
};

export type ConrepAppPropertySets = {
  [propertyName: string]: string[];
};

export type ActionFilter = {
  mode: ConrepAppFilterPresetDateRestriction;
  actions: Action[] | string[];
  inverted: boolean;
};

export type CustomFilter = Record<string, string[]>;

export type ConrepFilterData = {
  workday?: string;
  techCrew?: string;
  tab?: string;
};

export type LoadInitialDataType = (
  data: ConrepFilterData,
  onFinish?: () => void,
) => void;

export enum ViewToggle {
  SPLIT_VIEW = 0,
  VIEWER_EXPANDED = 1,

  REPORT_EXPANDED = 2,
}
