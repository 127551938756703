import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: () => void;
};
export const ProjectListItemCreate = ({ onClick }: Props) => {
  const { t } = useTranslation('project');
  return (
    <button
      className="flex min-h-[200px] items-center justify-center border border-dashed border-blue-600 text-blue-600 transition-colors hover:border-blue-700 hover:bg-blue-50 focus:bg-blue-100 focus:outline-none"
      onClick={onClick}
    >
      <div className="flex items-center gap-2 font-medium">
        <FontAwesomeIcon icon={faPlus} /> {t('listView.createProject')}
      </div>
    </button>
  );
};
