import * as TabsPrimitive from '@radix-ui/react-tabs';
import React from 'react';

import { cn } from 'src/common/utils';

const Root = TabsPrimitive.Root;

const List = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => {
  return (
    <TabsPrimitive.List
      ref={ref}
      className={cn('flex gap-6', className)}
      {...props}
    />
  );
});
List.displayName = TabsPrimitive.List.displayName;

const Tab = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(
        'border-b-2 border-transparent font-medium leading-9 tracking-wider text-gray-800 transition-colors hover:border-blue-800 focus:text-blue-600 disabled:cursor-default disabled:text-gray-500 disabled:hover:border-transparent data-[state=active]:border-blue-600 data-[state=active]:text-blue-800',
        className,
      )}
      {...props}
    />
  );
});
Tab.displayName = TabsPrimitive.Trigger.displayName;

const Content = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => {
  return <TabsPrimitive.Content ref={ref} className={className} {...props} />;
});
Content.displayName = TabsPrimitive.Content.displayName;

export const Tabs = Object.assign(Root, {
  List,
  Tab,
  Content,
});
