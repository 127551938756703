import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/common/components/buttons/Button';
import { TextAreaInput } from 'src/common/components/inputs/TextAreaInput/TextAreaInput';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { websiteRegex, zipCodeRegex } from 'src/company/constants';

import type { CompanyPopulatedUser, OwnerPopulatedCompany } from 'shared';

export type CompanyInfoFormValues = {
  name: string;
  description: string;
  zipCode: string;
  cityName: string;
  websiteLink: string;
};

type Props = {
  company?: OwnerPopulatedCompany;
  owner?: CompanyPopulatedUser;
  onClose: () => void;
  onSave: (c: CompanyInfoFormValues) => void;
  isLoading: boolean;
};

export const CompanyInfoPage = ({
  company,
  owner,
  onClose,
  onSave,
  isLoading,
}: Props) => {
  const { t } = useTranslation(['admin', 'common']);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<CompanyInfoFormValues>({ mode: 'onChange' });

  useEffect(() => {
    if (company) {
      reset({
        name: company.name,
        description: company.description,
        zipCode: company.zipCode,
        cityName: company.cityName,
        websiteLink: company.websiteLink,
      });
    } else {
      reset({});
    }
  }, [reset, company]);

  return (
    <div className="flex h-full flex-col justify-end px-6 pb-6">
      {owner && (
        <div className="absolute left-6 top-14 text-sm text-shuttleGray-600">
          {`${t('common:owner')}: ${owner.name} ${owner.surname}`}
        </div>
      )}
      <form
        className="mt-6 flex flex-col gap-4"
        onSubmit={handleSubmit(onSave)}
      >
        <TextInput
          {...register('name', { required: true })}
          label={t('common:name')}
          placeholder={t('admin:placeholder', {
            title: t('common:name'),
          })}
          autoFocus
          required
        />
        <TextAreaInput
          {...register('description')}
          label={t('common:description')}
          rows={7}
          placeholder={t('admin:placeholder', {
            title: t('common:description'),
          })}
          resize={false}
        />
        {/* TODO: COMPANY IMAGE INPUT GOES HERE */}
        <div className="w-full text-lg text-red-600">
          NEW IMAGE COMPONENT COMING SOON
        </div>
        <div className="mt-4 flex w-full gap-2">
          <TextInput
            {...register('zipCode', {
              pattern: zipCodeRegex,
            })}
            label={t('common:zipCode')}
            placeholder={t('admin:placeholder', {
              title: t('common:zipCode'),
            })}
            error={
              errors.zipCode &&
              t('admin:error', {
                title: t('common:zipCode'),
              })
            }
          />
          <TextInput
            {...register('cityName')}
            label={t('common:city')}
            placeholder={t('admin:placeholder', {
              title: t('common:city'),
            })}
          />
        </div>
        <TextInput
          {...register('websiteLink', {
            pattern: websiteRegex,
          })}
          label={t('common:website')}
          placeholder={t('admin:placeholder', {
            title: t('common:website'),
          })}
          error={
            errors.websiteLink &&
            t('admin:error', {
              title: t('common:website'),
            })
          }
        />
        <div className="flex items-center justify-between">
          <div className="flex-1" />
          <div className="flex-1 text-right">
            <Button variant="tertiary" onClick={onClose} disabled={isLoading}>
              {t('common:button.abort')}
            </Button>
            <Button
              type="submit"
              className="ml-2"
              loading={isLoading}
              disabled={!isValid || !isDirty}
            >
              {company ? t('common:button.save') : t('common:button.create')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
