import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'src/common/components/Badge';
import { FileVersionView } from 'src/file/types';
import { ForgeViewerHeaderType } from '../../types';
import { ForgeViewerViewSelector } from './ViewSelector';

type Props = {
  title?: string;
  type?: ForgeViewerHeaderType;
  views: FileVersionView[] | undefined;
  selectedViewId?: string;
  onViewChange: (v: FileVersionView) => void;
  children?: ReactNode;
};

export const ForgeViewerHeader = ({
  title,
  type,
  views,
  selectedViewId,
  onViewChange,
  children,
}: Props) => {
  const { t } = useTranslation('forge');

  const getBadgeText = () => {
    if (type === ForgeViewerHeaderType.SHEETSET) {
      return t('header.sheetSet');
    } else if (type === ForgeViewerHeaderType.FILE) {
      return t('header.file');
    }
    return t('header.view');
  };

  return (
    <div className="flex h-12 min-w-[256px] shrink-0 items-center justify-between">
      <div className="flex flex-row items-center space-x-4">
        {title && (
          <div className="flex items-center space-x-2">
            <span className="font-semibold">{title}</span>
            <Badge>{getBadgeText()}</Badge>
          </div>
        )}
        {views && type !== ForgeViewerHeaderType.VIEW && (
          <ForgeViewerViewSelector
            views={views}
            selectedViewId={selectedViewId}
            onViewChange={onViewChange}
          />
        )}
      </div>
      {children}
    </div>
  );
};
