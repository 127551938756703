import { ReactNode } from 'react';

import { cn } from 'src/common/utils';
import { Avatar } from 'src/common/components/Avatar';

import type { CompanyPopulatedUser } from 'shared';

type Props = {
  user: CompanyPopulatedUser;
  middleContent: ReactNode;
  isSelected?: boolean;
  children: ReactNode;
};

export const SimpleUserRow = ({
  user,
  middleContent,
  isSelected,
  children,
}: Props) => {
  return (
    <tr className={cn({ 'bg-blue-50': isSelected })}>
      <td className="table-td table-td-truncate min-w-[200px]">
        <div className="flex items-center">
          <Avatar size="sm" alt={`${user.name} ${user.surname}`} />
          <div className="ml-4 overflow-hidden py-1">
            <div className="truncate text-base font-semibold">
              {[user.name, user.surname].join(' ')}
            </div>
            <div className="truncate text-base text-shuttleGray-600">
              {user.email}
            </div>
          </div>
        </div>
      </td>
      <td className="table-td table-td-truncate lg:table-cell">
        {middleContent}
      </td>
      <td className="table-td w-[140px] text-right">{children}</td>
    </tr>
  );
};
