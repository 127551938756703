import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TabNavBar } from 'src/common/components/TabNavBar';
import { NotFound } from 'src/common/components/NotFound';
import { Button } from 'src/common/components/buttons/Button';
import { Container } from 'src/common/components/Container';
import { CompaniesView } from './CompaniesView';
import { UsersView } from './UsersView';
import { ProjectsView } from './ProjectsView';
import { AdminAppbar } from '../components/AdminAppbar';
import { SignupsView } from './SignupsView';
import { AnalysisView } from './AnalysisView';

export const AdminView = () => {
  const { t } = useTranslation(['admin', 'common']);

  return (
    <>
      <Helmet>
        <title>Sitelife - Admin</title>
      </Helmet>
      <div className="flex h-full w-full flex-col">
        <AdminAppbar />
        <TabNavBar.Container>
          <TabNavBar>
            <TabNavBar.Link to="users" label={t('common:users')} />
            <TabNavBar.Link
              to="organizations"
              label={t('common:organizations')}
            />
            <TabNavBar.Link to="projects" label={t('projects.title')} />
            <TabNavBar.Link to="signups" label={t('signups.title')} />
            <TabNavBar.Link to="analysis" label={t('analysis.title')} />
          </TabNavBar>
        </TabNavBar.Container>
        <main className="h-full overflow-auto">
          <Container variant="box" className="py-8">
            <Routes>
              <Route path="users" element={<UsersView />} />
              <Route path="organizations" element={<CompaniesView />} />
              <Route path="projects" element={<ProjectsView />} />
              <Route index element={<Navigate to="users" replace />} />
              <Route path="signups" element={<SignupsView />} />
              <Route path="analysis" element={<AnalysisView />} />
              <Route
                path="*"
                element={
                  <NotFound
                    action={
                      <Link to="/admin">
                        <Button>
                          <FontAwesomeIcon icon={faArrowLeft} />
                          {t('backToUsers')}
                        </Button>
                      </Link>
                    }
                  />
                }
              />
            </Routes>
          </Container>
        </main>
      </div>
    </>
  );
};
