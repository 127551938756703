import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'src/app/store';
import type { ForgeView } from 'src/Forge/types';

import { compareDateAsc, isArrayOfType, isString } from 'src/common/utils';
import { Action } from 'src/project/types/actions';
import { TechCrew } from 'src/project/types/techCrews';
import {
  ActionFilter,
  ConrepAppActionDic,
  ConrepAppElementProperties,
  ConrepAppFilterPreset,
  ConrepAppFilterPresetDateRestriction,
  ConrepAppFilterPresets,
  ConrepAppPropertySets,
  ConrepAppReduxFilter,
  ConrepAppRegie,
  ConrepAppUserStatus,
  ConrepAppWorkday,
  ConrepAppWorkdayElements,
  ConrepAppWorkdayMessage,
  ConrepAppWorkdayNumbers,
  ConrepAppWorkdayNumbersEntry,
  ConrepAppWorkdayReview,
  CustomFilter,
  ElementAction,
  ReviewStatus,
} from './types';

// TODO: place this in other file and export it for other reducers as well
enum AppLoadingStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  FAILED = 'failed',
}

export interface ConrepAppState {
  actionDic: {
    value: ConrepAppActionDic | null;
    status: AppLoadingStatus;
  };

  filterPresets: {
    value: ConrepAppFilterPresets | null;
    status: AppLoadingStatus;
  };
  techCrew: {
    value: TechCrew | null;
    status: AppLoadingStatus;
  };
  userTechCrews: {
    value: TechCrew[] | null;
    status: AppLoadingStatus;
  };
  isTechCrewControlled: {
    value: boolean | null;
    status: AppLoadingStatus;
  };
  workdayData: {
    value: ConrepAppWorkday | null;
    status: AppLoadingStatus;
  };
  workdayNumbers: {
    value: ConrepAppWorkdayNumbers | null;
    status: AppLoadingStatus;
  };
  workdayElements: {
    value: ConrepAppWorkdayElements | null;
    status: AppLoadingStatus;
  };
  userStatus: {
    value: ConrepAppUserStatus | null;
    status: AppLoadingStatus;
  };
  viewerModelOptions: {
    value: ForgeView[] | null;
    status: AppLoadingStatus;
  };
  showViewerModelOptions: {
    value: boolean;
    status: AppLoadingStatus;
  };
  blockFiltering: {
    value: boolean;
    status: AppLoadingStatus;
  };
  allElementProperties: {
    value: ConrepAppElementProperties | null;
    status: AppLoadingStatus;
  };
  filteredElementProperties: {
    value: ConrepAppElementProperties | null;
    status: AppLoadingStatus;
  };
  propertySetsAll: {
    value: ConrepAppPropertySets | null;
    status: AppLoadingStatus;
  };
  propertySetsFiltered: {
    value: ConrepAppPropertySets | null;
    status: AppLoadingStatus;
  };
  loadingPercentage: {
    value: number | null;
    status: AppLoadingStatus;
  };
  actionFilter: {
    value: ActionFilter;
    status: AppLoadingStatus;
  };
  customFilter: {
    value: CustomFilter;
    status: AppLoadingStatus;
  };
  idFilter: {
    value: number[] | null;
    status: AppLoadingStatus;
  };
}

const initialState: ConrepAppState = {
  actionDic: { value: null, status: AppLoadingStatus.IDLE },
  filterPresets: { value: null, status: AppLoadingStatus.IDLE },
  techCrew: { value: null, status: AppLoadingStatus.IDLE },
  userTechCrews: { value: null, status: AppLoadingStatus.IDLE },
  isTechCrewControlled: { value: null, status: AppLoadingStatus.IDLE },
  workdayData: { value: null, status: AppLoadingStatus.IDLE },
  workdayNumbers: { value: null, status: AppLoadingStatus.IDLE },
  workdayElements: { value: null, status: AppLoadingStatus.IDLE },
  userStatus: { value: null, status: AppLoadingStatus.IDLE },
  viewerModelOptions: { value: null, status: AppLoadingStatus.IDLE },
  showViewerModelOptions: { value: false, status: AppLoadingStatus.IDLE },
  blockFiltering: { value: false, status: AppLoadingStatus.IDLE },
  allElementProperties: { value: null, status: AppLoadingStatus.IDLE },
  filteredElementProperties: { value: null, status: AppLoadingStatus.IDLE },
  propertySetsAll: { value: null, status: AppLoadingStatus.IDLE },
  propertySetsFiltered: { value: null, status: AppLoadingStatus.IDLE },
  loadingPercentage: { value: null, status: AppLoadingStatus.IDLE },
  actionFilter: {
    value: {
      mode: ConrepAppFilterPresetDateRestriction.NONE,
      actions: [],
      inverted: false,
    },
    status: AppLoadingStatus.IDLE,
  },
  customFilter: { value: {}, status: AppLoadingStatus.IDLE },
  idFilter: { value: null, status: AppLoadingStatus.IDLE },
};

export const conrepAppSlice = createSlice({
  name: 'conrepApp',
  initialState,
  reducers: {
    clearConrepAppState: () => initialState,

    setActionDic: (state, action: PayloadAction<ConrepAppActionDic | null>) => {
      state.actionDic.value = action.payload;
    },

    setFilterPresets: (
      state,
      action: PayloadAction<ConrepAppFilterPresets | null>,
    ) => {
      state.filterPresets.value = action.payload;
    },
    addNewFilterPreset: (
      state,
      action: PayloadAction<ConrepAppFilterPreset>,
    ) => {
      if (state.filterPresets.value) {
        state.filterPresets.value.push(action.payload);
      } else {
        state.filterPresets.value = [action.payload];
      }
    },
    updateFilterPresetStore: (
      state,
      action: PayloadAction<ConrepAppFilterPreset>,
    ) => {
      if (state.filterPresets.value) {
        const index = state.filterPresets.value.findIndex(
          (fp) => fp._id === action.payload._id,
        );
        state.filterPresets.value.splice(index, 1, action.payload);
      }
    },
    deleteFilterPresetStore: (state, action: PayloadAction<string>) => {
      if (state.filterPresets.value) {
        const index = state.filterPresets.value.findIndex(
          (fp) => fp._id === action.payload,
        );
        state.filterPresets.value.splice(index, 1);
      }
    },

    setTechCrew: (state, action: PayloadAction<TechCrew | null>) => {
      state.techCrew.value = action.payload;
    },
    setUserTechCrews: (state, action: PayloadAction<TechCrew[] | null>) => {
      state.userTechCrews.value = action.payload;
    },

    setWorkdayData: (state, action: PayloadAction<ConrepAppWorkday | null>) => {
      state.workdayData.value = action.payload;
    },
    setWorkdayDataUpdate: (state, action: PayloadAction<string>) => {
      if (state.workdayData.value) {
        state.workdayData.value.updatedAt = new Date().toISOString();
        state.workdayData.value.updatedBy = action.payload;
      }
    },
    setWorkdayNumbers: (
      state,
      action: PayloadAction<ConrepAppWorkdayNumbers | null>,
    ) => {
      state.workdayNumbers.value = action.payload;
    },
    workdayNumbersChangeCount: (
      state,
      action: PayloadAction<{
        wdId: string;
        action: string;
        path: string;
        count: number;
      }>,
    ) => {
      const index = (
        state.workdayNumbers.value as ConrepAppWorkdayNumbersEntry[]
      ).findIndex((wdn) => action.payload.wdId === wdn._id);
      if (
        index > -1 &&
        state.workdayNumbers.value &&
        (action.payload.path === 'elementCount' ||
          action.payload.path === 'infoCount' ||
          action.payload.path === 'nonModeledElementCount' ||
          action.payload.path === 'screenshotCount' ||
          action.payload.path === 'regieCount')
      ) {
        if (action.payload.action === 'ADD') {
          state.workdayNumbers.value[index][action.payload.path] +=
            action.payload.count;
        }
        if (action.payload.action === 'REMOVE') {
          state.workdayNumbers.value[index][action.payload.path] -=
            action.payload.count;
        }
        if (action.payload.action === 'SET') {
          state.workdayNumbers.value[index][action.payload.path] =
            action.payload.count;
        }
      }
    },
    workdayNumbersChangeUpdate: (
      state,
      action: PayloadAction<{ wdId: string; user: string }>,
    ) => {
      const index = (
        state.workdayNumbers.value as ConrepAppWorkdayNumbersEntry[]
      ).findIndex((wdn) => action.payload.wdId === wdn._id);
      if (index > -1 && state.workdayNumbers.value) {
        state.workdayNumbers.value[index].updatedAt = new Date().toString();
        state.workdayNumbers.value[index].updatedBy = action.payload.user;
      }
    },

    setWorkdayElements: (
      state,
      action: PayloadAction<ConrepAppWorkdayElements | null>,
    ) => {
      state.workdayElements.value = action.payload;
    },

    deleteElementActionStore: (
      state,
      action: PayloadAction<{ extId: string; idToDelete: string }>,
    ) => {
      if (
        state.workdayElements.value &&
        action.payload.extId in state.workdayElements.value
      ) {
        state.workdayElements.value[action.payload.extId] =
          state.workdayElements.value[action.payload.extId].filter(
            (ea) => ea._id !== action.payload.idToDelete,
          );
      }
    },
    deleteElementActionMultiStore: (
      state,
      action: PayloadAction<{
        actionId: string;
        wdId: string;
        extIds: string[];
      }>,
    ) => {
      action.payload.extIds.forEach((extId) => {
        if (
          state.workdayElements.value &&
          extId in state.workdayElements.value
        ) {
          state.workdayElements.value[extId] = state.workdayElements.value[
            extId
          ].filter(
            (ea) =>
              !(
                !isString(ea.workday) &&
                ea.workday._id === action.payload.wdId &&
                ea.action === action.payload.actionId &&
                ea.externalId === extId
              ),
          );
        }
      });
    },
    createOrUpdateElementActionStore: (
      state,
      action: PayloadAction<ElementAction>,
    ) => {
      if (state.workdayElements.value) {
        if (action.payload.externalId in state.workdayElements.value) {
          const index = state.workdayElements.value[
            action.payload.externalId
          ].findIndex((ea) => ea._id === action.payload._id);
          if (index !== undefined && index > -1) {
            state.workdayElements.value[action.payload.externalId][index] =
              action.payload;
          } else {
            state.workdayElements.value[action.payload.externalId].push(
              action.payload,
            );
            state.workdayElements.value[action.payload.externalId].sort(
              compareDateAsc,
            );
          }
        } else {
          state.workdayElements.value[action.payload.externalId] = [
            action.payload,
          ];
        }
      }
    },
    setUserStatus: (
      state,
      action: PayloadAction<ConrepAppUserStatus | null>,
    ) => {
      state.userStatus.value = action.payload;
    },
    setViewerModelOptions: (
      state,
      action: PayloadAction<ForgeView[] | null>,
    ) => {
      state.viewerModelOptions.value = action.payload;
    },
    setShowViewerModelOptions: (state, action: PayloadAction<boolean>) => {
      state.showViewerModelOptions.value = action.payload;
    },
    setBlockFiltering: (state, action: PayloadAction<boolean>) => {
      state.blockFiltering.value = action.payload;
    },
    setAllElementProperties: (
      state,
      action: PayloadAction<ConrepAppElementProperties | null>,
    ) => {
      state.allElementProperties.value = action.payload;
    },
    setFilteredElementProperties: (
      state,
      action: PayloadAction<ConrepAppElementProperties | null>,
    ) => {
      state.filteredElementProperties.value = action.payload;
    },
    setPropertySetsAll: (
      state,
      action: PayloadAction<ConrepAppPropertySets | null>,
    ) => {
      state.propertySetsAll.value = action.payload;
    },
    setPropertySetsFiltered: (
      state,
      action: PayloadAction<ConrepAppPropertySets | null>,
    ) => {
      state.propertySetsFiltered.value = action.payload;
    },
    setLoadingPercentage: (state, action: PayloadAction<number | null>) => {
      state.loadingPercentage.value = action.payload;
    },
    deleteRegie: (state, action: PayloadAction<string>) => {
      const index = state.workdayData.value?.regies.findIndex(
        (r: ConrepAppRegie) => r._id === action.payload,
      );
      if (index !== undefined && index > -1) {
        state.workdayData.value?.regies.splice(index, 1);
      }
    },
    addRegie: (state, action: PayloadAction<ConrepAppRegie>) => {
      state.workdayData.value?.regies.push(action.payload);
    },
    updateRegie: (state, action: PayloadAction<ConrepAppRegie>) => {
      const index = state.workdayData.value?.regies.findIndex(
        (r: ConrepAppRegie) => r._id === action.payload._id,
      );
      if (index !== undefined && index > -1) {
        state.workdayData.value?.regies.splice(index, 1, action.payload);
      }
    },
    deleteElementActionInWorkdayData: (
      state,
      action: PayloadAction<string>,
    ) => {
      if (
        state.workdayData.value &&
        isArrayOfType<ElementAction>(state.workdayData.value.elementActions)
      ) {
        state.workdayData.value.elementActions =
          state.workdayData.value.elementActions.filter(
            (ea) => ea._id !== action.payload,
          );
      }
    },
    deleteElementActionMultiInWorkdayData: (
      state,
      action: PayloadAction<{ actionId: string; extIds: string[] }>,
    ) => {
      if (
        state.workdayData.value &&
        isArrayOfType<ElementAction>(state.workdayData.value.elementActions)
      ) {
        state.workdayData.value.elementActions =
          state.workdayData.value.elementActions.filter(
            (ea) =>
              !(
                action.payload.extIds.includes(ea.externalId) &&
                ea.action === action.payload.actionId
              ),
          );
      }
    },
    createOrUpdateElementActionInWorkdayData: (
      state,
      action: PayloadAction<ElementAction>,
    ) => {
      if (
        state.workdayData.value &&
        isArrayOfType<ElementAction>(state.workdayData.value.elementActions)
      ) {
        const index = state.workdayData.value.elementActions.findIndex(
          (ea) => ea._id === action.payload._id,
        );
        if (index !== undefined && index > -1) {
          state.workdayData.value.elementActions[index] = action.payload;
        } else {
          state.workdayData.value.elementActions.push(action.payload);
        }
      }
    },
    addWorkdayReviewStore: (
      state,
      action: PayloadAction<ConrepAppWorkdayReview>,
    ) => {
      state.workdayData.value?.reviews.push(action.payload);

      if (action.payload.status) {
        state.workdayNumbers.value?.find((wdn) => {
          if (wdn._id === action.payload.workdayId) {
            wdn.approved = action.payload.status === ReviewStatus.APPROVED;
            return true;
          }
          return false;
        });
      }
    },
    addOpenWorkdayReviewStore: (state) => {
      state.workdayData.value?.reviews.push({
        status: ReviewStatus.OPEN,
        createdAt: new Date().toISOString(),
      });
    },
    addWorkdayMessageStore: (
      state,
      action: PayloadAction<ConrepAppWorkdayMessage>,
    ) => {
      state.workdayData.value?.messages.push(action.payload);

      if (action.payload.text) {
        state.workdayNumbers.value?.find((wdn) => {
          if (wdn._id === action.payload.workdayId) {
            wdn.messages += 1;
            return true;
          }
          return false;
        });
      }
    },
    setActiveFiltersCustom: (
      state,
      action: PayloadAction<{ filterParameter: string; value: string[] }>,
    ) => {
      state.customFilter.value[action.payload.filterParameter] =
        action.payload.value;
    },
    setActiveFiltersActionMode: (
      state,
      action: PayloadAction<ConrepAppFilterPresetDateRestriction>,
    ) => {
      state.actionFilter.value.mode = action.payload;
    },
    setActionFilterActions: (
      state,
      action: PayloadAction<Action[] | string[]>,
    ) => {
      state.actionFilter.value.actions = action.payload;
    },
    setActionFilterInverted: (state, action: PayloadAction<boolean>) => {
      state.actionFilter.value.inverted = action.payload;
    },
    setFilterPreset: (state, action: PayloadAction<ConrepAppReduxFilter>) => {
      state.actionFilter.value = action.payload.actionFilter;
      state.customFilter.value = action.payload.customFilter;
      state.idFilter.value = action.payload.idFilter;
    },
    setIdFilter: (state, action: PayloadAction<number[] | null>) => {
      state.idFilter.value = action.payload;
    },
    updateScreenshotDescription: (
      state,
      action: PayloadAction<{ screenshotId: string; description: string }>,
    ) => {
      const index = state.workdayData.value?.screenshots.findIndex(
        (ss) => ss._id === action.payload.screenshotId,
      );
      if (index !== undefined && index > -1 && state.workdayData.value) {
        state.workdayData.value.screenshots[index].description =
          action.payload.description;
      }
    },
    resetFilters: (state) => {
      state.idFilter.value = [];
      state.actionFilter.value = {
        mode: ConrepAppFilterPresetDateRestriction.NONE,
        actions: [],
        inverted: false,
      };
      state.customFilter.value = {};
    },
  },
});

export const {
  clearConrepAppState,
  setActionDic,
  setFilterPresets,
  addNewFilterPreset,
  updateFilterPresetStore,
  deleteFilterPresetStore,
  setTechCrew,
  setUserTechCrews,
  setWorkdayElements,
  deleteElementActionStore,
  deleteElementActionMultiStore,
  createOrUpdateElementActionStore,
  setWorkdayNumbers,
  workdayNumbersChangeCount,
  workdayNumbersChangeUpdate,
  setWorkdayData,
  setWorkdayDataUpdate,
  setUserStatus,
  setViewerModelOptions,
  setShowViewerModelOptions,
  setIdFilter,
  setBlockFiltering,
  setAllElementProperties,
  setFilteredElementProperties,
  setPropertySetsAll,
  setPropertySetsFiltered,
  setLoadingPercentage,
  deleteRegie,
  addRegie,
  updateRegie,
  deleteElementActionInWorkdayData,
  deleteElementActionMultiInWorkdayData,
  createOrUpdateElementActionInWorkdayData,
  addWorkdayReviewStore,
  addOpenWorkdayReviewStore,
  addWorkdayMessageStore,
  setActiveFiltersCustom,
  setActiveFiltersActionMode,
  setActionFilterActions,
  setActionFilterInverted,
  updateScreenshotDescription,
  resetFilters,
  setFilterPreset,
} = conrepAppSlice.actions;
export const { reducer } = conrepAppSlice;

export const selectConrepAppActionDic = (state: RootState) =>
  state.conrepApp.actionDic.value;
export const selectConrepAppFilterPresets = (state: RootState) =>
  state.conrepApp.filterPresets.value;
export const selectConrepAppTechCrew = (state: RootState) =>
  state.conrepApp.techCrew.value;
export const selectConrepAppUserTechCrews = (state: RootState) =>
  state.conrepApp.userTechCrews.value;
export const selectWorkdayData = (state: RootState) =>
  state.conrepApp.workdayData.value;
export const selectWorkdayNumbers = (state: RootState) =>
  state.conrepApp.workdayNumbers.value;
export const selectWorkdayElements = (state: RootState) =>
  state.conrepApp.workdayElements.value;
export const selectUserStatus = (state: RootState) =>
  state.conrepApp.userStatus.value;
export const selectViewerModelOptions = (state: RootState) =>
  state.conrepApp.viewerModelOptions.value;
export const selectShowViewerModelOptions = (state: RootState) =>
  state.conrepApp.showViewerModelOptions.value;
export const selectBlockFiltering = (state: RootState) =>
  state.conrepApp.blockFiltering.value;
export const selectAllElementProperties = (state: RootState) =>
  state.conrepApp.allElementProperties.value;
export const selectFilteredElementProperties = (state: RootState) =>
  state.conrepApp.filteredElementProperties.value;
export const selectPropertySetsAll = (state: RootState) =>
  state.conrepApp.propertySetsAll.value;
export const selectPropertySetsFiltered = (state: RootState) =>
  state.conrepApp.propertySetsFiltered.value;
export const selectLoadingPercentage = (state: RootState) =>
  state.conrepApp.loadingPercentage.value;
export const selectActionFilter = (state: RootState) =>
  state.conrepApp.actionFilter.value;
export const selectIdFilter = (state: RootState) =>
  state.conrepApp.idFilter.value;
export const selectCustomFilter = (state: RootState) =>
  state.conrepApp.customFilter.value;
