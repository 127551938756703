import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/pro-regular-svg-icons';

import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';
import WarningIllustration from 'src/assets/illu_warning.svg';

type Props = {
  onClose: () => void;
  onRetry?: () => void;
};

export const FailureModal = ({ onRetry, onClose }: Props) => {
  const { t } = useTranslation('file');

  return (
    <>
      <div className="flex items-center justify-between">
        <Modal.Title>{t('upload.failureModal.title')}</Modal.Title>
        <Modal.Close onClose={onClose} />
      </div>
      <div className="mt-6 flex flex-col items-center gap-7">
        <img alt="" src={WarningIllustration} />
        <div className="flex flex-col gap-4">
          <h2 className="text-xl font-semibold">
            {t('upload.failureModal.subTitle')}
          </h2>
          <p className="text-shuttleGray-600">
            {t('upload.failureModal.text')}
          </p>
        </div>
        <div className="ml-auto mt-5 flex gap-4">
          <Button variant="secondary" onClick={onClose}>
            {t('upload.failureModal.closeButton')}
          </Button>
          {onRetry && (
            <Button onClick={onRetry}>
              <FontAwesomeIcon icon={faUpload} fixedWidth />
              {t('upload.failureModal.retryButton')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
