import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';

import { Spinner } from 'src/common/components/Spinner';
import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';
import newOwnerIllu from 'src/assets/illu_new-owner.svg';
import { SelectNative } from 'src/common/components/inputs/SelectNative/SelectNative';
import toast from 'src/common/toast';
import { transferOwnership } from '../api';
import { useCompanyUsersQuery } from '../queries';

interface Props {
  companyId: string;
  userId: string;
  isOpen: boolean;
  onClose: () => void;
  onTransferred?: () => void;
}

export const TransferOwnerModal = ({
  companyId,
  userId,
  isOpen,
  onClose,
  onTransferred,
}: Props) => {
  const { t } = useTranslation(['company', 'common']);
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<{ newOwner: string }>({ defaultValues: { newOwner: '' } });

  const { data: companyMembers, isPending } = useCompanyUsersQuery(companyId);

  const handleTransfer = async ({ newOwner }: { newOwner: string }) => {
    try {
      await transferOwnership(companyId, newOwner);
      queryClient.invalidateQueries({
        queryKey: ['companies'],
      });
      toast.success(t('company:transferOwner.success'));
      onTransferred && onTransferred();
      onClose();
    } catch (e) {
      toast.error(t('company:transferOwner.failure'));
    }
  };

  const potentialNewOwners = companyMembers.filter((u) => u._id !== userId);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="w-[680px]">
      <Modal.Header onClose={onClose}>
        {t('company:transferOwner.title')}
      </Modal.Header>
      <Modal.Body>
        <form
          className="mt-8 flex flex-col items-center gap-6"
          onSubmit={handleSubmit(handleTransfer)}
        >
          <div className="ml-4 flex gap-9">
            <img src={newOwnerIllu} alt="" />
            <div className="flex w-full flex-col gap-4">
              <p className="text-shuttleGray-600">
                <Trans ns="company" i18nKey="transferOwner.text" />
              </p>
              {isPending ? (
                <Spinner />
              ) : (
                <SelectNative
                  label={t('company:transferOwner.newOwner')}
                  required
                  {...register('newOwner')}
                >
                  <option value="" disabled hidden>
                    {t('company:transferOwner.choose')}
                  </option>
                  {potentialNewOwners.map((member) => (
                    <option key={member._id} value={member._id}>
                      {member.name} {member.surname}
                    </option>
                  ))}
                </SelectNative>
              )}
            </div>
          </div>
          <div className="ml-auto flex gap-2">
            <Button variant="tertiary" onClick={onClose}>
              {t('common:button.abort')}
            </Button>
            <Button variant="primary" type="submit" disabled={!isDirty}>
              {t('common:button.save')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
