import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { useMutation } from '@tanstack/react-query';

import { Button } from 'src/common/components/buttons/Button';
import { Card } from 'src/common/components/Card';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { getSignupLinkByEmail } from '../apiSignups';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';

export const SignupsView = () => {
  const { t } = useTranslation(['admin', 'common']);

  const mutation = useMutation({
    mutationFn: getSignupLinkByEmail,
    onSuccess() {
      toast.success(t('signups.success'));
    },
    onError() {
      toast.error(t('signups.error'));
    },
  });
  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    mutation.mutate(email);
  };

  const handleCopyToClipboard = () => {
    if (mutation.data) {
      navigator.clipboard.writeText(mutation.data.url);
      toast.success(t('signups.savedLinkToClipboard'));
    }
  };

  return (
    <Card className="mt-4 flex flex-col p-6">
      <h1 className="text-2xl font-semibold tracking-wide">
        {t('signups.title')}
      </h1>
      <p className="mb-4 mt-4">{t('signups.text')}</p>

      <TextInput
        placeholder={t('admin:placeholder', {
          title: t('common:email'),
        })}
        onChange={(e) => setEmail(e.target.value)}
        autoFocus
      />
      <Button
        className="mt-4 w-40"
        onClick={() => handleSubmit()}
        disabled={email.length === 0}
      >
        {t('signups.getLink')}
      </Button>
      {mutation.data && (
        <div className="mt-4 flex bg-shuttleGray-100 p-3">
          <span>{mutation.data.url}</span>
          <ActionIcon className="ml-auto" onClick={handleCopyToClipboard}>
            <FontAwesomeIcon icon={faCopy} />
          </ActionIcon>
        </div>
      )}
    </Card>
  );
};
