import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useProjectCustomParameters } from 'src/Conrep/App/Model/PropertyPanel/queries';
import { ConrepAppModelBased } from 'src/Conrep/App/ModelBased';
import { getProperties } from 'src/Forge';
import { Card } from 'src/common/components/Card';
import { Chip } from 'src/common/components/Chip';
import { SidebarLayout } from 'src/common/components/SidebarLayout';
import toast from 'src/common/toast';
import { useFilesQuery } from 'src/file/queries';
import { CustomParameters } from '../components/ModelParameter/CustomParameters';
import { DisplayedParameters } from '../components/ModelParameter/DisplayedParameters';
import { Filters } from '../components/ModelParameter/Filters';
import { ModelSelection } from '../components/ModelParameter/ModelSelection';
import { SettingsSidebar } from '../components/SettingsSidebar';
import { useProject } from '../project-context';
import { is3dProject } from '../utils';

export const ModelParameterView = () => {
  const { t } = useTranslation('project');
  const project = useProject();
  const { data: files } = useFilesQuery(project._id);

  const [parameters, setParameters] = useState<string[]>([]);
  const { data: customParameters } = useProjectCustomParameters(project._id);

  const [parametersLoading, setParametersLoading] = useState(false);

  const handleLoadParameters = async (fileId: string, viewId: string) => {
    if (files) {
      const modelFile = files.find((file) => file._id === fileId);
      if (modelFile && 'activeVersion' in modelFile) {
        try {
          setParametersLoading(true);
          const properties = await getProperties(
            project._id,
            fileId,
            modelFile.activeVersion,
            viewId,
          );
          setParameters(properties);
          toast.success(
            t('settings.modelParameter.loadParametersSuccess', {
              count: properties.length,
            }),
          );
          if (properties.length <= 0) {
            toast.error(
              t('settings.modelParameter.loadParametersNoParameters'),
            );
          }
        } catch (err) {
          toast.error(t('settings.modelParameter.loadParametersFailure'));
        } finally {
          setParametersLoading(false);
        }
      }
    }
  };

  return (
    <SidebarLayout
      header={
        <div>
          <h3 className="mb-1 text-2xl font-semibold">
            {t('settings.modelParameter.title')}
          </h3>
          <p className="text-shuttleGray-600">
            {project && is3dProject(project)
              ? t('settings.modelParameter.description')
              : t('settings.modelParameter.onlyIn3D')}
          </p>
        </div>
      }
      sidebar={<SettingsSidebar />}
    >
      <Card className="p-4">
        <ConrepAppModelBased>
          <h4 className="mb-4 text-lg font-semibold">
            {t('settings.modelParameter.modelSelection.title')}
          </h4>
          {parameters && parameters.length > 0 ? (
            <Chip className="mr-auto" onClose={() => setParameters([])}>
              <Trans
                ns="project"
                i18nKey="settings.modelParameter.loadedParameters"
                values={{ count: parameters.length }}
                components={[<span className="font-semibold" key="1" />]}
              />
            </Chip>
          ) : (
            <ModelSelection
              project={project}
              loading={parametersLoading}
              onLoadParameters={handleLoadParameters}
            />
          )}

          <hr className="my-6" />

          <h4 className="mb-2 text-lg font-semibold">
            {t('settings.modelParameter.displayedParameters.title')}
          </h4>
          <p className="mb-4 text-shuttleGray-600">
            <Trans
              ns="project"
              i18nKey="settings.modelParameter.displayedParameters.subtitle"
              components={[
                <span className="font-semibold text-shuttleGray-800" key="1" />,
              ]}
            />
          </p>

          <DisplayedParameters project={project} parameters={parameters} />

          <hr className="my-6" />

          <h4 className="mb-2 text-lg font-semibold">
            {t('settings.modelParameter.filters.title')}
          </h4>
          <p className="mb-4 text-shuttleGray-600">
            <Trans
              ns="project"
              i18nKey="settings.modelParameter.filters.subtitle"
              components={[
                <span className="font-semibold text-shuttleGray-800" key="1" />,
              ]}
            />
          </p>
          <Filters project={project} parameters={parameters} />
          <hr className="my-6" />

          <div className="hidden">
            <h4 className="mb-2 text-lg font-semibold">
              {t('settings.modelParameter.customParameters.title')}
            </h4>
            <p className="mb-4 text-shuttleGray-600">
              <Trans
                ns="project"
                i18nKey="settings.modelParameter.customParameters.subtitle"
                components={[
                  <span
                    className="font-semibold text-shuttleGray-800"
                    key="1"
                  />,
                ]}
              />
            </p>
          </div>
          <CustomParameters
            project={project}
            parameters={customParameters || []}
          />
        </ConrepAppModelBased>
      </Card>
    </SidebarLayout>
  );
};
