import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import {
  faChevronDown,
  faChevronUp,
  faCog,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';

import { Badge } from 'src/common/components/Badge';
import { ProjectWithDownloadRef } from 'src/project/types/projects';
import { Button } from 'src/common/components/buttons/Button';
import { ProjectListItem } from '../Item';
import { ProjectListItemCreate } from '../Item/Create';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { useModal } from 'src/common/components/Modal';
import { ProjectCreateModal } from '../../CreateProject/ProjectCreateModal';

type Props = {
  companyGroup: {
    name: string;
    companyId?: string;
    isWorkspace?: boolean;
    isAdmin?: boolean;
    projects: ProjectWithDownloadRef[];
  };
  disableCollapse?: boolean;
};

export const ProjectListCompanySection = ({
  companyGroup: {
    name,
    companyId,
    isWorkspace = false,
    isAdmin = false,
    projects,
  },
  disableCollapse,
}: Props) => {
  const { t } = useTranslation('project');
  const navigate = useNavigate();
  const { open, close, isOpen } = useModal();

  const [showCompanySection, setShowCompanySection] = useState(true);

  return (
    <>
      <div key={name}>
        <div className="mb-4 flex items-center gap-2">
          <h2 className="text-lg font-semibold">{name}</h2>
          <CompanySectionBadge isWorkspace={isWorkspace} isAdmin={isAdmin} />
          {!disableCollapse && (
            <ActionIcon
              onClick={() => setShowCompanySection(!showCompanySection)}
            >
              <FontAwesomeIcon
                icon={showCompanySection ? faChevronDown : faChevronUp}
              />
            </ActionIcon>
          )}
          <div className="flex grow justify-end">
            {companyId && (
              <div className="flex gap-2">
                {!isWorkspace && (
                  <Button
                    variant="tertiary"
                    size="sm"
                    onClick={() => navigate(`/company/${companyId}/members`)}
                  >
                    <FontAwesomeIcon icon={faUser} />
                    {t('listView.members')}
                  </Button>
                )}
                {isAdmin && (
                  <Button
                    variant="tertiary"
                    size="sm"
                    onClick={() => navigate(`/company/${companyId}/settings`)}
                  >
                    <FontAwesomeIcon icon={faCog} />
                    {t('listView.settings')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>

        {(showCompanySection || disableCollapse) &&
          (!isAdmin && projects.length <= 0 ? (
            <p>{t('listView.memberNoProjects')}</p>
          ) : (
            <div
              className="grid gap-4"
              style={{
                gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
              }}
            >
              {isAdmin && <ProjectListItemCreate onClick={open} />}
              {projects.map((project) => (
                <ProjectListItem key={project._id} project={project} />
              ))}
            </div>
          ))}
      </div>
      <ProjectCreateModal
        isOpen={isOpen}
        onClose={close}
        companyId={companyId}
      />
    </>
  );
};

const CompanySectionBadge = ({
  isWorkspace,
  isAdmin,
}: {
  isWorkspace: boolean;
  isAdmin: boolean;
}) => {
  const { t } = useTranslation('project');

  if (isWorkspace) {
    return <Badge>{t('listView.myWorkspace')}</Badge>;
  }

  if (isAdmin) {
    return <Badge>{t('listView.myOrganization')}</Badge>;
  }

  return null;
};
