import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/common/components/buttons/Button';
import { NotFound } from 'src/common/components/NotFound';
import { MainAppbarLayout } from 'src/app/components/MainAppbarLayout';

export const NotFoundInApp = () => {
  const { t } = useTranslation('app');
  return (
    <MainAppbarLayout>
      <NotFound
        action={
          <Link to="/">
            <Button>
              <FontAwesomeIcon icon={faArrowLeft} />
              {t('notFoundGoBack')}
            </Button>
          </Link>
        }
      />
    </MainAppbarLayout>
  );
};
