import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { changeProjectCompany } from 'src/admin/apiProjects';
import {
  useAdminCompaniesQuery,
  useAdminCompanyUsersQuery,
} from 'src/admin/queries';
import { Button } from 'src/common/components/buttons/Button';
import { Pagination } from 'src/common/components/Pagination';
import { GetAdminProjectResponse } from 'src/project/types/projects';
import { SearchSort } from 'src/common/components/SearchSort';
import { Card } from 'src/common/components/Card';
import { Modal } from 'src/common/components/Modal';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Spinner } from 'src/common/components/Spinner';
import { SelectNative } from 'src/common/components/inputs/SelectNative/SelectNative';

import type { OwnerPopulatedCompany } from 'shared';

type Props = {
  project: GetAdminProjectResponse;
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
};

export const ProjectChangeCompanyModal = ({
  project,
  isOpen,
  onBack,
  onClose,
}: Props) => {
  const { t } = useTranslation(['admin', 'common']);
  const queryClient = useQueryClient();

  const [newCompanyId, setNewCompanyId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const [searchBy, setSearchBy] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const { data: paginatedCompanies, isLoading: isLoadingCompanies } =
    useAdminCompaniesQuery(page, entriesPerPage, searchBy, sortBy, sortOrder);

  const sortOptions: SelectSitelifeOptionType[] = [
    { label: t('common:name'), value: 'name' },
    { label: t('common:description'), value: 'description' },
    { label: t('common:owner'), value: 'owner.name' },
    { label: t('common:city'), value: 'cityName' },
  ];

  const companies = paginatedCompanies ? paginatedCompanies.data : [];

  async function handleChangeCompany(newOwnerId: string) {
    try {
      setIsLoading(true);
      if (!newCompanyId || !newOwnerId) {
        throw new Error();
      }
      await changeProjectCompany(project._id, newCompanyId, newOwnerId);
      await queryClient.invalidateQueries({
        queryKey: ['admin-projects'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['admin-users'],
      });
      toast.success(t('admin:projects.modal.information.changeCompanySuccess'));
      onBack();
    } catch (e) {
      toast.error(t('admin:projects.modal.information.changeCompanyFailure'));
    } finally {
      setNewCompanyId(undefined);
      setIsLoading(false);
    }
  }

  return (
    <Modal className="min-w-[700px]" isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header onClose={onClose}>
        {t('admin:projects.modal.information.changeCompanyTitle')}
      </Modal.Header>
      <div className="flex h-[600px] flex-col justify-between">
        <div className="px-6">
          <SearchSort
            searchBy={searchBy}
            sortBy={
              sortOptions.find((sortOption) => sortOption.value === sortBy) ||
              sortOptions[0]
            }
            sortOrder={sortOrder}
            sortOptions={sortOptions}
            onSearchBy={(newSearchBy) => setSearchBy(newSearchBy)}
            onSortBy={(newSortBy) => setSortBy(newSortBy)}
            onSortOrder={(newSortOrder) => setSortOrder(newSortOrder)}
          />
        </div>
        <div className="mt-6 h-full overflow-y-auto bg-gray-100 p-6">
          <Card>
            {isLoadingCompanies ? (
              <Spinner containerClassName="w-full h-full my-[160px] text-center" />
            ) : companies.length > 0 ? (
              <table className="relative w-full divide-y divide-gray-200 shadow">
                <thead>
                  <tr>
                    <th scope="col" className="table-th">
                      {t('common:name')}
                    </th>
                    <th scope="col" className="table-th">
                      {t('common:description')}
                    </th>
                    <th scope="col" className="table-th xl:table-cell" />
                  </tr>
                </thead>
                <tbody className="table-body">
                  {companies.map((company) => (
                    <CompanyRow
                      key={company._id}
                      company={company}
                      currentCompanyId={project.company._id}
                      onClickCompany={setNewCompanyId}
                    />
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
                {searchBy ? (
                  <>
                    <span className="font-semibold text-shuttleGray-800">
                      {`${searchBy}`}
                    </span>{' '}
                    {t('common:notFound.message')}
                  </>
                ) : (
                  t('common:noUsers')
                )}
              </p>
            )}
          </Card>
        </div>
        {companies.length > 0 && !isLoadingCompanies && paginatedCompanies && (
          <Pagination
            maxPage={Math.ceil(paginatedCompanies.totalCount / entriesPerPage)}
            page={page}
            onChangePage={setPage}
            entriesPerPage={entriesPerPage}
            onChangeEntriesPerPage={setEntriesPerPage}
            className="p-6"
          />
        )}
      </div>
      {newCompanyId && (
        <Modal
          isOpen={!!newCompanyId}
          onRequestClose={() => setNewCompanyId(undefined)}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <ChangeOwnerModal
              project={project}
              companyId={newCompanyId}
              onConfirm={(ownerId: string) => handleChangeCompany(ownerId)}
              onCancel={() => setNewCompanyId(undefined)}
              isLoading={isLoading}
            />
          )}
        </Modal>
      )}
    </Modal>
  );
};

const CompanyRow = ({
  company,
  currentCompanyId,
  onClickCompany,
}: {
  company: OwnerPopulatedCompany;
  currentCompanyId: string;
  onClickCompany: (id?: string) => void;
}) => {
  const { t } = useTranslation(['admin', 'common']);
  return (
    <tr>
      <td className="table-td table-td-truncate min-w-[200px] font-semibold">
        {company.name}
      </td>
      <td className="table-td table-td-truncate min-w-[200px]">
        {company.description}
      </td>
      <td className="table-td flex justify-end">
        <Button
          variant="secondary"
          size="sm"
          onClick={() => onClickCompany(company._id)}
          disabled={company._id === currentCompanyId}
        >
          {company._id === currentCompanyId
            ? t('admin:projects.modal.information.organization')
            : t('common:button.select')}
        </Button>
      </td>
    </tr>
  );
};

const ChangeOwnerModal = ({
  project,
  companyId,
  onConfirm,
  onCancel,
  isLoading,
}: {
  project: GetAdminProjectResponse;
  companyId: string;
  onConfirm: (id: string) => Promise<void>;
  onCancel: () => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation(['admin', 'common']);

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<{ newOwnerId: string }>({ defaultValues: { newOwnerId: '' } });

  const { data: companyMembers, isLoading: isLoadingCompanyMembers } =
    useAdminCompanyUsersQuery(companyId);

  async function handleSave({ newOwnerId }: { newOwnerId: string }) {
    await onConfirm(newOwnerId);
  }

  const potentialNewOwners = companyMembers?.filter(
    (u) => u._id !== project.owner._id,
  );

  return (
    <form
      className="flex w-[432px] flex-col space-y-3 p-4"
      onSubmit={handleSubmit(handleSave)}
    >
      <h3 className="text-xl font-bold">
        {t('admin:projects.modal.information.changeCompanyConfirmTitle')}
      </h3>
      <div className="text-shuttleGray-600">
        {t('admin:projects.modal.information.changeCompanyConfirmMessage')}
      </div>
      {isLoadingCompanyMembers ? (
        <Spinner className="h-full w-full" />
      ) : (
        <SelectNative
          label={t(
            'admin:projects.modal.information.changeCompanySelectNewOwner',
          )}
          required
          {...register('newOwnerId')}
        >
          <option value="" disabled hidden>
            {t('admin:projects.modal.information.transferOwner.choose')}
          </option>
          {potentialNewOwners.map((member) => (
            <option key={member._id} value={member._id}>
              {member.name} {member.surname}
            </option>
          ))}
        </SelectNative>
      )}
      <div className="flex justify-end space-x-2">
        <Button variant="tertiary" onClick={onCancel} disabled={isLoading}>
          {t('common:button.abort')}
        </Button>
        <Button
          type="submit"
          disabled={!isDirty || !isValid}
          loading={isLoading}
        >
          {t('admin:projects.modal.information.changeCompanyConfirmButton')}
        </Button>
      </div>
    </form>
  );
};
