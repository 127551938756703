import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

import { Button } from 'src/common/components/buttons/Button';

type Props = {
  title: string;
  message: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  theme?: string;
  customCancelText?: string;
  customConfirmText?: string;
};

export const ConfirmationDialog = ({
  title,
  message,
  onCancel,
  onConfirm,
  customCancelText,
  customConfirmText,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex w-[432px] flex-col p-4">
      <h3 className="mb-3 text-xl font-bold">{title}</h3>
      <div className="mb-3 text-shuttleGray-600">{message}</div>
      <div className="flex justify-end space-x-2">
        <Button variant="tertiary" onClick={onCancel}>
          {customCancelText || t('button.abort')}
        </Button>
        <Button onClick={onConfirm}>
          {customConfirmText || t('button.confirm')}
        </Button>
      </div>
    </div>
  );
};
