import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';
import { CustomParameter } from '../../types/projects';
import { SelectNative } from 'src/common/components/inputs/SelectNative/SelectNative';

interface Props {
  parameter: CustomParameter | null | undefined;
  onSave: (newType: string | number | boolean) => void;
  onClose: () => void;
}

export const CustomParameterTypeModal = ({
  parameter,
  onSave,
  onClose,
}: Props) => {
  const { t } = useTranslation(['project', 'common']);
  const { register, handleSubmit, setValue, watch } = useForm<{
    type: string | number | boolean;
  }>();

  useEffect(() => {
    if (parameter) {
      setValue('type', parameter.type);
    }
  }, [parameter, setValue]);

  if (!parameter) {
    return null;
  }

  return (
    <Modal isOpen onRequestClose={onClose} className="w-[680px]">
      <Modal.Header onClose={onClose}>
        {t(
          'project:settings.modelParameter.customParameters.addCustomParametersType',
        )}
      </Modal.Header>
      <Modal.Body>
        <form
          className="flex flex-col gap-6"
          onSubmit={handleSubmit(({ type }) => {
            onSave(type);
          })}
        >
          <SelectNative
            label={t('project:settings.modelParameter.customParameters.type')}
            className="mt-2"
            required
            value={watch('type')?.toString() || parameter.type.toString()}
            {...register('type')}
          >
            {/* used for custom CustomParameter entry */}
            <option value="string">
              {t('project:settings.modelParameter.customParameters.textType')}
            </option>
            <option value="number">
              {t('project:settings.modelParameter.customParameters.numberType')}
            </option>
            <option value="boolean">
              {t(
                'project:settings.modelParameter.customParameters.booleanType',
              )}
            </option>
          </SelectNative>
          <div className="ml-auto flex gap-2">
            <Button variant="tertiary" onClick={onClose}>
              {t('common:button.abort')}
            </Button>
            <Button variant="primary" type="submit">
              {t('common:button.save')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
