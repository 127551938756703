import { useTranslation } from 'react-i18next';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from 'src/common/utils';
import LicenseBasicIllu from 'src/assets/projectLicense/illu_basic_license.svg';
import LicenseFreeIllu from 'src/assets/projectLicense/illu_free_license.svg';
import { Badge } from 'src/common/components/Badge/Badge';
import { Button } from 'src/common/components/buttons/Button';
import { LicenseType } from 'src/project/types/projects';
import { FeatureList } from 'src/common/components/FeatureList';

type Props = {
  handleProjectCreate: (
    licenseType: Exclude<LicenseType, LicenseType.DEMO>,
  ) => Promise<void>;
  disableLicenseButtons: boolean;
  isLoadingFreeProject: boolean;
  isLoadingBasicProject: boolean;
};

export const ProjectLicensePicker = ({
  handleProjectCreate,
  disableLicenseButtons,
  isLoadingFreeProject,
  isLoadingBasicProject,
}: Props) => {
  const { t } = useTranslation(['common', 'project']);

  return (
    <div className="flex justify-between gap-2">
      <div className="flex w-1/2 flex-col items-center gap-2 rounded-sm border py-4">
        <LicenseHeader
          illustration={LicenseFreeIllu}
          textLicense={t('common:license.free')}
          subtitleTextLicense={t('project:license.freeLicenseSubtitle')}
        />
        <FreeFeatures />
        <Button
          variant="secondary"
          className="mt-2 flex items-center rounded-sm border p-4"
          onClick={() => handleProjectCreate(LicenseType.FREE)}
          disabled={disableLicenseButtons}
          loading={isLoadingFreeProject}
        >
          {t('project:createForm.view.createProject')}
        </Button>
      </div>
      <div className="flex w-1/2 flex-col items-center gap-2 rounded-sm border py-4">
        <LicenseHeader
          illustration={LicenseBasicIllu}
          textLicense={t('common:license.basic')}
          subtitleTextLicense={t('project:license.basicLicenseSubtitle')}
          recommendation={t('project:createForm.view.recommendation')}
        />
        <BasicFeatures />
        <Button
          variant="primary"
          className="mt-2 flex items-center rounded-sm border p-4"
          onClick={() => handleProjectCreate(LicenseType.BASIC)}
          disabled={disableLicenseButtons}
          loading={isLoadingBasicProject}
        >
          {t('project:createForm.view.createProject')}
        </Button>
        <p className="mx-auto mt-2 text-center text-sm">
          *{t('project:license.additionalInfo')}
        </p>
      </div>
    </div>
  );
};

const LicenseHeader = ({
  illustration,
  textLicense,
  subtitleTextLicense,
  recommendation,
}: {
  illustration: string;
  textLicense: string;
  subtitleTextLicense: string;
  recommendation?: string;
}) => {
  return (
    <>
      <Badge
        variant="orange"
        className={cn('ml-auto mr-4', !recommendation && 'invisible')}
      >
        <FontAwesomeIcon icon={faStar} size="xs" />
        {recommendation}
      </Badge>
      <img src={illustration} alt="" className="h-16 w-auto" />
      <h4 className="font-semibold">{textLicense}</h4>
      <h6 className="font-normal text-gray-600">{subtitleTextLicense}</h6>
    </>
  );
};

const FreeFeatures = () => {
  const { t } = useTranslation(['project']);

  const features = [
    ...t('project:license.freeFunctionalities', {
      returnObjects: true,
    }).map((v) => ({
      label: v,
      enabled: true,
    })),
    ...t('project:license.freeDisabledFunctionalities', {
      returnObjects: true,
    }).map((v) => ({
      label: v,
      enabled: false,
    })),
  ];

  return (
    <div className="gap-4 px-6">
      <FeatureList features={features} columns={1} showPricing={false} />
    </div>
  );
};

const BasicFeatures = () => {
  const { t } = useTranslation(['project']);

  const features = t('project:license.basicFunctionalities', {
    returnObjects: true,
  }).map((v) => ({
    label: v,
    enabled: true,
  }));

  return (
    <div className="gap-4 px-6">
      <FeatureList features={features} columns={1} showPricing={false} />
    </div>
  );
};
