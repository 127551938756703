import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import {
  useProjectCustomParameterProperties,
  useProjectCustomParameters,
} from 'src/Conrep/App/Model/PropertyPanel/queries';
import { useProject } from 'src/project/project-context';
import { DraggableParameterItem } from './DraggableParameterItem';

export type ParameterItem = {
  title: string;
  subtitle?: string;
};

type Props = {
  items: ParameterItem[];
  canRemoveItems: boolean;
  setItems?: (items: ParameterItem[]) => void;
  onEdit?: (item: ParameterItem) => void;
};

export const DraggableParameterList = ({
  items,
  canRemoveItems,
  setItems,
  onEdit,
}: Props) => {
  const getListStyle = () => ({
    // background: isDraggingOver ? 'lightgray' : 'white',
    // padding: grid,
    width: '100%',
  });

  const currentProject = useProject();
  const { data: customParameters } = useProjectCustomParameters(
    currentProject._id,
  );
  const { data: customParameterPropertiesList } =
    useProjectCustomParameterProperties(currentProject._id);

  const hasLinkedProperties = (item: ParameterItem) => {
    const matchedParameter = customParameters?.find(
      (element) => element.name === item.title,
    );

    return !!(
      matchedParameter &&
      customParameterPropertiesList?.some(
        (element) => element.customParameter === matchedParameter._id,
      )
    );
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const tmp = Array.from(items);
    const [removed] = tmp.splice(result.source.index, 1);
    tmp.splice(result.destination.index, 0, removed);
    setItems && setItems(tmp);
  };

  const handleRemove = (item: string) => {
    const tmp = Array.from(items);
    const index = tmp.findIndex(
      (parameterItem) => parameterItem.title === item,
    );
    if (index > -1) {
      tmp.splice(index, 1);
      setItems && setItems(tmp);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle()}
          >
            {items.map((item, index) => (
              <DraggableParameterItem
                title={item.title}
                subtitle={item.subtitle}
                index={index}
                isLastItem={index === items.length - 1}
                canRemoveItem={canRemoveItems && !hasLinkedProperties(item)}
                onEdit={onEdit && onEdit}
                onRemove={setItems && handleRemove}
                key={item.title}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
