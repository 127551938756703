import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  CSSProperties,
  forwardRef,
  ReactNode,
} from 'react';

import { cn } from 'src/common/utils';
import { InputWrapper } from '../Wrapper/InputWrapper';

export interface TextAreaInputProps
  extends ComponentPropsWithoutRef<'textarea'> {
  id?: string;
  label?: ReactNode;
  description?: ReactNode;
  error?: string | boolean;
  required?: boolean;
  inputCount?: number;
  wrapperProps?: { [key: string]: any };
  resize?: boolean;
}

export const TextAreaInput = forwardRef<
  HTMLTextAreaElement,
  TextAreaInputProps
>(function TextAreaInput(props: TextAreaInputProps, ref) {
  const {
    className,
    id,
    label,
    error,
    required,
    disabled,
    style,
    placeholder,
    description,
    inputCount,
    wrapperProps,
    resize = true,
    maxLength = 1500,
    ...rest
  } = props;

  return (
    <InputWrapper
      id={id}
      required={required}
      aria-disabled={disabled}
      label={label}
      error={error}
      description={description}
      className={className}
      style={style}
      inputCount={inputCount}
      inputMaxLength={maxLength}
      {...wrapperProps}
    >
      <TextArea
        {...rest}
        required={required}
        disabled={disabled}
        invalid={!!error}
        ref={ref}
        id={id}
        placeholder={placeholder}
        error={error}
        style={{ resize: resize ? 'vertical' : 'none' }}
        maxLength={maxLength}
      />
    </InputWrapper>
  );
});

interface InputProps extends ComponentPropsWithoutRef<'textarea'> {
  id?: string;
  className?: string;
  style?: CSSProperties;
  invalid?: boolean;
  error?: string | boolean;
  required?: boolean;
  disabled?: boolean;
}

const TextArea = forwardRef(function TextArea(
  props: InputProps,
  ref: ComponentPropsWithRef<'textarea'>['ref'],
) {
  const { id, className, invalid, required, disabled, style, error, ...rest } =
    props;

  return (
    <textarea
      {...rest}
      id={id}
      ref={ref}
      required={required}
      aria-invalid={invalid}
      disabled={disabled}
      style={style}
      className={cn(
        { 'border-red-500': error },
        { 'border-gray-100 bg-gray-100': disabled },
        {
          'border-gray-300 hover:border-gray-500': !disabled && !error,
        },
        'w-full flex-auto rounded-sm leading-[22px]',
        'focus:border focus:border-blue-600 focus:outline-none',
        className,
      )}
    />
  );
});
