export class ExtendedGuiViewer3D extends Autodesk.Viewing.GuiViewer3D {
  lockSelection(
    dbIds: number | Array<number>,
    lock: boolean,
    model?: Autodesk.Viewing.Model,
  ) {
    model = model || this.model;
    this.impl.selector.lockSelection(dbIds, lock, model);
  }
}
