import { useTranslation } from 'react-i18next';

import { Tooltip } from 'src/common/components/Tooltip';
import { Beamer } from './Beamer';

export const Changelog = () => {
  const { t } = useTranslation('app');

  return (
    <Tooltip content={t('appbar.changelog')} position="bottom">
      <Beamer />
    </Tooltip>
  );
};
