import {
  Action,
  AnyAction,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';

import { reducer as conrepAppReducer } from 'src/Conrep/App/slice';
import { reducer as forgeViewerReducer } from 'src/Forge/slice';

export const createStore = (preloadedState = {}) =>
  configureStore({
    reducer: {
      conrepApp: conrepAppReducer,
      forgeViewer: forgeViewerReducer,
    },
    devTools: import.meta.env.MODE === 'development',
    preloadedState,
  });

export const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch &
  ThunkDispatch<RootState, null, AnyAction>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
