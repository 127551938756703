import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';

import { updateCompany, updateCompanyMembers } from 'src/admin/apiCompanies';
import {
  useAdminCompanyImageQuery,
  useAdminUsersQuery,
} from 'src/admin/queries';
import { Modal } from 'src/common/components/Modal';
import { Tabs } from 'src/common/components/Tabs';
import { AddMembersModal } from 'src/common/components/AddMembersModal';
import { CompanyInfoFormValues, CompanyInfoPage } from './CompanyInfoPage';
import { CompanyMembersPage } from './CompanyMembersPage';

import type { OwnerPopulatedCompany } from 'shared';

type Props = {
  company: OwnerPopulatedCompany;
  isPersonalCompany: boolean;
  isOpen: boolean;
  onClose: () => void;
};

export const CompanyEditModal = ({
  company,
  isPersonalCompany,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation(['admin', 'common']);

  const queryClient = useQueryClient();

  const { data: imageDownloadRef } = useAdminCompanyImageQuery(company._id);
  const [isLoadingCompanyInfoPage, setIsLoadingCompanyInfoPage] =
    useState(false);
  const [inAddMembers, setInAddMembers] = useState(false);

  async function callUpdateCompany(companyData: CompanyInfoFormValues) {
    try {
      setIsLoadingCompanyInfoPage(true);
      await updateCompany(companyData, company._id);
      await queryClient.invalidateQueries({
        queryKey: ['admin-companies'],
      });
      toast.success(t('admin:organizations.modal.updateOrgSuccess'));
    } catch {
      toast.error(t('admin:organizations.modal.updateOrgFailure'));
    } finally {
      setIsLoadingCompanyInfoPage(false);
    }
  }

  /**
   * @deprecated this violates react state design principles
   * TODO: refactor this, so children dont write state into the parent: lift state up
   */
  function CallGetUsersHook(
    page: number,
    entriesPerPage: number,
    searchBy?: string,
    sortBy?: string,
    sortOrder?: 'asc' | 'desc',
  ) {
    const { isPending, data: paginatedUsers } = useAdminUsersQuery(
      page,
      entriesPerPage,
      searchBy,
      sortBy,
      sortOrder,
    );

    return { isPending, paginatedUsers };
  }

  if (inAddMembers) {
    return (
      <AddMembersModal
        CallGetUsersHook={CallGetUsersHook}
        callAddMembers={async (newMemberIds: string[]) => {
          await updateCompanyMembers(
            {
              users: [
                ...company.users.map((u: string) => u.toString()),
                ...newMemberIds,
              ],
            },
            company._id,
          );
          await queryClient.invalidateQueries({
            queryKey: ['admin-companies', 'admin-projects', 'admin-users'],
          });
        }}
        isOpen={inAddMembers}
        onBack={() => setInAddMembers(false)}
        onClose={() => {
          setInAddMembers(false);
          onClose();
        }}
      />
    );
  }

  return (
    <Modal className="min-w-[700px]" isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header onClose={onClose}>
        {t('admin:organizations.modal.editTitle')}
      </Modal.Header>
      <div className="flex items-center px-6 pt-6">
        <div className="grid h-24 w-24 place-items-center rounded border border-shuttleGray-300 p-1">
          {imageDownloadRef?.downloadUrl ? (
            <img src={imageDownloadRef?.downloadUrl} alt={company.name} />
          ) : (
            <span className="text-shuttleGray-300">LOGO</span>
          )}
        </div>
        <div className="ml-4 w-2/3 overflow-hidden py-1">
          <div className="truncate text-2xl font-semibold">{company.name}</div>
          <div className="truncate text-lg text-shuttleGray-600">
            {company.description}
          </div>
        </div>
        <div className="ml-auto mt-auto truncate text-shuttleGray-600">
          <Trans
            ns="admin"
            i18nKey="organizations.modal.members"
            values={{ count: company.users.length }}
          />
        </div>
      </div>
      <Tabs defaultValue="information">
        <div className="px-6">
          <Tabs.List>
            <Tabs.Tab value="information">
              {t('admin:organizations.modal.information')}
            </Tabs.Tab>
            <Tabs.Tab value="members" disabled={isPersonalCompany}>
              {t('admin:organizations.modal.members')}
            </Tabs.Tab>
          </Tabs.List>
        </div>
        <Tabs.Content value="information">
          <CompanyInfoPage
            company={company}
            onClose={onClose}
            onSave={callUpdateCompany}
            isLoading={isLoadingCompanyInfoPage}
          />
        </Tabs.Content>
        <Tabs.Content value="members">
          <CompanyMembersPage
            company={company}
            onClickAddMembers={() => {
              setInAddMembers(true);
            }}
          />
        </Tabs.Content>
      </Tabs>
    </Modal>
  );
};
