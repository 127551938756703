import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';

import { updateCompanyMembers } from 'src/admin/apiCompanies';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Modal } from 'src/common/components/Modal';

import type { OwnerPopulatedCompany } from 'shared';

type Props = {
  company: OwnerPopulatedCompany;
  userId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const CompanyRemoveUserModal = ({
  company,
  userId,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation(['admin', 'common']);
  const queryClient = useQueryClient();

  async function handleRemoveUserFromCompany() {
    try {
      await updateCompanyMembers(
        {
          users: company.users
            .map((u: string) => u.toString())
            .filter((u: string) => u !== userId),
          admins: company.admins
            .map((a: string) => a.toString())
            .filter((a: string) => a !== userId),
        },
        company._id,
      );
      await queryClient.invalidateQueries({ queryKey: ['admin-users'] });
      toast.success(t('admin:removeUserSuccess'));
      onClose();
    } catch (e) {
      toast.error(t('admin:removeUserFailure'));
    }
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ConfirmationDialog
        title={t('admin:organizations.modal.removeUserTitle')}
        message={
          <Trans
            ns="admin"
            i18nKey="organizations.modal.removeUserMessage"
            values={{ companyName: company.name }}
            components={[
              <span className="font-extrabold text-shuttleGray-800" key="1" />,
            ]}
          />
        }
        onCancel={onClose}
        onConfirm={handleRemoveUserFromCompany}
        customConfirmText={t('common:button.delete')}
      />
    </Modal>
  );
};
