import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { KeyboardEvent, useState } from 'react';

import { cn } from 'src/common/utils';
import { TextInput } from '../inputs/TextInput/TextInput';
import { SelectNative } from '../inputs/SelectNative/SelectNative';
import { Button } from '../buttons/Button';

type Props = {
  maxPage: number;
  page: number;
  entriesPerPage?: number;
  onChangePage: (newPage: number) => void;
  onChangeEntriesPerPage?: (newEntriesPerPage: number) => void;
  className?: string;
};
export const Pagination = ({
  maxPage,
  entriesPerPage,
  page,
  onChangeEntriesPerPage,
  onChangePage,
  className,
}: Props) => {
  const { t } = useTranslation('common');
  const [localPage, setLocalPage] = useState(page);
  const [previousPage, setPreviousPage] = useState(page);

  if (page !== previousPage) {
    setPreviousPage(page);
    setLocalPage(page);
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      checkLocalPageAndFireOnPage();
    }
  };

  const checkLocalPageAndFireOnPage = () => {
    if (page !== localPage && localPage > 0 && localPage <= maxPage) {
      onChangePage(localPage);
    }
  };

  return (
    <div
      className={cn(
        {
          'grid grid-cols-[1fr_auto_1fr]': onChangeEntriesPerPage,
        },
        className,
      )}
    >
      {onChangeEntriesPerPage && (
        <div className="mr-4 flex items-center">
          <span className="whitespace-nowrap">
            {t('pagination.entriesPerPage')}
          </span>
          <SelectNative
            name="entriesPerPage"
            value={entriesPerPage}
            onChange={(event) => {
              onChangeEntriesPerPage(Number(event.target.value));
              onChangePage(1);
            }}
            className="ml-2 w-[88px]"
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={99999999}>{t('all')}</option>
          </SelectNative>
        </div>
      )}
      <div className="flex items-center justify-center">
        <Button
          variant="secondary"
          disabled={page <= 1}
          onClick={() => onChangePage(page - 1)}
          className="mr-4"
          aria-label="go to previous page"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <span>{t('page')}</span>
        <TextInput
          className="mx-1 w-[70px]"
          type="number"
          min={1}
          max={maxPage}
          value={localPage}
          onChange={(event) => setLocalPage(Number(event.target.value))}
          onBlur={checkLocalPageAndFireOnPage}
          onKeyDown={handleKeyDown}
        />
        <span className="whitespace-nowrap">
          {t('of')} {maxPage}
        </span>
        <Button
          variant="secondary"
          disabled={page >= maxPage}
          onClick={() => onChangePage(page + 1)}
          className="ml-4"
          aria-label="go to next page"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </div>
    </div>
  );
};
