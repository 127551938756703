import React, { ButtonHTMLAttributes } from 'react';
import { cva } from 'class-variance-authority';

import { cn } from 'src/common/utils';
import { Spinner } from '../Spinner';

import type { VariantProps } from 'class-variance-authority';

const actionIconVariants = cva(
  'inline-flex items-center justify-center rounded-sm focus:outline-none disabled:cursor-default text-shuttleGray-500 hover:bg-shuttleGray-50 focus:bg-shuttleGray-100 disabled:text-gray-400 disabled:hover:bg-transparent',
  {
    variants: {
      active: {
        true: 'bg-blue-600 text-white hover:bg-blue-700 focus:bg-blue-800 disabled:bg-blue-100',
      },
      size: {
        sm: 'h-8 w-8 text-xs',
        md: 'h-10 w-10 text-sm',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

type Props = VariantProps<typeof actionIconVariants> &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
  };

const ActionIcon = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      size = 'md',
      active = false,
      loading,
      className,
      children,
      disabled,
      onClick,
      ...rest
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        {...rest}
        disabled={disabled || loading}
        onClick={onClick}
        className={cn(actionIconVariants({ size, active }), className)}
      >
        <div
          className={cn('flex content-center items-center gap-2', {
            invisible: loading,
          })}
        >
          {children}
        </div>
        {loading && <Spinner containerClassName="absolute" />}
      </button>
    );
  },
);

ActionIcon.displayName = 'ActionIcon';

export { ActionIcon, actionIconVariants };
