import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { deleteUserImage, editUserImage, getUserImage } from '../api';
import { resizeImage } from 'src/common/utils';
import { s3 } from 'src/common/fetch';
import { logger } from 'src/logger';
import { ImageInput } from 'src/common/components/inputs/ImageInput';
import { useUser } from 'src/auth/user-context';

export const ProfileImage = () => {
  const user = useUser();
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ['user-profile', user._id, 'image'],
    queryFn: () => getUserImage(user._id),
  });
  const deleteMutation = useMutation({
    mutationFn: deleteUserImage,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['user-profile', user._id],
      });
      queryClient.resetQueries({
        queryKey: ['user-profile', user._id, 'image'],
      });
    },
    onError: logger.capture,
  });
  const uploadMutation = useMutation({
    mutationFn: async (file: File) => {
      const { blob, extension } = await resizeImage(file);
      const { url: uploadUrl } = await editUserImage(user._id, extension);
      return s3.upload(uploadUrl, blob);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-profile', user._id],
      });
    },
    onError: logger.capture,
  });

  return (
    <ImageInput
      src={query.data?.downloadUrl}
      onChange={uploadMutation.mutate}
      onDelete={() => deleteMutation.mutate(user._id)}
      isLoading={query.isPending}
      isDeleting={deleteMutation.isPending}
      isUploading={uploadMutation.isPending}
      variant="rounded"
    />
  );
};
