import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import { Card } from 'src/common/components/Card';
import { SidebarLayout } from 'src/common/components/SidebarLayout';
import toast from 'src/common/toast';
import { SettingsSidebar } from '../components/SettingsSidebar';
import { LicenseEvaluationType, Tag } from '../types/projects';
import { updateProject } from '../api/projectsApi';
import { validateLicense } from '../utils';
import { ModelSettingsTag } from '../components/ModelSettings/Tag';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { compareStringAsc } from 'src/common/utils';
import { Button } from 'src/common/components/buttons/Button';
import { useProject } from '../project-context';

export const TagView = () => {
  const project = useProject();
  const { t } = useTranslation(['project', 'common']);

  const [localTagList, setLocalTagList] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newTagName, setNewTagName] = useState<string | null>(null);

  useEffect(() => {
    if (project.tags) {
      const tagList: Tag[] = project.tags;
      setLocalTagList(tagList);
    }
  }, [project]);

  const onTagSave = (editedTag: Tag) => {
    if (localTagList.map((tag) => tag.name).includes(editedTag.name)) {
      toast.error(t('settings.tags.saveTagsAlreadyExistsError'));
      return;
    }
    const idx = localTagList.findIndex((tag) => tag._id === editedTag._id);
    const newLocalTagList = cloneDeep(localTagList);
    if (idx > -1) {
      newLocalTagList[idx].name = editedTag.name;
    } else {
      newLocalTagList.push(editedTag);
    }
    sendUpdateTags(newLocalTagList);
  };

  const handleAddTag = () => {
    const name = newTagName;
    if (name) {
      const newLocalTagList = cloneDeep(localTagList);
      newLocalTagList.push({ name });
      sendUpdateTags(newLocalTagList);
      setNewTagName(null);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !tagAlreadyExists()) {
      handleAddTag();
    }
  };

  const onTagRemove = (removedTag: Tag) => {
    const idx = localTagList.findIndex((tag) => tag._id === removedTag._id);
    const newLocalTagList = cloneDeep(localTagList);
    newLocalTagList.splice(idx, 1);
    sendUpdateTags(newLocalTagList);
  };

  const sendUpdateTags = async (tagsList: Tag[]) => {
    const newTagList = tagsList.sort((a, b) =>
      compareStringAsc(a.name, b.name),
    );

    try {
      setIsLoading(true);
      const update = await updateProject(project._id, {
        tags: [...newTagList],
      });

      if (update.tags) {
        setLocalTagList(update.tags);
      }
      toast.success(t('settings.tags.saveTagsSuccess'));
    } catch (err) {
      toast.error(t('settings.tags.saveTagsFailure'));
    } finally {
      setIsLoading(false);
    }
  };

  const tagAlreadyExists = () => {
    if (newTagName) {
      const idx = localTagList.findIndex(
        (listTag) => listTag.name === newTagName,
      );
      if (idx > -1) {
        return `"${newTagName}" ${t('settings.tags.alreadyExists')}`;
      }
    }
  };

  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;
  const disabled = isLoading || isLicenseExpired;

  return (
    <SidebarLayout
      header={
        <div className="flex items-end justify-between gap-3">
          <div>
            <h1 className="mb-1 text-2xl font-semibold">
              {t('settings.tags.title')}
            </h1>
            <p className="text-shuttleGray-600">
              {t('settings.tags.description')}
            </p>
          </div>
        </div>
      }
      sidebar={<SettingsSidebar />}
    >
      <Card className="min-h-full">
        {localTagList.length === 0 && (
          <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
            {t('settings.tags.noData')}
          </p>
        )}
        <div className="flex-wrap">
          <div className="w-full p-4">
            <div className="shrink overflow-y-auto">
              {localTagList.map((tag) => {
                return (
                  <ModelSettingsTag
                    tag={tag}
                    onTagSave={onTagSave}
                    onTagRemove={onTagRemove}
                    usedTagList={project.usedTags ?? []}
                    key={`${tag.name}_tag`}
                    disabled={disabled}
                  />
                );
              })}
              {!disabled && (
                <div className="flex items-center justify-between px-1 py-2">
                  <div className="mr-4 flex w-full items-center">
                    <div className="flex w-full flex-col ">
                      <TextInput
                        placeholder={t('settings.tags.newTagPlaceHolder')}
                        value={newTagName || ''}
                        onChange={(e) => setNewTagName(e.target.value)}
                        error={tagAlreadyExists()}
                        onKeyDown={onKeyDown}
                      />
                    </div>
                  </div>
                  <Button
                    className=""
                    variant="primary"
                    onClick={handleAddTag}
                    disabled={!newTagName || tagAlreadyExists() !== undefined}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </SidebarLayout>
  );
};
