import { ExportFormat } from './types';

export const EXPORT_FORMATS: ExportFormat[] = ['PDF', 'Excel', 'CSV'];

export const monthMapping = {
  0: 'common:util.monthMapping.jan',
  1: 'common:util.monthMapping.feb',
  2: 'common:util.monthMapping.mar',
  3: 'common:util.monthMapping.apr',
  4: 'common:util.monthMapping.may',
  5: 'common:util.monthMapping.jun',
  6: 'common:util.monthMapping.jul',
  7: 'common:util.monthMapping.aug',
  8: 'common:util.monthMapping.sep',
  9: 'common:util.monthMapping.oct',
  10: 'common:util.monthMapping.nov',
  11: 'common:util.monthMapping.dec',
} as const;
