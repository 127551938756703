import { NavLink } from 'react-router-dom';

import { cn } from 'src/common/utils';
import { Badge } from '../Badge';
import { Container } from '../Container';

const TabNavBar = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex h-12 justify-start">{children}</div>;
};

type LinkProps = {
  to: string;
  label: string;
  disabled?: boolean;
  badge?: string;
};

export const TabNavBarLink = ({ label, to, disabled, badge }: LinkProps) => {
  if (disabled) {
    return null;
  }

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cn(
          'flex h-full items-center break-words border-b-2 px-4 font-medium tracking-wider transition-colors hover:text-shuttleGray-700 focus:text-blue-800 focus:outline-none',
          isActive
            ? 'border-blue-800 text-blue-800'
            : 'border-transparent text-shuttleGray-500',
        )
      }
    >
      {label}
      {badge ? <Badge className="ml-1">{badge}</Badge> : null}
    </NavLink>
  );
};

export const NavBarContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="bg-white shadow">
      <Container>{children}</Container>
    </div>
  );
};

TabNavBar.Container = NavBarContainer;
TabNavBar.Link = TabNavBarLink;

export { TabNavBar };
