import { useTranslation } from 'react-i18next';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LicenseBasicIllu from 'src/assets/projectLicense/illu_basic_license.svg';
import { Modal } from '../Modal/Modal';
import { Button } from '../buttons/Button';

type UpgradeModalProps = {
  onClose: () => void;
  upgradeButton: () => void;
  isOpen: boolean;
};

export const UpgradeModal = ({
  onClose,
  upgradeButton,
  isOpen,
}: UpgradeModalProps) => {
  const { t } = useTranslation('common');
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="w-[680px]">
      <Modal.Header onClose={onClose}>
        {t('upgradeConfirmationModal.title')}
      </Modal.Header>
      <Modal.Body>
        <img
          className="mx-auto mb-8"
          src={LicenseBasicIllu}
          alt="Basic illustation"
        />
        <p className=" text-center text-shuttleGray-600">
          {t('upgradeConfirmationModal.text')}
        </p>
        <div className="mt-4  inline-flex bg-blue-50 p-2 text-blue-700">
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="mr-2 mt-1"
            fixedWidth
          />
          <p>{t('upgradeConfirmationModal.information')}</p>
        </div>
        <div className="mt-8 flex justify-center">
          <Button
            variant="primary"
            onClick={() => {
              upgradeButton();
            }}
          >
            {t('upgradeConfirmationModal.title')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
