import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';

import { updateWeatherProvider } from 'src/Conrep/settings/api';
import toast from 'src/common/toast';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Radio } from 'src/common/components/inputs/Radio';
import { Button } from 'src/common/components/buttons/Button';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { SidebarLayout } from 'src/common/components/SidebarLayout';
import { LicenseEvaluationType, WeatherProviders } from '../types/projects';
import { SettingsSidebar } from '../components/SettingsSidebar';
import { validateLicense } from '../utils';
import { Card } from 'src/common/components/Card';
import { arraysEqual } from 'src/common/utils';
import { useProject } from '../project-context';

export const WeatherView = () => {
  const project = useProject();
  const { t } = useTranslation(['project', 'common']);
  const [localWeatherProvider, setLocalWeatherProvider] =
    useState<WeatherProviders>(WeatherProviders.NONE);
  const [entries, setEntries] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const setLocalConfig = useCallback(() => {
    if (project.weatherSettings && project.weatherSettings.weatherProvider) {
      setLocalWeatherProvider(project.weatherSettings.weatherProvider);
    }
    if (
      project.weatherSettings &&
      project.weatherSettings.weatherQuickEntries
    ) {
      setEntries(project.weatherSettings.weatherQuickEntries);
    }
  }, [project.weatherSettings]);

  useEffect(() => {
    setLocalConfig();
  }, [project.weatherSettings, setLocalConfig]);

  const handleWeatherProviderChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newWeatherProvider =
      WeatherProviders[event.target.value as keyof typeof WeatherProviders];
    setLocalWeatherProvider(newWeatherProvider);
    if (newWeatherProvider === WeatherProviders.NONE) {
      setEntries([]);
    } else {
      if (
        project.weatherSettings?.weatherProvider === newWeatherProvider &&
        project.weatherSettings?.weatherQuickEntries &&
        project.weatherSettings.weatherQuickEntries.length > 0
      ) {
        setEntries(project.weatherSettings.weatherQuickEntries);
      } else {
        setEntries(['07:00', '12:00', '17:00']);
      }
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await updateWeatherProvider(project._id, localWeatherProvider, entries);
      queryClient.invalidateQueries({
        queryKey: ['projects', project._id],
      });
      toast.success(t('settings.weather.updateSuccess'));
    } catch (err) {
      toast.error(t('settings.weather.updateError'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddEntry = () => {
    const lastTime = [...entries]
      .sort((entryA, entryB) => entryA.localeCompare(entryB))
      ?.pop()
      ?.split(':') || ['11', '30'];
    const value = dayjs
      .duration({ hours: +lastTime[0], minutes: +lastTime[1] })
      .add(30, 'minutes')
      .format('LT');
    setEntries([...entries, value]);
  };

  const handleUpdateEntry = (event: any, index: number) => {
    const newEntries = [...entries];
    newEntries[index] = event.target.value;
    setEntries(newEntries);
  };

  const handleRemoveEntry = (index: number) => {
    setEntries(entries.filter((entry, filterIndex) => index !== filterIndex));
  };

  const compareEntries = [...entries];
  const duplicateEntries = entries.filter(
    (item, index) => compareEntries.indexOf(item) !== index,
  );
  const hasDuplicateEntries = duplicateEntries.length > 0;

  const weatherEqual = isEqual(
    localWeatherProvider,
    project.weatherSettings?.weatherProvider,
  );

  const weatherEntriesEqual =
    (!project.weatherSettings?.weatherQuickEntries && entries.length <= 0) ||
    (project.weatherSettings?.weatherQuickEntries &&
      arraysEqual<string>(
        entries,
        project.weatherSettings.weatherQuickEntries,
      ));

  const weatherSettingsEqual = weatherEqual && weatherEntriesEqual;

  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;

  return (
    <SidebarLayout
      header={
        <div>
          <h3 className="mb-1 text-2xl font-semibold">
            {t('settings.weather.title')}
          </h3>
          <p className="text-shuttleGray-600">
            {t('settings.weather.description')}
          </p>
        </div>
      }
      sidebar={<SettingsSidebar />}
    >
      <Card className="flex flex-col gap-8 p-4">
        <div className="flex flex-col gap-4">
          <h4 className="text-xl font-semibold">
            {t('settings.weather.provider')}
          </h4>
          <div className="flex gap-4">
            <Radio
              name="weather-provider"
              value={WeatherProviders.NONE}
              onChange={handleWeatherProviderChanged}
              checked={localWeatherProvider === WeatherProviders.NONE}
              label={t('settings.weather.noProvider')}
              disabled={isLicenseExpired}
            />
            <Radio
              name="weather-provider"
              value={WeatherProviders.OPEN_WEATHER}
              onChange={handleWeatherProviderChanged}
              checked={localWeatherProvider === WeatherProviders.OPEN_WEATHER}
              label={t('settings.weather.openWeather')}
              disabled={isLicenseExpired}
            />
            <Radio
              name="weather-provider"
              value={WeatherProviders.GERMAN_WEATHER}
              onChange={handleWeatherProviderChanged}
              checked={localWeatherProvider === WeatherProviders.GERMAN_WEATHER}
              label={t('settings.weather.germanWeatherService')}
              disabled={isLicenseExpired}
            />
          </div>
        </div>
        {localWeatherProvider !== WeatherProviders.NONE && (
          <div>
            <hr className="my-6" />
            <h4 className="mb-4 text-lg font-semibold">
              {t('settings.weather.quickEntries')}
            </h4>
            {entries.map((entry, index) => (
              <div className="mb-2" key={index}>
                <EntrySuggestion
                  entry={entry}
                  duplicate={
                    hasDuplicateEntries &&
                    duplicateEntries.some(
                      (duplicateEntry) => duplicateEntry === entry,
                    )
                  }
                  index={index}
                  onUpdateEntry={handleUpdateEntry}
                  onRemoveEntry={handleRemoveEntry}
                  disabled={isLicenseExpired}
                />
              </div>
            ))}
            <Button
              className="mt-2"
              variant="secondary"
              size="sm"
              onClick={handleAddEntry}
              disabled={isLoading || hasDuplicateEntries || isLicenseExpired}
            >
              <FontAwesomeIcon icon={faPlus} />
              {t('common:button.add')}
            </Button>
            {hasDuplicateEntries && (
              <InlineMessage variant="error" className="ml-2">
                {t('settings.weather.quickEntriesAlert')}
              </InlineMessage>
            )}
          </div>
        )}
        <Button
          className="ml-auto"
          onClick={() => handleSave()}
          disabled={
            weatherSettingsEqual ||
            isLoading ||
            hasDuplicateEntries ||
            isLicenseExpired
          }
          loading={isLoading}
        >
          {t('common:button.save')}
        </Button>
      </Card>
    </SidebarLayout>
  );
};

interface Props {
  entry: string;
  duplicate: boolean;
  index: number;
  disabled: boolean;
  onUpdateEntry: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  onRemoveEntry: (index: number) => void;
}

const EntrySuggestion = ({
  entry,
  duplicate,
  index,
  disabled,
  onUpdateEntry,
  onRemoveEntry,
}: Props) => (
  <div className="flex items-center gap-2" key={index}>
    <TextInput
      className="w-60"
      type="time"
      value={entry}
      error={duplicate}
      onChange={(event) => onUpdateEntry(event, index)}
      disabled={disabled}
    />
    <ActionIcon disabled={disabled} onClick={() => onRemoveEntry(index)}>
      <FontAwesomeIcon icon={faTimes} />
    </ActionIcon>
  </div>
);
