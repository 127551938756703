import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  faArrowRight,
  faChevronDown,
  faChevronRight,
  faEye,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { ForgeProgress, ModelFile, SitelifeFile } from 'src/file/types';
import {
  getActiveModelFileVersion,
  isModelFile,
  toPrettyDate,
} from 'src/file/util';
import { FileViews } from './FileViews';
import { TranslationStatus } from './TranslationStatus';
import { TypeBadge } from './TypeBadge';
import { deleteFile } from 'src/file/api';
import { Modal, useModal } from 'src/common/components/Modal';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { Button } from 'src/common/components/buttons/Button';
import { logger } from 'src/logger';
import { useProject } from 'src/project/project-context';

export const FileRow = ({
  file,
  disabled,
}: {
  file: SitelifeFile;
  disabled: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isModel = isModelFile(file);
  const hasViews = isModel && getHasViews(file);
  const views = hasViews ? getActiveModelFileVersion(file)?.views : undefined;
  const activeFileVersion = isModel ? getActiveModelFileVersion(file) : null;
  const canBeViewed = Boolean(
    activeFileVersion?.forgeProgress === ForgeProgress.DONE,
  );

  if (!file._id || !file.project) {
    logger.capture('file._id or file.project is undefined');
    return null;
  }

  return (
    <>
      <tr>
        <td className="table-td">
          <div className="flex items-center">
            <div className="w-8">
              {views?.length && (
                <ActionIcon onClick={() => setIsExpanded((v) => !v)}>
                  <FontAwesomeIcon
                    icon={isExpanded ? faChevronDown : faChevronRight}
                  />
                </ActionIcon>
              )}
            </div>
            {canBeViewed ? (
              <Link to={`/p/${file.project}/dashboard/v?file=${file._id}`}>
                <div className="group flex items-center p-1 hover:bg-shuttleGray-50">
                  <span className="w-8">
                    <TypeBadge fileType={file.type} />
                  </span>
                  <span className="text-td-text">
                    {file.name + file.extension}
                  </span>
                  <span className="mx-2 text-shuttleGray-700 opacity-0 group-hover:opacity-100">
                    <FontAwesomeIcon icon={faEye} />
                  </span>
                </div>
              </Link>
            ) : (
              <div className="flex items-center p-1">
                <span className="w-8">
                  <TypeBadge fileType={file.type} />
                </span>
                <span className="text-td-text">
                  {file.name + file.extension}
                </span>
              </div>
            )}
          </div>
        </td>
        <td className="table-td">{toPrettyDate(file.createdAt)}</td>
        <td className="table-td">
          {isModel && <TranslationStatus file={file} />}
        </td>
        <td className="table-td">
          <div className="flex justify-end">
            <DeleteButton disabled={disabled} fileId={file._id} />
          </div>
        </td>
      </tr>
      {isExpanded && hasViews && <FileViews file={file} />}
    </>
  );
};

function DeleteButton({
  disabled,
  fileId,
}: {
  fileId: string;
  disabled?: boolean;
}) {
  const deleteModal = useModal();
  const queryClient = useQueryClient();
  const project = useProject();
  const { t } = useTranslation(['file', 'common']);

  const handleConfirmDelete = async () => {
    try {
      await deleteFile(project._id, fileId);
      queryClient.invalidateQueries({
        queryKey: ['projects', project._id, 'files'],
      });
      deleteModal.close();
      toast.success(t('file:item.actions.deleteSuccess'));
    } catch (e) {
      toast.error(t('file:item.actions.deleteError'));
      logger.capture(e);
    }
  };

  return (
    <>
      <ActionIcon onClick={deleteModal.open} disabled={disabled}>
        <FontAwesomeIcon icon={faTrash} />
      </ActionIcon>
      <DeleteConfirmModal
        isOpen={deleteModal.isOpen}
        onConfirm={handleConfirmDelete}
        onClose={deleteModal.close}
      />
    </>
  );
}

function getHasViews(file: ModelFile) {
  const activeVersion = getActiveModelFileVersion(file);
  if (activeVersion?.views && activeVersion.views.length > 0) {
    return true;
  }
  return false;
}

function DeleteConfirmModal({
  isOpen,
  onConfirm,
  onClose,
}: {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}) {
  const { t } = useTranslation(['file', 'common']);

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Delete-File-Confirmation"
      className="flex w-[432px] flex-col"
    >
      <Modal.Header onClose={onClose}>
        {t('file:item.actions.deleteFile')}
      </Modal.Header>
      <span className="mt-2 px-6 font-normal text-gray-600">
        {t('file:item.actions.deleteConfirmation')}
      </span>
      <Modal.Body>
        <InlineMessage variant="warning" className="mb-4">
          {t('file:item.actions.warningConfirmation')}
        </InlineMessage>
        <ul className="space-y-4">
          <li>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="mr-2 text-teal-600"
            />
            {t('file:item.actions.firstWarningText')}
          </li>
          <li>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="mr-2 text-teal-600"
            />
            {t('file:item.actions.secondWarningText')}
          </li>
          <li>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="mr-2 text-teal-600"
            />
            {t('file:item.actions.thirdWarningText')}
          </li>
        </ul>
        <div className="mt-8 flex justify-end space-x-2">
          <Button variant="tertiary" onClick={onClose}>
            {t('common:button.abort')}
          </Button>
          <Button variant="danger" onClick={onConfirm}>
            {t('common:button.delete')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
