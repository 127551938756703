import * as Sentry from '@sentry/react';

import type { SheetSet } from 'src/sheetset/types';

import {
  SitelifeFile,
  FileVersionViewDictionary,
  ModelFile,
} from 'src/file/types';
import {
  getProperties as getForgeProperties,
  getParamValues,
  getCategoryValues,
} from 'src/Forge/api';
import {
  License,
  LicenseEvaluationType,
  LicenseType,
  GetProjectResponse,
} from './types/projects';
import { TechCrew } from './types/techCrews';
import { s3 } from 'src/common/fetch';
import { isModelFile } from 'src/file/util';

export async function loadLocalImage(downloadUrl: string): Promise<string> {
  const imageBlob = await s3.get(downloadUrl);
  return URL.createObjectURL(imageBlob);
}

export function is3dProject(project: GetProjectResponse): boolean {
  return (
    project.conrepSettings?.generalSettings
      .modelBasedPerformanceDocumentation || false
  );
}

export function getViewDict(files?: SitelifeFile[]) {
  if (!files) return null; // returns null as a leftover from refactoring
  const modelFiles = files.filter((file): file is ModelFile =>
    isModelFile(file),
  );

  const dict: FileVersionViewDictionary = {};
  modelFiles.forEach((file) => {
    file.versions.forEach((version) => {
      version.views.forEach((view) => {
        dict[`${view.file}_${view.viewableId}`] = view;
      });
    });
  });
  return dict;
}

export function validateLicense(license: License | undefined) {
  // assert the license object itself is valid
  if (!license || !license.type) {
    return LicenseEvaluationType.INVALID;
  }
  if (!Object.keys(LicenseType).includes(license.type)) {
    return LicenseEvaluationType.INVALID;
  }

  // the FREE license does not expire
  if (license.type === LicenseType.FREE) {
    return LicenseEvaluationType.VALID;
  }

  // check the expiration date for BASIC and DEMO licenses
  if (!license.expirationDate) {
    return LicenseEvaluationType.INVALID;
  }
  if (new Date(license.expirationDate).getTime() < new Date().getTime()) {
    return LicenseEvaluationType.EXPIRED;
  }

  // all good
  return LicenseEvaluationType.VALID;
}

export async function getProperties(
  sheetSets: SheetSet[],
  viewDict: FileVersionViewDictionary,
  viewId: string,
  projectId: string,
) {
  const view = sheetSets
    .map((sheetSet) => sheetSet.views)
    .flat()
    .find((fileVersionView) => fileVersionView._id === viewId);

  if (!view) {
    throw new Error();
  }

  const defaultView = viewDict[`${view.file}_${view.viewableId}`];

  const properties = await getForgeProperties(
    projectId,
    defaultView.file,
    defaultView.fileVersion,
    defaultView._id,
  );
  return properties.map((property) => ({
    value: property,
    label: property,
  }));
}

export async function getCategories(
  techCrew: TechCrew,
  viewIds: string[],
  viewDict: FileVersionViewDictionary,
): Promise<string[]> {
  if (!techCrew.categoryParameter || techCrew.categoryParameter === '') {
    return (
      await Promise.all(
        viewIds.map(async (viewId) => {
          const view = Object.values(viewDict).find((dictItem) => {
            return dictItem._id === viewId;
          });
          if (view) {
            try {
              return getCategoryValues(
                techCrew.project,
                view.file,
                view.fileVersion,
                view._id,
              );
            } catch (error) {
              Sentry.captureException(error);
            }
          }
          return [];
        }),
      )
    ).flat();
  } else {
    return (
      await Promise.all(
        viewIds.map(async (viewId) => {
          const view = Object.values(viewDict).find((dictItem) => {
            return dictItem._id === viewId;
          });
          if (view) {
            try {
              return getParamValues(
                techCrew.project,
                view.file,
                view.fileVersion,
                view._id,
                techCrew.categoryParameter || '',
              );
            } catch (error) {
              Sentry.captureException(error);
            }
          }
          return [];
        }),
      )
    ).flat();
  }
}

export function splicePosition(positionString: string) {
  const index = positionString.indexOf('.');
  if (index >= 0) {
    const prefix = positionString.substring(0, index);
    const match = prefix.match(/\d+/g);
    if (!match) {
      return positionString.substring(index + 1);
    }
  }
  return positionString;
}
