import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/common/components/buttons/Button';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { PasswordInput } from 'src/common/components/inputs/PasswordInput/PasswordInput';
import { UpdateUserRequest } from 'src/user/types';
import { PASSWORD_REGEX } from 'src/user/constants';
import { PasswordStrengthInput } from 'src/common/components/inputs/PasswordStrengthInput/PasswordStrengthInput';

type Props = {
  onSubmit: (data: UpdateUserRequest) => void;
  isLoading: boolean;
  isPasswordUpdateError: boolean;
};

export const UpdatePasswordForm = ({
  onSubmit,
  isLoading,
  isPasswordUpdateError,
}: Props) => {
  const { t } = useTranslation(['auth', 'user']);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

  const handleSubmit = () => {
    if (!canSubmit) {
      return;
    }
    const payload: UpdateUserRequest = {
      oldPassword: password,
      password: newPassword,
      newPasswordConfirmed: newPasswordConfirmation,
    };
    onSubmit(payload);
  };

  const isSame = newPassword === newPasswordConfirmation;
  const isPasswordValid = PASSWORD_REGEX.test(newPassword);

  const canSubmit = isSame && isPasswordValid && password !== '';

  return (
    <div className="mt-2 flex gap-4">
      <div className="flex-1">
        <PasswordInput
          label={t('user:currentPassword')}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          name="currentPassword"
          className="mb-4 w-full"
          autoComplete="current-password"
          id="currentPassword"
          required
          error={isPasswordUpdateError && t('passwordWrong')}
        />

        <PasswordStrengthInput
          label={t('user:newPassword')}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          name="newPassword"
          className="mb-4 w-full"
          id="newPassword"
          autoComplete="new-password"
          required
          description={t('resetPassword.passwordInputDescription')}
          error={
            newPassword !== '' && !isPasswordValid
              ? t('resetPassword.passwordError')
              : false
          }
        />

        <PasswordInput
          label={t('user:confirmNewPassword')}
          value={newPasswordConfirmation}
          onChange={(e) => setNewPasswordConfirmation(e.target.value)}
          name="newPasswordConfirmation"
          className="mb-4 w-full"
          id="newPasswordConfirmation"
          autoComplete="new-password"
          required
          error={
            newPasswordConfirmation !== '' && !isSame
              ? t('resetPassword.passwordConfirmError')
              : false
          }
        />

        <Button
          type="button"
          className="mt-4"
          onClick={handleSubmit}
          disabled={!canSubmit}
          loading={isLoading}
        >
          {t('resetPassword.savePassword')}
        </Button>
      </div>
      <div className="mt-5 flex-1">
        <InlineMessage variant="warning">
          {t('user:passwordAlertText')}
        </InlineMessage>
      </div>
    </div>
  );
};
