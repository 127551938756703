import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { addUsersToProject } from 'src/admin/apiProjects';
import {
  useAdminProjectImageQuery,
  useAdminUsersQuery,
} from 'src/admin/queries';
import { Modal } from 'src/common/components/Modal';
import { Tabs } from 'src/common/components/Tabs';
import { GetAdminProjectResponse } from 'src/project/types/projects';
import { AddMembersModal } from 'src/common/components/AddMembersModal';
import { ProjectChangeCompanyModal } from './ProjectChangeCompanyModal';
import { ProjectEditModalInformation } from './ProjectEditModalInformation';
import { ProjectEditModalUsers } from './ProjectEditModalUsers';

type Props = {
  project: GetAdminProjectResponse;
  isOpen: boolean;
  onClose: () => void;
};

export const ProjectEditModal = ({ project, isOpen, onClose }: Props) => {
  const { t } = useTranslation(['admin', 'common']);
  const queryClient = useQueryClient();

  const { data: imageDownloadRef } = useAdminProjectImageQuery(project._id);

  const [inChangeCompany, setInChangeCompany] = useState(false);
  const [inAddMembers, setInAddMembers] = useState(false);

  /**
   * @deprecated this violates react state design principles
   * TODO: refactor this, so children dont write state into the parent: lift state up
   */
  function CallGetUsersHook(
    page: number,
    entriesPerPage: number,
    searchBy?: string,
    sortBy?: string,
    sortOrder?: 'asc' | 'desc',
  ) {
    const { isPending, data: paginatedUsers } = useAdminUsersQuery(
      page,
      entriesPerPage,
      searchBy,
      sortBy,
      sortOrder,
      {
        filterBy: 'notInProject',
        filter: project._id,
      },
    );
    return { isPending, paginatedUsers };
  }

  if (inChangeCompany) {
    return (
      <ProjectChangeCompanyModal
        project={project}
        isOpen={inChangeCompany}
        onBack={() => setInChangeCompany(false)}
        onClose={() => {
          setInChangeCompany(false);
          onClose();
        }}
      />
    );
  }

  if (inAddMembers) {
    return (
      <AddMembersModal
        CallGetUsersHook={CallGetUsersHook}
        callAddMembers={async (newMemberIds: string[]) => {
          await addUsersToProject(project._id, newMemberIds);
          await queryClient.invalidateQueries({
            queryKey: ['admin-companies', 'admin-projects', 'admin-users'],
          });
        }}
        isOpen={inAddMembers}
        onBack={() => setInAddMembers(false)}
        onClose={() => {
          setInAddMembers(false);
          onClose();
        }}
      />
    );
  }

  return (
    <Modal className="min-w-[700px]" isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header onClose={onClose}>
        {t('admin:projects.modal.editTitle')}
      </Modal.Header>
      <div className="flex items-center px-6 pt-6">
        <div className="grid h-24 w-24 place-content-center overflow-hidden rounded border border-shuttleGray-300 p-1">
          {imageDownloadRef?.downloadUrl ? (
            <img src={imageDownloadRef?.downloadUrl} alt={project.name} />
          ) : (
            <span className="text-shuttleGray-300">LOGO</span>
          )}
        </div>
        <div className="ml-4 w-2/3 overflow-hidden py-1">
          <div className="truncate text-2xl font-semibold">{project.name}</div>
          <div className="truncate text-lg text-shuttleGray-600">
            {project.description}
          </div>
        </div>
      </div>
      <Tabs defaultValue="">
        <div className="px-6">
          <Tabs.List>
            <Tabs.Tab value="information">
              {t('admin:projects.modal.information.tab')}
            </Tabs.Tab>
            <Tabs.Tab value="users">
              {t('admin:projects.modal.users.tab')}
            </Tabs.Tab>
            <Tabs.Tab value="documentation">
              {t('admin:projects.modal.documentation.tab')}
            </Tabs.Tab>
            <Tabs.Tab value="analysis">
              {t('admin:projects.modal.analysis.tab')}
            </Tabs.Tab>
          </Tabs.List>
        </div>
        <Tabs.Content value="information">
          <ProjectEditModalInformation
            project={project}
            onClickChangeCompany={() => setInChangeCompany(true)}
            onClose={onClose}
          />
        </Tabs.Content>
        <Tabs.Content value="users">
          <ProjectEditModalUsers
            project={project}
            onClickAddMembers={() => setInAddMembers(true)}
          />
        </Tabs.Content>
        <Tabs.Content value="documentation">IN DESIGN</Tabs.Content>
        <Tabs.Content value="analysis">IN DESIGN</Tabs.Content>
      </Tabs>
    </Modal>
  );
};
