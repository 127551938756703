import { UserCreatePayload, UserUpdatePayload } from 'shared';

import type { PaginatedResponse } from 'src/common/types';

import { api } from 'src/common/fetch';

import type {
  OwnerCompanyPopulatedProject,
  CompanyPopulatedUser,
  Company,
} from 'shared';

export async function getUsers(
  page: number,
  entriesPerPage: number,
  searchBy?: string,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
  filter?: {
    filterBy: string;
    filter?: string;
  },
) {
  const params = {
    page: page.toString(),
    entriesPerPage: entriesPerPage.toString(),
    ...(searchBy && { searchBy }),
    ...(sortBy && { sortBy }),
    ...(sortOrder && { sortOrder: sortOrder?.toString() }),
    ...filter,
  };
  const searchParams = new URLSearchParams(params);

  return api.get<PaginatedResponse<CompanyPopulatedUser>>(
    `/api-admin/users?${searchParams.toString()}`,
  );
}

export async function createUser(user: UserCreatePayload) {
  return api.post<CompanyPopulatedUser>('/api-admin/users/', user);
}

export async function updateUser(user: UserUpdatePayload, userId: string) {
  return api.patch<CompanyPopulatedUser>(`/api-admin/users/${userId}`, user);
}

export async function deleteUser(userId: string) {
  return api.delete(`/api-admin/users/${userId}`);
}

export async function impersonateUser(userId: string) {
  return api.post<CompanyPopulatedUser>(`/api-admin/users/${userId}/ipu`);
}

export async function getUserCompanies(userId: string) {
  return api.get<Company[]>(`/api-admin/users/${userId}/companies`);
}

export async function getUserProjects(userId: string) {
  return api.get<OwnerCompanyPopulatedProject[]>(
    `/api-admin/users/${userId}/projects`,
  );
}

export async function getUserDemoProjects(userId: string) {
  return api.get<OwnerCompanyPopulatedProject[]>(
    `/api-admin/users/${userId}/projects/demo`,
  );
}

export async function generateDemoProject(userId: string, projectId: string) {
  return api.post(`/api-admin/users/${userId}/projects/demo/${projectId}`);
}
