import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Input } from '../Input/Input';
import { InputWrapper } from '../Wrapper/InputWrapper';

export interface PasswordInputProps extends ComponentPropsWithoutRef<'input'> {
  id?: string;
  label?: ReactNode;
  description?: ReactNode;
  error?: string | boolean;
  required?: boolean;
  wrapperProps?: { [key: string]: any };
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  function PasswordInput(props: PasswordInputProps, ref) {
    const {
      className,
      id,
      label,
      error,
      required,
      disabled,
      style,
      placeholder,
      description,
      wrapperProps,
      ...rest
    } = props;

    const [reveal, toggle] = useState(false);

    return (
      <div>
        <InputWrapper
          id={id}
          required={required}
          aria-disabled={disabled}
          label={label}
          error={error}
          description={description}
          className={className}
          style={style}
          {...wrapperProps}
        >
          <div className="relative flex-auto">
            <Input
              {...rest}
              required={required}
              disabled={disabled}
              invalid={!!error}
              ref={ref}
              id={id}
              type={reveal ? 'text' : 'password'}
              placeholder={placeholder}
              error={error}
              className="pr-10"
            />
            <ActionIcon
              aria-hidden
              onClick={() => toggle(!reveal)}
              type="button"
              className="absolute inset-y-1 right-1 text-shuttleGray-500"
            >
              {reveal ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </ActionIcon>
          </div>
        </InputWrapper>
      </div>
    );
  },
);
