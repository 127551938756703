import { useTranslation } from 'react-i18next';
import * as amplitude from '@amplitude/analytics-browser';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import toast from 'src/common/toast';
import { Button } from 'src/common/components/buttons/Button';
import { ForgeProgress, ModelFile } from 'src/file/types';
import { triggerForgeTranslation } from 'src/file/api';
import { getActiveModelFileVersion } from 'src/file/util';
import { useProject } from 'src/project/project-context';
import { logger } from 'src/logger';

export const TranslateButton = ({ file }: { file: ModelFile }) => {
  const { t } = useTranslation(['file']);
  const { _id: projectId } = useProject();
  const version = getActiveModelFileVersion(file);
  const queryClient = useQueryClient();
  const [isTranslating, setIsTranslating] = useState(false);

  const handleTranslate = async () => {
    try {
      if (!file._id || !version?._id) {
        throw new Error('File must have an id and an active version');
      }
      setIsTranslating(true);
      await triggerForgeTranslation(projectId, file._id, version._id);
      amplitude.track('plan: translation', { projectId: projectId });
      queryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'files'],
      });
      toast.success(t('file:item.prepareButton.triggerSuccess'));
    } catch (e) {
      logger.capture(e);
      toast.error(t('file:item.prepareButton.triggerFailure'));
      setIsTranslating(false);
    }
  };

  const notTranslatable =
    version?.forgeProgress === ForgeProgress.FORGE_PROGRESS ||
    version?.forgeProgress === ForgeProgress.DONE;

  return (
    <Button
      size="sm"
      variant="secondary"
      onClick={handleTranslate}
      disabled={notTranslatable}
      loading={isTranslating}
    >
      {t('file:item.prepareButton.prepare')}
    </Button>
  );
};
