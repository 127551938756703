import {
  faChevronDown,
  faChevronRight,
  faEmptySet,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dataset, Workspace } from 'shared';

import { deleteWorkspaceById } from 'src/admin/apiAnalysis';
import { useAdminDatasetsQuery } from 'src/admin/queries';
import { Modal } from 'src/common/components/Modal/Modal';
import { Spinner } from 'src/common/components/Spinner/Spinner';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Button } from 'src/common/components/buttons/Button';
import toast from 'src/common/toast';
import { DatasetRow } from './DatasetRow';
import { Tooltip } from 'src/common/components/Tooltip';

type Props = {
  workspace: Workspace;
};

export const WorkspaceRow = ({ workspace }: Props) => {
  const { t } = useTranslation(['admin', 'common']);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const { data: datasets, isPending } = useAdminDatasetsQuery(workspace.id);
  const notProdEnvironment = import.meta.env.VITE_ENVIRONMENT !== 'production';

  const queryClient = useQueryClient();

  const handleWorkspaceDelete = async () => {
    try {
      setIsDeleting(true);
      setIsExpanded(false);
      await deleteWorkspaceById(workspace.id);
      await queryClient.invalidateQueries({
        queryKey: ['admin-analysis'],
      });
      setIsDeleting(false);
      setIsDeleteConfirmationOpen(false);
      toast.success(t('admin:analysis.deleteWorkspace.success'));
    } catch {
      toast.error(t('admin:analysis.deleteWorkspace.failure'));
    }
  };

  return (
    <>
      <tr>
        <td className="table-td table-td-truncate min-w-[200px]">
          <div className="flex items-center">
            <div className="ml-4 flex-col overflow-hidden">
              <div className="inline-flex items-center">
                {isPending && <Spinner />}
                {!isPending && datasets && datasets.length > 0 ? (
                  <ActionIcon onClick={() => setIsExpanded(!isExpanded)}>
                    <FontAwesomeIcon
                      icon={isExpanded ? faChevronDown : faChevronRight}
                    />
                  </ActionIcon>
                ) : (
                  <span>
                    {!isPending && (
                      <ActionIcon onClick={() => {}} disabled>
                        <FontAwesomeIcon icon={faEmptySet} />
                      </ActionIcon>
                    )}
                  </span>
                )}
                <div className="ml-4 truncate text-base font-semibold">
                  {workspace.name}
                </div>
              </div>
              {isExpanded && (
                <div>
                  {datasets?.map((dataset: Dataset) => (
                    <DatasetRow
                      workspace={workspace}
                      dataset={dataset}
                      key={dataset.id}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </td>
        <td className="table-td flex">
          {/* TODO: Uncomment this later when reformating is ready */}
          {/* <ActionIcon className="ml-auto">
          <FontAwesomeIcon icon={faEdit} />
        </ActionIcon> */}
          <Tooltip
            content={
              notProdEnvironment
                ? t('admin:analysis.deleteWorkspace.onlyInProd')
                : null
            }
          >
            <ActionIcon
              className="ml-auto"
              onClick={() => setIsDeleteConfirmationOpen(true)}
              disabled={isDeleting || notProdEnvironment}
            >
              <FontAwesomeIcon icon={faTrash} />
            </ActionIcon>
          </Tooltip>
        </td>
      </tr>
      <Modal
        isOpen={isDeleteConfirmationOpen}
        onRequestClose={() => setIsDeleteConfirmationOpen(false)}
        className="w-[432px]"
      >
        {isDeleting ? (
          <Spinner containerClassName="mx-[160px] my-[160px] text-center" />
        ) : (
          <>
            <Modal.Header onClose={() => setIsDeleteConfirmationOpen(false)}>
              {t('admin:analysis.deleteWorkspace.title')}
            </Modal.Header>
            <Modal.Body>
              <p className="mb-3 text-shuttleGray-600">
                {t('admin:analysis.deleteWorkspace.message')}
              </p>
              <div className="flex justify-end space-x-2">
                <Button
                  variant="tertiary"
                  onClick={() => setIsDeleteConfirmationOpen(false)}
                >
                  {t('common:button.abort')}
                </Button>
                <Button variant="danger" onClick={handleWorkspaceDelete}>
                  {t('common:button.delete')}
                </Button>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};
