import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'src/app/store';

import { FileVersionViewReference } from 'src/file/types';

import type {
  ForgeViewerDbIdToExternalIdMapping,
  ForgeViewerExternalIdToDbIdMapping,
  ForgeViewerExternalIdToFileIdMapping,
  ForgeViewerNaming,
  RestoreViewData,
} from './types';

type ForgeViewerState = {
  ready: boolean;
  filtering: boolean;
  viewReference: FileVersionViewReference | null;
  restoreViewData: RestoreViewData | null;
  markupActive: boolean;
  selection: number[] | null;
  modelElementSelection: number[] | null;
  isolated: number[] | null;
  isolatedUnsync: number[] | null;
  leafNodes: number[] | null;
  naming: ForgeViewerNaming | null;
  dbIdToExternalIdMapping: ForgeViewerDbIdToExternalIdMapping | null;
  externalIdToDbIdMapping: ForgeViewerExternalIdToDbIdMapping | null;
  externalIdToFileIdMapping: ForgeViewerExternalIdToFileIdMapping | null;
};

const initialState: ForgeViewerState = {
  ready: false,
  filtering: false,
  viewReference: null,
  restoreViewData: null,
  markupActive: false,
  selection: null,
  modelElementSelection: null,
  isolated: null,
  isolatedUnsync: null,
  leafNodes: null,
  naming: null,
  dbIdToExternalIdMapping: null,
  externalIdToDbIdMapping: null,
  externalIdToFileIdMapping: null,
};

export const forgeViewerSlice = createSlice({
  name: 'forgeViewer',
  initialState,
  reducers: {
    clearForgeViewerState: () => initialState,

    setForgeViewerReady: (state, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },

    setForgeViewerFiltering: (state, action: PayloadAction<boolean>) => {
      state.filtering = action.payload;
    },

    setForgeViewerViewReference: (
      state,
      action: PayloadAction<FileVersionViewReference | null>,
    ) => {
      state.viewReference = action.payload;
    },

    setForgeViewerRestoreViewData: (
      state,
      action: PayloadAction<RestoreViewData | null>,
    ) => {
      state.restoreViewData = action.payload;
    },

    setForgeViewerMarkupActive: (state, action: PayloadAction<boolean>) => {
      state.markupActive = action.payload;
    },

    setForgeViewerSelection: (
      state,
      action: PayloadAction<number[] | null>,
    ) => {
      state.selection = action.payload;
    },

    setForgeViewerModelElementSelection: (state) => {
      state.modelElementSelection = state.selection;
    },

    resetForgeViewerModelElementSelection: (state) => {
      state.modelElementSelection = null;
    },

    setForgeViewerIsolated: (state, action: PayloadAction<number[] | null>) => {
      state.isolated = action.payload;
    },

    setForgeViewerIsolatedUnsync: (
      state,
      action: PayloadAction<number[] | null>,
    ) => {
      state.isolatedUnsync = action.payload;
    },

    setForgeViewerLeafNodes: (
      state,
      action: PayloadAction<number[] | null>,
    ) => {
      state.leafNodes = action.payload;
    },

    setForgeViewerNaming: (
      state,
      action: PayloadAction<ForgeViewerNaming | null>,
    ) => {
      state.naming = action.payload;
    },

    setForgeViewerDbIdToExternalIdMapping: (
      state,
      action: PayloadAction<ForgeViewerDbIdToExternalIdMapping | null>,
    ) => {
      state.dbIdToExternalIdMapping = action.payload;
    },

    setForgeViewerExternalIdToDbIdMapping: (
      state,
      action: PayloadAction<ForgeViewerExternalIdToDbIdMapping | null>,
    ) => {
      state.externalIdToDbIdMapping = action.payload;
    },

    setForgeViewerExternalIdToFileIdMapping: (
      state,
      action: PayloadAction<ForgeViewerExternalIdToFileIdMapping | null>,
    ) => {
      state.externalIdToFileIdMapping = action.payload;
    },
  },
});

export const { reducer } = forgeViewerSlice;
export const {
  setForgeViewerReady,
  setForgeViewerFiltering,
  setForgeViewerViewReference,
  setForgeViewerRestoreViewData,
  setForgeViewerMarkupActive,
  setForgeViewerSelection,
  setForgeViewerModelElementSelection,
  resetForgeViewerModelElementSelection,
  setForgeViewerIsolated,
  setForgeViewerIsolatedUnsync,
  setForgeViewerLeafNodes,
  setForgeViewerNaming,
  setForgeViewerDbIdToExternalIdMapping,
  setForgeViewerExternalIdToDbIdMapping,
  setForgeViewerExternalIdToFileIdMapping,
} = forgeViewerSlice.actions;
export const selectForgeViewerReady = (state: RootState) =>
  state.forgeViewer.ready;
export const selectForgeViewerFiltering = (state: RootState) =>
  state.forgeViewer.filtering;
export const selectForgeViewerViewReference = (state: RootState) =>
  state.forgeViewer.viewReference;
export const selectForgeViewerRestoreViewData = (state: RootState) =>
  state.forgeViewer.restoreViewData;
export const selectForgeViewerMarkupActive = (state: RootState) =>
  state.forgeViewer.markupActive;
export const selectForgeViewerSelection = (state: RootState) =>
  state.forgeViewer.selection;
export const selectForgeViewerModelElementSelection = (state: RootState) =>
  state.forgeViewer.modelElementSelection;
export const selectForgeViewerIsolated = (state: RootState) =>
  state.forgeViewer.isolated;
export const selectForgeViewerIsolatedUnsync = (state: RootState) =>
  state.forgeViewer.isolatedUnsync;
export const selectForgeViewerLeafNodes = (state: RootState) =>
  state.forgeViewer.leafNodes;
export const selectForgeViewerNaming = (state: RootState) =>
  state.forgeViewer.naming;
export const selectForgeViewerDbIdToExternalIdMapping = (state: RootState) =>
  state.forgeViewer.dbIdToExternalIdMapping;
export const selectForgeViewerExternalIdToDbIdMapping = (state: RootState) =>
  state.forgeViewer.externalIdToDbIdMapping;
export const selectForgeViewerExternalIdToFileIdMapping = (state: RootState) =>
  state.forgeViewer.externalIdToFileIdMapping;

export const selectViewReference = (state: RootState) =>
  state.forgeViewer.viewReference;
