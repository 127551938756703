import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { CompanyPopulatedUser, OwnerCompanyPopulatedProject } from 'shared';

import { generateDemoProject } from 'src/admin/apiUsers';
import { useAdminUserDemoProjectsQuery } from 'src/admin/queries';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { Modal } from 'src/common/components/Modal';
import { SelectSitelife } from 'src/common/components/SelectSitelife';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Button } from 'src/common/components/buttons/Button';

function sortDemoProjects(
  a: OwnerCompanyPopulatedProject,
  b: OwnerCompanyPopulatedProject,
) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

function countDemoInstances(
  existingDemoProjects: OwnerCompanyPopulatedProject[],
  masterProject?: OwnerCompanyPopulatedProject,
) {
  return existingDemoProjects.reduce((count, demoProject) => {
    return masterProject?._id === demoProject.originDemoProject
      ? count + 1
      : count;
  }, 0);
}

type Props = {
  user: CompanyPopulatedUser;
  existingDemoProjects: OwnerCompanyPopulatedProject[];
  companyName: string;
};

export const AddDemoProjectButton = ({
  user,
  existingDemoProjects,
  companyName,
}: Props) => {
  const { t } = useTranslation(['admin', 'common']);
  const queryClient = useQueryClient();

  const { data: masterDemoProjects, isLoading: isLoadingMasterDemoProjects } =
    useAdminUserDemoProjectsQuery(user._id);

  const [showModal, setShowModal] = useState(false);
  const [selectedMasterProject, setSelectedMasterProject] =
    useState<SelectSitelifeOptionType>();
  const [isGenerating, setIsGenerating] = useState(false);

  const sortedMasterDemoProjects = masterDemoProjects.sort(sortDemoProjects);
  const masterDemoOptions = sortedMasterDemoProjects.map((masterProject) => {
    return { label: masterProject.name, value: masterProject._id };
  });

  const onClickCreateDemoProject = async () => {
    setIsGenerating(true);
    try {
      const userId = user._id;
      const masterDemoProjectId = selectedMasterProject?.value;
      if (!userId || !masterDemoProjectId) {
        throw new Error();
      }
      await generateDemoProject(userId, masterDemoProjectId);
      toast.success(t('admin:users.demo.generateDemoProjectSuccess'));
      await queryClient.invalidateQueries({
        queryKey: ['admin-user-projects'],
      });
    } catch (exception) {
      toast.error(t('admin:users.demo.generateDemoProjectFailure'));
    } finally {
      setIsGenerating(false);
      setShowModal(false);
    }
  };

  const onClose = () => setShowModal(false);

  return (
    <>
      <Button
        variant="secondary"
        size="sm"
        onClick={() => setShowModal(true)}
        loading={isLoadingMasterDemoProjects}
      >
        {t('admin:users.demo.button')}
      </Button>
      <Modal isOpen={showModal} onRequestClose={onClose} className="w-[640px]">
        <Modal.Header onClose={onClose}>
          {t('admin:users.demo.title')}
        </Modal.Header>
        <div className="col flex flex-col space-y-4 p-6">
          <div>
            <Trans
              ns="admin"
              i18nKey="users.demo.text"
              values={{ companyName }}
              components={[
                <span className="font-semibold" key="0" />,
                <span className="font-semibold" key="1" />,
              ]}
            />
          </div>
          <div>
            <span>{t('admin:users.demo.selectProject')}</span>
            <SelectSitelife
              options={masterDemoOptions}
              value={selectedMasterProject}
              onChange={(selected: SelectSitelifeOptionType) =>
                setSelectedMasterProject(selected)
              }
              isLoading={isLoadingMasterDemoProjects}
              closeMenuOnSelect
              usePortaling
            />
          </div>
          {selectedMasterProject && (
            <DemoInlineMessage
              masterDemoProjectId={selectedMasterProject.value}
              masterDemoProjects={masterDemoProjects}
              existingDemoProjects={existingDemoProjects}
              companyName={companyName}
            />
          )}
          <div className="ml-auto flex gap-2 pt-2">
            <Button
              variant="tertiary"
              onClick={onClose}
              disabled={isGenerating}
            >
              {t('common:button.abort')}
            </Button>
            <Button
              variant="primary"
              onClick={onClickCreateDemoProject}
              disabled={!selectedMasterProject}
              loading={isGenerating}
            >
              {t('common:button.generate')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const DemoInlineMessage = ({
  masterDemoProjectId,
  masterDemoProjects,
  existingDemoProjects,
  companyName,
}: {
  masterDemoProjectId?: string;
  masterDemoProjects: OwnerCompanyPopulatedProject[];
  existingDemoProjects: OwnerCompanyPopulatedProject[];
  companyName: string;
}) => {
  const count = countDemoInstances(
    existingDemoProjects,
    masterDemoProjects.find((p) => p._id === masterDemoProjectId),
  );

  return (
    <InlineMessage variant="secondary">
      <span>
        <Trans
          ns="admin"
          i18nKey="users.demo.info"
          values={{ companyName, count }}
          components={[
            <span className="font-semibold" key="0" />,
            <span className="font-semibold" key="1" />,
          ]}
        />
      </span>
    </InlineMessage>
  );
};
