import { faHardHat } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import NoServiceSpecificationIllustration from 'src/assets/illu_no-ss.svg';
import { Button } from 'src/common/components/buttons/Button';
import { Card } from 'src/common/components/Card';

export const NoActions = () => {
  const { t } = useTranslation('project');
  const navigate = useNavigate();
  const { projectId = '' } = useParams();

  return (
    <Card>
      <div className="mx-auto my-6 max-w-[500px] text-center">
        <img
          className="mx-auto mb-8"
          src={NoServiceSpecificationIllustration}
          alt=""
        />
        <h1 className="my-4 text-4xl font-semibold">
          {t('techCrewEdit.noActions.title')}
        </h1>
        <div className="mb-6 text-shuttleGray-600">
          <p className="my-3">{t('techCrewEdit.noActions.description1')}</p>
          <p className="my-3">{t('techCrewEdit.noActions.description2')}</p>
          <p className="my-3">{t('techCrewEdit.noActions.description3')}</p>
        </div>
        <Button
          onClick={() => navigate(`/p/${projectId}/dashboard/settings/actions`)}
        >
          <FontAwesomeIcon icon={faHardHat} />
          {t('techCrewEdit.noActions.action')}
        </Button>
      </div>
    </Card>
  );
};
