import { faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from 'src/common/utils';
import FileIllustration from 'src/assets/illu_file.svg';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';
import { FileType } from 'src/file/types';
import {
  getFileType,
  getModelFileTypeNameString,
  getModelFileTypeString,
  splitFileName,
} from 'src/file/util';
import { TypeBadge } from '../../TypeBadge';

type Props = {
  fileType: FileType.REVIT | FileType.IFC | FileType.PDF_PLAN;
  currentFileNames?: string[];
  onFileSelected: (file: File) => void;
  onClose: () => void;
};

export const SelectModal = ({
  fileType,
  currentFileNames,
  onFileSelected,
  onClose,
}: Props) => {
  const { t } = useTranslation(['file']);

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>();

  const dropRef = useRef<HTMLDivElement>(null);
  const dragCounter = useRef(0);
  const [dragging, setDragging] = useState<boolean>(false);
  const [duplicateFileName, setDuplicateFileName] = useState<string>();
  const [invalidFileName, setInvalidFileName] = useState<boolean>();
  const [invalidFileType, setInvalidFileType] = useState<boolean>();

  const mimeTypes =
    fileType === FileType.REVIT
      ? '.rvt'
      : fileType === FileType.IFC
      ? '.ifc'
      : 'application/pdf';

  const handleSelectFile = useCallback(
    (file: File) => {
      const { name } = splitFileName(file);
      const fileNameIsTaken = currentFileNames?.includes(name);

      setDuplicateFileName(name);
      setInvalidFileType(false);
      setInvalidFileName(false);

      try {
        const { fileType: checkedFileType } = getFileType(file);

        if (checkedFileType !== fileType) {
          setInvalidFileType(true);
          return;
        }
      } catch (err) {
        setInvalidFileType(true);
        return;
      }

      if (!fileNameIsTaken) {
        setDuplicateFileName(undefined);
        onFileSelected(file);
      }
    },
    [currentFileNames, fileType, onFileSelected],
  );

  useEffect(() => {
    const handleDragEnter = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current++;
      if (
        e.dataTransfer &&
        e.dataTransfer.items &&
        e.dataTransfer.items.length > 0
      ) {
        setDragging(true);
      }
    };

    const handleDragLeave = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dragCounter.current--;

      if (dragCounter.current > 0) return;
      setDragging(false);
    };

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
      dragCounter.current = 0;

      if (!e.dataTransfer || e.dataTransfer.files.length < 1) {
        return;
      }
      handleSelectFile(e.dataTransfer.files[0]);
    };
    if (dropRef.current) {
      dropRef.current.addEventListener('dragenter', handleDragEnter);
      dropRef.current.addEventListener('dragleave', handleDragLeave);
      dropRef.current.addEventListener('dragover', handleDragOver);
      dropRef.current.addEventListener('drop', handleDrop);
    }
    const copy = dropRef.current;
    return () => {
      if (copy) {
        copy.removeEventListener('dragenter', handleDragEnter);
        copy.removeEventListener('dragleave', handleDragLeave);
        copy.removeEventListener('dragover', handleDragOver);
        copy.removeEventListener('drop', handleDrop);
      }
    };
  }, [handleSelectFile]);

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      handleSelectFile(e.currentTarget.files[0]);
    }
  };

  return (
    <>
      <div className="flex w-full justify-between">
        <Modal.Title>
          <span className=" flex flex-row items-center">
            <TypeBadge fileType={fileType} />
            {`${getModelFileTypeNameString(fileType)} - ${t(
              'file:upload.selectModal.title',
            )}`}
          </span>
        </Modal.Title>
        <Modal.Close onClose={onClose} />
      </div>
      <h2 className="mb-6 text-shuttleGray-600">
        {t('file:upload.selectModal.subTitle', {
          fileTypeName: getModelFileTypeNameString(fileType),
          fileType: getModelFileTypeString(fileType),
        })}
      </h2>
      {duplicateFileName && !invalidFileType && (
        <InlineMessage variant="error" className="mb-6">
          {t('file:upload.selectModal.fileNameTaken', {
            fileName: duplicateFileName,
          })}
        </InlineMessage>
      )}
      {(invalidFileName || invalidFileType) && (
        <InlineMessage variant="error" className="mb-6">
          {invalidFileName && t('file:upload.modal.invalidFileName')}
          {invalidFileType &&
            t('file:upload.modal.uploadCorrectFileType', {
              fileExtension: fileType === FileType.PDF_PLAN ? 'PDF' : fileType,
            })}
        </InlineMessage>
      )}

      <div
        className={cn(
          'relative mb-2 flex flex-col items-center gap-8 rounded border-2 border-dashed border-gray-300 p-11',
          { 'border-none': dragging },
        )}
        ref={dropRef}
      >
        {dragging && <DropOverlay />}
        <img alt="" src={FileIllustration} />
        <Button
          onClick={() => {
            inputRef?.click();
          }}
        >
          {t('file:upload.selectModal.selectFile')}
        </Button>
      </div>
      <input
        type="file"
        className="hidden"
        multiple
        accept={mimeTypes}
        onChange={(e) => onFileChange(e)}
        onClick={(event) => {
          (event.target as HTMLInputElement).value = '';
        }}
        ref={(fileInput) => {
          setInputRef(fileInput);
        }}
      />
    </>
  );
};

const DropOverlay = () => {
  const { t } = useTranslation('file');
  return (
    <div
      className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center rounded border-2 border-dashed border-blue-700 bg-blue-50/95 font-medium text-blue-600"
      style={{ zIndex: 99999 }}
    >
      <div className="flex items-center gap-0.5">
        <FontAwesomeIcon icon={faUpload} fixedWidth />
        {t('upload.selectModal.dropFile')}
      </div>
    </div>
  );
};
