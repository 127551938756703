import { useTranslation } from 'react-i18next';
import { Workspace } from 'shared';
import { useState } from 'react';

import { Card } from 'src/common/components/Card';
import { Container } from 'src/common/components/Container';
import { Spinner } from 'src/common/components/Spinner';
import { WorkspaceRow } from '../components/analysis/WorkspaceRow';
import { useAdminWorkspaceQuery } from '../queries';
import { Pagination } from 'src/common/components/Pagination/Pagination';
import { SearchSort } from 'src/common/components/SearchSort/SearchSort';

export const AnalysisView = () => {
  const { t } = useTranslation(['admin', 'common']);

  const [page, setPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const { data: paginatedWorkspaces, isPending } = useAdminWorkspaceQuery(
    page,
    entriesPerPage,
    sortOrder,
    searchBy,
  );

  return (
    <Container className="h-full" variant="box">
      <h1 className="text-2xl font-semibold tracking-wide">
        {t('analysis.title')}
      </h1>
      <SearchSort
        searchBy={searchBy}
        sortOrder={sortOrder}
        onSearchBy={(newSearchBy) => setSearchBy(newSearchBy)}
        onSortOrder={(newSortOrder) => setSortOrder(newSortOrder)}
        searchOnChange={true}
      />
      <Card className="mt-6 overflow-auto">
        {isPending ? (
          <Spinner containerClassName="w-full my-[160px] text-center" />
        ) : paginatedWorkspaces && paginatedWorkspaces.data.length > 0 ? (
          <>
            <table className="relative w-full divide-y divide-gray-200 shadow">
              <thead>
                <tr>
                  <th scope="col" className="table-th">
                    {t('common:labels.name')}
                  </th>
                </tr>
              </thead>
              <tbody className="table-body">
                {paginatedWorkspaces.data.map((workspace: Workspace) => (
                  <WorkspaceRow workspace={workspace} key={workspace.id} />
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
            {t('common:notFound.message')}
          </p>
        )}
      </Card>
      <Pagination
        maxPage={
          paginatedWorkspaces
            ? Math.ceil(paginatedWorkspaces.totalCount / entriesPerPage)
            : 0
        }
        page={page}
        onChangePage={setPage}
        entriesPerPage={entriesPerPage}
        onChangeEntriesPerPage={setEntriesPerPage}
        className="p-6"
      />
    </Container>
  );
};
