import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectSitelife } from 'src/common/components/SelectSitelife';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Button } from 'src/common/components/buttons/Button';
import { FileType } from 'src/file/types';
import { validateLicense } from 'src/project/utils';
import {
  GetProjectResponse,
  LicenseEvaluationType,
} from 'src/project/types/projects';
import { useFilesQuery } from 'src/file/queries';

interface Props {
  project: GetProjectResponse;
  loading: boolean;
  onLoadParameters: (fileId: string, viewId: string) => void;
}

export const ModelSelection = ({
  project,
  loading,
  onLoadParameters,
}: Props) => {
  const { t } = useTranslation('project');
  const { data: files } = useFilesQuery(project._id);

  const [selectedFile, setSelectedFile] = useState<SelectSitelifeOptionType>();
  const [selectedView, setSelectedView] =
    useState<SelectSitelifeOptionType | null>(null);

  if (!files) {
    return null;
  }

  const fileOptions: SelectSitelifeOptionType[] = files
    .filter(
      (file) => file.type === FileType.REVIT || file.type === FileType.IFC,
    )
    .map((file) => ({ value: file._id, label: file.name }));

  let viewOptions: SelectSitelifeOptionType[] = [];
  if (selectedFile) {
    const modelFile = files.find((file) => file._id === selectedFile.value);
    if (modelFile && 'versions' in modelFile) {
      const activeVersion = modelFile.versions.find(
        (version) => version._id === modelFile.activeVersion,
      );
      if (activeVersion) {
        viewOptions = activeVersion.views.map((version) => ({
          value: version._id,
          label: version.name,
        }));
      }
    }
  }

  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;

  return (
    <div className="flex max-w-4xl gap-4">
      <div className="flex w-full flex-col gap-1.5">
        {t('settings.modelParameter.modelSelection.file')}
        <SelectSitelife
          options={fileOptions}
          onChange={(newFile: SelectSitelifeOptionType) => {
            setSelectedFile(newFile);
            setSelectedView(null);
          }}
          value={selectedFile}
          isDisabled={loading || isLicenseExpired}
          usePortaling
        />
      </div>
      <div className="flex w-full flex-col gap-1.5">
        {t('settings.modelParameter.modelSelection.view')}
        <SelectSitelife
          options={viewOptions}
          onChange={(newView: SelectSitelifeOptionType) =>
            setSelectedView(newView)
          }
          value={selectedView}
          isDisabled={loading || viewOptions.length <= 0}
          usePortaling
        />
      </div>
      <Button
        onClick={() =>
          selectedFile?.value &&
          selectedView?.value &&
          onLoadParameters(selectedFile.value, selectedView.value)
        }
        disabled={!selectedFile || !selectedView}
        loading={loading}
        className="mt-auto"
      >
        {t('settings.modelParameter.modelSelection.loadParameters')}
      </Button>
    </div>
  );
};
