import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';

import toast from 'src/common/toast';
import { Button } from 'src/common/components/buttons/Button';
import { PasswordInput } from 'src/common/components/inputs/PasswordInput/PasswordInput';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { ExternalTextLink, TextLink } from 'src/common/components/TextLink';
import { AuthLayout } from '../../components/AuthLayout';
import { useAuth } from 'src/auth/user-context';

export const LoginView = () => {
  const { login } = useAuth();
  const { t } = useTranslation('auth');
  const { search } = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await login(email, password);
    } catch (err) {
      toast.error(t('loggedError'));
    }
  };

  return (
    <AuthLayout aside={<MarketingCopy />}>
      <form onSubmit={handleSubmit} className="px-8">
        <TextInput
          label={t('email')}
          placeholder={t('email')}
          value={email}
          name="email"
          className="mt-3 w-full"
          onChange={(e) => setEmail(e.target.value)}
          id="email"
          required
        />

        <PasswordInput
          label={t('password')}
          placeholder={t('password')}
          value={password}
          name="password"
          className="mt-3 w-full"
          onChange={(e) => setPassword(e.target.value)}
          id="password"
          required
        />

        <div className="mt-8 flex w-full flex-col gap-2 text-base">
          <Button type="submit">
            {`${t('login')}${
              import.meta.env.VITE_ENVIRONMENT === 'staging'
                ? ` ${t('test')}`
                : ''
            }`}
          </Button>
        </div>
      </form>
      <div className="mt-2 py-2 text-center">
        <TextLink to="/forgot-password">{`${t('forgotPassword')}`}</TextLink>
      </div>
      <hr className="my-4 text-gray-300" />
      <div className="px-8 pt-2">
        <h1 className="mb-2 text-xl font-semibold ">{t('newToSitelife')}</h1>
        <Button
          className="mt-2 w-full"
          variant="secondary"
          onClick={() => navigate(`/sign-up${search}`)}
        >
          {`${t('toSignUp')}`}
        </Button>
      </div>
    </AuthLayout>
  );
};

const MarketingCopy = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <h1 className="mb-4 mt-11 text-4xl font-semibold">
        {t('marketingAside.title')}
      </h1>
      <p className="mb-4 text-shuttleGray-600">
        {t('marketingAside.description')}
      </p>
      <ul className="mb-6">
        <li className="mb-2 flex items-center gap-2">
          <FontAwesomeIcon
            fixedWidth
            icon={faCheckCircle}
            className="text-green-600"
          />
          <span>
            <Trans ns="auth" i18nKey="marketingAside.list.documentation">
              Modellbasierte <strong>Dokumentation</strong>
            </Trans>
          </span>
        </li>
        <li className="flex items-center gap-2">
          <FontAwesomeIcon
            fixedWidth
            icon={faCheckCircle}
            className="text-green-600"
          />
          <span>
            <Trans ns="auth" i18nKey="marketingAside.list.analysis">
              Kontextbezogene <strong>Analyse</strong>
            </Trans>
          </span>
        </li>
      </ul>

      <p className="mb-4 text-shuttleGray-600">
        <Trans ns="auth" i18nKey="marketingAside.visitWebsite">
          Besuchen Sie unsere{' '}
          <ExternalTextLink href="https://bim-management.com/">
            Website
          </ExternalTextLink>{' '}
          und erfahren Sie viele weitere Informationen rund um Sitelife.
        </Trans>
      </p>
      <a href="https://bim-management.com/" target="_blank" rel="noreferrer">
        <Button variant="secondary" size="sm" className="mb-10">
          {t('marketingAside.button')}
        </Button>
      </a>
      <p className="text-sm text-shuttleGray-600">
        {t('marketingAside.footer')}
      </p>
    </>
  );
};
