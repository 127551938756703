import { Navigate, Route, Routes } from 'react-router-dom';

import { Container } from 'src/common/components/Container';
import { TechCrew } from 'src/project/types/techCrews';
import { LicenseExpiredMessage } from 'src/project/components/LicenseExpiredMessage';
import { PlannedHoursView } from './PlannedHoursView';
import { GetProjectResponse } from 'src/project/types/projects';
import { AnalysisParametersView } from './AnalysisParametersView';
import { hasEditAnalysisPermission } from 'src/Conrep/App/ActionAllowed/utils';
import { User } from 'src/user/types';
import { AnalysisPowerBiManagementView } from './AnalysisPowerBiManagementView';
import { RefreshHistory } from '../types';

export const AnalysisSettingsView = ({
  project,
  techCrews,
  history,
  licenseExpired,
  user,
  hasAdminPermissions,
  isLoading,
}: {
  project: GetProjectResponse;
  techCrews: TechCrew[];
  history: RefreshHistory | undefined;
  licenseExpired: boolean;
  user: User;
  hasAdminPermissions: boolean;
  isLoading: boolean;
}) => {
  const hasAnalysisEditPermissions = !!techCrews?.some((tc) =>
    hasEditAnalysisPermission(user._id, tc),
  );

  return (
    <Container variant="box" className="py-4">
      {licenseExpired && <LicenseExpiredMessage />}
      <div className="pt-4">
        <Routes>
          {(hasAdminPermissions || hasAnalysisEditPermissions) && (
            <Route
              path="plannedHours"
              element={
                <PlannedHoursView
                  techCrews={techCrews}
                  licenseExpired={licenseExpired}
                  hasAdminPermissions={hasAdminPermissions}
                  isLoading={isLoading}
                />
              }
            />
          )}
          {hasAdminPermissions && (
            <Route
              path="analysisParameters"
              element={<AnalysisParametersView />}
            />
          )}
          {hasAdminPermissions && (
            <Route
              path="powerBiManagement"
              element={
                <AnalysisPowerBiManagementView
                  project={project}
                  history={history}
                />
              }
            />
          )}
          <Route index element={<Navigate to="plannedHours" replace />} />
        </Routes>
      </div>
    </Container>
  );
};
