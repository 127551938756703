import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  faArrowLeft,
  faEdit,
  faExclamationTriangle,
  faInfoCircle,
  faPlus,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import Tippy from '@tippyjs/react';

import toast from 'src/common/toast';
import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Tooltip } from 'src/common/components/Tooltip';
import { SidebarLayout } from 'src/common/components/SidebarLayout';
import { Badge } from 'src/common/components/Badge';
import { Paper } from 'src/common/components/Paper';
import { NoActions } from 'src/project/components/NoActions';
import { ActionGroupForm } from '../components/ActionGroupForm';
import { useActionGroupsQuery, useActionsQuery } from '../queries';
import { Action } from '../types/actions';
import { ActionGroup } from '../types/actionGroups';
import { deleteActionGroup } from '../api/actionGroupsApi';
import { TechCrewSidebar } from '../components/TechCrewSidebar';
import { TechCrew } from '../types/techCrews';
import { Card } from 'src/common/components/Card';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Spinner } from 'src/common/components/Spinner';

export const TechCrewActionGroupsView = ({
  techCrew,
  isLicenseExpired,
}: {
  techCrew: TechCrew;
  isLicenseExpired: boolean;
}) => {
  const { t } = useTranslation(['project', 'common']);
  const { projectId = '' } = useParams();
  const { data: actions, isPending } = useActionsQuery(projectId);
  const { data: actionGroups } = useActionGroupsQuery(projectId, techCrew._id);

  const [isCreateActionGroupModalOpen, setIsCreateActionGroupModalOpen] =
    useState(false);

  const usedCategories = [
    ...new Set(
      actionGroups
        .flat()
        .map((actionGroup) => actionGroup.categories)
        .flat(),
    ),
  ];
  const hasActionGroups = actionGroups.length > 0;
  const hasActions = actions && actions.length > 0;

  return (
    <>
      <SidebarLayout
        header={
          <SidebarHeader
            techCrew={techCrew}
            disabled={isLicenseExpired}
            onCreate={
              hasActions
                ? () => setIsCreateActionGroupModalOpen(true)
                : undefined
            }
          />
        }
        headerLeft={<BackToTechCrewsButton />}
        sidebar={<TechCrewSidebar />}
      >
        {hasActions ? (
          hasActionGroups ? (
            <div className="mt-2 flex w-full flex-col overflow-auto pb-4">
              {isPending ? (
                <Spinner containerClassName="w-full h-full my-[160px] text-center" />
              ) : (
                <table className="w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="table-th">{t('actionGroup.view.name')}</th>
                      <th className="table-th">
                        {t('actionGroup.view.categories')}
                      </th>
                      <th className="table-th">
                        {t('actionGroup.view.actions')}
                      </th>
                      <th className="table-th" />
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {actions
                      ? actionGroups.map((group) => (
                          <ActionGroupRow
                            projectId={projectId}
                            actionGroup={group}
                            allActions={actions}
                            key={group._id}
                            techCrew={techCrew}
                            usedCategories={usedCategories}
                            isLicenseExpired={isLicenseExpired}
                          />
                        ))
                      : null}
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            <Card>
              <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
                {t('techCrewEdit.actionGroupView.noGroups')}
              </p>
            </Card>
          )
        ) : (
          <NoActions />
        )}
      </SidebarLayout>
      {actions ? (
        <Modal
          className="mt-12 w-[680px]"
          isOpen={isCreateActionGroupModalOpen}
          onRequestClose={() => setIsCreateActionGroupModalOpen(false)}
        >
          <Modal.Header onClose={() => setIsCreateActionGroupModalOpen(false)}>
            {t('actionGroup.form.newActionGroup')}
          </Modal.Header>
          <Modal.Body>
            <ActionGroupForm
              projectId={projectId}
              actions={actions}
              techCrew={techCrew}
              onCancel={() => setIsCreateActionGroupModalOpen(false)}
              inUse={false}
              usedCategories={usedCategories}
              isFirst={actionGroups.length === 1}
            />
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

const SidebarHeader = ({
  techCrew,
  disabled,
  onCreate,
}: {
  techCrew: TechCrew;
  disabled: boolean;
  onCreate?: () => void;
}) => {
  const { t } = useTranslation(['project']);
  return (
    <div className="flex min-h-[40px] items-center">
      <div className="flex items-center">
        <div
          style={{ backgroundColor: techCrew.color }}
          className="h-4 w-4 rounded"
        />
        <h1 className="ml-2 flex text-2xl font-semibold text-shuttleGray-800">
          {techCrew.name}
        </h1>
      </div>
      {onCreate && (
        <div className="ml-auto">
          <Button className="ml-4" onClick={onCreate} disabled={disabled}>
            <FontAwesomeIcon icon={faPlus} />
            {t('project:actionGroup.view.newActionGroups')}
          </Button>
        </div>
      )}
    </div>
  );
};

const BackToTechCrewsButton = () => {
  const { t } = useTranslation('project');

  return (
    <Link to="../../techCrews">
      <Button variant="tertiary">
        <FontAwesomeIcon icon={faArrowLeft} />
        <span>{t('techCrewEdit.allTrades')}</span>
      </Button>
    </Link>
  );
};

const CategoryList = ({ categories }: { categories: string[] }) => {
  const { t } = useTranslation('project');

  if (categories.length === 0) {
    return <span>{t('actionGroup.view.noCategory')}</span>;
  }
  if (categories.length < 3) {
    return <span>{categories.join(', ')}</span>;
  }
  const moreCategoriesLength = categories.length - 1;

  return (
    <>
      <span>{categories[0]}</span>
      <Tippy
        trigger="mouseenter focus"
        content={
          <Paper className="p-4 text-sm text-shuttleGray-600">
            <ul>
              {categories.slice(1).map((category) => (
                <li key={category} className="mb-1 last:mb-0">
                  {category}
                </li>
              ))}
            </ul>
          </Paper>
        }
      >
        <span className="ml-2 leading-none">
          <Badge className="ml-2">
            +{t('actionGroup.view.more', { count: moreCategoriesLength })}
          </Badge>
        </span>
      </Tippy>
    </>
  );
};

const ActionName = ({ action }: { action: Action }) => {
  return (
    <div className="flex items-center">
      <div
        className="mr-1 h-4 w-4 rounded"
        style={{ backgroundColor: action.color }}
      />
      <span>{action.name}</span>
    </div>
  );
};

const ActionList = ({
  actionIds,
  allActions,
}: {
  actionIds: string[];
  allActions: Action[];
}) => {
  const { t } = useTranslation('project');

  if (actionIds.length === 0) {
    return <span>{t('actionGroup.view.noCategory')}</span>;
  }

  const fullActions = allActions.filter((a) => actionIds.includes(a._id));

  if (fullActions.length === 1) {
    return <ActionName action={fullActions[0]} />;
  }

  const namedActions = (
    <>
      <ActionName action={fullActions[0]} />
      <span>,&nbsp;</span>
      <ActionName action={fullActions[1]} />
    </>
  );

  if (fullActions.length === 2) {
    return <div className="flex flex-wrap items-center">{namedActions}</div>;
  }

  const moreActionsLength = fullActions.length - 2;

  return (
    <div className="flex flex-wrap items-center">
      {namedActions}
      <Tippy
        trigger="mouseenter focus"
        content={
          <Paper className="p-4 text-sm text-shuttleGray-600">
            <ul>
              {fullActions.slice(2).map((action) => (
                <li key={action._id} className="mb-1 last:mb-0">
                  {action.name}
                </li>
              ))}
            </ul>
          </Paper>
        }
      >
        <span className="ml-2 leading-none">
          <Badge>
            +{t('actionGroup.view.more', { count: moreActionsLength })}
          </Badge>
        </span>
      </Tippy>
    </div>
  );
};

const DeleteButton = ({
  actionGroup,
  isUsed,
  isDefault,
  onDelete,
}: {
  isUsed: boolean;
  isDefault: boolean;
  actionGroup: ActionGroup;
  onDelete: (actionGroup: ActionGroup) => void;
}) => {
  const { t } = useTranslation(['project', 'common']);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const tooltipText = isUsed
    ? t('project:actionGroup.view.techCrewUsed')
    : isDefault
    ? t('project:actionGroup.view.isDefault')
    : t('common:button.delete');

  return (
    <>
      <Tooltip content={tooltipText}>
        <span>
          <ActionIcon
            onClick={() => setIsConfirmModalOpen(true)}
            disabled={isUsed || isDefault}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ActionIcon>
        </span>
      </Tooltip>
      <Modal isOpen={isConfirmModalOpen} overlayClassName="items-center">
        <ConfirmationDialog
          title={t('project:actionGroup.deleteModal.title')}
          message={t('project:actionGroup.deleteModal.message')}
          customConfirmText={t('project:actionGroup.deleteModal.confirm')}
          onCancel={() => setIsConfirmModalOpen(false)}
          onConfirm={() => {
            setIsConfirmModalOpen(false);
            onDelete(actionGroup);
          }}
        />
      </Modal>
    </>
  );
};

const ActionGroupRow = ({
  projectId,
  actionGroup,
  allActions,
  techCrew,
  usedCategories,
  isLicenseExpired,
}: {
  projectId: string;
  actionGroup: ActionGroup;
  allActions: Action[];
  techCrew: TechCrew;
  usedCategories: string[];
  isLicenseExpired: boolean;
}) => {
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleDelete = async (group: ActionGroup) => {
    try {
      await deleteActionGroup(group.project, group.techCrew, group._id);
      toast.success(t('actionGroup.view.deleteActionGroupSuccess'));
      queryClient.invalidateQueries({
        queryKey: [
          'projects',
          group.project,
          'techCrews',
          group.techCrew,
          'actionGroups',
        ],
      });
    } catch (error) {
      toast.error(t('actionGroup.view.deleteActionGroupFailure'));
    }
  };

  return (
    <>
      <tr>
        <td className="table-td">
          <div className="flex items-center">
            {!actionGroup.isDefaultActionGroup && actionGroup.isUsed && (
              <Tooltip content={t('actionGroup.view.techCrewUsed')}>
                <div>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mr-2 text-orange-700"
                  />
                </div>
              </Tooltip>
            )}
            {actionGroup.isDefaultActionGroup && (
              <Tooltip content={t('actionGroup.view.default')}>
                <div>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="mr-2 text-blue-500"
                  />
                </div>
              </Tooltip>
            )}
            <h3 className="font-semibold">{actionGroup.name}</h3>
          </div>
        </td>
        <td className="table-td">
          {actionGroup.isDefaultActionGroup ? (
            <div className="font-light italic text-gray-700">
              {t('actionGroup.view.defaultCategories')}
            </div>
          ) : (
            <CategoryList categories={actionGroup.categories} />
          )}
        </td>
        <td className="table-td">
          <ActionList actionIds={actionGroup.actions} allActions={allActions} />
        </td>
        <td className="table-td flex items-center justify-end">
          <Tooltip content={t('common:button.edit')}>
            <ActionIcon
              onClick={() => setIsEditModalOpen(true)}
              disabled={isLicenseExpired}
            >
              <FontAwesomeIcon icon={faEdit} />
            </ActionIcon>
          </Tooltip>
          <DeleteButton
            actionGroup={actionGroup}
            isUsed={actionGroup.isUsed || isLicenseExpired}
            isDefault={actionGroup.isDefaultActionGroup}
            onDelete={handleDelete}
          />
        </td>
      </tr>
      {isEditModalOpen && (
        <Modal
          className="mt-12 w-[680px]"
          isOpen={isEditModalOpen}
          onRequestClose={() => setIsEditModalOpen(false)}
        >
          <Modal.Header onClose={() => setIsEditModalOpen(false)}>
            {`${actionGroup.name} ${t('actionGroup.form.edit')}`}
          </Modal.Header>
          <Modal.Body>
            <ActionGroupForm
              projectId={projectId}
              actions={allActions}
              techCrew={techCrew}
              actionGroup={actionGroup}
              onCancel={() => setIsEditModalOpen(false)}
              inUse={actionGroup.isUsed}
              usedCategories={usedCategories}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
