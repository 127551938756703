import { useTranslation } from 'react-i18next';

import { Modal } from 'src/common/components/Modal';
import { Button } from 'src/common/components/buttons/Button';
import UploadIllustration from 'src/assets/illu_uploadcompleted.svg';

type Props = {
  onClose: () => void;
};

export const SuccessModal = ({ onClose }: Props) => {
  const { t } = useTranslation('file');

  return (
    <>
      <div className="flex items-center justify-between">
        <Modal.Title>{t('upload.successModal.title')}</Modal.Title>
        <Modal.Close onClose={onClose} />
      </div>
      <div className="mt-11 flex flex-col items-center gap-12">
        <img className="w-[160px]" alt="" src={UploadIllustration} />
        <Button className="ml-auto" variant="secondary" onClick={onClose}>
          {t('upload.successModal.closeButton')}
        </Button>
      </div>
    </>
  );
};
