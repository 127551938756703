import React, { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';

import { cn } from 'src/common/utils';

export type Props = {
  label?: ReactNode;
  error?: string;
};

export const Toggle = React.forwardRef<
  HTMLInputElement,
  Props & InputHTMLAttributes<HTMLInputElement>
>(function Toggle(
  {
    label,
    error,
    id,
    disabled,
    checked,
    required,
    onChange,
    className,
    ...rest
  },
  ref,
) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };

  return (
    <div className={cn('inline-flex flex-col gap-2', className)}>
      <label
        htmlFor={id}
        className={cn('inline-flex w-full items-center gap-2', [
          {
            'text-gray-400': disabled,
          },
        ])}
      >
        <div className="relative cursor-pointer">
          <div
            className={cn('h-4 w-7 rounded-full', [
              {
                'bg-gray-400 focus-within:bg-gray-700':
                  !checked && !error && !disabled,
                'bg-blue-600 focus-within:bg-blue-800':
                  checked && !error && !disabled,
                'bg-red-500 focus-within:bg-red-700': error && !disabled,
                'bg-gray-200': !checked && !error && disabled,
                'bg-blue-100': checked && !error && disabled,
                'bg-red-200': error && disabled,
              },
            ])}
          >
            <input
              id={id}
              className="h-1 w-1 opacity-0"
              type="checkbox"
              disabled={disabled}
              required={required}
              checked={checked}
              onChange={handleChange}
              ref={(inputElement) => {
                if (ref) {
                  if (typeof ref === 'function') {
                    ref(inputElement);
                  } else {
                    ref.current = inputElement;
                  }
                }
              }}
              {...rest}
            />
          </div>
          <div
            style={{ transition: 'all 0.3s ease-in-out' }}
            className={cn(
              'absolute top-[1px]  h-[14px] w-[14px] rounded-full bg-white',
              [
                {
                  'left-[1px]': !checked,
                  'translate-x-[13px]': checked,
                },
              ],
            )}
          />
        </div>
        {label || required ? (
          <div>
            {label}
            {required && (
              <span className={disabled ? 'text-gray-400' : 'text-red-600'}>
                *
              </span>
            )}
          </div>
        ) : null}
      </label>
      {error && (
        <div
          className={cn('text-sm', [
            { 'text-red-600': !disabled, 'text-gray-400': disabled },
          ])}
        >
          {error}
        </div>
      )}
    </div>
  );
});
