import { Dataset, Workspace } from 'shared';

import { PaginatedResponse } from 'src/common/types';
import { api } from 'src/common/fetch';

export async function getWorkspacesAsAdmin(
  page: number,
  entriesPerPage: number,
  sortOrder: string,
  filter?: string,
): Promise<PaginatedResponse<Workspace>> {
  const params: {
    page: string;
    entriesPerPage: string;
    sortOrder: string;
    filter?: string;
  } = {
    page: page.toString(),
    entriesPerPage: entriesPerPage.toString(),
    sortOrder,
  };
  if (filter) {
    params.filter = filter;
  }
  const searchParams = new URLSearchParams(params);
  return api.get<PaginatedResponse<Workspace>>(
    `/api-admin/analysis/workspaces?${searchParams.toString()}`,
  );
}

export async function getDatasetsAsAdmin(workspaceId: string) {
  return api.get<Dataset[]>(
    `/api-admin/analysis/workspaces/${workspaceId}/datasets`,
  );
}

export async function deleteWorkspaceById(workspaceId: string) {
  return api.delete(`/api-admin/analysis/workspaces/${workspaceId}`);
}

export async function deleteDatasetById(
  workspaceId: string,
  datasetId: string,
) {
  return api.delete(
    `/api-admin/analysis/workspaces/${workspaceId}/datasets/${datasetId}`,
  );
}
