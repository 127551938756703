import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { Modal } from 'src/common/components/Modal';
import toast from 'src/common/toast';
import { LicenseType } from 'src/project/types/projects';
import { ProjectLicensePicker } from './ProjectLicensePicker';
import { CreateProjectLoadingState } from './types';
import { createNewProject, trackProjectCreationAnalytics } from './utils';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';

type Props = {
  firstName: string;
  personalCompanyId: string;
};

export const CreateFirstProjectModal = ({
  firstName,
  personalCompanyId,
}: Props) => {
  const { t } = useTranslation(['common', 'project']);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);
  const onClose = () => setIsOpen(false);
  const [loading, setLoading] = useState<CreateProjectLoadingState>('idle');
  const name = t('project:createForm.view.createFirstProject.projectName', {
    firstName,
  });

  const handleProjectCreate = async (
    licenseType: Exclude<LicenseType, LicenseType.DEMO>,
  ) => {
    const loadingState =
      licenseType === LicenseType.BASIC
        ? 'creatingBasicProject'
        : 'creatingFreeProject';
    setLoading(loadingState);
    try {
      // call backend to create project and track with amplitude
      const { _id: projectId, name: projectName } = await createNewProject(
        name,
        licenseType,
        personalCompanyId,
      );
      trackProjectCreationAnalytics(projectId, projectName);
      // handle post creation tasks
      navigate(`/p/${projectId}/dashboard/overview`);
      queryClient.invalidateQueries({
        queryKey: ['projects'],
      });
      queryClient.invalidateQueries({
        queryKey: ['user'],
      });
    } catch (err) {
      toast.error(t('project:createForm.view.failMessage'));
    } finally {
      setLoading('idle');
    }
  };

  const disableLicenseButtons = loading !== 'idle';
  const isLoadingFreeProject = loading === 'creatingFreeProject';
  const isLoadingBasicProject = loading === 'creatingBasicProject';

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="w-[672px]">
      <div className="relative px-6 pt-6">
        <div className="flex flex-col items-center gap-2 text-center">
          <h1 className="text-xl font-semibold">
            {t('project:createForm.view.createFirstProject.welcome')}
          </h1>
          <p className="w-2/3">
            {t('project:createForm.view.createFirstProject.greetings')}
          </p>
          <h1 className="mt-4 text-lg font-semibold">
            {t(
              'project:createForm.view.createFirstProject.startWithFirstProject',
            )}
          </h1>
        </div>
        <ActionIcon className="absolute end-6 top-4" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </ActionIcon>
      </div>
      <Modal.Body>
        <ProjectLicensePicker
          handleProjectCreate={handleProjectCreate}
          disableLicenseButtons={disableLicenseButtons}
          isLoadingFreeProject={isLoadingFreeProject}
          isLoadingBasicProject={isLoadingBasicProject}
        />
      </Modal.Body>
    </Modal>
  );
};
