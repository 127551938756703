import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { User } from 'src/user/types';
import { login as signIn, logout as signOut } from './api';

const authContext = React.createContext<User | null>(null);

/**
 * can only be used inside of `<UserProvider />`
 */
export const useUser = () => {
  const user = React.useContext(authContext);
  if (!user) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return user;
};

export function useAuth() {
  const queryClient = useQueryClient();

  async function login(email: string, password: string) {
    const _user = await signIn(email, password);
    queryClient.setQueryData(['user'], _user);
  }

  async function logout() {
    await signOut();
    queryClient.resetQueries();
  }

  return { login, logout };
}

export function UserProvider({
  user,
  children,
}: {
  user: User;
  children: React.ReactNode;
}) {
  return <authContext.Provider value={user}>{children}</authContext.Provider>;
}
