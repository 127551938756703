import {
  faSortAlphaDown,
  faSortAlphaUp,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectSitelife } from 'src/common/components/SelectSitelife';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { SelectNative } from 'src/common/components/inputs/SelectNative/SelectNative';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { ProjectFilter } from 'src/project/types/projects';

type Props = {
  search: string;
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  filterOptions: SelectSitelifeOptionType[];
  selectedFilters: ProjectFilter[];
  onSelectFilterChange: (filters: ProjectFilter[]) => void;
  sortProperty: 'name' | 'createdAt';
  onSortPropertyChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  sortDirection: 'asc' | 'desc';
  onToggleSortDirection: () => void;
};

export const ProjectListActionBar = ({
  search,
  onSearchChange,
  filterOptions,
  selectedFilters,
  onSelectFilterChange,
  sortProperty,
  onSortPropertyChange,
  sortDirection,
  onToggleSortDirection,
}: Props) => {
  const { t } = useTranslation(['common', 'project']);

  return (
    <div>
      <h1 className="text-xl font-semibold">{t('project:listView.title')}</h1>
      <div className="flex items-end gap-4">
        <TextInput
          placeholder={t('project:listView.actionBar.searchPlaceholder')}
          className="w-[320px]"
          value={search}
          onChange={onSearchChange}
          aria-label={t('project:listView.actionBar.searchPlaceholder')}
        />
        <div className="flex items-end gap-2">
          <div className="flex flex-col border-l-2 pl-4">
            <span>{t('common:filterBy')}</span>
            <SelectSitelife
              className="mr-2 mt-1 w-[400px]"
              options={filterOptions}
              value={selectedFilters.map((f) => ({
                label: f,
                value: f,
              }))}
              onChange={(selection: SelectSitelifeOptionType[]) => {
                onSelectFilterChange(
                  selection.map((p) => p.value as ProjectFilter),
                );
              }}
              isMulti
              closeMenuOnSelect={false}
            />
          </div>
          <div className="flex flex-col border-l-2 pl-4">
            <span>{t('common:sortBy')}</span>
            <SelectNative
              onChange={onSortPropertyChange}
              value={sortProperty}
              aria-label="sort"
              className="mt-1"
            >
              <option value="name">
                {t('project:listView.actionBar.projectName')}
              </option>
              <option value="createdAt">
                {t('project:listView.actionBar.creationDate')}
              </option>
            </SelectNative>
          </div>
          <ActionIcon
            className="mb-1"
            onClick={onToggleSortDirection}
            aria-label="toggle sort direction"
          >
            <FontAwesomeIcon
              icon={sortDirection === 'asc' ? faSortAlphaDown : faSortAlphaUp}
              size="lg"
            />
          </ActionIcon>
        </div>
      </div>
    </div>
  );
};
