import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  faEdit,
  faInfoCircle,
  faPlus,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import * as amplitude from '@amplitude/analytics-browser';

import toast from 'src/common/toast';
import { Button } from 'src/common/components/buttons/Button';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import { Tooltip } from 'src/common/components/Tooltip';
import { Modal } from 'src/common/components/Modal';
import { Container } from 'src/common/components/Container';
import { useTechCrewsQuery } from '../queries';
import { TechCrewForm } from '../components/TechCrew/Form';
import { deleteTechCrew, updateTechCrew } from '../api/techCrewsApi';
import { getViewDict, validateLicense } from '../utils';
import {
  GetProjectResponse,
  LicenseEvaluationType,
  LicenseType,
} from '../types/projects';
import { LicenseExpiredMessage } from '../components/LicenseExpiredMessage';
import { Card } from 'src/common/components/Card';
import { Spinner } from 'src/common/components/Spinner';
import { useFilesQuery } from 'src/file/queries';
import { TechCrewUsage } from '../types/techCrews';
import { Toggle } from 'src/common/components/inputs/Toggle';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';

export const TechCrewListView = ({
  project,
  currentUserId,
  isProjectOwnerOrAdmin,
  is3dProject,
}: {
  project: GetProjectResponse;
  currentUserId: string;
  isProjectOwnerOrAdmin: boolean;
  is3dProject: boolean;
}) => {
  const { t } = useTranslation(['project', 'common', 'conrep']);
  const { projectId = '' } = useParams();
  const { data: allTechCrews, isPending } = useTechCrewsQuery(projectId);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const techCrews = isProjectOwnerOrAdmin
    ? allTechCrews
    : allTechCrews?.filter((tc) => tc.admins.includes(currentUserId));

  const isFreeProject = project.license.type === LicenseType.FREE;
  const isLicenseExpired =
    validateLicense(project.license) === LicenseEvaluationType.EXPIRED;

  return (
    <>
      <Container className="h-full py-4" variant="box">
        {isLicenseExpired && <LicenseExpiredMessage />}
        <div className="py-4">
          <div className="flex justify-between space-x-4">
            <div>
              <h1 className="text-2xl font-semibold tracking-wide">
                {t('techCrews.title')}
              </h1>
              <div className="mt-1 font-normal text-shuttleGray-600">
                {t('techCrews.subtitle')}
              </div>
            </div>
            {isProjectOwnerOrAdmin && !isFreeProject && (
              <Button
                onClick={() => setIsCreateModalOpen(true)}
                className="self-end"
                disabled={isLicenseExpired}
              >
                <FontAwesomeIcon icon={faPlus} />
                {t('techCrew.newTechCrew')}
              </Button>
            )}
          </div>

          <div className="mt-6 w-full overflow-auto">
            {techCrews && techCrews.length > 0 ? (
              isPending ? (
                <Card>
                  <Spinner containerClassName="w-full my-[160px] text-center" />
                </Card>
              ) : (
                <table className="relative w-full divide-y divide-gray-200 shadow">
                  <thead>
                    <tr>
                      <th scope="col" className="table-th">
                        {t('techCrews.name')}
                      </th>
                      <th scope="col" className="table-th">
                        {t('techCrews.members')}
                      </th>
                      <th scope="col" className="table-th hidden md:table-cell">
                        {t('techCrews.defaultView')}
                      </th>
                      {!isFreeProject && (
                        <th
                          scope="col"
                          className="table-th hidden md:table-cell"
                        >
                          <div className="flex items-center gap-2">
                            <span>{t('techCrew.controlled.header')}</span>
                            <span className="text-base font-normal normal-case">
                              <Tooltip content={t('techCrew.controlled.info')}>
                                <FontAwesomeIcon
                                  className="text-shuttleGray-400"
                                  icon={faInfoCircle}
                                />
                              </Tooltip>
                            </span>
                          </div>
                        </th>
                      )}
                      <th scope="col" className="table-th px-6 py-3" />
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {techCrews?.map((tc) => (
                      <TechCrewRow
                        projectId={projectId}
                        techCrew={tc}
                        isFreeProject={isFreeProject}
                        isLicenseExpired={isLicenseExpired}
                        isProjectOwnerOrAdmin={isProjectOwnerOrAdmin}
                        key={tc._id}
                      />
                    ))}
                  </tbody>
                </table>
              )
            ) : (
              <Card>
                <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
                  {t('techCrews.noTechCrews')}
                </p>
              </Card>
            )}
          </div>
        </div>
      </Container>
      <Modal
        className="w-[768px]"
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
      >
        <Modal.Header onClose={() => setIsCreateModalOpen(false)}>
          {t('techCrew.form.newTechCrew')}
        </Modal.Header>
        <Modal.Body>
          <TechCrewForm
            onCancel={() => setIsCreateModalOpen(false)}
            projectId={projectId}
            isFreeProject={isFreeProject}
            is3dProject={is3dProject}
            disabled={isLicenseExpired}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const TechCrewRow = ({
  projectId,
  techCrew,
  isFreeProject,
  isLicenseExpired,
  isProjectOwnerOrAdmin,
}: {
  projectId: string;
  techCrew: TechCrewUsage;
  isFreeProject: boolean;
  isLicenseExpired?: boolean;
  isProjectOwnerOrAdmin?: boolean;
}) => {
  const { t } = useTranslation(['project', 'common']);
  const queryClient = useQueryClient();

  const { data: files } = useFilesQuery(projectId);
  const viewDict = getViewDict(files);
  const viewId = `${techCrew.defaultView?.file || ''}_${
    techCrew.defaultView?.viewableId || ''
  }`;
  const view = viewDict?.[viewId] ? viewDict[viewId].name : '';

  const [isConfirmControlledOpen, setIsConfirmControlledOpen] = useState(false);

  const handleToggleIsControlled = async () => {
    try {
      const editedTechCrew = { ...techCrew, controlled: !techCrew.controlled };
      await updateTechCrew(projectId, techCrew._id, editedTechCrew);
      await queryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'techCrews'],
      });
      toast.success(t('common:success.save'));
    } catch {
      toast.error(t('common:errors.save'));
    }
  };

  const handleTechCrewDelete = async () => {
    try {
      const { _id: techCrewId } = techCrew;
      await deleteTechCrew(projectId, techCrewId);
      amplitude.track('techcrew: delete', { projectId });
      toast.success(t('project:techCrew.techCrewDeletedSuccess'));
      queryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'techCrews'],
      });
    } catch (err) {
      toast.error(t('project:techCrew.techCrewDeletedFailure'));
    }
  };

  return (
    <tr key={techCrew._id}>
      <td className="table-td">
        <div className="flex items-center py-2">
          <div
            style={{ backgroundColor: techCrew.color }}
            className="h-4 w-4 min-w-[16px] rounded"
          />
          <div className="ml-2 max-w-[150px] truncate font-semibold">
            {techCrew.name}
          </div>
        </div>
      </td>
      <td className="table-td">{techCrew.members.length}</td>
      <td className="table-td table-td-truncate hidden min-w-[200px] md:table-cell">
        {view}
      </td>
      {!isFreeProject && (
        <td className="table-td">
          <Toggle
            checked={techCrew.controlled}
            disabled={!isProjectOwnerOrAdmin}
            onChange={() => setIsConfirmControlledOpen(true)}
          />
        </td>
      )}
      <td className="table-td">
        <div className="flex items-center justify-end">
          <Link to={`${techCrew._id}/settings`}>
            <ActionIcon disabled={isLicenseExpired}>
              <FontAwesomeIcon icon={faEdit} />
            </ActionIcon>
          </Link>
          {!isFreeProject && (
            <Tooltip
              content={
                techCrew.isUsed
                  ? t('project:techCrew.techCrewInUse')
                  : t('project:techCrew.deleteTechCrew')
              }
            >
              <ActionIcon
                disabled={
                  techCrew.isUsed || isLicenseExpired || !isProjectOwnerOrAdmin
                }
                onClick={handleTechCrewDelete}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ActionIcon>
            </Tooltip>
          )}
        </div>
      </td>
      <Modal isOpen={isConfirmControlledOpen}>
        <ConfirmationDialog
          onCancel={() => {
            setIsConfirmControlledOpen(false);
          }}
          title={
            techCrew.controlled
              ? t('project:techCrew.controlled.modalHeaderNotControlled')
              : t('project:techCrew.controlled.modalHeaderControlled')
          }
          message={`${
            techCrew.controlled
              ? t('project:techCrew.controlled.askNotControlled')
              : t('project:techCrew.controlled.askControlled')
          } ${t('project:techCrew.controlled.info')}`}
          onConfirm={() => {
            handleToggleIsControlled();
            setIsConfirmControlledOpen(false);
          }}
        />
      </Modal>
    </tr>
  );
};
