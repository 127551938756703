import React from 'react';

import { cn } from 'src/common/utils';
import { Paper } from '../Paper';

const Menu = ({
  children,
  className,
}: React.ComponentPropsWithoutRef<'div'> & { className?: string }) => {
  return <Paper className={cn('min-w-[160px]', className)}>{children}</Paper>;
};

const MenuTitle = ({ children }: { children: React.ReactNode }) => {
  return <div className="px-3 py-3 font-medium">{children}</div>;
};

const MenuList = ({ children }: { children: React.ReactNode }) => {
  return <div className="p-1">{children}</div>;
};

type MenuItemProps = React.ComponentPropsWithoutRef<'button'> & {
  onClick?: () => void;
  disabled?: boolean;
  selected?: boolean;
  className?: string;
};
const MenuItem = React.forwardRef<HTMLButtonElement, MenuItemProps>(
  function MenuItem({ children, onClick, disabled, selected, className }, ref) {
    return (
      <button
        ref={ref}
        className={cn(
          'flex w-full items-center gap-2 p-2 hover:bg-blue-50 focus:bg-blue-100 disabled:cursor-default disabled:text-gray-400 disabled:hover:bg-transparent',
          { 'bg-blue-100': selected },
          className,
        )}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    );
  },
);

const MenuDivider = () => {
  return <hr className="text-gray-300" />;
};

Menu.Divider = MenuDivider;
Menu.Item = MenuItem;
Menu.Title = MenuTitle;
Menu.List = MenuList;

export { Menu };
