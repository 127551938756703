import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';

import { cn } from 'src/common/utils';

export interface InputWrapperProps extends ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
  label?: ReactNode;
  description?: ReactNode;
  error?: string | boolean;
  required?: boolean;
  inputCount?: number;
  inputMaxLength?: number;
}

export const InputWrapper = forwardRef<HTMLDivElement, InputWrapperProps>(
  function InputWrapper(props: InputWrapperProps, ref) {
    const {
      className,
      label,
      children,
      required,
      id,
      error,
      description,
      inputCount,
      inputMaxLength,
      ...others
    } = props;

    return (
      <div className={cn('inline-block', className)} ref={ref} {...others}>
        {label && (
          <label
            htmlFor={id}
            id={id ? `${id}-label` : undefined}
            className={cn({ 'mb-1': !description })}
          >
            {label}
            {required && <span className="text-red-500">*</span>}
          </label>
        )}

        {description && (
          <div className="mb-2 text-xs text-shuttleGray-400">{description}</div>
        )}

        {children}

        <div className="flex justify-between">
          <div>
            {typeof error !== 'boolean' && error && (
              <div className="mt-2 text-sm text-red-500">{error}</div>
            )}
          </div>

          <div>
            {(inputCount || inputCount === 0) && inputMaxLength && (
              <div className="mt-2 text-sm">
                {inputCount}/{inputMaxLength}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);
