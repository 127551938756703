import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

import { cn } from 'src/common/utils';
import { Card } from 'src/common/components/Card';
import Background from 'src/assets/login_background.webp';
import Logo from 'src/assets/signet_sitelife_full.svg';

type Props = {
  children: ReactNode;
  aside?: ReactNode;
};

export const AuthLayout = ({ children, aside }: Props) => {
  const { t } = useTranslation('auth');
  return (
    <div
      className="relative flex h-full w-full items-center justify-center overflow-y-auto bg-cover bg-fixed bg-center px-6"
      style={{ backgroundImage: `url(${Background})` }}
    >
      <Card
        className={cn('relative m-auto', aside ? 'max-w-[928px]' : 'max-w-md')}
      >
        <div className={cn({ 'grid md:grid-cols-2': aside })}>
          {aside ? (
            <aside className="bg-gray-100 px-8 py-6">{aside}</aside>
          ) : null}
          <div className="pb-8 pt-2">
            <img alt="sitelife logo" src={Logo} className="mx-auto w-[180px]" />
            {children}
          </div>
        </div>
      </Card>
      <div className="fixed bottom-0 left-0 flex w-full gap-4 bg-white px-4 py-3">
        <a
          href="https://help.sitelife.com"
          target="_blank"
          rel="noreferrer"
          className="text-sm text-shuttleGray-600"
        >
          {t('authLayout.helpCenter')}
        </a>
        <a
          href="https://app.getbeamer.com/sitelife"
          target="_blank"
          rel="noreferrer"
          className="text-sm text-shuttleGray-600"
        >
          {t('authLayout.version')} {import.meta.env.VITE_VERSION}
        </a>
        <a
          href="https://bim-management.com/impressum/"
          target="_blank"
          rel="noreferrer"
          className="ml-auto text-sm text-shuttleGray-600"
        >
          {t('authLayout.copyright')}
        </a>
        <a
          href="https://bim-management.com/datenschutzerklaerung/"
          target="_blank"
          rel="noreferrer"
          className="text-sm text-shuttleGray-600"
        >
          {t('authLayout.privacy')}
        </a>
      </div>
    </div>
  );
};
