import { HTMLAttributes } from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons';

import { cn } from 'src/common/utils';

type Props = {
  variant?: 'success' | 'warning' | 'error' | 'info';
  icon?: IconDefinition;
};

export const ActionMessage = ({
  variant = 'success',
  icon,
  children,
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement> & Props) => (
  <div
    className={cn(
      'flex min-h-[56px] items-center gap-2 rounded-sm border border-gray-200 py-2 pl-4 pr-2 shadow',
      [
        {
          'bg-green-50 text-green-700': variant === 'success',
          'bg-orange-50 text-orange-700': variant === 'warning',
          'bg-red-50 text-red-700': variant === 'error',
          'bg-teal-50 text-teal-700': variant === 'info',
        },
      ],
      className,
    )}
    {...rest}
  >
    <FontAwesomeIcon icon={icon || iconMap[variant]} fixedWidth />
    {children}
  </div>
);

const iconMap: { [key: string]: FontAwesomeIconProps['icon'] } = {
  success: faCheckCircle,
  warning: faExclamationTriangle,
  error: faExclamationCircle,
  info: faInfoCircle,
};
