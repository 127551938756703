import dayjs from 'dayjs';

import {
  FileType,
  FileDataWrapper,
  BaseFile,
  FileOrFolder,
  FileVersionView,
  FileVersionViewReference,
  ModelFile,
  SitelifeFile,
  ForgeBatchSignedS3Response,
} from './types';
import { SheetSet } from 'src/sheetset/types';
import {
  MIN_FILE_SIZE_FOR_CHUNK_UPLOAD,
  MIN_UPLOAD_SIZE,
} from 'src/Forge/constants';

function calculateChunks(
  fileSize: number,
  chunkSize = MIN_UPLOAD_SIZE,
): number {
  return Math.ceil(fileSize / chunkSize);
}

function shouldCalculateChunks(fileData: FileDataWrapper): boolean {
  return (
    (fileData.fileType === FileType.REVIT ||
      fileData.fileType === FileType.IFC) &&
    fileData.file.size > MIN_FILE_SIZE_FOR_CHUNK_UPLOAD
  );
}

export function isForgeBatchUpload(
  uploadObject: string | ForgeBatchSignedS3Response,
): uploadObject is ForgeBatchSignedS3Response {
  return (uploadObject as ForgeBatchSignedS3Response).urls !== undefined;
}

export function splitFileName(file: File): { name: string; ext: string } {
  const name = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
  const ext =
    file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) ||
    file.name;
  return { name, ext };
}

export function getFileType(file: File): {
  fileType: FileType;
  mimeType: string;
} {
  const { ext } = splitFileName(file);
  const lowerCaseExtention = ext.toLowerCase();
  switch (lowerCaseExtention) {
    case 'png':
      return { fileType: FileType.IMAGE, mimeType: 'image/png' };
    case 'jpg':
      return { fileType: FileType.IMAGE, mimeType: 'image/jpg' };
    case 'jpeg':
      return { fileType: FileType.IMAGE, mimeType: 'image/jpeg' };
    case 'pdf':
      return { fileType: FileType.PDF_PLAN, mimeType: 'application/pdf' };
    case 'rvt':
      return { fileType: FileType.REVIT, mimeType: '.rvt' };
    case 'ifc':
      return { fileType: FileType.IFC, mimeType: '.ifc' };
  }
  throw new Error(file.name);
}

export async function createFileObjects(
  files: FileDataWrapper[],
  path: string | undefined,
): Promise<Partial<BaseFile>[]> {
  const fileObjects = await Promise.all(
    files.map((f) => {
      const { name, ext } = splitFileName(f.file);
      let folder = path || '/';
      if (!folder.startsWith('/')) folder = `/${folder}`;
      if (!folder.endsWith('/')) folder += '/';

      const file: BaseFile = {
        path: folder,
        name,
        extension: `.${ext}`,
        type: f.fileType,
      };

      if (shouldCalculateChunks(f)) {
        file.chunks = calculateChunks(f.file.size);
      }

      if (f.fileType === FileType.REVIT) {
        if (f.isCompressed && f.rootFilename) {
          return {
            ...file,
            isCompressed: f.isCompressed,
            rootFilename: f.rootFilename,
          };
        }
        return { ...file, isCompressed: false };
      }

      return file;
    }),
  );

  return fileObjects;
}

export function toPrettyDate(dateString: string | Date | undefined): string {
  return dayjs(dateString).format('L');
}

export function isModelFile(file: SitelifeFile | BaseFile): file is ModelFile {
  return [FileType.REVIT, FileType.IFC, FileType.PDF_PLAN].includes(file.type);
}

export function getActiveModelFileVersion(file: ModelFile) {
  const index =
    file.versions.findIndex((v) => v._id === file.activeVersion) || 0;
  if (index > -1) {
    return file.versions[index];
  }
  return null;
}

export function matchesSearchFilter(
  text: string,
  searchFilter: string | undefined,
) {
  return (
    !searchFilter ||
    text.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())
  );
}

export function getFileIdOrFolderName(fileOrFolder: FileOrFolder) {
  if (fileOrFolder.type === 'file') {
    return fileOrFolder.file?._id || '';
  }
  return fileOrFolder.folder.name;
}

export function getModelFileTypeNameString(
  modelFileType: FileType.REVIT | FileType.IFC | FileType.PDF_PLAN,
): string {
  switch (modelFileType) {
    case FileType.REVIT:
      return 'Revit';
    case FileType.IFC:
      return 'IFC';
    case FileType.PDF_PLAN:
      return 'PDF';
  }
  return '';
}

export function getModelFileTypeString(
  modelFileType: FileType.REVIT | FileType.IFC | FileType.PDF_PLAN,
): string {
  switch (modelFileType) {
    case FileType.REVIT:
      return 'rvt';
    case FileType.IFC:
      return 'ifc';
    case FileType.PDF_PLAN:
      return 'pdf';
  }
  return '';
}

export function fileVersionViewToReference(
  view: FileVersionView,
): FileVersionViewReference {
  return {
    file: view.file,
    fileVersion: view.fileVersion,
    guid: view.guid,
    viewableId: view.viewableId,
    isMasterView: !!view.isMasterView,
  };
}

export function isSameViewFromSameFile(
  view1?: FileVersionView | FileVersionViewReference | null,
  view2?: FileVersionView | FileVersionViewReference | null,
) {
  return view1?.viewableId === view2?.viewableId && view1?.file === view2?.file;
}

export function getViewsFromFile(file: ModelFile) {
  return file.versions.find((v) => v._id === file.activeVersion)?.views || [];
}

export function getViewsFromSheetSet(sheetSet: SheetSet, files: ModelFile[]) {
  return sheetSet.views
    .map((viewRef) => {
      const file = files.find((f) => f._id === viewRef.file);
      const version = file?.versions.find((v) => v._id === viewRef.fileVersion);
      return version?.views.find((v) => v.viewableId === viewRef.viewableId);
    })
    .filter((v): v is FileVersionView => !!v);
}
