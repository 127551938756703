import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import GmailLogo from 'src/assets/email/gmail.svg?react';
import OutlookLogo from 'src/assets/email/outlook.svg?react';
import YahooLogo from 'src/assets/email/yahoo.svg?react';
import { Button } from 'src/common/components/buttons/Button';

type Props = {
  email: string;
};

export const SignUpSuccessMessage = ({ email }: Props) => {
  const { t } = useTranslation('auth');
  return (
    <div className="px-8">
      <h1 className="mb-2 mt-2 text-xl font-semibold">{t('thanks')}</h1>
      <p className="mb-2 text-shuttleGray-600">
        <Trans
          ns="auth"
          i18nKey="signUp.success.verifyEmail"
          values={{ email }}
          components={[
            <span className="font-semibold text-shuttleGray-800" key="0" />,
          ]}
        />
      </p>
      <p className="mb-2 text-shuttleGray-600">
        {t('signUp.success.spamInfo')}
      </p>
      <div className="my-4 grid grid-cols-3 gap-2">
        <EmailProviderButton
          label={t('signUp.success.open', { provider: 'Gmail' })}
          url="https://mail.google.com/"
        >
          <GmailLogo className="h-4 w-4" />
        </EmailProviderButton>
        <EmailProviderButton
          label={t('signUp.success.open', { provider: 'Outlook' })}
          url="https://outlook.office.com/mail/"
        >
          <OutlookLogo className="h-4 w-4" />
        </EmailProviderButton>
        <EmailProviderButton
          label={t('signUp.success.open', { provider: 'Yahoo!' })}
          url="https://mail.yahoo.com/"
        >
          <YahooLogo className="h-4 w-4" />
        </EmailProviderButton>
      </div>{' '}
      <div className="w-full">
        <Link to="/" className="w-full">
          <Button variant="tertiary" className="w-full">
            {t('signUp.toLogin')}
          </Button>
        </Link>
      </div>
    </div>
  );
};

const EmailProviderButton = ({
  label,
  url,
  children,
}: {
  label: string;
  url: string;
  children: ReactNode;
}) => {
  return (
    <button className="h-10 rounded-sm border border-shuttleGray-200 hover:border-blue-500 hover:bg-blue-50">
      <Link to={url} target="_blank">
        <div className="flex h-full w-full items-center justify-center gap-2 p-2">
          {children}
          <span className="text-sm text-shuttleGray-600">{label}</span>
        </div>
      </Link>
    </button>
  );
};
