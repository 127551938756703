import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { SitelifeAppbar } from 'src/app/components/appbar';
import { Container } from 'src/common/components/Container';
import { CompanyInvitations } from 'src/company/components/CompanyInvitations';
import { ProjectList } from '../components/ProjectList';
import { ProjectInvitations } from '../components/ProjectInvitations';

export const ProjectListView = () => {
  const { t } = useTranslation('project');

  return (
    <>
      <Helmet>
        <title>Sitelife - {t('listView.title')}</title>
      </Helmet>
      <div className="flex h-full flex-col">
        <SitelifeAppbar />
        <main className="flex h-full grow flex-row overflow-y-auto">
          <Container variant="box" className="py-8">
            <CompanyInvitations />
            <ProjectInvitations />
            <ProjectList />
          </Container>
        </main>
      </div>
    </>
  );
};
