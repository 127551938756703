import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import toast from 'src/common/toast';
import { Button } from 'src/common/components/buttons/Button';
import { InlineMessage } from 'src/common/components/InlineMessage';
import { SelectNative } from 'src/common/components/inputs/SelectNative/SelectNative';
import { TextInput } from 'src/common/components/inputs/TextInput/TextInput';
import { InputWrapper } from 'src/common/components/inputs/Wrapper/InputWrapper';
import { ExternalTextLink } from 'src/common/components/TextLink';
import { updateUser } from '../api';
import { UpdateUserRequest, UserProfileResponse } from '../types';
import { UpdatePasswordForm } from './UpdatePasswordForm';
import { useAuth } from 'src/auth/user-context';

type FormValues = {
  name: string;
  surname: string;
  email: string;
  position: string;
};

type Props = {
  userProfile: UserProfileResponse;
};

export const ProfileInfoForm = ({ userProfile: user }: Props) => {
  const { t, i18n } = useTranslation(['user', 'common']);
  const { logout } = useAuth();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordUpdateError, setIsPasswordUpdateError] = useState(false);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [language, setLanguage] = useState(i18n.language.slice(0, 2));
  const queryClient = useQueryClient();

  useEffect(() => {
    const defaultFormValues = {
      name: user.name,
      surname: user.surname,
      position: user.position,
    };
    reset({ ...defaultFormValues });
  }, [user, reset]);

  const changeLanguage = (newLanguage: string) => {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  const handleSubmitSuccess = async (data: FormValues) => {
    setIsLoading(true);
    const { name, surname, position } = data;
    const userData = { name, surname, position };

    try {
      await updateUser(user._id, userData);
      queryClient.invalidateQueries({
        queryKey: ['user-profile', user._id],
      });
      reset({ ...userData });
      toast.success(t('updateSuccess'));
    } catch (e) {
      toast.error(t('updateFailure'));
    }
    setIsLoading(false);
  };

  const handleUpdatePassword = async (userData: UpdateUserRequest) => {
    setIsLoading(true);
    setIsPasswordUpdateError(false);
    try {
      await updateUser(user._id, userData);
      logout();
    } catch (e) {
      if (e instanceof Error && e.message === 'Request failed with code 403') {
        setIsPasswordUpdateError(true);
      }
      toast.error(t('updateFailure'));
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitSuccess)}>
      <TextInput
        label={t('name')}
        {...register('name')}
        required
        className="mb-4 w-full"
      />
      <TextInput
        label={t('surname')}
        {...register('surname')}
        className="mb-4 w-full"
      />
      <TextInput
        label={t('position')}
        {...register('position')}
        className="w-full"
      />

      <hr className="my-8 text-gray-300" />

      <div className="w-1/2">
        <SelectNative
          label={t('language')}
          className="w-full"
          value={language}
          onChange={(event) => changeLanguage(event.currentTarget.value)}
        >
          <option value="de">Deutsch</option>
          <option value="en">English</option>
        </SelectNative>
      </div>

      <hr className="my-8 text-gray-300" />

      <InputWrapper label={t('email')} className="mb-4 flex w-full flex-col">
        <span className="font-semibold">{user.email}</span>
      </InputWrapper>

      <InlineMessage>
        <Trans
          ns="user"
          i18nKey="emailChange"
          components={[
            <ExternalTextLink href="https://help.sitelife.com" key="1">
              support
            </ExternalTextLink>,
          ]}
        />
      </InlineMessage>

      <hr className="mb-4 mt-8 text-gray-300" />

      <div className="flex items-center">
        <span>{t('password')}</span>
        {isChangePasswordOpen ? (
          <Button
            variant="tertiary"
            className="ml-auto"
            type="button"
            onClick={() => setIsChangePasswordOpen(false)}
          >
            {t('common:button.close')}
          </Button>
        ) : (
          <Button
            variant="tertiary"
            className="ml-auto"
            type="button"
            onClick={() => setIsChangePasswordOpen(true)}
          >
            {t('common:button.edit')}
          </Button>
        )}
      </div>

      {isChangePasswordOpen ? (
        <UpdatePasswordForm
          onSubmit={handleUpdatePassword}
          isLoading={isLoading}
          isPasswordUpdateError={isPasswordUpdateError}
        />
      ) : null}

      <hr className="mb-8 mt-4 text-gray-300" />

      <div className="flex items-center">
        <Button
          className="ml-auto"
          type="submit"
          loading={isLoading}
          disabled={!isDirty}
        >
          {t('common:button.save')}
        </Button>
      </div>
    </form>
  );
};
