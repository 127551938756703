import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';

import { Badge } from 'src/common/components/Badge';
import { getActiveModelFileVersion } from 'src/file/util';
import { ModelFile } from 'src/file/types';
import { logger } from 'src/logger';

export const FileViews = ({ file }: { file: ModelFile }) => {
  const views = getActiveModelFileVersion(file)?.views;

  return (
    <>
      {views?.map((view) => {
        if (!file._id) {
          logger.capture('file._id is undefined');
          return;
        }
        return (
          <tr key={view._id}>
            <td className="table-td" colSpan={4}>
              <Link
                to={`/p/${view.project}/dashboard/v?file=${file._id}&view=${view._id}`}
              >
                <div className="flex pl-16">
                  <div className="group flex flex-row items-center space-x-2 p-1 pr-2 hover:bg-shuttleGray-50">
                    <Badge variant="teal">{view.role}</Badge>
                    <span className="text-td-text">{view.name}</span>
                    <span className="mx-2 text-shuttleGray-700 opacity-0 group-hover:opacity-100">
                      <FontAwesomeIcon icon={faEye} />
                    </span>
                  </div>
                </div>
              </Link>
            </td>
          </tr>
        );
      })}
    </>
  );
};
