import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

import { Avatar } from 'src/common/components/Avatar';
import { User } from 'src/user/types';
import { SelectNative } from 'src/common/components/inputs/SelectNative/SelectNative';
import { ActionIcon } from 'src/common/components/buttons/ActionIcon';
import toast from 'src/common/toast';
import { TransferOwnerModal } from 'src/company/components/TransferOwnerModal';
import { Button } from 'src/common/components/buttons/Button';
import { addUserToAdmins, removeUser, removeUserFromAdmins } from '../api';
import { CompanyRole } from '../utils';
import { ConfirmationDialog } from 'src/common/components/ConfirmationDialog';
import { Modal } from 'src/common/components/Modal';

type Props = {
  member: User;
  otherMembers: User[];
  memberRole: CompanyRole;
  userRole: CompanyRole;
  currentUserId: string;
  companyId: string;
};

export const CompanyUserItem = ({
  member,
  otherMembers,
  memberRole,
  userRole,
  currentUserId,
  companyId,
}: Props) => {
  const { t } = useTranslation('company');
  const queryClient = useQueryClient();

  const [showUserDeleteModal, setShowUserDeleteModal] =
    useState<boolean>(false);
  const [showUserRoleModal, setShowUserRoleModal] = useState(false);
  const [userRoleToChange, setUserRoleToChange] = useState<CompanyRole>();

  const userCapabilities = CAPABILITIES[userRole];
  const canEditRoles =
    userRole === CompanyRole.OWNER ||
    (userRole === CompanyRole.ADMIN && memberRole !== CompanyRole.OWNER);

  const isSelf = member._id === currentUserId;
  const isOwner = memberRole === CompanyRole.OWNER;
  const hasOtherMembers = otherMembers.length > 0;
  const isCurrentUserOwner = isSelf && isOwner;

  const canTransferOwnership = isCurrentUserOwner && hasOtherMembers;
  const canChangeRole = !isCurrentUserOwner && canEditRoles;

  async function changeUserRole() {
    if (!userRoleToChange) {
      return;
    }
    try {
      switch (userRoleToChange) {
        case CompanyRole.ADMIN:
          await addUserToAdmins(companyId, member._id);
          break;
        case CompanyRole.USER:
          await removeUserFromAdmins(companyId, member._id);
          break;
      }
      queryClient.invalidateQueries({
        queryKey: ['companies'],
      });
      setShowUserRoleModal(false);
      setUserRoleToChange(undefined);
    } catch (e) {
      toast.error(t('users.roles.error'));
    }
  }

  async function deleteUser() {
    try {
      await removeUser(companyId, member._id);
      setShowUserDeleteModal(false);
      queryClient.invalidateQueries({
        queryKey: ['companies', companyId, 'users'],
      });
    } catch (e) {
      toast.error(t('users.delete.error'));
    }
  }

  function handleRoleChange(event: ChangeEvent<HTMLSelectElement>) {
    setUserRoleToChange(event.target.value as CompanyRole);
    setShowUserRoleModal(true);
  }

  const roleLabel = {
    [CompanyRole.OWNER]: 'users.owner' as const,
    [CompanyRole.ADMIN]: 'users.admin' as const,
    [CompanyRole.USER]: 'users.normalUser' as const,
  };

  return (
    <>
      <tr key={member.email}>
        <td className="table-td table-td-truncate min-w-[200px]">
          <div className="flex items-center">
            <Avatar
              size="sm"
              alt={`${member.name} ${member.surname}`}
              src={member.image?.key}
            />

            <div className="ml-4 overflow-hidden py-1">
              <div className="truncate text-base font-semibold">
                {[member.name, member.surname].join(' ')}
              </div>
              <div className="truncate text-base text-shuttleGray-600">
                {member.email}
              </div>
            </div>
          </div>
        </td>
        <td className="table-td table-td-truncate hidden min-w-[100px] lg:table-cell">
          {member.position}
        </td>
        <td className="table-td">
          {canChangeRole ? (
            <SelectNative value={memberRole} onChange={handleRoleChange}>
              <option value={CompanyRole.ADMIN}>{t('users.admin')}</option>
              <option value={CompanyRole.USER}>{t('users.normalUser')}</option>
            </SelectNative>
          ) : (
            t(roleLabel[memberRole])
          )}
        </td>
        <td className="pr-3 text-right">
          {userCapabilities.includes(memberRole) && !isSelf && (
            <ActionIcon
              onClick={() => setShowUserDeleteModal(true)}
              aria-label="remove user"
            >
              <FontAwesomeIcon icon={faTrash} />
            </ActionIcon>
          )}
          {canTransferOwnership ? (
            <TransferOwnershipButton
              companyId={companyId}
              userId={currentUserId}
            />
          ) : null}
        </td>
      </tr>
      <Modal className="mt-80" isOpen={showUserDeleteModal}>
        <ConfirmationDialog
          title={t('users.delete.title')}
          message={t('users.delete.message')}
          customConfirmText={t('users.delete.confirm')}
          onCancel={() => setShowUserDeleteModal(false)}
          onConfirm={deleteUser}
        />
      </Modal>
      <Modal className="mt-80" isOpen={showUserRoleModal}>
        <ConfirmationDialog
          title={t('users.roles.title')}
          message={t('users.roles.message')}
          customConfirmText={t('users.roles.confirm')}
          onCancel={() => setShowUserRoleModal(false)}
          onConfirm={changeUserRole}
        />
      </Modal>
    </>
  );
};

const CAPABILITIES: { [key in CompanyRole]: CompanyRole[] } = {
  [CompanyRole.USER]: [],
  [CompanyRole.ADMIN]: [CompanyRole.ADMIN, CompanyRole.USER],
  [CompanyRole.OWNER]: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.USER],
};

const TransferOwnershipButton = ({
  companyId,
  userId,
}: {
  companyId: string;
  userId: string;
}) => {
  const { t } = useTranslation('company');
  const [showTransferOwnerModal, setShowTransferOwnerModal] = useState(false);

  return (
    <>
      <Button
        size="sm"
        variant="tertiary"
        onClick={() => setShowTransferOwnerModal(true)}
      >
        {t('transferOwner.title')}
      </Button>
      {showTransferOwnerModal ? (
        <TransferOwnerModal
          isOpen={showTransferOwnerModal}
          userId={userId}
          companyId={companyId}
          onClose={() => setShowTransferOwnerModal(false)}
        />
      ) : null}
    </>
  );
};
