import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';

import { Select } from '../Select/Select';
import { InputWrapper } from '../Wrapper/InputWrapper';

export interface SelectNativeProps extends ComponentPropsWithoutRef<'select'> {
  id?: string;
  label?: ReactNode;
  description?: ReactNode;
  error?: string | boolean;
  required?: boolean;
  wrapperProps?: { [key: string]: any };
}

export const SelectNative = forwardRef<HTMLSelectElement, SelectNativeProps>(
  function SelectNative(props: SelectNativeProps, ref) {
    const {
      className,
      id,
      label,
      error,
      required,
      disabled,
      style,
      placeholder,
      description,
      wrapperProps,
      ...rest
    } = props;

    return (
      <InputWrapper
        id={id}
        required={required}
        aria-disabled={disabled}
        label={label}
        error={error}
        description={description}
        className={className}
        style={style}
        {...wrapperProps}
      >
        <Select
          {...rest}
          required={required}
          disabled={disabled}
          invalid={!!error}
          ref={ref}
          id={id}
          placeholder={placeholder}
          error={error}
        />
      </InputWrapper>
    );
  },
);
