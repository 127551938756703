import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import { Container } from 'src/common/components/Container';
import { Button } from 'src/common/components/buttons/Button';
import { Pagination } from 'src/common/components/Pagination';
import { UserRow } from '../components/user/UserRow';
import { useAdminUsersQuery } from '../queries';
import { SearchSort } from 'src/common/components/SearchSort';
import { UserForm } from 'src/admin/components/user/UserForm';
import { Card } from 'src/common/components/Card';
import { Modal } from 'src/common/components/Modal';
import { SelectSitelifeOptionType } from 'src/common/components/SelectSitelife/types';
import { Spinner } from 'src/common/components/Spinner';

export const UsersView = () => {
  const { t } = useTranslation(['admin', 'common']);

  const [searchBy, setSearchBy] = useState('');
  const [sortBy, setSortBy] = useState('surname');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);

  const { data: paginatedUsers, isPending } = useAdminUsersQuery(
    page,
    entriesPerPage,
    searchBy,
    sortBy,
    sortOrder,
  );

  // reset page to 1 when search changes
  useEffect(() => {
    setPage(1);
  }, [searchBy]);

  const sortOptions: SelectSitelifeOptionType[] = [
    { label: t('common:surname'), value: 'surname' },
    { label: t('common:firstName'), value: 'name' },
    { label: t('common:email'), value: 'email' },
    { label: t('common:position'), value: 'position' },
  ];

  const users = paginatedUsers ? paginatedUsers.data : [];

  return (
    <Container className="h-full" variant="box">
      <h1 className="text-2xl font-semibold tracking-wide">
        {t('common:users')}
      </h1>
      <div className="flex w-full items-end">
        <SearchSort
          searchBy={searchBy}
          sortBy={
            sortOptions.find((sortOption) => sortOption.value === sortBy) ||
            sortOptions[0]
          }
          sortOrder={sortOrder}
          sortOptions={sortOptions}
          onSearchBy={(newSearchBy) => setSearchBy(newSearchBy)}
          onSortBy={(newSortBy) => setSortBy(newSortBy)}
          onSortOrder={(newSortOrder) => setSortOrder(newSortOrder)}
          className="mr-4"
        />
        <Button
          className="ml-auto"
          onClick={() => setIsCreateUserModalOpen(true)}
        >
          <FontAwesomeIcon icon={faPlus} />
          {t('users.createUser')}
        </Button>
      </div>
      <Card className="mt-6 overflow-auto">
        {isPending ? (
          <Spinner containerClassName="w-full my-[160px] text-center" />
        ) : users.length > 0 ? (
          <table className="relative w-full divide-y divide-gray-200 shadow">
            <thead>
              <tr>
                <th scope="col" className="table-th">
                  {t('common:person')}
                </th>
                <th scope="col" className="table-th">
                  {t('common:position')}
                </th>
                <th scope="col" className="table-th">
                  {t('common:type')}
                </th>
                <th scope="col" className="table-th xl:table-cell" />
              </tr>
            </thead>
            <tbody className="table-body">
              {users.map((user) => (
                <UserRow user={user} key={user._id} />
              ))}
            </tbody>
          </table>
        ) : (
          <p className="mx-auto my-[160px] max-w-[500px] text-center text-shuttleGray-600">
            <span className="font-semibold text-shuttleGray-800">
              {`"${searchBy}"`}
            </span>{' '}
            {t('common:notFound.message')}
          </p>
        )}
      </Card>
      {users.length > 0 && !isPending && paginatedUsers && (
        <Pagination
          maxPage={Math.ceil(paginatedUsers.totalCount / entriesPerPage)}
          page={page}
          onChangePage={setPage}
          entriesPerPage={entriesPerPage}
          onChangeEntriesPerPage={setEntriesPerPage}
          className="mt-4"
        />
      )}
      <Modal
        className="min-w-[680px]"
        isOpen={isCreateUserModalOpen}
        onRequestClose={() => setIsCreateUserModalOpen(false)}
      >
        <Modal.Header onClose={() => setIsCreateUserModalOpen(false)}>
          {t('users.modal.createTitle')}
        </Modal.Header>
        <Modal.Body>
          <UserForm onClose={() => setIsCreateUserModalOpen(false)} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};
