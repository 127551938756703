import { DataExportQueryParameter } from 'shared';

import { ExportResultURL } from './types';
import { api } from 'src/common/fetch';

export function getQueryString(queryParameter: DataExportQueryParameter) {
  const { date, dateFrom, dateTo, fileVersionViewId, parameterString, lang } =
    queryParameter;
  const query = [];
  // check date option
  if (date) {
    query.push(`date=${date}`);
  } else if (dateFrom && dateTo) {
    query.push(`dateFrom=${dateFrom}&dateTo=${dateTo}`);
  } else {
    throw new Error(
      "Invalid query parameter: 'date' or 'dateFrom' and 'dateTo' must be specified",
    );
  }
  // check parameter option
  if (fileVersionViewId && parameterString) {
    query.push(`fileVersionViewId=${fileVersionViewId}`);
    query.push(`parameterString=${parameterString}`);
  } else if (fileVersionViewId || parameterString) {
    throw new Error(
      "Invalid query parameter: 'fileVersionViewId' and 'parameterString' must be specified together",
    );
  }
  // check language option
  if (lang) {
    query.push(`lang=${lang}`);
  }
  return query.join('&');
}

export async function getPDFV2(
  projectId: string,
  techCrewId: string,
  queryParameter: DataExportQueryParameter,
) {
  const queryString = getQueryString(queryParameter);

  return api.get<ExportResultURL>(
    `/api-conrep/projects/${projectId}/techcrews/${techCrewId}/data/v2/pdf?${queryString}`,
  );
}

export async function getExcelV2(
  projectId: string,
  techCrewId: string,
  queryParameter: DataExportQueryParameter,
) {
  const queryString = getQueryString(queryParameter);

  return api.getFile(
    `/api-conrep/projects/${projectId}/techcrews/${techCrewId}/data/v2/excel?${queryString}`,
    {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  );
}

export async function getCSVV2(
  projectId: string,
  techCrewId: string,
  queryParameter: DataExportQueryParameter,
) {
  const queryString = getQueryString(queryParameter);

  return api.getFile(
    `/api-conrep/projects/${projectId}/techcrews/${techCrewId}/data/v2/csv?${queryString}`,
    {
      Accept: 'application/zip',
    },
  );
}
