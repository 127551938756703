import { FC } from 'react';

import { useProject } from 'src/project/project-context';

export const ConrepAppModelBased: FC = ({ children }) => {
  const project = useProject();
  const conrepSettings = project.conrepSettings;
  if (!conrepSettings) {
    return null;
  }

  const isModelBased =
    conrepSettings.generalSettings.modelBasedPerformanceDocumentation;

  if (isModelBased) {
    return <>{children}</>;
  }

  return null;
};
