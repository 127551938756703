/* eslint-disable no-console */
import * as Sentry from '@sentry/react';

const logLevels = {
  error: 'error',
  warn: 'warn',
  info: 'info',
  debug: 'debug',
} as const;

const transportMap = {
  error: console.error,
  warn: console.warn,
  info: console.info,
  debug: console.debug,
} as const;

const isProd = process.env.NODE_ENV === 'production';

function createLogger({
  levels = Object.values(logLevels),
}: {
  levels?: Array<keyof typeof logLevels>;
} = {}) {
  const defaultLevel = 'debug';

  function _log({
    level = defaultLevel,
    message,
  }: {
    level?: keyof typeof logLevels;
    message: string;
  }) {
    if (!levels.includes(level)) {
      return;
    }

    if (isProd && level !== 'error') {
      return;
    }

    const timestamp = new Date();
    const transport = transportMap[level];
    transport(`sitelife-client [${timestamp.toISOString()}]: ${message}`);
  }

  return {
    error: (message: string) => _log({ level: logLevels.error, message }),
    warn: (message: string) => _log({ level: logLevels.warn, message }),
    info: (message: string) => _log({ level: logLevels.info, message }),
    debug: (message: string) => _log({ level: logLevels.debug, message }),
    capture: (error: unknown) => Sentry.captureException(error),
  };
}

export const logger = createLogger();
