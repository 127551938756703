import { useQuery } from '@tanstack/react-query';

import {
  getProjects,
  getProjectImage,
  getProjectUsersAsAdmin,
} from 'src/admin/apiProjects';
import {
  getUsers,
  getUserProjects,
  getUserDemoProjects,
} from 'src/admin/apiUsers';
import {
  getCompanies,
  getCompanyImage,
  getCompanyUsersAsAdmin,
} from 'src/admin/apiCompanies';
import { getDatasetsAsAdmin, getWorkspacesAsAdmin } from './apiAnalysis';

export function useAdminCompaniesQuery(
  page: number,
  entriesPerPage: number,
  searchBy?: string,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
) {
  return useQuery({
    queryKey: [
      'admin-companies',
      page,
      entriesPerPage,
      searchBy,
      sortBy,
      sortOrder,
    ],
    queryFn: () =>
      getCompanies(page, entriesPerPage, searchBy, sortBy, sortOrder),
  });
}

export function useAdminCompanyUsersQuery(companyId: string) {
  return useQuery({
    queryKey: ['admin-companies', companyId, 'users'],
    queryFn: () => getCompanyUsersAsAdmin(companyId),
    initialData: [],
    staleTime: 0,
  });
}

export function useAdminCompanyImageQuery(companyId: string) {
  return useQuery({
    queryKey: ['admin-companies', companyId, 'image'],

    queryFn: () => getCompanyImage(companyId),
  });
}

export function useAdminProjectsQuery(
  page: number,
  entriesPerPage: number,
  searchBy?: string,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
) {
  return useQuery({
    queryKey: [
      'admin-projects',
      page,
      entriesPerPage,
      searchBy,
      sortBy,
      sortOrder,
    ],
    queryFn: () =>
      getProjects(page, entriesPerPage, searchBy, sortBy, sortOrder),
  });
}

export function useAdminProjectUsersQuery(projectId: string) {
  return useQuery({
    queryKey: ['admin-projects', projectId, 'users'],
    queryFn: () => getProjectUsersAsAdmin(projectId),
    initialData: [],
    staleTime: 0,
  });
}

export function useAdminProjectImageQuery(projectId: string) {
  return useQuery({
    queryKey: ['admin-projects', projectId, 'image'],

    queryFn: () => getProjectImage(projectId),
  });
}

export function useAdminUsersQuery(
  page: number,
  entriesPerPage: number,
  searchBy?: string,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
  filter?: {
    filterBy: string;
    filter?: string;
    invertFilter?: 'true' | 'false';
  },
) {
  return useQuery({
    queryKey: [
      'admin-users',
      page,
      entriesPerPage,
      searchBy,
      sortBy,
      sortOrder,
      filter,
    ],
    queryFn: () =>
      getUsers(page, entriesPerPage, searchBy, sortBy, sortOrder, filter),
  });
}

export function useAdminUserProjectsQuery(userId: string) {
  return useQuery({
    queryKey: ['admin-user-projects', userId],
    queryFn: () => getUserProjects(userId),
    initialData: [],
    staleTime: 0,
  });
}

export function useAdminUserDemoProjectsQuery(userId: string) {
  return useQuery({
    queryKey: ['admin-user-demo-projects'],
    queryFn: () => getUserDemoProjects(userId),
    initialData: [],
    staleTime: 0,
  });
}

export function useAdminWorkspaceQuery(
  page: number,
  entriesPerPage: number,
  sortOrder: string,
  searchBy?: string,
) {
  return useQuery({
    queryKey: ['admin-analysis', page, entriesPerPage, searchBy, sortOrder],
    queryFn: () =>
      getWorkspacesAsAdmin(page, entriesPerPage, sortOrder, searchBy),
  });
}

export function useAdminDatasetsQuery(workspaceId: string) {
  return useQuery({
    queryKey: ['admin-analysis', workspaceId],

    queryFn: () => getDatasetsAsAdmin(workspaceId),
  });
}
