import Highlighter from 'react-highlight-words';

type Props = {
  className?: string;
  highlightedSearch: string | undefined | null;
  textToHighlight: string | undefined;
};

export const SitelifeHighlighter = (props: Props) => {
  const { className, highlightedSearch, textToHighlight } = props;

  if (highlightedSearch && textToHighlight) {
    return (
      <Highlighter
        className={className}
        searchWords={highlightedSearch.split(' ')}
        autoEscape
        textToHighlight={textToHighlight}
      />
    );
  }

  if (textToHighlight) {
    return <div className={className}>{textToHighlight || ''}</div>;
  }
  return null;
};
