import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, useTranslation } from 'react-i18next';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';

import { cn } from 'src/common/utils';
import { ExternalTextLink } from '../TextLink/External';

export const FeatureList = ({
  features,
  columns = 2,
  showPricing = true,
}: {
  features: { label: string; enabled: boolean }[];
  columns?: number;
  showPricing?: boolean;
}) => {
  const { t } = useTranslation('project');
  return (
    <>
      <div className="self-start">
        <ul
          className={cn('grid', {
            'grid-cols-1': columns === 1,
            'grid-cols-2': columns === 2,
            'grid-cols-3': columns === 3,
            'grid-cols-4': columns === 4,
          })}
        >
          {features.map(({ label, enabled }, index) => (
            <li
              key={index}
              className="mr-4 mt-4 flex items-center space-x-2 text-left"
            >
              {enabled ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-green-600"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className=" text-gray-600"
                />
              )}
              <span className="">{label}</span>
            </li>
          ))}
        </ul>
      </div>
      {showPricing && (
        <p className="mt-4 text-left text-xs">
          *{t('license.additionalInfo')}
          <span className="ml-1" />
          <Trans
            ns="project"
            i18nKey="license.informationLink"
            components={[
              <ExternalTextLink
                href="https://bim-management.com/sitelife/"
                key="0"
              >
                &nbsp;
              </ExternalTextLink>,
            ]}
          />
        </p>
      )}
    </>
  );
};
