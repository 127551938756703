import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';

import { s3 } from 'src/common/fetch';
import { resizeImage } from 'src/common/utils';
import { logger } from 'src/logger';
import { ImageInput } from 'src/common/components/inputs/ImageInput';
import {
  deleteCompanyImage,
  editCompanyImage,
  getCompanyImage,
} from 'src/company/api';
import { Company } from '../types';

export function CompanyImage({ company }: { company: Company }) {
  const queryClient = useQueryClient();
  const deleteMutation = useMutation({
    mutationFn: deleteCompanyImage,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['companies', company._id],
      });
      queryClient.resetQueries({
        queryKey: ['companies', company._id, 'image'],
      });
    },
    onError: logger.capture,
  });
  const uploadMutation = useMutation({
    mutationFn: async (file: File) => {
      const { blob, extension } = await resizeImage(file);
      const { url: uploadUrl } = await editCompanyImage(company._id, extension);
      return s3.upload(uploadUrl, blob);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['companies', company._id],
      });
    },
    onError: logger.capture,
  });

  const hasImage = !!company.image;

  const query = useQuery({
    queryKey: ['companies', company._id, 'image'],
    queryFn: () => getCompanyImage(company._id),
    enabled: hasImage && !deleteMutation.isPending,
  });

  return (
    <div className="mb-8">
      <ImageInput
        src={query.data?.downloadUrl}
        onChange={uploadMutation.mutate}
        onDelete={() => deleteMutation.mutate(company._id)}
        isLoading={hasImage && query.isLoading}
        isDeleting={deleteMutation.isPending}
        isUploading={uploadMutation.isPending}
      />
    </div>
  );
}
