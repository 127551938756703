import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import React from 'react';

import { UnauthorizedError } from './errors';

export function init() {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing(),
      new CaptureConsole({ levels: ['error'] }),
    ],
    tracesSampleRate:
      import.meta.env.VITE_ENVIRONMENT === 'production' ? 0.1 : 1,
    sampleRate: 1.0,
    normalizeDepth: 10,
    denyUrls: ['viewer3D.min.js'],
    ignoreErrors: [new UnauthorizedError().message],
    enabled: import.meta.env.VITE_ENVIRONMENT !== 'local',
  });
}

export function useSentryUser(id?: string, email?: string) {
  React.useEffect(() => {
    if (!id) {
      return;
    }
    Sentry.setUser({ id, email });
  }, [id, email]);
}
